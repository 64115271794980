import { ClickAwayListener, Fab, Grid, Popper } from '@mui/material'
import { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'

const DefaultCompassPopper = ({ render, sx, open, setOpen, fabSx, fabSize, ariaLabel, buttonContent, componentRef }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const onClick = (event) => {
    setAnchorEl(event.target)
    setOpen(!open)
  }

  return (
    <Grid container sx={sx}>
      <Fab color='primary' size={fabSize || 'large'} onClick={onClick} aria-label={ariaLabel || '+'} sx={fabSx}> {buttonContent || <AddIcon />}</Fab>
      {open ? <CompassPopper open={open} setOpen={setOpen} anchorEl={anchorEl} render={render} componentRef={componentRef} /> : null}
    </Grid>
  )
}

const CompassPopper = ({ open, setOpen, anchorEl, render, componentRef }) => {
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Popper open={open} container={componentRef?.current} anchorEl={anchorEl} sx={{ overflowY: 'auto', padding: '15px', minWidth: '20%', background: '#4c4c4c', maxHeight: '50%', zIndex: '1000000' }}>
        {render}
      </Popper>
    </ClickAwayListener>

  )
}

export { DefaultCompassPopper }
