import { PauseCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { apiPut } from "../../../generic/Api_Functions";
import { useState } from "react";
import { useSmartTranslation } from "../../../generic/hooks/useSmartTranslation";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PausePartSelectEndJobDialog from "./PausePartSelectEndJobDialog";

const PausePart = (props) => {

  const { t_ } = useSmartTranslation();

  const [showSelectEndJob, setShowSelectEndJob] = useState(false);

  const onPauseError = () => {
    props.showAlert({
      title: t_("Error pausing part"),
      severity: "error",
    });
  }

  const onPauseSuccess = () => {
    props.showAlert({
      title: t_("Part has been paused"),
      severity: "success",
    });
  }

  const onPauseWorkflow = async () => {
    try {
      await apiPut("part/pause/" + props.part.id);
      onPauseSuccess();
    } catch (error) {
      if (error.status === 409) {
        // Part is in a job with multiple tasks. Decide if we want to end all tasks
        setShowSelectEndJob(true);
      } else {
        onPauseError();
      }
    }
  }

  const onPauseWorkflowForce = async (endJob) => {
    setShowSelectEndJob(false);
    try {
      await apiPut("part/pause/" + props.part.id + "/" + endJob)
      onPauseSuccess();
    } catch (error) {
      onPauseError();
    }
  }

  const onResumeWorkflow = async () => {
    try {
      await apiPut("part/resume/" + props.part.id)
      props.showAlert({
        title: t_("Part has been resumed"),
        severity: "success",
      });
    } catch (error) {
      props.showAlert({
        title: t_("Part could not be resumed"),
        severity: "error",
      });
    }
  }

  return (
    <>
      <PausePartSelectEndJobDialog
        setOpen={showSelectEndJob}
        cancelCallback={() => setShowSelectEndJob(false)}
        part={props.part}
        pauseNowCallback={() => { onPauseWorkflowForce(false) }}
        pauseAtJobEndCallback={() => { onPauseWorkflowForce(true) }}
      />
      {props.part && props.part.status !== "FINISHED" ?
        props.part.paused ?
          <Button startIcon={<PlayCircleOutlineIcon />} onClick={onResumeWorkflow}>{t_("Resume workflow")}</Button>
          : <Button startIcon={<PauseCircle />} onClick={onPauseWorkflow}>{t_("Pause workflow")}</Button>
        : null}
    </>
  );
};

export default PausePart;