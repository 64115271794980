import { AddCircleRounded, DeleteRounded, SaveRounded } from '@mui/icons-material'
import { Button, IconButton, Stack, TextField } from '@mui/material'
import { useSmartTranslation } from '../../hooks/useSmartTranslation'
import SaveAsIcon from '@mui/icons-material/SaveAs'

/**
 * Component for a smart toolbar.
 * @param {Object} props - The props object.
 * @param {function()} props.onAddElementClick - The function to call when the add button is clicked.
 * @param {function()} props.onSaveElementClick - The function to call when the save button is clicked.
 * @param {function()} props.onSaveAsElementClick - The function to call when the save as button is clicked.
 * @param {function()} props.onDeleteElementClick - The function to call when the delete button is clicked.
 * @param {boolean} props.disableSaveButton - Whether the save button should be disabled.
 * @param {Object[]} props.labels - The labels to display.
 * @param {string} props.labels.title - The title of the label.
 * @param {string} props.labels.value - The value of the label.
 * @param {boolean} props.labels.numeric - Whether the label is numeric.
 * @param {boolean} props.labels.negativeNumbers - Whether the numeric label allows negative numbers.
 * @param {function(string)} props.labels.onChange - The function to call when the label is changed.
 * @returns
 */
const SmartToolbar = ({
  onAddElementClick,
  onSaveElementClick,
  onSaveAsElementClick,
  onDeleteElementClick,
  disableSaveButton = false,
  labels
}) => {
  const { t_ } = useSmartTranslation()

  const getActionButton = (label, icon, onClick) => {
    return (
      <Button
        sx={{ borderColor: 'transparent' }}
        color='primary'
        variant='outlined'
        onClick={onClick}
        startIcon={icon}
      >
        {label}
      </Button>
    )
  }

  return (
    <Stack direction='row'>
      <Stack direction='row' gap={4}>
        {labels.map((label, index) => {
          return (
            <TextField
              type={label.numeric ? 'number' : 'text'}
              key={index}
              label={label.title}
              InputLabelProps={{
                shrink: true
              }}
              onBlur={() => {
                if (!label.numeric) {
                  return
                }
                if (isNaN(parseFloat(label.value))) {
                  label.onChange(0)
                }
                if (!label.negativeNumbers && label.value < 0) {
                  label.onChange(0)
                }
              }}
              value={label.value}
              onChange={(event) => { label.onChange(event.target.value) }}
            />
          )
        })}
      </Stack>
      <Stack style={{ position: 'sticky', justifyContent: 'flex-end', flexGrow: 1 }} direction='row'>
        {onAddElementClick ? getActionButton(t_('ADD RECORD'), <AddCircleRounded />, onAddElementClick) : null}
        {onSaveElementClick ? getActionButton(t_('SAVE'), <SaveRounded />, onSaveElementClick) : null}
        {onSaveAsElementClick ? getActionButton(t_('SAVE AS'), <SaveAsIcon />, onSaveAsElementClick) : null}
        {onDeleteElementClick
          ? (
            <IconButton
              disabled={disableSaveButton}
              style={{ aspectRatio: 1 / 1 }}
              onClick={onDeleteElementClick}
            >
              <DeleteRounded color={!disableSaveButton ? 'primary' : 'disabled'} />
            </IconButton>
            )
          : null}
      </Stack>
    </Stack>

  )
}
export default SmartToolbar
