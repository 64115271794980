import { DeleteOutlineRounded, DeleteRounded } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import React from 'react'
import createDialogTitle from '../dialogUtil'
import SmartDialog from './SmartDialog'
import SmartMateriaUITable from '../../SmartMateriaUITable'
import { useSmartTranslation } from '../../hooks/useSmartTranslation'

const SmartDeleteDialog = (props) => {
  const {
    show = false,
    cancelCallback,
    deleteCallBack,
    rows,
    columns,
    pageable = null
  } = props

  const { t_ } = useSmartTranslation()

  return (
    <SmartDialog
      title={createDialogTitle({ color: 'error', title: t_('Delete data?'), icon: DeleteRounded })}
      message={t_("Are you sure you want to delete data? You can't undo this action.")}
      renderComponent={
        <SmartMateriaUITable
          columns={columns}
          dataFetch={rows}
          pagination={pageable}
          pageable={pageable !== null}
        />
      }
      setOpen={show}
      accept={false}
      cancelCallback={() => {
        if (cancelCallback && typeof cancelCallback === 'function') {
          cancelCallback()
        }
      }}
      renderCustomButtons={
        <Button
          color='error'
          onClick={
            () => {
              if (deleteCallBack && typeof deleteCallBack === 'function') {
                deleteCallBack()
              }
            }
}
          variant='outlined'
        >
          <Typography color='error'>{t_('DELETE')}</Typography>
          <DeleteOutlineRounded color='error' sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
        </Button>
      }
    />
  )
}

export default SmartDeleteDialog
