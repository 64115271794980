import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import { apiGet } from '../../generic/Api_Functions'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import CompassButton from '../Common/CompassButton/CompassButton'
import { useSmartInterval } from '../../generic/hooks/useSmartInterval'
import { Link } from 'react-router-dom'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import CompassCard from '../Common/CompassCard/CompassCard'
import CompassGrid from '../Common/compassGrid/CompassGrid'

export default function Presence ({ fullScreen, setFullScreen }) {
  const [devices, setDevices] = useState(null)
  const { t_ } = useSmartTranslation()

  useSmartInterval(
    async () => {
      const response = await apiGet('compass/devices/')
      return response
    }, 1000,
    (data) => {
      if (data != null) {
        setDevices(data)
      }
    }
  )
  const presenceButtons = {
    DOC: 'doc',
    HISTORY: 'history',
    LOGIN: 'login',
    LOGOUT: 'logout'
  }
  const getHistoryButton = (device) => {
    return (
      <Link to='/compass/presence/history/' state={{ device }}>
        <CompassButton
          icon={<ContentPasteSearchIcon />}
          color='secondary'
        />
      </Link>
    )
  }

  const getDocButton = () => {
    return (
      <Link to='/compass/presence/viewFile' state={{ urlBack: '/compass/presence' }}>
        <CompassButton
          icon={<FeedOutlinedIcon />}
          color='secondary'
        />
      </Link>
    )
  }

  const getLoginButton = (device, allDevices) => {
    return (
      <Link to='/compass/presence/authentication' state={{ activeDevices: allDevices, deviceSelected: [device], login: true }}>
        <CompassButton
          icon={<LoginIcon />}
          label={t_('Log in')}
          color='primary'
        />
      </Link>

    )
  }

  const getLogoutButton = (device, allDevices) => {
    return (
      <Link to='/compass/presence/authentication' state={{ activeDevices: allDevices, deviceSelected: [device], login: false }}>
        <CompassButton
          icon={<LogoutIcon />}
          iconPosition='right'
          label={t_('Logout')}
          color='warning'
        />
      </Link>
    )
  }

  const getPresenceButton = (presenceButtonName, device, allDevices) => {
    let md = 2
    let button = null
    switch (presenceButtonName) {
      case presenceButtons.HISTORY:
        button = getHistoryButton(device)
        break
      case presenceButtons.DOC:
        button = getDocButton()
        break
      case presenceButtons.LOGIN:
        button = getLoginButton(device, allDevices)
        md = 4
        break
      case presenceButtons.LOGOUT:
        button = getLogoutButton(device, allDevices)
        md = 4
        break
      default:
        break
    }

    if (!button) return null

    return (<Grid item md={md} key={presenceButtonName}>{button}</Grid>)
  }
  const buttons = (device, allDevices) => {
    return Object.values(presenceButtons).map(buttonName => { return getPresenceButton(buttonName, device, allDevices) })
  }

  return (
    <CompassGrid
      items={devices}
      fullScreenItem={fullScreen}
      renderItem={(device, isFullscreen) =>
        <CompassCard
          device={device}
          buttons={buttons(device, devices)}
          onDoubleClick={() => setFullScreen(isFullscreen ? null : device)}
          isFullScreen={isFullscreen}
        />}
      actions={[
        <Link to='/compass/presence/authentication' state={{ activeDevices: devices, deviceSelected: devices, login: true }} key='sign_in'>
          <CompassButton
            icon={<LoginIcon />}
            label={t_('Log in to all')}
            maxLabelLength={40}
            color='primary'
          />
        </Link>,
        <Link to='/compass/presence/authentication' state={{ activeDevices: devices, deviceSelected: devices, login: false }} key='sign_out'>
          <CompassButton
            icon={<LogoutIcon />}
            iconPosition='right'
            label={t_('Sign out of all')}
            maxLabelLength={40}
            color='warning'
          />
        </Link>
      ]}
    />
  )
}
