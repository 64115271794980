// in the file where you are creating the theme (invoking the function `createTheme()`)

import { createTheme } from '@mui/material'

const mode = 'dark'

const theme = createTheme({
  palette: {
    // mode: 'light',
    mode,
    ...(mode === 'dark'
      ? {
          background: {
            default: '#303030',
            button: '#424242',
            buttonHover: '#474F4A',
            paper: '#424242',
            color_212121: '#212121',
            color_373636: '#373636',
            table_row_hover: '#38815775',
            row2: '#2D2D2C',
            chip: '#35353580',
            card: '#282827',
            appBar: '#000000'
          },
          primary: {
            main: '#7FE1A7'
          },
          calendar: {
            systemDefault: '#BDC15C'
          }

        }
      : {
          background: {
            default: '#303030',
            button: '#424242',
            buttonHover: '#474F4A',
            paper: '#424242',
            color_212121: '#212121',
            toolbar: '#373636',
            toolbarMultipleSelection: '#43424250'
          },
          warning: {
            main: '#FFB300'
          },

          primary: {
          // light: '#757ce8',
            main: '#FF5733'
            // default: "#7FE1A7",

          // contrastText: '#fff',
          }
        }),

    secondary: {
      // light: '#757ce8',
      main: '#FFFFFF',
      darker: '#05fd92'
      // contrastText: '#fff',
    },

    warning: {
      main: '#ED6C02'
    },
    error: {
      main: '#F68D76'
    },
    info: {
      main: '#0688D1'
    },
    // edit: {
    //     main: '#FFFF00'
    // },
    action: {
      // hover:"#05fd9250",
      hover: '#00000020',
      selected: '#00000050'
    },
    manual_action: '#FFFF00'
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: '0.5px solid #00000020',
          color: '#AAAAAA'
        }
      }
    }
  }

})

export default theme
