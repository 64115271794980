import { Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { GetDate } from '../../../generic/DateTimeFunctions'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'
import PropTypes from "prop-types";
import AlertUI from '../../../generic/AlertUI';
import PausePart from '../pause/PausePart';

// Import typedefs from another file
/** 
 * @typedef {import('../../../generic/utilities/objectStructures/partWindow/part').PartHistoryHeader} PartHistoryHeader PartHistoryHeader object
 */

/**
 * @param {object} props props object
 * @param {PartHistoryHeader} props.part Part object
 */
const PartHistoryHeader = (props) => {
  const { t_ } = useSmartTranslation()

  const [AlertElement, showAlert] = AlertUI();

  const leftAlignStyle = {
    textAlign: "left"
  }

  const dividerStyle = {
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
  }

  const typographyValueStyle = {
    marginLeft: "0.5rem"
  }

  const divFlexStyle = {
    display: "flex"
  }

  return (
    <div style={leftAlignStyle}>
      {AlertElement}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" style={{ textAlign: "left" }}>{t_("Part").toUpperCase()}</Typography>
        <PausePart part={props.part} showAlert={showAlert} />
      </Stack>
      <Divider style={dividerStyle} />
      <Grid container direction="row" spacing={2} columns={{ xs: 1, sm: 4, md: 8, lg: 14 }} style={{ marginLeft: "1rem" }}>
        <Grid item xs={3}>
          <div style={divFlexStyle}>
            <Typography>{t_("Code")}:</Typography>
            <Typography color={"grey"} style={typographyValueStyle}>{props.part.code}</Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={divFlexStyle}>
            <Typography>{t_("Name")}:</Typography>
            <Typography color={"grey"} style={typographyValueStyle}>{props.part.name}</Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={divFlexStyle}>
            <Typography>{t_("Description")}:</Typography>
            <Typography color={"grey"} style={typographyValueStyle}>{props.part.description}</Typography>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={divFlexStyle}>
            <Typography>{t_("Raw Material Reference")}:</Typography>
            <Typography color={"grey"} style={typographyValueStyle}>{props.part.rawMaterialReferenceName}</Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={divFlexStyle}>
            <Typography>{t_("Status")}:</Typography>
            <Typography color={"grey"} style={typographyValueStyle}>{props.part.paused ? t_("WORKFLOW_PAUSED") : props.part.status}</Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={divFlexStyle}>
            <Typography>{t_("FO")}:</Typography>
            <Typography color={"grey"} style={typographyValueStyle}>{props.part.fabricationOrder}</Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={divFlexStyle}>
            <Typography>{t_("Delivery date")}:</Typography>
            <Typography color={"grey"} style={typographyValueStyle}>{GetDate(props.part.deliveryDate)}</Typography>
          </div>
        </Grid>
      </Grid>
      <Divider style={dividerStyle} />
    </div >
  )
}

export default PartHistoryHeader


PartHistoryHeader.propTypes = {
  /**
   * Part object
   */
  part: PropTypes.object.isRequired,

}