import React from 'react'
import { apiGet, apiPost, apiDelete, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const QualityType = () => {
  const { t_ } = useSmartTranslation()

  const columns = [{
    name: t_('Code'),
    field: 'code',
    charLimit: 60,
    mandatory: true,
    unique: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_('Name'),
    field: 'name',
    charLimit: 80,
    mandatory: true,
    unique: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_('Description'),
    field: 'description',
    charLimit: 200
  }
  ]

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('qualityType', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        return apiPost('qualityType', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('qualityType/' + rows[0].id, rows)
        } else {
          return apiDelete('qualityTypeMulti', rows)
        }
      },
      visibilityButton: (qualityTypes) => !qualityTypes.systemDefault
    }
  }

  const fetchFunction = async (pagination) => {
    const qualityTypes = await apiGet('qualityTypes', pagination)
    return qualityTypes
  }

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Quality Types')}
      dataFetch={fetchFunction}
      actions={actions}
      multipleSelection
      multipleOptions
      pageable
    />
  )
}

export default QualityType
