import { useEffect, useState } from "react";

/**
 * Will change the value to valueToSet only when the toogle changes from false to true. If the toogle does not change or goes from true to false, nothing will be done
 * @param {*} valueToSet The new value to be set when forceValue goes from false to true
 * @param {*} toogle When this value changes from false to true, the value will be set to valueToSet. When the value is constant or changes from true to false nothing will be done
 * @returns Two element array similar to useState. The first element is the value. The second value is the set function for the value
 */
const useStateSetNewValueOnChangeToTrue = (valueToSet, toogle) => {

  const [toogleLastState, setToogleLastState] = useState(toogle);
  const [value, setValue] = useState(valueToSet);

  useEffect(() => {
    // If the force value has not changed dont do anything
    if (toogleLastState !== toogle) {
      // Save the last value of toogle to compare in next calls
      setToogleLastState(toogle);

      // If the toogle has been set to and the previous value was false, change the value to valueToSet
      if (toogle) {
        setValue(valueToSet);
      }

    }

  }, [valueToSet, toogleLastState, toogle]);

  return [value, setValue];
}

export default useStateSetNewValueOnChangeToTrue;