import React, { useState } from 'react'
import {
  IconButton,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Alert,
  Stack,
  Collapse,
  TextField
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { apiPost } from '../../generic/Api_Functions'
import { useLocation, useNavigate } from 'react-router-dom'
import CompassButton from '../Common/CompassButton/CompassButton'
import CompassDialog from '../Common/CompassDialog/CompassDialog'
import { Exposure, Logout } from '@mui/icons-material'
import CompassWindow from '../Common/CompassWindow/CompassWindow'

export default function PresenceLogin () {
  const location = useLocation()
  const { activeDevices, deviceSelected, login } = location.state
  const [selectedOption, setSelectedOption] = useState()
  const [activeDevicesAll] = useState([...activeDevices])
  const [activeDevicesLocal, setactiveDevicesLocal] = useState([...activeDevices])
  const [deviceSelectedLocal, setDeviceSelectedLocal] = useState([...deviceSelected])
  const [codeWorker, setCodeWorker] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [openReportQuantityDialog, setOpenReportQuantityDialog] = useState(false)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const { t_ } = useSmartTranslation()
  const handleChange = (e) => setCodeWorker(e.target.value)
  const [errorMessage, setErrorMessage] = useState({ show: false })

  const divstyle = {
    padding: '20px',
    backgroundColor: '#4C4C4C',
    minHeight: '80px',
    overflowY: 'auto',
    maxHeight: '500px'
  }
  const buttonCss = {
    border: '1px solid #D0D0D0 ',
    backgroundColor: '#303030',
    color: '#D9D9D9'
  }
  const workerButton = {
    minWidth: '50px',
    minHeight: '50px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
  const workerItem = {
    border: '1px solid white',
    marginRight: '1px',
    borderRadius: '2px',
    padding: '2px 4px',
    minWidth: '295px',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }

  const updateactiveDevices = (deviceSelectedFilter) => {
    const filter = activeDevicesAll.filter(i => !(deviceSelectedFilter ?? deviceSelectedLocal).includes(i))
    setactiveDevicesLocal(filter)
  }

  const handleSeleccion = (event) => {
    const nuevaSeleccion = event.target.value
    setSelectedOption(nuevaSeleccion)
  }

  const addElement = () => {
    if (selectedOption) {
      if (!deviceSelectedLocal.includes(selectedOption)) {
        setDeviceSelectedLocal([...deviceSelectedLocal, selectedOption])
        setSelectedOption(null)
      }
    }
    updateactiveDevices([...deviceSelectedLocal, selectedOption])
  }

  const removeElement = (element) => {
    const items = deviceSelectedLocal.filter(i => i.id !== element.id)
    setDeviceSelectedLocal(items)
    updateactiveDevices(items)
  }
  const goBack = () => {
    navigate('/compass/presence')
  }
  const confirmationDialog = () => {
    if (login) {
      logIn()
    } else {
      if (codeWorker !== '') {
        setOpenDialog(true)
      }
    }
  }
  const logIn = async () => {
    if (codeWorker.trim().length <= 0) {
      setMessage(t_('Error, the identification code is required'))
      setOpen(true)
      return
    }
    if (deviceSelectedLocal.length <= 0) {
      setMessage(t_('Error, you must select at least one asset'))
      setOpen(true)
      return
    }

    const devicesList = []
    const workerCodeList = []
    workerCodeList.push(codeWorker)

    deviceSelectedLocal?.map((e) => (devicesList.push(e.id)))
    await apiPost('compass/deviceWorker/register', {
      workerCodeList,
      deviceList: devicesList,
      login
    }).then((response) => {
      if (response.length > 0) {
        setMessage(t_('The worker was already logged in on these devices:') + response)
        setOpen(true)
      } else {
        goBack()
      }
    }).catch((e) => {
      setMessage(e.message)
      setOpen(true)
    })
  }
  const handleCloseConfirm = () => {
    setOpenDialog(false)
  }
  const handleAcceptConfirm = () => {
    setOpenDialog(false)
    if (!login) {
      setOpenReportQuantityDialog(true)
    }
  }
  const handleExit = () => {
    setOpenReportQuantityDialog(false)
  }
  const handleReportQuantityAccept = () => {
    if (deviceSelectedLocal.length > 1) {
      setOpenReportQuantityDialog(false)
      navigate('/compass/production')
    } else {
      navigate('/compass/production/quantity/report/' + deviceSelectedLocal[0].id, { state: { urlBack: '/compass/presence', device: deviceSelectedLocal[0], selectedFabricationOrder: deviceSelectedLocal[0].currentFabricationOrders[0], logoutUser: true } })
    }
  }
  const handleReportQuantityCancel = () => {
    setOpenReportQuantityDialog(false)
    logIn()
  }
  return (
    <>
      <CompassWindow
        message={errorMessage}
        onCloseMessage={() => setErrorMessage({ ...errorMessage, open: false })}
        onClose={goBack}
        render={
          <>
            <Box sx={{ width: '100%' }}>
              <Typography variant='h4' color='primary' className='title' sx={{ textAlign: 'center', marginBottom: '15px' }}> {t_('Identification')} </Typography>
              <Box sx={{ width: '100%' }}>
                <Collapse in={open}>
                  <Alert
                    variant='filled' severity='error' style={{ color: 'white' }}
                    action={
                      <IconButton aria-label='close' color='inherit' size='small' onClick={() => { setOpen(false) }}>
                        <CloseIcon fontSize='inherit' />
                      </IconButton>
              }
                    sx={{ mb: 2 }}
                  >
                    {message}
                  </Alert>
                </Collapse>
              </Box>

              <Stack spacing={2} justifyContent='center' alignItems='left'>
                <TextField label={t_('Identification')} fullWidth variant='filled' margin='normal' value={codeWorker} onChange={handleChange} />
                <div style={{ color: 'white', textAlign: 'left' }}> {t_('Asset')}  </div>

                <div style={divstyle}>
                  {deviceSelectedLocal?.sort((a, b) => { return a.name > b.name ? 1 : -1 }).map((e) => (
                    <Box key={e.id} sx={{ flexGrow: 1, display: { xs: 'flex' }, float: 'left', marginRight: '10px', marginBottom: '10px' }}>
                      <Typography color='secundary' style={workerItem}> {e.name}  </Typography>
                      <button style={{ ...workerButton, ...buttonCss }} color='secundary' onClick={() => removeElement(e)}>
                        <Typography color='secundary' sx={{ textAlign: 'center' }}> X</Typography>
                      </button>
                    </Box>
                  ))}

                </div>
                <Grid container justifyContent='space-between' alignItems='left' spacing={1}>
                  <Grid item xs={10} style={{ paddingLeft: '0' }}>
                    <FormControl fullWidth variant='filled' sx={{ backgroundColor: '#4C4C4C' }}>
                      <InputLabel id='ilListaActivo'>{t_('Asset list')} </InputLabel>
                      <Select label={t_('Asset list')} value={selectedOption ?? ''} onChange={handleSeleccion}>
                        {activeDevicesLocal?.sort((a, b) => { return a.name > b.name ? 1 : -1 }).map((e) => (
                          <MenuItem key={e.id} value={e}>{e.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <CompassButton label='+' color='primary' onClick={addElement} style={{ height: '100%', fontSize: '25px' }} />
                  </Grid>
                </Grid>
                <>
                  <Stack sx={{ height: '80px' }}> <CompassButton label={login ? t_('Log in') : t_('Log out')} color='primary' onClick={confirmationDialog} /></Stack>
                </>
              </Stack>

            </Box>
          </>
}
      />

      <CompassDialog
        openDialog={openDialog}
        handleClose={handleCloseConfirm}
        handleAccept={handleAcceptConfirm}
        acceptLabel={t_('ACCEPT')}
        cancelLabel={t_('CANCEL')}
        title={{ icon: <Logout color='primary' sx={{ fontSize: '1.5rem' }} />, render: <Typography variant='h5'>{t_('Log out')}</Typography> }}
        body={<Stack><Typography> {t_('Are you sure you want to log out?')}</Typography><Typography>{t_('Worker') + ': ' + codeWorker}</Typography></Stack>}
      />
      <CompassDialog
        openDialog={openReportQuantityDialog}
        handleClose={handleReportQuantityCancel}
        handleAccept={handleReportQuantityAccept}
        acceptLabel={t_('Quantity')}
        cancelLabel={t_('Log out')}
        title={{ icon: <Logout color='primary' sx={{ fontSize: '1.5rem' }} />, render: <Typography variant='h5'>{t_('Log out')}</Typography> }}
        body={<Stack><Typography> {t_('Do you want to report new quantities?')}</Typography></Stack>}
        showExitButton
        handleExit={handleExit}
        acceptIcon={<Exposure />}
      />

    </>

  )
};
