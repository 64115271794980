import { TextField, Stack, FormControlLabel, Checkbox, Divider } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { CalendarRepeatComponent, isRRuleValid, rruleObjectToString, rruleStringToObject } from './CalendarRepeatComponent'

import moment from 'moment/moment'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const sameDay = (d1, d2) => {
  if (!d1 || !d2) {
    return false
  }
  return moment(d1).isSame(moment(d2), 'day')
}

const CalendarEventMenu = (props) => {
  const { calendarEvent, onCalendarEventChange, showErrors } = props

  const { t_ } = useSmartTranslation()

  const nameError = (showErrors && (calendarEvent.name === null || calendarEvent.name.trim().length === 0))
  const dateError = (showErrors && calendarEvent.startDate > calendarEvent.endDate)
  const hourError = (showErrors && sameDay(calendarEvent.startDate, calendarEvent.endDate) && !(calendarEvent.startTime < calendarEvent.endTime))
  return (
    <Stack>

      <TextField
        label={t_('Name')}
        variant='outlined'
        value={calendarEvent.name}
        error={nameError}
        helperText={nameError ? t_('Name cannot be empty') : null}
        onChange={(event) => { onCalendarEventChange({ ...calendarEvent, name: event.target.value }) }}
        disabled={calendarEvent.systemDefault}
        sx={{ marginTop: '1rem' }}
      />

      <Stack spacing={2} sx={{ marginTop: '1rem' }}>

        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '1rem' }}>

          <DatePicker
            label={t_('Start date')}
            inputFormat='dd/MM/yyyy'
            value={calendarEvent.startDate}
            onChange={(newValue) => {
              onCalendarEventChange({ ...calendarEvent, startDate: newValue })
            }}
            slotProps={{ textField: { error: dateError } }}
            disabled={calendarEvent.systemDefault}
          />
          <DatePicker
            label={t_('End date')}
            value={calendarEvent.endDate}
            inputFormat='dd/MM/yyyy'
            onChange={(newValue) => {
              onCalendarEventChange({ ...calendarEvent, endDate: newValue })
            }}
            slotProps={{ textField: { error: dateError, helperText: dateError ? t_('End date must be the same or after start date') : null } }}
            disabled={calendarEvent.systemDefault}
          />

          <TimePicker
            label={t_('Start hour')}
            value={calendarEvent.startTime}
            onChange={(newValue) => {
              onCalendarEventChange({ ...calendarEvent, startTime: newValue })
            }}
            slotProps={{ textField: { error: hourError } }}
            disabled={calendarEvent.systemDefault}
          />
          <TimePicker
            label={t_('End hour')}
            value={calendarEvent.endTime}
            onChange={(newValue) => {
              onCalendarEventChange({ ...calendarEvent, endTime: newValue })
            }}
            slotProps={{ textField: { error: hourError, helperText: hourError ? t_('End date must be the same or after start date') : null } }}
            disabled={calendarEvent.systemDefault}
          />
        </div>

        <Stack>
          <FormControlLabel
            control={
              <Checkbox
                checked={calendarEvent.working}
                disabled={calendarEvent.systemDefault}
                onChange={(event, newValue) => {
                  onCalendarEventChange({ ...calendarEvent, working: newValue })
                }}
              />
          } label={t_('Working')}
          />
        </Stack>
        <Divider />
        <CalendarRepeatComponent
          rrule={calendarEvent.rrule}
          showErrors={showErrors}
          minDate={calendarEvent.endDate}
          onChange={(newValue) => { onCalendarEventChange({ ...calendarEvent, rrule: newValue }) }}
          disable={calendarEvent.systemDefault}
        />
      </Stack>
    </Stack>
  )
}

const eventToMutableEvent = (event) => {
  const startDate = new Date(event.startDate)
  const endDate = new Date(event.endDate)

  const startTime = new Date()
  startTime.setHours(event.startTime.split(':')[0])
  startTime.setMinutes(event.startTime.split(':')[1])

  const endTime = new Date()
  endTime.setHours(event.endTime.split(':')[0])
  endTime.setMinutes(event.endTime.split(':')[1])

  return { ...event, startDate, endDate, startTime, endTime, rrule: rruleStringToObject(event.rrule) }
}

const mutableEventToEvent = (mutableEvent) => {
  const event = { ...mutableEvent }
  event.startDate = moment(event.startDate).format('yyyy-MM-DD')
  event.endDate = moment(event.endDate).format('yyyy-MM-DD')
  event.startTime = moment(event.startTime).format('HH:mm')
  event.endTime = moment(event.endTime).format('HH:mm')
  event.rrule = rruleObjectToString(event.rrule)
  return event
}

const isEventValid = (calendarEvent) => {
  if (calendarEvent.name === null || calendarEvent.name.trim().length === 0) {
    return false
  }
  if (calendarEvent.startDate > calendarEvent.endDate) {
    return false
  }
  if (sameDay(calendarEvent.startDate, calendarEvent.endDate) && !(calendarEvent.startTime < calendarEvent.endTime)) {
    return false
  }
  if (calendarEvent.rrule !== null && calendarEvent.rrule.ON && !isRRuleValid(calendarEvent.rrule, calendarEvent.endDate)) {
    return false
  }

  return true
}

export { CalendarEventMenu, eventToMutableEvent, mutableEventToEvent, isEventValid }
