import React from 'react'
import Picklist from '../configuration/picklist/Picklist'
import SmartNavigator from '../generic/SmartNavigator'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

const MainPicklist = (props) => {
  const { t_ } = useSmartTranslation()

  const activePath = props.activePath
  const componentsInfo = [
    {
      title: t_('Picklist'),
      component: <Picklist />,
      to: '/configuration/picklist'
    }
  ]
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} selected={activePath} />
    </div>
  )
}

export default MainPicklist
