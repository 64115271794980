import React, { useEffect, useMemo, useState } from 'react'
import useSmartTranslation from '../generic/hooks/useSmartTranslation/useSmartTranslation'
import { CircularProgress, Stack } from '@mui/material'
import { apiGet } from './../generic/Api_Functions'
import { useTheme } from '@emotion/react'
import AlertUI from '../generic/AlertUI'
const GenericKPIs = ({ dashboardsUrl }) => {
  const { t_, i18n } = useSmartTranslation()

  const [loadIframe, setLoadIframe] = useState()
  const [token, setToken] = useState(null)
  const theme = useTheme()
  const [alertElement, showAlert] = AlertUI()

  const locale = useMemo(() => {
    if (i18n.language.startsWith('es')) {
      return 'es-ES'
    } else if (i18n.language.startsWith('fr')) {
      return 'fr-FR'
    }
    return 'en'
  }, [i18n.language])

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const themeMode = theme.palette.mode

  useEffect(() => {
    const getToken = async () => {
      try {
        const result = await apiGet('kpi/token')
        setToken(result.token)
      } catch (error) {
        showAlert({
          title: error.message,
          severity: 'error'
        })
      }
    }
    if (token === null) {
      getToken()
    }
  }, [showAlert, token])

  useEffect(() => {
    const setUserPreferences = () => {
      setLoadIframe(true)
      // return fetch(dashboardsUrl + '/api/user/preferences?auth_token=' + token, {
      //   method: 'PATCH',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ "locale": locale, "theme": themeMode, "timezone": timezone })
      // }).then(() => {
      //   setLoadIframe(true);
      // }).catch((error) => {
      //   setLoadIframe(true);
      // });
    }
    if (token !== null) {
      setUserPreferences()
    }
  }, [locale, themeMode, timezone, token, dashboardsUrl])

  return (
    <>
      {alertElement}
      {loadIframe && token !== null
        ? <iframe
            style={{ border: 'none' }}
            title={t_('MIC-DASHBOARD')}
            src={dashboardsUrl + '?auth_token=' + token}
            height={window.innerHeight}
            width='100%'
          />
        : <Stack justifyContent='space-around' direction='row'><CircularProgress /></Stack>}
    </>
  )
}

export default GenericKPIs
