/**
 * @typedef {import("../../generic/utilities/objectStructures/globalStructures").IdName} IdName IdName object
 * @typedef {import("../../generic/utilities/objectStructures/workflow/workflow").Workflow} Workflow Workflow object
 * @typedef {import("../../generic/utilities/objectStructures/workflow/workflow").JobReference} JobReference JobReference object
 * @typedef {import("../../generic/utilities/objectStructures/workflow/workflow").Position} Position Position object
 */

/**
 * Method to create initial nodes
 * @param {number} idStartNode Start node id
 * @param {number} positionXStartNode Position x of start node
 * @param {number} positionYStartNode Position y of start node
 * @param {number} idEndNode End node id
 * @param {number} positionXEndNode Position x of end node
 * @param {number} positionYEndNode Position y of end node
 * @param {boolean} isConnectable Is connectable *Default true*
 * @returns {import("reactflow").Node[]} node array: *Start and end nodes*
 */
const initialNodes = (idStartNode, positionXStartNode, positionYStartNode, idEndNode, positionXEndNode, positionYEndNode, isConnectable = true) => {
  return [{
    id: 'start',
    type: 'startNode',
    data: { label: 'Start', workflowPositionId: idStartNode, isConnectable },
    position: { x: positionXStartNode || -300, y: positionYStartNode || 0 },
    sourcePosition: 'right'
  },
  {
    id: 'end',
    type: 'endNode',
    data: { label: 'End', workflowPositionId: idEndNode, isConnectable },
    position: { x: positionXEndNode || 300, y: positionYEndNode || 0 },
    targetPosition: 'left'
  }]
}

/**
 * Method to create endEdge object
 * @param {JobReference} currentJob Current job reference
 * @param {number} countEnd Number of end nodes
 * @param {string} handle Source node type
 * @param {boolean} workflowInUse Workflow in use
 * @param {boolean} edgeCardVisible Variable to show/hide edge card. *Default true*
 * @returns {import("reactflow").Edge} endEdge object
 */
const endEdge = (currentJob, countEnd, handle, workflowInUse, edgeCardVisible = true) => {
  return {
    id: currentJob.id + '-end' + countEnd,
    source: currentJob.technology.name + '-' + currentJob.id,
    sourceHandle: handle,
    target: 'end',
    targetHandle: 'target',
    type: 'custom',
    data: {
      selected: false,
      type: handle,
      connectivity: 'FLEXIBLE',
      workflowInUse,
      edgeCardVisible
    }
  }
}

/**
 * Method used to load node and edge list by given workflow
 * @param {object} props
 * @param {Workflow} props.workflow Workflow object
 * @param {function(JobReference, Position)} props.technologyNodeData
 * @param {Boolean} props.edgeCardVisible=true Variable to show/hide edge card. *Default true*
 * @param {Boolean} props.isConnectable Variable to enable/disable connectable options. *Default true*
 * @param {number} props.maxJobIdNum Max job id count
 * @param {Boolean} props.addStartEndNodes Variable to enable/disable the creation of the starting and ending nodes
 *
 * @returns
 */
const getWorkflowNodesAndEdges = ({ workflow, technologyNodeData, edgeCardVisible = true, isConnectable = true, maxJobIdNum = 0, addStartEndNodes = true }) => {
  const startPosition = workflow.workflowPositions.find((position) => position.startNode === true)
  const endPosition = workflow.workflowPositions.find((position) => position.endNode === true)

  const newNodes = addStartEndNodes ? [...initialNodes(startPosition.id, startPosition.positionX, startPosition.positionY, endPosition.id, endPosition.positionX, endPosition.positionY, isConnectable)] : []

  // Add nodes
  workflow.jobReferences.forEach((jobReference) => {
    const position = workflow.workflowPositions.find((position) => position.jobReference?.id === jobReference.id)
    if (jobReference.id > maxJobIdNum) {
      maxJobIdNum = jobReference.id
    }

    newNodes.push({
      id: jobReference.technology.name + '-' + jobReference.id,
      type: 'technologyNode',
      position: { x: position.positionX, y: position.positionY },
      data: technologyNodeData(jobReference, position)
    })
  })

  // Add edges
  const newEdges = []
  workflow.jobReferences.forEach((jobReference) => {
    if (jobReference.jobIsFirst && addStartEndNodes) {
      newEdges.push({
        id: jobReference.id + '-start',
        source: 'start',
        sourceHandle: 'start',
        target: jobReference.technology.name + '-' + jobReference.id,
        targetHandle: 'target',
        type: 'custom',
        data: {
          selected: false,
          type: 'start',
          connectivity: 'FLEXIBLE',
          workflowInUse: workflow.workflowIsInUse,
          edgeCardVisible
        }
      })
    }

    if (jobReference.jobReferenceResults === null || jobReference.jobReferenceResults.length === 0) {
      newEdges.push(endEdge(jobReference, 1, 'OK', workflow.workflowIsInUse, edgeCardVisible))
      newEdges.push(endEdge(jobReference, 2, 'NOT_OK', workflow.workflowIsInUse, edgeCardVisible))
      return
    }
    if (jobReference.jobReferenceResults.length === 1) {
      newEdges.push(endEdge(jobReference, 1, jobReference.jobReferenceResults[0].operationResult === 'OK' ? 'NOT_OK' : 'OK', workflow.workflowIsInUse, edgeCardVisible))
    }

    jobReference.jobReferenceResults.forEach((jobReferenceResult) => {
      const nextJobReference = workflow.jobReferences.find((jobReference) => jobReference.id === jobReferenceResult.nextJobReference)

      if (!addStartEndNodes && !nextJobReference) {
        return
      }

      const edge = {
        id: jobReference.id + '-' + (jobReferenceResult.operationResult === 'OK' ? 'OK' : 'NOT_OK'),
        source: jobReference.technology.name + '-' + jobReference.id,
        target: nextJobReference ? nextJobReference.technology.name + '-' + nextJobReference.id : 'end',
        sourceHandle: jobReferenceResult.operationResult === 'OK' ? 'OK' : 'NOT_OK',
        targetHandle: 'target',
        type: 'custom',
        data: {
          connectivity: jobReferenceResult.connectivity,
          selected: false,
          type: jobReferenceResult.operationResult === 'OK' ? 'OK' : 'NOT_OK',
          workflowInUse: workflow.workflowIsInUse,
          edgeCardVisible
        }
      }
      newEdges.push(edge)
    })
  })

  const returnValue = { nodes: newNodes, edges: newEdges, maxJobIdNum: maxJobIdNum + 1 }

  return returnValue
}

export default getWorkflowNodesAndEdges
