import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

import {
  Typography,
  Grid,
  IconButton,
  Box,
  TextField,
  Stack,
  Alert,
  Collapse
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { useNavigate, useLocation } from 'react-router-dom'
import { apiGet, apiPost } from '../../generic/Api_Functions'
import CompassButton from '../Common/CompassButton/CompassButton'
import CompassWorkerSelector from '../Common/CompassButton/CompassWorkerSelector'
import CompassWindow from '../Common/CompassWindow/CompassWindow'

const SupervisionIncident = () => {
  const location = useLocation()
  const device = location.state.device
  const navigate = useNavigate()
  const { t_ } = useSmartTranslation()

  const [incidences, setIncidences] = useState([])
  const [selectedIncidenceType, setSelectedIncidenceType] = useState()
  const [workersSel, setWorkersSel] = useState(null)

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('error')

  const [errorMessage, setErrorMessage] = useState({ show: false })
  const [estimatedDuration, setEstimatedDuration] = useState('00:00:00')
  const [durationError, setDurationError] = useState(false)

  const [data, setData] = useState({
    incidence: null,
    startDate: null,
    endDate: null,
    comment: '',
    device: null,
    status: null
  })
  const goBack = () => navigate('/compass/supervision')

  const fetchdata = useCallback(async () => {
    const incidences = await apiGet('incidencesByDeviceAndDeviceStatus/' + device.id)
    setIncidences(incidences)
  }, [device.id])

  useEffect(() => {
    if (data.device === null) {
      setData({ ...data, device: device.name, status: device.status })
    }
  }, [device, data])

  useEffect(() => {
    if (device.id) {
      fetchdata()
    }
  }, [device, fetchdata])

  const formLabelStyle = {
    fontSize: '2rem'
  }

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  /*
  const handleClearInput = () => {
    setData({ ...data, incidence: null, startDate: null, endDate: null, comment: '', device: null, status: null })
    setSelectedIncidenceType(null)
    if (device?.workers?.length > 1) setWorkersSel(null)
  } */

  const save = () => {
    if (data.incidence.unavailableCalendarEvent && (data.incidence.estimatedDuration === null || data.incidence.estimatedDuration === '')) {
      setMessage(t_('Estimated duration cannot be null because the incidence generates an unavailable calendar event'))
      setSeverity('error')
      setOpen(true)
      return
    }
    if (data.incidence.estimatedDuration === '00:00' || data.incidence.estimatedDuration === '0:00') {
      setMessage(t_('Estimated duration cannot be null because the incidence generates an unavailable calendar event'))
      setSeverity('error')
      setOpen(true)
      return
    }
    const createIncidence = () => {
      apiPost('compass/create/incidence/' + device.id, data).then(() => {
        setMessage(t_('The operation has been completed successfully.'))
        setSeverity('success')
        setOpen(true)
        goBack()
      }).catch((error) => {
        if (error.message) {
          setMessage(error.message)
        } else {
          setMessage(t_('An error has occurred, please check the data and retry the operation.'))
        }
        setSeverity('error')
        setOpen(true)
      })
    }

    if (device?.workers?.length === 0) {
      setMessage(t_('There are no workers working on this device'))
      setSeverity('error')
      setOpen(true)
      return
    }
    if (!workersSel) {
      setMessage(t_('Select a worker'))
      setSeverity('error')
      setOpen(true)
      return
    }
    if (data.incidence === null) {
      setMessage(t_('Select a incidence'))
      setSeverity('error')
      setOpen(true)
      return
    }
    createIncidence()
  }
  const changeEstimatedDuration = (value) => {
    setEstimatedDuration(value)
    data.incidence.estimatedDuration = value
    // Check if it has the format HH:mm
    const timeRegex = /^(\d+|0\d+|1\d+|2[0-3]):[0-5][0-9]$/
    setDurationError(!timeRegex.test(value) || (value === '00:00' || value === '0:00'))
  }

  const incidenceTypes = useMemo(() => {
    const types = []
    incidences.forEach((incidence) => {
      if (!types.find((item) => item.id === incidence.incidenceType.id)) {
        types.push(incidence.incidenceType)
      }
    })
    return types
  }, [incidences])

  return (
    <>
      <CompassWindow
        message={errorMessage}
        onCloseMessage={() => setErrorMessage({ ...errorMessage, open: false })}
        onClose={goBack}
        containerWidth='lg'
        renderTitle={
          <>
            <Typography color='primary' sx={{ textAlign: 'center', padding: '5px 25px ' }}> {t_('Status')} </Typography>
            <Typography color='primary' sx={{ textAlign: 'center' }}> {t_('Asset')} : {device.name}</Typography>
          </>
      }
        render={
          <Box sx={{ width: '100%' }}>
            <Stack spacing={2} justifyContent='center' alignItems='left'>
              <Box sx={{ width: '100%' }}>
                <Collapse in={open}>
                  <Alert
                    variant='filled' severity={severity} style={{ color: 'white' }}
                    action={
                      <IconButton aria-label='close' color='inherit' size='small' onClick={() => { setOpen(false) }}>
                        <CloseIcon fontSize='inherit' />
                      </IconButton>
                }
                    sx={{ mb: 2 }}
                  >
                    {message}
                  </Alert>
                </Collapse>
              </Box>
              <CompassWorkerSelector workers={device?.workers} selectedWorker={workersSel} onChange={(worker) => setWorkersSel(worker)} />
              <Grid container justifyContent='left' alignItems='center' spacing={1}>
                <Grid item xs={12}>
                  <Typography color='primary' sx={{ textAlign: '' }}>{t_('Incidence Types')}:</Typography>
                </Grid>
                {incidenceTypes?.map(type => (
                  <Grid
                    item
                    xs={4}
                    key={type.id}
                    style={{ height: '6rem' }}
                  >
                    <CompassButton
                      isSelected={selectedIncidenceType && selectedIncidenceType.id === type.id}
                      label={type.name}
                      labelStyle={formLabelStyle}
                      color='secondary'
                      isSelectedColor='primary'
                      onClick={() => {
                        setSelectedIncidenceType(type)
                        setData({ ...data, incidence: null })
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container justifyContent='space-between' alignItems='center' spacing={1}>
                <Grid item xs={12}>
                  <Typography color='primary' sx={{ textAlign: '' }}>{t_('Incidences')}:</Typography>
                </Grid>
                {selectedIncidenceType && incidences
                  ?.filter(incidence => incidence.incidenceType.id === selectedIncidenceType.id)
                  .map(incidence => (
                    <Grid
                      item xs={4}
                      key={incidence.id}
                      style={{ height: '6rem' }}
                    >
                      <CompassButton
                        isSelected={data.incidence && data.incidence.id === incidence.id}
                        label={incidence.name}
                        labelStyle={formLabelStyle}
                        color='secondary'
                        isSelectedColor='primary'
                        onClick={() => { setEstimatedDuration(incidence.estimatedDuration); setData({ ...data, incidence }); setDurationError(false) }}
                      />
                    </Grid>
                  ))}
              </Grid>
              {data != null && data.incidence !== null && data.incidence.estimatedDuration !== null
                ? (
                  <TextField disabled={!data.incidence.unavailableCalendarEvent} error={durationError} label={t_('Estimated duration') + ' (HH:mm)'} fullWidth variant='filled' margin='normal' value={estimatedDuration} name='estimatedDuration' onChange={(event) => { changeEstimatedDuration(event.target.value) }} />
                  )
                : null}
              <TextField label={t_('Comment')} fullWidth variant='filled' margin='normal' value={data.comment} name='comment' onChange={handleInputChange} rows={4} multiline />
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ height: '6rem' }}>
                <Grid item xs={6} style={{ paddingLeft: '0' }}>
                  <CompassButton label={t_('ACCEPT')} labelStyle={formLabelStyle} onClick={save} color='primary' />
                </Grid>
                <Grid item xs={6}>
                  <CompassButton label={t_('CANCEL')} labelStyle={formLabelStyle} onClick={goBack} color='secondary' />
                </Grid>
              </Grid>
            </Stack>
          </Box>
      }
      />
    </>

  )
}
export default SupervisionIncident
