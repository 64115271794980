import React, { useState } from 'react'
import { apiGet } from '../../../generic/Api_Functions'
import SmartMateriaUITable from '../../../generic/SmartMateriaUITable'
import { DefaultAccordion } from '../../../generic/DefaultAccordion'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'
import PartHistoryHeader from './PartHistoryHeader'
import { useSmartInterval } from '../../../generic/hooks/useSmartInterval'
import { Chip, Stack, Tooltip, Typography } from '@mui/material'

// Import typedefs from another file
/**
 * @typedef {import('../../../generic/utilities/objectStructures/partWindow/part').PartHistoryHeader} PartHistoryHeader PartHistoryHeader object
 */

/**
 *
 * @param {Object} props general props object
 * @param {BigInt} props.partId Part object id number
 * @returns
 */
const PartHistory = (props) => {
  const { t_ } = useSmartTranslation()

  /** @type {[PartHistoryHeader, React.Dispatch<PartHistoryHeader>]} */
  const [part, setPart] = useState(null)

  useSmartInterval(
    async () => {
      if (props.partId === null) {
        return null
      }
      return await apiGet('parts/' + props.partId)
    }, 1000,
    (data) => {
      if (data != null) {
        setPart(data)
      }
    }
  )

  const getJobTableData = async (pagination) => {
    if (props.partId != null) {
      const jobData = await apiGet('partWorkflowHistory/' + props.partId, pagination)
      return jobData
    }
  }

  const getMoveTableData = async (pagination) => {
    if (props.partId != null) {
      const moveData = await apiGet('partFixtureMoveHistory/' + props.partId, pagination)
      return moveData
    }
  }

  const columnsJobs = [
    {
      name: t_('Operation Result'),
      field: 'operationResult',
      render: (opResult) => {
        return (
          <>
            <span>{t_(opResult.operationResult)}</span>
          </>
        )
      }
    },
    {
      name: t_('Job'),
      field: 'technology',
      render: (job) => {
        return (
          <>
            <span>{t_(job.technology)}</span>
          </>
        )
      }
    },
    {
      name: t_('Asset'),
      field: 'device.name'
    },
    {
      name: t_('Program'),
      field: 'programs.name',
      render: (row) => {
        if (row.programs.length === 0) {
          return ''
        }
        if (row.programs.length === 1) {
          return row.programs[0]
        }
        return <Tooltip title={<Stack direction='column' gap={1}>{row.programs.filter((item, index) => index !== 0).map((item) => { return <Typography key={item}>{item}</Typography> })}</Stack>}><Stack direction='row' justifyContent='center'>{row.programs[0]}<Chip label={'+' + (row.programs.length - 1)} /></Stack></Tooltip>
      }
    },
    {
      name: t_('Date start'),
      field: 'dateStart',
      type: 'dateTimePicker'
    },
    {
      name: t_('Date end'),
      field: 'dateEnd',
      type: 'dateTimePicker'
    },
    {
      name: t_('Duration'),
      field: 'duration',
      sortable: false
    }
  ]

  const columnsMovement = [
    {
      name: t_('From'),
      field: 'deviceFrom',
      sortable: false
    },
    {
      name: t_('To'),
      field: 'deviceTo',
      sortable: false
    },
    {
      name: t_('Handler'),
      field: 'handler'
    },
    {
      name: t_('Date start'),
      field: 'dateStart',
      type: 'dateTimePicker'
    },
    {
      name: t_('Date end'),
      field: 'dateEnd',
      type: 'dateTimePicker'
    },
    {
      name: t_('Duration'),
      field: 'duration',
      sortable: false
    }
  ]

  const sortOptions = {
    default: {
      field: 'dateEnd',
      direction: -1
    }
  }
  return (
    <div style={{ padding: '2rem' }}>{part !== null
      ? (
        <><PartHistoryHeader part={part} />
          <DefaultAccordion title={t_('JOBS')}>
            <SmartMateriaUITable
              columns={columnsJobs}
              dataFetch={getJobTableData}
              pageable
              sortOptions={sortOptions}
              sticky={false}
            />
          </DefaultAccordion>
          <DefaultAccordion title={t_('MOVES')}>
            <SmartMateriaUITable
              columns={columnsMovement}
              dataFetch={getMoveTableData}
              pageable
              sortOptions={sortOptions}
              sticky={false}
            />
          </DefaultAccordion>
        </>
        )
      : null}
    </div>
  )
}

export default PartHistory
