import React from 'react'
import SmartNavigator from '../generic/SmartNavigator'
import Planner from '../planner/Planner'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
const MainPlanner = () => {
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('Planning'),
      component: <Planner />
    }
  ]
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  )
}

export default MainPlanner
