import * as React from 'react';

import DefaultSortableRenderItem from '../generic/smartSortable/GenericSortableRenderItem';
import SmartSortableContainer from '../generic/smartSortable/SmartSortableContainer';
const ArrangeHomeDevices = (props) => {

  const { devices, sortingChange, setVisibility } = props;


  const ArrangeDeviceItem = ({ item, index }) => {
    return <DefaultSortableRenderItem labels={[item.deviceType.name, item.name]} itemId={item.id} hideShow={true} visibility={item.homeVisible} setVisibility={setVisibility} />;
  }
  const onSortChanged = (items) => {
    if (sortingChange && typeof sortingChange === 'function') {
      sortingChange(items)
    }
  }
  return (<div style={{ width: '100%', margin: 'auto' }}>
    <SmartSortableContainer itemList={devices} itemId='id' renderItem={ArrangeDeviceItem} onSortChanged={onSortChanged} />
  </div>);
};

export default ArrangeHomeDevices;
