import React from 'react'

import cnc from '../../../images/CNC.png'
import edm from '../../../images/EDM.png'
import cmm from '../../../images/CMM.png'
import storage from '../../../images/Storage.png'
import handler from '../../../images/Handler.png'
import polish from '../../../images/Polish.png'
import operator from '../../../images/OPERATOR.png'
import camera from '../../../images/CAMERA.png'
import marking from '../../../images/MARKING.png'
import cleaning from '../../../images/CLEANING.png'
import treatment from '../../../images/TREATMENT_CENTER.png'

export default function IcoMachine ({ machineType }) {
  const getMachineType = () => {
    let icon
    switch (machineType) {
      case 'MACHINING_CENTER': icon = cnc; break
      case 'EDM': icon = edm; break
      case 'CMM': icon = cmm; break
      case 'STORAGE': icon = storage; break
      case 'HANDLER': icon = handler; break
      case 'POLISHING_CENTER': icon = polish; break
      case 'OPERATOR': icon = operator; break
      case 'IDENTIFICATION': icon = camera; break
      case 'MARKING': icon = marking; break
      case 'CLEANING_CENTER': icon = cleaning; break
      case 'TREATMENT_CENTER': icon = treatment; break
      default: break
    }
    return icon
  }

  return (
    <>
      <img src={getMachineType()} style={{ maxWidth: '100%', maxHeight: '100%' }} alt='assetIcon' />
    </>
  )
}
