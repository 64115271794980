import { Checkbox, Stack, Autocomplete, TextField, Divider } from "@mui/material";
import { useState, useEffect, useReducer, useRef } from 'react';
import { apiGet } from "../../generic/Api_Functions";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircularProgress from '@mui/material/CircularProgress';
import AlertUI from "../../generic/AlertUI";
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation';


const FixtureLinks = (props) => {

  const { links, onLinksChanged, errorMessage } = props;

  const [dataLoaded, setDataLoaded] = useState(false);
  const [AlertElement, showAlert] = AlertUI();
  const showErrorRef = useRef(showAlert)

  const noneId = "NONE";
  const allId = "ALL";
  const { t_ } = useSmartTranslation();

  const getLinkAllVariableName = (variableName) => {
    return "linkAll" + variableName.charAt(0).toUpperCase() + variableName.slice(1);
  }

  useEffect(() => {
    if (errorMessage !== undefined && errorMessage !== null && errorMessage !== "") {
      showErrorRef.current(
        {
          title: errorMessage,
          severity: "warning"
        }
      );
    }

  }, [errorMessage])

  const filterValidToolTypes = (toolTypes, newState) => {
    if (newState.linkAllToolGroups) {
      // All allowed
      return toolTypes;
    }
    return toolTypes.filter((toolType) => toolType.id === allId || newState.toolGroups.find((item) => item.id === toolType.toolGroup.id))
  }

  const filterValidToolReferences = (toolReferences, newState) => {
    if (newState.linkAllToolTypes && newState.linkAllToolGroups) {
      return toolReferences;
    }
    if (newState.linkAllToolTypes) {
      // Must select any from the tooltypes from the selected groups
      return toolReferences.filter((toolReference) => toolReference.id === allId || newState.toolGroups.find((item) => item.id === toolReference.toolType.toolGroup.id));
    }
    return toolReferences.filter((toolReference) => toolReference.id === allId || newState.toolTypes.find((item) => toolReference.toolType.id === item.id));
  }

  const currentDataAction = (state, action) => {
    if (action.type === "LINKS_LOADING") {
      return null;
    }
    if (action.type === "LINKS_LOADED") {
      onLinksChanged(action.value);
      return { ...action.value };
    }
    if (action.type === "RESET_LINKS") {
      onLinksChanged(action.value);
      return {
        rawMaterialReferences: [],
        partReferences: [],
        fixtureReferences: [],
        toolGroups: [],
        toolTypes: [],
        toolReferences: [],
        devices: [],
        linkAllRawMaterialReferences: false,
        linkAllPartReferences: false,
        linkAllFixtureReferences: false,
        linkAllToolGroups: false,
        linkAllToolTypes: false,
        linkAllToolReferences: false,
        linkAllDevices: false,
      };
    }
    if (action.type === "SET_NEW_DATA") {
      let newState = { ...state };

      let newValues = action.value;
      if (newValues.length > 0) {
        // Find the last selected option
        let lastSelected = newValues.filter((item => !state[action.dataType].find((item2) => item.id === item2.id)));
        // Check if previously was all selected
        if (state[getLinkAllVariableName(action.dataType)]) {
          lastSelected = lastSelected.filter((item) => item.id !== allId);
          newValues = newValues.filter((item) => item.id !== allId);
        }
        if (lastSelected.length > 0) {
          if (lastSelected[0].id === allId || lastSelected[0].id === noneId) {
            newValues = [];
          }
          newState[getLinkAllVariableName(action.dataType)] = (lastSelected[0].id === allId);
        }
      } else {
        newState[getLinkAllVariableName(action.dataType)] = false;
      }

      newState[action.dataType] = newValues;

      // Check tool group
      if (action.dataType === "toolGroups") {
        if (newValues.length === 0 && !newValues.linkAllToolGroups) {
          // If nothing selected remove tooltype and tool selection
          newState.toolTypes = [];
          newState.toolReferences = [];
          newState.linkAllToolTypes = false;
          newState.linkAllToolReferences = false;
        } else {
          // Check if tooltype and toolreferences are still valid
          newState.toolTypes = filterValidToolTypes(newState.toolTypes, newState);
          newState.toolReferences = filterValidToolReferences(newState.toolReferences, newState);

          // Set to all if nothing selected on type
          if (newState.toolTypes.length === 0) {
            newState.toolTypes = [];
            newState.linkAllToolTypes = true;
          }

          // Set to all if nothing is selected on tool reference
          if (newState.toolReferences.length === 0) {
            newState.toolReferences = [];
            newState.linkAllToolReferences = true;
          }
        }
      }

      // Check tool type
      if (action.dataType === "toolTypes") {
        if (newValues.length === 0 && !newValues.linkAllToolGroups) {
          newState.toolReferences = [];
          newState.linkAllToolReferences = false;
        }
        // Check if toolreferences are still valid
        newState.toolReferences = filterValidToolReferences(newState.toolReferences, newState);
      }
      onLinksChanged(newState);
      return newState;
    }
  }

  // Load new links for selected items
  useEffect(() => {
    const loadLinks = async () => {
      if (!links || links.length === 0) {
        // Nothing to do
        return;
      }
      // Load the item links
      if (links.length === 1) {
        dispatchCurrentData({ type: "LINKS_LOADING" });
        let loadedLinks = await apiGet("fixtureReferences/" + links[0].id + "/links");
        dispatchCurrentData({ type: "LINKS_LOADED", value: loadedLinks });
      } else {
        // If the selected items are multiple. We simply set all to default values
        dispatchCurrentData({ type: "RESET_LINKS" });
      }
    }
    loadLinks();
  }, [links]);

  const [currentData, dispatchCurrentData] = useReducer(currentDataAction, null);

  const onSelectionChanged = (dataType, newValues) => {
    dispatchCurrentData({ type: "SET_NEW_DATA", dataType: dataType, value: newValues });
  }

  // Load initial data
  useEffect(() => {
    const addNoneAndAllOptions = (options, onlyAll) => {
      if (onlyAll) {
        return [
          { id: allId, name: t_("All") },
          ...options
        ]
      }
      return [
        { id: noneId, name: t_("None") },
        { id: allId, name: t_("All") },
        ...options
      ]
    }

    const initialDataLoad = async () => {

      let loadedData = {
        rawMaterial: addNoneAndAllOptions(await apiGet('rawMaterialsReferenceIdCodeName')),
        part: addNoneAndAllOptions((await apiGet('partReferencesDto'))),
        fixture: addNoneAndAllOptions(await apiGet('fixtureReferencesIdCodeName')),
        toolGroups: addNoneAndAllOptions(await apiGet('toolGroupsBasicData')),
        originalToolTypes: addNoneAndAllOptions(await apiGet('allToolTypesBasicData'), true),
        originalToolReferences: addNoneAndAllOptions(await apiGet('allToolReferencesBasicData'), true),
        device: addNoneAndAllOptions(await apiGet('deviceIdCodeName')),
        dataLoaded: true,
      }
      setDataLoaded(loadedData);
    }
    initialDataLoad();
  });

  const possibleToolTypes = dataLoaded && currentData ? filterValidToolTypes(dataLoaded.originalToolTypes, currentData) : [];
  const possibleToolReferences = dataLoaded && currentData ? filterValidToolReferences(dataLoaded.originalToolReferences, currentData) : [];

  const renderOption = (props, option, { selected }, dataType) => {
    let checked = selected;
    if (currentData) {
      if (currentData[getLinkAllVariableName(dataType)]) {
        checked = option.id !== noneId;
      } else if (currentData[dataType].length === 0) {
        checked = option.id === noneId;
      }
    }
    props['aria-selected'] = checked;
    return (
      <li {...props}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={checked}
        />
        {option.name}
      </li>
    );
  }

  const getValueForOption = (optionsVariableName) => {
    if (currentData[getLinkAllVariableName(optionsVariableName)]) {
      return [{ id: allId, name: t_("All") }]
    } else {
      return currentData[optionsVariableName];
    }
  }

  const renderInput = (params, label, error) => {
    return (
      <TextField
        {...params}
        variant="standard"
        label={label}
        error={error}
      />
    )
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const toolTypeDisabled = currentData && !currentData.linkAllToolGroups && currentData.toolGroups?.length === 0;
  const toolReferencesDisabled = currentData && ((!currentData?.linkAllToolGroups && currentData?.toolGroups.length === 0) || (!currentData?.linkAllToolTypes && currentData?.toolTypes?.length === 0));

  return (
    <>
      {AlertElement}
      {dataLoaded.dataLoaded && currentData !== null ?
        <div>
          <fieldset style={{ padding: "30px", marginBottom: "2em" }}><legend style={{ textAlign: "left" }}>{t_("Resources")}</legend>
            <Stack gap={3}>
              <Autocomplete
                multiple
                options={dataLoaded.rawMaterial}
                limitTags={5}
                value={getValueForOption("rawMaterialReferences")}
                onChange={(event, values) => onSelectionChanged("rawMaterialReferences", values)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderOption={((props, option, { selected }) => renderOption(props, option, { selected }, "rawMaterialReferences"))}
                renderInput={(params) => renderInput(params, t_("Raw Material"), false)}
              />
              <Autocomplete
                multiple
                options={dataLoaded.part}
                limitTags={5}
                value={getValueForOption("partReferences")}
                onChange={(event, values) => onSelectionChanged("partReferences", values)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderOption={((props, option, { selected }) => renderOption(props, option, { selected }, "partReferences"))}
                renderInput={(params) => renderInput(params, t_("Part"), false)}
              />
              <Autocomplete
                multiple
                options={dataLoaded.fixture}
                value={getValueForOption("fixtureReferences")}
                limitTags={5}
                onChange={(event, values) => onSelectionChanged("fixtureReferences", values)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderOption={((props, option, { selected }) => renderOption(props, option, { selected }, "fixtureReferences"))}
                renderInput={(params) => renderInput(params, t_("Fixture"), false)}
              />
              <Stack direction="row" justifyContent="space-between" alignItems="end" gap={3}>

                <Autocomplete
                  multiple
                  style={{ minWidth: '12rem' }}
                  options={dataLoaded.toolGroups}
                  limitTags={2}
                  value={getValueForOption("toolGroups")}
                  onChange={(event, values) => onSelectionChanged("toolGroups", values)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => t_(option.name)} // Aplica la traducción al nombre de la opción
                  renderOption={((props, option, { selected }) => renderOption(props, { ...option, name: t_(option.name) }, { selected }, "toolGroups"))} // Aplica la traducción al nombre de la opción en el menú desplegable
                  renderInput={(params) => renderInput(params, t_("Tool group"), false)} // Aplica la traducción al título del campo
                />

                <Autocomplete
                  multiple
                  style={{ minWidth: '12rem' }}
                  limitTags={2}
                  options={possibleToolTypes}
                  disabled={toolTypeDisabled}
                  value={getValueForOption("toolTypes")}
                  onChange={(event, values) => onSelectionChanged("toolTypes", values)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => t_(option.name)}
                  renderOption={((props, option, { selected }) => renderOption(props, { ...option, name: t_(option.name) }, { selected }, "toolTypes"))}
                  renderInput={(params) => renderInput(params, t_("Tool type"), (errorMessage && !toolTypeDisabled && !currentData?.linkAllToolTypes && currentData?.toolTypes.length === 0))}
                />
                <Autocomplete
                  multiple
                  style={{ minWidth: '12rem' }}
                  limitTags={2}
                  options={possibleToolReferences}
                  disabled={toolReferencesDisabled}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(toolReference) => t_(toolReference.name)}
                  value={getValueForOption("toolReferences")}
                  onChange={(event, values) => onSelectionChanged("toolReferences", values)}
                  renderOption={((props, option, { selected }) => renderOption(props, { ...option, name: t_(option.name) }, { selected }, "toolReferences"))}
                  renderInput={(params) => renderInput(params, t_("Tool"), (errorMessage && !toolReferencesDisabled && !currentData?.linkAllToolReferences && currentData?.toolReferences.length === 0))}
                />
              </Stack>
            </Stack>
          </fieldset>
          <Divider />
          <fieldset style={{ padding: "30px", marginTop: "2em" }}><legend style={{ textAlign: "left" }}>{t_("Assets")}</legend>

            <Autocomplete
              multiple
              options={dataLoaded.device}
              limitTags={5}
              value={getValueForOption("devices")}
              onChange={(event, values) => onSelectionChanged("devices", values)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              renderOption={((props, option, { selected }) => renderOption(props, option, { selected }, "devices"))}
              renderInput={(params) => renderInput(params, t_("Asset"), false)}
            />

          </fieldset>
        </div>
        : <CircularProgress />}
    </>

  );
}

export default FixtureLinks;
