import { apiDelete, apiPut, apiGet } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import AddElement from "../../generic/smartAddElement/AddElement";
import GenericToggleGroup from '../../generic/buttons/toggleButtonGroups/GenericToggleGroup'
import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import { useToggle } from '../../generic/hooks/useToggle';
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const RawMaterial = (props) => {

  const { endpoint, reference, hideColumns, onRowClick } = props
  let parallelepiped = "PARALLELEPIPED";
  let cylindrical = "CYLINDRICAL";
  const disableRowActions = useRef(false)
  const [editingRow, setEditingRow] = useState(null)

  const [viewMode, setViewMode] = useState('NOT ASSIGNED');
  const forceLoad = useRef(false)
  const [toggle, resetPage] = useToggle()
  const { t_ } = useSmartTranslation();

  const toolbarButtonsInfo = {
    all: {
      value: 'ALL',
      color: 'info',
      content: t_('ALL')
    },
    notProcessed: {
      value: 'NOT PROCESSED',
      color: 'warning',
      content: t_('NOT PROCESSED')
    },
    processed: {
      value: 'PROCESSED',
      color: 'success',
      content: t_('PROCESSED')
    },
    notAssigned: {
      value: 'NOT ASSIGNED',
      color: 'secondary',
      content: t_('NOT ASSIGNED')
    }
  }


  const columns = [{
    name: t_("Code"),
    field: "code",
    readOnly: true,
    charLimit: 60,
    mandatory: true,
    unique: true
  },
  {
    name: t_("Name"),
    field: "name",
    charLimit: 80,
    mandatory: true,
  },
  {
    name: t_("Description"),
    field: "description",
    charLimit: 200,
  },
  {
    name: t_("Status"),
    field: "status",
    readOnly: true,
    render: (row) => {
      return t_(row.status);
    }
  },
  {
    name: t_("X Length (mm)"),
    field: "lengthX",
    readOnly: (rowData) => {
      return readOnlyCondition(rowData, parallelepiped);
    },
    mandatory: (rowData) => {
      return mandatoryCondition(rowData, parallelepiped);
    },
    reset: (rowData) => {
      return readOnlyCondition(rowData, parallelepiped);
    },
    numeric: true,
    hide: reference.shape && reference.shape.code === 'PARALLELEPIPED' ? false : true
  },
  {
    name: t_("Y Length (mm)"),
    field: "lengthY",
    readOnly: (rowData) => {
      return readOnlyCondition(rowData, parallelepiped);
    },
    mandatory: (rowData) => {
      return mandatoryCondition(rowData, parallelepiped);
    },
    reset: (rowData) => {
      return readOnlyCondition(rowData, parallelepiped);
    },
    numeric: true,
    hide: reference.shape && reference.shape.code === 'PARALLELEPIPED' ? false : true
  },
  {
    name: t_("Z Length (mm)"),
    field: "lengthZ",
    readOnly: (rowData) => {
      return readOnlyCondition(rowData, parallelepiped);
    },
    mandatory: (rowData) => {
      return mandatoryCondition(rowData, parallelepiped);
    },
    reset: (rowData) => {
      return readOnlyCondition(rowData, parallelepiped);
    },
    numeric: true,
    hide: reference.shape && reference.shape.code === 'PARALLELEPIPED' ? false : true
  },
  {
    name: t_("Diam (mm)"),
    field: "diameter",
    readOnly: (rowData) => {
      return readOnlyCondition(rowData, cylindrical);
    },
    mandatory: (rowData) => {
      return mandatoryCondition(rowData, cylindrical);
    },
    reset: (rowData) => {
      return readOnlyCondition(rowData, cylindrical);
    },
    numeric: true,
    hide: reference.shape && reference.shape.code === 'CYLINDRICAL' ? false : true
  },
  {
    name: t_("Length (mm)"),
    field: "length",
    readOnly: (rowData) => {
      return readOnlyCondition(rowData, cylindrical);
    },
    mandatory: (rowData) => {
      return mandatoryCondition(rowData, cylindrical);
    },
    reset: (rowData) => {
      return readOnlyCondition(rowData, cylindrical);
    },
    numeric: {
      default: 0,
    },
    hide: reference.shape && reference.shape.code === 'CYLINDRICAL' ? false : true
  },
  {
    name: t_("Location"),
    field: "location.name",
    readOnly: true,
  },
  {
    name: t_("Shelf"),
    field: "shelveStorageConfiguration.shelve",
    readOnly: true,
    hide: hideColumns,
  },
  {
    name: t_("Row"),
    field: "shelveStoragePosition.position",
    readOnly: true,
    hide: hideColumns,
  },
  {
    name: t_("Column"),
    field: "shelveStoragePosition.depth",
    readOnly: true,
    hide: hideColumns,
  },

  {
    name: t_("Fixture"),
    field: "fixture.code",
    type: "select",
    edit: {
      source: async () => {
        if (editingRow !== null)
          return await apiGet("fixturesAvailableByRawMaterial/" + editingRow.id);
      },
      id: "id",
      field: "code",
    },
  },
  {
    name: t_("Assigned"),
    field: "part.code",
    readOnly: true
  },
  {
    name: t_("Entry date"),
    field: "entryDate",
    type: "dateTimePicker",
    readOnly: false,

    mandatory: true,
  }
  ]

  const toggleAssigned = (value) => {
    setViewMode(value);
    forceLoad.current = !forceLoad.current
    resetPage()
  }

  const mandatoryCondition = (row, property) => {
    if (reference.shape.code === property) return true;
    return false;
  };

  const readOnlyCondition = (row, property) => {
    if (
      reference.shape === undefined ||
      reference.shape === null ||
      reference.shape.code !== property ||
      reference.shape.code === "" ||
      reference.shape.code === undefined
    ) {
      return true;
    }
    return false;
  };

  const actions = {
    edit: {
      fetchFunction: (row) => {
        /*    if (row.entryDate) {
              let date = moment(row.entryDate).format("YYYY-MM-DD[T]HH:mm:ssZ")
              row.entryDate = date
            }
        */
        return apiPut("rawMaterials", row)
      },
      visibilityButton: (rawMaterial) => !rawMaterial.workflowStarted,
    },
    delete: {
      fetchFunction: (rows) => {
        rows.forEach(element => {
          element.entryDate = null;
        });

        if (rows.length === 1) {
          return apiDelete("rawMaterials/" + rows[0].id, rows)
        }
        else {
          return apiDelete("rawMaterialsMulti", rows)
        }
      },
      visibilityButton: (rawMaterial) => !rawMaterial.part,
    }
  }


  const toolbar = {
    render: {
      fetchFunction: (extraFunctions) => {
        return <>
          <GenericToggleGroup
            initialValue={viewMode}
            buttons={[toolbarButtonsInfo.notAssigned, toolbarButtonsInfo.notProcessed, toolbarButtonsInfo.processed, toolbarButtonsInfo.all]}
            exclusive
            onChange={(value) => toggleAssigned(value)}
          />
          <Box component="span" sx={{ flexGrow: 1, textAlign: "end" }}>
            <AddElement
              parameter={reference.id}
              endpoint={endpoint}
              referenceName={reference.name}
              title={t_("Add raw materials")}
              onAlert={extraFunctions.showAlert}
            /></Box>
        </>
      }
    },
  }

  const onEditIndexChanged = (editIndex, row) => {
    if (editIndex >= 0) {
      setEditingRow(row);
    }
  }

  const dataFetch = async () => {
    return await apiGet(endpoint + "/" + reference.id + "/" + viewMode.replaceAll(' ', '_'))
  };

  return (
    <div>
      <SmartMateriaUITable
        columns={columns}
        title={reference.name}
        dataFetch={dataFetch}
        actions={actions}
        multipleSelection={!hideColumns}
        disableFlexGrow
        toolbar={toolbar}
        forceLoad={forceLoad.current}
        setDisableRowActions={(event) => disableRowActions.current = event}
        onEditIndexChanged={onEditIndexChanged}
        resetPage={toggle}
        onRowClick={(row) => {
          if (onRowClick && typeof onRowClick === 'function' && row.id) {
            onRowClick(row)
          }
        }}
      />
    </div>
  )
}

export default RawMaterial;
