import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography, useTheme, TextField, IconButton } from "@mui/material";
import { Save, Cancel, Edit, DeleteOutlineRounded, AddCircleRounded } from '@mui/icons-material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DndContext } from '@dnd-kit/core';
import SmartDroppableItem from './SmartDroppableItem';
import SmartDraggableItem from './SmartDraggableItem';
import AlertUI from "../../generic/AlertUI";
import SmartSearch from '../../generic/formComponent/Search';
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const SmartGroupCreator = (props) => {
  const { t_ } = useSmartTranslation();
  const { errorMessage, onChange, grouped, ungrouped, keys } = props;
  const [editingGroupId, setEditingGroupId] = useState(null);
  const [editedGroupNames, setEditedGroupNames] = useState({});
  const [AlertElement, showAlert] = AlertUI();
  const showErrorRef = useRef(showAlert);
  const [filterTextUngrouped, setFilterTextUngrouped] = useState('');
  const [filterTextGroups, setFilterTextGroups] = useState('');
  const theme = useTheme();

  const initialize = () => {
    let indexCounter = 1;
    const initilizeItem = (item) => {
      const itemTempId = indexCounter++;
      return {
        tempId: itemTempId,
        parentId: item[keys.itemId],
        text: item[keys.itemText]
      };
    };
    const newGrouped = grouped.map((group) => {
      const groupTempId = indexCounter++;
      return {
        tempId: groupTempId,
        parentId: group[keys.groupId],
        text: group[keys.groupText],
        items: group[keys.groupItems].map((item) => initilizeItem(item))
      };
    });
    const newUngrouped = ungrouped.map((item) => initilizeItem(item));

    return {
      grouped: newGrouped,
      ungrouped: newUngrouped
    };
  };

  const [items, setItems] = useState(initialize());

  useEffect(() => {
    if (!onChange || typeof onChange !== "function") {
      return
    }
    onChange({
      grouped: items.grouped.map((val) => ({
        [keys.groupId]: val.parentId,
        [keys.groupText]: val.text,
        [keys.groupItems]: val.items.map((item) => ({
          [keys.itemId]: item.parentId,
          [keys.itemText]: item.text,
        }))
      })),
      ungrouped: items.ungrouped.map((val) => ({
        [keys.itemId]: val.parentId,
        [keys.itemText]: val.text
      }))
    });
  }, [items, onChange, keys])

  useEffect(() => {
    if (errorMessage !== undefined && errorMessage !== null && errorMessage !== "") {
      showErrorRef.current(
        {
          title: errorMessage,
          message: ' ',
          severity: "error"
        }
      );
    }
  }, [errorMessage])

  const showErrorMessage = (title, message) => {
    showErrorRef.current({
      title: title,
      message: message,
      severity: "error",
    });
  };

  const style = {
    width: '100%',
    border: '1px solid #b8b7b7',
    background: theme.palette.background.default,
    borderRadius: '10px',
    marginBottom: '0.6rem',
    justifyContent: "space-between",
    padding: '0.6rem',
    paddingLeft: "3rem",
    paddingRight: "3rem",
    columnGap: '3rem',
  }

  const itemsStyle = {
    ...style,
    background: theme.palette.background.color_212121,
  }

  const dragBtnStyle = {
    background: theme.palette.background.color_212121,
    border: '1px solid #b8b7b7',
    borderRadius: '5px',
  }

  const handleSearchUngrouped = (items) => {
    let filteredUngroups = [];
    if (items.ungrouped !== undefined & items.ungrouped !== null) {
      filteredUngroups = items.ungrouped.filter((item) =>
        item.text.toLowerCase().includes(filterTextUngrouped.toLowerCase())
      );
    }

    return filteredUngroups && filteredUngroups.length > 0 ? (
      filteredUngroups.map((item) => (
        <SmartDraggableItem key={item.tempId} id={item.tempId} data={{ item: item }} customStyle={{ ...itemsStyle }}>
          {item.text}
        </SmartDraggableItem>
      ))
    ) : (
      <Typography>{t_("No data to display")}</Typography>
    );
  };

  const handleSearchGroups = (groupedData, filterTextGroups) => {
    if (!filterTextGroups) {
      return groupedData;
    }
    const filteredGrouped = Object.keys(groupedData).reduce((filtered, groupId) => {
      const group = groupedData[groupId];
      if (group.text.toLowerCase().includes(filterTextGroups.toLowerCase())) {
        filtered[groupId] = group;
      }
      return filtered;
    }, {});
    return filteredGrouped;
  };

  const handleSaveGroupName = async (groupId) => {
    const editedName = editedGroupNames[groupId];
    const existsName = await isGroupNameUnique(editedName);
    if (!existsName) {
      const groupIndex = items.grouped.findIndex((group) => group.tempId === groupId);
      if (groupIndex !== -1) {
        const updatedGroup = {
          ...items.grouped[groupIndex],
          tempId: items.grouped[groupIndex].tempId,
          text: editedName,
          items: items.grouped[groupIndex].items,
        };
        const updatedGroups = [...items.grouped];
        updatedGroups[groupIndex] = updatedGroup;
        setItems((prevGroups) => ({
          ...prevGroups,
          grouped: updatedGroups,
        }));
      }
      setEditingGroupId(null);
    } else {
      showErrorMessage(t_("Group name already exists"), t_("Please choose another name"));
    }
  };

  const isGroupNameUnique = (name) => {
    return items.grouped.some((group) => group.text === name);
  };

  const handleCancelEditGroupName = () => {
    setEditedGroupNames((prevNames) => ({
      ...prevNames,
      [editingGroupId]: '',
    }));
    setEditingGroupId(null);
  };

  const handleAddGroup = async () => {
    const maxGroupedId = items.grouped.reduce((max, group) => {
      return isNaN(group.tempId) ? max : Math.max(max, group.tempId);
    }, 0);
    const maxUngroupedId = items.ungrouped.reduce((max, item) => {
      return isNaN(item.tempId) ? max : Math.max(max, item.tempId);
    }, 0);

    const maxId = Math.max(maxGroupedId, maxUngroupedId);
    const newGroupId = maxId + 1;

    const newGroup = {
      tempId: newGroupId,
      text: `Group_${newGroupId}`,
      items: [],
    };
    setItems((prevGroups) => ({
      ...prevGroups,
      grouped: [...items.grouped, newGroup],
    }));
  };

  const searchGroupType = (itemId) => {
    const foundInGrouped = items.grouped.find((group) => group.tempId === itemId);
    const foundInUngrouped = items.ungrouped.find((item) => item.tempId === itemId);
    if (foundInGrouped) {
      return "grouped";
    } else if (foundInUngrouped) {
      return "ungrouped";
    } else {
      return null;
    }
  };

  const handleDelete = async (event) => {
    const groupType = searchGroupType(event.active.id);
    if (groupType !== null && groupType !== "ungrouped") {
      if (event.active.data.current.type === 'groups') {
        const groupIndex = items.grouped.findIndex((group) => group.tempId === event.active.id);
        if (groupIndex !== -1) {
          const updatedGroups = [...items.grouped];
          updatedGroups.splice(groupIndex, 1);
          const deletedItems = items.grouped[groupIndex].items;
          setItems((prevGroups) => ({
            ...prevGroups,
            grouped: updatedGroups,
            ungrouped: [...items.ungrouped, ...deletedItems]
          }));
        }
      }
    }
    if (groupType === null) {
      const itemId = event.active.id;
      const groupId = event.active.data.current.groupId;
      const groupIndex = items.grouped.findIndex((group) => group.tempId === groupId);
      if (groupIndex !== -1) {
        const updatedGroups = [...items.grouped];
        const deletedItemIndex = updatedGroups[groupIndex].items.findIndex((item) => item.tempId === itemId);
        if (deletedItemIndex !== -1) {
          const deletedItem = updatedGroups[groupIndex].items[deletedItemIndex];
          updatedGroups[groupIndex].items.splice(deletedItemIndex, 1);
          setItems((prevGroups) => ({
            ...prevGroups,
            grouped: updatedGroups,
            ungrouped: [...items.ungrouped, deletedItem]
          }));
        }
      }
    }
  };

  const handleMoveItem = (event) => {
    if (event.active.data.current.type !== 'groups') {
      if (event.active.data.current.groupId !== event.over.id) {
        let updatedGroups = [...items.grouped];
        let newGroupIndex = updatedGroups.findIndex((group) => group.tempId === event.over.id);
        let oldGroupIndex = updatedGroups.findIndex((group) => group.tempId === event.active.data.current.groupId);
        let newGroup = updatedGroups.find((group) => group.tempId === event.over.id);
        if (newGroup) {
          newGroup.items.push({
            tempId: event.active.data.current.item.tempId,
            parentId: event.active.data.current.item.parentId,
            text: event.active.data.current.item.text,
          });
        }
        updatedGroups[newGroupIndex] = newGroup;
        let oldGroup = updatedGroups.find((group) => group.tempId === event.active.data.current.groupId);
        if (oldGroup) {
          oldGroup.items = oldGroup.items.filter((item) => item.tempId !== event.active.id);
          updatedGroups[oldGroupIndex] = oldGroup;
        } else {
          items.ungrouped = items.ungrouped.filter((item) => item.tempId !== event.active.id);
        }
        setItems((prevGroups) => ({
          ...prevGroups,
          grouped: updatedGroups,
          ungrouped: items.ungrouped
        }));
        return items;
      }
    }
  };

  const handleDragEnd = (event) => {
    if (event.over && event.over.hasOwnProperty('id')) {
      let overId = event.over.id;
      if (overId === 'delete') {
        handleDelete(event);
      } else {
        handleMoveItem(event);
      }
    }
  };

  const printItemsUngrouped = (items) => {
    return (
      <div>
        <div style={{ marginBottom: '1rem' }}>
          <Typography variant="h6">{t_("Features")}</Typography>
          <SmartSearch setFilterText={setFilterTextUngrouped} setWidth="50%" setLoadTable={() => true} loadTable={true} />
        </div>
        <div style={{ ...style, display: 'flex', flexDirection: 'column' }}>
          {handleSearchUngrouped(items)}
        </div>
      </div>
    );
  }

  const printItemsGrouped = (items) => {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <Typography variant="h6">{t_("Groups")}</Typography>
          <Button onClick={handleAddGroup}>
            <AddCircleRounded sx={{ fontSize: '1.2em' }} />
            <Typography sx={{ marginLeft: '0.4em', fontSize: '0.875rem' }} >{t_("ADD GROUP")}</Typography>
          </Button>
          <SmartSearch setFilterText={setFilterTextGroups} setLoadTable={() => true} loadTable={true} />
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
          {Object.keys(items.grouped) && Object.keys(items.grouped).length > 0 ? (
            Object.keys(handleSearchGroups(items.grouped, filterTextGroups)).map((index) => {
              const group = items.grouped[index];
              const isEditing = editingGroupId === group.tempId;
              return (
                <SmartDroppableItem id={group.tempId}>
                  <div style={{ justifyContent: 'center', alignItems: 'center' }} >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1rem' }}>
                      {isEditing ? (
                        <TextField
                          value={editedGroupNames[group.tempId] || ''}
                          onChange={(event) => setEditedGroupNames((prevNames) => ({ ...prevNames, [group.tempId]: event.target.value }))
                          }
                        />
                      ) : (
                        <Typography variant="h6" >{group.text}</Typography>
                      )}
                      <div style={{ marginLeft: '1rem' }}>
                        {isEditing ? (
                          <>
                            <IconButton onClick={() => handleSaveGroupName(group.tempId)}><Save /></IconButton>
                            <IconButton onClick={handleCancelEditGroupName}><Cancel /></IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton style={{ marginBottom: '0.5rem' }} onClick={() => setEditingGroupId(group.tempId)}><Edit /></IconButton>
                            <SmartDraggableItem key={group.tempId} id={group.tempId} data={{ group, type: 'groups' }} customStyle={{ ...dragBtnStyle }} >
                              <DragHandleIcon />
                            </SmartDraggableItem>
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      {group.items.map((item) => (
                        <div>
                          <SmartDraggableItem key={item.tempId} id={item.tempId} data={{ item: item, groupId: group.tempId }} customStyle={{ ...itemsStyle }}>
                            {item.text}
                          </SmartDraggableItem>
                        </div>
                      ))}
                    </div>
                  </div>
                </SmartDroppableItem>
              );
            })
          ) : (
            <div style={{ ...style, display: 'flex', gridColumn: '1 / span 2', justifySelf: 'center', justifyContent: 'center' }}>
              <Typography>{t_("No groups created")}</Typography>
            </div>)
          }
        </div>
        <div style={{ display: 'inline-block' }}>
          <SmartDroppableItem id="delete">
            <DeleteOutlineRounded />
          </SmartDroppableItem>
        </div>
      </div >
    )
  }

  return (
    <>
      {AlertElement}
      < DndContext onDragEnd={handleDragEnd} >
        <div style={{ ...style, display: 'flex', flexDirection: 'row' }}>
          {printItemsUngrouped(items)}
          {printItemsGrouped(items)}
        </div>
      </DndContext >
    </>
  );
}
export default SmartGroupCreator;
