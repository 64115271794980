import { useTheme } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const GenereicSortableRenderItem = (props) => {
  const { labels, hideShow, visibility, itemId, setVisibility } = props;
  const theme = useTheme();
  const margin = 0.6
  const style = {
    display: 'flex',
    width: '100%',
    border: '1px solid #b8b7b7',
    background: theme.palette.background.default,
    borderRadius: '10px',
    marginBottom: margin + 'rem',
    justifyContent: "space-between",
    padding: margin + 'rem',
    paddingLeft: "3rem",
    paddingRight: "3rem",
    columnGap: '3rem'
  }
  const style2 = {
    display: 'flex',
    justifyContent: "space-between",
    width: '100%',
  }
  const styleIcon = {
    cursor: "pointer",
    position: "relative"
  }
  const toggleVisibility = async (item) => {
    setVisibility(item, !visibility)
  }
  return (<div style={style}>
    <div style={style2}>{labels.map((label, index) => <span key={"Generic-Sortable-Item-" + index + "-" + label}>{label}</span>)
    }</div>
    {hideShow ? <div onClick={(event) => {
      toggleVisibility(itemId);
      event.stopPropagation();
    }}
      style={styleIcon}>{visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}</div>
      : null}
  </div>
  )
};

export default GenereicSortableRenderItem;