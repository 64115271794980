import React, { useEffect, useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { useTheme } from "@mui/material";

const SmartDraggableItem = (props) => {

  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });

  const [color, setColor] = useState('green');

  useEffect(() => {
    if (props.id === 'delete') {
      setColor('red');
    }
  }, [props.id]);

  const theme = useTheme();
  const margin = 0.6
  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #b8b7b7',
    background: theme.palette.background.default,
    borderRadius: '10px',
    marginBottom: margin + 'rem',
    justifyContent: "space-between",
    padding: margin + 'rem',
    paddingLeft: "3rem",
    paddingRight: "3rem",
    columnGap: '3rem',
    color: isOver ? color : undefined,
  }

  return (
    <div ref={setNodeRef} style={style}>
      {props.children}
    </div>
  );
}
export default SmartDraggableItem;