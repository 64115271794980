import React, { useRef, useEffect, useState } from 'react'
import { apiGet, getBackendUrl, apiPut } from '../generic/Api_Functions'
import bcrypt from 'bcryptjs-react'
import { Button, TextField, Paper, IconButton, InputAdornment } from '@mui/material'
import loginWallpaper from '../../images/login_wallpaper.jpg'
import ImageLogoDefault from '../../images/Logo.PNG'
import AlertUI from '../generic/AlertUI'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useUser } from '../generic/hooks/useUser'
import { useNavigate } from 'react-router-dom'
import { CheckCredentials } from '../generic/LoginFunctions'
function LoginPage ({ timeoutAlert }) {
  const [AlertElement, showAlert] = AlertUI()
  const { t_ } = useSmartTranslation()
  const showAlertRef = useRef(showAlert)
  const [showPasswordLogin, setShowPasswordLogin] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [showNewPasswordDialog, setShowNewPasswordDialog] = useState(false)
  const [username, setUsername] = useState(null)
  const { userLogin } = useUser()
  const navigate = useNavigate()
  useEffect(() => {
    if (timeoutAlert) {
      showAlertRef.current({
        title: 'Timeout',
        severity: 'warning'
      })
    }
  }, [timeoutAlert])

  const backgroundImage = loginWallpaper

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    },
    logo: {
      width: '300px',
      marginBottom: '20px',
      objectFit: 'contain'
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      paddingLeft: '4rem',
      paddingRight: '4rem'
    },
    paper: {
      backgroundColor: '#303030',
      padding: '20px',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '400px',
      width: '100%'
    },
    textField: {
      bgcolor: '303030',
      marginBottom: '20px',
      width: '100%'
    }
  }

  /**
   * Handle submit of login form
   */
  const handleSubmit = async (event) => {
    event.preventDefault()
    const username = document.getElementById('tf1').value
    setUsername(username)
    const password = document.getElementById('tf2').value

    if (username !== null || password !== null) {
      checkLogin(username, password)
    }
  }

  const showWrongCredentials = () => {
    showAlert({
      title: 'Login error',
      severity: 'warning',
      message: t_('Wrong username or password')
    })
  }

  /**
   * Check if is user's first login
   */
  const checkFirstLogin = async (username) => {
    const firstLogin = await apiGet('userFirstLogin/' + username)
    if (firstLogin) {
      setShowNewPasswordDialog(true)
    } else {
      userLogin(username)
      navigate('/home')
    }
    return firstLogin
  }

  /**
   * Check username and password with DB,
   * if correct check if it's first login
   */
  const checkLogin = async (username, password) => {
    try {
      const dbUserPassword = await apiGet('userPassword/' + username)
      if (dbUserPassword) {
        const response = await CheckCredentials(username, password, dbUserPassword)
        if (response.body.result) {
          checkFirstLogin(username)
        } else if (!response.body.result && response.statusCodeValue === 200) {
          showWrongCredentials()
        } else {
          showAlert({
            title: t_('Login error'),
            severity: 'error'
          })
        }
      } else {
        showWrongCredentials()
      }
    } catch (e) {
      showWrongCredentials()
    }
  }

  /**
   * Form dialog for login
   */
  const loginForm = () => {
    return (
      <form onSubmit={handleSubmit} style={styles.form}>
        <TextField
          key='tf1'
          id='tf1'
          color='primary'
          label={t_('User')}
          required
          variant='filled'
          sx={styles.textField}
        />
        <TextField
          key='tf2'
          id='tf2'
          color='primary'
          type={showPasswordLogin ? 'text' : 'password'}
          label={t_('Password')}
          required
          variant='filled'
          sx={styles.textField}
          InputProps={
            {
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='visibility'
                    onClick={() => setShowPasswordLogin(!showPasswordLogin)}
                    edge='end'
                  >
                    {showPasswordLogin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>)
            }
          }
        />
        {AlertElement && <div style={{ marginBottom: '10px' }}>{AlertElement}</div>}
        <Button type='submit' variant='contained' color='primary'>
          {t_('Log in')}
        </Button>
      </form>
    )
  }

  /**
   * Form dialog for creating a new password
   */
  const newPasswordForm = () => {
    const handleSaveNewPassword = (password) => {
      const salt = bcrypt.genSaltSync(10)
      const hashedPassword = bcrypt.hashSync(password, salt)

      apiPut('user/setPassword/' + true, { username, hashedPassword, challenge: null, salt })
        .then(() => {
          setShowNewPasswordDialog(false)
          showAlert({ severity: 'success', title: t_('Password set successfully') })
        })
        .catch(() => {
          showAlert({ severity: 'error', title: t_('Error while setting password') })
        })
    }

    const handleCheckPassword = (pwd1, pwd2) => {
      if (pwd1 !== pwd2) {
        showAlert({
          title: 'Password error',
          severity: 'warning',
          message: t_('Passwords do not match')
        })
        return false
      } else if (pwd1.length < 5 || pwd2.length < 5) {
        showAlert({
          title: 'Password error',
          severity: 'warning',
          message: t_('Password must have at least 5 characters')
        })
        return false
      } else {
        return true
      }
    }

    const handleSubmitNewPassword = (event) => {
      event.preventDefault()
      const pwd1 = document.getElementById('pwd1').value
      const pwd2 = document.getElementById('pwd2').value
      if (handleCheckPassword(pwd1, pwd2)) {
        handleSaveNewPassword(pwd1)
      }
    }

    return (
      <form onSubmit={handleSubmitNewPassword} style={styles.form}>
        <h3>{t_('Create new password')}</h3>
        <TextField
          key='pwdw1'
          id='pwd1'
          color='primary'
          type={showPassword ? 'text' : 'password'}
          label={t_('Password')}
          required
          variant='filled'
          sx={styles.textField}
          InputProps={
            {
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>)
            }
          }
        />
        <TextField
          key='pwd2'
          id='pwd2'
          color='primary'
          type={showPasswordConfirm ? 'text' : 'password'}
          label={t_('Confirm Password')}
          required
          variant='filled'
          sx={styles.textField}
          InputProps={
            {
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='visibility'
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                    edge='end'
                  >
                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>)
            }
          }
        />
        {AlertElement && <div style={{ marginBottom: '10px' }}>{AlertElement}</div>}
        <div>
          <Button sx={{ marginInline: '0.5rem' }} type='submit' variant='contained' color='primary'>
            {t_('Save')}
          </Button>
          <Button sx={{ marginInline: '0.5rem' }} type='reset' variant='contained' color='primary' onClick={() => setShowNewPasswordDialog(false)}>
            {t_('Cancel')}
          </Button>
        </div>
      </form>
    )
  }

  return (
    <div style={styles.container}>
      <Paper
        sx={styles.paper}
      >
        <img src={getBackendUrl() + 'logo'} height='100px' alt='SmartPM logo' style={styles.logo} onError={(e) => { e.target.src = ImageLogoDefault }} />
        {!showNewPasswordDialog
          ? loginForm()
          : newPasswordForm()}
      </Paper>
    </div>
  )
}

export default LoginPage
