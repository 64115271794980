import React from "react";
import SmartNavigator from "../generic/SmartNavigator";
import StoragePosition from "../management/StoragePosition";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";

const MainStoragePosition = () => {
  const { t_ } = useSmartTranslation();
  const componentsInfo = [
    {
      title: t_("Storage Situation"),
      component: <StoragePosition />,
    }
  ];
  return <SmartNavigator componentsInfo={componentsInfo} />;
};
export default MainStoragePosition;