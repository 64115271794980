import React from "react";
import SmartNavigator from "../generic/SmartNavigator";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";
import Users from './../configuration/users/Users';

const MainUsers = () => {
    const { t_ } = useSmartTranslation();

    const componentsInfo = [
        {
            title: t_("Users"),
            component: <Users />,
        },
    ];

    return (
        <div>
            <SmartNavigator componentsInfo={componentsInfo} />
        </div>
    );
};

export default MainUsers;
