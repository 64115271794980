import { Box, Chip, Divider, List, ListItem, Stack, Tooltip, Typography } from "@mui/material";
import ChecklistIndicator from "./ChecklistIndicator";

const TooltipList = ({ items, getItemId, getItemLabel, getItemOk }) => {


  if (items.length === 0) {
    return null;
  }

  if (items.length === 1) {
    return getItemLabel(items[0]);
  }

  return <Tooltip componentsProps={{ tooltip: { sx: { backgroundColor: "transparent" } } }}
    title={
      <List sx={{ bgcolor: 'background.paper' }}>
        {items.map(
          (item, index) => {
            return (
              <Box key={getItemId(item)}>
                <ListItem>
                  <Stack direction="row" justifyContent="space-between" gap={2} sx={{ flexGrow: 1 }}>
                    <ChecklistIndicator status={getItemOk(item) ? "OK" : "ERROR"} />
                    <Typography>{getItemLabel(item)}</Typography>
                  </Stack>
                </ListItem>
                {index < items.length - 1 ? <Divider component="li" /> : null}
              </Box>
            )
          }
        )}
      </List>
    }>
    <div>
      <Stack direction="row" alignItems="center" gap={2}>
        {items !== null && items.length > 0 ? getItemLabel(items[0]) : null}
        {items !== null && items.length > 1 ? <Chip label={"+ " + (items.length - 1)} /> : null}
      </Stack>
    </div>
  </Tooltip>

};

export default TooltipList;