import React, { useState, useEffect } from 'react'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { apiDelete, apiGet, apiPut } from '../../generic/Api_Functions'
import AlertUI from '../../generic/AlertUI'
import { MenuItem, TextField } from '@mui/material'
import AddElementFeatureQualitative from '../../generic/smartAddElement/AddElementFeatureQualitative'

const PartFeatureHistoryQualitative = (props) => {
  const [AlertElement, handleShowAlert] = AlertUI()
  const { feature, part, group = null } = props
  const { t_ } = useSmartTranslation()
  const [indice, setIndice] = useState(null)
  const [newValue, setNewValue] = useState(null)
  const [qualityValues, setQualityValues] = useState(null)

  useEffect(() => {
    const qualityValuesDataFetch = async () => {
      const data = await apiGet('qualityValues')
      setQualityValues(data)
      return data
    }
    qualityValuesDataFetch()
  }, [])

  const columns = [
    {
      name: t_('Result'),
      field: 'value',
      render: (rowData) => {
        if (indice === rowData['smart-table-index']) {
          return (
            <TextField
              style={{ paddingBottom: '15px' }}
              select
              value={newValue !== null ? newValue : rowData.value}
              onChange={(event) => {
                setNewValue(event.target.value)
              }}
              fullWidth
              size='small'
            >
              {qualityValues.map((option) => (
                <MenuItem key={option.id} value={option.code}>
                  {option.code}
                </MenuItem>
              ))}
            </TextField>
          )
        } else {
          return rowData.value
        }
      }

    },
    {
      name: t_('Measurement date'),
      field: 'reportDate',
      type: 'dateTimePicker',
      defaultNewValue: new Date().toISOString(),
      mandatory: true

    }
  ]

  if (group) {
    // Add feature name column
    columns.unshift(
      {
        name: t_('Name'),
        field: 'feature.name',
        readOnly: true
      }
    )
  }

  const dataFetch = async (pagination) => {
    const urlId = group ? group.id : feature.featureId

    const result = await apiGet('inspectionReportFeatureQualitative' + (group ? 'ByGroup' : '') + '/' + part.id + '/' + urlId, pagination)
      .catch((error) => {
        handleShowAlert({
          title: 'Error getting data.',
          message: error
        })
      })

    return result
  }

  const actions = {
    edit: {
      fetchFunction: (row) => {
        if (newValue !== null) {
          row.value = newValue
        }
        return apiPut('feature/qualitative/part', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('inspectionReportFeature/' + rows[0].id).then()
        } else {
          return apiDelete('inspectionReportFeatureMulti', rows).then()
        }
      }
    },
    create: {
      fetchFunction: null,
      render: (extraFunctions) => {
        return (
          <AddElementFeatureQualitative
            title={t_('ADD RECORD')}
            endpoint='inspectionReportFeaturesQualitative'
            partId={part.id}
            parameter={feature.featureId}
            onAlert={extraFunctions.handleShowAlert}
          />
        )
      }
    }
  }

  if (group) {
    // Dont allow adding
    delete actions.create
  }

  const sortOptions = {
    default: {
      field: 'inspectionReport.reportDate',
      direction: -1
    }
  }

  return (
    <>
      {AlertElement}
      <SmartMateriaUITable
        title={group ? group.name : feature.name}
        columns={columns}
        dataFetch={dataFetch}
        sortOptions={sortOptions}
        actions={actions}
        onEditIndexChanged={(row) => setIndice(row)}
        multipleSelection
        pageable
      />
    </>
  )
}

export default PartFeatureHistoryQualitative
