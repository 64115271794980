import React, { useEffect, useState } from "react";

import { apiGet } from "../../generic/Api_Functions";
import { TextField, Grid, IconButton, MenuItem } from "@mui/material";
import { DeleteRounded } from "@mui/icons-material";
import { useRef } from "react";


const SensorsActuatorsComponentsFunctionsProtocols = (props) => {

    const { ioElementSet, setIoElementSet, funcion, ioElementSetFunctionIoElement, addIoElementSetFunctionIoElement, setAddIoElementSetFunctionIoElement, setSaveDisabled } = props;
    const [ioElements, setIoElements] = useState([])
    const [protocols, setProtocols] = useState([])
    const sensorActuatorValue = useRef()
    var errors = useRef([])

    useEffect(() => {
        const fetchData = async () => {
            let ioElementsDB = await apiGet("ioElement")
            setIoElements(ioElementsDB)
        };
        fetchData()
        const fetchProtocols = async () => {
            let protocolsDB = await apiGet("protocols")
            setProtocols(protocolsDB)
        }
        fetchProtocols()
    }, [ioElementSet]);

    let ioElement = ioElementSetFunctionIoElement !== undefined ? ioElementSetFunctionIoElement.ioElement.id : ""

    const handleValue = (value) => {
        if (ioElements.length === 0) return ""
        if (value !== undefined) {
            sensorActuatorValue.current = value
            return value
        }
        if (ioElementSetFunctionIoElement !== undefined) {
            sensorActuatorValue.current = ioElementSetFunctionIoElement.ioElement.id
            return ioElementSetFunctionIoElement.ioElement.id
        }
        return ""
    }

    const getIoElementsToShow = () => {
        if (ioElements.length === 0) return ioElements

        if (ioElementSetFunctionIoElement === undefined) {

            return ioElements.filter(ioElement => {
                let ioElementFind = ioElementSet.ioElementSetFunctions
                    .find((ioElementSetFunction) => ioElementSetFunction.function.code === funcion.code).ioElementSetFunctionIoElements.find((ioElementForFunctionCode) => ioElementForFunctionCode.ioElement.id === ioElement.id)
                if (ioElementFind !== undefined) {
                    return ioElement.id !== ioElementFind.ioElement.id
                }
                return true
            })
        } else {
            return ioElements
        }
    }

    const deleteIoElement = () => {

        if (ioElement === "") {
            setAddIoElementSetFunctionIoElement(false)
            return
        }
        let copyIoELementSet = { ...ioElementSet }

        copyIoELementSet.ioElementSetFunctions.find((ioElementSetFunction) => ioElementSetFunction.function.code === funcion.code)
            .ioElementSetFunctionIoElements = ioElementSet.ioElementSetFunctions.find((ioElementSetFunction) => ioElementSetFunction.function.code === funcion.code)
                .ioElementSetFunctionIoElements
                .filter(ioElementSetFunctionIoElement => (ioElementSetFunctionIoElement.ioElement.id !== ioElement))
        setIoElementSet(copyIoELementSet)
    }


    const handleChangeUpdateValue = (event, functionProtocolConfiguration) => {
        if (event.target.value === undefined || event.target.value === "") {
            setSaveDisabled(true)
        }
        else setSaveDisabled(false)
        functionProtocolConfiguration.protocolValue = event.target.value
    }

    const disabledFunction = () => {
        if (errors.current.length === 0) {
            setSaveDisabled(false)
        } else {
            setSaveDisabled(true)
        }
    }

    const handleChangeCreateValue = (event, protocol, controller, code) => {
        if (errors.current.find(error => error === code) !== undefined && event.target.value !== '') {
            errors.current = errors.current.filter((errorCopy) => {
                return errorCopy !== code
            })
        }

        let funcionProtocol
        let functionProtocolConfiguration

        if (protocol !== undefined && controller !== undefined) {
            //Create structure 
            functionProtocolConfiguration = {
                protocolParameter: protocol,
                protocolValue: event.target.value,
                functionProtocol: {
                    function: funcion,
                    protocol: controller.protocol
                }
            }
        }

        if (ioElementSetFunctionIoElement.ioElement.functionProtocol === undefined || ioElementSetFunctionIoElement.ioElement.functionProtocol === null) {
            funcionProtocol = {
                function: funcion,
                protocol: controller.protocol,
                functionProtocolConfigurations: [functionProtocolConfiguration]
            }
            ioElementSetFunctionIoElement.ioElement.functionProtocol = funcionProtocol
        } else {
            ioElementSetFunctionIoElement.ioElement.functionProtocol.functionProtocolConfigurations
                .find(functionProtocolConfiguration => functionProtocolConfiguration.protocolParameter.parameter === protocol.parameter) === undefined ?
                ioElementSetFunctionIoElement.ioElement.functionProtocol.functionProtocolConfigurations.push(functionProtocolConfiguration)
                :
                ioElementSetFunctionIoElement.ioElement.functionProtocol.functionProtocolConfigurations
                    .find(functionProtocolConfiguration => functionProtocolConfiguration.protocolParameter.parameter === protocol.parameter).protocolValue = event.target.value
        }
    }

    return (
        < Grid item xs={12} sx={{ border: addIoElementSetFunctionIoElement ? "1px solid #7EE1A7" : "0px solid rgba(255, 255, 255, 0.12)", padding: "1em", borderRadius: "5px" }} >
            {/* < Stack direction="row" spacing={3} > */}
            <Grid container direction="row" spacing={3} columns={{ xs: 1, sm: 4, md: 8, lg: 12 }}>
                {/* <FormControl > */}
                <Grid item xs={2}>
                    <TextField
                        select
                        label="Sensor/Actuator"
                        value={handleValue()}
                        disabled={sensorActuatorValue.current !== undefined}
                        fullWidth={true}
                        onChange={(event) => {
                            let ioElementNew = ioElements.find((ioElement) => {
                                return ioElement.id === event.target.value
                            })

                            let ioElementSetFUnctionIoElementNew = {
                                ioElement: ioElementNew,
                                ioElementSetFunction: {
                                    id: ioElementSet.ioElementSetFunctions
                                        .find((ioElementSetFunction) => ioElementSetFunction.function.code === funcion.code).id
                                }
                            }
                            ioElementSet.ioElementSetFunctions
                                .find((ioElementSetFunction) => ioElementSetFunction.function.code === funcion.code).ioElementSetFunctionIoElements
                                .unshift(ioElementSetFUnctionIoElementNew)

                            handleValue(event.target.value)

                            setIoElementSet(ioElementSet)
                            if (setAddIoElementSetFunctionIoElement !== undefined)
                                setAddIoElementSetFunctionIoElement(false)
                        }}
                    // sx={{ minWidth: '14em' }}
                    >
                        {
                            getIoElementsToShow().map((ioElement, index) => (
                                <MenuItem value={ioElement.id} key={ioElement.id + index}>
                                    {ioElement.name}
                                </MenuItem>

                            ))}
                    </TextField>
                </Grid>
                {/* </FormControl> */}
                {
                    ioElementSetFunctionIoElement !== undefined ?
                        <>
                            <Grid item xs={1.5}>
                                <TextField label="Protocol" value={ioElementSetFunctionIoElement.ioElement.ioController.name} disabled fullWidth={true} />
                            </Grid>
                            {
                                ioElementSetFunctionIoElement.ioElement.functionProtocol !== undefined && ioElementSetFunctionIoElement.ioElement.functionProtocol !== null
                                    ?
                                    ioElementSetFunctionIoElement.ioElement.functionProtocol.functionProtocolConfigurations
                                        .sort((a, b) => a.id - b.id)
                                        .map((functionProtocolConfiguration, index) => (
                                            <Grid key={functionProtocolConfiguration.protocolParameter.parameter + index} item xs={functionProtocolConfiguration.protocolParameter.parameter === "serverAddress" || functionProtocolConfiguration.protocolParameter.parameter === "variableName" ? 3 : 2}>
                                                <TextField key={index} required={true} fullWidth={true} label={functionProtocolConfiguration.protocolParameter.parameter} defaultValue={functionProtocolConfiguration.protocolValue}
                                                    onChange={(event) => handleChangeUpdateValue(event, functionProtocolConfiguration)}
                                                // sx={{ minWidth: "7em", width: `${functionProtocolConfiguration.protocolValue.length}%` }} 
                                                />
                                            </Grid>
                                        ))
                                    :
                                    protocols.length !== 0 ?
                                        <>

                                            {setSaveDisabled(true)}
                                            {protocols.find((protocol) => protocol.name === ioElementSetFunctionIoElement.ioElement.ioController.protocol.name).protocolParameters
                                                .sort((a, b) => a.id - b.id)
                                                .map((protocolParameter, index) => (
                                                    <>
                                                        {errors.current !== undefined &&
                                                            errors.current.find(error => error === funcion.code + ioElementSetFunctionIoElement.ioElement.code + protocolParameter.parameter) === undefined ?
                                                            errors.current.push(funcion.code + ioElementSetFunctionIoElement.ioElement.code + protocolParameter.parameter)
                                                            : null
                                                        }

                                                        <Grid item xs={protocolParameter.parameter === "serverAddress" || protocolParameter.parameter === "variableName" ? 3 : 2}>
                                                            <TextField key={index} required={true} fullWidth={true} label={protocolParameter.parameter}
                                                                onChange={(event) => handleChangeCreateValue(event, protocolParameter, ioElementSetFunctionIoElement.ioElement.ioController, funcion.code + ioElementSetFunctionIoElement.ioElement.code + protocolParameter.parameter)}
                                                                onBlur={() => disabledFunction()}
                                                            // sx={{ minWidth: "7em" }}
                                                            />
                                                        </Grid>
                                                    </>
                                                ))
                                            }
                                        </>
                                        : null
                            }
                        </>
                        : null
                }
                <Grid item xs={0.5}>
                    <IconButton style={{ color: "white" }} onClick={() => deleteIoElement()}  >
                        <DeleteRounded />
                    </IconButton>
                </Grid>
                {/* </Stack > */}
            </Grid>
        </Grid >
    )
}

export default SensorsActuatorsComponentsFunctionsProtocols;
