import React from 'react'
import { Fab, Typography } from '@mui/material'
import { EditRounded, SaveRounded } from '@mui/icons-material'
import { useState } from 'react'
import { useSmartTranslation } from '../../hooks/useSmartTranslation'


const orange = '#FFA501'

const ButtonBackFab = (props) => (
  <Fab
    onClick={props.onClick}
    color={props.color}
    aria-label={props.ariaLabel}
    variant={props.variant}
    disabled={props.disabled}
    sx={{
      marginLeft: '10px',
      backgroundColor: theme => props.showEdit && props.editControl ? theme.palette.background.button : theme.palette.primary.main,
      color: props.showEdit && props.editControl ? orange : '#000',
      border: props.showEdit && props.editControl ? `1px solid #FFA500` : null,
      "&:hover": {
        backgroundColor: theme => props.showEdit && props.editControl ? theme.palette.background.default : '#63bf8a',
      },
    }}

  >
    {props.children}
  </Fab >
)

const ButtonSave = (props) => {
  const { buttonAction, saveDisabled = false, buttonFixed, editControl = false, useOnSaveClick = false } = props;
  const [showEdit, setShowEdit] = useState(true)
  const changeEditTosave = () => {
    editControl(true)
    setShowEdit(false)
  }
  const { t_ } = useSmartTranslation();

  const onSave = () => {
    setShowEdit(true);
    buttonAction();
  }
  return (
    <ButtonBackFab disabled={saveDisabled} editControl={editControl} showEdit={showEdit} onClick={useOnSaveClick && showEdit === false ? onSave : (showEdit === true && editControl !== false ? () => changeEditTosave() : () => buttonAction())} aria-label="add" variant="extended" buttonFixed={buttonFixed}>
      {editControl && showEdit ?
        <>
          <Typography color={orange} style={{ marginRight: '0.3em', fontSize: '0.875rem' }} >{t_("EDIT")}</Typography>
          <EditRounded color={orange} />
        </>
        :
        <>
          <Typography style={{ marginRight: '0.3em', fontSize: '0.875rem' }} >{t_("SAVE")}</Typography>
          <SaveRounded />
        </>
      }

    </ButtonBackFab>
  )

}
export default ButtonSave


