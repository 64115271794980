import React from 'react'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { IconButton } from '@mui/material'
import { ManageSearchOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const Picklist = () => {
  const { t_ } = useSmartTranslation()
  const navigate = useNavigate()
  const routeMap = {
    Incidences: '/configuration/picklist/incidence',
    Quality_types: '/configuration/picklist/qualityTypes',
    'Device status': '/configuration/picklist/deviceStatus',
    Reasons: '/configuration/picklist/reasons',
    'Fabrication order status': '/configuration/picklist/fabricationOrderStatus'
  }

  const columns = [{
    name: t_('Code'),
    field: 'code',
    charLimit: 60,
    mandatory: true,
    unique: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_('Name'),
    field: 'name',
    charLimit: 80,
    mandatory: true,
    unique: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_('Description'),
    field: 'description',
    charLimit: 200
  }
  ]

  const actions = {
    custom: [
      {
        name: 'ShowPickLists',
        render: (row) => {
          const route = routeMap[row.code]
          return (
            <IconButton
              onClick={(event) => {
                if (route) {
                  navigate(route)
                }
              }}
            >
              <ManageSearchOutlined />
            </IconButton>
          )
        }
      }
    ]
  }

  const fetchFunction = () => {
    const picklists = [
      {
        code: 'Incidences',
        name: 'Incidences',
        description: 'Incidences description'
      },
      {
        code: 'Quality_types',
        name: 'Quality_types',
        description: 'Quality_types description'
      },
      {
        code: 'Device status',
        name: 'Status',
        description: 'Status description'
      },
      {
        code: 'Reasons',
        name: 'Reasons',
        description: 'Reasons description'
      },
      {
        code: 'Fabrication order status',
        name: 'Fabrication order status',
        description: 'Fabrication order status description'
      }
    ]
    return picklists
  }

  return (
    <>
      <SmartMateriaUITable
        columns={columns}
        title={t_('Picklist')}
        dataFetch={fetchFunction}
        actions={actions}
        multipleOptions

      />
    </>
  )
}
export default Picklist
