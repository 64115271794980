import {
  Toolbar,
  Typography,
  Button,
  Box,
  Tooltip,
  Chip,
  Stack
  , ButtonGroup
} from '@mui/material'
import {
  DeleteOutlineRounded
  , AddCircleRounded
} from '@mui/icons-material'

import React, { useState } from 'react'
import AlertUI from './AlertUI'
import { SmartDeleteDialog } from './utilities/SmartDialog'
import SmartSearch from './formComponent/Search'
import ImportFile from '../importFile'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

const titleStyle = {
  fontSize: '25px !important',
  marginRight: '1em'
}

const SmartMaterialUIToolbar = (props) => {
  const {
    multipleSelectionValue,
    title,
    setFilterText,
    filterText,
    actions,
    columns,
    editIndex,
    messageToolbarMultipleSelection,
    setMultipleSelectionValue,
    searchAndPaginationVisible,
    toolbarDisplay,
    smartTableIndexKey,
    toolbar,
    multipleSelectionActions,
    deleteMultipleSelectionDisabled,
    disableFlexGrow,
    setLoadTable,
    loadTable,
    setDisableRowActions,
    onAddElementClick,
    resetPageCount,
    datafetch,
    pagination,
    pageable,
    importFile,
    renderingFilters
  } = props

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const [AlertElement, showAlert] = AlertUI()
  const toolbarStyle = multipleSelectionValue.rows.length === 0 && !multipleSelectionValue.allSelected
    ? {
        flexGrow: 1, height: 100
      }
    : {
        flexGrow: 1,
        height: 100,
        backgroundColor: theme => theme.palette.background.toolbarMultipleSelection,
        border: theme => `10px  ${theme.palette.primary.main}`,
        borderStyle: 'none none none solid'
      }

  const { t_ } = useSmartTranslation()

  const deleteMultipleSelection = () => {
    return (
      <Tooltip arrow title='delete selections'>
        <Button
          variant='outlined'
          sx={{ borderColor: 'transparent' }}
          color='error'
          onClick={() => {
            if (
              typeof actions.delete
                .fetchFunction === 'function'
            ) {
              if (setDisableRowActions !== undefined) setDisableRowActions(true)
              setOpenDeleteDialog(true)
            }
          }}
        >
          <DeleteOutlineRounded sx={{ marginRight: '0.5em' }} color='error' />
          <Typography color='error'>{t_('DELETE')}</Typography>
        </Button>
      </Tooltip>
    )
  }

  const resetMultiSelectionValue = () => {
    setMultipleSelectionValue({
      rows: [],
      allSelected: false
    })
  }

  const cancelOperation = () => {
    if (setDisableRowActions !== undefined) setDisableRowActions(false)
    showAlert({
      title: t_('Operation cancelled'),
      severity: 'info'
    })
  }

  const deleteFunction = async (multipleSelectionValue) => {
    if (setDisableRowActions !== undefined) setDisableRowActions(false)
    await actions.delete.fetchFunction(
      multipleSelectionValue
    ).then((paramsMulti) => {
      if (paramsMulti && paramsMulti.disableAlert) {
        return
      }
      resetMultiSelectionValue()
      resetPageCount()
      showAlert({
        title: t_('Deleted correctly.'),
        severity: 'success'
      })
    }).catch((error) => {
      resetMultiSelectionValue()
      showAlert({
        title:
          '',
        message: error.message,
        severity: error.name
      })
    })
  }

  const getPageableDeleteDialog = () => {
    let deletePageable = null
    if (pageable) {
      deletePageable = datafetch
      deletePageable = { ...pagination }
      deletePageable.size = -1
      deletePageable.page = 0
    }
    return deletePageable
  }

  return (
    <>
      {AlertElement}

      <Toolbar
        sx={{ ...toolbarStyle, display: [searchAndPaginationVisible || toolbarDisplay] }}
      >
        {
          multipleSelectionValue.rows.length === 0 && !multipleSelectionValue.allSelected
            ? (
              <>

                {typeof title === 'string' ? <Typography sx={titleStyle}>{title}</Typography> : <Box sx={titleStyle}>{title}</Box>}
                {toolbar && toolbar.render && toolbar.render.fetchFunction
                  ? toolbar.render.fetchFunction({ showAlert })
                  : null}
                {renderingFilters ? renderingFilters() : null}

                {disableFlexGrow
                  ? null
                  : <Box sx={{ flexGrow: 1 }} />}
                <Stack style={{ position: 'sticky', right: 24 }} direction='row' alignItems='center'>
                  {importFile ? <ImportFile beanParams={importFile} columns={columns} /> : null}
                  {actions
                    ? (
                        actions.create && actions.create.render === undefined
                          ? (
                            <Button
                              sx={{ marginLeft: '1em', border: '0px' }}
                              color='primary'
                              variant='outlined'
                              onClick={() => {
                                if (onAddElementClick && typeof onAddElementClick === 'function') {
                                  onAddElementClick()
                                }
                              }}
                              disabled={smartTableIndexKey !== null ? editIndex > -2 : true}
                            >

                              <AddCircleRounded sx={{ fontSize: '1.2em' }} />
                              <Typography sx={{ marginLeft: '0.4em', fontSize: '0.875rem' }}>{t_('ADD RECORD')}</Typography>

                            </Button>

                            )
                          : actions.create && actions.create.render !== undefined && typeof actions.create.render === 'function' ? actions.create.render({ handleShowAlert: showAlert }) : null
                      )
                    : null}
                  <SmartSearch setFilterText={setFilterText} setLoadTable={setLoadTable} loadTable={loadTable} />
                </Stack>
              </>
              )
            : (
              <>
                {multipleSelectionActions && deleteMultipleSelectionDisabled
                  ? (
                    <ButtonGroup variant='outlined'>
                      {multipleSelectionActions.map((action, index) => (
                        action.render(datafetch, index, pagination, multipleSelectionValue)
                      ))}
                    </ButtonGroup>
                    )
                  : multipleSelectionActions && !deleteMultipleSelectionDisabled
                    ? (
                      <Stack direction='row' gap={1}>
                        {deleteMultipleSelection()}
                        <ButtonGroup variant='outlined'>
                          {multipleSelectionActions.map((action, index) => (
                            action.render(datafetch, index, pagination, multipleSelectionValue)
                          ))}
                        </ButtonGroup>
                      </Stack>
                      )
                    : deleteMultipleSelection()}
                <Box sx={{ flexGrow: 1 }} />
                <Typography>
                  {messageToolbarMultipleSelection}
                  <SmartDeleteDialog
                    show={openDeleteDialog}
                    cancelCallback={() => {
                      cancelOperation()
                      setOpenDeleteDialog(false)
                    }}
                    deleteCallBack={() => {
                      let deleteParam = datafetch
                      if (pageable) {
                        deleteParam = getPageableDeleteDialog()
                      }
                      deleteFunction(deleteParam)
                      setOpenDeleteDialog(false)
                    }}
                    rows={datafetch}
                    columns={columns}
                    pageable={getPageableDeleteDialog()}
                  />
                </Typography>
                {filterText
                  ? <Chip variant='outlined' label={filterText} />
                  : null}
              </>
              )
        }
      </Toolbar>
    </ >
  )
}
export default SmartMaterialUIToolbar
