import Vid from "../../../videos/biemh-deteccion-personas.mp4";
const PresenceDetectionVideo = () => {


  return (
    <div className="App" style={{ textAlign: 'center' }}>
      <video width='65%'
        height='65%' controls >
        <source src={Vid} type="video/mp4" />
      </video>
    </div >
  );
};

export default PresenceDetectionVideo;
