import React from 'react';
import { useDraggable } from '@dnd-kit/core';

const SmartDraggableItem = (props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
    data: props.data,
  });
  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  const mergedStyle = { ...style, ...props.customStyle };

  return (
    <button ref={setNodeRef} style={mergedStyle} {...listeners} {...attributes}>
      {props.children}
    </button>
  );
};

export default SmartDraggableItem;