import React from 'react'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { apiDelete, apiGet, apiPut } from '../../generic/Api_Functions'
import AlertUI from '../../generic/AlertUI'
import { Slider, ThemeProvider, createTheme } from '@mui/material'
import AddElementFeature from '../../generic/smartAddElement/AddElementFeature'

const PartFeatureHistory = (props) => {
  const [AlertElement, handleShowAlert] = AlertUI()
  const { feature, part, createInspectionReportFeatureEndpoint, group = null } = props
  const { t_ } = useSmartTranslation()
  const theme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          thumb: {
            display: 'none'
          }
        }
      }
    }
  })
  const columns = [
    {
      name: t_('Nominal'),
      field: 'feature.value',
      readOnly: true
    },
    {
      name: t_('Unit'),
      field: 'unit.code',
      readOnly: true
    },
    {
      name: t_('Upper tolerance'),
      field: 'feature.upperTolerance',
      readOnly: true
    },
    {
      name: t_('Lower tolerance'),
      field: 'feature.lowerTolerance',
      readOnly: true
    },
    {
      name: t_('Result'),
      field: 'value'
    },
    {
      name: t_('Deviation'),
      field: 'deviation',
      readOnly: true
    },
    {
      name: t_('Measurement date'),
      field: 'inspectionReport.reportDate',
      type: 'dateTimePicker'
    },
    {
      name: '',
      field: 'inTolerance',
      search: false,
      render: (row) => {
        let color = 'success'
        let position = [33, 66]
        const min = Number(row.feature.value) + Number(row.feature.lowerTolerance)
        const max = Number(row.feature.value) + Number(row.feature.upperTolerance)
        const value = Number(row.value)
        if (value < min || value > max) {
          position = min > value ? [0, 33] : [66, 100]
          color = 'error'
        }
        return (
          <ThemeProvider theme={theme}>
            <Slider
              value={position}
              aria-labelledby='read-only-slider'
              color={color}
              sx={{ width: '8em' }}
            />
          </ThemeProvider>
        )
      }
    }
  ]

  if (group) {
    // Add feature name column
    columns.unshift(
      {
        name: t_('Name'),
        field: 'feature.name',
        readOnly: true
      }
    )
  }

  const dataFetch = async (pagination) => {
    const urlId = group ? group.id : feature.id

    const result = await apiGet('inspectionReportFeature' + (group ? 'ByGroup' : '') + '/' + part.id + '/' + urlId, pagination)
      .catch((error) => {
        handleShowAlert({
          title: 'Error getting data.',
          message: error
        })
      })
    for (let i = 0; i < result.content.length; i++) {
      result.content[i].feature.upperTolerance = addPositiveSymbol(result.content[i].feature.upperTolerance)
      result.content[i].feature.lowerTolerance = addPositiveSymbol(result.content[i].feature.lowerTolerance)
      result.content[i].deviation = addPositiveSymbol(result.content[i].deviation)
    }
    return result
  }
  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('inspectionReportFeature', row).then()
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('inspectionReportFeature/' + rows[0].id).then()
        } else {
          return apiDelete('inspectionReportFeatureMulti', rows).then()
        }
      }
    },
    create: {
      fetchFunction: null,
      render: (extraFunctions) => {
        return (
          <AddElementFeature
            title={t_('ADD RECORD')}
            partId={part.id}
            featureUnit={feature.unit}
            parameter={feature.id}
            endpoint={createInspectionReportFeatureEndpoint}
            referenceName={feature.name}
            onAlert={extraFunctions.handleShowAlert}
          />
        )
      }
    }
  }

  const sortOptions = {
    default: {
      field: 'inspectionReport.reportDate',
      direction: -1
    }
  }
  const addPositiveSymbol = (value) => {
    return value > 0 ? '+' + value : value
  }
  return (

    <>
      {AlertElement}
      <SmartMateriaUITable
        title={group ? group.name : feature.name}
        columns={columns}
        dataFetch={dataFetch}
        sortOptions={sortOptions}
        actions={actions}
        multipleSelection
        pageable
      />
    </>
  )
}

export default PartFeatureHistory
