import { keyframes } from '@mui/system';

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const actionBadgeStyle = {
  '.MuiBadge-badge': {
    backgroundColor: theme => theme.palette.manual_action,
    color: "black",
    fontWeight: "bold",
    animation: `${blink} 1.7s ease-in-out infinite`
  },
}

export default actionBadgeStyle;