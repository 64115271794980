import React from 'react'
import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import PropTypes from "prop-types";
import { useState } from 'react';
import { Link } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}

      {...other}
    >
      {value === index && (
        <Box p={3} sx={{ backgroundColor: theme => `${theme.palette.background.paper} !important` }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const SmartNavigator = ({ componentsInfo, selected }) => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(selected !== undefined ? selected : 0)

  const handleChangeSelection = (event, newValue) => {
    setSelectedIndex(newValue);
  };

  return (
    <div style={{
      minWidth: "min-content",
      backgroundColor: theme => theme.palette.background.paper,
    }}>
      <Tabs
        value={selectedIndex}
        onChange={handleChangeSelection}
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        {
          componentsInfo.map((componentInfo, index) => {

            return (
              <Tab sx={{ backgroundColor: (theme) => `${theme.palette.background.color_212121} !important` }} key={index} label={componentInfo.title} {...componentInfo.to !== undefined ? { to: componentInfo.to } : {}} {...componentInfo.to !== undefined ? { component: Link } : {}} {...a11yProps(index)
              } />
            )
          })
        }
      </Tabs>

      {
        componentsInfo.map((componentInfo, index) => {
          return (
            <TabPanel sx={{ backgroundColor: theme => theme.palette.background.paper }} key={index} value={selectedIndex} index={index} dir={theme.direction}>
              {componentInfo.component}
            </TabPanel>
          )
        })
      }
    </div>
  )
}

export default SmartNavigator
