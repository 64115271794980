import React, { useState } from 'react'

import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { useSmartInterval } from '../../generic/hooks/useSmartInterval'

import Grid from '@mui/material/Grid'
import { apiGet } from '../../generic/Api_Functions'
import { Stack, CircularProgress } from '@mui/material'
import CompassCard from '../Common/CompassCard/CompassCard'
import CompassButton from '../Common/CompassButton/CompassButton'
import { useNavigate } from 'react-router-dom'
import { ContentPasteSearch, FeedOutlined } from '@mui/icons-material'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import CompassGrid from '../Common/compassGrid/CompassGrid'

export default function Inspect ({ fullScreen, setFullScreen }) {
  const [devices, setDevices] = useState(null)
  const { t_ } = useSmartTranslation()
  const [firstLoad, setFirstLoad] = useState(true)
  const navigate = useNavigate()

  useSmartInterval(
    async () => {
      const response = await apiGet('compass/devices/')
      firstLoad && setFirstLoad(false)
      return response
    },
    1000,
    (data) => {
      if (data != null) {
        setDevices(data)
      }
    }
  )

  const getButtons = (device) => {
    return (
      <>
        <Grid item md={2}>
          <CompassButton
            icon={<FeedOutlined />}
            color='secondary'
            onClick={() => navigate('/compass/inspect/viewFile', { state: { urlBack: '/compass/inspect' } })}
          />
        </Grid>
        <Grid item md={2}>
          <CompassButton
            icon={<ContentPasteSearch />}
            color='secondary'
            onClick={() => navigate('/compass/inspect/history', { state: { device, urlBack: '/compass/inspect' } })}
          />
        </Grid>
        <Grid item md={8}>
          <CompassButton
            icon={<QueryStatsIcon />}
            color='primary'
            label={t_('Control procedure')}
            onClick={() => navigate('/compass/inspect/procedure', { state: { device, urlBack: '/compass/inspect' } })}
          />
        </Grid>
      </>
    )
  }

  return (
    firstLoad
      ? <Stack sx={{ width: '100%', height: '80vh' }} direction='row' justifyContent='center' alignItems='center'><CircularProgress size='15rem' /></Stack>
      : (
        <CompassGrid
          items={devices}
          fullScreenItem={fullScreen}
          renderItem={(device, isFullscreen) =>
            <CompassCard
              device={device}
              buttons={getButtons(device)}
              fabricationOrder={device.currentFabricationOrders[0]}
              onDoubleClick={() => setFullScreen(isFullscreen ? null : device)}
              isFullScreen={isFullscreen}
            />}
        />
        )
  )
}
