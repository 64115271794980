import { Stack } from '@mui/system'
import { Button, Typography, ButtonGroup, ToggleButtonGroup, ToggleButton } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

const PlannerCalendarToolbar = (props) => {
  const {
    setDatesInfo,
    onDateChange,
    currentDuration,
    dispatchNewDuration,
    setGroupby,
    groupby
  } = props
  const { t_ } = useSmartTranslation()

  const changeView = async (newView) => {
    // Dont allow deselecing view
    if (newView == null) {
      return
    }
    // By default day time
    let value = 24 * 60 * 60 * 1000
    if (newView === 'weeks') {
      value = value * 7
    } else if (newView === 'months') {
      value = value * 28
    }
    dispatchNewDuration({ type: 'SET_RANGE', value })
  }

  // Check currently selected view
  let currentView = null
  if (currentDuration.value === 1 && currentDuration.mode !== 'ms' && currentDuration.mode !== 'hours') {
    currentView = currentDuration.mode
  }
  const handleModeChange = (event) => {
    setGroupby(event.target.value)
  }
  const currentDate = new Date()
  const disableTodayButton = setDatesInfo === null || (setDatesInfo.start < currentDate && setDatesInfo.end > currentDate)

  return (
    <Stack direction='row' alignItems='flex-end' justifyContent='space-between'>
      <Stack direction='row' gap={3}>
        <ToggleButtonGroup exclusive value={currentView} onChange={(event) => changeView(event.target.value)} size='small'>
          <ToggleButton value='days'>{t_('Day')}</ToggleButton>
          <ToggleButton value='weeks'>{t_('Week')}</ToggleButton>
          <ToggleButton value='months'>{t_('Month')}</ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup exclusive value={groupby} onChange={(event) => handleModeChange(event)} size='small'>
          <ToggleButton value='devices'>{t_('Assets')}</ToggleButton>
          <ToggleButton value='parts'>{t_('Parts')}</ToggleButton>
          <ToggleButton value='technologies'>{t_('Technologies')}</ToggleButton>
          <ToggleButton value='references'>{t_('References')}</ToggleButton>
          <ToggleButton value='fabrication_orders'>{t_('Fabrication orders')}</ToggleButton>
        </ToggleButtonGroup>
        <ButtonGroup>
          <Button disabled={currentDuration.isMaxValue} onClick={() => dispatchNewDuration({ type: 'ZOOM_OUT' })}><ZoomOutIcon /></Button>
          <Button disabled={currentDuration.isMinValue} onClick={() => dispatchNewDuration({ type: 'ZOOM_IN' })}><ZoomInIcon /></Button>
        </ButtonGroup>
      </Stack>
      <Stack direction='row' gap={3} alignItems='center'>
        <Typography variant='h4' sx={{ fontSize: '1.5rem' }}>{setDatesInfo !== null ? setDatesInfo.view.title : null}</Typography>
        <ButtonGroup>
          <Button variant='outlined' onClick={() => onDateChange('prev')}><KeyboardArrowLeftIcon /></Button>
          <Button variant='outlined' disabled={disableTodayButton} onClick={() => onDateChange('today')}>{t_('Today')}</Button>
          <Button variant='outlined' onClick={() => onDateChange('next')}><KeyboardArrowRightIcon /></Button>
        </ButtonGroup>
      </Stack>
    </Stack>
  )
}

export default PlannerCalendarToolbar
