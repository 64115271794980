import { apiGet, apiPost, apiPut, apiDelete } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { IconButton } from '@mui/material'
import { Close, Done } from '@mui/icons-material'
import { useState } from 'react'

const Standard = () => {
  const { t_ } = useSmartTranslation()
  const [editingRowIndex, setEditingRowIndex] = useState(-2)

  const handleCurrentStandard = (row) => {
    row.currentStandard = !row.currentStandard
  }

  const onEditIndexChanged = (editIndex) => {
    setEditingRowIndex(editIndex)
  }

  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Name'),
      field: 'name',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    },
    {
      name: t_('Visible'),
      field: 'currentStandard',
      defaultNewValue: false,
      render: (row) => (
        <IconButton
          disabled={!(row['smart-table-index'] === editingRowIndex) || row.currentStandard}
          onClick={() => handleCurrentStandard(row)}
        >
          {row.currentStandard ? <Done color='primary' value /> : <Close color='error' />}
        </IconButton>
      )
    }
  ]

  const dataFetch = async (pagination) => {
    const standards = await apiGet('standards', pagination)
    return standards
  }

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('standard', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        return apiPost('standard', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('standard/' + rows[0].id, rows)
        } else {
          return apiDelete('standards', rows)
        }
      },
      visibilityButton: (row) => {
        return !row.systemDefault
      }
    }
  }

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Material standards')}
      dataFetch={dataFetch}
      actions={actions}
      pageable
      multipleSelection
      onEditIndexChanged={onEditIndexChanged}
    />
  )
}

export default Standard
