import ManufacturingOrder from '../configuration/orders/FabricationOrder'
import Order from '../configuration/orders/Order'
import SmartNavigator from '../generic/SmartNavigator'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

const MainOrders = (props) => {
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('Orders'),
      component: <Order />,
      to: '/orders'
    },
    {
      title: t_('Fabrication orders'),
      component: <ManufacturingOrder allFoMode />,
      to: '/fabricationOrders'
    }
  ]
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  )
}

export default MainOrders
