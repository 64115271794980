import { Stack, Typography } from '@mui/material'

const ChecklistQuantityIndicator = ({ quantity, total, sx }) => {
  return (
    <Stack direction='row' sx={sx}>
      <Typography color={quantity === total ? 'secondary' : 'error'}>{quantity}</Typography>
      <Typography>/</Typography>
      <Typography>{total}</Typography>
    </Stack>
  )
}

export default ChecklistQuantityIndicator
