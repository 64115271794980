import React, { useState, useEffect, useCallback } from 'react'
import {
  useNodesState,
  useEdgesState
} from 'reactflow'

import 'reactflow/dist/style.css'

import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import { SmartReactflow } from './smartreactflow'
import AlertUI from '../../generic/AlertUI'
import { Grid, IconButton, Paper, Slide, Stack, TextField, Typography } from '@mui/material'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useToggle } from '../../generic/hooks/useToggle'
import { SmartDeleteDialog, SmartDialog } from '../../generic/utilities/SmartDialog'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { NodeTypesBar } from './nodetypes'
import SortIcon from '@mui/icons-material/Sort'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import getWorkflowNodesAndEdges from './utils'
import { TechnologyNode } from './customnodes'
import { FULL_HEIGHT } from '../../generic/utilities/size_utils'
import { SmartToolBar } from '../../generic/utilities/smarttoolbar'

const defaultViewport = { x: 0, y: 0, zoom: 0.5 }

const initialNodes = (idStartNode, positionXStartNode, positionYStartNode, idEndNode, positionXEndNode, positionYEndNode) => {
  return [{
    id: 'start',
    type: 'startNode',
    data: { label: 'Start', workflowPositionId: idStartNode },
    position: { x: positionXStartNode || -300, y: positionYStartNode || 0 },
    sourcePosition: 'right'
  },
  {
    id: 'end',
    type: 'endNode',
    data: { label: 'End', workflowPositionId: idEndNode },
    position: { x: positionXEndNode || 300, y: positionYEndNode || 0 },
    targetPosition: 'left'
  }]
}

const Workflow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes(null))
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [technologies, setTechnologies] = useState(null)
  const [workflowName, setWorkflowName] = useState('')
  const [workflowVersion, setWorkflowVersion] = useState(1)
  const [workflowDescription, setWorkflowDescription] = useState('')
  const [AlertElement, showAlert] = AlertUI()
  const [showWorkflowTable, setShowWorkflowTable] = useState(false)
  const [currentSelectedWorkflow, setCurrentSelectedWorkflow] = useState(null)
  const [fitView, toogleFitView] = useToggle()
  const [showDeleteWorkflowDialog, setShowDeleteWorkflowDialog] = useState(false)
  const [resetSelectedRow, toogleResetSelectedRow] = useToggle()
  const [technologyNum, setTechnologyNum] = useState(1)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [openWorkflowInfoDialog, setOpenWorkflowInfoDialog] = useState(false)
  const [selectedWorkflowToCopy, setSelectedWorkflowToCopy] = useState(null)
  const [workflowCopyName, setWorkflowCopyName] = useState('')
  const [workflowCopyDescription, setWorkflowCopyDescription] = useState('')
  const [workflowsSimpleData, setWorkflowsSimpleData] = useState([])
  const [simpleWorkflowsLoadToggle, reloadSimpleWorkflows] = useToggle()

  const { t_ } = useSmartTranslation()
  useEffect(() => {
    const fetchData = async () => {
      if (technologies === null) {
        const technologies = await apiGet('technologiesWorkflow')
        setTechnologies(technologies)
      }
    }
    fetchData()
  }, [technologies])

  useEffect(() => {
    const loadWorkflows = async () => {
      setWorkflowsSimpleData(await apiGet('workflowsAllVersionsSimpleData'))
    }
    loadWorkflows()
  }, [simpleWorkflowsLoadToggle])

  const onAddNewButtonClick = () => {
    setNodes(initialNodes(null))
    setEdges([])
    setWorkflowName('')
    setWorkflowDescription('')
    setWorkflowVersion(1)
    setCurrentSelectedWorkflow(null)
    setTechnologyNum(1)
  }

  const onDeleteElementClick = () => {
    setShowDeleteWorkflowDialog(true)
  }

  const onDeleteWorkflow = async () => {
    await apiDelete('workflows/' + currentSelectedWorkflow.id).then(() => {
      showAlert({
        title: '',
        message: t_('Deleted correctly.'),
        severity: 'success'
      })
      setShowDeleteWorkflowDialog(false)
      setShowWorkflowTable(false)
      onAddNewButtonClick()
      reloadSimpleWorkflows()
    }).catch((error) => {
      showAlert({
        title: '',
        message: t_(error.message),
        severity: error.name
      })
    })
  }

  const onChangeWorkflowName = (newValue) => {
    setWorkflowName(newValue)
  }

  const onChangeWorkflowDescription = (newValue) => {
    setWorkflowDescription(newValue)
  }

  const onChangeWorkflowVersion = (newValue) => {
    setWorkflowVersion(newValue)
  }

  const onSaveElementClick = async (isSaveAs) => {
    if ((!workflowName && !isSaveAs) || (isSaveAs && !workflowCopyName)) {
      showAlert({
        title: '',
        message: t_('The workflow name is required'),
        severity: 'error'
      })
      return
    }

    if (nodes.length === 2) {
      showAlert({
        title: '',
        message: t_('The workflow is empty'),
        severity: 'error'
      })
      return
    }

    if (edges.length === 0 || edges.length < ((nodes.length - 2) * 2 + 1)) {
      showAlert({
        title: '',
        message: t_('There are unconnected jobs'),
        severity: 'error'
      })
      return
    }

    if (edges.length === 0 || edges.length > ((nodes.length - 2) * 2 + 1)) {
      showAlert({
        title: '',
        message: t_('There are to many connections'),
        severity: 'error'
      })
      return
    }

    if (currentSelectedWorkflow?.workflowIsInUse && workflowVersion === currentSelectedWorkflow?.version) {
      showAlert({
        title: '',
        message: t_('Current workflow is in use create new version'),
        severity: 'error'
      })
      return
    }

    const jobReferenceList = []
    const jobPositions = []

    nodes.forEach((node, index) => {
      if (node.id === 'start' || node.id === 'end') {
        const isStart = node.id === 'start'
        jobPositions.push({ startNode: isStart, endNode: !isStart, positionX: node.position.x, positionY: node.position.y, id: node.data.workflowPositionId })
        return
      }

      const jobId = node.id.substring(node.id.lastIndexOf('-') + 1)

      const isFirst = edges.find((edge) => edge.source === 'start' && edge.target === node.id) !== undefined
      const edgeOk = edges.find((edge) => edge.source === node.id && edge.sourceHandle === 'OK')
      const jobReferenceResult = []

      if (edgeOk) {
        const nodeOk = nodes.find((node) => node.id === edgeOk.target)
        const nodeOkId = nodeOk.id.substring(nodeOk.id.lastIndexOf('-') + 1)
        const jobReferenceOk = { operationResult: 'OK', nextJobReference: nodeOkId === 'end' ? null : nodeOkId, connectivity: edgeOk.data.connectivity }
        jobReferenceResult.push(jobReferenceOk)
      }

      const edgeNotOk = edges.find((edge) => edge.source === node.id && edge.sourceHandle === 'NOT_OK')
      if (edgeNotOk) {
        const nodeNotOk = nodes.find((node) => node.id === edgeNotOk.target)
        const nodeNotOkId = nodeNotOk.id.substring(nodeNotOk.id.lastIndexOf('-') + 1)
        const jobReferenceNotOk = { operationResult: 'FAIL', nextJobReference: nodeNotOkId === 'end' ? null : nodeNotOkId, connectivity: edgeNotOk.data.connectivity }
        jobReferenceResult.push(jobReferenceNotOk)
      }

      const jobReference = {
        id: jobId,
        jobIsFirst: isFirst,
        technology: technologies.find(technology => technology.name === node.data.technologyName),
        jobReferenceResults: jobReferenceResult,
        jobOrder: index,
        name: node.data.jobName,
        rework: node.data.rework
      }

      jobReferenceList.push(jobReference)
      jobPositions.push({ jobReference, startNode: false, endNode: false, positionX: node.position.x, positionY: node.position.y, id: node.data.workflowPositionId })
    })

    const workflow = {
      id: currentSelectedWorkflow?.id,
      name: isSaveAs ? workflowCopyName : workflowName,
      description: isSaveAs ? workflowCopyDescription : workflowDescription,
      version: isSaveAs ? 1 : workflowVersion,
      jobReferences: jobReferenceList,
      workflowPositions: jobPositions,
      versionOf: currentSelectedWorkflow?.versionOf
    }

    if (isSaveAs) {
      apiPost('workflow/copy', workflow).then(() => {
        showAlert({ message: t_('Created correctly.'), severity: 'success' })
        reloadSimpleWorkflows()
      }).catch((error) => {
        showAlert({ message: error.message, severity: error.name })
      })
      return
    }
    if (!currentSelectedWorkflow) {
      apiPost('workflows', workflow).then((data) => {
        updateIdValues(data)
        showAlert({ message: t_('Created correctly.'), severity: 'success' })
        reloadSimpleWorkflows()
      }).catch((error) => {
        showAlert({ message: error.message, severity: error.name })
      })
      return
    }

    apiPut('workflows', workflow).then((data) => {
      updateIdValues(data)
      showAlert({ message: t_('Updated correctly.'), severity: 'success' })
      reloadSimpleWorkflows()
    }).catch((error) => {
      showAlert({ message: error.message, severity: error.name })
    })
  }

  const updateIdValues = (jobIdMapper) => {
    // Update ids and next technolyNum value with the ones on the db
    let maxTechnologyId = 0
    const newNodes = structuredClone(nodes)
    const newEdges = structuredClone(edges)
    for (const key in jobIdMapper) {
      if (jobIdMapper[key] > maxTechnologyId) {
        maxTechnologyId = jobIdMapper[key]
      }
      const node = newNodes.find((node) => node.id.substring(node.id.lastIndexOf('-') + 1) === key)
      const previoustJobId = node.id
      const newJobId = node.data.technologyName + '-' + jobIdMapper[key]
      node.id = newJobId
      newEdges.filter((edge) => edge.source === previoustJobId)?.forEach((edge) => {
        edge.source = newJobId
      })
      newEdges.filter((edge) => edge.target === previoustJobId)?.forEach((edge) => {
        edge.target = newJobId
      })
    }
    setTechnologyNum(maxTechnologyId + 1)
    setNodes(newNodes)
    setEdges(newEdges)
  }

  const columns = [
    {
      name: t_('Name'),
      field: 'name'
    },
    {
      name: t_('Version'),
      field: 'version'
    },
    {
      name: t_('Description'),
      field: 'description'
    }
  ]

  const newNode = useCallback((type, position, technologyNum) => {
    return {
      id: `${type}-` + technologyNum,
      type: 'technologyNode',
      position,
      data: {
        id: `${type}-` + technologyNum,
        technologyName: `${type}`,
        jobName: '',
        rework: false,
        type: 'default',
        selected: false,
        workflowPositionId: null
      }
    }
  }, [])

  const createNodeData = useCallback((row, position, jobReference) => {
    return {
      technologyName: jobReference.technology.name,
      jobName: jobReference.name,
      rework: jobReference.rework,
      type: 'default',
      selected: false,
      workflowPositionId: position.id
      // workflowInUse: row.workflowIsInUse
    }
  }, [])

  const changeCurrentFlow = (row) => {
    onAddNewButtonClick()
    setCurrentSelectedWorkflow(row)
    setWorkflowName(row.name)
    setWorkflowDescription(row.description)
    setWorkflowVersion(row.version)

    const result = getWorkflowNodesAndEdges({ workflow: row, technologyNodeData: (jobReference, position) => createNodeData(row, position, jobReference) })

    setNodes(result.nodes)
    setEdges(result.edges)
    setTechnologyNum(result.maxJobIdNum)
    toogleFitView()
  }

  const onSaveAsElementClick = () => {
    setWorkflowCopyName('')
    setWorkflowCopyDescription('')
    setOpenWorkflowInfoDialog(true)
  }

  const handleButtonClick = (event, row) => {
    event.stopPropagation()
    setSelectedWorkflowToCopy(row)
    setOpenConfirmationDialog(true)
  }

  const generateNewCopyOfWorkflow = async (row) => {
    try {
      await apiPost('workflow/copy', row)
      reloadSimpleWorkflows()
    } catch (error) {
      showAlert({
        title: '',
        message: t_(error.message),
        severity: error.name
      })
    }
  }

  const dataFetchVersions = async (id) => {
    return apiGet('workflows/' + id + '/versions')
  }

  const actions = {
    accordion: {
      visibilityButton: (row) => {
        return row.moreVersions
      },
      render: (row) => {
        if (!row.moreVersions) {
          return null
        }

        return (
          <SmartMateriaUITable
            actions={actions}
            columns={columns}
            sticky={false}
            dataFetch={() => dataFetchVersions(row.versionOf)}
            toolbarDisplay='none'
            dense
            onRowClick={(row) => changeCurrentFlow(row)}
            resetSelectedRow={resetSelectedRow}
            tablePageSize={3}
            sortOptions={{
              default: {
                field: 'version',
                direction: 1
              }
            }}
          />
        )
      }
    },
    custom: [
      {
        name: t_('Save as'),
        render: (row) => {
          return (
            <IconButton onClick={(event) => {
              handleButtonClick(event, row)
            }}
            >
              <ContentCopyIcon />
            </IconButton>
          )
        }
      }
    ]
  }

  const onWorkflowDrop = useCallback(async (workflowId, position) => {
    // Load workflow
    const data = await apiGet('workflows/' + workflowId)

    // Change id
    let technologyNumCurrent = technologyNum
    const jobReferenceIdMap = {}
    data.jobReferences.forEach((jobReference) => {
      technologyNumCurrent++
      jobReferenceIdMap[jobReference.id] = technologyNumCurrent
      jobReference.id = technologyNumCurrent
    })

    const firstJobRefPosition = data.workflowPositions.find((currentPosition) => {
      if (!currentPosition.jobReference) {
        return false
      }
      return currentPosition.jobReference.jobIsFirst
    })
    const positionOffset = { x: position.x - firstJobRefPosition.positionX, y: position.y - firstJobRefPosition.positionY }

    data.workflowPositions = data.workflowPositions.filter((currentPosition) => currentPosition.jobReference)
    data.workflowPositions.forEach((currentPosition) => {
      currentPosition.jobReference.id = jobReferenceIdMap[currentPosition.jobReference.id]

      // Remove id
      currentPosition.id = null

      // Move workflow to match drop position
      currentPosition.positionX += positionOffset.x
      currentPosition.positionY += positionOffset.y
    })

    data.jobReferences.forEach((jobReference) => {
      jobReference.jobReferenceResults.forEach((result) => {
        if (result.nextJobReference) {
          result.nextJobReference = jobReferenceIdMap[result.nextJobReference]
        }
      })
    })

    const result = getWorkflowNodesAndEdges({ workflow: data, technologyNodeData: (jobReference, position) => createNodeData(data, position, jobReference), addStartEndNodes: false })

    setNodes([...nodes, ...result.nodes])
    setEdges([...edges, ...result.edges])
    setTechnologyNum(result.maxJobIdNum)
  }, [edges, nodes, setEdges, setNodes, technologyNum, createNodeData])

  const onDrop = useCallback(
    (event, position) => {
      const type = event.dataTransfer.getData('application/reactflow/nodeType')
      const subType = event.dataTransfer.getData('application/reactflow/subType')
      if (type === 'workflow') {
        onWorkflowDrop(subType, position)
        return
      }

      if (type !== 'technology') {
        return
      }

      // check if the dropped element is valid
      if (typeof subType === 'undefined' || !subType) {
        return
      }
      setNodes((nds) => nds.concat(newNode(subType, position, technologyNum)))
      setTechnologyNum(technologyNum + 1)
    },
    [setNodes, setTechnologyNum, technologyNum, newNode, onWorkflowDrop]
  )
  const labelsToolBar = [
    { title: t_('Name'), value: workflowName, onChange: onChangeWorkflowName },
    { title: t_('Version'), value: workflowVersion, onChange: onChangeWorkflowVersion, numeric: true, negativeNumbers: false },
    { title: t_('Description'), value: workflowDescription, onChange: onChangeWorkflowDescription }
  ]

  return (
    <>
      <SmartDialog
        message={<Typography sx={{ marginTop: '25px' }}>{t_('Are you sure you want to make a copy of the workflow?')}</Typography>}
        title={{ icon: <SortIcon fontSize='large' />, render: <Typography variant='h5'>{t_('Copy workflow')}</Typography> }}
        setOpen={openConfirmationDialog}
        renderComponent={
          <SmartMateriaUITable
            columns={columns}
            dataFetch={new Array(selectedWorkflowToCopy)}
          />
        }
        close={false}
        cancel
        cancelCallback={() => {
          setOpenConfirmationDialog(false)
        }}
        acceptCallback={() => {
          generateNewCopyOfWorkflow(selectedWorkflowToCopy)
          setOpenConfirmationDialog(false)
        }}
      />
      <SmartDialog
        title={{ icon: <SortIcon fontSize='large' />, render: <Typography variant='h5'>{t_('New workflow')}</Typography> }}
        message={<Typography sx={{ marginTop: '25px' }}>{t_('Introduce name and description for the new workflow')}</Typography>}
        setOpen={openWorkflowInfoDialog}
        renderComponent={
          <>
            <Grid container spacing={3} sx={{ padding: (theme) => theme.spacing(2) }}>
              <Grid item xs={4}>
                <TextField
                  value={workflowCopyName}
                  label={t_('Name')} onChange={(event) => { setWorkflowCopyName(event.target.value) }}
                  inputProps={{
                    maxLength: 45
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  sx={{ width: '100%' }}
                  value={workflowCopyDescription}
                  label={t_('Description')} onChange={(event) => { setWorkflowCopyDescription(event.target.value) }}
                  inputProps={{
                    maxLength: 250
                  }}
                />
              </Grid>
            </Grid>
          </>
        }
        close={false}
        cancel
        cancelCallback={() => {
          setOpenWorkflowInfoDialog(false)
        }}
        acceptCallback={() => {
          onSaveElementClick(true)
          setOpenWorkflowInfoDialog(false)
        }}
      />
      <SmartDeleteDialog
        show={showDeleteWorkflowDialog}
        cancelCallback={() => {
          setShowDeleteWorkflowDialog(false)
        }}
        deleteCallBack={() => {
          onDeleteWorkflow()
          onAddNewButtonClick()
          setShowDeleteWorkflowDialog(false)
        }}
        rows={[currentSelectedWorkflow]}
        columns={columns}
      />
      <div style={{ display: 'grid', gridTemplateRows: 'min-content 1fr min-content', gridTemplateColumns: '12rem 1fr', height: FULL_HEIGHT.WITH_NAV_BAR_AND_SMART_NAVIGATOR_AND_FOOTER, gap: '0 1rem' }}>
        <div style={{ height: '100%', gridRow: 'span 3' }}><NodeTypesBar technologies={technologies} workflows={workflowsSimpleData} /></div>

        <Stack gap={2}>
          {AlertElement}
          <SmartToolBar
            labels={labelsToolBar}
            onAddElementClick={() => {
              toogleResetSelectedRow()
              onAddNewButtonClick()
            }}
            onSaveElementClick={() => onSaveElementClick(false)}
            onDeleteElementClick={() => onDeleteElementClick()}
            disableSaveButton={!currentSelectedWorkflow}
            onSaveAsElementClick={() => onSaveAsElementClick(true)}
          />
        </Stack>
        <SmartReactflow
          nodes={nodes}
          setNodes={setNodes}
          edges={edges}
          setEdges={setEdges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          defaultViewport={defaultViewport}
          showWorkflowTable={showWorkflowTable}
          fitView={fitView}
          nodeComponent={TechnologyNode}
          onDrop={onDrop}
        />
        <Stack>
          <IconButton sx={{ borderRadius: 0, paddingLeft: 0, paddingRight: 0 }} onClick={() => setShowWorkflowTable(!showWorkflowTable)}>{showWorkflowTable ? <KeyboardArrowDown /> : <KeyboardArrowUp />}</IconButton>
          <Slide direction='up' in={showWorkflowTable} mountOnEnter unmountOnExit>
            <Stack direction='row' sx={{ paddingRight: '1rem' }} justifyContent='center'>
              <Paper>
                <SmartMateriaUITable
                  actions={actions}
                  columns={columns}
                  sticky={false}
                  dataFetch='workflows'
                  toolbarDisplay='none'
                  dense
                  onRowClick={(row) => changeCurrentFlow(row)}
                  resetSelectedRow={resetSelectedRow}
                  tablePageSize={5}
                />
              </Paper>
            </Stack>
          </Slide>
        </Stack>
      </div>
    </>
  )
}
export default Workflow
