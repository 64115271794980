import { Button, Stack } from "@mui/material";
import { SmartDialog } from "../../../generic/utilities/SmartDialog";
import { useSmartTranslation } from "../../../generic/hooks/useSmartTranslation";

const PausePartSelectEndJobDialog = (props) => {

  const { t_ } = useSmartTranslation();

  return (
    <SmartDialog
      setOpen={props.setOpen}
      accept={false}
      cancelCallback={props.cancelCallback}
      title={{ render: props.part?.code }}
      autoClose={false}
      renderComponent={
        <Stack gap={3}>
          {t_("Part is currently on a job with multiple tasks. Do you want to end the job before pausing?")}
          <Stack direction="row" gap={3} justifyContent="space-between">
            <Button variant="outlined" onClick={props.pauseNowCallback}>{t_("Pause as soon as possible")}</Button>
            <Button variant="outlined" onClick={props.pauseAtJobEndCallback}>{t_("End the job and pause after")}</Button>
          </Stack>
        </Stack>
      } />
  );
};

export default PausePartSelectEndJobDialog;