import { ExpandMoreRounded } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react'

const accordionStyle = {
  '&.MuiAccordionSummary-root': {
    borderRadius: '5px 5px 0px 0px',
    borderBottom: '0.5px solid rgba(255, 255, 255, 0.12)',
    boxShadow: theme => '0px 0px 1px 0px ' + theme.palette.primary.main + '60, 0px 0px 3px 0px ' + theme.palette.primary.main + '20'
  },
  '&.MuiAccordionDetails-root': {
    borderRadius: '0px 0px 5px 5px',
    boxShadow: theme => '0px 2px 1px -1px ' + theme.palette.primary.main + '60, 0px 1px 1px 0px ' + theme.palette.primary.main + '60, 0px 1px 3px 0px ' + theme.palette.primary.main + '20'
  }
}

const DefaultAccordion = (props) => {
  const {
    title = null,
    titleStyle = {},
    children,
    defaultExpanded = true,
    headerRender = null,
    highlightHeaderOnExpanded = false,
    headerSx = null,
    contentSx = null
  } = props
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()
  const handleAccordionChange = () => (event, isExpanded) => {
    setExpanded(!!isExpanded)
  }
  const renderTitle = () => {
    if (title === null) {
      return
    }
    return (
      <Typography
        variant='h6'
        component='div'
        sx={titleStyle}
      >
        {title.toString().toLocaleUpperCase()}
      </Typography>
    )
  }
  return (

    <Accordion
      defaultExpanded={defaultExpanded}
      // Does not render child when is not expanded
      // https://mui.com/material-ui/react-accordion/#performance
      TransitionProps={{ unmountOnExit: true }}
      onChange={highlightHeaderOnExpanded && handleAccordionChange()}
    >
      <AccordionSummary
        sx={{ ...accordionStyle, ...headerSx }}
        expandIcon={
          <ExpandMoreRounded
            sx={accordionStyle}
          />
        }
        aria-controls='panel1a-content'
        id='panel1a-header'
        style={{
          color: expanded ? theme.palette.primary.main : 'inherit'
        }}
      >
        <Stack justifyContent='space-between' direction='row' alignItems='center' sx={{ width: '100%', paddingRight: '1.5rem' }}>
          {renderTitle()}
          {headerRender}
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{ ...accordionStyle, ...contentSx, padding: '2em' }}
      >
        {
          children
        }

      </AccordionDetails>
    </Accordion>
  )
}

export default DefaultAccordion

DefaultAccordion.propTypes = {
  /**
   * Title to show on the accordion header
   */
  title: PropTypes.string,
  /**
   * Children to show inside the accordion
   */
  children: PropTypes.node.isRequired,
  /**
   * Set the accordion expanded or not at initial render. True by default
   */
  defaultExpanded: PropTypes.bool,
  /**
   * This will render in the accordion header
   */
  headerRender: PropTypes.node
}
