import React from 'react'
import SmartNavigator from '../generic/SmartNavigator'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import QualityType from '../configuration/qualityType/QualityType'

const MainQualityTypes = (props) => {
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('Quality Types'),
      component: <QualityType />
    }
  ]
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  )
}

export default MainQualityTypes
