import { enUS, es, pt, ca, eu, fr } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'

const useSmartTranslation = () => {
  const { t: t_, i18n } = useTranslation()
  return { t_, i18n }
}

const useLocalizationProviderAdapterLocale = () => {
  const { i18n } = useTranslation()

  switch (i18n.language) {
    case 'es':
      return es
    case 'eus':
      return eu
    case 'pt':
      return pt
    case 'ca':
      return ca
    case 'fr':
      return fr
    default:
      return enUS
  }
}

export default useSmartTranslation
export { useLocalizationProviderAdapterLocale }
