import React from 'react'
import SmartNavigator from '../generic/SmartNavigator'
import MaterialGroups from '../configuration/materials/MaterialGroups'
import MaterialTypes from '../configuration/materials/MaterialTypes'
import Standard from '../configuration/materials/Standard'
import Materials from '../configuration/materials/Materials'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

const MainMaterials = () => {
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('Material groups'),
      component: <MaterialGroups />
    },
    {
      title: t_('Material types'),
      component: <MaterialTypes />
    },
    {
      title: t_('Material standards'),
      component: <Standard />
    },
    {
      title: t_('Materials'),
      component: <Materials />
    }
  ]
  return <SmartNavigator componentsInfo={componentsInfo} />
}
export default MainMaterials
