import React from 'react'
import { IconButton } from '@mui/material'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation/index.js'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable.jsx'
import { useLocation, useNavigate } from 'react-router-dom'
import { apiPost } from '../../generic/Api_Functions.jsx'
import { GetDateTime } from '../../generic/DateTimeFunctions.jsx'
import ButtonBack from '../../generic/buttons/floatingButtons/ButtonBack.jsx'
import { RemoveDoneOutlined } from '@mui/icons-material'

const SupervisionIncidentList = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const device = location.state.device
  const { t_ } = useSmartTranslation()

  const dataFetch = async (pagination) => {
    const list = await apiPost('compass/incidences/' + device.id, pagination)
    return list
  }

  const columns = [
    {
      name: t_('Status'),
      field: 'incidence.status.name'
    },
    {
      name: t_('Start date'),
      field: 'startDate',
      render: (row) => {
        return GetDateTime(row.startDate)
      }
    },
    {
      name: t_('End date'),
      field: 'endDate',
      render: (row) => {
        return GetDateTime(row.endDate)
      }
    },
    {
      name: t_('Duration'),
      field: 'duration'
    },
    {
      name: t_('Incidence'),
      field: 'incidence.name'
    },
    {
      name: t_('Comment'),
      field: 'comment'
    }
  ]

  const handleMarkIncidentDone = async (row) => {
    await apiPost('compass/update/incidenceAsDone/' + device.id, row)
  }

  const actions = {
    create: {},
    custom: [
      {
        name: t_('Mark as done'),
        render: (row) => {
          if (row.endDate === null) {
            return (
              <IconButton
                onClick={() => handleMarkIncidentDone(row)}
              >
                <RemoveDoneOutlined />
              </IconButton>
            )
          }
        }
      }
    ]
  }

  return (
    <>
      <SmartMateriaUITable
        title={t_('Incidence') + ' - ' + t_(device.name)}
        columns={columns}
        dataFetch={dataFetch}
        onAddElementClick={() => navigate('/compass/supervision/addIncident', { state: { device } })}
        actions={actions}
        pageable
      />
      <div style={{ width: '100%', float: 'right' }}>
        <ButtonBack buttonAction={() => navigate('/compass/supervision')} />
      </div>
    </>
  )
}

export default SupervisionIncidentList
