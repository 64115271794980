import { Card, CardContent, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { memo } from 'react'
import { Handle, NodeToolbar, Position, useReactFlow } from 'reactflow'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { SmartSelect } from '../../generic/smartSelect'

function JobLinkerNode ({ id, data }) {
  const { t_ } = useSmartTranslation()
  const { getNodes, setNodes } = useReactFlow()
  const handleSize = '0.6rem'

  const okStyle = { top: '20%', background: 'green', width: handleSize, height: handleSize }
  const noOkStyle = { top: '80%', background: 'red', width: handleSize, height: handleSize }

  const onSelectStyle = {
    border: '2px solid #D0D0D0'
  }

  return (
    <>
      <NodeToolbar position={Position.Top}>
        <Card sx={{ backgroundColor: (theme) => theme.palette.background.card }}>
          <CardContent sx={{ paddingBottom: '0px !Important' }}>
            <div style={{ display: 'flex' }}>
              <Stack>
                <Stack direction='row'>
                  <Typography color='primary'> {t_('Tools') + ':'}&nbsp;</Typography><Typography> {data.toolQuantity}</Typography>
                </Stack>
                <Stack direction='row'>
                  <Typography color='primary'>{t_('Total time') + ':'}&nbsp;</Typography><Typography> {data.totalTime}</Typography>
                </Stack>
                <div style={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
                  <SmartSelect
                    value={data.currentRule?.name}
                    selectableOptions={data.ruleList.map(rule => rule.name)}
                    multiple={false}
                    size='small'
                    label={t_('Rule')}
                    onChange={(newValue) => {
                      const rule = data.ruleList.find(rule => rule.name === newValue)
                      const newNodeValues = getNodes().map((node) => {
                        if (node.id === id) {
                          node.data = { ...node.data }
                          node.data.currentRule = rule
                        }
                        return node
                      })

                      setNodes(newNodeValues)
                      data.dispatchEditingData({ type: 'CHANGE_JOB_REFERENCE_RULE', currentSelectedRule: rule, currentPartReferenceJobReferenceIndex: data.partReferenceJobReferenceIndex })
                    }}
                    autoSelectFirst={false}
                    disabled={!data.editMode}
                    disableClearable={false}
                  />
                </div>
              </Stack>
            </div>
          </CardContent>
        </Card>
      </NodeToolbar>
      <div style={data.selected ? onSelectStyle : undefined}>
        <Handle
          id='target'
          type='target'
          className='left'
          position={Position.Left}
          style={{ zIndex: 10000, width: handleSize, height: handleSize }}
          isConnectable={false}
        />
        <Card sx={{ minWidth: '6rem', minHeight: '3rem' }}>
          <CardContent style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0, display: 'inline' }}>
            <Typography component='div'>{data.technologyNane}<br /> {
            data.jobName ? <Box sx={{ fontStyle: 'italic', fontSize: 12 }} display='inline'>{data.jobName}</Box> : null
            }
            </Typography>
          </CardContent>
        </Card>
        <Handle
          id='OK'
          type='source'
          style={okStyle}
          position={Position.Right}
          isConnectable={false}
        />
        <Handle
          id='NOT_OK'
          type='source'
          style={noOkStyle}
          position={Position.Right}
          isConnectable={false}
        />
      </div>
    </>
  )
}

export default memo(JobLinkerNode)
