import React from 'react';
import ActionCenter from '../../maintenance/actions/ActionCenter';
import ActionCenterMenu from '../../maintenance/actions/ActionCenterMenu';

const Process = ({ activePath }) => {
  if (!activePath) {
    return <ActionCenterMenu />;
  }
  return <ActionCenter activePath={activePath} />;
};

export default Process;