import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { apiGet, apiDelete, apiPost, apiPut } from '../../generic/Api_Functions'
import moment from 'moment/moment'
import { Button, Grid, IconButton, TextField, Typography } from '@mui/material'
import { ManageSearchOutlined, PauseCircle, PlayCircleFilledOutlined, StorageRounded } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import PausePartSelectEndJobDialog from '../parts/pause/PausePartSelectEndJobDialog'
import PauseResumeFinishedList from '../parts/pause/PauseResumeFinishedList'
import AlertUI from '../../generic/AlertUI'
import createDialogTitle from '../../generic/utilities/dialogUtil'
import DeleteIcon from '@mui/icons-material/Delete'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import { GetDateTime } from '../../generic/DateTimeFunctions'
import { useToggle } from '../../generic/hooks/useToggle'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { DateTimePicker } from '@mui/x-date-pickers'

// Import typedefs from another file
/**
 *  * @typedef {import('../../generic/utilities/objectStructures/orderWindow/lineOrder').LineOrder} LineOrder LineOrder object
 *  * @typedef {import('../../generic/utilities/objectStructures/globalStructures').IdCodeName} IdCodeName IdCodeName object
 */

/**
 *
 * @param {Object} props Props Object
 * @param {LineOrder} props.lineOrder Line Order object
 * @param {IdCodeName} props.client Client Object
 * @param {Date} props.orderDate Order date
 * @param {boolean} props.addModeByDefault Add mode by default
 *
 *
 * @returns
 */
const ManufacturingOrder = ({ lineOrder, client, orderDate, addModeByDefault, allFoMode }) => {
  const [AlertElement, showAlert] = AlertUI()
  const [AlertElementDialog, showAlertDialog] = AlertUI()
  const [editing, setEditing] = useState(false)
  const [currentFabricationOrder, setCurrentFabricationOrder] = useState(null)
  const [openPartsDialog, setOpenPartsDialog] = useState(false)
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [confirmationAction, setConfirmationAction] = useState('')
  const [rowIdToConfirm, setRowIdToConfirm] = useState(null)
  const { t_ } = useSmartTranslation()
  const [openCopyDialog, setOpenCopyDialog] = useState(false)
  const [unfinishedPartsPauseResume, setUnfinishedPartsPauseResume] = useState([])
  const [partsOnDevicesToPause, setPartsOnDevicesToPause] = useState([])
  const [showDialog, setShowDialog] = useState({ open: false, rowValue: null, openMultiple: false })
  const [isAddingNewRow, setIsAddingNewRow] = useState(false)
  const [lineOrders, setLineOrders] = useState(null)
  const [editingItem, setEditingItem] = useState(null)
  const [ofCopy, setOfCopy] = useState(null)
  const [formData, setFormData] = useState({
    fabricationOrder: '',
    quantity: 1,
    endDate: moment().format('YYYY-MM-DDTHH:mm'),
    priority: 5,
    endDateError: false
  })
  const [resetMultipleSelectionValue, toogleResetMultipleSelectionValue] = useToggle()
  const generateCopyNumber = async (originalOrder) => {
    try {
      return await apiGet('getFabricationOrderNumberCopy/' + originalOrder)
    } catch (error) {
      showAlert({
        title: t_('Error getting copy data'),
        message: t_(error.message),
        severity: error.name
      })
    }
  }

  const handleCopyButtonClick = async (row) => {
    try {
      const copyNumber = await generateCopyNumber(row.fabricationOrder)
      const rowCopy = { ...row }
      rowCopy.fabricationOrder = row.fabricationOrder + '-' + copyNumber
      setOfCopy(rowCopy)
      setFormData({
        quantity: row.quantity,
        endDate: '',
        priority: row.priority
      })
      setOpenCopyDialog(true)
    } catch (error) {
      showAlert({
        title: t_('Error generating copy number'),
        message: t_(error.message),
        severity: error.name
      })
    }
  }
  const handleAcceptButtonClick = async () => {
    if (!formData.endDate || formData.endDate === ' ') {
      showAlertDialog({
        title: t_('Error'),
        message: t_('Delivery date cannot be null'),
        severity: 'error'
      })
      setFormData(prevData => ({
        ...prevData,
        endDateError: true
      }))
      return
    }
    const orderDate = moment(ofCopy.date)
    const endDate = moment(formData.endDate)
    if (!endDate.isValid() || endDate.isBefore(orderDate)) {
      showAlertDialog({
        title: t_('Error'),
        message: t_('Delivery date must be later than the order date'),
        severity: 'error'
      })
      setFormData(prevData => ({
        ...prevData,
        endDateError: true
      }))
      return
    }
    const priority = Number(formData.priority)
    if (isNaN(priority) || priority <= 0 || priority > 10) {
      showAlertDialog({
        title: t_('Error'),
        message: t_('priority must be greater than 0 and less than or equal to 10'),
        severity: 'error'
      })
      setFormData(prevData => ({
        ...prevData,
        priorityError: true
      }))
      return
    }
    ofCopy.endDate = formData.endDate
    ofCopy.quantity = formData.quantity
    ofCopy.priority = formData.priority
    ofCopy.id = null
    setOpenCopyDialog(false)
    try {
      await apiPost('fabricationOrder', ofCopy)
      setFormData(prevData => ({
        ...prevData,
        endDateError: false
      }))
      showAlert({
        title: t_(''),
        message: t_('Copied correctly'),
        severity: 'success'
      })
    } catch (error) {
      showAlert({
        title: t_('Error creating fabrication order'),
        message: t_(error.message),
        severity: error.name
      })
    }
  }

  useEffect(() => {
    const getLineOrders = async (lineOrder) => {
      return await apiGet('order/' + lineOrder + '/lineOrderShortData/')
    }
    if (editingItem != null && editingItem.order) {
      if (editingItem.order.id !== undefined) {
        const response = getLineOrders(editingItem.order.id)
        setLineOrders(response)
      }
    }
  }, [editingItem])
  const columns = [
    {
      name: t_('Fabrication Order'),
      field: 'fabricationOrder',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: true
    },

    {
      name: t_('Part Reference'),
      field: 'partReference.name',
      mandatory: allFoMode,
      type: 'select',
      hide: isAddingNewRow && !allFoMode,
      readOnly: true,
      edit: {
        source: async () => {
          return await apiGet('partReferencesShortData')
        },
        id: 'id',
        field: 'name'
      }
    },

    {
      name: t_('Quantity'),
      field: 'quantity',
      mandatory: true,
      numeric: true,
      defaultNewValue: 1
    },
    {
      name: t_('Delivery date'),
      field: 'endDate',
      mandatory: true,
      type: 'dateTimePicker'
    },
    {
      name: t_('Priority'),
      field: 'priority',
      numeric: true,
      mandatory: true,
      defaultNewValue: 5
    },
    {
      name: t_('Client'),
      field: 'client.name',
      type: 'select',
      defaultNewValue: client,
      edit: {
        source: async () => {
          return await apiGet('clients')
        },
        id: 'id',
        field: 'name'
      }
    },
    {
      name: t_('Order'),
      field: 'order.code',
      type: 'select',
      hide: !allFoMode,
      readOnly: true,
      edit: {
        source: async () => {
          return await apiGet('ordersShortData/')
        },
        id: 'id',
        field: 'code',
        onClick_render: async (row) => {
          if (row.order && row.order.id) {
            row.date = row.order.date
            setLineOrders(await apiGet('order/' + row.order.id + '/lineOrderShortData/'))
          } else {
            setLineOrders(null)
            row.date = undefined
            row.lineOrder = undefined
          }
        }
      }
    },
    {
      name: t_('Line'),
      field: 'lineOrder.lineNumber',
      type: 'select',
      hide: !allFoMode,
      edit: {
        source: async () => {
          return lineOrders
        },
        id: 'id',
        field: 'lineNumber',
        onClick_render: async (row) => {
          if (row.lineOrder && row.lineOrder.id) {
            row.partReference = row.lineOrder.partReference
          }
        }
      },
      readOnly: true
    },
    {
      name: t_('Order date'),
      field: 'date',
      render: (row) => row.date ? GetDateTime(row.date) : orderDate ? GetDateTime(orderDate) : ''
    }
  ]

  const dataFetch = async (pagination) => {
    if (allFoMode) {
      return await apiGet('allFabricationOrder', pagination)
    } else {
      return await apiGet('lineOrder/' + lineOrder.id + '/fabricationOrder', pagination)
    }
  }

  const checkClientValue = (row) => {
    if (!row.client) {
      row.client = null
      return
    }
    if (Object.keys(row.client).length === 0) {
      row.client = null
    }
  }
  const actions = {
    edit: {
      fetchFunction: (row) => {
        checkClientValue(row)
        if (!allFoMode) {
          row.lineOrder = lineOrder
        }
        return apiPut('fabricationOrder', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        checkClientValue(row)
        if (!allFoMode) {
          row.lineOrder = lineOrder
        }
        return apiPost('fabricationOrder', row)
      }
    },
    delete: {
      fetchFunction: async (rows) => {
        if (rows.length === 1) {
          const allRaw = await apiGet('fabricationOrder/' + rows[0].id + '/allRaw')
          if (!allRaw) {
            showAlert({ severity: 'error', title: t_('This OF is linked to a part and isnt in RAW'), message: '  ' })
            return { disableAlert: true }
          } else {
            setShowDialog({ open: true, rowValue: rows[0], openMultiple: false })
            return { disableAlert: true }
          }
        } else {
          setShowDialog({ open: true, rowValues: rows, openMultiple: true })
          return { disableAlert: true }
        }
      }
    },
    custom: [
      {
        render: (row) => {
          return (
            <IconButton
              disabled={editing}
              onClick={() => {
                handleCopyButtonClick(row)
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          )
        }
      },
      {
        render: (row) => {
          return (
            <IconButton
              disabled={editing}
              onClick={async () => handlePauseResumeClick('resume', row.id)}
            >
              <PlayCircleFilledOutlined />
            </IconButton>
          )
        }
      },
      {
        render: (row) => {
          return (
            <IconButton
              disabled={editing}
              onClick={async () => handlePauseResumeClick('pause', row.id)}
            >
              <PauseCircle />
            </IconButton>
          )
        }
      },
      {
        render: (row) => {
          return (
            <IconButton
              disabled={editing}
              onClick={() => {
                setCurrentFabricationOrder(row)
                setOpenPartsDialog(true)
              }}
            >
              <ManageSearchOutlined />
            </IconButton>
          )
        }
      }

    ]
  }

  // ORder by second collumn as first one will be the same on all
  const sortOptions = {
    default: {
      field: 'endDate',
      direction: 1
    }
  }
  const validator = (row) => {
    const date = row.date ? moment(row.date, moment.ISO_8601, true) : moment(orderDate, moment.ISO_8601, true)
    const endDate = moment(row.endDate, moment.ISO_8601, true)
    if (endDate.isValid() && endDate.isValid()) {
      if (endDate.isBefore(date)) {
        return { errorFields: ['endDate'], alertMessage: 'Delivery date must be later than the order date' }
      }
    } else {
      return false
    }
  }

  const partDialogDataFetch = async (pagination) => {
    const parts = await apiGet('partsFabricationOrder/' + currentFabricationOrder.id, pagination)
    return parts
  }

  const onPauseWorkflowForce = async (partId, endJob) => {
    partsOnDevicesToPause.shift()
    setPartsOnDevicesToPause([...partsOnDevicesToPause])
    await apiPut('part/pause/' + partId + '/' + endJob)
  }

  const handlePauseResumeClick = async (action, rowId) => {
    setConfirmationDialogOpen(true)
    setConfirmationAction(action)
    setRowIdToConfirm(rowId)
  }

  const handleConfirmationAccept = async () => {
    setConfirmationDialogOpen(false)
    if (confirmationAction === 'pause') {
      const data = await apiPut('order/pause/' + rowIdToConfirm)
      showAlert({
        title: t_('Part(s) has been paused'),
        severity: 'success'
      })
      if (data.length > 0) {
        const finishedParts = []
        const partsOnDevice = []
        // Separate different data
        data.forEach(element => {
          if (element.status === 'FINISHED') {
            finishedParts.push(element)
          } else {
            partsOnDevice.push(element)
          }
        })
        setUnfinishedPartsPauseResume(finishedParts)
        setPartsOnDevicesToPause(partsOnDevice)
      }
    } else if (confirmationAction === 'resume') {
      const data = await apiPut('order/resume/' + rowIdToConfirm)
      showAlert({
        title: t_('Part(s) has been resumed'),
        severity: 'success'
      })
      if (data.length > 0) {
        setUnfinishedPartsPauseResume(data)
      }
    }
  }
  const dialogEditCopy = (
    <Grid container spacing={1} marginTop={2}>
      {AlertElementDialog}
      <Grid item xs={3}>
        <TextField
          fullWidth
          margin='normal'
          value={ofCopy?.fabricationOrder}
          readOnly
          disabled
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          margin='normal'
          label='Quantity'
          type='number'
          value={formData.quantity}
          onChange={(e) => setFormData({ ...formData, quantity: e.target.value })}
        />
      </Grid>
      <Grid item xs={3} marginTop='16px'>
        <DateTimePicker
          fullWidth
          margin='normal'
          label='Delivery date'
          value={formData.endDate}
          onChange={(newValue) => setFormData({ ...formData, endDate: newValue })}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              error={formData.endDateError}
            />
          )}
          helperText={formData.endDateError ? t_('Delivery date must be later than or equal to the order date') : ''}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          margin='normal'
          label='Priority'
          type='number'
          value={formData.priority}
          onChange={(e) => setFormData({ ...formData, priority: e.target.value })}
          inputProps={{ min: 1, max: 10 }}
          error={formData.priorityError}
          helperText={formData.priorityError ? t_('Priority must be greater than 0 and less than or equal to 10') : ''}
        />
      </Grid>
    </Grid>
  )
  const iconToDisplay = confirmationAction === 'pause' ? PauseCircle : PlayCircleFilledOutlined

  const deleteFabricationOrders = (unlink) => {
    return apiDelete('order/fabricationOrderMulti/' + unlink, showDialog.rowValues.rows).then(() => { showAlert({ severity: 'success', title: t_('Deleted correctly'), message: '  ' }); toogleResetMultipleSelectionValue() }).catch(() => { showAlert({ severity: 'error', title: t_('Any of the OF is linked to a part and isnt in RAW'), message: '  ' }) })
  }
  const deleteFabricationOrder = (unlink) => {
    return apiDelete('fabricationOrder/' + showDialog.rowValue.id + '/' + unlink).then(() => { showAlert({ severity: 'success', title: t_('Deleted correctly'), message: '  ' }) }).catch(() => { showAlert({ severity: 'error', title: t_('This OF is linked to a part and isnt in RAW'), message: '  ' }); toogleResetMultipleSelectionValue() })
  }
  return (
    <>
      <SmartDialog
        title={{ icon: <DeleteIcon color='primary' />, render: <Typography variant='h5' color='primary' sx={{ marginLeft: '0.3em' }}>This OF is linked to a part</Typography> }}
        message={showDialog.openMultiple ? 'Any of the OF is linked to a part, do you want to unlink or delete it?' : 'This OF is linked to a part, do you want to unlink or delete it?'}
        cancel
        cancelCallback={() => setShowDialog({ ...showDialog, open: false })}
        accept={false}
        close={false}
        setOpen={showDialog.open}
        renderCustomButtons={
          <>
            <Button color='error' onClick={() => { showDialog.openMultiple ? deleteFabricationOrders(false) : deleteFabricationOrder(false); setShowDialog(prevValue => { return { ...prevValue, open: false, openMultiple: false } }) }} variant='outlined'>
              <Typography color='error'>{t_('Unlink')} </Typography>
              <LinkOffIcon color='error' sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
            </Button>
            <Button color='error' onClick={() => { showDialog.openMultiple ? deleteFabricationOrders(true) : deleteFabricationOrder(true); setShowDialog(prevValue => { return { ...prevValue, open: false, openMultiple: false } }) }} variant='outlined'>
              <Typography color='error'>{t_('Delete')} </Typography>
              <DeleteIcon color='error' sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
            </Button>
          </>
        }

      />

      <SmartDialog
        setOpen={confirmationDialogOpen}
        message={t_(`Are you sure you want to ${confirmationAction} the fabrication order?`)}
        title={createDialogTitle({ title: t_('Confirm'), icon: iconToDisplay })}
        accept
        cancel
        close={false}
        acceptCallback={handleConfirmationAccept}
        cancelCallback={() => setConfirmationDialogOpen(false)}
      />
      <PausePartSelectEndJobDialog
        setOpen={unfinishedPartsPauseResume.length === 0 && partsOnDevicesToPause.length > 0 ? partsOnDevicesToPause[0].id : false}
        cancelCallback={() => {
          partsOnDevicesToPause.shift()
          setPartsOnDevicesToPause([...partsOnDevicesToPause])
        }}
        part={partsOnDevicesToPause.length > 0 ? partsOnDevicesToPause[0] : null}
        pauseNowCallback={() => { onPauseWorkflowForce(partsOnDevicesToPause[0].id, false) }}
        pauseAtJobEndCallback={() => { onPauseWorkflowForce(partsOnDevicesToPause[0].id, true) }}
      />
      <PauseResumeFinishedList
        setOpen={unfinishedPartsPauseResume.length > 0}
        parts={unfinishedPartsPauseResume}
        closeCallback={() => setUnfinishedPartsPauseResume([])}
      />
      <SmartDialog
        setOpen={openPartsDialog}
        close
        accept={false}
        cancel={false}
        closeCallback={() => setOpenPartsDialog(false)}
        title={{ icon: <StorageRounded color='primary' />, render: <Typography variant='h5' color='primary' sx={{ marginLeft: '0.3em' }}>{currentFabricationOrder?.fabricationOrder} - {t_('Parts')}</Typography> }}
        renderComponent={
          <SmartMateriaUITable
            columns={[{
              name: t_('Code'),
              field: 'code',
              edit: false
            }, {
              name: t_('Status'),
              field: 'status',
              edit: false
            }]}
            dataFetch={partDialogDataFetch}
            dense
            toolbarDisplay='none'
            pageable
          />
        }
      />
      <SmartDialog
        setOpen={openCopyDialog}
        cancel
        cancelCallback={() => {
          setOpenCopyDialog(false)
        }}
        acceptCallback={() => handleAcceptButtonClick()}
        title={{ icon: <ContentCopyIcon color='primary' />, render: <Typography variant='h5' color='primary' sx={{ marginLeft: '0.3em' }}>{t_('Fabrication order copy')}</Typography> }}
        renderComponent={
          dialogEditCopy
        }
      />

      {AlertElement}
      <SmartMateriaUITable
        columns={columns}
        title={allFoMode ? t_('Fabrication orders') : lineOrder.lineNumber}
        dataFetch={dataFetch}
        actions={actions}
        multipleSelection
        sortOptions={sortOptions}
        pageable
        addModeByDefault={addModeByDefault}
        resetMultipleSelectionValue={resetMultipleSelectionValue}
        validator={validator}
        editingRow={(event) => setEditing(event)}
        onEditIndexChanged={(editIndex, editingRow) => { setIsAddingNewRow(editIndex === -1); setEditingItem(editingRow) }}
        importFile={[{ bean: { beanName: 'FabricationOrder' }, name: t_('Fabrication Order') }]}

      />
    </>
  )
}

export default ManufacturingOrder
