import { Suspense, useEffect, useRef, useState } from 'react'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { Canvas, useFrame, useThree, extend } from '@react-three/fiber'
import { Button, CircularProgress, LinearProgress, Stack } from '@mui/material'
import ModelLoader from './ModelLoader'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import Proptypes from 'prop-types'

// Calling extend with the native OrbitControls class from Three.js
// will make orbitControls available as a native JSX element.
// Notice how the OrbitControls classname becomes lowercase orbitControls when used as JSX element.
extend({ OrbitControls })

/**
 *
 * @param {object} props Props object
 * @param {'STL'|'PLY'|'OBJ'} props.modelType Model type
 * @param {number} props.documentId Document id
 *
 * @returns
 */
const SmartThreejs = ({ modelType = 'PLY', documentId }) => {
  const [modelSize, setModelSize] = useState(0)
  const orbitControlsRef = useRef()

  const PositionButton = ({ label, position }) => {
    return (
      <Button onClick={() => {
        const objectSize = modelSize * 3
        orbitControlsRef.current.object.position.x = objectSize * position[0]
        orbitControlsRef.current.object.position.y = objectSize * position[1]
        orbitControlsRef.current.object.position.z = objectSize * position[2]
        orbitControlsRef.current.target.set(0, 0, 0)
        orbitControlsRef.current.update()
      }}
      >{label}
      </Button>
    )
  }

  const onSetModelSize = (newSize) => {
    if (modelSize !== newSize && orbitControlsRef?.current?.object) {
      setModelSize(newSize)
      // Set the camera outside the model
      orbitControlsRef.current.object.position.set(newSize * 2, newSize * 2, newSize * 2)
      // Adjust camera far and near to avoid the model to dissapear
      orbitControlsRef.current.object.near = 1
      orbitControlsRef.current.object.far = newSize * 10
    }
  }

  const CameraControls = ({ controls, objectSize }) => {
    // Get a reference to the Three.js Camera, and the canvas html element.
    // We need these to setup the OrbitControls class.
    // https://threejs.org/docs/#examples/en/controls/OrbitControls

    const {
      camera,
      gl: { domElement }
    } = useThree()

    // Ref to the controls, so that we can update them on every frame using useFrame
    // const controls = useRef();
    useFrame(_ => controls.current.update())

    return (
      <orbitControls
        ref={controls}
        args={[camera, domElement]}
      />
    )
  }

  const getModel = () => {
    let loader
    switch (modelType) {
      case 'PLY':
        loader = PLYLoader
        break
      case 'OBJ':
        loader = OBJLoader
        break
      case 'STL':
        loader = STLLoader
        break
      default:
        break
    }

    return <ModelLoader documentId={documentId} loader={loader} modelType={modelType} setModelSize={onSetModelSize} solid edges edgesColor='black' edgesMinAngle={1} />
  }

  const canvasRef = useRef()

  return (
    <Stack sx={{ height: 'calc(100vh - 140px)' }}>
      <Button onClick={() => canvasRef.current.requestFullscreen()}>Fullscreen</Button>
      <Stack direction='row'>
        <PositionButton label='top' position={[0, 1, 0]} />
        <PositionButton label='bottom' position={[0, -1, 0]} />
        <PositionButton label='Perspective' position={[1, 1, 1]} />
        <PositionButton label='Side 1' position={[1, 0, 0]} />
        <PositionButton label='Side 2' position={[-1, 0, 0]} />
        <PositionButton label='Side 3' position={[0, 0, 1]} />
        <PositionButton label='Side 4' position={[0, 0, -1]} />
      </Stack>
      <Suspense fallback={<LinearProgress />}>
        <Canvas orthographic ref={canvasRef}>
          {getModel()}
          <hemisphereLight color='white' groundColor='#0f0f0f' position={[-7, 25, 13]} intensity={1} />
          <CameraControls controls={orbitControlsRef} objectSize={modelSize} />
          <axesHelper args={[1000]} />
        </Canvas>
      </Suspense>
    </Stack>
  )
}

SmartThreejs.propTypes = {
  modelType: Proptypes.oneOf(['PLY', 'OBJ', 'STL']).isRequired,
  documentId: Proptypes.number.isRequired
}

export default SmartThreejs
