import React, { useRef, useState, useEffect } from 'react'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { Edit, ManageSearchOutlined } from '@mui/icons-material'
import AddElement from '../../generic/smartAddElement/AddElement'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import Tool from './Tool'
import { apiDelete, apiPost, apiGet } from '../../generic/Api_Functions'
import { IconButton, TextField } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import AlertUI from '../../generic/AlertUI'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const ToolReference = () => {
  const navigate = useNavigate()
  const [AlertElement, showAlert] = AlertUI()
  const state = useLocation()
  const show = useRef(showAlert)
  const [hideColumn, setHideColumn] = useState(false)
  const [editingRow, setEditingRow] = useState(null)
  const [toolDialogOpen, setToolDialogOpen] = useState(false)
  const [rowTool, setRowTool] = useState({})
  const { t_ } = useSmartTranslation()

  const onEditIndexChanged = (editIndex, row) => {
    setHideColumn(false)
    if (editIndex === -1) {
      // Creating new element
      setHideColumn(true)
    }

    if (editIndex >= 0) {
      // Editing element. Row will have data
      setEditingRow(row)
      setHideColumn(false)
    }
  }

  useEffect(() => {
    if (state.state !== null && state.state instanceof Object) {
      setRowTool(state.state)
      setToolDialogOpen(true)
    } else if (state.state === 'BACK') {
      show.current({
        severity: 'info'
      })
    } else if (state.state === 'EDIT') {
      show.current({
        title: t_('Tool parameters modified correctly.'),
        severity: 'success'
      })
    }
  }, [state, t_])

  const columns = [{
    name: t_('Code'),
    field: 'code',
    charLimit: 60,
    mandatory: true,
    unique: true,
    readOnly: true
  },
  {
    name: t_('Name'),
    field: 'name',
    charLimit: 80,
    mandatory: true,
    unique: true
  },
  {
    name: t_('Description'),
    field: 'description',
    charLimit: 200
  },
  {
    name: t_('Tool type'),
    field: 'toolType.name',
    type: 'select',
    edit: {
      source: async () => {
        return await apiGet('allToolTypesBasicData')
      },
      id: 'id',
      field: 'name'
    },
    mandatory: true
  },
  {
    name: 'D',
    field: '',
    edit: false,
    render: (row) => {
      if (row.parameterList === undefined) {
        return null
      } else {
        const valueFound = row.parameterList.find((parameterValue) => parameterValue.name === 'Diameter')
        return (valueFound !== undefined ? valueFound.value : null)
      }
    }
  },
  {
    name: 'RC',
    field: 'description',
    edit: false,
    render: (row) => {
      if (row.parameterList === undefined) {
        return null
      } else {
        const valueFound = row.parameterList.find((parameterValue) => parameterValue.name === 'Corner Radius')
        return (valueFound !== undefined ? valueFound.value : null)
      }
    }
  },
  {
    name: 'TL',
    field: 'description',
    edit: false,
    render: (row) => {
      if (row.parameterList === undefined) {
        return null
      } else {
        const valueFound = row.parameterList.find((parameterValue) => parameterValue.name === 'Total Length')
        return (valueFound !== undefined ? valueFound.value : null)
      }
    }
  },
  {
    name: 'CL',
    field: 'description',
    charLimit: 200,
    edit: false,
    render: (row) => {
      if (row.parameterList === undefined) {
        return null
      } else {
        const valueFound = row.parameterList.find((parameterValue) => parameterValue.name === 'Cutting Length')
        return (valueFound !== undefined ? valueFound.value : null)
      }
    }
  },
  {
    name: 'Z',
    field: 'description',
    edit: false,
    render: (row) => {
      if (row.parameterList === undefined) {
        return null
      } else {
        const valueFound = row.parameterList.find((parameterValue) => parameterValue.name === 'Number of Flutes')
        return (valueFound !== undefined ? valueFound.value : null)
      }
    }
  },
  {
    name: t_('Total Life'),
    field: 'totalLife'
  },
  {
    name: t_('Life warning'),
    field: 'lifeWarning'
  },
  {
    name: t_('Holder'),
    field: 'defaultFixtureReference.name',
    type: 'select',
    edit: {
      source: async () => {
        if (editingRow !== null) { return await apiGet('fixtureReferencesByToolReference/' + editingRow.id) }
      },
      id: 'id',
      field: 'name'
    },
    hide: hideColumn
  },
  {
    name: t_('Material type'),
    field: 'materialType.name',
    type: 'select',
    mandatory: true,
    edit: {
      source: async () => {
        return await apiGet('materialTypes')
      },
      id: 'id',
      field: 'name'
    }
  },
  {
    name: t_('Quantity'),
    field: 'quantity',
    defaultNewValue: 0,
    mandatory: true,
    numeric: true,

    render: (row, extraFunctions) => {
      return (
        row !== undefined && row.id === undefined && row.quantity === 0
          ? <TextField
              value={quantityValue}
              onChange={(event) => {
                setQuantityValue(event.target.value)
              }}
              helperText={' '}
              type='number'
              inputProps={{ min: 0 }}
            />

          : (
            <div id={'quantity' + row.id} style={{ alignItems: 'center', display: 'flex' }}>
              {row.quantity}
              <AddElement
                endpoint='tools'
                parameter={row.id}
                referenceName={row.name}
                disable={editing || disableRowActions.current}
                onAlert={extraFunctions.handleShowAlert}
              />
            </div>))
    }
  }
  ]

  const handleDataVisibleInNextWindow = (row) => {
    delete row['smart-table-index']
    delete row.quantity
    row.active = true
    // row.userPermision = userPermission
    return row
  }

  const actions = {
    create: {
      fetchFunction: (row) => {
        row.quantity = quantityValue
        if (row.unit === undefined || Object.keys(row.unit).length === 0) {
          row.unit = null
        }
        setQuantityValue(0)
        return apiPost('toolReferences', row).then((toolReference) => {
          createToolParameterValuesInheritToolReferenceParameterValues(toolReference)
          setRowTool(toolReference)
          navigate('/resources/toolManager/' + toolReference.id, { state: { row: handleDataVisibleInNextWindow(toolReference) } })
        })
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('toolReferences/' + rows[0].id, rows)
        } else {
          return apiDelete('toolReferences', rows)
        }
      }
    },
    custom: [
      {
        name: '',
        render: (row) => {
          return (
            <IconButton
              disabled={!!editing}
              onClick={(event) => {
                setRowTool(row)
                setToolDialogOpen(true)
              }}
            >
              <ManageSearchOutlined />
            </IconButton>
          )
        }
      },
      {
        render: (row) => {
          return (
            <IconButton
              onClick={() => navigate('/resources/toolManager/' + row.id, { state: { row: handleDataVisibleInNextWindow(row) } })}
            >
              <Edit />
            </IconButton>
          )
        }
      }
    ]
  }

  const createToolParameterValuesInheritToolReferenceParameterValues = (rowTool) => {
    apiPost('toolByToolReference', rowTool)
  }
  const [quantityValue, setQuantityValue] = useState(0)
  const [editing, setEditing] = useState(false)
  const disableRowActions = useRef(false)
  return (
    <div>
      {AlertElement}
      <SmartDialog
        cancel={false} accept={false} close closeCallback={() => setToolDialogOpen(false)} setOpen={toolDialogOpen}
        renderComponent={<Tool row={rowTool} endpoint='tools' units={rowTool.unit ? rowTool.unit.code : ''} />}
      />
      <SmartMateriaUITable
        title={t_('Tools')}
        columns={columns}
        dataFetch='toolReferencesDto'
        dense
        actions={actions}
        multipleSelection
        editingRow={(row) => setEditing(row)}
        setDisableRowActions={(event) => disableRowActions.current === event}
        onEditIndexChanged={onEditIndexChanged}
      />
    </div>
  )
}

export default ToolReference
