import { Autocomplete, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import ReplayIcon from '@mui/icons-material/Replay'
import FolderIcon from '@mui/icons-material/Folder'
import StartIcon from '@mui/icons-material/Start'
import PublishIcon from '@mui/icons-material/Publish'
import PlanDialogContent from './PlanDialogContent'
import PlanPublishDialogContent from './PlanPublishDialogContent'
import { useEffect, useState } from 'react'
import { apiDelete, apiGet, apiPost, apiPut } from '../generic/Api_Functions'
import { SmartDeleteDialog, SmartDialog } from '../generic/utilities/SmartDialog'
import TaskDateDialogContent from './TaskDateDialogContent'

import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import SmartSelect from '../generic/smartSelect/SmartSelect'
import { Delete, SaveRounded } from '@mui/icons-material'

/**
* @typedef {import("../generic/utilities/objectStructures/planner/planification").Planification} Planification Planification object
* @typedef {import("../generic/utilities/objectStructures/planner/planification").PartPlannerEdit} PartPlannerEdit PartPlannerEdit object
* @typedef {import("../generic/utilities/objectStructures/planner/planification").Settings} Settings Planification Settings object
*/

/**
 *
 * @param {Object} props  Props object
 * @param {Planification} props.selectedFile Current selected files planification
 * @param {React.Dispatch<React.SetStateAction<Planification>>} props.setSelectedFile Current selected files planification useState setter
 * @param {boolean} props.isPlannerWorking If true, planner is already working
 * @param {React.Dispatch<React.SetStateAction<Planification>>} props.setCriteria Current selected planification useState setter
 * @param {PartPlannerEdit} props.editingEvent
 * @param {React.Dispatch<React.SetStateAction<PartPlannerEdit>>} props.setEditingEvent Part event configuration useState setter
 * @param {function()} props.onTaskEditAccept Function to be executed after accepting task edition
 * @param {Planification[]} props.publishedPlans Published plans Planification object array
 * @param {function()} props.loadPublishedPlans Method used to reload publishedPlans arra
 */
const TimelineFooter = ({
  selectedFile, setSelectedFile, isPlannerWorking, setIsPlannerWorking, setCriteria, editingEvent,
  setEditingEvent, onTaskEditAccept, publishedPlans, loadPublishedPlans, showAlert, config
}) => {
  const [planDialogOpen, setPlanDialogOpen] = useState(false)
  const [simplePlanDialogOpen, setSimplePlanDialogOpen] = useState(false)
  const [planPublishDialogOpen, setPlanPublishDialogOpen] = useState(false)
  const [publishName, setPublishName] = useState('')
  const [open, setOpen] = useState(false)
  const [openAutocomplete, setOpenAutocomplete] = useState(false)
  const [planificationToDelete, setPlanificationToDelete] = useState([])
  const { t_ } = useSmartTranslation()
  /** @type {[String[], React.Dispatch<React.SetStateAction<String[]>>]} */
  const [planificationCriteriaTypeList, setPlanificationCriteriaTypeList] = useState(null)
  const [isValidPlannerName, setIsValidPlannerName] = useState(false)

  /**
   * @type {Settings} Settings object
   */
  const defaultPlanParameters = {
    aprox: 'metaheuristic',
    time_exec: '5',
    criteria: 'asap',
    cost_criteria: 'cost',
    priocost: '1',
    priotemp: '1',
    showErrors: false,
    unitCriteria: 'Job'
  }
  useEffect(() => {
    const getInfo = async () => {
      if (planificationCriteriaTypeList === null) {
        setPlanificationCriteriaTypeList(await apiGet('planificationCriteria'))
      }
    }
    getInfo()
  }, [planificationCriteriaTypeList])

  const [planParameters, setPlanParameters] = useState({ ...defaultPlanParameters })

  const handlechangepublished = (event, option) => {
    setSelectedFile(option)
    setCriteria(option)
  }

  const onPlanDialogAccept = async () => {
    setIsPlannerWorking(true)
    if (config.plannerIsActive) {
      if (!validatePlanParameters()) {
        setIsPlannerWorking(false)
        return
      }
      setPlanDialogOpen(false)
      await apiPost('planner/new/' + true, planParameters)
    } else {
      setSimplePlanDialogOpen(false)
      await apiPost('planner/new/' + false, planParameters)
    }

    // Sometimes when an existing planification in this case Temp doesn't have time to update to the new unit criteria, on first display it's displaying wrong data
    publishedPlans.find(plan => plan.fileName === 'Temp').unitCriteria.name = planParameters.unitCriteria
    setSelectedFile(publishedPlans.find(plan => plan.fileName === 'Temp'))
  }

  const onPlanPublishDialogAccept = async () => {
    setPlanPublishDialogOpen(false)
    await apiPost('planner/publish/planification', { currentPlanificationId: selectedFile.id, planificationName: publishName })
      .then((response) => {
        showAlert({ severity: 'success' })
        setSelectedFile(response)
        loadPublishedPlans()
      }).catch((e) => {
        showAlert({ severity: 'error', title: t_(e.message), message: null })
      })
    setPublishName('')
    setIsValidPlannerName(false)
  }

  function validatePlanParameters () {
    if (planParameters.priocost === '' || parseInt(planParameters.priocost) < 1 || parseInt(planParameters.priocost) > 10) {
      setPlanParameters({ ...planParameters, showErrors: true })
      return false
    }
    if (planParameters.priotemp === '' || parseInt(planParameters.priotemp) < 1 || parseInt(planParameters.priotemp) > 10) {
      setPlanParameters({ ...planParameters, showErrors: true })
      return false
    }
    if ((planParameters.time_exec === '' || parseInt(planParameters.time_exec) < 1)) {
      setPlanParameters({ ...planParameters, showErrors: true })
      return false
    }
    return true
  }
  const onReset = async () => {
    setIsPlannerWorking(true)
    const response = await apiGet('planner/resetPlan/' + selectedFile.id)
    setSelectedFile(response)
    loadPublishedPlans()
    setIsPlannerWorking(false)
  }
  const activatePlanification = async () => {
    await apiPut('planner/setCurrent/' + selectedFile.id)
    setSelectedFile(publishedPlans.find(plan => plan.fileName === 'Current'))
    loadPublishedPlans()
  }

  const saveCurrentPlanModifications = async () => {
    setIsPlannerWorking(true)
    setSelectedFile(await apiPut('planner/update/' + selectedFile.id))
    setIsPlannerWorking(false)
  }
  const handleCloseDialog = () => {
    setOpen(false)
    setOpenAutocomplete(true)
  }
  const handleDeleteDialog = () => {
    setOpen(false)
    deletePlanification()
  }
  const deletePlanification = async () => {
    await apiDelete('planner/delete/' + planificationToDelete.id)
    loadPublishedPlans()
    setSelectedFile(publishedPlans.find(plan => plan.fileName === 'Temp'))
    setOpenAutocomplete(true)
  }
  const columns = [
    {
      name: t_('Name'),
      field: 'fileName'
    }, {
      name: t_('Cost criteria'),
      field: 'costCriteria.name'
    }, {
      name: t_('Cost priority'),
      field: 'costPriority'
    }, {
      name: t_('Temporal criteria'),
      field: 'temporalCriteria.name'
    }, {
      name: t_('Temporal priority'),
      field: 'temporalPriority'
    }, {
      name: t_('Unit criteria'),
      field: 'unitCriteria.name'
    }
  ]
  const handleConfirmationDialog = (event, option) => {
    // Prevent default li planification change event
    event.stopPropagation()
    event.preventDefault()
    setPlanificationToDelete(option)
    setOpen(true)
    setOpenAutocomplete(false)
  }

  return (
    <>
      <SmartDialog
        title={{ render: <Typography variant='h5'>{t_('Start simple planification')}</Typography>, icon: <StartIcon fontSize='large' /> }}
        message={t_('Are you sure you want to create a new planification?')}
        setOpen={simplePlanDialogOpen}
        renderComponent={<Stack sx={{ alignItems: 'center' }}><SmartSelect value={planParameters.unitCriteria} selectableOptions={planificationCriteriaTypeList} onChange={(newValue) => setPlanParameters({ ...planParameters, unitCriteria: newValue })} label={t_('Select planning unit')} width='12rem' /></Stack>}
        cancelCallback={() => {
          setSimplePlanDialogOpen(false)
        }}
        acceptCallback={onPlanDialogAccept}
      />
      <SmartDialog
        title={{ render: <Typography variant='h5'>{t_('Start planification')}</Typography>, icon: <StartIcon fontSize='large' /> }}
        setOpen={planDialogOpen}
        cancelCallback={() => {
          setPlanDialogOpen(false)
          setPlanParameters({ ...defaultPlanParameters })
        }}
        acceptCallback={onPlanDialogAccept}
        renderComponent={<PlanDialogContent setPlanParameters={setPlanParameters} planParameters={planParameters} />}
      />
      <SmartDialog
        title={{ render: <Typography variant='h5'>{t_('Publish planification')}</Typography>, icon: <PublishIcon fontSize='large' /> }}
        setOpen={planPublishDialogOpen}
        accept={false}
        cancelCallback={() => { setPlanPublishDialogOpen(false); setPublishName(''); setIsValidPlannerName(false) }}
        acceptCallback={onPlanPublishDialogAccept}
        renderCustomButtons={
          <Button disabled={!isValidPlannerName} onClick={onPlanPublishDialogAccept} variant='outlined' color={isValidPlannerName ? 'primary' : undefined}>
            <Typography color={isValidPlannerName ? 'primary' : 'grey'}>{t_('ACCEPT')} </Typography>
            <SaveRounded color={isValidPlannerName ? 'primary' : 'disabled'} sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
          </Button>
        }
        renderComponent={<PlanPublishDialogContent setPublishName={setPublishName} publishedPlans={publishedPlans} publishName={publishName} setIsValidPlannerName={setIsValidPlannerName} />}
      />
      <SmartDialog
        title={{ render: <Typography variant='h5'>{t_('Edit event')}</Typography> }}
        setOpen={editingEvent !== null}
        cancelCallback={() => setEditingEvent(null)}
        acceptCallback={onTaskEditAccept}
        renderComponent={<TaskDateDialogContent event={editingEvent} setEvent={setEditingEvent} />}
      />
      <Stack direction='row' justifyContent='space-between' style={{ marginTop: '2rem' }}>
        {isPlannerWorking
          ? (
            <Stack />)
          : (
            <Stack direction='row' gap={2}>
              <Stack>
                <Autocomplete
                  open={openAutocomplete}
                  onOpen={() => {
                    setOpenAutocomplete(true)
                  }}
                  onClose={() => setOpenAutocomplete(false)}
                  size='small'
                  value={Object.keys(selectedFile).length > 0 ? selectedFile : null}
                  onChange={handlechangepublished}
                  getOptionLabel={(option) => option.fileName ?? ''}
                  isOptionEqualToValue={(option, value) => option.fileName === value.fileName}
                  sx={{ minWidth: '10rem' }}
                  fullWidth
                  disableClearable
                  disableCloseOnSelect
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label=''
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position='start' disablePointerEvents>
                            <FolderIcon />
                          </InputAdornment>
                        )
                      }}
                    />}
                  options={publishedPlans}
                  renderOption={(props, option) =>
                    <>
                      <Box component='li' {...props}>
                        <Box
                          sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          {option.fileName}
                        </Box>
                        {option?.fileName !== 'Temp' && option?.fileName !== 'Current'
                          ? (
                            <IconButton
                              color='primary' sx={{ p: '10px' }} aria-label='directions' onClick={(event) => {
                                handleConfirmationDialog(event, option)
                              }}
                            >
                              <Delete />
                            </IconButton>)
                          : null}
                      </Box>
                    </>}
                />
              </Stack>
              <Button variant='outlined' onClick={() => setPlanPublishDialogOpen(true)}>
                {t_('Save As') + '...'}
              </Button>
              <Button variant='outlined' onClick={() => saveCurrentPlanModifications()} disabled={selectedFile != null ? !selectedFile.editing || !selectedFile.canSave : true}>
                {t_('Save')}
              </Button>
            </Stack>)}
        <Stack direction='row' gap={2}>
          {isPlannerWorking
            ? (
              <CircularProgress />)
            : (
              <Stack direction='row' gap={2}>
                <Button
                  variant='outlined'
                  endIcon={<StartIcon />}
                  onClick={() => config.plannerIsActive ? setPlanDialogOpen(true) : setSimplePlanDialogOpen(true)}
                  {...(selectedFile != null && selectedFile.editing === true && config?.plannerIsActive ? { color: 'warning' } : {})}
                >
                  {t_('Plan')}
                </Button>
                <Button variant='outlined' endIcon={<ReplayIcon />} onClick={onReset} disabled={selectedFile != null ? !selectedFile.editing : true}>
                  {t_('Reset')}
                </Button>
                <Button variant='outlined' endIcon={<PublishIcon />} onClick={activatePlanification} disabled={selectedFile != null && (!selectedFile.canActivate || (selectedFile.canActivate && selectedFile.current && !selectedFile.editing))}>
                  {t_('Activate')}
                </Button>
              </Stack>)}
        </Stack>
      </Stack>
      <SmartDeleteDialog rows={[planificationToDelete]} columns={columns} show={open} cancelCallback={handleCloseDialog} deleteCallBack={handleDeleteDialog} />
    </>
  )
}
export default TimelineFooter
