import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import { Badge, CircularProgress, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import { useSmartInterval } from '../../generic/hooks/useSmartInterval'
import { apiGet } from '../../generic/Api_Functions'
import CompassButton from '../Common/CompassButton/CompassButton'
import CompassCard from '../Common/CompassCard/CompassCard'
import { ContentPasteSearch, Exposure, ListAlt } from '@mui/icons-material'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import CompassGrid from '../Common/compassGrid/CompassGrid'

export default function Production ({ fullScreen, setFullScreen }) {
  const [devices, setDevices] = useState(null)
  const navigate = useNavigate()
  const { t_ } = useSmartTranslation()

  useSmartInterval(
    async () => {
      const response = await apiGet('compass/devices/')
      return response
    }, 1000,
    (data) => {
      if (data != null) {
        setDevices(data)
      }
    }
  )
  const productionButtons = {
    DOC: 'doc',
    HISTORICSTATES: 'historicStates',
    CHANGESTATES: 'changeStates',
    HISTORICREPORTS: 'historicReports',
    REPORTQUANTITY: 'reportQuantity'
  }

  const getDocButton = () => {
    return (
      <CompassButton
        icon={
          <FeedOutlinedIcon />
        }
        color='secondary'
        onClick={() => navigate('/compass/production/viewFile', { state: { urlBack: '/compass/production' } })}
      />
    )
  }
  const getHistoricButton = (device) => {
    return (
      <CompassButton
        icon={
          <Badge badgeContent={<ListAlt />} sx={{ padding: '0.5rem' }}>
            <ContentPasteSearch />
          </Badge>
        }
        color='secondary'
        onClick={() => navigate('/compass/production/edit/' + device.id, { state: { device } })}
      />
    )
  }
  const getEditButton = (device) => {
    return (
      <CompassButton
        icon={
          <ListAlt />
        }
        label={t_('FO')}
        color='primary'
        onClick={() => navigate('/compass/production/edit/changeState', { state: { row: undefined, device, urlBack: '/compass/production' } })}
      />
    )
  }
  const getReportHistoricButton = (device) => {
    return (
      <CompassButton
        icon={
          <Badge badgeContent={<Exposure />} sx={{ padding: '0.5rem' }}>
            <ContentPasteSearch />
          </Badge>
        }
        color='secondary'
        onClick={() => navigate('/compass/production/quantity/' + device.id, { state: { urlBack: '/compass/production', device } })}
      />
    )
  }
  const getReportButton = (device) => {
    return (
      <CompassButton
        icon={
          <Exposure />
        }
        label={t_('Quantity')}
        color='primary'
        onClick={() => navigate('/compass/production/quantity/report/' + device.id, { state: { urlBack: '/compass/production', device, selectedFabricationOrder: device.currentFabricationOrders[0] } })}
      />
    )
  }
  const getProductionButton = (productionButtonName, device) => {
    let md = 2
    let button = null
    switch (productionButtonName) {
      case productionButtons.HISTORICSTATES:
        button = getHistoricButton(device)
        md = 2
        break
      case productionButtons.CHANGESTATES:
        button = getEditButton(device)
        md = 3
        break
      case productionButtons.DOC:
        button = getDocButton()
        md = 2
        break
      case productionButtons.HISTORICREPORTS:
        button = getReportHistoricButton(device)
        md = 2
        break
      case productionButtons.REPORTQUANTITY:
        button = getReportButton(device)
        md = 3
        break
      default:
        break
    }

    if (!button) return null

    return (<Grid item md={md} key={productionButtonName}>{button}</Grid>)
  }

  const buttons = (device) => {
    return Object.values(productionButtons).map(buttonName => { return getProductionButton(buttonName, device) })
  }

  return (
    devices === null
      ? (
        <Stack
          sx={{ width: '100%', height: '80vh' }}
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress size='15rem' />
        </Stack>
        )
      : (
        <CompassGrid
          items={devices}
          fullScreenItem={fullScreen}
          renderItem={(device, isFullscreen) =>
            <CompassCard
              device={device}
              buttons={buttons(device)}
              fabricationOrder={device.currentFabricationOrders[0]}
              showInterrupted
              showPendingTrue
              showFabricationOrderExtraData
              onDoubleClick={() => setFullScreen(isFullscreen ? null : device)}
              isFullScreen={isFullscreen}
            />}
        />
        )
  )
}
