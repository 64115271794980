import { AccessTimeRounded, LockOpenRounded, PendingActionsRounded, TouchAppRounded, Widgets } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useCallback, useState, useRef } from 'react'
import AlertUI from '../../generic/AlertUI'
import { apiGet, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import theme from '../../../themeConfig'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import LoadActionDialog from './LoadActionDialog'
import CheckDialog from '../../generic/CheckDialog'
import UnloadActionDialog from './UnloadActionDialog'
import MoveUpIcon from '@mui/icons-material/MoveUp'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { useNavigate } from 'react-router-dom'

const Actions = (props) => {
  const [AlertElement, showAlert] = AlertUI()
  const [forceLoad, setForceLoad] = useState(true)
  const [resetMultipleSelectionValue, setResetMultipleSelectionValue] = useState(false)
  const [openFabricationOrderDialog, setOpenFabricationOrderDialog] = useState(false)
  const [openUnloadDialog, setOpenUnloadDialog] = useState(false)
  const [currentStorageAction, setCurrentStorageAction] = useState(null)
  const [showCheckDialog, setShowCheckDialog] = useState(false)
  const [openDialogToConfirmManualActions, setOpenDialogToConfirmManualActions] = useState(false)
  const actionPagination = useRef(null)
  const actualDataFetch = useRef(null)

  const { t_ } = useSmartTranslation()
  const navigate = useNavigate()

  const isCellRunning = async () => {
    const status = await apiGet('cellGeneralStatus')
    return status === 'AUTOMATIC'
  }

  const closeCheckDialog = (state) => {
    setShowCheckDialog(state)
  }

  const getIconForType = (type) => {
    if (type == null) {
      return null
    }
    switch (type.code) {
      case 'LOAD':
        return <Widgets color='blue' style={{ color: theme.palette.info.main }} />
      case 'UNLOAD':
        return <MoveUpIcon />
      case 'UNLOCK':
        return <LockOpenRounded />
      default:
        return <AccessTimeRounded style={{ color: '#FFFF00' }} />
    }
  }

  const columns = [
    {
      field: 'name',
      search: false,
      render: (row) => (
        row.executionDate === undefined || row.executionDate === null
          ? getIconForType(row.type)
          : null
      )
    },

    {
      name: t_('Code'),
      field: 'code',
      charLimit: 60,
      mandatory: true,
      unique: true
    },
    {
      name: t_('Type'),
      field: 'type.name',
      charLimit: 60,
      mandatory: true,
      unique: true
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200,
      readOnly: true

    },
    {
      name: t_('Creation date'),
      field: 'createdDate',
      type: 'datetime',
      readOnly: true,
      sortOptions: 'asc'
    },
    {
      name: t_('Reading date'),
      readOnly: true,
      field: 'readingDate',
      type: 'datetime'
    }
  ]

  const sortOptions = {
    default: {
      field: 'createdDate',
      direction: -1
    }
  }

  const dialogManualAction = dataFetch => {
    const pageable = actionPagination.current !== undefined
    return (
      <>
        <SmartMateriaUITable
          columns={columns}
          dataFetch={dataFetch}
          title='Actions'
          searchAndPaginationVisible=''
          pagination={actionPagination.current}
          pageable={pageable}
        />
      </>
    )
  }

  const actions = {
    custom: [
      {
        name: 'ShelvePositions',
        showOnNew: 'false',
        render: (row, dataFetch, pagination) => {
          return (
            <IconButton onClick={() => {
              const paginationCopy = { ...pagination }
              paginationCopy.rows = [row.id.toString()]
              actionPagination.current = paginationCopy
              actualDataFetch.current = dataFetch
              if (row.type && (row.type.code === 'LOAD' || row.type.code === 'UNLOAD')) {
                setCurrentStorageAction(row)
                if (row.type.code === 'UNLOAD') {
                  setOpenUnloadDialog(true)
                } else {
                  setOpenFabricationOrderDialog(true)
                }
              } else if (row.type && row.type.code === 'PLAN') {
                navigate('/planning')
              } else {
                setOpenDialogToConfirmManualActions(true)
              }
            }}
            >
              <TouchAppRounded sx={{ fontSize: '2rem', filter: 'drop-shadow(2px 1px black)' }} />
            </IconButton>
          )
        }
      }
    ]
  }

  const fetchData = useCallback(async (pagination) => {
    let dataList = []
    dataList = await apiGet('uncheckedActions', pagination)
      .catch((error) => {
        showAlert({
          title: 'Error getting data.',
          message: error
        })
      })
    if (dataList.content !== undefined && dataList.content.find((data) => data.readingDate === null)) {
      await apiPut('updateReadingDate', dataList.content)
      setForceLoad(!forceLoad)
    }

    return dataList
  }, [showAlert, forceLoad])

  const updateExecutedDate = async (pagination) => {
    if (pagination !== undefined) {
      pagination.size = -1
      pagination.page = 0
    } else {
      pagination = {}
    }

    await apiPut('updateExecutedDate', pagination)
      .then((data) => {
        showAlert({
          title: 'Updated correctly.',
          severity: 'success'
        })
        setResetMultipleSelectionValue(!resetMultipleSelectionValue)
      }
      )
      .catch((error) => {
        showAlert({
          title: 'Error updating data.',
          message: error.message,
          severity: 'error'
        })
      })
    setForceLoad(!forceLoad)
  }

  const titleDialogManualAction = {
    icon: <PendingActionsRounded fontSize='large' color='warning' />,
    render: 'Action complete?'
  }

  const acceptManualActions = () => {
    updateExecutedDate(actionPagination.current, actualDataFetch.current)
    setOpenDialogToConfirmManualActions(false)
  }

  return (
    <>
      {AlertElement}
      <LoadActionDialog
        action={currentStorageAction}
        open={openFabricationOrderDialog}
        key={'load-action-dialog-id-' + (currentStorageAction ? currentStorageAction.id : '')}
        setOpen={setOpenFabricationOrderDialog}
        onDone={() => {
          showAlert({
            title: 'Updated correctly.',
            severity: 'success'
          })
        }}
      />
      <UnloadActionDialog
        action={currentStorageAction}
        open={openUnloadDialog}
        key={'unload-action-dialog-id-' + (currentStorageAction ? currentStorageAction.id : '')}
        setOpen={setOpenUnloadDialog}
        onDone={() => {
          showAlert({
            title: 'Updated correctly.',
            severity: 'success'
          })
        }}
      />
      <SmartDialog
        message="Are you sure you want to set action as complete? You can't undo this action."
        renderComponent={dialogManualAction(actualDataFetch.current)}
        cancelCallback={() => setOpenDialogToConfirmManualActions(false)}
        setOpen={openDialogToConfirmManualActions}
        title={titleDialogManualAction}
        accept
        cancel
        acceptCallback={async () => {
          const running = await isCellRunning()
          running
            ? setShowCheckDialog(true)
            : acceptManualActions()
          setOpenDialogToConfirmManualActions(false)
        }}
      />
      <CheckDialog
        open={showCheckDialog}
        onClose={(state) => closeCheckDialog(state)}
        onAccept={() => {
          acceptManualActions()
          setShowCheckDialog(false)
        }}
      />
      <SmartMateriaUITable
        columns={columns}
        dataFetch={fetchData}
        actions={actions}
        sortOptions={sortOptions}
        forceLoad={forceLoad}
        deleteMultipleSelectionDisabled
        resetMultipleSelectionValue={resetMultipleSelectionValue}
        pageable
        toolbar={props.toolbar}
      />
    </>
  )
}

export default Actions
