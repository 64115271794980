import { useEffect, useState } from 'react'
import { apiGet } from '../../Api_Functions'

// Import typedef from another file
/**
 * @typedef {import('../../utilities/objectStructures/globalStructures').FrontEndConfig} FrontEndConfig FrontEndConfig object
 */

const useFrontEndConfig = () => {
  /** @type {[FrontEndConfig, React.Dispatch<FrontEndConfig>]} */
  const [config, setConfig] = useState(null)
  useEffect(() => {
    apiGet('frontEndConfig')
      .then(setConfig)
  }, [])
  return [config]
}

export default useFrontEndConfig
