import { useState, useEffect } from 'react'
import { apiGet } from './Api_Functions'

const VersionNum = () => {
  const [versions, setVersions] = useState([])

  const getVersion = async () => {
    const result = await apiGet('micVersion')
    setVersions(result)
  }

  useEffect(() => {
    getVersion()
  }, [])

  return (versions ? versions.version_num : null)
}

export default VersionNum
