import { useState } from "react"
import { apiGet } from "../../Api_Functions"
import { useSmartInterval } from "../useSmartInterval"

const useUncheckedAlarms = () => {
  const [uncheckedNotifications, setUncheckedNotifications] = useState(0)
  const [uncheckedAlarms, setUncheckedAlarms] = useState(0)

  const fetchData = async () => {
    const data = await apiGet('getUncheckedAlarmSummary')
    return data
  }

  const setData = (intervalData) => {
    setUncheckedAlarms(intervalData.alarms && Number.isInteger(intervalData.alarms) ? intervalData.alarms : 0)
    setUncheckedNotifications(intervalData.notifications && Number.isInteger(intervalData.notifications) ? intervalData.notifications : 0)
  }

  useSmartInterval(fetchData, 1000, setData)

  return { uncheckedNotifications, uncheckedAlarms }
}

export default useUncheckedAlarms