import React from 'react'
import off from '../../../../images/OFF.png'
import on from '../../../../images/ON.png'
import auto from '../../../../images/AUTO.png'
import manual from '../../../../images/MANUAL.png'
import offline from '../../../../images/OFFLINE.png'
import working from '../../../../images/Gears.gif'
import idle from '../../../../images/clock.gif'
import icoStatusStyle from './icoStatusStyle'
import { ReportOutlined, Error } from '@mui/icons-material/'
import PropTypes from 'prop-types'
import Badge from '@mui/material/Badge'
import { IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const style = icoStatusStyle

const actionBadgeStyle = {
  '.MuiBadge-badge': {
    margin: '0.5rem'
  }
}

/**
 *
 * @param {Object} props Props object
 * @param {'MANUAL'|'AUTO'|'OFFLINE'|'NON_OPERATIVE'} props.mode Device mode: MANUAL, AUTO, OFFLINE, NON_OPERATIVE
 * @param {'ON'|'OFF'| 'IDLE' |'WORKING'|'ERROR'} props.status Device status: ON, OFF, IDLE, WORKING, ERROR
 * @param {number} props.incidenceQuantity Quantity of incidences
 * @returns
 */
const IcoStatus = ({ status, mode, incidenceQuantity = 0, device }) => {
  const navigate = useNavigate()
  return (
    <div style={{ ...style.mainContainer }}>
      <div style={{ ...style.totalHeight, ...style.minWidth }}>
        {incidenceQuantity !== 0 &&
          <IconButton sx={{ padding: '0' }} onClick={() => navigate('/compass/supervision/incidentList', { state: { device } })}>
            <Badge badgeContent={incidenceQuantity} color='error' sx={actionBadgeStyle}>
              <ReportOutlined color='error' style={{ ...style.totalHeight, ...style.iconFontSize }} />
            </Badge>
          </IconButton>}
      </div>
      <div style={{ ...style.totalHeight, ...style.minWidth }}>
        {status !== 'OFF' && <img src={on} style={{ ...style.totalHeight }} alt='' />}
        {status === 'OFF' && <img src={off} style={{ ...style.totalHeight }} alt='' />}
      </div>
      <div style={{ ...style.totalHeight, ...style.minWidth }}>
        {status === 'IDLE' && <img src={idle} style={{ ...style.totalHeight }} alt='' />}
        {status === 'WORKING' && <img src={working} style={{ ...style.totalHeight }} alt='' />}
        {status === 'ERROR' && <Error color='error' style={{ ...style.totalHeight, ...style.iconFontSize }} alt='' />}
        {status === 'STOPPED' && <img src={idle} style={{ ...style.totalHeight }} alt='' />}
      </div>
      <div style={{ ...style.totalHeight, ...style.minWidth }}>
        {mode === 'MANUAL' && <img src={manual} style={{ ...style.totalHeight }} alt='' />}
        {mode === 'AUTO' && <img src={auto} style={{ ...style.totalHeight }} alt='' />}
        {mode === 'OFFLINE' && <img src={offline} style={{ ...style.totalHeight }} alt='' />}
        {mode === 'NON_OPERATIVE' && <Error style={{ ...style.totalHeight, ...style.iconFontSize, ...style.nonOperativeIconColor }} />}
      </div>
    </div>
  )
}

IcoStatus.propTypes = {
  /**
   * Device mode: MANUAL, AUTO, OFFLINE, NON_OPERATIVE
   */
  mode: PropTypes.string.isRequired,
  /**
   * Device status: ON, OFF, IDLE, WORKING, ERROR
   */
  status: PropTypes.string,
  incidenceQuantity: PropTypes.number
}

export default IcoStatus
