import React from "react";
import SMARTPMCELL from "../../images/mic-defecto.jpg";

const MainDefault = () => {
  // const classes = estilos();

  return (
    <div sx={{ textAlign: "center", width: "100%", }}>
      <img sx={{ width: "100%" }} src={SMARTPMCELL} alt="EgileCell"></img>
    </div>
  );
};

export default MainDefault;
