import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack } from '@mui/material'
import React from 'react'
import { CompassButton } from '../CompassButton'
import PropTypes from 'prop-types'
import createDialogTitle from '../../../generic/utilities/dialogUtil/index'
import { Close, WarningRounded } from '@mui/icons-material'

const dialogActionsStyle = {
  justifyContent: 'center',
  alignItems: 'stretch',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  minHeight: '3rem',
  marginBottom: '0.5rem',
  padding: '1rem',
  gap: '1.5rem'
}
const floatButton = {
  float: 'right',
  marginRight: '-20px',
  marginTop: '-20px',
  position: 'absolute'
}
const buttonCss = {
  border: '1px solid #D0D0D0 ',
  backgroundColor: '#303030',
  color: '#D9D9D9'
}

const CompassDialog = (props) => {
  const {
    openDialog,
    handleClose,
    handleAccept,
    acceptLabel,
    cancelLabel,
    title,
    body,
    showExitButton,
    handleExit,
    cancelIcon,
    acceptIcon
  } = props

  const checkProps = () => {
    if (!openDialog) return false
    if (!handleClose) return false
    if (!handleAccept) return false
    if (!acceptLabel) return false
    if (!cancelLabel) return false
    return true
  }
  const renderTitle = () => {
    if (title === null) return
    if (title === 'default') return getTitleComponent(createDialogTitle({ color: 'warning', title: 'Attention', icon: WarningRounded }))
    return getTitleComponent(title)
  }

  const getTitleComponent = (titleToRender) => {
    return (
      <DialogTitle>
        <Stack spacing={1} alignItems='center' justifyContent='center'>
          {titleToRender.icon}
          {titleToRender.render}
        </Stack>
      </DialogTitle>
    )
  }

  if (!checkProps) return null

  return (
    <Dialog
      open={openDialog} fullWidth maxWidth='sm' disableEscapeKeyDown PaperProps={{
        style: {
          overflowY: 'unset'
        }
      }}
    >
      {showExitButton
        ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <IconButton type='button' sx={{ p: '10px' }} onClick={handleExit} style={{ ...buttonCss, ...floatButton }}>
              <Close />
            </IconButton>
          </div>)
        : null}

      <div style={{ backgroundColor: '#373636' }}>
        {renderTitle()}
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogContentText>
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={dialogActionsStyle}>
          <div style={{ height: '75px' }}>
            <CompassButton
              label={acceptLabel}
              onClick={handleAccept}
              color='primary'
              icon={acceptIcon || null}
            />
          </div>
          <div style={{ height: '75px' }}>
            <CompassButton
              label={cancelLabel}
              onClick={handleClose}
              color='warning'
              icon={cancelIcon || null}
            />
          </div>
        </DialogActions>
      </div>
    </Dialog>
  )
}

CompassDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  acceptLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default CompassDialog
