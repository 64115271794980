import ReactFlow, { Background, Controls, ReactFlowProvider, addEdge } from 'reactflow'
import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react'

import 'reactflow/dist/style.css'
import { StartNode, EndNode } from '../customnodes'
import { ConnectionLine } from '../connectionline'
import { CustomEdge } from '../customedges'
import { useTheme } from '@emotion/react'

/**
 * Smart ReactFlow component
 * @param {Object} props Props object
 * @param {import('reactflow').NodeTypes} props.nodeComponent Custom node component
 * @param {import('reactflow').Node[]} props.nodes Nodes array
 * @param {React.Dispatch<React.SetStateAction<Node<any, string | undefined>[]>>} props.setNodes Set nodes function
 * @param {import('reactflow').Node} props.newNode New node object
 * @param {import('reactflow').Edge[]} props.edges Edges array
 * @param {React.Dispatch<React.SetStateAction<Node<any, string | undefined>[]>>} props.setEdges Set edges function
 * @param {import('reactflow').OnNodesChange} props.onNodesChange On nodes change function
 * @param {import('reactflow').OnEdgesChange} props.onEdgesChange On edges change function
 * @param {import('reactflow').Viewport} props.defaultViewport Default viewport object
 * @param {Boolean} props.showWorkflowTable Show workflow table
 * @param {Boolean} props.fitView Fit view
 * @param {Number} props.technologyNum Technology number
 * @param {function(number)} props.technologyNumChange Technology number change function
 * @param {Boolean} props.isNodeSelected Is node selected
 * @param {function(import('reactflow').Node[])} props.onNodeSelectionChange Node selection change function
 * @param {function(import('reactflow').Node[])} props.onEdgeSelectionChange Edge selection change function
 * @param {Boolean} props.disableFitOnResize Disable fit on resize
 * @returns
 */
const SmartReactflow = ({
  nodeComponent,
  nodes,
  setNodes,
  edges,
  setEdges,
  onNodesChange,
  onEdgesChange,
  defaultViewport,
  showWorkflowTable,
  fitView,
  onDrop = (event, position) => {},
  isNodeSelected = false,
  onNodeSelectionChange,
  onEdgeSelectionChange,
  disableFitOnResize = false
}) => {
  const reactFlowWrapper = useRef(null)
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [connectionLineType, setConnectionLineType] = useState('default')
  const theme = useTheme()

  const nodeTypes = useMemo(() => ({
    technologyNode: nodeComponent,
    startNode: StartNode,
    endNode: EndNode
  }), [nodeComponent])

  const edgeTypes = useMemo(() => ({
    custom: CustomEdge
  }), [])

  const onConnect = useCallback((params) => {
    params.data = {
      isMandatory: false,
      type: params.sourceHandle,
      selected: false,
      connectivity: 'FLEXIBLE'
    }
    params.type = 'custom'
    /* params.markerEnd = {
      type: MarkerType.ArrowClosed,
      width: 10,
      height: 20,
      color: params.sourceHandle === 'NOT_OK' ? '#F68D76' : params.sourceHandle === 'OK' ? '#589D74' : '#B1B1B7'
    } */
    setEdges((eds) => addEdge(params, eds))
  }, [setEdges])

  const onDragOver = useCallback((event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onSelectionChange = (selection) => {
    let nodesChanges = false
    let edgeChanges = false
    const newNodeValues = nodes.map((node) => {
      if (!node.data.selected && selection.nodes.find((n) => n.id === node.id)) {
        node.data.selected = true
        nodesChanges = true
      }

      if (node.data.selected && !selection.nodes.find((n) => n.id === node.id)) {
        node.data = { ...node.data }
        node.data.selected = false
        nodesChanges = true
      }
      return node
    })

    const newEdgeValues = edges.map((edge) => {
      if (!edge.data.selected && selection.edges.find((e) => e.id === edge.id)) {
        edge.data = { ...edge.data }
        edge.data.selected = true
        edgeChanges = true
      }

      if (edge.data.selected && !selection.edges.find((e) => e.id === edge.id)) {
        edge.data = { ...edge.data }
        edge.data.selected = false
        edgeChanges = true
      }
      return edge
    })

    if (nodesChanges) {
      setNodes(newNodeValues)
      if (onNodeSelectionChange && typeof onNodeSelectionChange === 'function') {
        onNodeSelectionChange(newNodeValues.filter((node) => node.data.selected))
      }
    }
    if (edgeChanges) {
      setEdges(newEdgeValues)
      if (onEdgeSelectionChange && typeof onEdgeSelectionChange === 'function') {
        onEdgeSelectionChange(newEdgeValues.filter((edge) => edge.data.selected))
      }
    }
  }

  useEffect(() => {
    if (!reactFlowWrapper.current || disableFitOnResize) return
    const resizeObserver = new global.ResizeObserver(() => {
      // Do what you want to do when the size of the element changes
      reactFlowInstance?.fitView()
    })
    resizeObserver.observe(reactFlowWrapper.current)
    return () => resizeObserver.disconnect() // clean up
  }, [reactFlowInstance, disableFitOnResize])

  useEffect(() => {
    setTimeout(() => reactFlowInstance?.fitView(), 0)
  }, [reactFlowInstance, showWorkflowTable, fitView])

  return (
    <ReactFlowProvider>
      <div ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={setReactFlowInstance}
          onDrop={(event) => {
            event.preventDefault()
            const position = reactFlowInstance.screenToFlowPosition({
              x: event.clientX,
              y: event.clientY
            })
            onDrop(event, position)
          }}
          onDragOver={onDragOver}
          fitView
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          defaultViewport={defaultViewport}
          onConnectStart={(event, data) => {
            setConnectionLineType(data.handleId)
          }}
          style={{ backgroundColor: theme.palette.background.row2 }}
          proOptions={{ hideAttribution: true }}
          onSelectionChange={(selection) => onSelectionChange(selection)}
          deleteKeyCode='FakeDelete'
          connectionLineComponent={(event) => ConnectionLine({ fromX: event.fromX, fromY: event.fromY, toX: event.toX, toY: event.toY, label: event.label, type: connectionLineType, style: event.style })}
          panOnDrag={!isNodeSelected}
        >
          <Background />
          <Controls />
        </ReactFlow>
      </div>
    </ReactFlowProvider>
  )
}
export default SmartReactflow
