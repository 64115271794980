import { useEffect, useState } from "react";

/**
* Hook that will create a new pagination adding selected records and using extraFilterName
*/
function usePaginationMultiSelection(requestPagination, multipleSelectionValue, extraFilterParameter) {
  const [currentPagination, setCurrentPagination] = useState({})

  useEffect(() => {
    let newPagination = { ...requestPagination }
    let returnValue = [];
    if (multipleSelectionValue.rows.length === 0 && !multipleSelectionValue.allSelected) {
      return
    }
    multipleSelectionValue.rows.forEach(row => {
      Object.keys(row).forEach(element => {
        returnValue.push(row[element])
      });
    })
    newPagination.rows = returnValue
    newPagination.allSelected = multipleSelectionValue.allSelected;
    newPagination.extraFilterName = extraFilterParameter.name
    newPagination.extraFilterValue = !extraFilterParameter.value
    setCurrentPagination(newPagination)
  }, [requestPagination, extraFilterParameter, multipleSelectionValue]);

  return currentPagination
}

export default usePaginationMultiSelection