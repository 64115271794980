import { useSmartTranslation } from "../../../generic/hooks/useSmartTranslation";
import AlarmsNotifications from "../AlarmsNotifications";

const Alarms = (props) => {
  const { toolbar, unChecked, pendingChanges } = props
  const { t_ } = useSmartTranslation()

  return (
    <AlarmsNotifications title={t_("Alarms")} state="ALARMS" unChecked={unChecked} toolbar={toolbar} pendingChanges={pendingChanges} />
  )
}
export default Alarms