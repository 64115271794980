import React from 'react'
import FixtureTypes from "../configuration/fixture/FixtureTypes";
import SmartNavigator from '../generic/SmartNavigator';
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation';

const MainFixtureTypes = () => {
  const { t_ } = useSmartTranslation();
  const componentsInfo = [
    {
      title: t_("Fixture Types"),
      component: <FixtureTypes />,
    },
  ];
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  );
}

export default MainFixtureTypes
