import { Button } from '@mui/material'
import React, { memo } from 'react'
import { Handle, Position } from 'reactflow'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'

const StartNode = ({ data }) => {
  const { t_ } = useSmartTranslation()

  const handleSize = '0.6rem'

  return (
    <div className='conditionalNode' style={{ textAlign: 'center' }}>
      <Button
        color='info'
            // onClick={() => handleNewJobReferences(technology.name)}
        variant='contained'
      >{t_('Start')}
      </Button>
      <Handle
        id='start'
        type='source'
        position={Position.Right}
        style={{ width: handleSize, height: handleSize }}
        isConnectable={data.isConnectable}
      />
    </div>
  )
}

export default memo(StartNode)
