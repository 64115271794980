import { useState } from "react";


import React from 'react'
import { Button, Input } from "@mui/material";
import SmartMateriaUITable from "./SmartMateriaUITable";
import GlobalConfig from "./GlobalConfig";

let baseEndpointUrl = GlobalConfig["back-end_address"];

const SmartMaterialUITableTester = () => {


  const [file, setFile] = useState(null)

  //const [columns, setColumns] = useState(null)
  const [data,] = useState(null)
  const [columns,] = useState(null)

  /*const gettingColumns = (data) => {
    let keys = Object.keys(data[0])
    let columns = []

    keys.map((key) => (

      columns.push({ field: key, name: key })

    ))


    setColumns(columns)
  }*/



  const handleChange = ({ target: { files } }) => {
    setFile(files[0]);
    console.log(files[0])
  };

  // const importCSV = () => {
  //   let updates = [];
  //   Papa.parse(file, {
  //     //     before: function(file, inputElem)
  //     // {
  //     // 	// executed before parsing each file begins;
  //     // 	// what you return here controls the flow
  //     // },

  //     delimiter: "",
  //     chunkSize: 3,
  //     header: true,
  //     complete: function (responses) {
  //       console.log(responses.data.length, responses);
  //       setData(responses.data)
  //       gettingColumns(responses.data)
  //     }
  //   });
  // };

  const importCSV = async () => {

    const formData = new FormData();
    formData.append("file", file);


    const response = await fetch(baseEndpointUrl + "import/Fixture", {
      method: "PUT",
      body: formData,
    });

    return response
  };


  return (
    <>

      <Input
        type="file"
        onChange={handleChange}
      />

      <Button onClick={importCSV}>Click Me</Button>

      {columns !== null ?
        <SmartMateriaUITable
          dataFetch={data}
          title="olaKase"
          columns={columns}

        /> : null}
    </>
  );
}
export default SmartMaterialUITableTester