import React from 'react'
import RawMaterialReference from '../configuration/parts/RawMaterialReference';
import SmartNavigator from '../generic/SmartNavigator';
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";

const MainRawMaterial = () => {
  const { t_ } = useSmartTranslation();
  const componentsInfo = [
    {
      title: t_("Raw Material Reference"),
      component: <RawMaterialReference />,
    },
  ];
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  );
}

export default MainRawMaterial
