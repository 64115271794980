import { TextField, useMediaQuery, useTheme } from '@mui/material'
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers'
import React, { useState } from 'react'
import { useSmartTranslation } from '../hooks/useSmartTranslation'

const Index = (props) => {
  const { resultDate } = props
  const [entryDate, setEntryDate] = useState(resultDate.current)

  const theme = useTheme()
  const isMobileIsTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const { t_ } = useSmartTranslation()

  return (

    isMobileIsTablet
      ? (

        <MobileDatePicker
          disableMaskedInput
          label={t_('Entry date')}
          inputFormat='dd/MMM/yyyy HH:mm'
          value={entryDate}
          onChange={(newValue) => {
            setEntryDate(newValue)
            resultDate.current = newValue
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        )
      : <DesktopDatePicker
          disableMaskedInput
          label={t_('Entry date')}
          inputFormat='dd/MMM/yyyy HH:mm'
          value={entryDate}
          onChange={(newValue) => {
            setEntryDate(newValue)
            resultDate.current = newValue
          }}
          renderInput={(params) => <TextField {...params} />}
        />

  )
}

export default Index
