import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router'
import AlertUI from '../../generic/AlertUI'
import { apiDelete, apiGet, apiPost } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const SensorsActuatorsComponents = () => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const [AlertElement, showAlert] = AlertUI();
  const showAlertReference = useRef()
  const { t_ } = useSmartTranslation();

  useEffect(() => {
    showAlertReference.current = showAlert
  }, [showAlert])


  useEffect(() => {
    if (state === "BACK") {
      showAlertReference.current({
        title: t_("There have been no modifications."),
        severity: "info",

      })
    } else if (state === "EDIT") {
      showAlertReference.current({
        title: t_("Created correctly."),
        severity: "success",
      })
    }
  }, [state, t_])

  const columns = [{
    name: t_("Code"),
    field: "code",
    readOnly: true,
    charLimit: 60,
    mandatory: true,
    unique: true
  },
  {
    name: t_("Name"),
    field: "name",
    charLimit: 80,
    mandatory: true
  },
  {
    name: t_("Description"),
    field: "description",
    charLimit: 200,
  },
  {
    name: t_("Asset"),
    field: "device.name",
    type: "select",
    mandatory: true,
    edit: {
      source: async () => {
        return await apiGet("devicesBasicData");
      },
      id: "id",
      field: "name",
    }
  },
  {
    name: t_("Type"),
    field: "ioElementSetType.name",
    type: "select",
    mandatory: true,
    edit: {
      source: async () => {
        return await apiGet("ioElementSetTypes");
      },
      id: "id",
      field: "name",
    }
  }
  ]

  const actions = {
    create: {
      fetchFunction: (row) => {
        return apiPost("ioElementSets", row)
      }
    },
    edit: {
      redirect: true,
      redirectFunction: (row) => {
        navigate("/configuration/sensorsActuatorsComponents/" + row.id, { replace: true })
      }
    },
    delete: {
      fetchFunction: (rows) => {
        return apiDelete("ioElementSets/", rows)
      },
    },
  }
  return (
    <>
      {AlertElement}
      < SmartMateriaUITable
        columns={columns}
        title={t_("Sensors & Actuators components")}
        dataFetch="ioElementSets"
        actions={actions}
        multipleSelection
      />

    </>
  )
}

export default SensorsActuatorsComponents
