import React, { useState, useEffect, useReducer, useRef, useMemo, useCallback } from 'react'
import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import { useTheme } from '@emotion/react'
import { Chip, Stack, Tooltip, Typography } from '@mui/material'
import PlannerCalendarToolbar from './PlannerCalendarToolbar'
import { apiGet, apiPost, apiPut } from '../generic/Api_Functions'
import './plannerStyle.css'
import moment from 'moment'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import WarningIcon from '@mui/icons-material/Warning'
import useFrontEndConfig from '../generic/hooks/useFrontEndConfig/useFrontEndConfig'

// Import typedefs from another file
/**
 * @typedef {import('../generic/utilities/objectStructures/planner/eventInfo').EventInfo} EventInfo EventInfo object
 * @typedef {import('../generic/utilities/objectStructures/planner/planification').Planification} Planification Planification object
 * @typedef {import('../generic/utilities/objectStructures/planner/eventInfo').ExtendedProps} ExtendedProps ExtendedProps object
 */

/**
 *
 * @param {Object} props Props object
 * @param {'machines'|'parts'} props.groupby Calendar showing option. It coulb be as assets *('machine' option)* or parts *('parts' option)*
 * @param {function()} props.onEventClick Method triggered after clicking on a task
 * @param {Planification} props.selectedFile Planification object
 * @param {React.Dispatch<React.SetStateAction<Planification>} props.setSelectedFile selectedFile use state setter
 * @param {React.Dispatch<React.SetStateAction<String>} props.setGroupby groupBy use state setter
 * @param {boolean} props.isPlannerWorking If true, planner is running
 */
const Calendar = ({ groupby, onEventClick, selectedFile, setSelectedFile, setGroupby, isPlannerWorking, forceReloadCalendar }) => {
  const MIN_DURATION_HOURS = 8
  const MS_HOUR = 1000 * 60 * 60
  const MS_DAY = 1000 * 60 * 60 * 24
  const resourceAreaWidth = '20%'

  const [currentSetDatesInfo, setCurrentSetDatesInfo] = useState(null)
  const calendarRef = useRef()
  const { t_, i18n } = useSmartTranslation()
  const [config] = useFrontEndConfig()

  const calculateNewDurationFromMs = (ms) => {
    if (ms < (MIN_DURATION_HOURS * 60 * 60 * 1000)) {
      return { mode: 'hours', value: MIN_DURATION_HOURS, isMaxValue: false, isMinValue: true }
    }
    if (ms < MS_DAY) {
      // Round to an hour value
      return { mode: 'hours', value: Math.round(ms / MS_HOUR), isMaxValue: false, isMinValue: false }
    }
    // At least is a day. We calculate aproximate number of days
    const days = Math.round(ms / MS_DAY)
    if (days < 7) {
      return { mode: 'days', value: days, isMaxValue: false, isMinValue: false }
    }
    // A week or more
    if (days < 28) {
      return { mode: 'weeks', value: Math.round(days / 7), isMaxValue: false, isMinValue: false }
    }
    // Month mode
    return { mode: 'months', value: 1, isMaxValue: true, isMinValue: false }
  }

  const calculateNewDuration = (state, action) => {
    // Set exact range
    if (action.type === 'SET_RANGE') {
      return calculateNewDurationFromMs(action.value)
    }

    // Set the new value
    let newDuration = state.value

    if (action.type === 'ZOOM_IN') {
      newDuration = newDuration / 2
    } else if (action.type === 'ZOOM_OUT') {
      newDuration = newDuration * 2
    }

    // Month in previous call
    if (state.mode === 'months') {
      if (newDuration < 1) {
        // Change to weeks
        return { mode: 'weeks', value: 2, isMaxValue: false, isMinValue: false }
      }
      return { mode: 'months', value: 1, isMaxValue: true, isMinValue: false }
    }

    // Week in previous call
    if (state.mode === 'weeks') {
      if (newDuration < 1) {
        // Change to weeks
        return { mode: 'days', value: 3, isMaxValue: false, isMinValue: false }
      }
      if (newDuration > 3) {
        return { mode: 'months', value: 1, isMaxValue: false, isMinValue: false }
      }
      return { mode: 'weeks', value: Math.round(newDuration), isMaxValue: false, isMinValue: false }
    }

    // Days in previous call
    if (state.mode === 'days') {
      if (newDuration < 1) {
        // Change to hours
        return { mode: 'hours', value: 12, isMaxValue: false, isMinValue: false }
      }
      if (newDuration > 3) {
        return { mode: 'weeks', value: 1, isMaxValue: false, isMinValue: false }
      }
      return { mode: 'days', value: Math.round(newDuration), isMaxValue: false, isMinValue: false }
    }

    // Hours in previous call
    if (state.mode === 'hours') {
      if (newDuration < MIN_DURATION_HOURS) {
        return { mode: 'hours', value: MIN_DURATION_HOURS, isMaxValue: false, isMinValue: true }
      }
      if (newDuration > 23) {
        return { mode: 'days', value: 1, isMaxValue: false, isMinValue: false }
      }
      return { mode: 'hours', value: Math.round(newDuration), isMaxValue: false, isMinValue: false }
    }
  }

  useEffect(() => {
    if (!calendarRef.current) return
    calendarRef.current.calendar.refetchEvents()
  }, [forceReloadCalendar])

  const locale = useMemo(() => {
    if (i18n.language.startsWith('es')) {
      return 'es'
    } else if (i18n.language.startsWith('fr')) {
      return 'fr'
    } else if (i18n.language.startsWith('en')) {
      return 'en-gb'
    } else if (i18n.language.startsWith('pt')) {
      return 'pt'
    } else if (i18n.language.startsWith('ca')) {
      return 'ca'
    }
    return 'en-gb'
  }, [i18n.language])

  const onDispatchDurationChange = (state, action) => {
    const newData = calculateNewDuration(state, action)

    // Calculate aprox time in hours
    let hours = newData.value
    if (newData.mode === 'ms') {
      hours = Math.round(hours / MS_HOUR)
    } else if (newData.mode === 'days') {
      hours = hours * 24
    } else if (newData.mode === 'weeks') {
      hours = hours * 24 * 7
    } else if (newData.mode === 'months') {
      hours = hours * 24 * 30
    }

    // Decide slot interval depending on hours
    let interval
    let labelInterval
    if (hours < 48) {
      interval = '00:15:00'
      labelInterval = '00:30:00'
    } else if (hours < 100) {
      interval = '00:30:00'
      labelInterval = '01:00:00'
    } else if (hours < 300) {
      interval = '01:00:00'
      labelInterval = '02:00:00'
    } else if (hours < 600) {
      interval = '02:00:00'
      labelInterval = '04:00:00'
    } else {
      interval = '03:00:00'
      labelInterval = '06:00:00'
    }

    newData.interval = interval
    newData.labelInterval = labelInterval

    return newData
  }

  const [resources, setResources] = useState([])
  const [currentDuration, dispatchNewDuration] = useReducer(onDispatchDurationChange, { type: 'SET_RANGE', value: MS_DAY }, (arg) => onDispatchDurationChange(null, arg))
  const theme = useTheme()

  useEffect(() => {
    document.documentElement.style.setProperty('--fc-border-color', theme.palette.divider)
    document.documentElement.style.setProperty('--fc-page-bg-color', theme.palette.background.default)
    document.documentElement.style.setProperty('--fc-list-event-hover-bg-color', theme.palette.background.default)
    document.documentElement.style.setProperty('--fc-button-border-color', theme.palette.Button)
    document.documentElement.style.setProperty('--fc-button-bg-color', theme.palette.Button)
    document.documentElement.style.setProperty('--fc-button-hover-bg-color', theme.palette.Button)
    document.documentElement.style.setProperty('--planner-timeline-background-color', theme.palette.background.color_373636)
    document.documentElement.style.setProperty('--planner-timeline-background-color-header', theme.palette.background.row2)
    document.documentElement.style.setProperty('--planner-timeline-background-color-odd', theme.palette.row2)
    document.documentElement.style.setProperty('--planner-timeline-border-color', theme.palette.primary.main + '80')
    document.documentElement.style.setProperty('--fc-bg-event-opacity', 1)
  }, [theme])

  const tooltipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            maxWidth: '70vw'
          }
        }
      }
    },
    palette: {
      primary: {
        main: theme.palette.primary.main
      }
    }
  })
  const loadResources = async (groupby, planificationId) => {
    const resources = await apiGet('planner/getResources/' + groupby, { planificationId })
    setResources(resources)
  }
  useEffect(() => {
    if (groupby !== null && selectedFile !== null) {
      loadResources(groupby, selectedFile.id)
    }
  }, [groupby, selectedFile])

  /**
   * Method used to drop event
   * @param {EventInfo} eventDropInfo
   */
  const eventDrop = (eventDropInfo) => {
    const machineNames = eventDropInfo.event.extendedProps.machineNames
    let previousTask = calendarRef.current.calendar.getEvents().find(task => task.extendedProps.partId === eventDropInfo.event.extendedProps.partId && task.extendedProps.currentJobNum === eventDropInfo.event.extendedProps.currentJobNum - 1)
    let nextTask = calendarRef.current.calendar.getEvents().find(task => task.extendedProps.partId === eventDropInfo.event.extendedProps.partId && task.extendedProps.currentJobNum === eventDropInfo.event.extendedProps.currentJobNum + 1)

    // Set end if is not set yet
    let end = eventDropInfo.event.end
    if (!end) {
      const duration = eventDropInfo.oldEvent.end.valueOf() - eventDropInfo.oldEvent.start.valueOf()
      end = duration + eventDropInfo.event.start.valueOf()
      end = new Date(end)
      eventDropInfo.event.setEnd(end)
    }

    const resource = eventDropInfo.newResource != null ? eventDropInfo.newResource : eventDropInfo.event.getResources()[0]

    const overlap = calendarRef.current.calendar.getEvents().find(task => {
      // Check that is not the same event that we dropped
      if (task.id === eventDropInfo.event.id) {
        return false
      }

      // Check it is on  the same resource
      if (resource.id !== task.getResources()[0].id) {
        return false
      }

      // Check if there is overlaping with another event
      if (
        !(eventDropInfo.event.start >= task.start && eventDropInfo.event.start < task.end) &&
        !(eventDropInfo.event.end > task.start && eventDropInfo.event.end <= task.end) &&
        !(eventDropInfo.event.start <= task.start && eventDropInfo.event.end >= task.end)
      ) {
        return false
      }

      return true
    })

    // If we found an overlap revert
    if (overlap) {
      eventDropInfo.revert()
      return false
    }

    if (selectedFile.unitCriteria?.name === 'Fabrication Order') {
      previousTask = calendarRef.current.calendar.getEvents().find(task => task.extendedProps.fabricationOrderId === eventDropInfo.event.extendedProps.fabricationOrderId && task.extendedProps.currentJobNum === eventDropInfo.event.extendedProps.currentJobNum - 1)
      nextTask = calendarRef.current.calendar.getEvents().find(task => task.extendedProps.fabricationOrderId === eventDropInfo.event.extendedProps.fabricationOrderId && task.extendedProps.currentJobNum === eventDropInfo.event.extendedProps.currentJobNum + 1)
    }

    if (previousTask !== undefined) {
      if (previousTask.end.valueOf() > eventDropInfo.event.start.valueOf()) {
        eventDropInfo.revert()
        return false
      }
    }

    if (nextTask !== undefined) {
      if (nextTask.start.valueOf() < end.valueOf()) {
        eventDropInfo.revert()
        return false
      }
    }

    if (eventDropInfo.newResource != null) {
      machineNames.includes(eventDropInfo.newResource.title) ? updateTask(eventDropInfo, end) : eventDropInfo.revert()
    } else {
      updateTask(eventDropInfo, end)
    }
  }
  /**
   * Method used to update task
   * @param {EventInfo} eventDropInfo
   */
  const updateTask = async (eventDropInfo, newEnd) => {
    const eventId = eventDropInfo.event.id
    const newResourceId = eventDropInfo.newResource != null ? eventDropInfo.newResource.id : null
    const newStart = eventDropInfo.event.start
    const partId = eventDropInfo.event.extendedProps.partId

    await apiPut('planificationTaskModification/update/' + selectedFile.id, { id: eventId, resourceId: newResourceId, dateStart: newStart.valueOf(), dateEnd: newEnd.valueOf(), partId, fabricationOrderId: eventDropInfo.event.extendedProps.fabricationOrderId, taskReferenceId: eventDropInfo.event.extendedProps.taskReferenceId })
    calendarRef.current.calendar.refetchEvents()
    if (!selectedFile.editing) {
      setSelectedFile({ ...selectedFile, editing: true })
    }
  }
  const onRangeSelected = (selectInfo) => {
    // Calculate selected range
    dispatchNewDuration({ mode: 'ms', type: 'SET_RANGE', value: selectInfo.end - selectInfo.start })

    // Move to the start position of the selected range
    calendarRef.current.getApi().gotoDate(selectInfo.start)
  }

  const loadPlanification = useCallback(async (startTime, endTime, groupby) => {
    if (Object.keys(selectedFile).length === 0) {
      return []
    }
    if (groupby === null) {
      return []
    }
    const file = selectedFile.id
    const response = await apiPost('planner/planificationWithCalendarEvents', { planificationId: file, startDate: startTime, endDate: endTime, groupBy: groupby })
    response.forEach(element => {
      if (!element.jobEvent) {
        element.display = 'background'
      }
    })
    return response
  }, [selectedFile])

  const onDateChange = (event) => {
    if (event === 'prev') {
      calendarRef.current.calendar.prev()
    } else if (event === 'next') {
      calendarRef.current.calendar.next()
    } else if (event === 'today') {
      calendarRef.current.calendar.today()
    }
  }
  const resourceAreaHeaderContent = () => {
    switch (groupby) {
      case 'devices':
        return t_('Assets')
      case 'parts':
        return t_('Parts')
      case 'technologies':
        return t_('Technologies')
      case 'references':
        return t_('References')
      case 'fabrication_orders':
        return t_('Fabrication orders')
      default:
        break
    }
  }
  const durationKeyValue = {}
  durationKeyValue[currentDuration.mode] = currentDuration.value
  return (

    <Stack gap={1}>
      <PlannerCalendarToolbar
        onDateChange={onDateChange}
        setDatesInfo={currentSetDatesInfo}
        currentDuration={currentDuration}
        dispatchNewDuration={dispatchNewDuration}
        setGroupby={setGroupby}
        groupby={groupby}
      />

      <FullCalendar
        locales={allLocales}
        locale={locale}
        ref={calendarRef}
        headerToolbar={null}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        datesSet={(setDatesInfo) => setCurrentSetDatesInfo(setDatesInfo)}
        initialView='resourceTimeLine'
        views={{
          resourceTimeLine: {
            type: 'resourceTimeline',
            duration: durationKeyValue
          }
        }}
        nowIndicator
        resourceAreaHeaderContent={resourceAreaHeaderContent}
        resources={resources}
        events={async (info) => {
          if (isPlannerWorking) {
            return []
          }
          const data = await loadPlanification(info.start.valueOf(), info.end.valueOf(), groupby)
          return data
        }}
        resourceAreaWidth={resourceAreaWidth}
        slotDuration={currentDuration.interval}
        slotLabelInterval={currentDuration.labelInterval}
        eventMaxStack={1}
        eventMinWidth={5}
        editable={groupby === 'devices'}
        eventDurationEditable={false}
        selectable
        select={onRangeSelected}
        eventDrop={eventDrop}
        resourceOrder='title,id'
        height={groupby === 'parts' ? 500 : 'auto'}
        eventClick={config?.plannerIsActive ? onEventClick : null}
        moreLinkContent={(info) => {
          return <Chip label={info.shortText} size='small' />
        }}
        moreLinkDidMount={(info) => {
          info.el.style.padding = 0
          // TODO Decidir si quieren mostrar o no la caja
          // info.el.style.backgroundColor = "transparent"
        }}
        eventDidMount={(info) => {
          info.el.style.padding = 0
          info.el.style.height = '3rem'
        }}
        eventContent={(/** @type {EventInfo} Event info param */ eventInfo) => {
          if (!eventInfo.event.extendedProps?.jobEvent) {
            return (
              <ThemeProvider theme={tooltipTheme}>
                <Tooltip
                  PopperProps={{ style: { zIndex: 1000000 } }}
                  title={
                    <Stack sx={{ whiteSpace: 'nowrap' }}>
                      <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Event')}:</Typography><Typography>{eventInfo.event.extendedProps.jobEventName}</Typography></Stack>
                      <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Date start')}:</Typography><Typography>{moment(eventInfo.event.start).format('YYYY-MM-DD HH:mm')}</Typography></Stack>
                      <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Date end')}:</Typography><Typography>{moment(eventInfo.event.end).format('YYYY-MM-DD HH:mm')}</Typography></Stack>
                    </Stack>
                }
                >
                  <Stack
                    sx={{
                      height: '3rem',
                      overflowX: 'hidden',
                      background: !eventInfo.event.extendedProps.systemDefault
                        ? 'repeating-linear-gradient(45deg, ' + theme.palette.error.dark + ', ' + theme.palette.error.dark + ' 10px, ' + theme.palette.error.main + ' 10px, ' + theme.palette.error.main + ' 20px)'
                        : 'repeating-linear-gradient(45deg, ' + theme.palette.calendar.systemDefault + ', ' + theme.palette.calendar.systemDefault + ' 10px, ' + theme.palette.error.main + ' 10px, ' + theme.palette.error.main + ' 20px)'
                    }}
                    direction='row'
                    justifyContent='center'
                  />
                </Tooltip>
              </ThemeProvider>
            )
          }
          return (
            <ThemeProvider theme={tooltipTheme}>
              <Tooltip
                PopperProps={{ style: { zIndex: 1000000 } }} title={
                  <Stack
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Asset name')}:</Typography><Typography>{eventInfo.event.extendedProps.machineName}</Typography></Stack>
                    <Stack direction='row' gap={1}>
                      <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('FO')}:</Typography><Typography>{eventInfo.event.extendedProps.nameFO}</Typography></Stack>
                      <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Delivery date')}:</Typography><Typography>{eventInfo.event.extendedProps.deliveryDateFO !== null ? moment(eventInfo.event.extendedProps.deliveryDateFO).format('YYYY-MM-DD HH:mm') : null}</Typography></Stack>
                    </Stack>
                    {selectedFile?.unitCriteria.name === 'Job'
                      ? (
                        <Stack direction='row' gap={1}>
                          <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Part name')}:</Typography><Typography>{eventInfo.event.extendedProps.partName}</Typography></Stack>
                          <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Status')}:</Typography><Typography>{eventInfo.event.extendedProps.status}</Typography></Stack>
                        </Stack>)
                      : null}
                    <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Tool reference')}:</Typography><Typography>{eventInfo.event.extendedProps.toolReferences.toString()}</Typography></Stack>
                    {selectedFile?.unitCriteria.name === 'Job' ? <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Fixture')}:</Typography><Typography>{eventInfo.event.extendedProps.fixtureName}</Typography></Stack> : null}
                    <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Job')}:</Typography>
                      <Typography sx={{
                        display: 'inline-block',
                        width: '24px',
                        height: '24px',
                        lineHeight: '24px',
                        borderRadius: '50%',
                        background: '#555555',
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '14px',
                        border: '1px solid #51cb99'
                      }}
                      >
                        {eventInfo.event.extendedProps.currentJobNum}
                      </Typography>
                      <Typography>{eventInfo.event.extendedProps.currentJob}</Typography>
                      <Typography sx={{ color: '#CCCCCC' }}>
                        {eventInfo.event.extendedProps.currentJobNum + '/' + eventInfo.event.extendedProps.totalJobCount}
                      </Typography>
                      <Typography sx={{ fontSize: 14, color: '#CCCCCC' }}>
                        {t_('(step n./total steps)')}
                      </Typography>
                    </Stack>
                    <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Previous job')}:</Typography><Typography>{eventInfo.event.extendedProps.previousJob}</Typography></Stack>
                    <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Next job')}:</Typography><Typography>{eventInfo.event.extendedProps.nextJob}</Typography></Stack>
                    <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Date start')}:</Typography><Typography>{moment(eventInfo.event.start).format('YYYY-MM-DD HH:mm')}</Typography></Stack>
                    <Stack direction='row' gap={1}><Typography type='h3' color='primary'>{t_('Date end')}:</Typography><Typography>{moment(eventInfo.event.end).format('YYYY-MM-DD HH:mm')}</Typography></Stack>
                  </Stack>
                }
              >
                <Stack
                  style={{ height: '3rem', overflowX: 'hidden', background: eventInfo.event.extendedProps.late ? 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.25) 50%, rgba(255,255,255,0) 100%)' : null }} direction='row'
                  justifyContent='center'
                >
                  {eventInfo.event.extendedProps.late ? <WarningIcon wrap sx={{ height: '100%', width: 'auto' }} color='error' /> : null}
                </Stack>
              </Tooltip>
            </ThemeProvider>
          )
        }}
      />
    </Stack>

  )
}

export default React.memo(Calendar)
