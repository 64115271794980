import { apiPut, apiPost, apiDelete, apiGet } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const ReasonType = () => {
  const { t_ } = useSmartTranslation()
  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Name'),
      field: 'name',
      charLimit: 80,
      mandatory: true,
      unique: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    },
    {
      name: t_('FO Status type'),
      field: 'foStatusType.name',
      mandatory: true,
      type: 'smartSelect',
      readOnly: (row) => {
        return row.systemDefault
      },
      edit: {
        source: async () => {
          return await apiGet('fabricationOrderStatusTypeBasic')
        },
        id: 'id',
        field: 'name'
      }

    }
  ]

  const actions = {
    create: {
      fetchFunction: (row) => apiPost('reasonType', row)
    },
    edit: {
      fetchFunction: (row) => apiPut('reasonType', row)
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('reasonType/' + rows[0].id)
        } else {
          return apiDelete('reasonTypeMulti', rows)
        }
      },
      visibilityButton: (row) => {
        return !row.systemDefault
      }
    }
  }

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Reason types')}
      dataFetch='reasonTypes'
      actions={actions}
      pageable
      multipleSelection
    />
  )
}

export default ReasonType
