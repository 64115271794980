import React, { useState } from 'react'
import { apiDelete, apiGet, apiPut, getBackendUrl } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import Proptypes from 'prop-types'
import CreateDocument from './CreateDocument'
import { IconButton } from '@mui/material'
import { FileDownloadOutlined, Visibility } from '@mui/icons-material'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import SmartThreejs from './visualization/3d/SmartThreejs'
import AlertUI from '../../generic/AlertUI'
import SmartReactDocViewer from './visualization/2d/SmartReactDocViewer'

// Import typedefs from another file
/**
 * @typedef {import('../../generic/utilities/objectStructures/globalStructures').IdCodeName} IdCodeName IdCodeName object
 * @typedef {import('../../generic/utilities/objectStructures/documentWindow/document').Document} Document Document dto
 */

/**
 * Document object
 * @param {Object} props Props object
 * @param {IdCodeName} props.reference Reference
 * @param {'PART'|'PART_REFERENCE'} props.resourceType Resource type
 * @returns
 */
const Document = ({ reference, resourceType }) => {
  const { t_ } = useSmartTranslation()
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false)
  /** @type {[Document, React.Dispatch<SetStateAction<Document>>]} */
  const [selectedDocument, setSelectedDocument] = useState({})
  const [AlertElement, showAlert] = AlertUI()
  /** @type {[string[], React.Dispatch<SetStateAction<string[]>>]} */
  const [documentNames, setDocumentNames] = useState([])

  const compatible3DExtensionList = ['obj', 'stl', 'ply']

  // The React Doc viewer library lists the following extension as supported but they are't working so we will exclude them from the supported extension list
  //  odt, doc, docx, xls, xlsx, ppt, pptx
  const compatibleDocExtensionList = ['bmp', 'csv', 'gif', 'htm', 'html', 'jpg', 'jpeg', 'pdf', 'png', 'tiff', 'txt', 'mp4']

  const columns = [
    {
      name: t_('Document name'),
      field: 'documentName',
      charLimit: 80,
      mandatory: true,
      unique: true,
      readOnly: true
    },
    {
      name: t_('File name'),
      field: 'fileName',
      charLimit: 80,
      mandatory: true,
      unique: true,
      readOnly: true
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    },
    {
      name: t_('Type'),
      field: 'documentType.name',
      type: 'smartSelect',
      edit: {
        source: async () => {
          return await apiGet('documentTypes')
        },
        id: 'id',
        field: 'name'
      },
      mandatory: true
    }
  ]

  const actions = {
    create: {
      fetchFunction: null,
      render: () => {
        return (
          <CreateDocument reference={reference} resourceType={resourceType} usedDocumentNames={documentNames} />
        )
      }
    },
    edit: {
      fetchFunction: (row) => {
        return apiPut('document', row)
      }
    },
    delete: {
      fetchFunction: (row) => {
        return apiDelete('document/' + resourceType + '/' + reference.id + '/' + row[0].id)
      }
    },
    custom: [
      {
        name: t_('View file'),
        render: (row) => {
          return (
            <IconButton
              onClick={() => {
                const document = row

                if (compatible3DExtensionList.find(extension => extension === row.extension?.toLowerCase()) === undefined && compatibleDocExtensionList.find(extension => extension === row.extension?.toLowerCase()) === undefined) {
                  showAlert({
                    title: '',
                    message: t_('Currently file view not available to the extension file: ') + row.extension,
                    severity: 'warning'
                  })
                  return
                }
                if (compatible3DExtensionList.find(extension => extension === row.extension?.toLowerCase()) !== undefined) {
                  document.compatible3DFile = true
                }

                setSelectedDocument(document)
                setOpenDocumentDialog(true)
              }}
            >
              <Visibility />
            </IconButton>
          )
        }
      },
      {
        name: t_('Download file'),
        render: (row) => {
          return (
            <IconButton
              onClick={() => {
                // Generate blob, url and download file with the content above
                const link = document.createElement('a')
                link.download = row.name
                link.href = getBackendUrl() + 'document/file/download/' + row.id
                link.click()
              }}
            >
              <FileDownloadOutlined />
            </IconButton>
          )
        }
      }
    ]
  }

  const dataFetch = async () => {
    const data = await apiGet('document/' + resourceType + '/' + reference.id)
    setDocumentNames(data.map(document => document.documentName))
    return data
  }

  return (
    <div>
      {AlertElement}
      <SmartDialog
        setOpen={openDocumentDialog}
        accept={false}
        cancel={false}
        close
        closeCallback={() => setOpenDocumentDialog(false)}
        renderComponent={
          selectedDocument.compatible3DFile
            ? <SmartThreejs documentId={selectedDocument.id} modelType={selectedDocument.extension} />
            : <SmartReactDocViewer documentId={selectedDocument.id} documentName={selectedDocument.name} />
        }
        paperWidth={selectedDocument.compatible3DFile ? '100%' : undefined}
      />
      <SmartMateriaUITable
        columns={columns}
        title={t_('Documents') + ' - ' + reference.name}
        dataFetch={dataFetch}
        actions={actions}
      />
    </div>
  )
}

Document.propTypes = {
  reference: Proptypes.shape({
    id: Proptypes.number,
    code: Proptypes.string,
    name: Proptypes.string
  }).isRequired,
  resourceType: Proptypes.oneOf(['PART', 'PART_REFERENCE', 'FEATURE']).isRequired
}

export default Document
