import React from 'react'
import SmartNavigator from '../generic/SmartNavigator'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import FabricationOrderStatus from '../configuration/fabricationOrderStatus/FabricationOrderStatus'
import FabricationOrderStatusTypes from '../configuration/fabricationOrderStatus/FabricationOrderStatusTypes'
const MainFabricationOrderStatus = () => {
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('Fabrication order status'),
      component: <FabricationOrderStatus />,
      to: '/configuration/picklist/fabricationOrderStatus'
    },
    {
      title: t_('Fabrication order status types'),
      component: <FabricationOrderStatusTypes />,
      to: '/configuration/picklist/fabricationOrderStatusTypes'
    }
  ]
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  )
}

export default MainFabricationOrderStatus
