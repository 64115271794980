import { IconButton } from '@mui/material'

const RoundedIconButton = (props) => {
  const { selected, onClick, icon, backgroundColor } = props
  return (
    <IconButton size='large' sx={{ padding: '50px', backgroundColor, border: selected ? '3px solid black' : null, '&:hover': { backgroundColor, opacity: 0.75 } }} onClick={onClick}>
      {icon}
    </IconButton>
  )
}

export default RoundedIconButton
