import { apiPost } from './Api_Functions'
import bcrypt from 'bcryptjs-react'
export const CheckCredentials = async (username, password, dbUserPassword) => {
  if (dbUserPassword) {
    const dbSalt = dbUserPassword.salt
    const challenge = dbUserPassword.challenge

    const hash1 = bcrypt.hashSync(password, dbSalt)
    const hash2 = bcrypt.hashSync(username + hash1, dbSalt)

    const hashedPassword3 = bcrypt.hashSync(username + hash2, challenge)

    dbUserPassword.hashedPassword = hashedPassword3
    dbUserPassword.salt = null
    const response = await apiPost('frontEndLogin', dbUserPassword)
    return response
  }
}
