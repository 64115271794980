import { useState } from 'react'
import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { CheckCorrectTimeFormatHoursAndMinutes } from '../../generic/DateTimeFunctions'

const Incidence = () => {
  const { t_ } = useSmartTranslation()
  const [editingRow, setEditingRow] = useState(undefined)
  const [isAddingNewRow, setIsAddingNewRow] = useState(false)

  const readOnlyAndNoEditable = (row) => {
    if (!row.unavailableCalendarEvent) {
      row.estimatedDuration = { edit: false }
      row.estimatedDuration = ''
      return true
    }
  }
  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      edit: false,
      charLimit: 60,
      mandatory: true,
      unique: true
    },
    {
      name: t_('Name'),
      field: 'name',
      edit: true,
      charLimit: 80,
      mandatory: true,
      unique: true
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    },
    {
      name: t_('Incidence type'),
      field: 'incidenceType.name',
      type: 'smartSelect',
      edit: {
        source: async () => {
          return await apiGet('incidenceTypesShortData')
        },
        id: 'id',
        field: 'name'
      },
      mandatory: true
    },
    {
      name: t_('Status'),
      field: 'status.name',
      type: 'smartSelect',
      edit: {
        source: async () => {
          return await apiGet('statusBasicData')
        },
        id: 'id',
        field: 'name'
      }
    },
    {
      name: t_('Unavailability'),
      field: 'unavailableCalendarEvent',
      type: 'boolean',
      readOnly: (row) => handleDisabled(row),
      reset: (row) => handleDisabled(row)
    },
    {
      name: t_('Estimated duration') + '(hh:mm)',
      field: 'estimatedDuration',
      charLimit: 10,
      readOnly: (row) => {
        return readOnlyAndNoEditable(row)
      },
      condition: (row) => {
        return CheckCorrectTimeFormatHoursAndMinutes(row.estimatedDuration, t_)
      },
      mandatory: (row) => {
        return row.unavailableCalendarEvent
      }
    }

  ]

  const handleDisabled = (row) => {
    if (!row.status) {
      return true
    }
    if (row.status.name === 'OFF' || row.status.name === 'WORK') {
      return true
    }
    if (editingRow?.id === row.id) {
      return false
    }
    if (isAddingNewRow && editingRow === undefined && row === null) {
      return false
    }
    if (!isAddingNewRow && editingRow === undefined) {
      return true
    }
    return true
  }

  const actions = {
    create: {
      fetchFunction: (incidence) =>
        apiPost('incidence/', incidence)

    },
    edit: {
      fetchFunction: (incidence) =>
        apiPut('incidence', incidence)

    },

    delete: {
      fetchFunction: (incidence) => {
        if (incidence.length === 1) {
          return apiDelete('incidence/' + incidence[0].id)
        } else {
          return apiDelete('incidenceMulti', incidence)
        }
      }
    }
  }

  const dataFetch = async (pagination) => {
    const orders = await apiGet('incidences', pagination)
    return orders
  }

  return (
    <>
      <SmartMateriaUITable
        title={t_('Incidences')}
        columns={columns}
        dataFetch={dataFetch}
        actions={actions}
        multipleSelection
        pageable
        multipleOptions
        onEditIndexChanged={(editIndex, row) => { setIsAddingNewRow(editIndex === -1); setEditingRow(row) }}
      />
    </>
  )
}

export default Incidence
