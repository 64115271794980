
import React from 'react'
import SmartSortableContainer from "../../generic/smartSortable/SmartSortableContainer";
import PropTypes from "prop-types";
import GenereicSortableRenderItem from '../../generic/smartSortable/GenericSortableRenderItem';

/**
 * Component used to order given task list
 */
const TaskListSorting = (props) => {
  const { taskList, sortingChange } = props

  const ReferenceWorkflowLinkerSortingItem = ({ item }) => {
    return <GenereicSortableRenderItem labels={[item?.defaultProgram?.name]} />;
  }

  const onSortChanged = (items) => {
    if (sortingChange && typeof sortingChange === 'function') {
      sortingChange(items)
    }
  }
  return (
    <SmartSortableContainer itemList={taskList} itemId='taskOrder' renderItem={ReferenceWorkflowLinkerSortingItem} onSortChanged={onSortChanged} />
  )
}

export default TaskListSorting


TaskListSorting.propTypes = {
  /**
   * Task list to order
   */
  taskList: PropTypes.array.isRequired,
  /**
   * Method that will be triggered when the order is changed
   */
  sortingChange: PropTypes.func.isRequired
}