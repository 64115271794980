import React from "react";
import {
  Drawer,
  Toolbar,
  Paper,
} from "@mui/material";
import { LeftMenu } from "./LeftMenu";
import theme from "../../themeConfig.js";

const drawerWidth = 240;
const drawerStyle = {
  width: drawerWidth,
  flexShrink: 0,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  ".MuiPaper-root": {
    backgroundColor: '#212121',
    overflow: "hidden",
  },
  ".MuiDrawer-paper": {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }
}
const drawerMinimizedStyle = {
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ".MuiPaper-root": {
    backgroundColor: '#212121',
    overflow: "hidden",
  },
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  ".MuiDrawer-paper": {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ".MuiPaper-root": {
      backgroundColor: '#212121',
      overflow: "hidden",
    },
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} - 3px)`,
    },
  }
}
const toolbarStyle = {
  height: "128px",
}

const LeftMenuContainer = (props) => {
  const { hideMenu, minimizeDrawer, setMinimizeDrawer, url, setHideMenu } = props;
  const handleMinimizeDrawer = () => {
    setMinimizeDrawer(!minimizeDrawer);
  };
  return hideMenu ? null : (
    <>
      <Drawer
        sx={minimizeDrawer ? drawerMinimizedStyle : drawerStyle}
        variant="permanent"
        component={Paper}
      >
        <Toolbar sx={toolbarStyle} />
        <LeftMenu url={url} minimizeDrawer={minimizeDrawer} handleMinimizeDrawer={handleMinimizeDrawer} setHideMenu={setHideMenu} />
      </Drawer>
    </>
  );
};

export default LeftMenuContainer;
