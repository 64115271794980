import React from 'react'
import { Fab, Typography } from '@mui/material'
import { ReplyRounded } from '@mui/icons-material'
import { useSmartTranslation } from '../../hooks/useSmartTranslation'

const ButtonBackFab = (props) => (
  <Fab
    onClick={props.onClick}
    color={props.color}
    aria-label={props.ariaLabel}
    variant={props.variant}
    sx={{
      backgroundColor: theme => theme.palette.background.button,
      color: theme => theme.palette.primary.main,
      border: theme => `1px solid ${theme.palette.primary.main}`,
      '&:hover': {
        backgroundColor: theme => theme.palette.background.buttonHover
      }
    }}
  >
    {props.children}
  </Fab>
)

const ButtonBack = (props) => {
  const { buttonAction, buttonFixed } = props
  const { t_ } = useSmartTranslation()
  return (
    <ButtonBackFab onClick={() => buttonAction()} color='primary' ariaLabel='add' variant='extended' buttonFixed={buttonFixed}>
      <ReplyRounded />
      <Typography style={{ marginLeft: '0.3em', fontSize: '0.875rem' }}>{t_('BACK')}</Typography>
    </ButtonBackFab>
  )
}
export default ButtonBack
