import { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../../../context/UserContextProvider';
import { apiGet } from '../../Api_Functions';

const useUser = () => {
  const userContext = useContext(UserContext);
  const [toggle, setToggle] = useState(false)
  useEffect(() => {
    if (userContext.user) return
    if (!localStorage.getItem('user')) return
    apiGet("user/details/" + localStorage.getItem('user'))
      .then(user => {
        userContext.setUser(user)
      })
      .catch(() => {
        userContext.setUser(null)
        localStorage.removeItem('user')
      })
  }, [userContext, toggle])

  if (!userContext) {
    throw new Error('useUser must be used within a UserProvider');
  }

  const userLogin = username => {
    localStorage.setItem('user', username)
    setToggle(prev => !prev)
  }

  const userLogout = () => {
    userContext.setUser(null)
    localStorage.removeItem('user')
  }

  const changeUserLanguage = language => {
    const newUser = { ...userContext.user, userConfiguration: { ...userContext.user.userConfiguration, languageCode: language } }
    userContext.setUser(newUser)
    return newUser
  }

  return {
    user: userContext.user,
    changeUserLanguage,
    userLogin,
    userLogout
  }
}

export default useUser