import { Close, Done } from '@mui/icons-material'
import React, { useState } from 'react'
import { apiGet } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'

const ToolReferenceParameterValue = (props) => {
  const { toolReference, actions, endPoint } = props

  const [title, setTitle] = useState(null)
  const columns = [
    {
      name: 'Code',
      field: 'code',
      readOnly: true,
      edit: false
    },
    {
      name: 'Name',
      field: 'name',
      readOnly: true,
      edit: false
    },
    {
      name: 'Type',
      field: 'toolParameterType.code',
      readOnly: true,
      edit: false
    },
    {
      name: 'Data Type',
      field: 'dataType',
      readOnly: true,
      edit: false
    },
    {
      name: 'Unit type',
      field: 'unitType.code',
      readOnly: true,
      edit: false
    },
    {
      name: 'Unit',
      field: 'unit.code',
      readOnly: true,
      edit: false
    },
    {
      name: 'Value',
      field: 'value'
      // mandatory: true,
    },
    {
      name: 'Mandatory',
      field: 'mandatory',
      render: (row) => {
        return (row.mandatory === true ? <Done color='primary' /> : <Close color='error' />)
      }
    }
  ]

  const dataFetch = async () => {
    const toolReferenceParameterValuesById = await apiGet(endPoint)

    setTitle('Tool Reference ' + toolReference.code + ' - Parameters values')
    return toolReferenceParameterValuesById
  }

  return (
    <div>

      <SmartMateriaUITable
        columns={columns}
        title={title}
        dataFetch={dataFetch}
        // multipleSelection
        actions={actions}
      />
    </div>
  )
}

export default ToolReferenceParameterValue
