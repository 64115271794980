import { Alert, Stack } from '@mui/material'
import SmartMateriaUITable from '../../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'
import { SmartDialog } from '../../../generic/utilities/SmartDialog'

const PauseResumeFinishedList = (props) => {
  const { t_ } = useSmartTranslation()

  const columns = [
    {
      name: t_('Code'),
      field: 'code'
    },
    {
      name: t_('Name'),
      field: 'name'
    }
  ]

  return (
    <SmartDialog
      setOpen={props.setOpen}
      cancel={false}
      close
      accept={false}
      closeCallback={props.closeCallback}
      renderComponent={
        <Stack gap={3}>
          <Alert severity='warning'>{t_('The following parts where ignored because they are already finished')}</Alert>
          <SmartMateriaUITable
            dataFetch={props.parts}
            columns={columns}
            dense
            toolbarDisplay='none'
          />
        </Stack>
      }
    />
  )
}

export default PauseResumeFinishedList
