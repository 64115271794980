import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useEffect, useState } from 'react'
import { apiGet, getBackendUrl } from '../../generic/Api_Functions'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import SmartReactDocViewer from './visualization/2d/SmartReactDocViewer'
import SmartDialog from '../../generic/utilities/SmartDialog/SmartDialog'
import measureImage from '../../../images/measure.png'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { TextSnippetOutlined } from '@mui/icons-material'

const DocumentPreview = ({ referenceId, onShowDocumentsClick, forceReloadDocuments = false, resourceType }) => {
  const [documents, setDocuments] = useState([])
  const [documentIndex, setDocumentIndex] = useState(0)
  const [selectedDocument, setSelectedDocument] = useState(null)

  useEffect(() => {
    const loadDocuments = async () => {
      const data = await apiGet('document/' + resourceType + '/' + referenceId)
      setDocuments(data)
    }
    if (!referenceId || !resourceType) {
      setDocuments([])
      return
    }
    loadDocuments()
  }, [referenceId, forceReloadDocuments, resourceType])

  if (documents.length === 0) {
    return <img onClick={onShowDocumentsClick} src={measureImage} alt='measure' style={{ width: '100%', height: '100%', objectFit: 'contain', cursor: 'pointer' }} />
  }

  const getPreview = (document) => {
    if (document.documentType.code === 'IMAGE') {
      return <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={getBackendUrl() + 'document/file/download/' + document.id} alt={document.documentName} />
    }
    return (
      <Stack gap={1} alignItems='center' sx={{ width: '100%', flex: 1 }}>
        <div style={{ position: 'relative' }}>
          <InsertDriveFileIcon sx={{ width: '15rem', height: '15rem' }} />
          <Typography color={(theme) => theme.palette.background.paper} sx={{ zIndex: 100, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{document.extension}</Typography>
          <Typography color='primary' sx={{ zIndex: 100, position: 'absolute', bottom: '0', left: '50%', transform: 'translate(-50%, 0)' }}>{document.documentName}</Typography>
        </div>
      </Stack>
    )
  }

  // Make sure index is inside range
  const changeDocumentIndex = (increment) => {
    setDocumentIndex(prevIndex => {
      let newIndex = prevIndex + increment
      if (newIndex >= documents.length) {
        newIndex = 0
      } else if (newIndex < 0) {
        newIndex = documents.length - 1
      }
      return newIndex
    })
  }

  return (
    <>
      {selectedDocument
        ? <SmartDialog
            setOpen={selectedDocument}
            accept={false}
            cancel={false}
            close
            closeCallback={() => setSelectedDocument(null)}
            renderComponent={<SmartReactDocViewer documentId={selectedDocument.id} documentName={selectedDocument.name} />}
          />
        : null}
      <Stack alignItems='center' gap={1}>
        <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
          <IconButton onClick={onShowDocumentsClick}><TextSnippetOutlined /></IconButton>
          {documents.length > 1
            ? (
              <Stack direction='row' alignItems='center' gap={3}>
                <Button onClick={() => changeDocumentIndex(-1)}><NavigateBeforeIcon /></Button>
                <Box>{(documentIndex + 1) + '/' + documents.length}</Box>
                <Button onClick={() => changeDocumentIndex(1)}><NavigateNextIcon /></Button>
              </Stack>
              )
            : null}
          <IconButton onClick={() => { setSelectedDocument(documents[documentIndex]) }}><FullscreenIcon /></IconButton>
        </Stack>
        <Box sx={{ width: '100%', flexGrow: 1, cursor: 'pointer' }} onClick={() => { setSelectedDocument(documents[documentIndex]) }}>{getPreview(documents[documentIndex])}</Box>
      </Stack>
    </>
  )
}

export default DocumentPreview
