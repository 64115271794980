import React from 'react'
import { Link } from 'react-router-dom'
import ImageLogoDefault from '../../images/LogoSmartpm.PNG'
import { getBackendUrl } from './Api_Functions'

const logoStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '210px',
  height: '84px'
}

const Logo = () => {
  return (
    <div>
      <Link to='/home'>
        <div style={logoStyle}>
          <img src={getBackendUrl() + 'logo'} width='200px' height='50px' alt='Logo' style={{ objectFit: 'contain', justifyContent: 'flex-start' }} onError={(e) => { e.target.src = ImageLogoDefault }} />
        </div>
      </Link>
    </div>
  )
}
export default Logo
