import { Button } from "@mui/material";

import {
  CheckCircleOutlined, Close,
  Done
} from '@mui/icons-material';

import PropTypes from "prop-types";

/**
 * 
 * @param {Object} props 
 * @param {Boolean} ok Indicates if the status is okay. This will make the component green and you won't be able to click on it
 * @param {Boolean} force Indicates if the user forced this component. Only used if okay is false. If force is true the component will be shown as warning. If false the component will show as an error in red
 * @param {Function} onForceChange Function called when the component is clicked. The new force value will be passed as parameter. If okay is true this method wont work as the component is disabled
 * @param {Boolean} disabled Disables force button
 * @returns 
 */
const ChecklistStatusButton = ({ ok, force = false, onForceChange = () => { }, disabled = false }) => {
  if (ok) {
    return <Done color="primary" sx={{ margin: "0.3rem" }} />
  }
  return (<Button disabled={disabled} sx={{ minWidth: 0 }} onClick={(event) => {
    onForceChange(!force);
    event.stopPropagation();
  }}>{force ? <CheckCircleOutlined sx={{ color: "#FFE033" }} /> : <Close color="error" />}</Button>);
};

ChecklistStatusButton.propTypes = {
  ok: PropTypes.bool.isRequired,
  force: PropTypes.bool,
  onForceChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default ChecklistStatusButton;