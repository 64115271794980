import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { useLocation, useNavigate } from 'react-router-dom'
import { Alert, Box, Collapse, Grid, MenuItem, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { CompassButton } from '../Common/CompassButton'
import { apiGet, apiPost } from '../../generic/Api_Functions'
import { Close } from '@mui/icons-material'
import CompassWorkerSelector from '../Common/CompassButton/CompassWorkerSelector'
import NumericKeyBoard from '../Common/Keyboard/NumericKeyBoard'
import CompassWindow from '../Common/CompassWindow/CompassWindow'
import CompassFabricationOrderSelector from '../Common/CompassFabricationOrderSelector/CompassFabricationOrderSelector'

export default function ProductionReportQuantity () {
  const location = useLocation()
  const { row, device, urlBack, logoutUser } = location.state
  const navigate = useNavigate()
  const [workerSelected, setWorkerSelected] = useState(null)
  const [fabricationOrder, setFabricationOrder] = useState(null)
  const [message, setMessage] = useState('')
  const [open, setOpen] = useState(false)
  const [qualityTypes, setQualityTypes] = useState()
  const [reports, setReports] = useState([])
  const [quantities, setQuantities] = useState([])
  const [errorMessage, setErrorMessage] = useState({ show: false })
  const [openMoreFabricationOrders, setMoreFabricationOrders] = useState(false)
  const [rowFabricationOrderInfo, setRowFabricationOrderInfo] = useState([])

  const goBack = () => {
    navigate(urlBack, { state: { device } })
  }
  const getQualityTypes = async () => {
    const response = await apiGet('qualityTypesAll')
    setQualityTypes(response)
  }
  useEffect(() => {
    if (device.currentFabricationOrders.length === 1) {
      setFabricationOrder(device.currentFabricationOrders[0])
    }
  }, [device])

  useEffect(() => {
    const loadQuantities = async () => {
      const data = await apiGet('compass/fabricationOrderReportQuantity/' + device.id + '/' + fabricationOrder.id + '/' + fabricationOrder.jobReferenceId)
      setQuantities(data)
    }
    if (fabricationOrder && fabricationOrder.id && fabricationOrder.jobReferenceId) {
      loadQuantities()
    }
  }, [fabricationOrder, device])

  const formLabelStyle = {
    fontSize: '1.5rem'
  }
  useEffect(() => {
    const getFabricationOrderInfo = async (row) => {
      const response = await apiGet('compass/deviceFabricationOrderStatus/' + row.device.id + '/' + row.fabricationOrder.id + '/' + row.jobReference.id + '/' + row.status + '')
      setRowFabricationOrderInfo([response])
      setFabricationOrder(response)
    }
    if (row !== null && row !== undefined) {
      getFabricationOrderInfo(row)
    }
  }, [row])
  useEffect(() => {
    getQualityTypes()
  }, [])

  useEffect(() => {
    if (qualityTypes) {
      const ok = qualityTypes.find((item) => item.code === 'Ok')
      setReports([{ comment: null, qualityType: ok.id, quantity: 0 }])
    }
  }, [qualityTypes])

  const { t_ } = useSmartTranslation()

  const onChange = (newValue) => {
    let rows
    if (rowFabricationOrderInfo.length !== 0) {
      rows = device.currentFabricationOrders.concat(rowFabricationOrderInfo)
    } else {
      rows = device.currentFabricationOrders
    }
    const row = rows.find((row) => row.id === newValue?.id && row.jobReferenceId === newValue?.jobReferenceId)
    setFabricationOrder(row)
  }
  const save = async () => {
    if (workerSelected === null) {
      setMessage(t_('No worker selected for the required action'))
      setOpen(true)
      return
    }

    reports.forEach((report) => {
      report.quantity = parseInt(report.quantity)
    })

    try {
      const url = 'compass/fabricationOrderReportQuantity/' + workerSelected.id + '/' + device.id + '/' + fabricationOrder.id + '/' + fabricationOrder.jobReferenceId
      await apiPost(url, reports).then(() => { logoutUser ? logoutUserFromDevice() : goBack() })
    } catch (error) {
      let response = ''
      try {
        response = JSON.parse(error.message)
      } catch (e) {
        response = error.message
      }
      setMessage(response)
      setOpen(true)
    }
  }
  const logoutUserFromDevice = async () => {
    await apiPost('compass/deviceWorker/register', {
      workerCodeList: [workerSelected.code],
      deviceList: [device.id],
      login: false
    })
    goBack()
  }

  const getQuantity = (qualityTypeId, newQuantity) => {
    let totalQuantity = 0
    const row = quantities.find((item) => item.qualityTypeId === qualityTypeId)
    if (row) {
      totalQuantity = row.quantity
    }

    let parsedNewQuantity = parseInt(newQuantity)
    if (!parsedNewQuantity || parsedNewQuantity < 0) {
      parsedNewQuantity = 0
    }
    return totalQuantity + parsedNewQuantity
  }

  return (
    <CompassWindow
      message={errorMessage}
      onCloseMessage={() => setErrorMessage({ ...errorMessage, open: false })}
      onClose={goBack}
      renderTitle={<Typography color='primary' sx={{ textAlign: '', padding: '5px 25px ' }}>{t_('Report quantity')}</Typography>}
      render={
        <>
          <Box>
            <Collapse in={open}>
              <Alert
                variant='filled' severity='error' style={{ color: 'white' }}
                action={
                  <IconButton aria-label='close' color='inherit' size='small' onClick={() => { setOpen(false) }}>
                    <Close fontSize='inherit' />
                  </IconButton>
              }
                sx={{ mb: 2 }}
              >
                {message}
              </Alert>
            </Collapse>
          </Box>
          <Typography color='primary' sx={{ textAlign: '' }}> {t_('Asset')} : {device.name}</Typography>
          <Stack spacing={2} gap={2} justifyContent='left' alignItems='left'>
            <CompassWorkerSelector workers={device?.workers} selectedWorker={workerSelected} onChange={(worker) => setWorkerSelected(worker)} />
          </Stack>

          <Box sx={{ width: '100%', marginTop: '25px', marginLeft: '6px' }}>
            <Stack spacing={2}>
              <Grid container gap={2} justifyContent='left' alignItems='left'>
                <Grid item xs={12} style={{ marginBottom: '6px' }}>
                  <Typography color='primary' sx={{ textAlign: '' }}>{t_('Fabrication orders')}:</Typography>
                </Grid>
                <CompassFabricationOrderSelector
                  open={openMoreFabricationOrders}
                  setOpen={setMoreFabricationOrders}
                  fabricationOrder={fabricationOrder}
                  currentFabricationOrders={device.currentFabricationOrders}
                  moreFabricationOrders={rowFabricationOrderInfo}
                  onChange={(fo) => { onChange(fo) }}
                />
              </Grid>
            </Stack>
          </Box>

          <Table sx={{ backgroundColor: (theme) => theme.palette.background.default, marginTop: '3rem', marginBottom: '3rem' }}>
            <TableHead>
              <TableRow>
                <TableCell>{t_('Quality type')}</TableCell>
                <TableCell>{t_('Quantity')}</TableCell>
                <TableCell>{t_('Total')}</TableCell>
                <TableCell> <CompassButton color='primary' label='Add +' onClick={() => setReports([...reports, { comment: null, qualityType: '', quantity: 0 }])} /></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((report, index) => {
                return (
                  <TableRow key={'quality_type' + index}>
                    <TableCell>
                      <Select
                        sx={{ width: '20rem' }}
                        value={report.qualityType}
                        disabled={index === 0}
                        onChange={(event) => {
                          const newReports = [...reports]
                          newReports[index].qualityType = event.target.value
                          setReports(newReports)
                        }}
                      >
                        {qualityTypes?.filter((item) => index === 0 || item.code !== 'Ok').map((qt) => { return <MenuItem key={qt.id} value={qt.id}>{qt.name}</MenuItem> })}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Stack direction='row'>
                        <TextField
                          value={report.quantity} onChange={(event) => {
                            const newReports = [...reports]
                            newReports[index].quantity = event.target.value
                            setReports(newReports)
                          }}
                        />
                        <NumericKeyBoard
                          value={report.quantity} onChange={(newValue) => {
                            const newReports = [...reports]
                            newReports[index].quantity = newValue
                            setReports(newReports)
                          }}
                        />
                      </Stack>

                    </TableCell>
                    <TableCell>
                      {getQuantity(report.qualityType, report.quantity)}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ height: '6rem', marginTop: '20px' }}>
            <Grid item xs={6} style={{ paddingLeft: '0' }}>
              <CompassButton label={t_('ACCEPT')} labelStyle={formLabelStyle} onClick={save} color='primary' />
            </Grid>
            <Grid item xs={6}>
              <CompassButton label={t_('CANCEL')} labelStyle={formLabelStyle} onClick={goBack} color='secondary' />
            </Grid>
          </Grid>
        </>
}
    />
  )
};
