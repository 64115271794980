import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const DeviceStatus = () => {
  const { t_ } = useSmartTranslation()

  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      unique: true,
      mandatory: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Name'),
      field: 'name',
      unique: true,
      mandatory: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },

    {
      name: t_('Status type'),
      field: 'statusType.name',
      type: 'smartSelect',
      edit: {
        source: async () => {
          return await apiGet('statusTypes')
        },
        id: 'id',
        field: 'name'
      },
      readOnly: (row) => {
        return row.systemDefault
      },
      mandatory: true
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    }
  ]

  const actions = {
    create: {
      fetchFunction: (row) => {
        return apiPost('status', row)
      }
    },
    edit: {
      fetchFunction: (row) => {
        return apiPut('status', row)
      }
    },
    delete: {
      visibilityButton: row => !row.systemDefault,
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('status/' + rows[0].id)
        } else {
          return apiDelete('status/deleteMulti/', rows)
        }
      }
    }
  }

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Device Status')}
      dataFetch='status'
      actions={actions}
      pageable
      multipleSelection
    />
  )
}

export default DeviceStatus
