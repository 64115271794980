import React, { useState } from 'react'
import { PendingActionsRounded, PersonRounded, KeyboardArrowUpRounded } from '@mui/icons-material'
import { Badge, ClickAwayListener, Divider, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Typography, useMediaQuery, useTheme } from '@mui/material'
import About from '../about/About'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import { useUncheckedActions } from '../../generic/hooks/useUncheckedActions'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { Link } from 'react-router-dom'
import { useUser } from '../../generic/hooks/useUser'
import actionBadgeStyle from '../../maintenance/actions/ActionBadgeStyle.js'
import { useAuthorization } from '../../generic/hooks/useAuthorization'

const dividerStyle = {
  backgroundColor: '#818181 !important'
}
const userStyle = {
  paddingLeft: 15,
  color: '#05fd92 !important'
}
const userConfigStyle = {
  paddingTop: 13
}

const TopBarUser = (props) => {
  const { open, setOpen, openSettings, setOpenSettings, setOpenLanguages, handleListKeyDown, handleLogout } = props
  const uncheckedActions = useUncheckedActions()
  const anchorRef = React.useRef(null)
  const { user } = useUser()
  const [aboutOpen, setAboutOpen] = useState(false)

  const theme = useTheme()
  const isDownXLarge = useMediaQuery(theme.breakpoints.down('xl'))

  const { t_ } = useSmartTranslation()
  const isUserAuthorized = useAuthorization()

  const handleCloseConfigUser = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
    setOpenSettings(false)
    setOpenLanguages(false)
  }

  return (
    <>
      <SmartDialog
        setOpen={aboutOpen}
        renderComponent={<About />}
        accept={false}
        cancel={false}
        close
        closeCallback={() => setAboutOpen(false)}
      />
      <IconButton
        component={Link}
        to={uncheckedActions > 0 ? '/compass/process/action' : '/compass/process'}
        disabled={!isUserAuthorized('COMPASS PROCESS')}
      >
        <Badge badgeContent={uncheckedActions} sx={actionBadgeStyle}>
          <PendingActionsRounded />
        </Badge>
      </IconButton>
      {
        !isDownXLarge
          ? (
            <div style={userStyle}>
              <Typography sx={{ fontSize: 18 }}>{user && user.username ? user.username : null}</Typography>
              <Typography sx={{ fontSize: 18 }}>
                {user && user.profile && user.profile.name ? user.profile.name : null}
              </Typography>
            </div>
            )

          : null
      }

      <div>
        {!isDownXLarge
          ? (
            <IconButton
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup='true'
              onClick={handleToggle}
            >
              <KeyboardArrowUpRounded />
            </IconButton>
            )
          : (
            <IconButton
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup='true'
              onClick={handleToggle}
            >
              <PersonRounded />
            </IconButton>
            )}

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement='bottom-end'
        >
          {({ TransitionProps, placement }) => (
            <div style={userConfigStyle}>
              <Grow
                {...TransitionProps}
                sx={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseConfigUser}>
                    <MenuList
                      id='menu-list-grow'
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuList
                        autoFocusItem={openSettings}
                        id='menu-list-grow'
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={handleCloseConfigUser}>
                          {t_('My account')}
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleCloseConfigUser}>
                          {t_('Theme')}
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleLogout}>
                          {t_('Logout')}
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={(event) => {
                          setAboutOpen(true)
                        }}
                        >
                          {t_('About')}
                        </MenuItem>
                      </MenuList>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            </div>
          )}
        </Popper>
      </div>

      {
        isDownXLarge
          ? <Divider
              sx={dividerStyle}
              orientation='vertical'
              flexItem
            />
          : null
      }
    </>
  )
}

export default TopBarUser
