
import { Box } from "@mui/material";
import React from "react";
import { apiDelete, apiPut } from "./Api_Functions";
import AddElement from "./smartAddElement/AddElement";
import SmartMateriaUITable from "./SmartMateriaUITable";







const SmartMaterialUITableTest = () => {
  const columns = [
    {
      name: "Code",
      field: "code",
      readOnly: true,
      unique: true
    },
    {
      name: "name",
      field: "name",
      readOnly: true,
      unique: true
    },
    {
      name: "description",
      field: "description",

    },
    {
      name: "currentLife",
      field: "currentLife",
    },
    {
      name: "location",
      field: "location",
    },


  ];


  const actions = {

    edit: {
      fetchFunction: (row) => {
        return apiPut("tools", row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete("tool/" + rows[0].id, rows)
        }
        else {
          return apiDelete("tools", rows)
        }
      },
    },
  }

  const defaultToolbar = {
    render: {
      fetchFunction: () => {
        return (
          <Box component="span" sx={{ flexGrow: 1 }} style={{ textAlign: "end" }} >

            <AddElement
              parameter={1}
              endpoint={"tools"}
              title={"Add record"}
            /></Box>)

      }
    },
  }



  return (
    <div>
      <SmartMateriaUITable
        columns={columns}
        title={"Tools instance"}
        dataFetch={"tools"}
        actions={actions}
        multipleSelection
        dense={true}
        toolbar={defaultToolbar}
        disableFlexGrow
      />
    </div>
  );
};

export default SmartMaterialUITableTest;
