import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { apiGet, apiPost } from './../generic/Api_Functions'
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, ToggleButton, ToggleButtonGroup, alpha } from '@mui/material'
import { Chart as ChartJS, Tooltip, Legend, LinearScale, CategoryScale, BarElement } from 'chart.js'
import SmartSelect from '../generic/smartSelect/SmartSelect'
import { useTheme } from '@emotion/react'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import { DatePicker } from '@mui/x-date-pickers'
import { useLocalizationContext } from '@mui/x-date-pickers/internals'
import { Bar } from 'react-chartjs-2'
import Annotation from 'chartjs-plugin-annotation'
import { draw } from './patterns/pattern'

const DeviceAvailabilityChart = () => {
  const addDaysToDate = (date, days) => {
    const newDate = new Date(date.getTime())
    newDate.setDate(newDate.getDate() + days)
    return newDate
  }

  const onDispatchDates = (state, action) => {
    const newState = { ...state }
    if (action.type === 'set_start_date') {
      newState.startDate = action.value
      // Check if is at least a day
      if (addDaysToDate(newState.startDate, 1) > newState.endDate) {
        newState.endDate = addDaysToDate(newState.startDate, 1)
      }
    }
    if (action.type === 'set_end_date') {
      newState.endDate = action.value
      // Check if is at least a day
      if (addDaysToDate(newState.startDate, 1) > newState.endDate) {
        newState.startDate = addDaysToDate(newState.endDate, -1)
      }
    }
    if (action.type === 'set_time_group') {
      newState.timeGroup = action.value
      // Set appropiate range depending on group
      if (newState.timeGroup === 'DAY') {
        newState.endDate = addDaysToDate(newState.startDate, 6)
        // Only hour mode allowed
        newState.yAxis = 'HOUR'
      } else if (newState.timeGroup === 'WEEK') {
        newState.endDate = addDaysToDate(newState.startDate, 28)
        // Only hour and day mode allowed
        if (newState.yAxis !== 'HOUR' && newState.yAxis !== 'DAY') {
          newState.yAxis = 'DAY'
        }
      } else if (newState.timeGroup === 'MONTH') {
        newState.endDate = addDaysToDate(newState.startDate, 365)
      } else if (newState.timeGroup === 'YEAR') {
        newState.endDate = addDaysToDate(newState.startDate, 365 * 4)
      }
    }
    if (action.type === 'set_y_axis') {
      newState.yAxis = action.value
    }
    if (action.type === 'set_include_farbication_orders') {
      newState.includeFabricationOrders = action.value
    }
    return newState
  }

  const [availabilities, setAvailabilities] = useState(null)
  const [devices, setDevices] = useState([])
  const [selectedDevice, setSelectedDevice] = useState(null)
  const theme = useTheme()

  const [dates, dispatchDates] = useReducer(onDispatchDates, { startDate: new Date(), endDate: addDaysToDate(new Date(), 6), timeGroup: 'DAY', yAxis: 'HOUR', includeFabricationOrders: true })

  const { t_ } = useSmartTranslation()

  ChartJS.register(
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    BarElement,
    Annotation
  )

  useEffect(() => {
    const loadDevices = async () => {
      setDevices(await apiGet('devicesBasicData'))
    }
    loadDevices()
  }, [])

  useEffect(() => {
    const loadAvailability = async () => {
      const data = await apiPost('availability/device/' + selectedDevice.id, dates)
      setAvailabilities(data)
    }
    if (!selectedDevice) {
      return
    }
    loadAvailability()
  }, [dates, selectedDevice])

  const adjustMsToCurrentTime = useCallback((ms) => {
    if (dates.yAxis === 'DAY') {
      ms = ms / 24
    } else if (dates.yAxis === 'WEEK') {
      ms = ms / 24 / 7
    }
    return ms / 3600000
  }, [dates.yAxis])

  // Set first device by default
  if (!selectedDevice && devices.length > 0) {
    setSelectedDevice(devices[0])
  }

  const textColor = theme.palette.text.primary

  const getNowAnnotation = () => {
    if (!availabilities) {
      return
    }
    const now = new Date()
    const foundItemIndex = availabilities.findIndex((item) => {
      const startDate = new Date(item.startDate)
      const endDate = new Date(item.endDate)
      return now <= endDate && now >= startDate
    })
    if (foundItemIndex < 0) {
      return
    }

    const startDate = new Date(availabilities[foundItemIndex].startDate)
    const hours = adjustMsToCurrentTime(now.getTime() - startDate.getTime())

    return {
      type: 'line',
      borderColor: theme.palette.primary.dark,
      borderWidth: 3,
      label: {
        display: true,
        backgroundColor: theme.palette.primary.dark,
        borderRadius: 5,
        color: textColor,
        content: t_('Now')
      },
      xMax: foundItemIndex - (dates.includeFabricationOrders ? 0.45 : 0.35),
      xMin: foundItemIndex + (dates.includeFabricationOrders ? 0.45 : 0.35),
      xScaleID: 'x',
      yMax: hours,
      yMin: hours,
      yScaleID: 'y'
    }
  }

  const nowAnnotation = getNowAnnotation()

  const generalAvailabilityAnnotations = useMemo(() => {
    if (!availabilities) {
      return []
    }
    let index = -1
    return availabilities.flatMap((availability) => {
      index++
      return [
        {
          id: availability.startDate + '_available',
          type: 'box',
          xMin: index - 0.45,
          xMax: index + 0.45,
          yMin: 0,
          yMax: adjustMsToCurrentTime(availability.availableMs),
          backgroundColor: theme.palette.info.main,
          drawTime: 'beforeDatasetsDraw'
        },
        {
          id: availability.startDate + '_not_available',
          type: 'box',
          xMin: index - 0.45,
          xMax: index + 0.45,
          yMin: adjustMsToCurrentTime(availability.availableMs),
          yMax: adjustMsToCurrentTime(availability.totalTimeMs),
          backgroundColor: theme.palette.error.main,
          drawTime: 'beforeDatasetsDraw'
        }
      ]
    })
  }, [availabilities, adjustMsToCurrentTime, theme])

  const allAnnotations = { nowAnnotation }

  if (dates.includeFabricationOrders) {
    generalAvailabilityAnnotations.forEach(element => {
      allAnnotations['annotation_availability_' + element.id] = element
    })
  }

  const options = !availabilities || availabilities.length === 0
    ? null
    : {
        responsive: true,
        plugins: { legend: { display: false }, annotation: { annotations: allAnnotations } },
        scales: {
          x: {
            stacked: true,
            ticks: { color: textColor },
            grid: { color: textColor }
          },
          y: {
            stacked: true,
            ticks: {
              callback: (value, index, ticks) => {
                return value
              },
              color: textColor
            },
            grid: { color: textColor }
          }
        }
      }

  const localizationContext = useLocalizationContext()

  const labels = useMemo(() => {
    if (!availabilities) {
      return []
    }
    return availabilities.map((item) => {
      const startDate = new Date(item.startDate)
      switch (dates.timeGroup) {
        case 'DAY':
          return localizationContext.utils.format(startDate, 'normalDateWithWeekday')
        case 'YEAR':
          return localizationContext.utils.format(startDate, 'year')
        case 'MONTH':
          return localizationContext.utils.format(startDate, 'month')
        case 'WEEK':
          return localizationContext.utils.format(startDate, 'normalDateWithWeekday') + ' - ' + localizationContext.utils.format(new Date(item.endDate), 'normalDateWithWeekday')
        default:
          return item.startDate
      }
    })
  }, [availabilities, dates.timeGroup, localizationContext.utils])

  const data = useMemo(() => {
    if (!availabilities) {
      return { labels: [], datasets: [] }
    }

    if (!dates.includeFabricationOrders) {
      const calculatedTotalAvilability = availabilities.map((item) => adjustMsToCurrentTime(item.totalTimeMs - item.availableMs))
      return {
        labels,
        datasets: [{
          label: t_('Available'),
          data: availabilities.map((item) => adjustMsToCurrentTime(item.availableMs)),
          backgroundColor: theme.palette.info.main,
          stack: 'Stack 0'
        },
        {
          label: t_('Not available'),
          data: calculatedTotalAvilability,
          backgroundColor: theme.palette.error.main,
          stack: 'Stack 0'
        }
        ]
      }
    }

    let fabricationOrders = {}

    availabilities.forEach(availability => {
      for (const [key, value] of Object.entries(availability.fabricationOrders)) {
        if (!fabricationOrders[key]) {
          fabricationOrders[key] = value
        }
      }
    })

    // Order by delivery date
    fabricationOrders = Object.entries(fabricationOrders).sort((entry1, entry2) => {
      return new Date(entry1[1].endDate).getTime() > new Date(entry2[1].endDate).getTime() ? 1 : -1
    })

    let datasets = fabricationOrders.map((entry) => {
      const fo = entry[1]
      return {
        label: fo.fabricationOrder + '(' + localizationContext.utils.format(new Date(fo.endDate), 'normalDateWithWeekday') + ')',
        data: availabilities.map((item) => item.fabricationOrders[fo.id] ? adjustMsToCurrentTime(item.fabricationOrders[fo.id].done) : 0),
        backgroundColor: fo.color,
        borderColor: theme.palette.text.primary,
        borderWidth: 2,
        stack: 'Stack0'
      }
    })

    datasets = datasets.concat(
      fabricationOrders.map((entry) => {
        const fo = entry[1]
        return {
          label: fo.fabricationOrder + '(' + localizationContext.utils.format(new Date(fo.endDate), 'normalDateWithWeekday') + ')',
          data: availabilities.map((item) => item.fabricationOrders[fo.id] ? adjustMsToCurrentTime(item.fabricationOrders[fo.id].pending) : 0),
          backgroundColor: draw(fo.color, alpha(theme.palette.text.primary, 0.8)),
          borderColor: theme.palette.text.primary,
          borderWidth: 2,
          stack: 'Stack0'
        }
      })
    )

    return {
      labels,
      datasets
    }
  }, [availabilities, dates.includeFabricationOrders, adjustMsToCurrentTime, theme.palette, t_, labels, localizationContext])

  const getYaxisSelector = () => {
    return (
      <FormControl>
        <InputLabel>{t_('Y axis')}</InputLabel>
        <Select
          value={dates.yAxis}
          label={t_('Y axis')}
          disabled={dates.timeGroup === 'DAY'}
          onChange={(event) => dispatchDates({ type: 'set_y_axis', value: event.target.value })}
        >
          <MenuItem value='HOUR'>{t_('Hours')}</MenuItem>
          {dates.timeGroup !== 'DAY' ? <MenuItem value='DAY'>{t_('Days')}</MenuItem> : null}
          {dates.timeGroup !== 'DAY' && dates.timeGroup !== 'WEEK' ? <MenuItem value='WEEK'>{t_('Weeks')}</MenuItem> : null}
        </Select>
      </FormControl>
    )
  }

  const getTimeRangeSelector = () => {
    const views = dates.timeGroup === 'YEAR' ? ['year'] : dates.timeGroup === 'MONTH' ? ['year', 'month'] : ['year', 'month', 'day']

    return (
      <>
        <DatePicker
          label={t_('Start')}
          views={views}
          onChange={(newDate) => {
            dispatchDates({ type: 'set_start_date', value: newDate })
          }}
          value={dates.startDate}
        />
        <DatePicker
          label={t_('End')}
          views={views}
          onChange={(newDate) => {
            dispatchDates({ type: 'set_end_date', value: newDate })
          }}
          value={dates.endDate}
        />
      </>
    )
  }

  return (
    <Stack gap={3}>
      <Stack direction='row' alignItems='flex-end' justifyContent='space-between'>
        {getYaxisSelector()}
        <Stack direction='row' gap={3}>

          <FormControlLabel control={<Checkbox checked={dates.includeFabricationOrders} onChange={(event) => dispatchDates({ type: 'set_include_farbication_orders', value: event.target.checked })} />} label={t_('Show fabrication orders')} />

          <SmartSelect
            label={t_('Asset')}
            selectableOptions={devices.map((item) => item.name)}
            value={selectedDevice?.name}
            onChange={(item) => setSelectedDevice(devices.find((device) => device.name === item))}
            minWidth='15rem'
          />

          <ToggleButtonGroup exclusive value={dates.timeGroup} onChange={(event) => dispatchDates({ type: 'set_time_group', value: event.target.value })} size='small'>
            <ToggleButton value='DAY'>{t_('Day')}</ToggleButton>
            <ToggleButton value='WEEK'>{t_('Week')}</ToggleButton>
            <ToggleButton value='MONTH'>{t_('Month')}</ToggleButton>
            <ToggleButton value='YEAR'>{t_('Year')}</ToggleButton>
          </ToggleButtonGroup>

          {getTimeRangeSelector()}
        </Stack>
      </Stack>
      <Bar options={options} data={data} />
    </Stack>
  )
}

export default DeviceAvailabilityChart
