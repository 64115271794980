import React from 'react'
import SmartSortableContainer from '../../generic/smartSortable/SmartSortableContainer'
import DefaultSortableRenderItem from '../../generic/smartSortable/GenericSortableRenderItem'

const ToolTypeToolParameterSorting = ({ toolTypeParameters, sortingChange }) => {
  const ToolTypeParameterItem = ({ item, index }) => {
    return <DefaultSortableRenderItem labels={[item.parameterCode, item.parameterName]} />
  }

  const onSortChanged = (items) => {
    if (sortingChange && typeof sortingChange === 'function') {
      sortingChange(items)
    }
  }
  return (
    <div style={{ width: '100%', margin: 'auto' }}>
      <SmartSortableContainer itemList={toolTypeParameters} itemId='parameterCode' renderItem={ToolTypeParameterItem} onSortChanged={onSortChanged} />
    </div>
  )
}

export default ToolTypeToolParameterSorting
