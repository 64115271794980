import React from "react";
import SmartNavigator from "../generic/SmartNavigator";
import SensorsActuators from "../configuration/Sensor&Actuators/SensorsActuators";
import SensorsActuatorsComponents from "../configuration/Sensor&Actuators/SensorsActuatorsComponents";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";

export default function MainConfigurationSensorsActuators(props) {
  const activePath = props.activePath;
  const { t_ } = useSmartTranslation();
  const componentsInfo = [
    {
      title: t_("SENSORS & ACTUATORS"),
      component: < SensorsActuators />,
      to: "/configuration/sensorsActuators",
    },
    {
      title: t_("SENSORS & ACTUATORS COMPONENTS"),
      component: < SensorsActuatorsComponents />,
      to: "/configuration/sensorsActuatorsComponents",
    },

  ];

  return <SmartNavigator componentsInfo={componentsInfo} selected={activePath} />;
}