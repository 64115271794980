import { DefaultAccordion } from '../../generic/DefaultAccordion'
import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { useEffect, useState } from 'react'
import { useSmartInterval } from '../../generic/hooks/useSmartInterval'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import SmartGroupCreator from '../../generic/smartSortable/SmartGroupCreator'
import { TextSnippetOutlined } from '@mui/icons-material'
import Document from '../document/Document'

const Features = (props) => {
  const { partReference } = props
  const quantitative = 'Quantitative'
  const qualitative = 'Qualitative'
  const { t_ } = useSmartTranslation()
  const [newValueTyping, setNewValueTyping] = useState({ code: true, name: true })
  const [openFeatureGroupsDialog, setOpenFeatureGroupsDialog] = useState(false)
  const [initialGroups, setInitialGroups] = useState(null)
  const [userFeatureGroups, setUserFeatureGroups] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [featureTypes, setFeatureTypes] = useState([])
  const [selectedRow, setSelectedRow] = useState({})
  const [showDocument, setShowDocument] = useState(false)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    const loadFeatureTypes = async () => {
      setFeatureTypes(await apiGet('featureTypes'))
    }
    loadFeatureTypes()
  })

  const condition = (value, field) => {
    let errorMsg = ''

    if (userFeatureGroups !== null) {
      if ((value === '' || value === null) && !newValueTyping[field]) {
        return t_('Required *')
      }

      if (value !== '' && newValueTyping[field]) {
        const copy = newValueTyping
        copy[field] = false
        setNewValueTyping(copy)
      }

      const x = userFeatureGroups.ungrouped.filter(feature => feature[field] === value)
      if (x.length !== 0) {
        errorMsg = t_('Key duplicated')
      }

      userFeatureGroups.grouped.forEach(featureGroup => {
        const y = featureGroup.features.filter(feature => feature[field] === value)
        if (y.length !== 0) {
          errorMsg = t_('Key duplicated')
        }
      })
    }

    return errorMsg
  }

  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      readOnly: true,
      charLimit: 60,
      mandatory: true,
      unique: true,
      condition: (rowData) => {
        return condition(rowData.code, 'code')
      }
    },
    {
      name: t_('Name'),
      field: 'name',
      charLimit: 80,
      mandatory: true,
      unique: true
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    },
    {
      name: t_('Feature type'),
      field: 'featureType.name',
      type: 'smartSelect',
      defaultNewValue: featureTypes.find((item) => item.code === 'QUANTITATIVE'),
      edit: {
        source: async () => {
          return featureTypes
        },
        id: 'id',
        field: 'name'
      },
      mandatory: true
    },
    {
      name: t_('Qualitative attribute'),
      field: 'qualitativeAttribute',
      readOnly: (rowData) => {
        return readOnlyCondition(rowData, qualitative)
      },
      mandatory: (rowData) => {
        return mandatoryCondition(rowData, qualitative)
      },
      reset: (rowData) => {
        return readOnlyCondition(rowData, qualitative)
      }
    },
    {
      name: t_('Nominal'),
      field: 'value',
      readOnly: (rowData) => {
        return readOnlyCondition(rowData, quantitative)
      },
      mandatory: (rowData) => {
        return mandatoryCondition(rowData, quantitative)
      },
      reset: (rowData) => {
        return readOnlyCondition(rowData, quantitative)
      }
    },
    {
      name: t_('Upper tolerance'),
      field: 'upperTolerance',
      readOnly: (rowData) => {
        return readOnlyCondition(rowData, quantitative)
      },
      mandatory: (rowData) => {
        return mandatoryCondition(rowData, quantitative)
      },
      reset: (rowData) => {
        return readOnlyCondition(rowData, quantitative)
      }
    },
    {
      name: t_('Lower tolerance'),
      field: 'lowerTolerance',
      readOnly: (rowData) => {
        return readOnlyCondition(rowData, quantitative)
      },
      mandatory: (rowData) => {
        return mandatoryCondition(rowData, quantitative)
      },
      reset: (rowData) => {
        return readOnlyCondition(rowData, quantitative)
      }
    },
    {
      name: t_('Unit'),
      field: 'unit.code',
      type: 'select',
      edit: {
        source: async () => {
          return await apiGet('units')
        },
        id: 'id',
        field: 'code'
      },
      readOnly: (rowData) => {
        return readOnlyCondition(rowData, quantitative)
      },
      mandatory: (rowData) => {
        return mandatoryCondition(rowData, quantitative)
      },
      reset: (rowData) => {
        return readOnlyCondition(rowData, quantitative)
      }
    },
    {
      name: t_('Frequency'),
      field: 'frequency'
    }
  ]
  const readOnlyCondition = (row, property) => {
    if (
      row.featureType.name !== property ||
      row.featureType.name === '' ||
      row.featureType.name === undefined
    ) { return true }
    return false
  }
  const mandatoryCondition = (row, property) => {
    if (row.featureType.name === property) return true
    return false
  }
  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('feature/' + partReference.id, row)
      }
    },
    create: {
      fetchFunction: (row) => {
        return apiPost('feature/' + partReference.id, row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('feature/' + rows[0].id, rows)
        } else {
          return apiDelete('feature/deleteMulti/' + partReference.id, rows)
        }
      }
    },
    custom: [
      {
        name: 'Documents',
        render: (row) => {
          return (
            <IconButton
              disabled={editing}
              onClick={() => {
                setSelectedRow(row)
                setShowDocument(true)
              }}
            >
              <TextSnippetOutlined />
            </IconButton>
          )
        }
      }
    ]
  }

  const addPositiveSymbol = (value) => {
    return value > 0 ? '+' + value : value
  }
  const validator = (row) => {
    const upperTolerance = parseFloat(row.upperTolerance)
    const lowerTolerance = parseFloat(row.lowerTolerance)

    if (upperTolerance <= lowerTolerance) {
      return { errorFields: ['upperTolerance'], alertMessage: 'Upper tolerance must be higher than lower tolerance' }
    } else {
      return false
    }
  }

  const fetchData = async () => {
    let groupList = await apiGet('featureGroups/' + partReference.id)
    let featureList = await apiGet('feature/noPagination/' + partReference.id)
    groupList = groupList.map((element) => ({
      ...element,
      features: element.features.map((feature) => ({
        ...feature,
        upperTolerance: addPositiveSymbol(feature.upperTolerance),
        lowerTolerance: addPositiveSymbol(feature.lowerTolerance)
      }))
    }))
    featureList = featureList.map((element) => ({
      ...element,
      upperTolerance: addPositiveSymbol(element.upperTolerance),
      lowerTolerance: addPositiveSymbol(element.lowerTolerance)
    }))
    return {
      grouped: groupList,
      ungrouped: featureList
    }
  }

  const keys = {
    itemId: 'id',
    itemText: 'name',
    groupId: 'id',
    groupText: 'name',
    groupItems: 'features'
  }

  const updateInitialGroups = (newGroups) => {
    setInitialGroups(newGroups)
  }

  const setIntervalData = (data) => {
    setUserFeatureGroups(data)
  }

  useSmartInterval(fetchData, 1000, setIntervalData)

  const handleSaveFeatureGroups = async (newGroups) => {
    let hasEnoughFeatures = true
    newGroups.grouped.forEach((group) => {
      if (group.features.length < 2) {
        hasEnoughFeatures = false
        setErrorMessage(t_('Group must have at least 2 features'))
      }
    })
    if (hasEnoughFeatures) {
      const updatedGroupsGrouped = newGroups.grouped.map((group) => ({
        ...group,
        partReferenceId: partReference.id
      }))

      await apiPut('featureGroups/' + partReference.id, updatedGroupsGrouped)
      await apiPut('features', newGroups.ungrouped)

      setOpenFeatureGroupsDialog(false)
      setErrorMessage(null)
      updateInitialGroups(await fetchData())
    }
  }

  const renderGroupedFeatures = (groupedFeatures, partReference, columns) => {
    return groupedFeatures.map((group) => {
      const actionGroup = {
        edit: {
          fetchFunction: (row) => {
            return apiPut('feature/' + partReference.id, row)
          }
        },
        create: {
          fetchFunction: (row) => {
            const createdRow = { ...row, featureGroup: { id: group.id, name: group.name } }
            return apiPost('feature/' + partReference.id, createdRow)
          }
        },
        delete: {
          fetchFunction: (rows) => {
            if (rows.length === 1) {
              return apiDelete('feature/' + rows[0].id)
            } else {
              return apiDelete('feature/deleteMulti/' + partReference.id, rows)
            }
          }
        }
      }

      const headerTitle = (group) => {
        return <Typography>{group.name}</Typography>
      }

      return (
        <DefaultAccordion
          headerRender={headerTitle(group)}
          key={'feature-group-' + group.name}
        >
          <SmartMateriaUITable
            columns={columns}
            dataFetch={group.features}
            pageable={false}
            validator={validator}
            actions={actionGroup}
            onEditIndexChanged={(index) => {
              if (index >= -1) {
                setNewValueTyping({ name: true, code: true })
              }
            }}
          />
        </DefaultAccordion>

      )
    })
  }

  const defaultToolbar = {
    render: {
      fetchFunction: () => {
        return (
          <>
            <Box sx={{ flexGrow: 12 }} />
            <Button color='primary' variant='text' onClick={() => setOpenFeatureGroupsDialog(true)}>{t_('Group')}</Button>
          </>
        )
      }
    }
  }

  return (
    <>
      <SmartDialog
        closeCallback={() => setShowDocument(false)}
        accept={false}
        cancel={false}
        close
        renderComponent={<Document reference={selectedRow} resourceType='FEATURE' />}
        setOpen={showDocument}
      />
      <SmartDialog
        setOpen={openFeatureGroupsDialog}
        close={false}
        accept
        cancel
        cancelCallback={() => { setOpenFeatureGroupsDialog(false); setErrorMessage(null) }}
        acceptCallback={() => handleSaveFeatureGroups(initialGroups)}
        title={{ render: <Typography variant='h5'> {t_('Group')} / {t_('Ungroup')}</Typography> }}
        renderComponent={
          <SmartGroupCreator
            onChange={setInitialGroups}
            grouped={userFeatureGroups?.grouped || []}
            ungrouped={userFeatureGroups?.ungrouped || []}
            keys={keys}
            errorMessage={errorMessage}
          />
        }
      />
      <SmartMateriaUITable
        columns={columns}
        title={t_('Reference') + ': ' + partReference.name}
        dataFetch={userFeatureGroups?.ungrouped || []}
        actions={actions}
        pageable={false}
        multipleSelection
        editingRow={(event) => setEditing(event)}
        toolbar={defaultToolbar}
        validator={validator}
      />
      {userFeatureGroups?.grouped !== null && userFeatureGroups?.grouped?.length > 0
        ? renderGroupedFeatures(userFeatureGroups.grouped, partReference, columns)
        : null}
    </>
  )
}

export default Features
