import { Toolbar, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { useColorsArray } from "../../generic/hooks/useColorsArray";
import { useSmartInterval } from "../../generic/hooks/useSmartInterval";

const CellPartsBarChart = (props) => {
  const { locations } = props
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [],
    backgroundColor: [],
    borderColor: [],
    borderWidth: [],
    hoverBackgroundColor: [],
    hoverBorderColor: []
  });
  const [getColor] = useColorsArray()
  const myLocationsRef = useRef()

  useEffect(() => {
    myLocationsRef.current = locations
  }, [locations])

  const getIntervalData = () => {
    let intervalData = {
      labels: [],
      datasets: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: [],
      hoverBackgroundColor: [],
      hoverBorderColor: []
    }

    let deviceReferences = {};
    myLocationsRef.current.forEach(location => {
      if (location.dateOut === null) {
        if (deviceReferences.hasOwnProperty(location.device.name)) {
          deviceReferences[location.device.name]++
        } else {
          deviceReferences[location.device.name] = 1;
        }
      }
    });

    Object.entries(deviceReferences).forEach(([key, value]) => {
      const myColor = getColor(intervalData.labels.length)
      intervalData.backgroundColor.push(myColor.backgroundColor)
      intervalData.borderColor.push(myColor.borderColor)
      intervalData.borderWidth.push(myColor.borderWidth)
      intervalData.hoverBackgroundColor.push(myColor.hoverBackgroundColor)
      intervalData.hoverBorderColor.push(myColor.hoverBorderColor)
      intervalData.labels.push(key)
      intervalData.datasets.push(value)
    })

    return intervalData
  }

  const setIntervalData = (intervalData) => {
    if (
      barData.datasets === undefined ||
      barData.datasets.length !== intervalData.datasets.length ||
      !barData.datasets.every((e, i) => e === intervalData.datasets[i]) ||
      !barData.labels.every((e, i) => e === intervalData.labels[i])
    ) {
      setBarData(intervalData)
    }
  }

  useSmartInterval(getIntervalData, 1000, setIntervalData)

  const options = {
    scaleFontColor: "red",
    responsive: true,
    tooltips: {
      mode: "single",
    },
    title: {
      display: false,
      text: "CELL PARTS",
      fontColor: "#fff",
      fontSize: 20,
    },
    scales: {
      x: {
        gridLines: {
          display: true,
        },
        ticks: {
          fontColor: "#fff !important", // this here
        },
      },
      y: {
        display: true,
        ticks: {
          stepSize: 1,
          suggestedMax: locations.length,
          fontColor: "#fff !important", // this here
        },
        gridLines: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const titleStyle = {
    flex: "1 1 100%",
  }

  return (
    <div>
      <Toolbar>
        <Typography
          align="center"
          sx={titleStyle}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          CELL PARTS
        </Typography>
      </Toolbar>
      <Bar
        data={{
          labels: barData.labels,
          responsive: true,
          offset: true,
          datasets: [
            {
              label: barData.labels,
              data: barData.datasets,
              backgroundColor: barData.backgroundColor,
              borderColor: barData.borderColor,
              borderWidth: barData.borderWidth,
              hoverBackgroundColor: barData.hoverBackgroundColor,
              hoverBorderColor: barData.hoverBorderColor,
            }
          ]
        }}
        height={220}
        options={{ options }}
      />
    </div>
  );
};

export default CellPartsBarChart;
