import { CircularProgress } from '@mui/material'

import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { apiGet } from '../../generic/Api_Functions'
import StorageConfig from '../../configuration/storage/StorageConfig'
import DeviceConfiguration from './DeviceConfiguration'
import AlertUI from '../../generic/AlertUI'
import { useLocation } from 'react-router'

const DeviceSettings = () => {
  const [device, setDevice] = useState({})
  const [AlertElement, showAlert] = AlertUI()
  const show = useRef(showAlert)
  const { deviceId } = useParams()
  const { state } = useLocation()

  useEffect(() => {
    if (state === 'BACK') {
      show.current({
        title: 'There have been no modifications.',
        severity: 'info'

      })
    } else if (state === 'EDIT') {
      show.current({
        title: 'Created correctly.',
        severity: 'success'
      })
    }
  }, [state])

  useEffect(() => {
    const fetchDevice = async () => {
      const deviceDB = await apiGet('deviceConfiguration/' + deviceId)
      setDevice(deviceDB)
    }
    fetchDevice()
  }, [deviceId])
  const getStorageLayout = () => {
    if (device.deviceType.name === 'STORAGE') {
      return (
        <StorageConfig
          deviceId={deviceId}
          device={device}
          setDevice={setDevice}
        />
      )
    }
  }

  const getTotalLayout = () => {
    if (device.id) {
      return (
        <div>
          {AlertElement}
          <DeviceConfiguration
            deviceId={deviceId}
            device={device}
            setDevice={setDevice}
          />
          {getStorageLayout()}
        </div>
      )
    } else {
      return <CircularProgress />
    }
  }

  return <div>{getTotalLayout()}</div>
}

export default DeviceSettings
