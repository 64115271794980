import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React, { useState } from 'react'

const GenericToggleGroup = (props) => {
  const {
    buttons = [],
    initialValue = null,
    onChange = null,
    exclusive = false,
    allowNone = false,
    widthFilter = null,
    heightFilter = null
  } = props

  const getInitialValue = () => {
    if (initialValue) return initialValue
    if (Array.isArray(buttons) && buttons.length > 0 && buttons[0].value) return buttons[0].value
    return ''
  }

  const constructInitialValue = () => {
    if (exclusive) return getInitialValue()
    return [getInitialValue()]
  }
  const [value, setValue] = useState(constructInitialValue)

  const transformValueChange = (newValue) => {
    if (allowNone) return newValue
    let returnPrevValue = newValue === null
    if (!exclusive) returnPrevValue = newValue.length === 0

    return returnPrevValue ? value : newValue
  }

  const handleChange = (event, newValue) => {
    const value = transformValueChange(newValue)
    setValue(value)
    if (onChange && typeof onChange === 'function') onChange(value)
  }

  const getToggleButtons = () => {
    if (!Array.isArray(buttons)) return null
    return buttons.map((button, i) => <ToggleButton
      style={{ width: widthFilter, height: heightFilter }}
      key={'button_' + i}
      value={button.value}

                                      > {button.content}
    </ToggleButton>)
  }
  return (
    <>
      <ToggleButtonGroup
        value={value}
        exclusive={exclusive}
        onChange={handleChange}
      >
        {getToggleButtons()}
      </ToggleButtonGroup>
    </>
  )
}

export default GenericToggleGroup
