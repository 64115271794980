import React from 'react'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation/index.js'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable.jsx'
import { GetDateTime } from '../../generic/DateTimeFunctions.jsx'
import { useLocation, useNavigate } from 'react-router-dom'
import { apiPost } from '../../generic/Api_Functions.jsx'
import ButtonBack from '../../generic/buttons/floatingButtons/ButtonBack.jsx'

const SupervisionStateList = () => {
  const location = useLocation()
  const device = location.state.device
  const { t_ } = useSmartTranslation()
  const navigate = useNavigate()

  const dataFetch = async (pagination) => {
    const list = await apiPost('deviceStatusByDeviceId/' + device.id, pagination)
    return list
  }

  const columns = [
    {
      name: t_('Status'),
      field: 'status'
    },
    {
      name: t_('Start date'),
      field: 'startDate',
      render: (row) => {
        return GetDateTime(row.startDate)
      }
    },
    {
      name: t_('End date'),
      field: 'endDate',
      render: (row) => {
        return GetDateTime(row.endDate)
      }
    },
    {
      name: t_('Comment'),
      field: 'comment'
    }
  ]

  const actions = {
    create: {}
  }

  return (
    <>
      <SmartMateriaUITable
        title={t_('Status') + ' - ' + t_(device.name)}
        columns={columns}
        dataFetch={dataFetch}
        pageable
        actions={actions}
        onAddElementClick={() => navigate('/compass/supervision/addStatus', { state: { device } })}
      />
      <div style={{ width: '100%', float: 'right' }}>
        <ButtonBack buttonAction={() => navigate('/compass/supervision')} />
      </div>
    </>
  )
}
export default SupervisionStateList
