import { Box, Grid, Stack, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { ChecklistJob, getJobStatus } from './ChecklistJob'
import ChecklistIndicator from './ChecklistIndicator'
import { getTaskStatus } from './ChecklistTask'
import { useSmartTranslation } from '../hooks/useSmartTranslation'
import ChecklistQuantityIndicator from './ChecklistQuantityIndicator'

/**
 * Shows the checklist for the part reference
 * @param {Object} props
 * @param {Object} props.workflow Workflow to show
 * @param {Function} props.onChangeWorkflow Function called with the changed workflow as parameter
 * @param {Boolean} props.forceDisabled If true. Errors can not be clicked and forced. False by default
 * @param {Function} props.onAddProgram Function to be called when the user clicks to add a program. Params will be workflow, job, task and task index
 * @returns
 */
const CheckListPartReference = ({ workflow, onChangeWorkflow, forceDisabled = false, onAddProgram }) => {
  const { t_ } = useSmartTranslation()

  const getHeaderKeyValueItem = (label, value) => {
    return <Stack direction='row'><Typography>{label + ':'}</Typography>{React.isValidElement(value) ? <Box sx={{ marginLeft: '0.5rem' }}>{value}</Box> : <Typography color='primary' sx={{ marginLeft: '0.5rem' }}>{value}</Typography>}</Stack>
  }

  const onJobDataChanged = (jobIndex, newData) => {
    const newWorkflow = { ...workflow }
    newWorkflow.jobs = [...workflow.jobs]
    newWorkflow.jobs[jobIndex] = newData
    onChangeWorkflow(newWorkflow)
  }

  const workflowStatus = useMemo(() => {
    return getWorkflowStatus(workflow)
  }, [workflow])

  return (
    <Stack gap={3}>
      <Stack direction='row' justifyContent='space-between' sx={{ margin: '0 0.6rem' }} gap={8}>
        {getHeaderKeyValueItem(t_('Reference'), workflow.partReference)}
        {forceDisabled ? null : getHeaderKeyValueItem(t_('Manufacturable') + '/' + t_('Total'), <ChecklistQuantityIndicator quantity={getQuantity(workflow)} total={workflow.total} />)}
        {getHeaderKeyValueItem(t_('Workflow'), workflow.workflow !== null ? workflow.workflow : '-')}
        <ChecklistIndicator status={workflowStatus} />
      </Stack>
      <Stack>

        <Grid container sx={{ backgroundColor: (theme) => theme.palette.background.default, padding: '1rem', paddingRight: '5rem' }}>
          <Grid item xs={6}><Typography sx={{ textAlign: 'start', marginLeft: '2rem' }}>{t_('Technology')}</Typography></Grid>
          <Grid item xs={3}><Typography sx={{ textAlign: 'center' }}>{t_('Fixture')}</Typography></Grid>
          <Grid item xs={3}><Typography sx={{ textAlign: 'end' }}>{t_('Asset')}</Typography></Grid>
        </Grid>

        {workflow.jobs
          ? workflow.jobs.map((job, index) => {
            return <ChecklistJob key={job.id} index={index} job={job} total={workflow.total} onChange={onJobDataChanged} forceDisabled={forceDisabled} onAddProgram={(job, task, index) => onAddProgram(workflow, job, task, index)} />
          })
          : <Typography variant='h6' color='error' sx={{ marginTop: '1rem' }}>{workflow.workflowId === null ? t_('Part reference without workflow') : t_('The workflow has an error')}</Typography>}
      </Stack>
    </Stack>
  )
}

const getQuantity = (workflow) => {
  let min = Number.MAX_SAFE_INTEGER
  workflow.jobs.forEach(job => {
    if (job.quantity < min) {
      min = job.quantity
    }
  })
  return min
}

const getWorkflowStatus = (workflow) => {
  if (workflow.jobs === null) {
    return 'ERROR'
  }

  let warning = false
  let error = false

  if (workflow.workflowId === null || !workflow.jobs) {
    return 'ERROR'
  }

  // Check tasks status
  workflow.jobs.forEach(job => {
    const jobStatus = getJobStatus(job)
    if (jobStatus === 'ERROR') {
      error = true
    } else if (jobStatus === 'WARNING') {
      warning = true
    }
  })

  // Check tool quantity
  if (getQuantity(workflow) < workflow.total) {
    warning = true
  }

  if (error) {
    return 'ERROR'
  }
  if (warning) {
    return 'WARNING'
  }
  return 'OK'
}

const setOpenJobsOnLoad = (workflow) => {
  if (workflow.workflowId === null || !workflow.jobs) {
    return
  }
  workflow.jobs.forEach(job => {
    let tasksOkay = true
    job.tasks.forEach(task => {
      if (getTaskStatus(task) !== 'OK') {
        tasksOkay = false
      }
    })
    job.tasksOpen = !tasksOkay
  })
}

export { CheckListPartReference, getWorkflowStatus, setOpenJobsOnLoad }
