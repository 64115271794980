import React, { useState } from 'react'
import { Tab, Tabs, TextField, Button, Box, Grid, IconButton, InputAdornment, Tooltip } from '@mui/material'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { Visibility, VisibilityOff, FileCopy } from '@mui/icons-material'

const CreateUserPasswordDialog = (props) => {
  const {
    password, setPassword, confirmPassword, setConfirmPassword,
    passwordError, setSelectedDialogTab, resetPassword
  } = props
  const [value, setValue] = useState(0)
  const [showPassword, setShowPassword] = useState(false)
  const { t_ } = useSmartTranslation()

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
    setSelectedDialogTab(newValue)
    resetPassword()
  }

  const handleGeneratePassword = () => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let generatedPassword = ''

    // Maximun i value will set the generated password length
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      generatedPassword += charset[randomIndex]
    }
    setPassword(generatedPassword)
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(password)
  }

  return (
    <div>
      <Tabs value={value} onChange={handleTabChange} centered>
        <Tab label={t_('Create Password')} />
        <Tab label={t_('Autogenerate Password')} />
      </Tabs>
      <Box p={2}>
        {value === 0 && (
          <Grid container spacing={2} justifyContent='flex-start'>
            <Grid item xs={12}>
              <TextField
                label={t_('Password')}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin='normal'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='Toggle password visibility'
                        onClick={handleTogglePasswordVisibility}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t_('Confirm Password')}
                type='password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                error={passwordError !== ''}
                helperText={passwordError}
              />
            </Grid>
          </Grid>
        )}
        {value === 1 && (
          <Grid container spacing={2} justifyContent='flex-start'>
            <Grid item xs={12} container justifyContent='flex-start'>
              <TextField
                label={t_('Autogenerated Password')}
                type='text'
                value={password}
                margin='normal'
                InputProps={{ readOnly: true }}
              />
              <Tooltip title={t_('Copy to Clipboard')} placement='right'>
                <IconButton
                  color='primary'
                  onClick={handleCopyToClipboard}
                  style={{ marginLeft: '8px' }}
                >
                  <FileCopy />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} container justifyContent='flex-start'>
              <Button
                variant='contained'
                color='info'
                onClick={handleGeneratePassword}
              >
                {t_('Autogenerate Password')}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  )
}

export default CreateUserPasswordDialog
