import React from "react";
import SmartNavigator from "../generic/SmartNavigator";
import Workers from "../configuration/workers/Workers";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";

const MainWorkers = () => {
  const { t_ } = useSmartTranslation();
  const componentsInfo = [
    {
      title: t_("Workers"),
      component: <Workers />,
    }
  ];
  return <SmartNavigator componentsInfo={componentsInfo} />;
};
export default MainWorkers;