import React, { useEffect, useState, useRef } from 'react'
import { apiDelete, apiGet, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import GlobalConfig from '../../generic/GlobalConfig'
import { Button, IconButton, Stack, Box, Typography } from '@mui/material'
import { AddCircleRounded, InsertDriveFileRounded, Publish } from '@mui/icons-material'

import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

import AlertUI from '../../generic/AlertUI'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import SmartSelect from '../../generic/smartSelect/SmartSelect'

const baseEndpointUrl = GlobalConfig['back-end_address']

const Programs = () => {
  const [technologies, setTechnologies] = useState([])

  const fileInputRef = useRef()
  const fileInputAddRef = useRef()

  const [AlertElement, showAlert] = AlertUI()

  const dataRow = useRef()
  const newRow = useRef()
  const [resetDataRow, setResetDataRow] = useState(false)
  const { t_ } = useSmartTranslation()
  const [dialogStatus, setDialogStatus] = useState({ open: false, file: null })
  const [controlOptions, setControlOptions] = useState([])
  const [controlSelectedName, setControlSelectedName] = useState('')
  const [controlSelectedId, setControlSelectedId] = useState([])

  const onRowClick = (row) => {
    newRow.current = row
    if (dataRow.current === null || dataRow.current === undefined) {
      dataRow.current = newRow.current
    } else if (dataRow.current['smart-table-index'] === newRow.current['smart-table-index'] || newRow.current['smart-table-index'] === -1) {
      dataRow.current = newRow.current
    }
  }

  useEffect(() => {
    if (resetDataRow) {
      dataRow.current = newRow.current
      setResetDataRow(false)
    }
  }, [resetDataRow, newRow])

  const columns = [
    { name: t_('Name'), field: 'name', tooltip: 'name', readOnly: true },
    {
      name: t_('Technology'),
      field: 'technology.name',
      tooltip: 'Technology',
      type: 'select',
      mandatory: true,
      edit: {
        source: async () => {
          return (technologies || null)
        },
        id: 'id',
        field: 'name'
      }
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      const options = await apiGet('getControls/')
      setControlOptions(options)

      apiGet('technologiesWorkflow').then((data) => {
        setTechnologies(data)
      })
    }
    fetchData()
    return () => {
    }
  }, [t_])

  const handleControlSelectChange = (newValue) => {
    setControlSelectedName(newValue)

    const selectedControl = controlOptions.find((opt) => opt.name === newValue)
    if (selectedControl) {
      setControlSelectedId(selectedControl.id)
    } else {
      setControlSelectedId(null)
    }
  }

  const finalConfigurationProgram = useRef()
  const [technologySelected, setTechnologySelected] = useState()

  function BodyModal (props) {
    return (
      <div sx={{ display: 'grid' }}>
        <Stack spacing={2}>
          <Typography component='span' variant='subtitle1'>{t_('1.- Assign a technology to this program')}</Typography>
          {technologies.map((technology, index) =>
            <Button
              variant='contained'
              key={index}
              color={technologySelected ? technologySelected.name === technology.name ? 'primary' : 'inherit' : 'inherit'}
              sx={{ color: 'rgba(0, 0, 0, 0.87) !important' }}
              onClick={() => {
                setTechnologySelected(technology)
                finalConfigurationProgram.current = { technology }
              }}
            >
              {t_(technology.name)}
            </Button>
          )}
          <SmartSelect
            label={t_('Control selection')}
            value={controlSelectedName}
            selectableOptions={controlOptions.map((opt) => opt.name)}
            onChange={handleControlSelectChange}
            disableClearable={false}
            autoSelectFirst={false}
          />
        </Stack>
      </div>
    )
  }

  const handleAcceptModal = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    if (controlSelectedId) {
      formData.append('control', controlSelectedId)
    }
    if (!finalConfigurationProgram.current.technology) {
      showAlert({
        title: t_('Technology has not been choosen.'),
        severity: 'error'
      })
      return
    } else {
      formData.append('technology', finalConfigurationProgram.current.technology.id)
    }

    const response = await fetch(baseEndpointUrl + 'programs', {
      method: 'POST',
      body: formData
    })
    if (response.ok === true) {
      showAlert({
        title: t_('Program saved correctly'),
        severity: 'success'

      })
    } else {
      const responseData = await response.json()

      showAlert({
        title: '',
        severity: 'error',
        message: responseData.message
      })
    }
    return response
  }

  const handleCreateRow = (event) => {
    setDialogStatus({ open: true, file: event.target.files[0] })
  }

  const handleChange = async (event) => {
    const formData = new FormData()
    formData.append('file', event.target.files[0])
    formData.append('program', row.current.id)
    formData.append('technology', row.current.technology.id)

    const response = await fetch(baseEndpointUrl + 'programs', {
      method: 'PUT',
      body: formData
    })

    if (response.ok === true) {
      showAlert({
        title: t_('Program modified correctly'),
        severity: 'success'

      })
    } else {
      showAlert({
        title: t_('An error occurred while creating.'),
        severity: 'error'

      })
    }
    return response
  }

  const row = useRef()

  const handleUploadProgram = (event) => {
    row.current = event
    fileInputRef.current.click()
  }

  const handleCreateClick = (event) => {
    setControlSelectedName(null)
    setControlSelectedId(null)
    event.target.value = null
  }

  const defaultToolbar = {
    render: {
      fetchFunction: () => {
        return (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={() => {
                row.current = null
                fileInputAddRef.current.click()
              }}
            >
              <AddCircleRounded sx={{ fontSize: '1.2em' }} />
              <Typography sx={{ marginLeft: '0.4em', fontSize: '0.875rem' }}>{t_('Add program')}</Typography>
            </Button>
            <input
              onClick={(event) => {
                handleCreateClick(event)
              }}
              onChange={(event) => handleCreateRow(event)}
              multiple
              ref={fileInputAddRef}
              type='file'
              hidden
            />

          </>

        )
      }
    }
  }

  const actions = {
    delete: {
      fetchFunction: (row) => {
        return (apiDelete('programs/' + row[0].id))
      }
    },

    edit: {
      fetchFunction: (row) => {
        return apiPut('programs/updateTechnology/' + row.id + '/' + row.technology.id)
      }
    },
    custom: [{

      name: 'UpLoad Program',
      render: (upload) => {
        return (
          <div>

            <IconButton onClick={() => handleUploadProgram(upload)}>
              <Publish />
            </IconButton>
            <input
              id='changeProgram'
              onClick={(event) => {
                event.target.value = null
              }}
              onChange={(event) => handleChange(event, upload)}
              multiple
              ref={fileInputRef}
              type='file'
              hidden
            />
          </div>
        )
      }
    }
    ]
  }

  return (
    <div>
      <SmartDialog
        message={<BodyModal />}
        cancel
        cancelCallback={() => setDialogStatus({ ...dialogStatus, open: false })}
        accept
        acceptCallback={() => {
          handleAcceptModal(dialogStatus.file)
          setDialogStatus({ ...dialogStatus, open: false })
        }}
        title={{ icon: <InsertDriveFileRounded color='primary' sx={{ fontSize: '1.8em' }} />, render: <Typography color='primary' variant='h8'>{dialogStatus.file?.name}</Typography> }}
        setOpen={dialogStatus.open}
      />
      {AlertElement}

      {technologies.length !== 0
        ? <SmartMateriaUITable
            columns={columns}
            title={t_('Programs')}
            dataFetch='programs'
            actions={actions}
            dense
            fetchInterval={1}
            toolbar={defaultToolbar}
            disableFlexGrow
            onRowClick={(row) => onRowClick(row)}
            editingRow={setResetDataRow}
          />
        : null}

    </div>
  )
}

export default Programs
