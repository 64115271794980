import { useState } from 'react'
import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import { AddCircleRounded, ManageSearchOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import FabricationOrder from './FabricationOrder'
import PropTypes from 'prop-types'

// Import typedefs from another file
/**
 * @typedef {import('../../generic/utilities/objectStructures/orderWindow/order').Order} Order Order object
 * @typedef {import('../../generic/utilities/objectStructures/orderWindow/lineOrder').LineOrder} LineOrder LineOrder object
 */

/**
 * SmartSelect component
 * @param {Object} props Properties object
 * @param {Order} props.order Order object
 * @param {boolean} props.addModeByDefault Add mode by default
 */
const LineOrder = ({ order, addModeByDefault }) => {
  const [openFabricationOrderDialog, setOpenFabricationOrderDialog] = useState(false)
  const [editing, setEditing] = useState(false)
  /** @type {[LineOrder, React.Dispatch<SetStateAction<LineOrder>>]} */
  const [currentLineOrder, setCurrentLineOrder] = useState({})
  const [fabricationOrderAddModeByDefault, setFabricationOrderAddModeByDefault] = useState(false)
  const { t_ } = useSmartTranslation()

  const columns = [
    {
      name: t_('Line order'),
      field: 'lineNumber',
      mandatory: true,
      unique: true,
      numeric: true
    },
    {
      name: t_('Fabrication order'),
      field: 'fabricationOrderQuantity',
      readOnly: true,
      defaultNewValue: 0,
      sortable: false,
      search: false,
      render: (row) => {
        return (
          <>
            {row.fabricationOrderQuantity}
            <IconButton
              color='primary'
              onClick={() => {
                setCurrentLineOrder(row); setFabricationOrderAddModeByDefault(true); setOpenFabricationOrderDialog(true)
              }}
              disabled={(editing || row['smart-table-index'] === -1)}
            >
              <AddCircleRounded />
            </IconButton>
          </>
        )
      }
    },
    {
      name: t_('Part Reference'),
      field: 'partReference.name',
      mandatory: true,
      readOnly: true,
      type: 'select',
      edit: {
        source: async () => {
          return await apiGet('partReferencesShortData')
        },
        id: 'id',
        field: 'name'
      }
    },
    {
      name: t_('Quantity'),
      field: 'quantity',
      mandatory: true,
      numeric: true,
      defaultNewValue: 1
    },
    {
      name: t_('Delivery date'),
      field: 'deliveryDate',
      mandatory: true,
      type: 'dateTimePicker'
    },
    {
      name: t_('Comment'),
      field: 'comment'
    }
  ]

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('order/' + order.id + '/lineOrder/' + row.id, row)
      }
    },
    create: {
      fetchFunction: (row) => {
        return apiPost('order/' + order.id + '/lineOrder', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('order/lineOrder/' + rows[0].id)
        } else {
          return apiDelete('order/' + order.id + '/lineOrder/multi', rows)
        }
      }
    },
    custom: [
      {
        render: (row) => {
          return (
            <IconButton
              disabled={editing}
              onClick={() => {
                setCurrentLineOrder(row)
                setFabricationOrderAddModeByDefault(false)
                setOpenFabricationOrderDialog(true)
              }}
            >
              <ManageSearchOutlined />
            </IconButton>
          )
        }
      }

    ]
  }

  const dataFetch = async () => {
    return await apiGet('order/' + order.id + '/lineOrders')
  }

  return (
    <>
      <SmartDialog
        setOpen={openFabricationOrderDialog}
        message=''
        renderComponent={<FabricationOrder orderDate={order.date} client={order.client} lineOrder={currentLineOrder} addModeByDefault={fabricationOrderAddModeByDefault} />}
        close
        accept={false}
        cancel={false}
        closeCallback={() => setOpenFabricationOrderDialog(false)}
      />
      <SmartMateriaUITable
        columns={columns}
        dataFetch={dataFetch}
        actions={actions}
        multipleSelection
        pageable={false}
        addModeByDefault={addModeByDefault}
        editingRow={(event) => setEditing(event)}
        sticky={false}
      />
    </>
  )
}

export default LineOrder

LineOrder.propTypes = {
  order: PropTypes.object.isRequired,
  addModeByDefault: PropTypes.bool.isRequired
}
