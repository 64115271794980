import { CircularProgress, TextField, Grid, Paper, MenuItem } from '@mui/material'

import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { apiGet, apiPut } from '../../generic/Api_Functions'
import { DefaultAccordion } from '../../generic/DefaultAccordion'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import ButtonBackSave from '../../generic/buttons/floatingButtons/ButtonBackSave'
import { useNavigate } from 'react-router'
import Chip from '@mui/material/Chip'
import { SmartSelect } from '../../generic/smartSelect'

const WorkerSettings = () => {
  const [worker, setWorker] = useState({})
  const [technologies, setTechnologies] = useState([])
  const [technologyGroups, setTechnologyGroups] = useState([])
  const [calendars, setCalendars] = useState([])
  const [selectedTechnologies, setSelectedTechnologies] = useState([])
  const [selectedGroupTechnologies, setSelectedGroupTechnologies] = useState([])

  const { workerId } = useParams()
  const { t_ } = useSmartTranslation()
  const backSaveButtonRef = useRef()
  const [editMode, setEditMode] = useState(false)

  const navigate = useNavigate()

  // Recupera el worker a cargar + las tecnologías y grupos a mostrar en los chips
  useEffect(() => {
    const fetchWorker = async () => {
      const workerDB = await apiGet('worker/' + workerId)
      setWorker(workerDB)
      if (workerDB.allIndividualTechnologies) {
        const initialChips = workerDB.allIndividualTechnologies.map((tech) => ({
          id: tech.id,
          name: tech.name,
          isGroup: false,
          fakeId: `technology_${tech.id}`
        }))

        const initialGroupChips = workerDB.allGroupTechnologies.map((tech) => ({
          id: tech.id,
          name: tech.name,
          isGroup: true,
          fakeId: `group_${tech.id}`
        }))

        setSelectedTechnologies(initialChips)
        setSelectedGroupTechnologies(initialGroupChips)
      }
    }
    fetchWorker()
  }, [workerId])

  // Recupera tecnologías individuales
  useEffect(() => {
    const fetchTechnologies = async () => {
      const technologiesDB = await apiGet('technologiesBasicData')
      setTechnologies(technologiesDB)
    }
    fetchTechnologies()
  }, [])

  // Recupera grupos de tecnología con sus correspondientes tecnologías dentro
  useEffect(() => {
    const fetchTechnologyGroups = async () => {
      const technologyGroupsDB = await apiGet('technologyGroupsNoPagination')
      setTechnologyGroups(technologyGroupsDB)
    }
    fetchTechnologyGroups()
  }, [])

  useEffect(() => {
    const fetchCalendars = async () => {
      const calendarsDB = await apiGet('allCalendarReferences')
      setCalendars(calendarsDB)
    }
    fetchCalendars()
  }, [])

  const changeBetweenVisualizeModes = (edit) => {
    setEditMode(edit)
  }

  const goBack = (state) => {
    navigate('/configuration/workers', { replace: true, state })
  }

  // Recupera las tecnologías a mostrar en el autocomplete de selección de tecnologías
  const optionTechnologiesMultiSelect = () => {
    if (technologies === null || technologies === undefined) {
      return []
    } else if (Object.keys(technologies).length === 0) {
      return []
    } else if (technologies !== undefined && technologies !== null && Array.isArray(technologies)) {
      const selectedValues = technologies.map((opt) => {
        return opt.name
      })
      return selectedValues
    } else {
      return []
    }
  }

  // Recupera las tecnologías a mostrar en el autocomplete de selección de grupos de tecnologías
  const optionTechnologyGroupsMultiSelect = () => {
    if (technologyGroups === null || technologyGroups === undefined) {
      return []
    } else if (Object.keys(technologyGroups).length === 0) {
      return []
    } else if (technologyGroups !== undefined && technologyGroups !== null && Array.isArray(technologyGroups)) {
      const selectedValues = technologyGroups.map((opt) => {
        return opt.name
      })
      return selectedValues
    } else {
      return []
    }
  }

  // Carga los autocompletes de tecnologías y grupos que corresponden al worker y actualiza el objeto worker que se envía para el guardado/update
  const selectTechnologiesOrGroups = (isGroup) => {
    if (worker.workerTechnologies === null || worker.workerTechnologies === undefined) {
      return []
    } else if (Object.keys(worker.workerTechnologies).length === 0) {
      return []
    } else if (Array.isArray(worker.workerTechnologies)) {
      const selectedValues = worker.workerTechnologies
        .filter((opt) => opt.isGroup === isGroup)
        .map((opt) => opt.name)
      return selectedValues
    } else {
      return []
    }
  }

  // Tecnologías individuales seleccionadas en el autocomplete de tecnologías y actualiza el objeto worker que se envía para el guardado/update
  const onChangeTechnologyValues = (newTechnologiesSelectedValues) => {
    const newTechnologyValues = newTechnologiesSelectedValues.map((value) => {
      const newTechnologyValue = technologies.find((elem) => elem.name === value)
      return {
        id: newTechnologyValue.id,
        name: newTechnologyValue.name,
        isGroup: false
      }
    })

    const updatedWorkerTechnologies = [...newTechnologyValues, ...worker.workerTechnologies.filter((value) => value.isGroup)]
    setSelectedTechnologies(newTechnologyValues)

    setWorker({ ...worker, workerTechnologies: updatedWorkerTechnologies })
  }

  // Grupos de tecnología seleccionadas en el autocomplete de tecnologías
  const onChangeTechnologyGroupValues = (newTechnologyGroupsSelectedValues) => {
    const selectedGroupTechnologies = []
    const newGroupValue = newTechnologyGroupsSelectedValues.map((value) => {
      const newGroupValue = technologyGroups.find((elem) => elem.name === value)
      return {
        id: newGroupValue.id,
        name: newGroupValue.name,
        isGroup: true
      }
    })

    const updatedWorkerTechnologies = [...newGroupValue, ...worker.workerTechnologies.filter((value) => !value.isGroup)]

    for (const groupName of newTechnologyGroupsSelectedValues) {
      const group = technologyGroups.find((elem) => elem.name === groupName)

      if (group) {
        // Desglose de grupos de tecnología con las tecnologías correspondientes
        selectedGroupTechnologies.push(
          ...group.technologies.map((tech) => ({
            id: tech.id,
            name: tech.name,
            group: group.name
          }))
        )
      }
    }
    setWorker({ ...worker, workerTechnologies: updatedWorkerTechnologies })
    setSelectedGroupTechnologies(selectedGroupTechnologies)
  }

  const onChangeCalendarValues = (event) => {
    const newCalendarSelectedValue = event.target.value
    const selectedCalendar = calendars.find((calendar) => calendar.id === newCalendarSelectedValue)

    setWorker({ ...worker, calendar: selectedCalendar })
  }

  const saveWorker = async () => {
    try {
      await apiPut('worker', worker)
      changeBetweenVisualizeModes(false)
      goBack('EDIT')
    } catch (error) {
      backSaveButtonRef.current.showErrorAlert({
        title: 'An error occurred while editing.',
        message: error.message,
        error: 'error'
      })
    }
  }

  const getTotalLayout = () => {
    if (worker.id) {
      return (
        <div>
          <DefaultAccordion title={t_('Identification')}>
            <Grid container direction='row' spacing={2} columns={{ xs: 1, sm: 4, md: 8, lg: 12 }}>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label={t_('Code')}
                  id={worker.code}
                  value={worker.code}
                  onChange={(event) => {
                    setWorker({ ...worker, code: event.target.value })
                  }}
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label={t_('Name')}
                  id={worker.name}
                  value={worker.name}
                  onChange={(event) => {
                    setWorker({ ...worker, name: event.target.value })
                  }}
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label={t_('Last name')}
                  id={worker.surname}
                  value={worker.surname}
                  onChange={(event) => {
                    setWorker({ ...worker, surname: event.target.value })
                  }}
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label={t_('Phone')}
                  id={worker.tlf}
                  value={worker.tlf}
                  onChange={(event) => {
                    setWorker({ ...worker, tlf: event.target.value })
                  }}
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label={t_('Email')}
                  id={worker.email}
                  value={worker.email}
                  onChange={(event) => {
                    setWorker({ ...worker, email: event.target.value })
                  }}
                  disabled={!editMode}
                />
              </Grid>
            </Grid>

          </DefaultAccordion>
          <DefaultAccordion title={t_('Availability & skill')}>
            <Grid container direction='row' spacing={2} columns={{ xs: 1, sm: 4, md: 8, lg: 12 }}>
              <Grid item xs={2}>
                <TextField
                  select
                  label={t_('Calendar')}
                  fullWidth
                  value={worker.calendar.id}
                  disabled={!editMode}
                  onChange={onChangeCalendarValues}
                >
                  {calendars.map((calendar) => (
                    <MenuItem value={calendar.id} key={calendar.id}>
                      {calendar.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <SmartSelect
                  multiple
                  selectableOptions={optionTechnologiesMultiSelect()}
                  value={selectTechnologiesOrGroups(false)}
                  onChange={onChangeTechnologyValues}
                  label={t_('Select skills')}
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={4}>
                <SmartSelect
                  multiple
                  selectableOptions={optionTechnologyGroupsMultiSelect()}
                  value={selectTechnologiesOrGroups(true)}
                  onChange={onChangeTechnologyGroupValues}
                  label={t_('Select skill groups')}
                  disabled={!editMode}
                />
              </Grid>
            </Grid>
            <div>
              <Paper style={{ marginTop: '16px', padding: '16px' }}>
                <h5>{t_('Selected individual skills')}</h5>
                {[...new Set(selectedTechnologies
                  // .filter((tech) => !tech.isGroup)
                  .concat(selectedGroupTechnologies)
                  .map((tech) => tech.name))].sort()
                  .map((techName, index) => (
                    <Chip
                      key={index}
                      label={techName}
                      variant='outlined'
                      style={{ margin: '4px' }}
                    />
                  ))}
              </Paper>
            </div>
          </DefaultAccordion>
          <ButtonBackSave
            editControl={() => changeBetweenVisualizeModes(true)}
            backButtonAction={() => goBack('BACK')}
            saveButtonAction={() => saveWorker()}
            ref={backSaveButtonRef}
          />
        </div>
      )
    } else {
      return <CircularProgress />
    }
  }

  return <div>{getTotalLayout()}</div>
}

export default WorkerSettings
