import React from "react";
import ImageManufacturing from "../../images/Manufacturing.png";



const LogoManufacturing = () => {
    return (
        <img src="../../../images/Manufacturing.png" alt="Manufacturing" style={{ height: "100%" }} onError={(e) => { e.target.src = ImageManufacturing; }} />
    );
};
export default LogoManufacturing;