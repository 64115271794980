import React, { useEffect, useState } from 'react'
import { DefaultAccordion } from '../generic/DefaultAccordion'
import Timeline from './Timeline'
import { apiGet, apiPost } from '../generic/Api_Functions'
import SmartMateriaUITable from './../generic/SmartMateriaUITable'
import DownloadIcon from '@mui/icons-material/Download'
import { Button, Stack, Typography, CircularProgress } from '@mui/material'
import CriteriaInfo from './CriteriaInfo'
import moment from 'moment'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import downloadAsCsv from './csv_utils'

const Planner = () => {
  /**
   * @typedef {import("../generic/utilities/objectStructures/planner/planification").Planification} Planification Planification object
   */
  /**
   * @typedef {import("../generic/utilities/objectStructures/planner/eventInfo").ExtendedProps} ExtendedProps EventExtendedProps object
   */

  /** @type {[ExtendedProps, React.Dispatch<React.SetStateAction<Planification>>]} */
  const [selectedPlanificationEvents, setSelectedPlanificationEvents] = useState([])
  /** @type {[Planification, React.Dispatch<React.SetStateAction<Planification>>]} */
  const [currentPlanification, setCurrentPlanification] = useState({ temporalCriteria: { name: 'None' }, costCriteria: { name: 'None' }, costPriority: 1, temporalPriority: 1, executionTime: 0, current: false, unitCriteria: { name: 'None' } })
  /** @type {[Planification, React.Dispatch<React.SetStateAction<string[]>>]} */
  const [selectedFile, setSelectedFile] = useState({})
  const [isPlannerWorking, setPlannerWorking] = useState(false)
  const [forceReloadCalendar, setForceReloadCalendar] = useState(false)
  const [downloadingFile, setDownloadingFile] = useState(false)
  const { t_ } = useSmartTranslation()

  /** @type {[Planification[], React.Dispatch<React.SetStateAction<Planification[]>>]} */
  const [publishedPlans, setPublishedPlans] = useState([])

  // Load "Current" plan if nothing more selected
  if (Object.keys(selectedFile).length === 0 && publishedPlans.length > 0) {
    const file = publishedPlans.find((item) => item.current)
    setSelectedFile(file)
  }

  useEffect(() => {
    const loadCriteria = async () => {
      if (Object.keys(selectedFile).length > 0) {
        const file = selectedFile.id
        const criteria1 = await apiGet('planner/getPlanificationCriteria', { planificationId: file })
        if (criteria1 && !Array.isArray(criteria1)) {
          setCurrentPlanification(criteria1)
        }
      }
    }
    loadCriteria()
  }, [selectedFile])
  useEffect(() => {
    if (Object.keys(selectedFile).length > 0) {
      const loadTableData = async () => {
        const file = selectedFile.id
        const response = await apiPost('planner/planification', { planificationId: file, startDate: null, endDate: null, groupBy: null })
        setSelectedPlanificationEvents(response)
      }
      loadTableData()
    }
  }, [selectedFile])

  const loadPublishedPlans = async () => {
    setPublishedPlans(await apiGet('planner/getCurrentPlanifications'))
  }

  useEffect(() => {
    loadPublishedPlans()
  }, [])

  useEffect(() => {
    let mounted = true
    const interval = setInterval(async () => {
      const response = await apiGet('planner/working')
      if (mounted) {
        // Check if we were loading previously and it finished
        if (!response && isPlannerWorking) {
          setForceReloadCalendar(!forceReloadCalendar)
        }

        if (isPlannerWorking !== response) {
          if (isPlannerWorking && !response) {
            setForceReloadCalendar(!forceReloadCalendar)
          }
          setPlannerWorking(response)
        }
      }
    }, 1000)
    return () => {
      mounted = false
      clearInterval(interval)
    }
  }, [isPlannerWorking, forceReloadCalendar])

  const handleDownloadPlanification = async () => {
    try {
      setDownloadingFile(true)
      const response = await apiGet('planner/download/' + selectedFile.id)
      const csvSeparator = await apiGet('settings/character').then((result) => {
        if (result[0].length !== 0) {
          return result[0]
        }
      })
      downloadAsCsv(columns, response, selectedFile.fileName, csvSeparator)
      setDownloadingFile(false)
    } catch (error) {
      console.error('Error downloading CSV:', error)
      setDownloadingFile(false)
    }
  }

  const columns = [
    {
      field: 'machineName',
      name: t_('Asset')
    },
    {
      field: 'start',
      name: t_('Start'),
      render: (row) => {
        return moment(row.start).format('YYYY-MM-DD HH:mm')
      }
    },
    {
      field: 'end',
      name: t_('End'),
      render: (row) => {
        return moment(row.end).format('YYYY-MM-DD HH:mm')
      }
    },
    {
      name: t_('FO'),
      field: 'nameFO'
    },
    {
      name: t_('Delivery date'),
      field: 'deliveryDateFO',
      render: (row) => {
        return moment(row.deliveryDateFO).format('YYYY-MM-DD HH:mm')
      }
    },
    {
      name: t_('Job (step n./total steps)'),
      field: 'currentJob',
      render: (row) => {
        return (
          <Stack direction='row' gap={1}>
            <Typography>{row.currentJob}</Typography>
            <Typography sx={{ color: '#CCCCCC' }}>
              {'(' + row.currentJobNum + '/' + row.totalJobCount + ')'}
            </Typography>
          </Stack>
        )
      }
    },
    {
      field: 'partName',
      name: t_('Part name'),
      hide: selectedFile?.unitCriteria?.name !== 'Job'
    },
    {
      name: t_('Status'),
      field: 'status',
      hide: selectedFile?.unitCriteria?.name !== 'Job'
    },
    {
      field: 'fixture',
      name: t_('Fixture')
    },
    {
      field: 'toolReferences',
      name: t_('Tool reference'),
      render: (row) => {
        return row.toolReferences.toString()
      }
    }
  ]

  const defaultToolbar = {
    render: {
      fetchFunction: () => {
        return (
          <>
            <Button endIcon={<DownloadIcon />} onClick={() => handleDownloadPlanification()}>
              {t_('Export')}
            </Button>
            {downloadingFile ? <CircularProgress /> : null}
          </>
        )
      }
    }
  }
  return (
    <>
      <DefaultAccordion title={t_('Timeline')}>
        <CriteriaInfo criteria={currentPlanification} />
        <Timeline
          selectedFile={selectedFile}
          setSelectedFile={(file) => setSelectedFile(file)}
          setCriteria={setCurrentPlanification}
          isPlannerWorking={isPlannerWorking}
          setIsPlannerWorking={(working) => setPlannerWorking(working)}
          handleDownloadPlanification={handleDownloadPlanification}
          forceReloadCalendar={forceReloadCalendar}
          publishedPlans={publishedPlans}
          loadPublishedPlans={loadPublishedPlans}
        />

      </DefaultAccordion>

      <DefaultAccordion title={t_('Details table')} defaultExpanded={false}>
        <SmartMateriaUITable
          title=''
          columns={columns}
          dataFetch={selectedPlanificationEvents}
          toolbar={defaultToolbar}
        />
      </DefaultAccordion>

    </>
  )
}

export default Planner
