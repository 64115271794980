import { React, useRef, useState } from "react";
import { TextField, Button, Typography, Stack, Grid } from "@mui/material";
import { AddCircleRounded } from "@mui/icons-material";
import { apiPut } from "../Api_Functions";
import { IconButton } from "@mui/material";
import SmartDatePicker from "../smartDatePicker/Index"
import { SmartDialog } from "../utilities/SmartDialog";
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const AddElement = (props) => {
  const { endpoint, parameter, title, referenceName, disable, onAlert } = props;
  const [showDialog, setShowDialog] = useState(false);
  const quantity = useRef(1);
  const entryDate = useRef(new Date());
  const { t_ } = useSmartTranslation();

  const updateQuantity = () => {
    if (!endpoint) return

    if (quantity.current <= 0) {
      return;
    }

    if (isNaN(entryDate.current)) {
      // Date is not valid
      return;
    }

    setShowDialog(false);

    apiPut(endpoint + "/" + parameter + "/" + quantity.current + "/" + entryDate.current, {})
      .catch(err => {
        onAlert({
          title: t_("Error updating quantity"),
          severity: "error",
          message: t_("Quantity could not be updated") + ": " + err
        })
      });
    quantity.current = 1
  };

  function onClickFunction() {
    entryDate.current = new Date()
    setShowDialog(true);
  }

  return (
    <div>
      <SmartDialog
        setOpen={showDialog}
        acceptCallback={updateQuantity}
        cancelCallback={() => setShowDialog(false)}
        title={{
          icon: <AddCircleRounded color="primary" sx={{ fontSize: '1.5em' }} />,
          render:
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" color="primary"> {referenceName}</Typography>
            </Stack>
        }}
        renderComponent={
          <>
            <Grid container spacing={3} justifyContent="center" sx={{ padding: (theme) => theme.spacing(2) }}>
              <Grid item xs={3}>
                <TextField
                  error={quantity.current <= 0}
                  label={t_("Quantity")} defaultValue={1} type='number' onChange={(event) => { quantity.current = event.target.value }} />
              </Grid>
              <Grid item xs={6}>
                <SmartDatePicker resultDate={entryDate} />
              </Grid>
            </Grid>
          </>
        }
      />
      {title === undefined ?
        < IconButton
          color="primary"
          onClick={onClickFunction}
          disabled={disable}>
          <AddCircleRounded />
        </IconButton >
        :
        <Button
          color="primary"
          onClick={onClickFunction}
          disabled={disable}>
          <AddCircleRounded style={{ fontSize: '1.2em' }} />
          <Typography style={{ marginLeft: '0.4em', fontSize: '0.875rem' }} >{title}</Typography>
        </Button >
      }
    </div >
  );
};

export default AddElement;
