import React, { useState } from 'react'
import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { IconButton } from '@mui/material'
import { ManageSearchOutlined } from '@mui/icons-material'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import ProfilePermission from './ProfilePermission'

const Profiles = () => {
  const { t_ } = useSmartTranslation()
  const [permissionsDialog, setPermissionDialog] = useState({ open: false, profileId: null, profileName: null, systemDefault: true })

  const columns = [{
    name: t_('Code'),
    field: 'code',
    charLimit: 70,
    mandatory: true,
    unique: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_('Name'),
    field: 'name',
    charLimit: 70,
    mandatory: true,
    unique: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_('Description'),
    field: 'description',
    charLimit: 200
  }
  ]

  const actions = {
    create: {
      fetchFunction: (row) => {
        return apiPost('profile', row)
      }
    },
    edit: {
      fetchFunction: (row) => {
        return apiPut('profile', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('profile/' + rows[0].id)
        } else {
          return apiDelete('profileMulti', rows)
        }
      },
      visibilityButton: (row) => {
        return !row.systemDefault
      }
    },
    custom: [
      {
        name: 'ShowToolTypeParameters',
        render: (row) => {
          return (
            <IconButton
              onClick={(event) => {
                setPermissionDialog({ open: true, profileId: row.id, systemDefault: row.systemDefault, profileName: row.name })
              }}
            >
              <ManageSearchOutlined />
            </IconButton>
          )
        }
      }
    ]
  }

  const fetchFunction = async (pagination) => {
    const profiles = await apiGet('profiles', pagination)
    return profiles
  }

  return (
    <>
      <SmartDialog
        setOpen={permissionsDialog.open}
        accept={false}
        close
        cancel={false}
        closeCallback={() => setPermissionDialog({ ...permissionsDialog, open: false })}
        renderComponent={<ProfilePermission profileId={permissionsDialog.profileId} systemDefault={permissionsDialog.systemDefault} profileName={permissionsDialog.profileName} />}
      />
      <SmartMateriaUITable
        columns={columns}
        title={t_('Profiles')}
        dataFetch={fetchFunction}
        actions={actions}
        multipleSelection
        pageable
      />
    </>
  )
}

export default Profiles
