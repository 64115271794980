import { Stack, TableCell, TableRow } from "@mui/material";
import ChecklistStatusButton from "./CheckListStatusButton";
import PropTypes from "prop-types";
import { useMemo } from "react";
import TooltipList from "./TooltipList";

/**
 * ChecklistTask component
 * @param {Object} props
 * @param {Number} props.index Task index in the job
 * @param {Object} props.task Task object
 * @param {String} props.task.program The program associated to the task. Could be null
 * @param {Boolean} props.task.programOk The program associated to the task is okay
 * @param {Array<Object>} props.task.tools The list of tools associated to the task
 * @param {Boolean} props.task.toolForce If the user forced the tool error
 * @param {Function} props.onChange Function to be called when a property of this task changes (Force for example). The function will be called with two params: 1-index 2-the new task object
 * @param {Function} props.onAddProgram Function that will be called when there is no program and the user wants to add it. Task and index will be the params
 * @returns 
 */
const ChecklistTask = ({ index, task, onChange, forceDisabled = false, onAddProgram }) => {

  const toolsOk = useMemo(() => {
    return areAllToolsOk(task.tools);
  }, [task.tools]);

  return (
    <TableRow sx={{ borderTop: (theme) => "1px " + theme.palette.primary.dark + " solid" }}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" gap={1}>
          <div>{task.program !== null ? task.program : "-"}</div>
          <ChecklistStatusButton ok={task.programOk} force={false} disabled={forceDisabled} onForceChange={() => onAddProgram(task, index)} />
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" gap={1}>
          <TooltipList items={task.tools} getItemId={(item) => item.id} getItemLabel={(item) => item.name} getItemOk={(item) => item.ok} />
          <ChecklistStatusButton ok={toolsOk} force={task.toolForce} onForceChange={(newForce) => onChange(index, { ...task, toolForce: newForce })} disabled={forceDisabled} />
        </Stack>
      </TableCell>
    </TableRow>
  );
};

/**
 * @param {Array<Object>} tools
 */
const areAllToolsOk = (tools) => {
  if (tools.length === 0) {
    // No tools required
    return true;
  }
  return tools.filter((tool) => !tool.ok).length === 0;
};

/**
 * @param {Object} task
 * @param {String} task.program The program associated to the task. Could be null
 * @param {Boolean} task.programOk The program associated to the task is okay
 * @param {Array<Object>} task.tools The list of tools associated to the task
 * @param {Boolean} task.toolForce If the user forced the tool error
 */
const getTaskStatus = (task) => {
  let toolsOk = areAllToolsOk(task.tools);
  if (task.programOk && toolsOk) {
    return "OK";
  }
  if (!task.programOk || (!toolsOk && !task.toolForce)) {
    return "ERROR";
  }
  return "WARNING";
}

ChecklistTask.propTypes = {
  index: PropTypes.number.isRequired,
  task: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddProgram: PropTypes.func.isRequired
}

export { ChecklistTask, getTaskStatus };