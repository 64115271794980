import React from 'react'
import { Typography } from '@mui/material'
import CopyrightIcon from '@mui/icons-material/Copyright'
import ImageLogoSmall from '../../generic/LogoGreenSmall'
import VersionNum from '../../generic/VersionNumber'

const appAboutStyle = {
  maxWidth: '1100px'
}
const aboutHeadStyle = {
  display: 'flex',
  marginLeft: '5%'
}
const boxStyle = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center'
}
const boxBodyStyle = {
  width: '65%',
  border: '1px solid grey',
  borderRadius: '8px',
  padding: '30px'
}

const About = (props) => {
  return (
    <div style={appAboutStyle}>
      <div style={aboutHeadStyle}>
        <ImageLogoSmall />
        <div style={{ marginLeft: '10%' }}>
          <Typography variant='h4' textAlign='center'>Manufacturing Intelligence Core</Typography>
          <Typography variant='h4' textAlign='center'>MIC by SMARTPM</Typography>
          <Typography variant='body2' sx={{ display: 'inline-flex' }}>
            Version <VersionNum /> | 64 Bits |
            <span>
              &nbsp; <CopyrightIcon fontSize='small' /> &nbsp;
            </span>
            SMART Process Management
          </Typography>
        </div>
      </div>
      <div style={boxStyle}>
        <div style={boxBodyStyle}>
          <Typography>MIC by SMARTPM is our automation platform for the machining industry.
            It integrates our knowledge of processes and metrology to connect assets, design,
            execute and control workflows improving productivity thanks to predictive analysis,
            machine learning and autonomous cell management.
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default About
