import { Stack, Typography } from "@mui/material";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";

/**
 * @typedef {import("../generic/utilities/objectStructures/planner/planification").Planification} Planification Planification object
 */

/**
 * @param {Object} props Props object 
 * @param {Planification} props.criteria 
 * @returns 
 */
const CriteriaInfo = ({ criteria }) => {
  const { t_ } = useSmartTranslation();

  return (
    criteria ?
      <Stack direction="row" gap={6} >
        <Stack direction="column" gap={1}>
          <Typography color="primary">{t_("Criteria")}:</Typography>
        </Stack >
        <Stack direction="column" gap={1}>
          <Typography color="primary">{t_("Temporal")}</Typography>
          <Typography>{criteria.temporalCriteria.name}</Typography>

        </Stack >
        <Stack direction="column" gap={1}>
          <Typography color="primary">{t_("Optimization")}</Typography>
          <Typography>{criteria.costCriteria.name}</Typography>
        </Stack >
        <Stack direction="column" gap={1}>
          <Typography color="primary">{t_("Unit")}</Typography>
          <Typography>{criteria.unitCriteria.name}</Typography>
        </Stack >
      </Stack > : null
  );

}
export default CriteriaInfo;