import React from 'react'
import Grid from '@mui/material/Grid'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add'
import { CompassButton } from '../CompassButton'
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft'

export default function NumericKeyBoard ({ value, onChange, decimalMode = false, buttonIcon = <AddIcon /> }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState()

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }
  const updateValue = (e) => {
    if (value === 0) {
      onChange(e.target.innerText)
    } else {
      onChange(value + e.target.innerText)
    }
  }

  const changeSign = () => {
    const newValue = parseFloat(value) * -1
    if (isNaN(newValue)) {
      return
    }
    onChange(newValue.toString())
  }

  const deleteValue = () => {
    onChange(0)
  }

  const sendValue = () => {
    setOpen(false)
  }
  const buttonStyle = {
    width: '100%',
    height: '100%'
  }

  const buttonLabels = [
    '7', '8', '9', '4', '5', '6', '1', '2', '3'
  ]

  const getCeButton = () => {
    return <Grid item xs={4}><div style={{ ...buttonStyle }}><CompassButton label='CE' labelStyle={{ fontSize: '3rem' }} color='secondary' onClick={deleteValue} /></div></Grid>
  }

  return (
    <>
      <button size='medium' aria-label='' onClick={handleClick('')}> {buttonIcon} </button>

      <Popper open={open} anchorEl={anchorEl} placement='bottom-end' transition style={{ zIndex: 100000 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Grid container justifyContent='space-between' alignItems='left' spacing={1} style={{ width: '30em', padding: '5px', marginTop: '5px', border: '1px solid white', backgroundColor: '#424242' }}>
                {buttonLabels.map((label) => {
                  return <Grid item xs={4} key={label}><div style={{ ...buttonStyle }}><CompassButton label={label} labelStyle={{ fontSize: '3rem' }} color='secondary' onClick={updateValue} /></div></Grid>
                })}
                {decimalMode ? <Grid item xs={4}><div style={{ ...buttonStyle }}><CompassButton label='+/-' labelStyle={{ fontSize: '3rem' }} color='secondary' onClick={changeSign} /></div></Grid> : getCeButton()}
                <Grid item xs={4}><div style={{ ...buttonStyle }}><CompassButton label='0' labelStyle={{ fontSize: '3rem' }} color='secondary' onClick={updateValue} /></div></Grid>
                {decimalMode
                  ? (
                    <>
                      <Grid item xs={4}><div style={{ ...buttonStyle }}><CompassButton label='.' labelStyle={{ fontSize: '3rem' }} color='secondary' onClick={updateValue} /></div></Grid>
                      {getCeButton()}
                    </>
                    )
                  : null}
                <Grid item xs={4}><div style={{ ...buttonStyle }}><CompassButton icon={<SubdirectoryArrowLeftIcon style={{ fontSize: '3rem' }} />} color='secondary' labelStyle={{ fontSize: '3rem' }} onClick={sendValue} /></div></Grid>

              </Grid>
            </Paper>
          </Fade>
        )}
      </Popper>

    </>

  )
}
