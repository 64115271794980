import { React, useEffect, useRef, useState } from 'react'
import { TextField, Button, Typography, Stack, Grid, Autocomplete, IconButton } from '@mui/material'
import { AddCircleRounded } from '@mui/icons-material'
import { apiGet, apiPut } from '../Api_Functions'
import SmartDatePicker from '../smartDatePicker/Index'
import { SmartDialog } from '../utilities/SmartDialog'
import { useSmartTranslation } from '../hooks/useSmartTranslation'

const AddElementFeatureQualitative = (props) => {
  const { endpoint, parameter, title, referenceName, disable, onAlert, partId, featureUnit } = props
  const [showDialog, setShowDialog] = useState(false)
  const [unitTypes, setUnitTypes] = useState([])
  const quantity = useRef(1)
  const entryDate = useRef(new Date())
  const { t_ } = useSmartTranslation()
  const [unitError, setUnitError] = useState(false)
  const [selectedValue, setselectedValue] = useState(null)

  const handleCloseDialog = () => {
    setselectedValue(null)
    setUnitError(false)
    setShowDialog(false)
  }

  const updateQuantity = () => {
    if (!endpoint) return

    if (quantity.current <= 0) {
      return
    }

    if (isNaN(entryDate.current)) {
      return
    }
    if (selectedValue !== null) {
      setShowDialog(false)

      apiPut(endpoint + '/' + partId + '/' + parameter + '/' + Date.parse(entryDate.current) + '/' + selectedValue)
        .catch(err => {
          onAlert({
            title: 'Error updating quantity',
            severity: 'error',
            message: 'Quantity could not be updated: ' + err
          })
        })
      quantity.current = 1
      setselectedValue(null)
      setUnitError(false)
    } else {
      setUnitError(true)
    }
  }

  function onClickFunction () {
    entryDate.current = new Date()
    setShowDialog(true)
  }

  useEffect(() => {
    const loadUnitTypes = async () => {
      const response = await apiGet('qualityValues')
      setUnitTypes(response)
    }
    loadUnitTypes()
  }, [])

  const renderInput = (params, label) => {
    return (
      <TextField
        {...params}
        label={label}
        helperText={unitError ? 'Please select a value' : ''}
        error={unitError}
      />
    )
  }

  return (
    <div>
      <SmartDialog
        setOpen={showDialog}
        acceptCallback={updateQuantity}
        cancelCallback={handleCloseDialog}
        title={{
          icon: <AddCircleRounded color='primary' sx={{ fontSize: '1.5em' }} />,
          render:
  <Stack
    direction='row'
    justifyContent='center'
    alignItems='center'
  >
    <Typography variant='h6' color='primary'> {referenceName}</Typography>
  </Stack>
        }}
        renderComponent={
          <>
            <Grid container spacing={3} justifyContent='center' sx={{ padding: (theme) => theme.spacing(2) }}>
              <Grid item xs={4}>
                <Autocomplete
                  disableClearable
                  forcePopupIcon={false}
                  defaultValue={featureUnit}
                  options={unitTypes}
                  getOptionLabel={(option) => option.code}
                  isOptionEqualToValue={(option1, option2) => option1.id === option2.id}
                  onChange={(event, value) => {
                    setselectedValue(value ? value.id : null)
                  }}
                  renderInput={(params) => renderInput(params, t_('Value'), false)}
                />
              </Grid>
              <Grid item xs={4}>
                <SmartDatePicker resultDate={entryDate} />
              </Grid>
            </Grid>
          </>
        }
      />
      {title === undefined
        ? (
          <IconButton
            color='primary'
            onClick={onClickFunction}
            disabled={disable}
          >
            <AddCircleRounded />
          </IconButton>)
        : (
          <Button
            color='primary'
            onClick={onClickFunction}
            disabled={disable}
          >
            <AddCircleRounded style={{ fontSize: '1.2em' }} />
            <Typography style={{ marginLeft: '0.4em', fontSize: '0.875rem' }}>{title}</Typography>
          </Button>)}
    </div>
  )
}

export default AddElementFeatureQualitative
