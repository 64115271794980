import { Autocomplete, Button, Checkbox, Paper, TextField } from '@mui/material'
import React, { useEffect, useState, useCallback } from 'react'
import theme from '../../../themeConfig'
import { AddCircleOutlineRounded } from '@mui/icons-material'
import { SmartDialog } from '../utilities/SmartDialog'
import { useSmartTranslation } from '../hooks/useSmartTranslation'
import Proptypes from 'prop-types'

/**
* SmartSelect component
* @param {Object} props Props object
* @param {String[]} props.selectableOptions Selectable options.
* @param {(string|string[])} props.value Current selected values.
* @param {Boolean} props.disableClearable If true, the input can't be cleared. *Default value true*
* @param {function(String)} props.onChange Method that will be exetuted after changin selected value.
* @param {String} props.label Text that will appear on the textfield.
* @param {Boolean}  props.error If true it will show error on the textfield.
* @param {Object} props.content The content that will open on the dialog *Add record*
* @param {React.Dispatch<React.SetStateAction<boolean>>}  props.setReloadAutocompleteOptions useState set parameter to refresh selectable items. *After  adding record*
* @param {Boolean} props.multiple If true the component will have the multiSelection mode turn on, otherwise. *Default value false*
* @param {Function} props.functionToChangeTheValueWhenAddANewElement Function that will be executed after closing the dialog that opens *Adding record*
* @param {Boolean} props.readOnly If true, the component will be readOnly. *Default value false*
* @param {Boolean} props.addButtonVisible If true, Add button will be visible. *Default value false*
* @param {Boolean} props.disabled If true, the component will be disabled. *Default value false*
* @param {Boolean} props.autoSelectFirst If true, the component will select the selectable option if there is only one. *Default value true*
* @param {String} props.minWidth SmartSelect component minWidth. *Default value 5rem*
* @param {Function} props.onAddClicked Function to be called when add button is clicked. If this is specified the content dialog wont be used
* @param {'small'|'medium'} props.size Component size
* @param {String} props.width SmartSelect component width. *Default undefined*
* @param {String} props.helperText Helper text that will be shown on the textfield. *Default value is empty*
*/
const SmartSelect = ({
  selectableOptions,
  value = null,
  disableClearable = true,
  onChange,
  label,
  error,
  content,
  setReloadAutocompleteOptions,
  multiple = false,
  functionToChangeTheValueWhenAddANewElement,
  readOnly = false,
  addButtonVisible = false,
  disabled = false,
  autoSelectFirst = true,
  minWidth = '5rem',
  onAddClicked = null,
  size = 'medium',
  width,
  helperText = '',
  fontSize = 'initial'
}) => {
  const { t_ } = useSmartTranslation()

  const title = { render: null }
  /**
   * Select All constant
   * @constant
   * @type {string}
   * @default  Select All
   */
  const SELECT_ALL = t_('Select All')
  const [openDialog, setOpenDialog] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [firstTimeAutoSelect, setFirstTimeAutoSelect] = useState(true)
  const addButton = useCallback(({ children, ...other }) => {
    const handleOpen = () => {
      if (onAddClicked !== null) {
        onAddClicked()
        return
      }
      setOpenDialog(true)
    }
    return (

      <Paper {...other}>
        {children}
        {addButtonVisible
          ? (
            <Button
              fullWidth
              sx={{ borderTopWidth: 1, borderTopColor: theme.palette.primary.main, borderTopStyle: 'solid', backgroundColor: theme.palette.background.paper }}
              onClick={() => { handleOpen() }}
              onMouseDown={event => {
                event.preventDefault()
              }}
            >
              <AddCircleOutlineRounded color='primary' /> {t_('ADD RECORD')}
            </Button>
            )
          : null}
      </Paper>
    )
  }, [addButtonVisible, onAddClicked, t_])

  const handleOptionsReload = () => {
    setReloadAutocompleteOptions(true)
    setOpenDialog(false)
    functionToChangeTheValueWhenAddANewElement()
  }

  const displayData = () => {
    let result = null
    if (multiple === false) {
      if (value !== '' && value !== undefined) {
        result = value
      }
    } else {
      result = []
      if (value !== '' && value !== undefined) {
        // To avoid blinking
        result = value.sort()
      }
    }
    return result
  }

  const options = () => {
    const sortedOptions = selectableOptions?.sort(function (a, b) {
      return a?.toLowerCase()?.localeCompare(b?.toLowerCase())
    })
    if (multiple) {
      let returnValue = []
      returnValue[0] = SELECT_ALL

      returnValue = returnValue.concat(sortedOptions)
      return returnValue
    }
    return sortedOptions
  }

  const onChangeMultiSelectedValue = (newValue) => {
    let newSelectedValue = []
    if (newValue.filter(elem => elem === SELECT_ALL).length === 1) {
      if (selectableOptions.length === (newValue.length - 1)) {
        newSelectedValue = []
      } else {
        newSelectedValue = newSelectedValue.concat(selectableOptions)
      }
    } else {
      newSelectedValue = newValue.filter(elem => elem !== SELECT_ALL)
    }; onChange(newSelectedValue)
  }

  const checkSelectAll = () => {
    let result
    if (value.length === (options().length - 1) && value.length >= 1) {
      result = true
    } else {
      result = false
    }
    return result
  }

  useEffect(() => {
    if (autoSelectFirst && !multiple && (value === '' || value == null) && selectableOptions?.length === 1 && firstTimeAutoSelect) {
      onChange(selectableOptions[0])
      setFirstTimeAutoSelect(false)
    }
  }, [autoSelectFirst, multiple, value, selectableOptions, onChange, firstTimeAutoSelect])

  useEffect(() => {
    if ((value === null || value === '') && !firstTimeAutoSelect) {
      setFirstTimeAutoSelect(true)
    }
  }, [value, firstTimeAutoSelect])

  const onBlur = (event) => {
    if (multiple && value?.find(val => val === inputValue) !== null) {
      setInputValue('')
    }
    event.preventDefault()
  }
  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Autocomplete
        size={size}
        disableCloseOnSelect={multiple}
        freeSolo={readOnly}
        popupIcon={null}
        disabled={disabled}
        limitTags={2}
        readOnly={readOnly}
        multiple={multiple}
        disableClearable={disableClearable}
        options={options()}
        value={displayData()}
        inputValue={multiple ? inputValue : undefined}
        onInputChange={multiple ? (event, inputValue) => { if (multiple && event !== null) setInputValue(inputValue) } : undefined}
        autoComplete
        autoHighlight
        includeInputInList
        fullWidth
        renderOption={(props, option, { selected }) => (
          multiple
            ? (
              <li
                {...props}
                className='multiselect-option'
              >
                <Checkbox checked={option === SELECT_ALL ? checkSelectAll() : selected} />
                {option}
              </li>)
            : <div {...props} style={{ fontSize }}>{option}</div>
        )}
        onChange={(event, newValue) => { multiple ? onChangeMultiSelectedValue(newValue) : onChange(newValue) }}
        renderInput={(params) => <TextField
          helperText={helperText} onBlur={onBlur} variant={readOnly ? 'standard' : 'outlined'} {...params} InputProps={readOnly ? { ...params.InputProps, readOnly: true, disableUnderline: true } : { ...params.InputProps, readOnly: false, style: { paddingRight: '1.7rem', width, minWidth, fontSize } }} error={error} label={label} InputLabelProps={{
            shrink: true,
            sx: { fontSize, '&.MuiOutlinedInput-notchedOutline': { fontSize } }
          }}
                                 />}
        PaperComponent={addButton}
      />
      {openDialog ? <SmartDialog title={title} setOpen renderComponent={content} close accept={false} cancel={false} closeCallback={handleOptionsReload} /> : null}
    </div>
  )
}

SmartSelect.propTypes = {
  selectableOptions: Proptypes.arrayOf(Proptypes.string),
  value: Proptypes.oneOfType([Proptypes.arrayOf(Proptypes.string).isRequired, Proptypes.string.isRequired]),
  disableClearable: Proptypes.bool,
  onChange: Proptypes.func,
  label: Proptypes.string,
  error: Proptypes.bool,
  content: Proptypes.object,
  multiple: Proptypes.bool,
  functionToChangeTheValueWhenAddANewElement: Proptypes.func,
  readOnly: Proptypes.bool,
  addButtonVisible: Proptypes.bool,
  disabled: Proptypes.bool,
  autoSelectFirst: Proptypes.bool,
  minWidth: Proptypes.string,
  onAddClicked: Proptypes.func,
  size: Proptypes.string,
  width: Proptypes.string,
  helperText: Proptypes.string
}
export default SmartSelect
