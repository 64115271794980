import React, { useEffect, useReducer } from "react";
import { apiGet, apiPost } from "../generic/Api_Functions";
import PasswordDialog from "./password/PasswordDialog";
import { Alert, Snackbar } from "@mui/material";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";
import ChecklistDialog from "./checklist/ChecklistDialog";

/**
 * CheckDialog component. This will show the password window and the checklist after
 * @param {Object} props 
 * @param {Boolean} props.open If the dialog should be open 
 * @param {Function} props.onClose Function called when the dialog must be closed 
 * @param {Boolean} props.virtualWareMode If true virtualware endpoints will be used and password wont be required. False by default
 * @param {Function} props.onAccept This method will be called when all steps (password, checklist) has been accepted. If null the cell will be set to AUTOMATIC. Null by default
 * @returns 
 */
const CheckDialog = ({ open, onClose, virtualWareMode = false, onAccept = null }) => {

  const onDialogStatus = (state, action) => {
    if (action === "OPEN") {
      return { passwordDialogOpen: true, checkListOpen: false, snackbar: false };
    } else if (action === "CLOSE") {
      return { ...state, passwordDialogOpen: false, checkListOpen: false };
    } else if (action === "PASWORD_OK") {
      return { passwordDialogOpen: false, checkListOpen: true, snackbar: false };
    } else if (action === "CHECKLIST_OK") {
      return { ...state, checkListOpen: false, snackbar: true }
    } else if (action === "CLOSE_SNACKBAR") {
      return { ...state, snackbar: false }
    }
    return state;
  };

  const [dialogStatus, dispatchDialogStatus] = useReducer(onDialogStatus, { passwordDialogOpen: false, checkListOpen: false, snackbar: false });

  useEffect(() => {
    if (open) {
      if (virtualWareMode) {
        dispatchDialogStatus("PASWORD_OK");
      } else {
        dispatchDialogStatus("OPEN");
      }
    }
  }, [open, virtualWareMode]);

  const dispatchClose = () => {
    dispatchDialogStatus("CLOSE");
    onClose();
  }

  const { t_ } = useSmartTranslation();

  const handleChecklistAccept = async () => {
    if (onAccept) {
      onAccept();
    } else {
      if (virtualWareMode) {
        await apiGet("virtualware/startSimulation");
      } else {
        await apiPost("cellGeneralStatus", "AUTOMATIC");
      }
    }
    onClose();
    dispatchDialogStatus("CHECKLIST_OK");
  };


  return (
    <>
      <PasswordDialog open={dialogStatus.passwordDialogOpen} onClose={dispatchClose} onAccept={() => { dispatchDialogStatus("PASWORD_OK") }} />
      <ChecklistDialog open={dialogStatus.checkListOpen} onClose={dispatchClose} onAccept={handleChecklistAccept} virtualWareMode={virtualWareMode} />
      <Snackbar
        open={dialogStatus.snackbar}
        autoHideDuration={6000}
        onClose={() => { dispatchDialogStatus("CLOSE_SNACKBAR") }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={() => { dispatchDialogStatus("CLOSE_SNACKBAR") }} elevation={6} variant="filled" severity="success">
          {t_("CHECKLIST IS APPROVED!")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CheckDialog;