import React from "react";
import { apiGet } from "../../generic/Api_Functions";
import SmartMateriaUITable from "../../generic/SmartMateriaUITable";
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const Presetting = () => {
  const { t_ } = useSmartTranslation();

  const columns = [
    { name: t_("Part"), field: "partCode" },
    {
      name: t_("Part Reference"),
      field: "partReferenceName",
    },
    { name: t_("Asset"), field: "deviceName" },
    { name: t_("Date"), field: "presetDate", type: "datetime" },
    { name: "X (μ)", field: "presetX" },
    { name: "Y (μ)", field: "presetY" },
    { name: "Z (μ)", field: "presetZ" },
    { name: "A (μ)", field: "presetA" },
    { name: "B (μ)", field: "presetB" },
    { name: "C (μ)", field: "presetC" },
  ];

  const sortOptions = [{
    field: 'presetDate',
    direction: -1
  }]

  const fetchFunction = async (pagination) => {
    return await apiGet("presets", pagination);;
  };

  const title = t_("Preset");
  return (
    <SmartMateriaUITable
      columns={columns}
      title={title}
      dataFetch={fetchFunction}
      pageable={true}
      sortOptions={sortOptions}
    />
  );
};

export default Presetting;
