const icoStatusStyle = {
  totalHeight: {
    height: '100%',
  },
  minWidth: {
    minWidth: '3rem'
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '3.2rem',
    gap: '0.625rem'
  },
  iconFontSize: {
    fontSize: '3rem'
  },
  nonOperativeIconColor: {
    color: '#cd6510'
  },
  noStatus: {
    minWidth: "3rem"
  }
}
export default icoStatusStyle
