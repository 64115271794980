import { ExpandMoreOutlined } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Collapse, Divider, Typography } from '@mui/material'
import SmartPaletteItem from './SmartPaletteItem'
import { useSmartTranslation } from '../hooks/useSmartTranslation'
import { useMemo, useState } from 'react'
import SmartSearch from '../formComponent/Search'
import { TransitionGroup } from 'react-transition-group'

const SmartPalette = ({
  sections,
  renderItem = ({ section, item, label, dragProperties, disabled, onClick, selected, checked, onCheckedChange }) =>
    <SmartPaletteItem
      key={section.id + '-' + label}
      label={label}
      dragProperties={dragProperties}
      disabled={disabled}
      onClick={onClick}
      selected={selected}
      checked={checked}
      onCheckedChange={onCheckedChange}
    />,
  getLabel = (section, item) => item?.name,
  isItemDisabled = (section, item) => false,
  areSameItem = (section1, item1, section2, item2) => section1?.id === section2?.id && item1?.id === item2?.id,
  filterItem = (section, item, filter) => { return getLabel(section, item).toLowerCase().includes(filter) },
  getDragProperties = (section, item) => { return false },
  onItemClick = (section, item) => {},
  isItemChecked = (section, item) => {},
  onCheckedChange = (section, item, checked) => {},
  selectedSection = null,
  selectedItem = null,
  sx = {}
}) => {
  const { t_ } = useSmartTranslation()
  const [filter, setFilter] = useState('')

  const getItems = (section) => {
    if (!section.items || section.items.length === 0) {
      return <Typography color='error'>{t_('Not available')}</Typography>
    }
    return (
      <TransitionGroup style={{ display: 'flex', flexDirection: 'column', gap: '0.2rem' }}>
        {section.items.map((item) =>
          <Collapse key={section.id + '-' + getLabel(section, item)}>
            {renderItem({
              section,
              item,
              label: getLabel(section, item),
              dragProperties: getDragProperties(section, item),
              disabled: isItemDisabled(section, item),
              onClick: () => onItemClick(section, item),
              selected: areSameItem(selectedSection, selectedItem, section, item),
              checked: isItemChecked(section, item),
              onCheckedChange: (checked) => onCheckedChange(section, item, checked)
            })}
          </Collapse>
        )}
      </TransitionGroup>
    )
  }

  const filteredSections = useMemo(() => {
    if (!filter || filter.length === 0) {
      return sections
    }
    return sections?.map((section) => {
      const lowerCaseFilter = filter.toLowerCase()
      const newSection = { ...section }
      newSection.items = newSection.items?.filter((item) => filterItem(section, item, lowerCaseFilter))
      return newSection
    })
  }, [sections, filter, filterItem])

  return (
    <Box direction='column' sx={{ ...sx, height: '100%', display: 'grid', gridTemplateRows: 'min-content min-content 1fr', backgroundColor: (theme) => theme.palette.background.card }}>
      <SmartSearch sx={{ padding: 1.5, paddingRight: 2 }} setFilterText={setFilter} setLoadTable={() => {}} loadTable />
      <Divider />
      <Box sx={{ height: '100%', overflow: 'auto', scrollbarColor: (theme) => theme.palette.text.disabled + ' ' + theme.palette.background.card }}>
        {filteredSections.map((section) =>
          <Accordion key={section.id} disableGutters defaultExpanded sx={{ backgroundImage: 'none', backgroundColor: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary sx={{ padding: 1.5, paddingBottom: 0 }} expandIcon={<ExpandMoreOutlined />}><Typography color='primary'>{section.title}</Typography></AccordionSummary>
            <AccordionDetails sx={{ paddingLeft: 1.5, paddingRight: 1.5, paddingTop: 0, paddingBottom: 1.5 }}>{getItems(section)}</AccordionDetails>
          </Accordion>

        )}
      </Box>
    </Box>
  )
}

export default SmartPalette
