import { ManageSearchOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import { apiDelete, apiPost, apiPut, apiGet } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import ToolTypeToolParameter from './ToolTypeToolParameter'
import ToolGroup from './ToolGroup'

import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { SmartDialog } from '../../generic/utilities/SmartDialog'

const ToolTypes = () => {
  const [rowCreatedInOtherSmartTable, setRowCreatedInOtherSmartTable] = useState(null)
  const { t_ } = useSmartTranslation()

  const handleRowCreatedInOtherSmartTable = (row) => {
    setRowCreatedInOtherSmartTable(row)
  }
  const [dialogStatus, setDialogStatus] = useState({ open: false, row: null })

  const columns = [{
    name: t_('Code'),
    field: 'code',
    charLimit: 60,
    mandatory: true,
    unique: true,
    readOnly: true
  },
  {
    name: t_('Name'),
    field: 'name',
    charLimit: 80,
    mandatory: true,
    unique: true,
    readOnly: (toolType) => {
      if (toolType.systemDefault === undefined || toolType.systemDefault === null) {
        return false
      }
      return toolType.systemDefault
    }
  },
  {
    name: t_('Description'),
    field: 'description',
    charLimit: 200
  },
  {
    name: t_('Tool group'),
    field: 'toolGroup.name',
    type: 'smartSelect',
    content: <ToolGroup handleRowCreatedInOtherSmartTable={handleRowCreatedInOtherSmartTable} addModeByDefault />,
    edit: {
      source: async () => {
        return await apiGet('toolGroupsBasicData')
      },
      id: 'id',
      field: 'name'
    },
    mandatory: true,
    readOnly: (toolType) => {
      if (toolType.systemDefault === undefined || toolType.systemDefault === null) {
        return false
      }
      return toolType.systemDefault
    }
  }
  ]

  const actions = {
    edit: {
      fetchFunction: (row) => {
        setRowCreatedInOtherSmartTable(null)
        return apiPut('toolTypes', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        setRowCreatedInOtherSmartTable(null)
        return apiPost('toolTypes', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('toolTypes/' + rows[0].id, rows)
        } else {
          return apiDelete('toolTypesMulti', rows)
        }
      },
      visibilityButton: (toolType) => !toolType.systemDefault
    },
    custom: [
      {
        name: 'ShowToolTypeParameters',
        render: (row) => {
          return (
            <IconButton
              onClick={(event) => {
                setDialogStatus({ open: true, row })
              }}
            >
              <ManageSearchOutlined />
            </IconButton>
          )
        }
      }
    ]
  }

  return (
    <>
      <SmartDialog
        cancel={false}
        closeCallback={() => setDialogStatus({ ...dialogStatus, open: false })}
        accept={false}
        close
        renderComponent={
          <ToolTypeToolParameter toolType={dialogStatus.row} />
      }
        setOpen={dialogStatus.open}
      />

      <SmartMateriaUITable
        columns={columns}
        title={t_('Tool types')}
        dataFetch='toolTypes'
        actions={actions}
        multipleSelection
        rowCreatedInOtherSmartTable={rowCreatedInOtherSmartTable}
        setRowCreatedInOtherSmartTable={setRowCreatedInOtherSmartTable}
        pageable
      />
    </>
  )
}
export default ToolTypes
