import { useSmartTranslation } from '../generic/hooks/useSmartTranslation';
import CalendarReference from './../configuration/calendars/CalendarReference';
import SmartNavigator from './../generic/SmartNavigator';


const MainCalendars = () => {
  const { t_ } = useSmartTranslation();
  const componentsInfo = [
    {
      title: t_("Calendars"),
      component: <CalendarReference />,
    },
  ];

  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  );
};

export default MainCalendars;