import React from 'react'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { apiPost } from '../../generic/Api_Functions'
import { useLocation, useNavigate } from 'react-router-dom'
import ButtonBack from '../../generic/buttons/floatingButtons/ButtonBack'
import { Typography, Stack } from '@mui/material'

export default function PresenceHistory () {
  const location = useLocation()
  const { device } = location.state
  const navigate = useNavigate()

  const getData = async (pagination) => {
    return await apiPost('deviceWorkers/' + device.id, pagination)
  }

  const { t_ } = useSmartTranslation()

  const columns = [
    {
      field: 'code',
      name: t_('Identification')
    },
    {
      field: 'name',
      name: t_('Name')
    },
    {
      field: 'surName',
      name: t_('Surname')
    },
    {
      field: 'dateLogin',
      name: t_('Date start'),
      type: 'dateTimePicker'
    },
    {
      field: 'dateLogout',
      name: t_('Date end'),
      type: 'dateTimePicker'
    },
    {
      field: 'totalPresenceTime',
      name: t_('Presence time')
    }
  ]
  const sortOptions = {
    default: {
      field: 'dateLogout',
      direction: -1
    }
  }
  return (
    <Stack>
      <SmartMateriaUITable
        columns={columns}
        title={<Typography color='secondary'>{t_('History')} - {device.name}</Typography>}
        dataFetch={getData}
        pageable
        sortOptions={sortOptions}
      />
      <div style={{ width: '100%', float: 'right' }}>
        <ButtonBack buttonAction={() => navigate('/compass/presence')} />
      </div>
    </Stack>
  )
}
