import { Stack } from "@mui/system";
import { Button, Typography, ButtonGroup, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useState } from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarReferenceLinkComponent from './CalendarReferenceLinkComponent';
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const EditCalendarEventsToolbar = (props) => {

  const {
    calendarReference,
    setDatesInfo,
    onViewChanged,
    onDataRefreshRequired,
    onDateChange,
    isLinked,
    calendarId,
  } = props;

  const [currentView, setCurrentView] = useState("timeGridWeek");

  const { t_ } = useSmartTranslation();

  const changeView = async (newView) => {
    // Dont allow deselecing view
    if (newView == null) {
      return;
    }
    onViewChanged(newView)
    setCurrentView(newView);
  }



  const currentDate = new Date();
  const disableTodayButton = setDatesInfo === null || (setDatesInfo.start < currentDate && setDatesInfo.end > currentDate);

  return (
    <>
      <Stack direction="row-reverse" justifyContent="space-between" alignItems="flex-end">


        <Stack direction="row" gap={4} alignItems="center">

          <Typography variant="h4" sx={{ fontSize: "1.5rem" }}>{setDatesInfo !== null ? setDatesInfo.view.title : null}</Typography>

          <ToggleButtonGroup exclusive={true} value={currentView} onChange={(event) => changeView(event.target.value)} size="small">
            <ToggleButton value="multiMonthYear" >{t_("Year")}</ToggleButton>
            <ToggleButton value="dayGridMonth">{t_("Month")}</ToggleButton>
            <ToggleButton value="listWeek" >{t_("Agenda")}</ToggleButton>
            <ToggleButton value="timeGridWeek" >{t_("Week")}</ToggleButton>
          </ToggleButtonGroup>

          <ButtonGroup>
            <Button variant="outlined" onClick={() => onDateChange("prev")} ><KeyboardArrowLeftIcon /></Button>
            <Button variant="outlined" disabled={disableTodayButton} onClick={() => onDateChange("today")} >{t_("Today")}</Button>
            <Button variant="outlined" onClick={() => onDateChange("next")} ><KeyboardArrowRightIcon /></Button>
          </ButtonGroup>
        </Stack>

        {calendarReference ?
          <CalendarReferenceLinkComponent
            calendarReference={calendarReference}
            isLinked={isLinked}
            calendarId={calendarId}
            onDataRefreshRequired={onDataRefreshRequired} />
          : null}

      </Stack>



    </>
  );
};

export default EditCalendarEventsToolbar;