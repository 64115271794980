import React, { useEffect, useMemo, useReducer, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { apiGet, apiPut } from '../../generic/Api_Functions'
import { useLocation, useNavigate } from 'react-router-dom'
import { CompassButton } from '../Common/CompassButton'
import { Alert, Collapse, TextField } from '@mui/material'
import CompassWorkerSelector from '../Common/CompassButton/CompassWorkerSelector'
import CompassWindow from '../Common/CompassWindow/CompassWindow'
import CompassDialog from '../Common/CompassDialog/CompassDialog'
import { Exposure, ListAlt } from '@mui/icons-material'
import CompassFabricationOrderSelector from '../Common/CompassFabricationOrderSelector/CompassFabricationOrderSelector'

export default function ProductionState () {
  const location = useLocation()
  const { row, device, urlBack } = location.state
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const navigate = useNavigate()
  const { t_ } = useSmartTranslation()
  const [errorMessage, setErrorMessage] = useState({ show: false })
  const [reasonTypes, setReasonTypes] = useState([])
  const [reasons, setReasons] = useState(null)
  const [openReportQuantityDialog, setOpenReportQuantityDialog] = useState(false)
  const [cancelledFabricationOrders, setCancelledFabricationOrders] = useState([])
  const [finishedFabricationOrders, setFinishedFabricationOrders] = useState([])
  const moreFabricationOrders = useMemo(() => {
    return [
      ...cancelledFabricationOrders.map(obj => ({ ...obj, show: false })),
      ...device.interruptedFabricationOrderList.map(obj => ({ ...obj, show: true })),
      ...device.nextFabricationOrders.map(obj => ({ ...obj, show: false })),
      ...finishedFabricationOrders.map(obj => ({ ...obj, show: false }))]
  }, [cancelledFabricationOrders, finishedFabricationOrders, device])

  const onDispatchSelectedData = (state, action) => {
    if (action.type === 'set_worker') {
      return { ...state, worker: action.value }
    }
    if (action.type === 'set_status') {
      return { ...state, status: action.value, reason: null, reasonType: null }
    }
    if (action.type === 'set_fo') {
      return { ...state, fo: action.value, moreFabricationOrderOpen: false, reason: null }
    }
    if (action.type === 'set_more_fabrication_order_open') {
      return { ...state, moreFabricationOrderOpen: action.value }
    }
    if (action.type === 'set_reason_type') {
      return { ...state, reasonType: action.value, reason: null }
    }
    if (action.type === 'set_reason') {
      return { ...state, reason: action.value }
    }
    if (action.type === 'set_comment') {
      return { ...state, comment: action.value }
    }
    return state
  }

  const [selectedData, dispatchSelectedData] = useReducer(onDispatchSelectedData, { deviceFabricationOrderId: null, worker: null, fo: null, status: null, reasonType: null, reason: null, comment: null, moreFabricationOrderOpen: false })
  const [staticStatus, setStaticStatus] = useState(null)

  useEffect(() => {
    const loadStatus = async () => {
      const status = await apiGet('compass/deviceFabricationOrderStatus/' + device.id + '/' + selectedData.fo.id + '/' + selectedData.fo.jobReferenceId)
      dispatchSelectedData({ type: 'set_status', value: status })
      setStaticStatus(status)
    }
    if (!selectedData.fo) {
      return
    }
    loadStatus()
  }, [selectedData.fo, device.id])

  useEffect(() => {
    if (!row) {
      return
    }
    const allFo = moreFabricationOrders.concat(device.currentFabricationOrders)
    const selected = allFo.find((item) => item.id === row.fabricationOrder.id && item.jobReferenceId === row.jobReference.id)
    if (selected) {
      dispatchSelectedData({ type: 'set_fo', value: selected })
    }
  }, [row, moreFabricationOrders, device])

  useEffect(() => {
    const getCancelledFO = async () => {
      const response = await apiGet('compass/cancelledDeviceFabricationOrder/' + device.id)
      setCancelledFabricationOrders(response)
    }
    getCancelledFO()
  }, [device])

  useEffect(() => {
    const getFinishedFO = async () => {
      const response = await apiGet('compass/finishedDeviceFabricationOrder/' + device.id)
      setFinishedFabricationOrders(response)
    }
    getFinishedFO()
  }, [device])

  const getReasonTypes = async () => {
    const response = await apiGet('reasonTypesShortData')
    setReasonTypes(response)
  }
  useEffect(() => {
    getReasonTypes()
  }, [])

  useEffect(() => {
    const getReasonTypes = async () => {
      const response = await apiGet('reasonTypesByOFStatus/' + selectedData.status)
      setReasonTypes(response)
    }

    if (selectedData.status !== null) {
      getReasonTypes()
    }
  }, [selectedData.status])

  const goBack = () => {
    navigate(urlBack, { state: { device } })
  }
  const save = async (returnBack) => {
    const data = { device: { id: device.id }, fabricationOrder: { id: selectedData.fo.id }, status: selectedData.status, comment: selectedData.comment, worker: selectedData.worker.id, jobReference: { id: selectedData.fo.jobReferenceId }, reason: selectedData.reason ? { id: selectedData.reason.id } : null }
    await apiPut('compass/deviceFabricationOrder', data).then(() => { returnBack && goBack() }).catch((e) => {
      setMessage(e.message)
      setOpen(true)
    })
  }
  const formLabelStyle = {
    fontSize: '2rem'
  }

  useEffect(() => {
    const getReasonsByType = async () => {
      const response = await apiGet('reasons/byType/' + selectedData.reasonType.id)
      if (response.length === 1) {
        dispatchSelectedData({ type: 'set_reason', value: response[0] })
      }
      setReasons(response)
    }
    if (!selectedData.reasonType) {
      return
    }
    getReasonsByType()
  }, [selectedData.reasonType])

  const handleReportQuantityCancel = () => {
    setOpenReportQuantityDialog(false)
    save(true)
  }

  const handleReportQuantityAccept = () => {
    setOpenReportQuantityDialog(false)
    save(false)
    navigate('/compass/production/quantity/report/' + device.id, { state: { urlBack: '/compass/production', device, selectedFabricationOrder: selectedData.fo } })
  }
  const handleAcceptButton = () => {
    if (selectedData.status === 'WAITING') {
      setMessage(t_('Choose a status to change'))
      setOpen(true)
      return
    }
    if (selectedData.worker === null) {
      setMessage(t_('No worker selected for the required action'))
      setOpen(true)
      return
    }
    if (selectedData.status !== 'ACTIVE' && selectedData.status !== 'WAITING' && selectedData.reason === null) {
      setMessage(t_('A reason is required for this status change'))
      setOpen(true)
      return
    }
    if (selectedData.status !== 'ACTIVE' && selectedData.status !== 'WAITING') {
      setOpenReportQuantityDialog(true)
    } else {
      save(true)
    }
  }

  return (
    <>
      <CompassWindow
        message={errorMessage}
        onCloseMessage={() => setErrorMessage({ ...errorMessage, open: false })}
        onClose={goBack}
        renderTitle={<Typography color='primary' sx={{ textAlign: '', padding: '5px 25px ' }}>{t_('Status')} <span style={{ color: '#FFFFFF', marginLeft: '5px' }}>{t_(staticStatus)}</span></Typography>}
        render={
          <>
            <Box>
              <Collapse in={open}>
                <Alert
                  variant='filled' severity='error' style={{ color: 'white' }}
                  action={
                    <IconButton aria-label='close' color='inherit' size='small' onClick={() => { setOpen(false) }}>
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
              }
                  sx={{ mb: 2 }}
                >
                  {message}
                </Alert>
              </Collapse>
            </Box>
            <Box>
              <Stack spacing={2} justifyContent='center' alignItems='left'>
                <CompassWorkerSelector workers={device?.workers} selectedWorker={selectedData.worker} onChange={(worker) => dispatchSelectedData({ type: 'set_worker', value: worker })} />
              </Stack>
            </Box>
            <Box>
              <Stack spacing={2} justifyContent='center' alignItems='left'>
                <Grid container justifyContent='space-between' alignItems='left' spacing={1}>
                  <Grid item xs={12} style={{ marginTop: '10px', textAlign: 'left', marginBottom: '20px' }}>
                    <Typography sx={{ marginBottom: '1em' }} color='primary'>{t_('Fabrication orders')}:</Typography>
                    <CompassFabricationOrderSelector
                      open={selectedData.moreFabricationOrderOpen}
                      setOpen={(open) => dispatchSelectedData({ type: 'set_more_fabrication_order_open', value: open })}
                      fabricationOrder={selectedData.fo}
                      currentFabricationOrders={device.currentFabricationOrders}
                      moreFabricationOrders={moreFabricationOrders}
                      onChange={(fo) => dispatchSelectedData({ type: 'set_fo', value: fo })}
                    />
                    <Typography xs={12} sx={{ marginTop: '1em' }} color='primary'>{t_('Change status')}:</Typography>
                  </Grid>
                  {['ACTIVE', 'INTERRUPTED', 'CANCELLED', 'FINISHED'].map((item) => {
                    return (
                      <Grid item xs style={{ height: '6rem' }} key={item}>
                        <CompassButton
                          isSelected={item === selectedData.status}
                          label={t_(item)}
                          onClick={() => dispatchSelectedData({ type: 'set_status', value: item })}
                          color={item === 'ACTIVE' ? 'primary' : item === 'CANCELLED' ? 'warning' : 'secondary'}
                          isSelectedColor={item === 'ACTIVE' ? 'primary' : item === 'CANCELLED' ? 'warning' : 'secondary'}
                          disabled={!selectedData.status}
                          labelStyle={{
                            color: !selectedData.status ? 'grey' : 'secondary'
                          }}
                        />
                      </Grid>
                    )
                  })}

                </Grid>
                {selectedData.status && (selectedData.status !== 'ACTIVE' && selectedData.status !== 'WAITING')
                  ? (
                    <>
                      <Grid container justifyContent='space-between' alignItems='center' spacing={1}>
                        <Grid item xs={12}>
                          <Typography color='primary' sx={{ textAlign: '' }}>{t_('Reasons Types')}:</Typography>
                        </Grid>
                        {reasonTypes?.map(type => (
                          <Grid
                            item
                            xs={4}
                            key={type.id}
                            style={{ height: '6rem' }}
                          >
                            <CompassButton
                              isSelected={selectedData.reasonType && selectedData.reasonType.id === type.id}
                              label={type.name}
                              color='secondary'
                              isSelectedColor='primary'
                              onClick={() => { dispatchSelectedData({ type: 'set_reason_type', value: type }) }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      <Grid container justifyContent='space-between' alignItems='center' spacing={1}>
                        <Grid item xs={12}>
                          <Typography color='primary' sx={{ textAlign: '' }}>{t_('Reasons')}:</Typography>
                        </Grid>
                        {selectedData.reasonType && reasons != null && reasons.length > 0 && reasons.map(reason => (
                          <Grid
                            item
                            xs={4}
                            key={reason.id}
                            style={{ height: '6rem' }}
                          >
                            <CompassButton
                              isSelected={reasons.length === 1 || (selectedData.reason && selectedData.reason.id === reason.id)}
                              label={reason.name}
                              color='secondary'
                              isSelectedColor='primary'
                              onClick={() => {
                                dispatchSelectedData({ type: 'set_reason', value: reason })
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      {selectedData.reason ? <TextField label={t_('Comment')} variant='outlined' sx={{ width: '100%', marginTop: '2rem' }} multiline value={selectedData.comment} onChange={(event) => dispatchSelectedData({ type: 'set_comment', value: event.target.value })} /> : null}

                    </>
                    )
                  : null}

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ height: '6rem', marginTop: '20px' }}>
                  <Grid item xs={6} style={{ paddingLeft: '0' }}>
                    <CompassButton
                      label={t_('ACCEPT')}
                      labelStyle={{
                        fontSize: '2rem',
                        color: !selectedData.status ? 'grey' : 'primary'
                      }}
                      disabled={!selectedData.status}
                      onClick={handleAcceptButton}
                      color='primary'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CompassButton label={t_('CANCEL')} labelStyle={formLabelStyle} onClick={goBack} color='secondary' />
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </>
        }
      />
      <CompassDialog
        openDialog={openReportQuantityDialog}
        handleClose={handleReportQuantityCancel}
        handleAccept={handleReportQuantityAccept}
        acceptLabel={t_('Quantity')}
        cancelLabel={t_('No')}
        title={{ icon: <ListAlt color='primary' sx={{ fontSize: '1.5rem' }} />, render: <Typography variant='h5'>{t_('FO')}</Typography> }}
        body={<Stack><Typography> {t_('Do you want to report new quantities?')}</Typography></Stack>}
        showExitButton
        handleExit={() => { setOpenReportQuantityDialog(false) }}
        acceptIcon={<Exposure />}
      />
    </>
  )
};
