import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React from 'react'

const SmartSortableItem = (({
  itemId, item, index, renderItem: RenderItem
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transition,
    transform,
    isDragging,
    isSorting
  } = useSortable({ id: itemId })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    cursor: isSorting ? 'grabbing' : 'grab',
  }


  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
    >
      <RenderItem item={item} index={index} />
    </div>)
})

export default SmartSortableItem