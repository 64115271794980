const getServerAddress = () => {
  //return 'PUT A CUSTOM URL HERE'
  return process.env.REACT_APP_ENDPOINT_URL ?? ''
}

const GlobalConfig = {
  'back-end_address': getServerAddress() + '/endpoints/',
}

export default GlobalConfig
