import SmartMateriaUITable from './../../generic/SmartMateriaUITable';
import { apiDelete, apiPost } from '../../generic/Api_Functions';
import { apiGet } from './../../generic/Api_Functions';
import AddElementQuantity from './../../generic/smartAddElement/AddElementQuantity';
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation';

const FixtureAssembly = (props) => {

  let { referenceId, referenceName } = props;
  const { t_ } = useSmartTranslation();

  const columns = [
    {
      name: t_("Code"),
      field: "code",
      edit: false,
      charLimit: 60,
      mandatory: true,
      unique: true,
      readonly: true,
    },
    {
      name: t_("Name"),
      field: "name",
      charLimit: 80,
      mandatory: true,
    },
    {
      name: t_("Description"),
      field: "description",
      charLimit: 200,
    },
    {
      name: t_("Location"),
      field: "device.name",
      type: "select",
      edit: {
        source: async () => {
          return await apiGet("devicesBasicData");
        },
        id: "id",
        field: "name",
      },
    },
  ];

  const actions = {
    create: {
      fetchFunction: null,
      render: (extraFunctions) => {
        return (
          <AddElementQuantity
            title={t_("ADD RECORD")}
            endpoint={"fixtureAssembly/create"}
            parameter={referenceId}
            referenceName={referenceName}
            onAlert={extraFunctions.showAlert}
          />)
      }
    },
    edit: {
      fetchFunction: (row) => {
        return apiPost("fixtureAssembly", row);
      },
    },
    delete: {
      fetchFunction: (pagination) => {
        if (Array.isArray(pagination)) {
          // When one selected instead of the pagination we get an array with a unique element that is the row
          let newPagination = {
            "page": 0,
            "size": -1,
            "columns": [],
            "genericSearch": "",
            "rows": [],
            "allSelected": false,
            "primaryKeys": [
              "id"
            ],
            "sortKey": [],
            "sortMode": []
          }
          pagination.map((item) => newPagination.rows.push(item.id));
          pagination = newPagination;
        }
        return apiDelete("fixtureAssembly/" + referenceId, pagination);
      },
    },
  };

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_("Fixture Assembly") + " - " + referenceName}
      dataFetch={referenceId ? "fixtureAssembly/" + referenceId : []}
      actions={actions}
      multipleSelection
      pageable={true}
    />
  );
}

export default FixtureAssembly;