import { useState, useCallback } from 'react'

const useToggle = (initial = false) => {
  const [value, setValue] = useState(initial)

  const setToggle = useCallback(() => {
    setValue(prev => !prev)
  }, [])

  return [value, setToggle]
}

export default useToggle
