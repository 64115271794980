import React from 'react'
import SmartNavigator from '../../generic/SmartNavigator'
import FixtureReference from './FixtureReference'
import FixtureAssemblyReference from './FixtureAssemblyReference'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

export default function MainFixture (props) {
  const activePath = props.activePath
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('Fixture'),
      component: <FixtureReference />,
      to: '/resources/fixtureReference'
    },
    {
      title: t_('Fixture assembly'),
      component: <FixtureAssemblyReference />,
      to: '/resources/fixtureAssemblyReference'
    }
  ]

  return <SmartNavigator componentsInfo={componentsInfo} selected={activePath} />
}
