import React, { useEffect, useReducer, useRef, useState } from 'react'
import { apiFile, apiGet } from '../../generic/Api_Functions'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { Button, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import { AddCircleRounded, CheckCircleRounded } from '@mui/icons-material'
import AlertUI from '../../generic/AlertUI'
import WarningIcon from '@mui/icons-material/Warning'
import Proptypes from 'prop-types'

// Import typedefs from another file
/**
 * @typedef {import('../../generic/utilities/objectStructures/globalStructures').IdCodeName} IdCodeName IdCodeName dto
 * @typedef {import('../../generic/utilities/objectStructures/documentWindow/document').CreateDocument} CreateDocument Create document dto
 * @typedef {import('../../generic/utilities/objectStructures/documentWindow/document').CreateDocumentAction} CreateDocumentAction Create document action dto
 */

/**
 *
 * @param {Object} props Props object
 * @param {string} props.resourceType Resource type
 * @param {IdCodeName} props.reference Reference
 * @param {string[]} props.usedDocumentNames Used document names
 * @returns
 */
const CreateDocument = ({ resourceType, reference, usedDocumentNames }) => {
  /** @type {[IdCodeName[], React.Dispatch<SetStateAction<IdCodeName[]>>]} */
  const [documentTypes, setDocumentTypes] = useState(null)
  /** @type {[IdCodeName, React.Dispatch<SetStateAction<IdCodeName>>]} */
  const [showDialog, setShowDialog] = useState(false)

  /** Method used to update editingData variable
   * @param {CreateDocument} state current editing data object
   * @param {CreateDocumentAction} action New values to be updated on programEditData object
   * @returns state object
   */
  const onDispatchEditingData = (state, action) => {
    const newState = { ...state }
    switch (action.type) {
      case 'SET_DOCUMENT_NAME':
        newState.documentName = action.currentDocumentName
        return newState
      case 'SET_DESCRIPTION':
        newState.description = action.currentDescription
        return newState
      case 'SET_DOCUMENT_TYPE':
        newState.documentType = action.currentDocumentType
        return newState
      case 'RESET_VALUES':
        newState.description = null
        newState.documentType = null
        newState.documentName = null
        return newState
      default:
        return newState
    }
  }

  /** @type {[CreateDocument, React.Dispatch<CreateDocumentAction>]} */
  const [data, dispatchdata] = useReducer(onDispatchEditingData,
    {
      description: null,
      documentType: null,
      documentName: null
    }
  )

  const { t_ } = useSmartTranslation()
  const fileInputAddRef = useRef()
  const [AlertElement, showAlert] = AlertUI()

  useEffect(() => {
    const fetchData = async () => {
      if (documentTypes === null) {
        const types = await apiGet('documentTypes')
        setDocumentTypes(types)
      }
    }
    fetchData()
  })

  const onAccept = async (event) => {
    await apiFile('document/' + resourceType + '/' + reference.id, data, event.target.files[0]).then(() => {
      setShowDialog(false)
    }).catch((error) => {
      showAlert({
        title: '',
        message: error.message,
        severity: error.name
      })
    })
    dispatchdata({ type: 'RESET_VALUES' })
  }

  const checkDocumentNameError = () => {
    if (data.documentName === '' || usedDocumentNames.includes(data.documentName)) {
      return true
    }
    return false
  }

  return (
    <>
      <SmartDialog
        cancelCallback={() => { dispatchdata({ type: 'RESET_VALUES' }); setShowDialog(false) }}
        accept={false}
        renderCustomButtons={
          <>
            <Button
              color='primary' onClick={() => {
                let error = false
                let message = t_('Select a document type')

                if (data.documentType == null) {
                  error = true
                }

                if (data.documentName === null) {
                  error = true
                  message = t_('Select a document name')
                }

                if (checkDocumentNameError()) {
                  error = true
                  message = t_('Document with this name already exists')
                }

                if (error) {
                  showAlert({
                    title: '',
                    message,
                    severity: 'warning'
                  })
                  return
                }
                fileInputAddRef.current.click()
              }} variant='outlined'
            >
              <Typography> {t_('ACCEPT')} </Typography>
              <CheckCircleRounded color='primary' sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
            </Button>
            <input
              onClick={(event) => {
                event.target.value = null
              }}
              onChange={(event) => onAccept(event)}
              multiple
              ref={fileInputAddRef}
              type='file'
              hidden
            />
          </>
}
        acceptCallback={() => onAccept()}
        setOpen={showDialog}
        title={{
          icon: <>{AlertElement}<AddCircleRounded color='primary' sx={{ fontSize: '1.5em' }} /></>,
          render:
  <Stack
    direction='row'
    justifyContent='center'
    alignItems='center'
  >
    <Typography variant='h6' color='primary'> {t_('ADD RECORD')}</Typography>
  </Stack>
        }}
        renderComponent={
          <div sx={{ display: 'grid' }}>
            <Stack spacing={2}>
              <Typography component='span'>{'1.-' + t_('Select a document type')}</Typography>
              {documentTypes?.map((documentType, index) =>
                <Button
                  variant='contained'
                  key={index}
                  color={data.documentType ? data.documentType.name === documentType.name ? 'primary' : 'inherit' : 'inherit'}
                  sx={{ color: 'rgba(0, 0, 0, 0.87) !important' }}
                  onClick={() => {
                    dispatchdata({ type: 'SET_DOCUMENT_TYPE', currentDocumentType: documentType })
                  }}
                >
                  {t_(documentType.name)}
                </Button>
              )}
              <Typography component='span'>{'2.-' + t_('Select a document name')}</Typography>
              <TextField
                InputLabelProps={{
                  shrink: true
                }}
                label={checkDocumentNameError()
                  ? (
                    <Tooltip placement='top' title={usedDocumentNames.includes(data.documentName) ? t_('Document with this name already exists') : t_('Requiered *')}>
                      <WarningIcon />
                    </Tooltip>
                    )
                  : t_('Requiered *')}
                onChange={(event) => {
                  dispatchdata({ type: 'SET_DOCUMENT_NAME', currentDocumentName: event.target.value })
                }}
                error={checkDocumentNameError()}
              />
              <Typography component='span'>{'3.-' + t_('Document description (optional)')}</Typography>
              <TextField
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(event) => {
                  dispatchdata({ type: 'SET_DESCRIPTION', currentDescription: event.target.value })
                }}
              />
            </Stack>
          </div>
        }
      />
      <Button
        sx={{ marginLeft: '1em', border: '0px' }}
        color='primary'
        variant='outlined'
        onClick={() => {
          setShowDialog(true)
        }}
      >
        <AddCircleRounded sx={{ fontSize: '1.2em' }} />
        <Typography sx={{ marginLeft: '0.4em', fontSize: '0.875rem' }}>{t_('ADD RECORD')}</Typography>

      </Button>
    </>
  )
}

CreateDocument.propTypes = {
  resourceType: Proptypes.oneOf(['PART', 'PART_REFERENCE']),
  reference: Proptypes.shape({
    id: Proptypes.number,
    code: Proptypes.string,
    name: Proptypes.string
  }),
  usedDocumentNames: Proptypes.arrayOf(Proptypes.string)

}

export default CreateDocument
