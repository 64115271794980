import { Card, CardActions, CardContent, Grid, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { useSmartTranslation } from "../../hooks/useSmartTranslation";

const PositionCard = (props) => {
  const { position, onChange, editMode } = props;
  const { t_ } = useSmartTranslation();
  const dimensions = [
    'X',
    'Y',
    'Z',
    'A',
    'B',
    'C'
  ]

  const positionCoordinateArray = {
    coordinateX: t_('Coordinate'),
    coordinateY: t_('Coordinate'),
    coordinateZ: t_('Coordinate'),
    coordinateA: t_('Coordinate'),
    coordinateB: t_('Coordinate'),
    coordinateC: t_('Coordinate'),
    offsetX: t_('Offset'),
    offsetY: t_('Offset'),
    offsetZ: t_('Offset'),
    offsetA: t_('Offset'),
    offsetB: t_('Offset'),
    offsetC: t_('Offset'),
  }

  const generateDimensionCard = (dimension) => {
    return (<Paper elevation={6}>
      <Card >
        <CardActions style={{ display: 'grid', placeItems: 'center' }}>
          <Typography variant="h7" component="div">
            {dimension}
          </Typography>
        </CardActions>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id={'coordinate' + dimension}
                label={positionCoordinateArray['coordinate' + dimension]}
                type="number"
                value={position['coordinate' + dimension]}
                onChange={(event) => {
                  let positionAux = position;
                  positionAux['coordinate' + dimension] = event.target.value;
                  onChange(event.target.value, position)

                }}
                onBlur={() => {
                  if (isNaN(parseFloat(position['coordinate' + dimension]))) {
                    let positionAux = position;
                    positionAux['coordinate' + dimension] = 0;
                    onChange(0, position)
                  }
                }}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id={'offset' + dimension}
                label={positionCoordinateArray['offset' + dimension]}
                type="number"
                value={position['offset' + dimension]}
                onChange={(event) => {
                  let positionAux = position;
                  positionAux['offset' + dimension] = event.target.value;
                  onChange(event.target.value, position)
                }}
                onBlur={() => {
                  if (isNaN(parseFloat(position['offset' + dimension]))) {
                    let positionAux = position;
                    positionAux['offset' + dimension] = 0;
                    onChange(0, position)
                  }
                }}
                disabled={!editMode}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
    )
  }

  return (
    dimensions.map(dimension => {
      return (< Grid key={"dimension-" + dimension} item xs={2} >{generateDimensionCard(dimension)}</Grid >)
    })
  )
}

export default PositionCard