import { apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const DeviceStatusType = () => {
  const { t_ } = useSmartTranslation()

  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      unique: true,
      readOnly: true
    },
    {
      name: t_('Name'),
      field: 'name',
      unique: true,
      readOnly: true
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    }
  ]

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('statusType', row)
      }
    }
  }

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Device Status types')}
      dataFetch='statusType'
      actions={actions}
      pageable
    />
  )
}

export default DeviceStatusType
