import { DeleteRounded } from '@mui/icons-material'
import { Card, CardContent, Checkbox, FormControlLabel, IconButton, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { memo } from 'react'
import { Handle, NodeToolbar, Position, useReactFlow } from 'reactflow'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'

function TechnologyNode ({ id, data }) {
  const handleSize = '0.6rem'

  const okStyle = { top: '20%', background: 'green', width: handleSize, height: handleSize }
  const noOkStyle = { top: '80%', background: 'red', width: handleSize, height: handleSize }
  const { t_ } = useSmartTranslation()

  const onSelectStyle = {
    border: '2px solid #D0D0D0'
  }

  const { setNodes, getNodes, setEdges } = useReactFlow()

  const onJobNameChange = (newValue) => {
    const newNodeValues = getNodes().map((node) => {
      if (node.id === id) {
        node.data = { ...node.data }
        node.data.jobName = newValue
      }
      return node
    })

    setNodes(newNodeValues)
  }

  const onReworkChange = (newValue) => {
    const newNodeValues = getNodes().map((node) => {
      if (node.id === id) {
        node.data = { ...node.data }
        node.data.rework = newValue
      }
      return node
    })

    setNodes(newNodeValues)
  }

  const onDeleteNode = () => {
    setNodes((node) => node.filter((e) => e.id !== id))
    setEdges((edge) => edge.filter((e) => e.source !== id && e.target !== id))
  }

  return (
    <>
      <NodeToolbar
        position={Position.Top}
      >
        <Card sx={{ backgroundColor: (theme) => theme.palette.background.card }}>
          <CardContent sx={{ paddingBottom: '0px !Important' }}>
            <TextField
              label={t_('Job name')}
              InputLabelProps={{
                shrink: true
              }}
              value={data.jobName}
              onChange={(event) => onJobNameChange(event.target.value)}
            />
            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.rework}
                    onChange={(event) => onReworkChange(event.target.checked)}
                  />
                    }
                label={t_('REWORKED').charAt(0).toUpperCase() + t_('REWORKED').substring(1).toLowerCase()}
              />
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
              >
                <IconButton
                  disabled={data.workflowInUse}
                  color='error' onClick={() => {
                    onDeleteNode()
                  }}
                >
                  <DeleteRounded />
                </IconButton>
              </div>
            </Stack>
          </CardContent>
        </Card>
      </NodeToolbar>
      <div style={data.selected ? onSelectStyle : undefined}>
        <Handle
          id='target'
          type='target'
          className='left'
          position={Position.Left}
          style={{ zIndex: 10000, width: handleSize, height: handleSize }}
          isConnectable={data.isConnectable}
        />
        <Card sx={{ minWidth: '6rem', minHeight: '3rem' }}>
          <CardContent style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0, display: 'inline' }}>
            <Typography component='div'>{data.technologyName}<br /> {
            data.jobName && !data.selected ? <Box sx={{ fontStyle: 'italic', fontSize: 12 }} display='inline'>{data.jobName}</Box> : null
            }
            </Typography>
          </CardContent>
        </Card>
        <Handle
          id='OK'
          type='source'
          style={okStyle}
          position={Position.Right}
          isConnectable={data.isConnectable}
        />
        <Handle
          id='NOT_OK'
          type='source'
          style={noOkStyle}
          position={Position.Right}
          isConnectable={data.isConnectable}
        />
      </div>
    </>
  )
}

export default memo(TechnologyNode)
