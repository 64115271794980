import GlobalConfig from '../generic/GlobalConfig'

import React, { useRef, useState, useEffect } from 'react'

import { Alert, Button, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { CancelRounded, FileDownloadOutlined, StarRateRounded, UploadFileRounded, FiberManualRecordRounded, RectangleRounded } from '@mui/icons-material'
import { apiGet, apiImportFile } from '../generic/Api_Functions'
import { SmartDialog } from '../generic/utilities/SmartDialog'
import createDialogTitle from '../generic/utilities/dialogUtil'
import CloseIcon from '@mui/icons-material/Close'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

const ImportFile = (props) => {
  const { columns, beanParams } = props

  const fileInputRef = useRef()
  const [dialogShow, setDialogShow] = useState(false)
  const [beanParamsActive, setBeanActive] = useState(beanParams !== undefined ? beanParams[0] : '')
  const [fields, setFields] = useState([])
  const [lastErrorMessage, setLastErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [csvSeparator, setCsvSeparator] = useState(';')
  const { t_ } = useSmartTranslation()

  useEffect(() => {
    apiGet('importFields', beanParamsActive.bean).then((result) => {
      setFields(result)
    })
    apiGet('settings/character').then((result) => {
      if (result[0].length !== 0) {
        setCsvSeparator(result[0])
      }
    })
  }, [beanParamsActive])

  const handleChange = ({ target: { files } }) => {
    setLastErrorMessage(false)
    importCSV(files[0])
  }

  const importCSV = async (newFile) => {
    setLoading(true)
    const response = await apiImportFile('import', beanParamsActive.bean, newFile)
      .then(data => {
        setLoading(false)
        if (data.errorCount === 0) {
          setLastErrorMessage({ severity: 'success', message: 'Data imported succesfully. Added ' + data.okCount + ' new rows', showButton: false })
        } else {
          setLastErrorMessage({ severity: 'error', message: 'Some of the data could not be loaded. ' + data.errorCount + '/' + (data.errorCount + data.okCount) + ' failed. Check the error file for details.', errorFileName: data.errorFileName, showButton: true })
        }
      }
      )
      .catch((error) => {
        setLoading(false)
        setLastErrorMessage({ severity: 'error', message: error.message, showButton: false })
      })
    return response
  }

  const handleUploadProgram = (event) => {
    setLastErrorMessage(false)
    console.warn('%cSi has llegado aquí TOP LOVE FOREVER EQUIPO 💖by Kristina ', 'color:violet; font-size:24px')
    fileInputRef.current.click()
  }

  const handleBeanActive = (event, newBeanActiveName) => {
    const newBean = beanParams.find((element) => element.bean.beanName === newBeanActiveName)
    if (newBean) {
      setBeanActive(newBean)
    }
  }

  const handleErrorDownloadButtonClick = () => {
    window.open(GlobalConfig['back-end_address'] + 'import/error_file/' + lastErrorMessage.errorFileName)
  }

  const downloadTemplate = () => {
    let output = ''
    fields.forEach(element => {
      output = output + element.fieldName + csvSeparator
    })
    // remove last ';'
    output = output.substring(0, output.length - 1)

    // Generate blob, url and download file with the content above
    const blob = new Blob([output])
    const fileDownloadUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = beanParamsActive.name + '_template.csv'
    link.href = fileDownloadUrl
    link.click()
  }

  const dialogRender = (event) => {
    let mainContent
    if (loading) {
      return <CircularProgress />
    } else {
      mainContent = (
        <>
          {lastErrorMessage
            ? (
              <Alert
                severity={lastErrorMessage.severity} action={lastErrorMessage.showButton
                  ? (
                    <>
                      <Button color='inherit' size='small' variant='outlined' onClick={handleErrorDownloadButtonClick}>
                        Download error file
                      </Button>
                      <IconButton size='small' onClick={() => { setLastErrorMessage(false) }}><CloseIcon /></IconButton>
                    </>
                    )
                  : null}
              > {lastErrorMessage.message}
              </Alert>
              )
            : null}
          <Grid
            container direction='column'
            justifyContent='center'
            spacing={2}
          >
            <Grid item>
              <ToggleButtonGroup
                value={beanParamsActive.bean.beanName}
                aria-label='text formatting'
                sx={{ bgcolor: 'background.paper' }}
                onChange={handleBeanActive}
                exclusive
              >
                {beanParams !== undefined
                  ? beanParams.map((element) =>
                    <ToggleButton value={element.bean.beanName} aria-label={element.bean.beanName} color='primary' key={'bean-' + element.bean.beanName}>
                      {element.name}
                    </ToggleButton>
                  )
                  : null}
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t_('Column Name')}</TableCell>
                  <TableCell>{t_('Type')}</TableCell>
                  <TableCell>{t_('Format')}</TableCell>
                  <TableCell>{t_('Required')}</TableCell>
                  <TableCell>{t_('Default value')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((data) =>
                  <TableRow key={beanParamsActive.id + '-field-' + data.fieldName}>
                    <TableCell>{data.fieldName}</TableCell>
                    <TableCell>{data.type}</TableCell>
                    <TableCell>{data.format}</TableCell>
                    <TableCell>
                      <div style={{ lineHeight: '0' }}>
                        {data.required && <StarRateRounded />}
                        {['length_x', 'length_y', 'length_z'].includes(data.fieldName)
                          ? (
                            <>
                              <StarRateRounded />
                              <RectangleRounded />
                            </>
                            )
                          : ['diameter', 'length'].includes(data.fieldName)
                              ? (
                                <>
                                  <StarRateRounded />
                                  <FiberManualRecordRounded />
                                </>
                                )
                              : null}
                      </div>
                    </TableCell>
                    <TableCell>{data.defaultValue}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )
    }

    return (mainContent)
  }

  const handleOpenDialog = () => {
    setDialogShow(true)
    setLastErrorMessage(false)
  }

  const handleCloseDialog = () => {
    setDialogShow(false)
  }

  return (
    <>
      <Button
        sx={{ marginLeft: '1em', border: '0px' }}
        color='primary'
        variant='outlined'
        onClick={() => handleOpenDialog()}
        disabled={false}
      >
        <UploadFileRounded sx={{ fontSize: '1.2em' }} />
        <Typography sx={{ marginLeft: '0.4em', fontSize: '0.875rem' }}>{t_('IMPORT FILE')}</Typography>
      </Button>
      <input
        id='changeProgram'
        onClick={(event) => {
          event.target.value = null
        }}
        onChange={handleChange}
        multiple
        ref={fileInputRef}
        type='file'
        hidden
      />
      <SmartDialog
        acceptCallbackArgsArray={[columns]} acceptCallback={() => handleUploadProgram()}
        renderComponent={dialogRender()}
        title={createDialogTitle({ title: t_('Import File'), icon: UploadFileRounded })} setOpen={dialogShow}
        accept={false}
        cancel={false}
        renderCustomButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              sx={{
                border: '1px solid rgba(255, 255, 255, 0.23) !important',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.08) !important'
                }
              }} variant='outlined' onClick={() => { downloadTemplate() }}
            >
              <Typography sx={{ color: 'lightgrey' }}>{t_('TEMPLATE')}</Typography>
              <FileDownloadOutlined sx={{ fontSize: '1.4em', marginLeft: '0.3rem', color: 'lightgrey' }} />
            </Button>
            <Button
              variant='outlined' sx={{
                border: '1px solid rgba(255, 255, 255, 0.23) !important',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.08) !important'
                },
                marginLeft: '5px'
              }} onClick={() => handleCloseDialog()} disabled={loading}
            >
              <CancelRounded sx={{ color: 'lightgrey !important', fontSize: '1.4em', marginRight: '0.3rem' }} />
              <Typography sx={{ color: 'lightgrey' }}>{t_('CANCEL')}</Typography>
            </Button>
            <Button variant='outlined' sx={{ marginLeft: '5px' }} onClick={() => handleUploadProgram()} disabled={loading}>
              <Typography>{t_('IMPORT FILE')} </Typography>
              <UploadFileRounded sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
            </Button>
          </div>
        }
      />
    </>
  )
}
export default ImportFile
