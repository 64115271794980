import { Typography } from '@mui/material'
import CompassWorkerSelector from '../Common/CompassButton/CompassWorkerSelector'
import { CompassButton } from '../Common/CompassButton'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import CompassWindow from '../Common/CompassWindow/CompassWindow'
import { apiGet } from '../../generic/Api_Functions'
import CompassFabricationOrderSelector from '../Common/CompassFabricationOrderSelector/CompassFabricationOrderSelector'

const InspectControlProcedure = () => {
  const location = useLocation()
  const [workerSelected, setWorkerSelected] = useState(null)
  const { device, urlBack } = location.state
  const navigate = useNavigate()
  const { t_ } = useSmartTranslation()
  const [fabricationOrder, setFabricationOrder] = useState(null)
  const [moreFabricationOrdersOpen, setMoreFabricationOrdersOpen] = useState(false)
  const [finishedFabricationOrders, setFinishedFabricationOrders] = useState([])
  const [features, setFeatures] = useState([])
  const [errorMessage, setErrorMessage] = useState({ show: false })
  const formLabelStyle = {
    fontSize: '1.5rem'
  }

  const titleStyle = {
    marginTop: '1.5rem',
    marginBottom: '1.5rem'
  }

  useEffect(() => {
    const updateFeatures = async () => {
      const response = await apiGet('compass/feature/fabricationOrder/' + fabricationOrder.partReferenceId + '/' + fabricationOrder.jobReferenceId)
      setFeatures(response)
    }
    if (!fabricationOrder) {
      return
    }
    updateFeatures()
  }, [fabricationOrder])

  useEffect(() => {
    const getFinishedFabricationOrders = async () => {
      const response = await apiGet('compass/finishedDeviceFabricationOrder/' + device.id)
      setFinishedFabricationOrders(response)
    }
    getFinishedFabricationOrders()
  }, [device])
  const onChange = (newValue) => {
    setMoreFabricationOrdersOpen(false)
    setFabricationOrder(newValue)
  }

  const goBack = () => {
    navigate(urlBack, { state: { device } })
  }

  // Select first fabrication order if only one available
  if (!fabricationOrder && device.currentFabricationOrders.length === 1) {
    setFabricationOrder(device.currentFabricationOrders[0])
  }

  const moreFabricationOrders = finishedFabricationOrders.concat(device.interruptedFabricationOrderList)
  return (
    <CompassWindow
      renderTitle={t_('Control procedure')}
      message={errorMessage}
      onCloseMessage={() => setErrorMessage({ ...errorMessage, open: false })}
      onClose={goBack} render={
        <>
          <CompassWorkerSelector workers={device?.workers} selectedWorker={workerSelected} onChange={(worker) => setWorkerSelected(worker)} />
          <Typography sx={titleStyle} color='primary'>{t_('Fabrication orders')}:</Typography>
          <CompassFabricationOrderSelector
            open={moreFabricationOrdersOpen}
            setOpen={setMoreFabricationOrdersOpen}
            fabricationOrder={fabricationOrder}
            currentFabricationOrders={device.currentFabricationOrders}
            moreFabricationOrders={moreFabricationOrders}
            onChange={onChange}
          />
          <Typography sx={titleStyle} color='primary'>{t_('Features')}:</Typography>

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '1rem' }}>
            {features.map((feature) =>
              <CompassButton
                key={feature.featureId}
                width='auto'
                height='6rem'
                isSelected={false}
                label={feature.featureName}
                onClick={() => {
                  if (!workerSelected) {
                    setErrorMessage({ ...errorMessage, open: true, content: 'Worker must be selected' })
                    return
                  }
                  navigate('/compass/inspect/report', { state: { fabricationOrder, featureId: feature.featureId, worker: workerSelected } })
                }}
                labelStyle={formLabelStyle}
                color='secondary'
                isSelectedColor='primary'
              />
            )}

          </div>

        </>
  }
    />
  )
}

export default InspectControlProcedure
