import { SearchRounded } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const TextSearchField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& placeholder': {
      color: 'white'
    },
    '& fieldset': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto'
      },
      border: 'none'
    },
    '& .MuiOutlinedInput-input': {
      color: 'white',
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '20ch'
      }

    },
    '&:hover fieldset': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
      borderRadius: theme.shape.borderRadius,
      border: 'none'
    },
    '&.Mui-focused fieldset': {
      border: '0.2px solid rgb(94,151,118)'
      // border: 'none',
      // boxShadow: "1px 1px 1px #7FE1A7",
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#DADADA'
    }
  }
}))

const SmartSearch = (props) => {
  const {
    setFilterText,
    setLoadTable,
    loadTable,
    sx = {}
  } = props

  const { t_ } = useSmartTranslation()

  return (
    <TextSearchField
      id='custom-css-outlined-input'
      placeholder={t_('Search...')}
      onChange={(event) => { setLoadTable(!loadTable); setFilterText(event.target.value) }}
      sx={sx}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchRounded style={{ color: 'white' }} />
          </InputAdornment>
        )
      }}
    />

  )
}
export default SmartSearch
