import { CircularProgress, Typography } from '@mui/material'
import { SmartDialog } from '../utilities/SmartDialog'
import Checklist from './CheckList'
import { getWorkflowStatus, setOpenJobsOnLoad } from './ChecklistPartReference'
import { apiGet } from '../Api_Functions'
import { useEffect, useState } from 'react'
import { useSmartInterval } from '../hooks/useSmartInterval'
import { useSmartTranslation } from '../hooks/useSmartTranslation'

const ChecklistDialog = ({ open, onClose, onAccept, virtualWareMode = false }) => {
  const [workflows, setWorkflows] = useState(null)
  const { t_ } = useSmartTranslation()
  const [error, setError] = useState(null)

  useEffect(() => {
    // When opening the dialog reset all data and force a load
    if (open) {
      setWorkflows(null)
      setError(null)
    }
  }, [open])

  useSmartInterval(async () => {
    if (open) {
      const url = virtualWareMode ? 'virtualware/checklist/actual' : 'checklist/actual'
      const data = await apiGet(url)
      data.forEach(workflow => {
        setOpenJobsOnLoad(workflow)
      })
      return data
    }
  }, 1000, (data) => {
    if (open) {
      // If workflow is empty allow checklist
      if (data.length === 0) {
        onAccept()
        return
      }
      if (workflows !== null) {
        // Merge user info to the new workflows
        data.forEach(workflow => {
          const oldWorkflow = workflows.find((item) => item.partReferenceId === workflow.partReferenceId && item.workflowId === workflow.workflowId)
          if (oldWorkflow === null) {
            // Init as new workflow
            setOpenJobsOnLoad(workflow)
          } else {
            // Merge data from previous
            if (workflow.jobs && oldWorkflow.jobs) {
              workflow.jobs.forEach(job => {
                const oldJob = oldWorkflow.jobs.find((item) => item.id === job.id)
                if (oldJob !== null) {
                  job.deviceForce = oldJob.deviceForce
                  job.fixtureForce = oldJob.fixtureForce
                  job.tasksOpen = oldJob.tasksOpen
                  job.tasks.forEach(newTask => {
                    const oldTask = oldJob.tasks.find((item) => item.id === newTask.id)
                    if (oldTask !== null) {
                      newTask.toolForce = oldTask.toolForce
                    }
                  })
                }
              })
            }
          }
        })
      }
      setWorkflows(data)
    }
  })

  const onAcceptClick = () => {
    if (workflows === null) {
      // Not loaded yet
      return
    }
    // Check if all is okay
    const status = getWorkflowsStatus(workflows)
    if (status === 'OK' || status === 'WARNING') {
      onAccept()
      return
    }

    // Check if any program has a problem
    let programError = false
    workflows.forEach(workflow => {
      if (workflow.workflowId === null || !workflow.jobs) {
        return
      }
      workflow.jobs.forEach(job => {
        job.tasks.forEach(task => {
          if (!task.programOk) {
            programError = true
          }
        })
      })
    })

    if (programError) {
      setError(t_('Some program is not associated to the job '))
    } else {
      setError(t_('CHECKLIST IS NOT APPROVED!'))
    }
  }

  return (
    <SmartDialog
      setOpen={open}
      cancelCallback={onClose}
      acceptCallback={onAcceptClick}
      title={{
        visible: false,
        render: <Typography style={{ fontSize: '25px' }}>{t_('CheckList')}</Typography>,
        icon: ''
      }}
      renderComponent={workflows === null ? <CircularProgress /> : <Checklist workflows={workflows} setWorkflows={setWorkflows} error={error} closeError={() => setError(null)} />}
    />
  )
}

const getWorkflowsStatus = (workflows) => {
  let warning = false
  let error = false

  workflows.forEach(workflow => {
    if (workflow.workflowId === null || !workflow.jobs) {
      error = true
      return
    }
    const workflowStatus = getWorkflowStatus(workflow)
    if (workflowStatus === 'ERROR') {
      error = true
    } else if (workflowStatus === 'WARNING') {
      warning = true
    }
  })

  if (error) {
    return 'ERROR'
  }
  if (warning) {
    return 'WARNING'
  }
  return 'OK'
}

export default ChecklistDialog
