import { PlayCircleFilledOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import { Alert, FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SmartDialog } from '../utilities/SmartDialog'
import createDialogTitle from '../utilities/dialogUtil/index.js'
import { useSmartTranslation } from '../hooks/useSmartTranslation'
import useUser from '../hooks/useUser/useUser.jsx'
import { apiGet } from '../Api_Functions.jsx'
import { CheckCredentials } from '../LoginFunctions.jsx'
/**
 * Password dialog
 * @param {*} props
 * @param {Boolean} props.open If the dialog is open
 * @param {Function} props.onClose Function called when the dialog must be closed
 * @param {Function} props.onAccept Function called when the password is correct
 * @returns
 */
const PasswordDialog = ({ open, onClose, onAccept }) => {
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordIncorrect, setPasswordIncorrect] = useState(false)
  const { t_ } = useSmartTranslation()
  const { user } = useUser()
  useEffect(() => {
    if (open) {
      setPassword('')
      setShowPassword(false)
      setPasswordIncorrect(false)
    }
  }, [open])

  const checkPassword = async () => {
    try {
      const username = user.username
      const dbUserPassword = await apiGet('userPassword/' + username)
      if (dbUserPassword) {
        const response = await CheckCredentials(username, password, dbUserPassword)
        if (response.body.result) {
          onAccept()
        } else {
          setPasswordIncorrect(true)
        }
      }
    } catch (error) {
      setPasswordIncorrect(true)
    }
  }

  const passwordDialogRender = () => (
    <FormControl variant='outlined'>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            checkPassword()
          }
        }}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword(!showPassword)}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {passwordIncorrect ? <Alert severity='warning' style={{ marginTop: '1rem' }} onClose={() => { setPasswordIncorrect(false) }}>{t_('Invalid PASSWORD')}</Alert> : null}
    </FormControl>
  )

  return (
    <SmartDialog
      setOpen={open}
      message={t_('Write admin password')}
      acceptCallback={checkPassword}
      renderComponent={passwordDialogRender()}
      cancelCallback={onClose}
      title={createDialogTitle({ title: t_('Start'), icon: PlayCircleFilledOutlined })}
    />
  )
}
export default PasswordDialog
