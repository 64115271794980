import React from 'react'
import { Box, Button, TextField, Grid, Typography } from '@mui/material'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'
import { apiPut } from '../../../generic/Api_Functions'
import PartProgressStepper from '../../../generic/PartProgressStepper'

const RequestControlledStopDialog = ({ part, closeDialog, showAlert }) => {
  const { t_ } = useSmartTranslation()

  const handleStopAfterJob = () => {
    controlledStopWithOptions(0)
  }

  const handleStopAfterWorkflow = () => {
    controlledStopWithOptions(1)
  }

  const controlledStopWithOptions = async (option) => {
    apiPut('requestControlledStop/' + part.id + '/' + option)
      .then(() => {
        closeDialog()
        showAlert({
          title: t_('Success'),
          message: t_('Cell has been succesfully stopped'),
          severity: 'success'
        })
      })
      .catch((error) => {
        closeDialog()
        showAlert({
          title: t_('Error stopping cell'),
          message: error.message,
          severity: error.name
        })
      })
  }

  return (
    <Box>
      <Typography variant='h4' gutterBottom align='center' marginTop={3}>
        {t_('Request controlled stop')}
      </Typography>
      <PartProgressStepper partId={part.id} />
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2} justifyContent='flex-start'>
          <Grid item xs={6}>
            <TextField
              label={t_('Name')}
              value={part?.name || ''}
              margin='normal'
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t_('Code')}
              value={part?.code || ''}
              margin='normal'
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent='flex-start'>
          <Grid item xs={6}>
            <TextField
              label={t_('Workflow')}
              value={part?.partReference?.workflow || ''}
              margin='normal'
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t_('Location')}
              value={part?.location?.name || ''}
              margin='normal'
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
        <Button variant='contained' color='primary' onClick={handleStopAfterJob} sx={{ marginRight: 1 }}>
          {t_('Stop after job')}
        </Button>
        <Button variant='contained' color='primary' onClick={handleStopAfterWorkflow} sx={{ marginRight: 1 }}>
          {t_('Stop after workflow')}
        </Button>
        <Button variant='contained' color='inherit' onClick={closeDialog} autoFocus>
          {t_('Cancel')}
        </Button>
      </Box>
    </Box>
  )
}

export default RequestControlledStopDialog
