import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import ButtonBack from '../../generic/buttons/floatingButtons/ButtonBack'
import { apiPost } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useLocation, useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { CompassButton } from '../Common/CompassButton'
import Exposure from '@mui/icons-material/Iso'
export default function ProductionFabricationOrderList () {
  const location = useLocation()
  const navigate = useNavigate()
  const { device } = location.state

  const { t_ } = useSmartTranslation()

  const goBack = () => {
    return navigate('/compass/production')
  }
  const getData = async (pagination) => {
    return await apiPost('compass/getFabricationOrders/' + device.id, pagination)
  }

  const columns = [
    {
      field: 'fabricationOrder.name',
      name: t_('Fabrication Order')
    },
    {
      field: 'jobReference.name',
      name: t_('Job')
    },
    {
      field: 'fabricationOrder.deliveryDate',
      name: t_('Delivery date'),
      type: 'datetime'
    },
    {
      field: 'status',
      name: t_('Status')
    },
    {
      field: 'reason.name',
      name: t_('Reason')
    },
    {
      field: 'comment',
      name: t_('Comment')
    },
    {
      field: 'priority',
      name: t_('Priority')
    }
  ]
  const actions = {
    custom: [
      {
        render: (row) => {
          return (
            row.status !== 'WAITING'
              ? (
                <div style={{ marginRight: '10px' }}>
                  <CompassButton
                    icon={<Exposure />}
                    onClick={() => navigate('/compass/production/quantity/report/' + row.id, { state: { row, device, urlBack: '/compass/production/edit/' + device.id } })}
                    color='primary'
                  />
                </div>)
              : (
                <div style={{ marginRight: '10px', visibility: 'hidden' }}>
                  <CompassButton
                    icon={<Exposure />}
                    color='primary'
                  />
                </div>)

          )
        }
      },
      {

        render: (row) => {
          return (
            <CompassButton
              icon={<Edit />}
              onClick={() => navigate('/compass/production/edit/changeState', { state: { row, device, urlBack: '/compass/production/edit/' + device.id } })}
              color='primary'
            />
          )
        }
      }
    ]
  }
  return (
    <>
      <Stack style={{ marginTop: '20px' }}>
        <SmartMateriaUITable
          title={
            <Typography color='primary' style={{ width: '100%' }}> {device.name}</Typography>
          }
          dataFetch={getData}
          columns={columns}
          pageable
          actions={actions}
        />
        <Box style={{ marginTop: '10px' }}>
          <ButtonBack variant='contained' color='primary' buttonAction={goBack} />
        </Box>
      </Stack>
    </>
  )
}
