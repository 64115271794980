import { Stack, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

const TaskDateDialogContent = (props) => {
  const { event, setEvent } = props
  const { t_ } = useSmartTranslation()
  return (
    <Stack gap={3} sx={{ marginTop: '1em' }}>
      <DatePicker
        label={t_('Minimun start date')}
        value={event && event.minDate !== null ? new Date(event.minDate) : ''}
        disabled={event ? !event.editable : false}
        onChange={(newValue) => {
          setEvent({ ...event, minDate: new Date(newValue) })
        }}
          // helperText={event && event.showErrorsTask && (event.date === null) ? "Value must not be null" : null}
        renderInput={(params) => <TextField {...params} />}
      />

      <TextField
        label={t_('Priority')}
        value={event ? event.priority : 5}
        disabled={event ? !event.editable : false}
        onChange={(newPriority) => setEvent({ ...event, priority: newPriority.target.value })}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        error={event && event.showErrorsTask && (isNaN(parseInt(event.priority)) || (parseInt(event.priority) < 1 || parseInt(event.priority) > 10))}
        helperText={event && event.showErrorsTask && (isNaN(parseInt(event.priority)) || (parseInt(event.priority) < 1 || parseInt(event.priority) > 10)) ? 'Value must be between 1 and 10' : null}
      />
    </Stack>
  )
}

export default TaskDateDialogContent
