import { apiGet, apiPost, apiPut, apiDelete } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const MaterialStandard = ({ selectedMaterial }) => {
  const { t_ } = useSmartTranslation()
  const columns = [
    {
      name: t_('Standard'),
      field: 'standard.name',
      charLimit: 200,
      type: 'select',
      edit: {
        source: async () => {
          const standardOptions = await apiGet('standardBasicData')
          return standardOptions
        },
        id: 'id',
        field: 'name'
      },
      mandatory: true,
      readOnly: true
    },
    {
      name: t_('Code'),
      field: 'code',
      charLimit: 60,
      mandatory: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Name'),
      field: 'name',
      charLimit: 60,
      mandatory: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    }
  ]

  const dataFetch = async (pagination) => {
    const materialStandards = await apiGet('getAllMaterialStandard/' + selectedMaterial.id, pagination)
    return materialStandards
  }

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('materialStandard', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        row.material = selectedMaterial.id
        return apiPost('materialStandard', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('materialStandard/' + rows[0].id, rows)
        } else {
          return apiDelete('materialStandardMulti', rows)
        }
      },
      visibilityButton: (row) => {
        return !row.systemDefault
      }
    }
  }

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Material code:') + ' ' + selectedMaterial.code + '  [' + selectedMaterial.materialStandard.code + ']'}
      dataFetch={dataFetch}
      actions={actions}
      pageable
      multipleSelection
    />
  )
}

export default MaterialStandard
