import { useEffect, useMemo, useReducer, useState } from 'react'
import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { Grid, Stack, TextField } from '@mui/material'
import AlertUI from '../../generic/AlertUI'
import { useToggle } from '../../generic/hooks/useToggle'
import { SmartDeleteDialog } from '../../generic/utilities/SmartDialog'
import { FULL_HEIGHT } from '../../generic/utilities/size_utils'
import SmartPalette from '../../generic/smartPalette/SmartPalette'
import { SmartToolBar } from '../../generic/utilities/smarttoolbar'
import SmartSelect from '../../generic/smartSelect/SmartSelect'

const Rule = () => {
  const { t_ } = useSmartTranslation()
  const [AlertElement, showAlert] = AlertUI()
  const [referenceOptions, setReferenceOptions] = useState([])
  const [resourceOptions, setResourceOptions] = useState([])
  const [rules, setRules] = useState([])
  const [formulas, setFormulas] = useState([])
  const [mustReloadRules, reloadRules] = useToggle()
  const [checkedItems, setCheckedItems] = useState([])
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const onDispatchEditingData = (state, action) => {
    if (action.type === 'SET_ID') {
      return { ...state, id: action.value }
    }
    if (action.type === 'SET_NAME') {
      return { ...state, name: action.value }
    }
    if (action.type === 'SET_CODE') {
      return { ...state, code: action.value }
    }
    if (action.type === 'SELECT_RESOURCE') {
      const resource = resourceOptions?.find((item) => item.name === action.value)
      return { ...state, resource, reference: null }
    }
    if (action.type === 'SET_REFERENCE') {
      const reference = referenceOptions?.find((item) => item.name === action.value)
      return { ...state, reference }
    }
    if (action.type === 'SET_FORMULA') {
      return { ...state, formula: action.value }
    }
    if (action.type === 'SET_CURRENT_RULE') {
      return { ...state, id: action.value.id, name: action.value.name, code: action.value.code, resource: action.value.resource, reference: action.value.reference, formula: action.value.formula }
    }
    if (action.type === 'NEW_RULE') {
      return { id: null, code: '', name: '', resource: null, reference: null, formula: '' }
    }
    return state
  }
  const [editingData, dispatchEditingData] = useReducer(onDispatchEditingData, { id: null, code: '', name: '', resource: null, reference: null, formula: '' })

  const nodes = [
    { id: 1, code: 'ALL', name: 'All' },
    { id: 2, code: 'ANY', name: 'Any' }
  ]

  const conditions = [
    { id: 1, code: 'EQUAL', name: '=' },
    { id: 2, code: 'NOT', name: '!' },
    { id: 3, code: 'GREATER', name: '>' },
    { id: 5, code: 'LESS', name: '<' },
    { id: 4, code: 'GREATER_OR_EQUAL', name: '>=' },
    { id: 6, code: 'LESS_OR_EQUAL', name: '<=' }
  ]

  useEffect(() => {
    const fetchReferenceOptions = async () => {
      if (!editingData?.resource) {
        setReferenceOptions([])
      } else {
        let data = []
        switch (editingData.resource.code) {
          case 'PART':
            data = await apiGet('partReferencesShortData')
            break
          case 'FIXTURE':
            data = await apiGet('fixtureReferences')
            break
          case 'TOOL':
            data = await apiGet('toolReferencesDto')
            break
          case 'RAW_MATERIAL':
            data = await apiGet('rawMaterialsReference')
            break
          case 'DEVICE':
            data = await apiGet('devicesBasicData')
            break
          default:
            break
        }
        data = data?.map((item) => { return { id: item.id, code: item.code, name: item.name } })
        setReferenceOptions(data)
      }
    }
    const fetchResourceOptions = async () => {
      const data = await apiGet('rule/resourceEnums')
      setResourceOptions(data)
    }

    fetchReferenceOptions()
    fetchResourceOptions()
  }, [editingData.resource])

  useEffect(() => {
    const fetchFormulas = async () => {
      const data = await apiGet('formula/all')
      setFormulas(data)
    }
    fetchFormulas()
  }, [])

  useEffect(() => {
    const loadRules = async () => {
      setRules(await apiGet('rules'))
    }
    loadRules()
  }, [mustReloadRules])

  const onSave = () => {
    if (editingData.code === '' || editingData.name === '' || editingData.resource === null || editingData.reference === null || editingData.formula === '') {
      showAlert({ title: t_('Error'), message: t_('All fields are mandatory.'), severity: 'error' })
      return
    }
    if (editingData.id === null) {
      apiPost('rules', editingData).then((response) => {
        dispatchEditingData({ type: 'SET_CURRENT_RULE', value: response })
        showAlert({ message: t_('Created correctly.'), severity: 'success' })
        reloadRules()
      }).catch((error) => {
        showAlert({ title: t_('Error creating rule'), message: error.message, severity: error.name })
      })
    } else {
      apiPut('rules', editingData).then((response) => {
        dispatchEditingData({ type: 'SET_CURRENT_RULE', value: response })
        showAlert({ message: t_('Updated correctly.'), severity: 'success' })
        reloadRules()
      }).catch((error) => {
        showAlert({ title: t_('Error updating rule'), message: error.message, severity: error.name })
      })
    }
  }
  const onDelete = () => {
    const rulesToDelete = checkedItems.length > 1 ? checkedItems : [editingData]
    apiDelete('rule/deleteMulti', rulesToDelete).then(() => {
      showAlert({
        title: '',
        message: t_('Deleted correctly.'),
        severity: 'success'
      })
      if (checkedItems.length > 0) {
        setCheckedItems([])
      }
      if (rulesToDelete.find(item => item.id === editingData.id)) {
        dispatchEditingData({ type: 'NEW_RULE', value: null })
      }
      reloadRules()
    }).catch((error) => {
      showAlert({
        title: '',
        message: t_(error.message),
        severity: error.name
      })
    })
  }

  const handleSelectResource = (newValue) => {
    dispatchEditingData({ type: 'SELECT_RESOURCE', value: newValue })
  }
  const handleSelectReference = (newValue) => {
    dispatchEditingData({ type: 'SET_REFERENCE', value: newValue })
  }

  // SmartPalette functions
  const handleOnItemClick = (section, item) => {
    if (section.id === 'rule') {
      dispatchEditingData({ type: 'SET_CURRENT_RULE', value: item })
    }
  }
  const handleGetDragProperties = (section, item) => {
    return { 'smartpm/rule/name': item.name }
  }
  const handleIsItemChecked = (section, item) => {
    if (section.id === 'rule') {
      return !!checkedItems.find((current) => current.id === item.id)
    }
    return undefined
  }
  const handleOnCheckedChange = (section, item, checked) => {
    if (section.id === 'rule') {
      if (checked) {
        setCheckedItems([...checkedItems, item])
      } else {
        setCheckedItems(checkedItems.filter((current) => item.id !== current.id))
      }
    }
  }
  /**
   * Return filtered items, checked items and editing item
   */
  const handleFilterItem = (section, item, filter) => {
    if (editingData && editingData.id === item.id) {
      return true
    }
    if (checkedItems.find((current) => current.id === item.id)) {
      return true
    }
    return item?.name.toLowerCase().includes(filter)
  }

  const columns = [
    {
      name: t_('Code'),
      field: 'code'
    },
    {
      name: t_('Name'),
      field: 'name'
    },
    {
      name: t_('Resource'),
      field: 'resource.name'
    },
    {
      name: t_('Reference'),
      field: 'reference.name'
    },
    {
      name: t_('Formula'),
      field: 'formula'
    }
  ]

  const sections = useMemo(() => {
    return [
      { id: 'node', items: nodes, title: t_('Node') },
      { id: 'condition', items: conditions, title: t_('Condition') },
      { id: 'formula', items: formulas, title: t_('Formula') },
      { id: 'rule', items: rules, title: t_('Rule') }
    ]
  }, [rules, t_])

  const labelsToolBar = [
    { title: t_('Code'), value: editingData?.code, onChange: (value) => dispatchEditingData({ type: 'SET_CODE', value }) },
    { title: t_('Name'), value: editingData?.name, onChange: (value) => dispatchEditingData({ type: 'SET_NAME', value }) }
  ]

  return (
    <>
      <SmartDeleteDialog
        show={showDeleteDialog}
        cancelCallback={() => { setShowDeleteDialog(false) }}
        deleteCallBack={() => {
          setShowDeleteDialog(false)
          onDelete()
        }}
        rows={checkedItems.length > 0 ? checkedItems : [editingData]}
        columns={columns}
      />
      <div style={{ width: '100%', height: FULL_HEIGHT.WITH_NAV_BAR_AND_SMART_NAVIGATOR_AND_FOOTER, display: 'grid', gridTemplateColumns: 'max-content 1fr', gridTemplateRows: 'max-content 1fr', gap: '1rem' }}>
        <SmartPalette
          sx={{ gridRow: 'span 2' }}
          sections={sections}
          onItemClick={(section, item) => handleOnItemClick(section, item)}
          getDragProperties={(section, item) => handleGetDragProperties(section, item)}
          selectedSection={sections.find((section) => section.id === 'rule') || {}}
          selectedItem={editingData}
          isItemChecked={(section, item) => handleIsItemChecked(section, item)}
          onCheckedChange={(section, item, checked) => { handleOnCheckedChange(section, item, checked) }}
          filterItem={(section, item, filter) => { return handleFilterItem(section, item, filter) }}
        />
        <Stack direction='column' gap={3}>
          {AlertElement}
          <SmartToolBar
            labels={labelsToolBar}
            onAddElementClick={() => dispatchEditingData({ type: 'NEW_RULE', value: null })}
            disableSaveButton={!editingData?.id && checkedItems.length === 0}
            onSaveElementClick={() => { onSave() }}
            onDeleteElementClick={() => setShowDeleteDialog(true)}
          />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <SmartSelect
                label={t_('Resource')}
                value={editingData?.resource?.name}
                onChange={handleSelectResource}
                selectableOptions={resourceOptions.map((item) => { return item.name })}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartSelect
                label={t_('Reference')}
                value={editingData?.reference?.name}
                onChange={handleSelectReference}
                selectableOptions={!editingData?.resource ? [] : referenceOptions.map((item) => { return item.name })}
              />
            </Grid>
          </Grid>
        </Stack>
        <TextField
          label={t_('Formula')}
          value={editingData?.formula}
          InputLabelProps={{ shrink: true }}
          onChange={event => dispatchEditingData({ type: 'SET_FORMULA', value: event.target.value })}
          onDragOver={(event) => event.preventDefault()}
          onDrop={(event) => {
            const name = event.dataTransfer.getData('smartpm/rule/name')
            dispatchEditingData({ type: 'SET_FORMULA', value: editingData.formula + name })
          }}
        />
      </div>
    </>
  )
}

export default Rule
