import { Typography } from "@mui/material"

export default function createDialogTitle({ color = "primary", iconSize = "1.8em", title, fontSize, variant = "h6", icon: Icon } = {}) {
  let colorPredefined = color === "primary" || color === "secondary" || color === "error" || color === "warning" || color === "info" || color === "success" ? true : false

  const IconCustom = (props) => (
    <Icon
      color={colorPredefined ? color : ""}
      sx={{
        fontSize: iconSize,
        color: colorPredefined ? "" : 'error'
      }}
    >
      {props.children}
    </Icon>
  )

  const TypographyCustom = (props) => (
    colorPredefined ?
      < Typography
        color={color + '.main'}
        variant={variant}
        sx={{
          fontSize: fontSize,
        }}
      >
        {props.children}
      </ Typography>
      :
      < Typography
        variant={variant}
        sx={{
          fontSize: fontSize,
          color: color
        }}
      >
        {props.children}
      </ Typography>
  )

  return (
    {
      visible: true,
      icon: <IconCustom />,
      render: <TypographyCustom >{title} </TypographyCustom>,
    }
  )
}
