import React, { useEffect, useState } from 'react'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { apiGet, apiPut } from '../../generic/Api_Functions'
import { Link } from 'react-router-dom'
import { Apps } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const StorageConfig = (props) => {
  const { deviceId, device, setDevice } = props
  const [storage, setStorage] = useState({})
  const [loadStorage, setLoadStorage] = useState(true)
  const [editedShelveStorageConfigurations, setEditedShelveStorageConfigurations] = useState(false)
  const { t_ } = useSmartTranslation()
  const fetchFunction = async () => {
    const shelveStorageConfigurations = await apiGet('shelveStorageConfigurations/' + deviceId)
    if (editedShelveStorageConfigurations) {
      await apiGet('deviceTechnologyByDeviceId/' + deviceId).then((data) => {
        setDevice(({ ...device, deviceTechnologies: data }))
        setEditedShelveStorageConfigurations(false)
      })
    }
    return shelveStorageConfigurations
  }

  const readOnlyCondition = (row) => {
    let isReadOnly = false
    if (Array.isArray(row.storageFunctions)) {
      row.storageFunctions.forEach((storageFunction) => {
        if (storageFunction.isStorageFunctionMixed !== undefined) {
          isReadOnly = false
        }
      })
    } else {
      if (row.storageFunctions.isStorageFunctionMixed !== undefined && row.storageFunctions.isStorageFunctionMixed === true) {
        isReadOnly = true
      }
    }
    return isReadOnly
  }

  useEffect(() => {
    const fetchStorage = async () => {
      const st = await apiGet('storages/byDeviceId/' + deviceId)
      setStorage(st)
    }

    if (loadStorage) {
      fetchStorage()
      setLoadStorage(false)
    }
  }, [storage, deviceId, loadStorage])

  const actionShelveConfigurationTable = {
    edit: {
      fetchFunction: (editRow) => {
        let apiPutParam
        if (storage) {
          if (!Array.isArray(editRow.storageFunctions)) {
            if (editRow.storageFunctions.isStorageFunctionMixed === undefined) {
              apiPutParam = Object.assign({ storageFunctions: null, updateStorageFunctions: true, shelveStorageConfiguration: editRow })
            } else {
              if (editRow.storageFunctions.isStorageFunctionMixed === true) {
                apiPutParam = Object.assign({ storageFunctions: null, updateStorageFunctions: false, shelveStorageConfiguration: editRow })
              }
            }
          } else {
            apiPutParam = Object.assign({ storageFunctions: editRow.storageFunctions, updateStorageFunctions: true, shelveStorageConfiguration: editRow })
          }
          return apiPut('shelveStorageConfigurationsAndStorageFunctions/', apiPutParam).then(() => { setEditedShelveStorageConfigurations(true); setLoadStorage(true) })
        }
      }
    },
    create: {
      fetchFunction: (newShelf) => {
        if (Object.keys(storage).length > 0) {
          const serializedShelf = {
            ...newShelf,
            storageId: storage.id
          }
          if (serializedShelf.storageFunctions.length === undefined || serializedShelf.storageFunctions.length === 0) {
            serializedShelf.storageFunctions = null
          }
          const copyStorage = { ...storage }
          copyStorage.shelveStorageConfigurations.push(serializedShelf)
          setStorage(copyStorage)
          const apiPutParam = Object.assign({ storageFunctions: serializedShelf.storageFunctions, updateStorageFunctions: true, shelveStorageConfiguration: serializedShelf })
          return apiPut('shelveStorageConfigurationsAndStorageFunctions', apiPutParam).then(() => { setEditedShelveStorageConfigurations(true); setLoadStorage(true) })
        }
      }
    },
    custom: [
      {
        name: 'ShelvePositions',
        showOnNew: 'false',
        render: (shelve) => {
          return (
            <IconButton
              component={Link}
              to={'/configuration/assets/' + deviceId + '/shelve/' + shelve.id}
              onClick={() => { }}
            >
              <Apps />
            </IconButton>
          )
        }
      }
    ]
  }

  const getShelveByDefault = () => {
    let shelveNumber = 1
    if (storage.shelveStorageConfigurations !== undefined && storage.shelveStorageConfigurations.length !== 0) {
      shelveNumber = Math.max(...storage.shelveStorageConfigurations.map(shelveStorageConfiguration => shelveStorageConfiguration.shelve)) + 1
    }
    return shelveNumber
  }

  const getDefaultNewTechnologyValue = () => {
    if (device === undefined) {
      return undefined
    } else if (device.deviceTechnologies === undefined) {
      return undefined
    } else if (device.deviceTechnologies.length === 1) {
      return device.deviceTechnologies[0].technology
    } else { return undefined }
  }

  const columnsInfoShelves = [
    {
      name: t_('Shelf'),
      field: 'shelve',
      readOnly: true,
      defaultNewValue: getShelveByDefault(),
      unique: true,
      numeric: true
    },
    {
      name: t_('Rows'),
      field: 'numRows',
      defaultNewValue: 1,
      readOnly: true,
      numeric: true
    },
    {
      name: t_('Columns'),
      field: 'numColumns',
      defaultNewValue: 1,
      readOnly: true,
      numeric: true
    },
    {
      name: t_('Width'),
      field: 'width',
      defaultNewValue: 120
    },
    {
      name: t_('Length'),
      field: 'length',
      defaultNewValue: 120
    },
    {
      name: t_('Zero X'),
      field: 'zeroX',
      defaultNewValue: 0,
      numeric: {
        default: 0
      }
    },
    {
      name: t_('Zero Y'),
      field: 'zeroY',
      defaultNewValue: 0,
      numeric: {
        default: 0
      }
    },
    {
      name: t_('Zero Z'),
      field: 'zeroZ',
      defaultNewValue: 0,
      numeric: {
        default: 0
      }
    },
    {
      name: t_('Technology'),
      field: 'technology.name',
      type: 'select',
      defaultNewValue: getDefaultNewTechnologyValue(),
      edit: {
        source: async () => {
          return await apiGet('technologyDeviceTypesByDeviceId/' + deviceId)
        },
        id: 'id',
        field: 'name'
      },
      mandatory: true
    },
    {
      name: t_('Function'),
      field: 'storageFunctions.code',
      type: 'smartMultiSelect',
      edit: {
        source: async () => {
          return await apiGet('storageFunctions')
        },
        id: 'id',
        field: 'code'
      },
      readOnly: (rowData) => {
        return readOnlyCondition(rowData)
      }
    }
  ]
  return (
    <>
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <SmartMateriaUITable
            title=''
            columns={columnsInfoShelves}
            dataFetch={fetchFunction}
            actions={actionShelveConfigurationTable}
            multipleOptions
          />
        </Grid>
      </Grid>
    </>
  )
}

export default StorageConfig
