import React, { useEffect, useRef } from "react";
import DonutChart from "react-donut-chart";

const backgroundColor = [
  "#7FE1A7",
  "#424242",
];

const appStyle = {
  fontSize: "18px",
  color: "white",
  fontWeight: "bold"
}

export default function StorageGraph(props) {
  const {
    totalSuccessPartPercentage,
    handleOEEloadedValue
  } = props;

  function randomIntFromInterval(min, max) { // min and max included         
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  const ref = useRef([])


  useEffect(() => {
    if (totalSuccessPartPercentage === undefined || totalSuccessPartPercentage === "") ref.current = 0
    else {
      var randomValue;
      if (totalSuccessPartPercentage <= 30)
        randomValue = randomIntFromInterval(35, 54);
      else if (totalSuccessPartPercentage <= 55)
        randomValue = randomIntFromInterval(55, 74);
      else
        randomValue = randomIntFromInterval(75, 95);
      ref.current = randomValue;
    }
    handleOEEloadedValue(true)
  }, [totalSuccessPartPercentage, handleOEEloadedValue])

  return (
    <div style={appStyle} >
      <DonutChart
        width={150}
        height={150}
        colors={backgroundColor}
        emptyColor={"#424242"}
        legend={false}
        startAngle={90}
        onMouseEnter={ref.current}
        clickToggle={false}
        defaultValue={0}

        data={[
          {
            //label: ref.current + "%",
            value: ref.current,
          },
          {
            value: 100 - ref.current,
          }
        ]}
      />
    </div>
  );
}
