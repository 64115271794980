import { useEffect, useRef, useState } from 'react'
import { useToggle } from '../useToggle'

/**
 *
 * @param callback Function that gets data in every task of the interval
 * @param delay Interval delay
 * @param updateState Function that sets fetched data (with callback function), to state variables
 * @param initialDoInterval Sets the initial state of this hook to whether perform the interval or not. Default true
 * @returns A function to pause/unpause the interval by passing true or false in the argument
 */
export const useSmartInterval = (callback, delay, updateState, initialDoInterval = true) => {
  const savedCallback = useRef()
  const savedDelay = useRef()
  const savedUpdateState = useRef()
  const [toggleInterval, setToggleInterval] = useToggle()
  const [toggleReload, setToggleReload] = useToggle()
  const componentMounted = useRef(true)
  const [doInterval, setDoInterval] = useState(initialDoInterval)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    savedDelay.current = delay
  }, [delay])

  useEffect(() => {
    savedUpdateState.current = updateState
  }, [updateState])

  useEffect(() => {
    const fetchFirst = async () => {
      const data = await savedCallback.current()
      if (componentMounted.current) {
        savedUpdateState.current(data)
      }
    }

    fetchFirst()
  }, [toggleReload])

  useEffect(() => {
    componentMounted.current = true
    if (savedCallback.current && doInterval) {
      const timeout = setTimeout(async () => {
        const data = await savedCallback.current()
        if (componentMounted.current) {
          savedUpdateState.current(data)
          setToggleInterval()
        }
      }, savedDelay.current)

      return () => {
        clearTimeout(timeout)
        componentMounted.current = false
      }
    }

    return () => {
    }
  }, [toggleInterval, doInterval, setToggleInterval])

  return (doIntervalValue = false, forceLoad = false) => {
    setDoInterval(doIntervalValue)
    if (forceLoad) setToggleReload()
  }
}
export default useSmartInterval
