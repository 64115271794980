import { Alert, Button, Card, Stack, Typography } from '@mui/material'
import { PlaylistAddCheck } from '@mui/icons-material'
import { CheckListPartReference } from './ChecklistPartReference'
import moment from 'moment'
import useFrontEndConfig from '../hooks/useFrontEndConfig/useFrontEndConfig'
import { useSmartTranslation } from '../hooks/useSmartTranslation'
import { useEffect, useState } from 'react'
import AddProgramDialog from './AddProgramDialog'

/**
 * Checklist component
 * @param {*} props
 * @param {Array<Object>} props.workflows List of workflows to show
 * @param {Function} props.setWorkflows Function called with the new workflows list (This new list will have some properties changed)
 * @param {String} props.error String that will be shown as error. Null to hide
 * @param {Function} props.closeError Function that will be called when closing the error
 * @returns
 */
const Checklist = ({ workflows, setWorkflows, error, closeError }) => {
  const { t_ } = useSmartTranslation()
  const [addProgramDialogParams, setAddProgramDialogParams] = useState(null)

  const frontendConfig = useFrontEndConfig()[0]

  useEffect(() => {
    if (error !== null) {
      // Scroll to the top so the user can see the error
      const element = document.getElementById('checklist-container')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [error])

  const changeWorkflows = (newWorkflow) => {
    const newWorkflows = [...workflows]
    // Find index of the workflow
    const index = workflows.findIndex((workflow) => workflow.workflowId === newWorkflow.workflowId && workflow.partReferenceId === newWorkflow.partReferenceId)
    newWorkflows[index] = newWorkflow
    setWorkflows(newWorkflows)
  }

  const checkAll = () => {
    const newWorkflows = structuredClone(workflows)
    newWorkflows.forEach(workflow => {
      if (workflow.jobs !== null) {
        workflow.jobs.forEach(job => {
          if (!job.fixtureOk) {
            job.fixtureForce = true
          }
          if (!job.deviceOk) {
            job.deviceForce = true
          }

          job.tasks.forEach(task => {
            if (!task.toolOk) {
              task.toolForce = true
            }
          })
        })
      }
    })
    setWorkflows(newWorkflows)
  }

  return (
    <>
      <AddProgramDialog open={addProgramDialogParams !== null && addProgramDialogParams.show} onClose={() => setAddProgramDialogParams({ ...addProgramDialogParams, show: false })} technology={addProgramDialogParams?.technology} taskId={addProgramDialogParams?.taskId} referenceName={addProgramDialogParams?.referenceName} />
      <Stack gap={3} id='checklist-container'>
        {error !== null ? <Alert severity='error' onClose={closeError}>{error}</Alert> : null}
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ padding: '1.5rem', backgroundColor: (theme) => theme.palette.background.card }}>
          <Typography>{t_('Date') + ': ' + moment().format('L') + ' ' + moment().format('LT')}</Typography>

          <Stack direction='row' gap={1}>
            <Typography>{t_('User') + ':'}</Typography>
            <Typography color='primary'>{frontendConfig?.user}</Typography>
          </Stack>

          <Button
            onClick={() => checkAll()}
          ><PlaylistAddCheck />
          </Button>
        </Stack>

        {workflows.map((workflow) =>
          <Card key={workflow.workflowId + '-' + workflow.partReferenceId} sx={{ padding: '1rem', backgroundColor: (theme) => theme.palette.background.default }}>
            <CheckListPartReference workflow={workflow} onChangeWorkflow={changeWorkflows} onAddProgram={(workflow, job, task, taskIndex) => { setAddProgramDialogParams({ technology: job.technology, taskId: task.id, referenceName: workflow.partReference, show: true }) }} />
          </Card>)}
      </Stack>
    </>
  )
}

export default Checklist
