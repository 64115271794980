import { useEffect, useState } from 'react'
import { apiGet } from '../../generic/Api_Functions'
import { DefaultAccordion } from '../../generic/DefaultAccordion'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useTheme } from '@emotion/react'
import NoOk from '../../../images/NO_OK.png'
import Ok from '../../../images/OK.png'
import { Box } from '@mui/material'
import ConstructionIcon from '@mui/icons-material/Construction'

const ToolTables = () => {
  const { t_ } = useSmartTranslation()
  const [partReferences, setPartReferences] = useState(null)
  const [devices, setDevices] = useState(null)
  const theme = useTheme()
  const boxStyle = {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1.8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderRadius: '0px 0px 5px 5px',
    boxShadow: theme => '0px 2px 1px -1px ' + theme.palette.primary.main + '60, 0px 1px 1px 0px ' + theme.palette.primary.main + '60, 0px 1px 3px 0px ' + theme.palette.primary.main + '20'
  }
  const imgStyle = {
    verticalAlign: 'middle',
    height: '1.8rem',
    width: '1.8rem'
  }
  const fixedColumns = [
    {
      name: t_('Task Order'),
      field: 'taskReference.taskOrder'
    },
    {
      name: t_('Tool'),
      field: 'name'
    },
    {
      name: t_('Duration'),
      field: 'taskReference.theoreticalTime'
    },
    {
      name: t_('D'),
      field: 'd'

    },
    {
      name: t_('RC'),
      field: 'rc'

    },
    {
      name: t_('CL'),
      field: 'cl'

    },
    {
      name: t_('TL'),
      field: 'tl'

    },
    {
      name: t_('Z'),
      field: 'z'
    }]
  const createColumns = (jobReference) => {
    const response = jobReference?.compatibleDevices?.map((device) => {
      return {
        name: device.name,
        field: 'devices',
        readOnly: true,
        render: (row) => {
          return (
            <img
              style={imgStyle}
              src={row.devices.find((item) => item.name === device.name) ? Ok : NoOk}
              alt=''
            />
          )
        }
      }
    })
    return response
  }

  const getToolIcon = (okay) => {
    return <ConstructionIcon color={okay ? 'primary' : 'error'} />
  }

  const columnsPartReference = (jobReference) => {
    return fixedColumns.concat(createColumns(jobReference)).map(item => ({ ...item }))
  }

  const toolColumns = [
    {
      name: t_('Code'),
      field: 'code'
    },
    {
      name: t_('Tool'),
      field: 'name'
    },
    {
      name: t_('D'),
      field: '',
      render: (row) => {
        if (row.parameterValueList === undefined) {
          return null
        } else {
          const valueFound = row.parameterValueList.find((parameterValue) => parameterValue.name === 'Diameter')
          return (valueFound !== undefined ? valueFound.value : null)
        }
      }
    },
    {
      name: t_('RC'),
      field: '',
      render: (row) => {
        if (row.parameterValueList === undefined) {
          return null
        } else {
          const valueFound = row.parameterValueList.find((parameterValue) => parameterValue.name === 'Corner Radius')
          return (valueFound !== undefined ? valueFound.value : null)
        }
      }

    },
    {
      name: t_('CL'),
      field: '',
      render: (row) => {
        if (row.parameterValueList === undefined) {
          return null
        } else {
          const valueFound = row.parameterValueList.find((parameterValue) => parameterValue.name === 'Cutting Length')
          return (valueFound !== undefined ? valueFound.value : null)
        }
      }
    },
    {
      name: t_('Z'),
      field: '',
      render: (row) => {
        if (row.parameterValueList === undefined) {
          return null
        } else {
          const valueFound = row.parameterValueList.find((parameterValue) => parameterValue.name === 'Number of Flutes')
          return (valueFound !== undefined ? valueFound.value : null)
        }
      }
    },
    {
      name: t_('TL'),
      field: '',
      render: (row) => {
        if (row.parameterValueList === undefined) {
          return null
        } else {
          const valueFound = row.parameterValueList.find((parameterValue) => parameterValue.name === 'Total Length')
          return (valueFound !== undefined ? valueFound.value : null)
        }
      }
    },
    {
      name: t_('Current life'),
      field: 'currentLife'
    },
    {
      name: t_('Holder'),
      field: 'fixture.name'
    },
    {
      name: t_('ATC'),
      field: 'atc'
    },
    {
      name: t_('Shelf'),
      field: 'shelveStoragePosition.shelve'
    },
    {
      name: t_('Row'),
      field: 'shelveStoragePosition.position'
    },
    {
      name: t_('Column'),
      field: 'shelveStoragePosition.depth'
    }
  ]

  useEffect(() => {
    const getAllPartReferences = async () => {
      const response = await apiGet('toolTable/partReferences')
      setPartReferences(response)
    }
    const getAllDevices = async () => {
      const response = await apiGet('deviceDto/toolTables')
      setDevices(response)
    }
    getAllPartReferences()
    getAllDevices()
  }, [])
  const dataFetchPartReferences = async (partReference, jobReference, pagination) => {
    return await apiGet('toolTable/partReference/' + partReference.id + '/' + jobReference.id, pagination)
  }
  const sortOptions = {
    default: {
      field: 'taskReference.taskOrder',
      direction: 1
    }
  }
  return (
    <>
      <DefaultAccordion
        headerRender={t_('Part references')}
        highlightHeaderOnExpanded
        defaultExpanded={false}
        headerSx={{ backgroundColor: theme.palette.background.default }}
        contentSx={{ backgroundColor: theme.palette.background.default }}
      >
        {partReferences?.map((partReference) => {
          return partReference.workflow !== null
            ? (
              <DefaultAccordion
                key={partReference.id}
                highlightHeaderOnExpanded
                title={partReference.name}
                titleStyle={{ fontSize: '1rem' }}
                headerRender={getToolIcon(partReference.toolsOk)}
                defaultExpanded={false}
                headerSx={{ backgroundColor: theme.palette.background.paper }}
                contentSx={{ backgroundColor: theme.palette.background.paper }}
              >
                {partReference?.jobReferences?.map((jobReference) => {
                  return (
                    <DefaultAccordion
                      key={jobReference.id}
                      highlightHeaderOnExpanded
                      title={jobReference.name}
                      titleStyle={{ fontSize: '1rem' }}
                      headerRender={getToolIcon(jobReference.toolsOk)}
                      defaultExpanded={false}
                    >
                      <SmartMateriaUITable
                        columns={columnsPartReference(jobReference)}
                        sortOptions={sortOptions}
                        dataFetch={(requestPagination) => { return dataFetchPartReferences(partReference, jobReference, requestPagination) }}
                        pageable
                      />
                    </DefaultAccordion>
                  )
                })}
              </DefaultAccordion>
              )
            : (
              <Box sx={{ ...boxStyle }}>
                {partReference.name}
              </Box>)
        }
        )}
      </DefaultAccordion>
      <DefaultAccordion
        headerRender={t_('Assets')}
        highlightHeaderOnExpanded
        defaultExpanded={false}
        headerSx={{ backgroundColor: theme.palette.background.default }}
        contentSx={{ backgroundColor: theme.palette.background.default }}
      >
        {devices?.length > 0
          ? devices?.map((device) => {
            return (
              <DefaultAccordion
                key={device.id}
                highlightHeaderOnExpanded
                headerRender={device.name}
                defaultExpanded={false}
                headerSx={{ backgroundColor: theme.palette.background.paper }}
                contentSx={{ backgroundColor: theme.palette.background.paper }}
              >
                <SmartMateriaUITable
                  columns={toolColumns}
                  dataFetch={device.tools}
                />
              </DefaultAccordion>
            )
          }
          )
          : (
            <Box sx={{ ...boxStyle }}>
              {t_('No tools available on assets')}
            </Box>
            )}
      </DefaultAccordion>
    </>
  )
}
export default ToolTables
