import { SaveRounded } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import React from 'react'
import createDialogTitle from '../dialogUtil'
import SmartDialog from './SmartDialog'
import { useSmartTranslation } from "../../hooks/useSmartTranslation";

const SmartSaveDialog = (props) => {
  const {
    show = false,
    cancelCallback,
    saveCallback
  } = props
  const { t_ } = useSmartTranslation();

  return (
    <SmartDialog
      title={createDialogTitle({ title: t_("Save data?"), icon: SaveRounded })}
      renderComponent={<Typography>{t_("Are you sure you want to save data?")}</Typography>}
      setOpen={show}
      accept={false}
      cancelCallback={() => {
        if (cancelCallback && typeof cancelCallback === 'function') {
          cancelCallback()
        }
      }}
      renderCustomButtons={
        <Button
          color='primary'
          onClick={
            () => {
              if (saveCallback && typeof saveCallback === 'function') {
                saveCallback()
              }
            }}
          variant='outlined'
        >
          <Typography color='primary' >{t_("SAVE")}</Typography>
          <SaveRounded color='primary' sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
        </Button >
      }
    />
  )
}

export default SmartSaveDialog