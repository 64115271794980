import { Box } from "@mui/material";
import AddElement from "../../generic/smartAddElement/AddElement";
import React, { useRef } from "react";
import { apiDelete, apiPut, apiGet } from "../../generic/Api_Functions";
import SmartMateriaUITable from "../../generic/SmartMateriaUITable";
import moment from 'moment/moment.js';
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation';

const Fixture = (props) => {
  const { parameter, endpoint, referenceName, hideColumns = false, onRowClick } = props;
  const { t_ } = useSmartTranslation();

  const columns = [
    {
      name: t_("Code"),
      field: "code",
      edit: false,
      charLimit: 60,
      mandatory: true,
      unique: true,
    },
    {
      name: t_("Name"),
      field: "name",
      edit: true,
      charLimit: 80,
      mandatory: true,
      unique: false,
    },
    {
      name: t_("Description"),
      field: "description",
      charLimit: 200,
    },
    {
      name: t_("Location"),
      field: "location.name",
      readOnly: true,
      hide: hideColumns,
    },
    {
      name: t_("Shelf"),
      field: "shelveStorageConfiguration.shelve",
      readOnly: true,
      hide: hideColumns,
    },
    {
      name: t_("Row"),
      field: "shelveStoragePosition.position",
      readOnly: true,
      hide: hideColumns,
    },
    {
      name: t_("Column"),
      field: "shelveStoragePosition.depth",
      readOnly: true,
      hide: hideColumns,
    },
    {
      name: t_("Entry date"),
      field: "entryDate",
      type: "dateTimePicker",
      readOnly: false,
      numeric: true,
      mandatory: true,
    }
  ];

  const formatDatePickerDate = (rows) => {

    rows.forEach((rawMaterial) => {
      let date = moment(rawMaterial.entryDate).format("YYYY-MM-DD[T]HH:mm:ssZ")
      rawMaterial.entryDate = date
    }

    );

    return rows
  };

  const actions = {
    edit: {
      fetchFunction: (row) => {
        if (row.entryDate) {
          let date = moment(row.entryDate).format("YYYY-MM-DD[T]HH:mm:ssZ")
          row.entryDate = date
        }
        return apiPut("fixtures", row);
      },
    },
    delete: {
      fetchFunction: (row) => {
        if (row.length === 1) {
          return apiDelete("fixtures/" + row[0].id, row);
        }
        else {
          row = formatDatePickerDate(row)
          return apiDelete("fixturesMulti", row);
        }
      },
    }
  };

  const defaultToolbar = {
    render: {
      fetchFunction: (extraFunctions) => {
        return (
          <Box component="span" sx={{ flexGrow: 1 }} style={{ textAlign: "end" }} >
            <AddElement
              parameter={parameter}
              endpoint="fixtures"
              referenceName={referenceName}
              title={t_("Add fixtures")}
              onAlert={extraFunctions.showAlert}
            /></Box>)

      }
    },
  }

  const disableRowActions = useRef(false)

  const dataFetch = async () => {
    const fixtures = await apiGet(endpoint + "/" + parameter)
    return fixtures
  };

  return (
    <div>
      <SmartMateriaUITable
        columns={columns}
        title={referenceName}
        dataFetch={dataFetch}
        actions={actions}
        multipleSelection={!hideColumns}
        dense={true}
        disableFlexGrow
        toolbar={defaultToolbar}
        setDisableRowActions={(event) => disableRowActions.current = event}
        onRowClick={(row) => {
          if (onRowClick && typeof onRowClick === 'function' && row.id) {
            onRowClick(row)
          }
        }}
      />
    </div>
  );
};

export default Fixture;
