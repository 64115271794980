import React, { useEffect, useRef } from "react";
import { apiDelete, apiGet, apiPost } from "../../generic/Api_Functions";
import SmartMateriaUITable from "../../generic/SmartMateriaUITable";
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router"
import AlertUI from "../../generic/AlertUI";

const Workers = () => {

  const { t_ } = useSmartTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [AlertElement, showAlert] = AlertUI();
  const show = useRef(showAlert)

  useEffect(() => {
    if (state === "BACK") {
      show.current({
        title: t_("There have been no modifications."),
        severity: "info",

      })
    } else if (state === "EDIT") {
      show.current({
        title: t_("Worker updated correctly."),
        severity: "success",
      })
    }
  }, [state, t_])

  const columns = [{
    name: t_("Code"),
    field: "code",
    charLimit: 70,
    mandatory: true,
    unique: true,
  },
  {
    name: t_("Name"),
    field: "name",
    charLimit: 70,
    mandatory: true,
    unique: true,
  },
  {
    name: t_("Last name"),
    field: "surname",
    charLimit: 70,
    mandatory: true,
  },
  {
    name: t_("Phone"),
    field: "tlf",
    charLimit: 70,
  },
  {
    name: t_("Email"),
    field: "email",
    charLimit: 70,
  },
  {
    name: t_("Calendar"),
    field: "calendar.name",
    value: "calendar.id",
    mandatory: true,
    type: "select",
    edit: {
      source: async () => {
        let calendarOptions = await apiGet("allCalendarReferences");
        return calendarOptions;
      },
      id: "id",
      field: "name",
    },
  },
  {
    name: t_("Skills"),
    field: "workerTechnologies.name",
    type: "smartMultiSelect",
    edit: {
      source: async () => {
        let technologyOptions = await apiGet("technologiesAndGroupsBasicData");
        return technologyOptions;
      },
      id: "fakeId",
      field: "name",
    },
    mandatory: true,
  },
  ]

  const actions = {
    custom: [{
      render: (row) => {
        return (
          <IconButton
            onClick={() => navigate("/configuration/workers/" + row.id, { replace: true })}
          >
            <Edit />
          </IconButton>
        )
      }
    }],
    create: {
      fetchFunction: (row) => {
        return apiPost("worker", row)
      },
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete("worker/" + rows[0].id, rows)
        }
        else {
          return apiDelete("workerMulti", rows)
        }
      },
    },
  }

  const fetchFunction = async (pagination) => {
    let workers = await apiGet('worker', pagination)
    return workers
  }
  return (
    <>{AlertElement}
      <SmartMateriaUITable
        columns={columns}
        title={t_("Workers")}
        dataFetch={fetchFunction}
        actions={actions}
        multipleSelection
        multipleOptions={true}
        pageable={true}
      />
    </>
  );
}

export default Workers;