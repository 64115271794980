import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/**
* Hook that will create a new pagination
*/
function usePagination (filters, pagination, pageableObject, columns, primaryKeys, sortColumnFieldPageable, sortOptions, filterText, columnFilters) {
  const [currentPagination, setCurrentPagination] = useState({})

  useEffect(() => {
    let newPagination
    let paramFilters = []
    let parameterValues = []
    let parameterNames = []

    if (filters !== undefined) {
      filters.forEach(filter => {
        parameterValues = [...parameterValues, filter.key]
        paramFilters = [...paramFilters, filter.field]
        parameterNames = [...parameterNames, filter.valueName]
      })
    }

    if (pagination !== undefined) {
      newPagination = { ...pagination }
      newPagination.page = pageableObject.number
      newPagination.size = pageableObject.size
    } else {
      newPagination = {
        parameterFilters: paramFilters,
        parameterFiltersValues: parameterValues,
        parameterFiltersNames: parameterNames,
        page: pageableObject.number,
        size: pageableObject.size,
        columns: columns.map((column) => column.field),
        genericSearch: '',
        rows: [],
        allSelected: false,
        primaryKeys,
        sortKey: [],
        sortMode: []
      }
    }

    /* Sorting */
    const filterColumn = columns.find(col => col.field === sortColumnFieldPageable.field)

    if (filterColumn !== undefined) {
      newPagination.sortKey = [sortColumnFieldPageable.field]
      newPagination.sortMode = [sortColumnFieldPageable.direction]
    }

    if (sortOptions !== undefined && Array.isArray(sortOptions)) {
      sortOptions.forEach(sortOption => {
        newPagination.sortKey = [...newPagination.sortKey, sortOption.field]
        newPagination.sortMode = [...newPagination.sortMode, sortOption.direction]
      })
    }

    /* Generic filter */
    if (filterText !== undefined && filterText !== '') {
      newPagination.genericSearch = filterText
    }

    /* Column filters */
    if (columnFilters !== undefined && Object.keys(columnFilters).length !== 0) {
      newPagination.columnFilters = Object.keys(columnFilters)
      newPagination.columnFilterValues = Object.values(columnFilters)
    }

    setCurrentPagination(newPagination)
  }, [filters, pagination, pageableObject.size, pageableObject.number, columns, primaryKeys, sortColumnFieldPageable, sortOptions, filterText, columnFilters])

  return currentPagination
}

export default usePagination

usePagination.propTypes = {
  /**
   * Request pagination object.Can be generated by usePagination hook. Is requiered
   */
  requestPagination: PropTypes.any.isRequired,
  /**
   * Multiple selection value object. Is requiered
   */
  multipleSelectionValue: PropTypes.any.isRequired,
  /**
   * Extra filter parameter object. Is requiered
   */
  extraFilterParameter: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired
  })
}
