import { ArrowRight, Close, Done } from '@mui/icons-material'
import { Button, ClickAwayListener, Paper, Popover, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import GenericToggleGroup from './buttons/toggleButtonGroups/GenericToggleGroup'
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";

const SmartMaterialUITableParameterFilters = (props) => {
  const { onToggleClick, openFilters, filters, handleCloseFilters, closeFilters, handleClick, anchorEl } = props
  const { t_ } = useSmartTranslation();

  const generateButtons = () => {
    const filterButtons = {
      passed: { value: "TRUE", color: '', content: <Done color="primary" /> },
      notPassed: { value: "FALSE", color: '', content: <Close color="error" /> }
    }
    return [filterButtons.passed, filterButtons.notPassed]
  }

  return (
    <div>
      <Button onClick={(event) => handleClick(event)}>
        <ArrowRight />
        {t_("FILTERS")}
      </Button>
      <Popover
        open={openFilters}
        anchorEl={anchorEl}
        onClose={handleCloseFilters}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ClickAwayListener
          onClickAway={closeFilters}>
          <Box component={Paper} style={{ border: "solid 1px #7FE1A7" }}>
            <div style={{ display: "flex" }}>
              {filters.map((filter, index) => (
                <div key={filter.name + index} style={{ margin: 5 }}>
                  <Typography key={"typography" + index + filter.name} fontSize={"0.8rem"} style={{ textAlign: "center" }}>{filter.name}</Typography>
                  <GenericToggleGroup
                    buttons={generateButtons(filter)}
                    onChange={(event) => onToggleClick(event, filter.name)}
                    allowNone={true}
                    exclusive
                    initialValue={filter.key}
                    widthFilter={"2rem"}
                    heightFilter={"2rem"}
                  />
                </div>
              ))}
            </div>
          </Box>
        </ClickAwayListener>
      </Popover >
    </div >
  )
}

export default SmartMaterialUITableParameterFilters