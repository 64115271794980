import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import multiMonthPlugin from '@fullcalendar/multimonth'
import { CalendarEventMenu, eventToMutableEvent, mutableEventToEvent, isEventValid } from './CalendarEventMenu'
import { Typography, Stack, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useState, useCallback, createRef, useEffect, useMemo } from 'react'
import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import moment from 'moment/moment'
import { rruleStringToObject } from './CalendarRepeatComponent'
import EventIcon from '@mui/icons-material/Event'
import SmartDialog from './../../generic/utilities/SmartDialog/SmartDialog'
import EditCalendarEventsToolbar from './EditCalendarEventsToolbar'
import AddIcon from '@mui/icons-material/Add'
import GenericFloatingButton from '../../generic/buttons/floatingButtons/GenericFloatingButton'
import SortIcon from '@mui/icons-material/Sort'
import SmartSortableContainer from '../../generic/smartSortable/SmartSortableContainer'
import JoinRightIcon from '@mui/icons-material/JoinRight'
import JoinFullIcon from '@mui/icons-material/JoinFull'
import GenereicSortableRenderItem from '../../generic/smartSortable/GenericSortableRenderItem'
import { DeleteOutlineRounded } from '@mui/icons-material'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import allLocales from '@fullcalendar/core/locales-all'

import AlertUI from '../../generic/AlertUI'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const EditCalendarEventsComponent = (props) => {
  const { calendarId, isReference = false } = props

  const calendarRef = createRef()
  const theme = useTheme()

  const [currentEditingEvent, setCurrentEditingEvent] = useState(null)
  const [deleteCalendarEventDialog, setDeleteCalendarEventDialog] = useState(false)
  const [editingEventName, setEditingEventName] = useState(null)
  const [AlertElement, showAlert] = AlertUI()
  const [showErrors, setShowErrors] = useState(false)
  const [calendar, setCalendar] = useState({})
  const [hideOverlaps, setHideOverlaps] = useState(false)
  const [currentSetDatesInfo, setCurrentSetDatesInfo] = useState(null)
  const [events, setEvents] = useState({})
  const [showEventSortDialog, setShowEventSortDialog] = useState(false)
  const [currentEventSorted, setCurrentEventSorted] = useState([])

  const [sortedEvents, setSortedEvents] = useState([])
  const [sortableItems, setSortableItems] = useState([])
  const [resetCalendar, setResetCalendar] = useState(false)
  const { t_, i18n } = useSmartTranslation()

  const isEditable = (!isReference || (calendar != null && !calendar.systemDefault))

  useEffect(() => {
    document.documentElement.style.setProperty('--fc-border-color', theme.palette.divider)
    document.documentElement.style.setProperty('--fc-page-bg-color', theme.palette.background.default)
    document.documentElement.style.setProperty('--fc-list-event-hover-bg-color', theme.palette.background.default)
  }, [theme])

  const getColorsForEvent = useCallback((event) => {
    const back1 = event.systemDefault ? theme.palette.calendar.systemDefault : (event.working ? theme.palette.info.dark : theme.palette.error.dark)
    return { background: back1, text: 'white' }
  }, [theme])

  const getOpacityForEvent = (event, hover) => {
    if (hover) {
      return 1
    }
    return isReference || !event.fromReference || hideOverlaps ? 0.85 : 0.65
  }

  useEffect(() => {
    const dataFetch = async () => {
      const newCalendar = await apiGet((isReference ? 'calendarReference/' : 'calendar/') + calendarId)
      setCalendar(newCalendar)
    }
    dataFetch()
  }, [calendarId, isReference])

  const eventListMapper = useCallback((calendarInfo, events) => {
    return events.map((event) => {
      const eventRef = calendarInfo.calendarEvents[event.groupId]
      const colors = getColorsForEvent(eventRef)

      return {
        groupId: event.groupId,
        title: eventRef.name,
        start: event.start,
        end: event.end,
        extendedProps: eventRef,
        backgroundColor: colors.background,
        borderColor: colors.background,
        textColor: colors.text,
        editable: (isReference || !eventRef.fromReference) && !eventRef.systemDefault
      }
    })
  }, [getColorsForEvent, isReference])

  const fetchEvents = useCallback((fetchInfo, successCallback, failureCallback) => {
    apiGet((isReference ? 'calendarReference/' : 'calendar/') + calendarId + '/event/' + hideOverlaps + '?startDate=' + fetchInfo.start.getTime() + '&endDate=' + fetchInfo.end.getTime())
      .then((calendarInfo) => {
        const fullCalendarEvents = eventListMapper(calendarInfo, calendarInfo.calendarEventInstances)

        const eventsThatCanBeSorted = []
        for (const key in calendarInfo.calendarEvents) {
          if (isReference || !calendarInfo.calendarEvents[key].fromReference) {
            eventsThatCanBeSorted.push(calendarInfo.calendarEvents[key])
          }
        }
        eventsThatCanBeSorted.sort((a, b) => b.priority - a.priority)

        setSortedEvents(eventsThatCanBeSorted)

        let sortableItems = eventsThatCanBeSorted.map((event) => {
          if (event.originalReferenceId !== null) {
            return { isGroup: true, id: event.id, refId: event.originalReferenceId, name: event.originalReferenceName }
          } else {
            return { isGroup: false, id: event.id, name: event.name }
          }
        })
        const seen = new Set()
        // Remove group duplicates
        sortableItems = sortableItems.filter((event) => {
          if (!event.isGroup) {
            return true
          }
          if (seen.has(event.refId)) {
            return false
          } else {
            seen.add(event.refId)
            return true
          }
        })
        setSortableItems(sortableItems)

        setEvents(calendarInfo.calendarEvents)

        successCallback(fullCalendarEvents)
      })
      .catch(failureCallback)
  }, [calendarId, isReference, eventListMapper, hideOverlaps])

  const onRangeSelected = (event) => {
    const startTime = event.start
    const endTime = event.end
    if (event.allDay) {
      startTime.setHours(9)
      endTime.setHours(15)
    }
    calendarRef.current.calendar.unselect()
    setCurrentEditingEvent({ id: null, name: '', startDate: event.start, endDate: event.end, startTime, endTime, rrule: rruleStringToObject(null, event.end), working: true })
  }

  const onEventChange = async (changeInfo) => {
    // NO change allopwed in overlaps mode
    if (hideOverlaps) {
      changeInfo.revert()
      return false
    }

    // Calculate new data
    const event = { ...changeInfo.event.extendedProps }
    // If it is a repeating event only change times
    if (changeInfo.event.extendedProps.rrule === null) {
      event.startDate = moment(changeInfo.event.start).format('yyyy-MM-DD')
      event.endDate = moment(changeInfo.event.end).format('yyyy-MM-DD')
    }

    event.startTime = moment(changeInfo.event.start).format('HH:mm')

    event.endTime = moment(changeInfo.event.end).format('HH:mm')
    await apiPut('calendarEvents/event', event)
    calendarRef.current.calendar.refetchEvents()
  }

  const onCurrentEditingEventChanged = (newEvent) => {
    setCurrentEditingEvent(newEvent)
  }

  const eventOrder = (event1, event2) => {
    // If one is from reference and other no. Set reference one back
    if (event1.extendedProps.fromReference !== event2.extendedProps.fromReference) {
      return event1.extendedProps.fromReference ? -1 : 1
    }
    // Check event order otherwise
    return event1.priority > event2.priority ? 1 : -1
  }

  const onAcceptEventEdit = async () => {
    // Check if its valid
    if (isEventValid(currentEditingEvent)) {
      const event = mutableEventToEvent(currentEditingEvent)
      if (event.id) {
        await apiPut('calendarEvents/event', event)
      } else {
        await apiPost((isReference ? 'calendarReference/' : 'calendar/') + calendarId + '/event', event)
      }
      setCurrentEditingEvent(null)
      // Update calendar
      calendarRef.current.calendar.refetchEvents()
    } else {
      setShowErrors(true)
    }
  }

  const onAcceptEventOrderChange = async () => {
    const newEvents = []

    let priority = 1
    // Iterate backwards (Lower priority first)
    for (let i = currentEventSorted.length - 1; i >= 0; i--) {
      const item = currentEventSorted[i]
      if (item.isGroup) {
        // Expand all group events
        const expanded = sortedEvents.filter((event) => event.originalReferenceId === item.refId)
        for (let z = 0; z < expanded.length; z++) {
          expanded[z].priority = priority
          newEvents.push(expanded[z])
          priority++
        }
      } else {
        const event = events[item.id]
        event.priority = priority
        newEvents.push(event)
        priority++
      }
    };

    await apiPut('calendarEvents/events', newEvents)
    calendarRef.current.calendar.refetchEvents()
    setShowEventSortDialog(false)
  }

  const onDateChange = (event) => {
    if (event === 'prev') {
      calendarRef.current.calendar.prev()
    } else if (event === 'next') {
      calendarRef.current.calendar.next()
    } else if (event === 'today') {
      calendarRef.current.calendar.today()
    }
  }
  const handleDelete = async (row) => {
    try {
      await apiDelete('calendarEvents/event/' + row.id)
      showAlert({ severity: 'success', title: t_('Successfully removed') })
      setResetCalendar(true)
    } catch (error) {
      showAlert({
        title: '',
        message: t_('An error has occurred'),
        severity: 'error'
      })
    }
  }
  useEffect(() => {
    if (resetCalendar) {
      calendarRef.current.calendar.refetchEvents()
      setResetCalendar(!resetCalendar)
    }
  }, [resetCalendar, calendarRef])
  const columns = [
    {
      name: t_('Name'),
      field: 'name'
    },
    {
      name: t_('Start date'),
      field: 'startDate',
      render: (row) => {
        return moment(row.startDate).format('YYYY-MM-DD')
      }
    },
    {
      name: t_('End date'),
      field: 'endDate',
      render: (row) => {
        return moment(row.endDate).format('YYYY-MM-DD')
      }
    },
    {
      name: t_('Start hour'),
      field: 'startTime',
      render: (row) => {
        return moment(row.startTime).format('HH:mm')
      }
    },
    {
      name: t_('End hour'),
      field: 'endTime',
      render: (row) => {
        return moment(row.endTime).format('HH:mm')
      }
    }
  ]
  const locale = useMemo(() => {
    if (i18n.language.startsWith('es')) {
      return 'es'
    } else if (i18n.language.startsWith('fr')) {
      return 'fr'
    } else if (i18n.language.startsWith('en')) {
      return 'en-gb'
    } else if (i18n.language.startsWith('pt')) {
      return 'pt'
    } else if (i18n.language.startsWith('ca')) {
      return 'ca'
    }
    return 'en-gb'
  }, [i18n.language])
  return (
    <>
      <SmartDialog
        title={{ icon: <EventIcon fontSize='large' />, render: <Typography variant='h4'>{currentEditingEvent && currentEditingEvent.id !== null ? editingEventName : t_('Create new event')}</Typography> }}
        setOpen={currentEditingEvent !== null}
        renderComponent={currentEditingEvent !== null
          ? <CalendarEventMenu
              calendarEvent={currentEditingEvent}
              onCalendarEventChange={onCurrentEditingEventChanged}
              showErrors={showErrors}
            />
          : null}
        close={false}
        cancel
        accept={currentEditingEvent && !currentEditingEvent.systemDefault}
        cancelCallback={() => {
          setShowErrors(false)
          onCurrentEditingEventChanged(null)
        }}
        renderCustomButtons={
          currentEditingEvent && currentEditingEvent.id != null && !currentEditingEvent.systemDefault
            ? (
              <Button
                color='error' onClick={() => {
                  setDeleteCalendarEventDialog(true)
                }} variant='outlined'
              >
                <Typography color='error'> {t_('DELETE')} </Typography>
                <DeleteOutlineRounded
                  color='error' sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }}
                />
              </Button>
              )
            : null
}
        acceptCallback={onAcceptEventEdit}
      />
      <SmartDialog
        title={{ icon: <SortIcon fontSize='large' />, render: <Typography variant='h5'>{t_('Event priority')}</Typography> }}
        setOpen={showEventSortDialog}
        renderComponent={
          <SmartSortableContainer
            itemList={sortableItems}
            itemId='id'
            renderItem={(renderData) => <GenereicSortableRenderItem labels={[renderData.item.name]} />}
            onSortChanged={setCurrentEventSorted}
          />
        }
        close={false}
        cancel
        cancelCallback={() => {
          setShowEventSortDialog(false)
        }}
        acceptCallback={onAcceptEventOrderChange}
      />
      <SmartDialog
        message={t_("Are you sure you want to delete the event? You can't undo this action.")}
        title={{ icon: <SortIcon fontSize='large' />, render: <Typography variant='h5'>{t_('Delete calendar event')}</Typography> }}
        setOpen={deleteCalendarEventDialog}
        renderComponent={
          <SmartMateriaUITable
            columns={columns}
            dataFetch={new Array(currentEditingEvent)}
          />
        }
        close={false}
        cancel
        accept={false}
        cancelCallback={() => {
          setDeleteCalendarEventDialog(false)
        }}
        renderCustomButtons={
          currentEditingEvent && currentEditingEvent.id != null && !currentEditingEvent.systemDefault
            ? (
              <Button
                color='error' onClick={() => {
                  setCurrentEditingEvent(null)
                  setDeleteCalendarEventDialog(false)
                  handleDelete(currentEditingEvent)
                }} variant='outlined'
              >
                <Typography color='error'> {t_('DELETE')} </Typography>
                <DeleteOutlineRounded
                  color='error' sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }}
                />
              </Button>
              )
            : null
}
      />

      <Stack spacing={2}>

        <EditCalendarEventsToolbar
          fullCalendarRef={calendarRef}
          calendarReference={isReference ? null : calendar !== null ? calendar.calendarReference : null}
          calendarId={calendar !== null ? calendar.id : null}
          isLinked={calendar !== null ? calendar.calendarReferenceLinked : false}
          setDatesInfo={currentSetDatesInfo}
          onViewChanged={(view) => calendarRef.current.calendar.changeView(view)}
          onDataRefreshRequired={() => calendarRef.current.calendar.refetchEvents()}
          onDateChange={onDateChange}
          fullCalendar={calendarRef.current !== null ? calendarRef.current.calendar : null}
        />
        {AlertElement}
        <div style={{ backgroundColor: theme.palette.background.default }}>
          <FullCalendar
            locales={allLocales}
            locale={locale}
            stickyHeaderDates={false}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, multiMonthPlugin]}
            ref={calendarRef}
            initialView='timeGridWeek'
            nowIndicator
            allDaySlot={false}
            eventDidMount={(info) => {
              info.el.style.opacity = getOpacityForEvent(info.event.extendedProps, false)
            }}
            datesSet={(setDatesInfo) => setCurrentSetDatesInfo(setDatesInfo)}
            eventChange={onEventChange}
            events={fetchEvents}
            select={isEditable
              ? (event) => {
                  onRangeSelected(event)
                }
              : null}
            height='auto'
            displayEventTime
            selectable
            eventClick={isEditable
              ? (info) => {
                  if (isReference || !info.event.extendedProps.fromReference) {
                    setEditingEventName(info.event.extendedProps.name)
                    setCurrentEditingEvent(eventToMutableEvent(info.event.extendedProps))
                  }
                }
              : null}
            eventOrderStrict
            eventOrder={eventOrder}
            slotDuration='00:30'
            slotLabelInterval='00:30'
            slotLabelFormat={(info) => {
              if (info.date.minute === 0) {
                return info.date.hour.toString() + ':00'
              } else {
                return ''
              }
            }}
            firstDay={1}
            headerToolbar={null}
            eventMouseEnter={(info) => {
              info.el.style.opacity = getOpacityForEvent(info.event.extendedProps, true)
            }}
            eventMouseLeave={(info) => {
              info.el.style.opacity = getOpacityForEvent(info.event.extendedProps, false)
            }}
            moreLinkClick='timeGridWeek'
            lazyFetching={false}
          />
        </div>
      </Stack>
      <Stack
        direction='row' gap={3} sx={{
          position: 'fixed',
          bottom: theme => theme.spacing(2),
          right: theme.spacing(10),
          zIndex: 100
        }}
      >

        <GenericFloatingButton
          label={hideOverlaps ? t_('Show overlaps') : t_('Hide overlaps')}
          icon={hideOverlaps ? <JoinRightIcon /> : <JoinFullIcon />}
          color={theme.palette.warning.light}
          opacity={0.85}
          onClick={() => { setHideOverlaps(!hideOverlaps) }}
        />

        <GenericFloatingButton
          label={t_('Priority')}
          icon={<SortIcon />}
          color={theme.palette.warning.light}
          opacity={0.85}
          onClick={() => { setShowEventSortDialog(true) }}
        />
        <GenericFloatingButton
          label={t_('Add')}
          icon={<AddIcon />}
          color={theme.palette.primary.main}
          opacity={0.85}
          onClick={() => {
            const endDate = new Date()
            endDate.setHours(endDate.getHours() + 1)
            onRangeSelected({ start: new Date(), end: endDate })
          }}
        />
      </Stack>
    </>
  )
}

export default EditCalendarEventsComponent
