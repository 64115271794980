import { Close, Done, Sort } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import React, { useRef, useState } from 'react'
import AlertUI from '../../generic/AlertUI'
import { apiGet, apiPut } from '../../generic/Api_Functions'
import GenericToggleGroup from '../../generic/buttons/toggleButtonGroups/GenericToggleGroup'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import ToolTypeToolParameterSorting from './ToolTypeToolParameterSorting'
import createDialogTitle from '../../generic/utilities/dialogUtil'
import { useToggle } from '../../generic/hooks/useToggle'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const ToolTypeToolParameter = (props) => {
  const { toolType } = props
  const [viewAll,
    setViewAll] = useState(false)
  const forceLoad = useRef(false)
  const [AlertElement, showAlert] = AlertUI()
  const [toolTypeParameters, setToolTypeParameters] = useState([])
  const [showParameterSorting, setShowParameterSorting] = useState(false)
  const [sortedToolTypeParameters, setSortedToolTypeParameters] = useState([])
  const [toggle, resetPage] = useToggle()
  const { t_ } = useSmartTranslation()

  const toolbarButtonsInfo = {
    assigned: {
      value: 'ASSIGNED',
      color: 'info',
      content: t_('ASSIGNED')
    },
    all: {
      value: 'ALL',
      color: 'warning',
      content: t_('ALL')
    }
  }
  const operationFailedAlert = (error) => {
    return {
      title: t_('Update error'),
      severity: error.name,
      message: (
        <div>
          <div>{t_('If error persists, contact with support:')}</div>
          <div>{error}</div>
        </div>)
    }
  }

  const operationSuccessAlert = {
    severity: 'success',
    title: t_('Update successful'),
    message: t_('The operation was correctly performed')
  }

  const handleClick = (row, field) => {
    /**
     * Default behaviour is a click over mandatory button
     * So the action is to change the tool type parameter
     */
    const fetchOptions = {
      action: 'change',
      mandatory: '/' + !row.mandatory
    }

    /**
     * In this case, the parameter was not assigned,
     * so the action must be to assign it and also set it as mandatory
     */
    if (!row.assigned) {
      fetchOptions.action = 'add'
    }

    /**
     * Action is performed in the assigned button
     * Just remove or add the parameter depending on the previous assigned value
     */
    if (field === 'assigned') {
      fetchOptions.action = row.assigned ? 'remove' : 'add'
      fetchOptions.mandatory = ''
    }

    apiGet('toolTypeParameters/' + toolType.id + '/' + fetchOptions.action + 'Parameter/' + row.parameterId + fetchOptions.mandatory)
      .then(res => {
        forceLoad.current = !forceLoad.current
        showAlert(operationSuccessAlert)
      })
      .catch(error =>
        showAlert(operationFailedAlert(error.message))
      )
  }

  const getTableButton = (row, field) => {
    return (
      <IconButton disabled={row.systemDefault} onClick={() => handleClick(row, field)}>
        {row[field] ? <Done color='primary' /> : <Close color='error' />}
      </IconButton>
    )
  }

  const columns = [
    {
      name: '#',
      field: 'parameterOrder'
    },
    {
      name: t_('Code'),
      field: 'parameterCode'
    },
    {
      name: t_('Name'),
      field: 'parameterName'
    },
    {
      name: t_('Description'),
      field: 'parameterDescription'
    },
    {
      name: t_('Assigned'),
      field: 'assigned',
      render: row => getTableButton(row, 'assigned')
    },
    {
      name: t_('Mandatory'),
      field: 'mandatory',
      render: row => getTableButton(row, 'mandatory')
    }
  ]

  const toggleAssigned = (value) => {
    setViewAll(value === toolbarButtonsInfo.all.value)
    forceLoad.current = !forceLoad.current
    resetPage()
  }

  const dataFetch = async () => {
    const toolParameters = await apiGet('toolTypeParameters/' + toolType.id)
    const filteredToolParameters = toolParameters.filter(toolParameter => toolParameter.assigned)
    setToolTypeParameters(filteredToolParameters)
    if (viewAll) return toolParameters
    return filteredToolParameters
  }

  const handleSortingChange = (sortedParameters) => {
    // Sorting change has been performed
    setSortedToolTypeParameters(sortedParameters)
  }
  const saveSorting = () => {
    // Send changes to backend
    const fetchNew = sortedToolTypeParameters.map((param, index) => { return { ...param, parameterOrder: index + 1 } })
    apiPut('toolTypeParameters/' + toolType.id + '/changeParameterSorting', fetchNew)
      .then(res => showAlert(operationSuccessAlert))
      .catch(error => {
        showAlert(operationFailedAlert(error.message))
      }
      )
    setShowParameterSorting(false)
  }

  const toolbar = {
    render: {
      fetchFunction: () => {
        return (
          <>
            <GenericToggleGroup
              buttons={[toolbarButtonsInfo.assigned, toolbarButtonsInfo.all]}
              exclusive
              onChange={(value) => toggleAssigned(value)}
            />
            <Button
              style={{ marginLeft: '2rem' }}
              variant='outlined'
              onClick={() => {
                setSortedToolTypeParameters(toolTypeParameters)
                setShowParameterSorting(true)
              }}
            >
              {t_('Set default sorting')}
            </Button>
          </>
        )
      }
    }
  }

  const manageParameterSortingDialog = () => {
    if (!showParameterSorting) return null
    return (
      <SmartDialog
        title={createDialogTitle({ title: toolType.name.toUpperCase() + ' ' + t_('PARAMETERS ORDER'), icon: Sort })}
        acceptCallback={saveSorting}
        cancelCallback={() => setShowParameterSorting(false)}
        setOpen
        renderComponent={
          <ToolTypeToolParameterSorting
            sortingChange={handleSortingChange}
            toolTypeParameters={sortedToolTypeParameters}
          />
      }
      />
    )
  }

  return (
    <>
      {AlertElement}
      {manageParameterSortingDialog()}
      <SmartMateriaUITable
        columns={columns}
        title={toolType.name + ' ' + t_('Parameters')}
        toolbar={toolbar}
        dataFetch={dataFetch}
        forceLoad={forceLoad.current}
        resetPage={toggle}
      />
    </>
  )
}

export default ToolTypeToolParameter
