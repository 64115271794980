import { Close } from '@mui/icons-material'
import { Alert, Box, Collapse, Container, IconButton, Typography } from '@mui/material'

const CompassWindow = (props) => {
  const { renderTitle, render, onClose, message, onCloseMessage, containerWidth = 'md' } = props

  const floatButton = {
    float: 'right',
    marginRight: '-20px',
    marginTop: '-20px'
  }
  const buttonCss = {
    border: '1px solid #D0D0D0 ',
    backgroundColor: '#303030',
    color: '#D9D9D9'
  }

  return (
    <Container maxWidth={containerWidth} style={{ marginTop: '1%', color: 'white', backgroundColor: 'rgb(66, 66, 66)', padding: 0, border: '1px solid white' }}>
      <IconButton type='button' sx={{ p: '10px' }} onClick={onClose} style={{ ...buttonCss, ...floatButton }}>
        <Close />
      </IconButton>
      {typeof renderTitle === 'string' ? <Typography color='primary' sx={{ textAlign: '', padding: '5px 25px ' }}>{renderTitle}</Typography> : renderTitle}
      <Container maxWidth={containerWidth} style={{ marginTop: ' 0', color: 'white', backgroundColor: 'rgb(66, 66, 66)', padding: '5px 25px 25px', borderTop: '1px solid white' }}>
        <Box>
          <Collapse in={message && message.open}>
            <Alert
              variant='filled' severity={message && message.severity ? message.severity : 'error'} style={{ color: 'white' }}
              action={
                <IconButton aria-label='close' color='inherit' size='small' onClick={onCloseMessage}>
                  <Close fontSize='inherit' />
                </IconButton>
            }
              sx={{ mb: 2 }}
            >
              {message && message.content ? message.content : null}
            </Alert>
          </Collapse>
        </Box>
        {render}
      </Container>
    </Container>
  )
}

export default CompassWindow
