import React, { useState } from 'react'
import Presence from '../Compass/Presence/Presence'
import Production from '../Compass/Production/Production'
import { MainCompassNav } from '../Compass/MainCompassNav'
import Supervision from '../Compass/Supervision/Supervision'
import Inspect from '../Compass/Inspect/Inspect'
import PresenceHistory from '../Compass/Presence/PresenceHistory'
import PresenceLogin from '../Compass/Presence/PresenceLogin'
import Process from '../Compass/Process/Process'
import ViewPart from '../Compass/Common/ViewPart/ViewPart'
import ProductionFabricationOrderList from '../Compass/Production/ProductionFabricationOrderList'
import ProductionQuantity from '../Compass/Production/ProductionQuantity'
import ProductionState from '../Compass/Production/ProductionState'
import ProductionReportQuantity from '../Compass/Production/ProductionReportQuantity'
import SupervisionStateList from '../Compass/Supervision/SupervisionStateList'
import SupervisionState from '../Compass/Supervision/SupervisionState'
import SupervisionIncidentList from '../Compass/Supervision/SupervisionIncidentList'
import SupervisionIncident from '../Compass/Supervision/SupervisionIncident'
import InspectControlProcedure from '../Compass/Inspect/InspectControlProcedure'
import InspectHistory from '../Compass/Inspect/InspectHistory'
import InspectReportFeature from '../Compass/Inspect/InspectReportFeature'

/**
 *
 * @param {object} props
 * @param {'PRESENCE'|'PRODUCTION'|'SUPERVISION'|'INSPECT'|'PROCESS'
 * |'PRESENCE_HISTORY'|'AUTHENTICATION'|'VIEW_FILE'
 * |'FABRICATION_ORDER_LIST'|'QUANTITY'|'PRODUCTION_STATE'|'REPORT_QUANTITY'
 * |'STATE_LIST'|'SUPERVISION_STATE'|'INCIDENT_LIST'|'INCIDENT'
 * |'ACTION'|'COMMAND'|'COMMAND_LOCATION'|'COMMAND_RESOURCE'
 * |'CONTROL_PROCEDURE'|'INSPECT_HISTORY'|'REPORT_FEATURES'} props.option
 * @returns
 */
const Compass = ({ option }) => {
  const [fullScreen, setFullScreen] = useState()

  const getComponent = () => {
    let Component
    let actionPath

    switch (option) {
      case 'PRESENCE': Component = Presence; break
      case 'PRESENCE_HISTORY': Component = PresenceHistory; break
      case 'AUTHENTICATION': Component = PresenceLogin; break
      case 'VIEW_FILE': Component = ViewPart; break

      case 'PRODUCTION': Component = Production; break
      case 'FABRICATION_ORDER_LIST': Component = ProductionFabricationOrderList; break
      case 'QUANTITY': Component = ProductionQuantity; break
      case 'PRODUCTION_STATE': Component = ProductionState; break
      case 'REPORT_QUANTITY': Component = ProductionReportQuantity; break

      case 'SUPERVISION': Component = Supervision; break
      case 'STATE_LIST': Component = SupervisionStateList; break
      case 'SUPERVISION_STATE': Component = SupervisionState; break
      case 'INCIDENT_LIST': Component = SupervisionIncidentList; break
      case 'INCIDENT': Component = SupervisionIncident; break

      case 'PROCESS': Component = Process; break
      case 'ACTION': Component = Process; actionPath = 'action'; break
      case 'COMMAND': Component = Process; actionPath = 'command'; break
      case 'COMMAND_LOCATION': Component = Process; actionPath = 'command/byLocation'; break
      case 'COMMAND_RESOURCE': Component = Process; actionPath = 'command/byResource'; break

      case 'INSPECT': Component = Inspect; break
      case 'CONTROL_PROCEDURE': Component = InspectControlProcedure; break
      case 'INSPECT_HISTORY': Component = InspectHistory; break
      case 'REPORT_FEATURES': Component = InspectReportFeature; break

      default: Component = Presence; break
    }
    return <Component fullScreen={fullScreen} setFullScreen={setFullScreen} activePath={actionPath} />
  }

  return (
    <MainCompassNav element={getComponent()} />
  )
}

export default Compass
