import React from 'react'
import Workflows from '../configuration/workflows/Workflow'
import SmartNavigator from '../generic/SmartNavigator'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

const MainWorkflows = () => {
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('Workflows'),
      component: <Workflows />
    }
  ]
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  )
}

export default MainWorkflows
