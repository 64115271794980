import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const VerticalResourceAssembly = (props) => {
  const { items, renderTopItem, showAll, onResourceClick = null, selectedIndex = -1 } = props;

  let topItem = showAll ? null : renderTopItem(items.length > 0 ? items[0] : null);
  // Inside tolltip string is not allowed
  if (!React.isValidElement(topItem)) {
    topItem = <span>{topItem}</span>
  }

  const showAllItems = () => {
    return (
      <ToggleButtonGroup orientation="vertical" sx={{ backgroundColor: !onResourceClick ? "Background" : null }}>
        {items.map(
          (resource, index) => {
            return (
              <ToggleButton
                onClick={() => { if (onResourceClick) onResourceClick(resource, index) }}
                key={resource.type.name + "-" + resource.id}
                color={onResourceClick ? "primary" : "standard"}
                selected={onResourceClick === null || (selectedIndex !== null && selectedIndex >= index)}
                disabled={!onResourceClick}
                value={resource.id}
              >{resource.code}</ToggleButton>
            )
          }
        )}
      </ToggleButtonGroup >
    );
  }

  return (

    showAll ? showAllItems() :
      items.length > 1 ?
        <Tooltip
          title={showAllItems()}
          componentsProps={{ tooltip: { sx: { backgroundColor: "transparent" } } }}
        >
          {topItem}
        </ Tooltip >
        : topItem
  );

};

VerticalResourceAssembly.propTypes = {
  /**
   * List of items
   */
  items: PropTypes.array.isRequired,
  /**
   * Method that will be triggered to render the item on top. First parameter will be the item. Required if showAll is false
   */
  renderTopItem: PropTypes.func,

  /**
   * If true all resources will be shown instead of using tooltip aproach
   */
  showAll: PropTypes.bool,

  /**
   * Function to be called when a resource is clicked
   */
  onResourceClick: PropTypes.func,

  /**
   * Index to show as selected. All previous ones will be also selected
   */
  selectedIndex: PropTypes.number,
}

export default VerticalResourceAssembly;