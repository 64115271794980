import React, { useEffect, useRef, useState } from 'react'
import { TextField, MenuItem, Grid } from '@mui/material'
import { DefaultAccordion } from '../../generic/DefaultAccordion'
import ButtonBackSave from '../../generic/buttons/floatingButtons/ButtonBackSave'
import { apiGet, apiPut } from '../../generic/Api_Functions'
import { useNavigate } from 'react-router-dom'
import AlertUI from '../../generic/AlertUI'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

// Import typedefs from another file
/**
 * @typedef {import('../../generic/utilities/objectStructures/settings/settings').ConfigurationSection} ConfigurationSection ConfigurationSection object
 */

const Settings = (props) => {
  const backSaveButtonRef = useRef()
  const { t_ } = useSmartTranslation()
  const [editMode, setEditMode] = useState(false)
  const { reloadTopBar, setReloadTopBar } = props
  /** @type {[ConfigurationSection[], React.Dispatch<SetStateAction<ConfigurationSection[]>>]} */
  const [settings, setSettings] = useState([])
  const [AlertElement, showAlert] = AlertUI()
  const changeBetweenVisualizeModes = (edit) => {
    setEditMode(edit)
  }
  const navigate = useNavigate()

  const saveChanges = async () => {
    try {
      const sendData = []
      settings.forEach((item) => {
        item.configurations.forEach((conf) => {
          sendData.push(conf)
        })
      })
      await apiPut('settings', sendData).then(() => { showAlert({ severity: 'success', title: 'saved correctly', message: '  ' }) })
      setReloadTopBar(!reloadTopBar)
    } catch (error) {
      showAlert({ severity: 'error', title: ('Error saving'), message: '  ' })
    }
  }

  useEffect(() => {
    const fetchFunction = async () => {
      try {
        const fetchedData = await apiGet('settings')
        setSettings(fetchedData)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    if (settings.length === 0) {
      fetchFunction()
    }
  }, [settings])

  const goBack = () => {
    navigate(-1)
  }
  const renderedElements = settings.map((setting, settingIndex) => (
    <DefaultAccordion title={t_(setting.name)} key={settingIndex}>
      <Grid container direction='row' spacing={5} columns={{ xs: 1, sm: 4, md: 8, lg: 12 }}>
        {setting.configurations.map((configuration, configurationIndex) => (
          <Grid key={'Grid' + configuration.key + configurationIndex} item xs={configuration.key === 'Language' ? 1.5 : 3}>
            {
              configuration.type === 'String'
                ? (
                  <TextField
                    fullWidth
                    label={t_(configuration.name)}
                    value={configuration.value}
                    onChange={(event) => {
                      const newSettings = [...settings]
                      newSettings[settingIndex].configurations[configurationIndex].value = event.target.value
                      setSettings(newSettings)
                    }}
                    disabled={!editMode}
                    InputProps={{
                      readOnly: !editMode
                    }}
                  />
                  )
                : (
                  <TextField
                    select
                    fullWidth
                    label={t_(configuration.name)}
                    value={configuration.value}
                    onChange={(event) => {
                      const newSettings = [...settings]
                      newSettings[settingIndex].configurations[configurationIndex].value = event.target.value
                      setSettings(newSettings)
                    }}
                    disabled={!editMode}
                  >

                    {configuration.values && configuration.values.map((configurationValue, configurationValueIndex) => (
                      <MenuItem key={configurationValueIndex} value={configurationValue.value}>
                        {configurationValue.value}
                      </MenuItem>
                    ))}

                  </TextField>
                  )
            }
          </Grid>
        ))}
      </Grid>
    </DefaultAccordion>
  ))

  return (
    <>
      {AlertElement}
      {renderedElements}
      <ButtonBackSave
        editControl={() => changeBetweenVisualizeModes(!editMode)}
        backButtonAction={() => goBack('BACK')}
        saveButtonAction={() => { saveChanges() }}
        ref={backSaveButtonRef}
        useOnSaveClick
      />
    </>
  )
}
export default Settings
