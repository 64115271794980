import { Box, Button, IconButton, Typography } from "@mui/material";
import SmartMateriaUITable from "../generic/SmartMateriaUITable";
import { DashboardCustomizeRounded, Lock, LockOpenRounded, ManageSearchOutlined, WindowRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { apiGet, apiPost, apiPut } from "../generic/Api_Functions";
import AlertUI from "../generic/AlertUI";
import { SmartDialog } from "../generic/utilities/SmartDialog";
import createDialogTitle from "../generic/utilities/dialogUtil";
import CheckDialog from "../generic/CheckDialog";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";

const Storage = (props) => {
  const navigate = useNavigate();
  const [AlertElement, showAlert] = AlertUI();
  const [emptyAllDialogOpen, setEmptyAllDialogOpen] = useState(false);
  const [fillAllDialogOpen, setFillAllDialogOpen] = useState(false);
  const [unlockStorageDialogOpen, setUnlockStorageDialogOpen] = useState(false);
  const [currentSelectedStorageId, setCurrentSelectedStorageId] = useState(null)
  const [showCheckDialog, setShowCheckDialog] = useState(false);
  const { t_ } = useSmartTranslation();

  const isCellRunning = async () => {
    let status = await apiGet("cellGeneralStatus");
    return status === "AUTOMATIC";
  }

  const columns = [
    {
      name: t_("Name"),
      field: "name",
      defaultNewValue: "",
      mandatory: true,
    },
    {
      name: t_("Locked"),
      field: "isDeviceBooked",
      render: (row) => (row.deviceBooked ?
        <IconButton onClick={() => {
          checkBookedStorage(row.id)
        }}>< Lock color='error' /></IconButton> :
        <LockOpenRounded color='primary' style={{ marginLeft: '0.5rem' }} />)
    },
    {
      name: t_("Positions (avail. / total)"),
      field: "isDeviceBooked",
      render: (row) => (row.availablePositions + "/" + row.totalPositions)
    },
  ];

  const actions = {
    custom: [
      {
        render: (row) => (
          <IconButton
            onClick={() => navigate("/process/storagePosition/" + row.id)}
          >
            <ManageSearchOutlined />
          </IconButton >
        ),
      },
    ]
  };

  const defaultToolbar = {
    render: {
      fetchFunction: () => {
        return (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <Button startIcon={<WindowRounded />} color="primary" variant="text" onClick={() => { setEmptyAllDialogOpen(true); }}>{t_("Empty All")}</Button>
            <Button startIcon={<DashboardCustomizeRounded />} color="primary" variant="text" onClick={() => { setFillAllDialogOpen(true); }}>{t_("Fill All")}</Button>
          </>
        )
      },
    }
  }

  const fillAll = () => {
    apiPost("shelveStoragePositions/fillAll")
      .then(() => {
        showAlert({
          title: t_("Success"),
          message: t_("Storage has been succesfully filled"),
          severity: "success",
        })
      })
      .catch((error) => {
        showAlert({
          title: t_("Error filling storage"),
          message: error.message,
          severity: error.name
        })
      })
    setFillAllDialogOpen(false)
  }

  const emptyAll = () => {
    apiPost("shelveStoragePositions/emptyAll")
      .then(() => {
        showAlert({
          title: t_("Success"),
          message: t_("Storage has been succesfully emptied"),
          severity: "success",
        })
      })
      .catch((error) => {
        showAlert({
          title: t_("Error emptying storage"),
          message: error.message,
          severity: error.name
        })
      })
    setEmptyAllDialogOpen(false);
  }

  const unlockStorage = async () => {
    await apiPut("unlockStorage/" + currentSelectedStorageId).then(() => {
      showAlert({
        title: t_("Storage successfully unlocked."),
      });
    }).catch((error) => {
      showAlert({
        title:
          t_("An error occurred while unlocking storage."),
        message: error.message,
        severity: error.name
      })
    })
  }

  const checkBookedStorage = async (storageId) => {
    let response = await apiGet("storage/isBooked/" + storageId);
    if (response) {
      setCurrentSelectedStorageId(storageId);
      setUnlockStorageDialogOpen(true)
    }
    else {
      showAlert({
        title: t_("Error"),
        message: t_("Storage is locked by the system, please wait"),
        severity: "error",
      })
    }
  };

  return (
    <>
      {AlertElement}
      <SmartDialog setOpen={emptyAllDialogOpen} message={t_("Are you sure you want to empty all the parts from this storage?")} title={createDialogTitle({ title: t_("Empty all positions?"), icon: WindowRounded })}
        accept={false} cancelCallback={() => setEmptyAllDialogOpen(false)} close={false} renderCustomButtons={<Button style={{ borderColor: theme => theme.palette.primary.main }} onClick={() => { emptyAll(); }} variant="outlined">
          <Typography color="primary">{t_("EMPTY")}</Typography>
          <WindowRounded color="primary" sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
        </Button >} />
      <SmartDialog setOpen={fillAllDialogOpen} message={t_("Are you sure you want to Fill all Storages?")} title={createDialogTitle({ title: t_("Fill All Storages?"), icon: DashboardCustomizeRounded })}
        accept={false} cancelCallback={() => setFillAllDialogOpen(false)} close={false} renderCustomButtons={<Button sx={{ borderColor: theme => theme.palette.primary.main }} onClick={() => { fillAll(); }} variant="outlined">
          <Typography color="primary">{t_("FILL ALL")}</Typography>
          <DashboardCustomizeRounded color="primary" sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
        </Button >} />
      <SmartDialog
        setOpen={unlockStorageDialogOpen}
        message={t_("Are you sure you want to unlock this storage?")}
        title={createDialogTitle({ title: t_("Unlock storage?"), icon: LockOpenRounded })}
        accept={true}
        cancel={true}
        acceptCallback={async () => {
          let running = await isCellRunning();
          running ?
            setShowCheckDialog(true) : unlockStorage();
          setUnlockStorageDialogOpen(false);
        }}
        cancelCallback={() => setUnlockStorageDialogOpen(false)}
      />
      <CheckDialog open={showCheckDialog}
        onClose={() => setShowCheckDialog(false)}
        onAccept={() => unlockStorage()} />
      < SmartMateriaUITable
        title={t_("Storages")}
        columns={columns}
        dataFetch={"storages"}
        actions={actions}
        dense={true}
        toolbar={defaultToolbar}
        disableFlexGrow
      />
    </>
  );
}

export default Storage;
