import React from 'react'
import SmartNavigator from '../generic/SmartNavigator'

import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import Formula from '../configuration/formula/Formula'

export default function MainFormula () {
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('Formula'),
      component: <Formula />
    }
  ]

  return <SmartNavigator componentsInfo={componentsInfo} />
}
