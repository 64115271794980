import { apiGet, apiPost, apiPut, apiDelete } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const MaterialTypes = () => {
  const { t_ } = useSmartTranslation()
  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Name'),
      field: 'name',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    },
    {
      name: t_('Heat treatment'),
      field: 'heatTreatment',
      charLimit: 200,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Material group'),
      field: 'materialGroup.name',
      type: 'select',
      edit: {
        source: async () => {
          return await apiGet('materialGroupsBasicData')
        },
        id: 'id',
        field: 'name'
      },
      mandatory: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    }
  ]

  const dataFetch = async (pagination) => {
    const materialTypes = await apiGet('getMaterialTypes', pagination)
    return materialTypes
  }

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('materialType', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        return apiPost('materialType', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('materialType/' + rows[0].id, rows)
        } else {
          return apiDelete('materialTypeMulti', rows)
        }
      },
      visibilityButton: (row) => {
        return !row.systemDefault
      }
    }
  }

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Material types')}
      dataFetch={dataFetch}
      actions={actions}
      pageable
      multipleSelection
    />
  )
}

export default MaterialTypes
