import { apiGet, apiPost, apiPut, apiDelete } from '../../generic/Api_Functions'
import { IconButton } from '@mui/material'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import SmartMateriaUITable from './../../generic/SmartMateriaUITable'
import { useState } from 'react'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const CalendarReference = () => {
  const [editingItem, setEditingItem] = useState(null)
  const navigate = useNavigate()
  const { t_ } = useSmartTranslation()
  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      charLimit: 60,
      edit: false,
      mandatory: true,
      unique: true
    },
    {
      name: t_('Name'),
      field: 'name',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: (row) => {
        return row.systemDefault === undefined ? undefined : row.systemDefault
      }
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    }
  ]

  const dataFetch = async (pagination) => {
    const orders = await apiGet('calendarReferences', pagination)
    return orders
  }

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('calendarReference', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        return apiPost('calendarReference', row).then((response) => {
          navigate(generatePath('/configuration/calendar/:id', { id: response.id }))
        })
      }
    },
    delete: {
      fetchFunction: (row) => {
        let url = 'calendarReferenceMulti'
        if (row.length === 1) {
          url = 'calendarReference/' + row[0].id
        }
        return apiDelete(url, row)
      },
      visibilityButton: (calendarReference) => !calendarReference.systemDefault
    },
    custom: [
      {
        name: t_('Edit events'),
        render: (row) => {
          return (
            <IconButton
              component={Link} to={'/configuration/calendar/' + row.id}
              disabled={(editingItem && editingItem.id === row.id)}
            >
              <CalendarMonthIcon />
            </IconButton>
          )
        }
      }
    ]
  }

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Calendars')}
      dataFetch={dataFetch}
      actions={actions}
      pageable
      onEditIndexChanged={(editIndex, editingRow) => { setEditingItem(editingRow) }}
      multipleSelection
    />
  )
}

export default CalendarReference
