import React, { useEffect, useState } from 'react'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import CloseIcon from '@mui/icons-material/Close'

import {
  IconButton,
  Box,
  Grid,
  Typography,
  TextField,
  Stack,
  Alert,
  Collapse
} from '@mui/material'
import { apiPost } from '../../generic/Api_Functions'
import { useNavigate, useLocation } from 'react-router-dom'
import CompassButton from '../Common/CompassButton/CompassButton'
import CompassWorkerSelector from '../Common/CompassButton/CompassWorkerSelector'
import CompassWindow from '../Common/CompassWindow/CompassWindow'

const SupervisionState = () => {
  const location = useLocation()
  const device = location.state.device
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('error')
  const { t_ } = useSmartTranslation()
  const [status, setStatus] = useState(device?.status)
  const [comment, setComment] = useState('')
  const [workersSel, setWorkersSel] = useState(null)
  const [currentDeviceStatus, setCurrentDeviceStatus] = useState('')
  const [errorMessage, setErrorMessage] = useState({ show: false })

  const handleInputChange = (event) => {
    setComment(event.target.value)
  }
  const formLabelStyle = {
    fontSize: '2rem'
  }

  useEffect(() => {
    if (device.id) {
      setCurrentDeviceStatus(device.status)
    }
  }, [device])

  const save = () => {
    const data = {
      status,
      comment
    }

    const createStatus = () => {
      apiPost('compass/deviceStatus/' + device.id, data).then(() => {
        setMessage(t_('The operation has been completed successfully.'))
        setSeverity('success')
        setOpen(true)
        goBack()
      }).catch((error) => {
        if (error.message) {
          setMessage(error.message)
        } else {
          setMessage(t_('An error has occurred, please check the data and retry the operation.'))
        }
        setSeverity('error')
        setOpen(true)
      })
    }

    if (device?.workers?.length === 0) {
      setMessage(t_('There are no workers working on this device'))
      setSeverity('error')
      setOpen(true)
      return
    }
    if (!workersSel) {
      setMessage(t_('Select a worker'))
      setSeverity('error')
      setOpen(true)
      return
    }

    if (device.mode === 'AUTO') {
      setMessage(t_('The device must be offline to perform a state change.'))
      setSeverity('error')
      setOpen(true)
      return
    }
    if (status === '') {
      setMessage(t_('Select a status'))
      setSeverity('error')
      setOpen(true)
      return
    }
    if (status === currentDeviceStatus) {
      setMessage(t_('Status is the same as the current one'))
      setSeverity('error')
      setOpen(true)
      return
    }
    createStatus()
  }
  const goBack = () => navigate('/compass/supervision')

  const buttons = [{ label: 'STOP', color: 'warning' }, { label: 'WORK', color: 'primary' }, { label: 'ERROR', color: 'error' }]

  return (
    <>
      <CompassWindow
        message={errorMessage}
        onCloseMessage={() => setErrorMessage({ ...errorMessage, open: false })}
        onClose={goBack}
        containerWidth='lg'
        renderTitle={
          <>
            <Typography color='primary' sx={{ textAlign: 'center', padding: '5px 25px ' }}> {t_('Status')} </Typography>
            <Typography color='primary' sx={{ textAlign: 'center' }}> {t_('Asset')} : {device.name}</Typography>
          </>
        }
        render={
          <Box sx={{ width: '100%' }}>
            <Stack spacing={2} justifyContent='center' alignItems='left'>
              <Box sx={{ width: '100%' }}>
                <Collapse in={open}>
                  <Alert
                    variant='filled' severity={severity} style={{ color: 'white' }}
                    action={
                      <IconButton aria-label='close' color='inherit' size='small' onClick={() => { setOpen(false) }}>
                        <CloseIcon fontSize='inherit' />
                      </IconButton>
                      }
                    sx={{ mb: 2 }}
                  >
                    {message}
                  </Alert>
                </Collapse>
              </Box>
              <CompassWorkerSelector workers={device?.workers} selectedWorker={workersSel} onChange={(worker) => setWorkersSel(worker)} />
              <Grid item xs={12}>
                <Typography color='primary' sx={{ textAlign: '' }}>{t_('Status')}:</Typography>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='left' spacing={1}>
                {buttons.map(button => {
                  return (
                    <Grid item xs style={{ height: '6rem' }} key={button.label}>
                      <CompassButton
                        isSelected={status === button.label}
                        label={t_(button.label)}
                        labelStyle={formLabelStyle}
                        onClick={() => { setStatus(button.label) }}
                        color={button.color}
                        isSelectedColor={button.color}
                        colorBg={status === button.label ? '#80808082' : ''}
                      />
                    </Grid>
                  )
                })}
              </Grid>

              <TextField label={t_('Comment')} fullWidth variant='filled' margin='normal' value={comment} name='comment' onChange={handleInputChange} rows={4} multiline />
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ height: '6rem' }}>
                <Grid item xs={6} style={{ paddingLeft: '0' }}>
                  <CompassButton label={t_('ACCEPT')} labelStyle={formLabelStyle} onClick={save} color='primary' />
                </Grid>
                <Grid item xs={6}>
                  <CompassButton label={t_('CANCEL')} labelStyle={formLabelStyle} onClick={goBack} color='secondary' />
                </Grid>
              </Grid>
            </Stack>
          </Box>
}
      />
    </>
  )
}
export default SupervisionState
