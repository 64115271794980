import React from 'react'
import Devices from "../configuration/devices/Devices";
import SmartNavigator from '../generic/SmartNavigator';
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation';

const MainDevices = () => {
  const { t_ } = useSmartTranslation();
  const componentsInfo = [
    {
      title: t_("Assets"),
      component: <Devices />,
    },
  ];
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  );
}

export default MainDevices
