import { useState, useEffect, useReducer } from "react";
import { apiGet, apiPut } from "../../generic/Api_Functions";
import { SmartDialog } from "../../generic/utilities/SmartDialog";
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import { Autocomplete, TextField, Checkbox, FormControlLabel, FormGroup, Typography, Button, InputAdornment } from "@mui/material";
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const CalendarReferenceLinkComponent = (props) => {

  const { calendarId, calendarReference, isLinked, onDataRefreshRequired } = props;


  const [calendarReferences, setCalendarReferences] = useState([]);
  const [currentCalendarReference, setCurrentCalendarReference] = useState(calendarReference);
  const [lastCalendarReference, setLastCalendarReference] = useState(calendarReference);
  const [currentlyLinked, setCurrentlyLinked] = useState(isLinked);
  const [deleteInstanceEvents, setDeleteInstanceEvents] = useState(false);
  const [copyRefEvents, setCopyRefEvents] = useState(false);
  const { t_ } = useSmartTranslation();
  const onDialogStatusChange = (state, action) => {
    if (action === "hide") {
      return { ...state, show: false };
    }
    setDeleteInstanceEvents(false);
    setCopyRefEvents(action === "unlink");
    return { show: true, mode: action, showRefDelete: currentlyLinked || action === 'unlink' };
  }

  /**
   * Mode can be:
   * "link"
   * "unlink"
   */
  const [dialogStatus, setDialogAction] = useReducer(onDialogStatusChange, { show: false, mode: null, showRefDelete: false })


  const changeCalendarReference = (newCalendarReference) => {
    if (currentCalendarReference.id !== newCalendarReference.id) {
      setCurrentCalendarReference(newCalendarReference);
      setDialogAction("link");
    }
  }


  useEffect(() => {
    setCurrentCalendarReference(calendarReference);
  }, [calendarReference]);

  useEffect(() => {
    setCurrentlyLinked(isLinked);
  }, [isLinked]);

  useEffect(() => {
    const loadCalendarReferences = async () => {
      setCalendarReferences(await apiGet('allCalendarReferences'));
    }
    loadCalendarReferences();
  }, []);

  const onDialogAccept = async () => {
    await apiPut("calendar/" + calendarId + "/" + dialogStatus.mode + (dialogStatus.mode === 'link' ? "/" + currentCalendarReference.id : "") + "/" + deleteInstanceEvents + "/" + copyRefEvents);
    onDataRefreshRequired();
    setLastCalendarReference(currentCalendarReference);
    setDialogAction("hide");
    setCurrentlyLinked((dialogStatus.mode === 'link'));
  }

  return (
    <>
      <SmartDialog
        title={{ icon: <LinkIcon />, render: <Typography variant="h4">{dialogStatus.mode === "link" ? t_("Link") : t_("Unlink")}</Typography> }}
        setOpen={dialogStatus.show}
        renderComponent={
          <>
            {dialogStatus.mode === "link" ? t_("Calendar reference will be changed. Are you sure?") : t_("Calendar reference will be unlinked. Are you sure?")}

            <FormGroup sx={{ marginTop: "1rem" }}>
              <FormControlLabel control={<Checkbox checked={deleteInstanceEvents} onChange={(event) => { setDeleteInstanceEvents(event.target.checked) }} />} label={t_("Delete own events")} />
              {dialogStatus.showRefDelete ?
                <FormControlLabel checked={!copyRefEvents} control={<Checkbox onChange={(event) => { setCopyRefEvents(!event.target.checked) }} />} label={t_("Delete events associated with the old reference")} /> : null}
            </FormGroup>
          </>
        }
        close={false}
        cancel={true}
        cancelCallback={() => {
          setDialogAction("hide");
          setCurrentCalendarReference(lastCalendarReference);
        }}
        acceptCallback={onDialogAccept}
      />
      <Autocomplete
        size="small"
        sx={{ minWidth: "18rem" }}
        disableClearable
        forcePopupIcon={false}
        options={calendarReferences}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option1, option2) => option1.id === option2.id}
        renderInput={(params) =>
          <TextField
            {...params}
            label={t_("Calendar reference")}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {currentlyLinked ? <LinkIcon /> : <LinkOffIcon />}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="contained" sx={{ height: "1.3rem" }} onClick={() => setDialogAction(currentlyLinked ? t_("unlink") : t_("link"))}>{currentlyLinked ? t_("Unlink") : t_("Link")}</Button>
                </InputAdornment >
              )
            }} />}
        onChange={(event, value) => { changeCalendarReference(value) }}
        value={currentCalendarReference} />
    </>
  );
};

export default CalendarReferenceLinkComponent;