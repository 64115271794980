import { ToggleButton, ToggleButtonGroup, Stack, FormControlLabel, Checkbox, Typography, TextField, FormHelperText } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment/moment'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const CalendarRepeatComponent = (props) => {
  const { rrule, onChange, showErrors, minDate, disable } = props
  const { t_ } = useSmartTranslation()
  const weekdays = [
    { key: 'MO', value: t_('Monday') },
    { key: 'TU', value: t_('Tuesday') },
    { key: 'WE', value: t_('Wednesday') },
    { key: 'TH', value: t_('Thursday') },
    { key: 'FR', value: t_('Friday') },
    { key: 'SA', value: t_('Saturday') },
    { key: 'SU', value: t_('Sunday') }
  ]
  const intervalError = (showErrors && rrule.INTERVAL < 1)
  const untilError = (showErrors && minDate >= rrule.UNTIL)
  const weekDayError = (showErrors && rrule.BYDAY.length === 0)

  return (
    <>
      <Stack direction='row' alignItems='baseline' justifyContent='space-between' spacing={1}>
        <FormControlLabel
          control={<Checkbox />}
          label={rrule.ON ? t_('Repeat every') : t_('Repeat')}
          checked={rrule.ON}
          onChange={(event, newValue) => {
            onChange({ ...rrule, ON: newValue, UNTIL: minDate > rrule.UNTIL ? minDate : rrule.UNTIL })
          }}
          disabled={disable}
        />
        {rrule.ON
          ? (
            <>
              <TextField
                label=''
                variant='outlined'
                type='number'
                sx={{ width: '5rem' }}
                value={rrule.INTERVAL}
                error={intervalError}
                helperText={intervalError ? t_('Must be bigger than 0') : null}
                onChange={(event) => {
                  onChange({ ...rrule, INTERVAL: event.target.value })
                }}
                disabled={disable}
              />
              <Typography>{t_('week')}{rrule.INTERVAL > 1 ? 's' : null} {t_('until')}</Typography>
              <DatePicker
                label=''
                value={moment.isMoment(rrule.UNTIL) ? rrule.UNTIL.toDate() : rrule.UNTIL}
                onChange={(newValue) => {
                  onChange({ ...rrule, UNTIL: newValue })
                }}
                slotProps={{ textField: { error: untilError, helperText: untilError ? t_('Must be later than end date') : null } }}
                disabled={disable}
              />
            </>)
          : null}
      </Stack>
      {rrule.ON
        ? (
          <>
            <ToggleButtonGroup
              color='primary'
              value={rrule.BYDAY}
              onChange={(event, newValue) => {
                onChange({ ...rrule, BYDAY: newValue })
              }}
              exclusive={false}
              aria-label={t_('Repeat week days')}
              disabled={disable}
            >
              {weekdays.map((day) => {
                return (
                  <ToggleButton
                    key={day.key}
                    value={day.key}
                    sx={weekDayError ? { borderColor: (theme) => theme.palette.error.main } : null}
                    disabled={disable}
                  >
                    {day.value}
                  </ToggleButton>
                )
              })}
            </ToggleButtonGroup>

            {weekDayError ? <FormHelperText color='common.error' error={showErrors}>{t_('You must select at least one week day')}</FormHelperText> : null}
          </>)

        : null}
    </>
  )
}

// Convert RRule string to javascript object
// Example: FREQ=WEEKLY;BYDAY=SU,TU;INTERVAL=1
const rruleStringToObject = (rrule, endDate) => {
  if (rrule === null || rrule === undefined) {
    // Return default values
    return { FREQ: 'WEEKLY', BYDAY: [], INTERVAL: 1, ON: false, UNTIL: endDate || new Date() }
  }
  const rruleParams = {}
  const rruleSplit = rrule.split(';')
  rruleSplit.forEach(element => {
    let [key, value] = element.split('=')
    if (value.indexOf(',') >= 0 || key === 'BYDAY') {
      value = value.split(',')
    }
    rruleParams[key] = value
  })
  rruleParams.ON = true
  rruleParams.UNTIL = moment(rruleParams.UNTIL, 'YYYYMMDD[T]hhmmss[Z]')
  return rruleParams
}

const rruleObjectToString = (rrule) => {
  if (rrule === null || !rrule.ON) {
    return null
  }
  const rruleValues = []
  for (const key in rrule) {
    if (key !== 'ON') {
      let value = rrule[key]
      if (key === 'UNTIL') {
        value = moment(value).format('YYYYMMDD[T000000Z]')
      } else if (Array.isArray(value)) {
        value = value.join()
      }
      rruleValues.push(key + '=' + value)
    }
  }
  return rruleValues.join(';')
}

const isRRuleValid = (rrule, minDate) => {
  if (rrule.INTERVAL < 1 || minDate >= rrule.UNTIL || rrule.BYDAY.length === 0) {
    return false
  } else {
    return true
  }
}

export { CalendarRepeatComponent, rruleStringToObject, rruleObjectToString, isRRuleValid }
