import { Stack } from '@mui/material'
import MoveRequestShelvePosition from './MoveRequestShelvePosition'
import { useEffect, useReducer, useState } from 'react'
import { apiGet, apiPost } from '../../../generic/Api_Functions'
import AlertUI from '../../../generic/AlertUI'
import useToggle from './../../../generic/hooks/useToggle/useToggle'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'
import { SmartSelect } from '../../../generic/smartSelect'
import { CompassButton } from '../../../Compass/Common/CompassButton'

const MoveRequest = (props) => {
  const { moveModeByLocation } = props

  const [AlertElement, showAlert] = AlertUI()

  const [devicesFrom, setDevicesFrom] = useState([])
  const [devicesTo, setDevicesTo] = useState([])
  const [freePositions, setFreePositions] = useState([])
  const [nonFreePositions, setNonFreePositions] = useState([])
  const [resourcesOnCell, setResourcesOnCell] = useState([])
  const [toggle, changeToggle] = useToggle()

  const { t_ } = useSmartTranslation()

  const types = [
    { label: t_('Fixture'), value: 'Fixture' },
    { label: t_('Part'), value: 'Part' },
    { label: t_('Raw Material'), value: 'Raw Material' },
    { label: t_('Tool'), value: 'Tool' }
  ]

  const reducerAction = (state, action) => {
    if (action.type === 'setFromDevice') {
      return { ...state, fromDevice: devicesFrom.find(device => device.name === action.value), fromPosition: null }
    }
    if (action.type === 'setToDevice') {
      return { ...state, toDevice: devicesTo.find(device => device.name === action.value), toPosition: null }
    }
    if (action.type === 'showErrors') {
      return { ...state, showErrors: true }
    }
    if (action.type === 'setShelf') {
      if (action.mode === 'from') {
        return { ...state, fromPosition: { shelf: action.value, row: null, column: null } }
      } else {
        return { ...state, toPosition: { shelf: action.value, row: null, column: null } }
      }
    }
    if (action.type === 'setRow') {
      if (action.mode === 'from') {
        return { ...state, fromPosition: { ...state.fromPosition, row: action.value, column: null } }
      } else {
        return { ...state, toPosition: { ...state.toPosition, row: action.value, column: null } }
      }
    }
    if (action.type === 'setColumn') {
      if (action.mode === 'from') {
        return { ...state, fromPosition: { ...state.fromPosition, column: action.value } }
      } else {
        return { ...state, toPosition: { ...state.toPosition, column: action.value } }
      }
    }
    if (action.type === 'setType') {
      return { ...state, resourceType: action.value, resourceReference: null, resource: null }
    }
    if (action.type === 'setResourceReference') {
      return { ...state, resourceReference: action.value, resource: null }
    }
    if (action.type === 'setResource') {
      return { ...state, resource: action.value }
    }
    if (action.type === 'init') {
      return { fromDevice: null, toDevice: null, showErrors: false, fromPosition: null, toPosition: null, resourceType: null, resourceReference: null, resource: null }
    }
  }

  const [selectedData, dispatchSelectedData] = useReducer(reducerAction, { fromDevice: null, toDevice: null, showErrors: false, fromPosition: null, toPosition: null, resourceType: null, resourceReference: null, resource: null })

  useEffect(() => {
    const loadResources = async () => {
      setResourcesOnCell(await apiGet('commandMove/resourcesInsideCell'))
    }
    if (!moveModeByLocation) {
      loadResources()
    }
  }, [moveModeByLocation, toggle])

  useEffect(() => {
    const loadPositions = async () => {
      const data = await apiGet('commandMove/' + selectedData.fromDevice.id + '/positions/false')
      setNonFreePositions(data.sort((a, b) => a.shelve - b.shelve || a.row - b.row || a.column - b.column))
    }

    if (!selectedData.fromDevice || !selectedData.fromDevice.hasPositions) {
      setNonFreePositions([])
      return
    }
    loadPositions()
  }, [selectedData.fromDevice])

  useEffect(() => {
    const loadPositions = async () => {
      const data = await apiGet('commandMove/' + selectedData.toDevice.id + '/positions/true')
      setFreePositions(data.sort((a, b) => a.shelve - b.shelve || a.row - b.row || a.column - b.column))
    }

    if (!selectedData.toDevice || !selectedData.toDevice.hasPositions) {
      setFreePositions([])
      return
    }
    loadPositions()
  }, [selectedData.toDevice])

  useEffect(() => {
    const loadDevices = async () => {
      const devices = await apiGet('commandMove/devices')
      setDevicesFrom(devices.filter(device => device.hasResource))
      setDevicesTo(devices.filter(device => !device.deviceIsFull))
    }
    loadDevices()
  }, [toggle])

  const move = async () => {
    // Check data is okay
    let fromPosition = null
    let toPosition = null

    // Check to is okay
    if (!selectedData.toDevice) {
      dispatchSelectedData({ type: 'showErrors' })
      return
    }

    if (selectedData.toDevice.hasPositions) {
      if (!selectedData.toPosition || !selectedData.toPosition.shelf || !selectedData.toPosition.row || !selectedData.toPosition.column) {
        dispatchSelectedData({ type: 'showErrors' })
        return
      }
      toPosition = freePositions.find((item) => item.shelve === selectedData.toPosition.shelf && item.row === selectedData.toPosition.row && item.column === selectedData.toPosition.column).id
    }
    // Check from or resource to move
    if (moveModeByLocation) {
      if (!selectedData.fromDevice) {
        dispatchSelectedData({ type: 'showErrors' })
        return
      }
      if (selectedData.fromDevice.hasPositions) {
        if (!selectedData.fromPosition || !selectedData.fromPosition.shelf || !selectedData.fromPosition.row || !selectedData.fromPosition.column) {
          dispatchSelectedData({ type: 'showErrors' })
          return
        }
        fromPosition = nonFreePositions.find((item) => item.shelve === selectedData.fromPosition.shelf && item.row === selectedData.fromPosition.row && item.column === selectedData.fromPosition.column).id
      }
    } else {
      if (!selectedData.resource) {
        dispatchSelectedData({ type: 'showErrors' })
        return
      }
    }

    let data
    let url

    if (moveModeByLocation) {
      data = [{ deviceId: selectedData.fromDevice.id, positionId: fromPosition }, { deviceId: selectedData.toDevice.id, positionId: toPosition }]
      url = 'commandMove/request'
    } else {
      data = { deviceId: selectedData.toDevice.id, positionId: toPosition }
      url = 'commandMove/request/fixture/' + selectedData.resource.fixtureId
    }

    try {
      await apiPost(url, data)
      showAlert({
        title: '',
        message: t_('Move request done'),
        severity: 'success'
      })
      changeToggle()
      dispatchSelectedData({ type: 'init' })
    } catch (error) {
      showAlert({
        title: '',
        message: error.message,
        severity: 'error'
      })
    }
  }

  const references = Array.from(new Set(resourcesOnCell.filter(resource => selectedData.resourceType && resource.type === selectedData.resourceType.value).map(item => item.reference)))

  const codes = Array.from(new Set(resourcesOnCell.filter(resource => selectedData.resourceType && resource.type === selectedData.resourceType.value && selectedData.resourceReference && selectedData.resourceReference === resource.reference).map(item => item.code)))

  return (
    <Stack justifyContent='space-around' direction='row'>
      <Stack direction='column' alignItems='flex-start'>
        <h2>{moveModeByLocation ? t_('Move by location') : t_('Move by resource')}</h2>
        <div style={{ marginBottom: '1rem' }}>
          {AlertElement}
        </div>
        <Stack direction='row'>
          <Stack direction='row' gap={10}>
            <Stack gap={3}>
              <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', width: moveModeByLocation ? 'fit-content' : 'initial', alignItems: 'center', gap: '3rem 6rem', justifyContent: 'space-between' }}>
                {!moveModeByLocation
                  ? (
                    <>
                      <div style={{ fontSize: '1.3rem' }}>{moveModeByLocation ? t_('From') : t_('Resource')}</div>
                      <SmartSelect
                        value={selectedData.resourceType?.value}
                        selectableOptions={types.map(item => item.value?.toString())}
                        label={t_('Type')}
                        onChange={(newValue) => {
                          dispatchSelectedData({ type: 'setType', value: types.find((item) => item.value === newValue) })
                        }}
                        minWidth='17rem'
                        error={selectedData.showErrors && !selectedData.resourceType}
                        fontSize='1.2rem'
                      />
                      <Stack direction='row' gap={6} alignItems='center'>
                        <SmartSelect
                          value={selectedData.resourceReference}
                          selectableOptions={references}
                          onChange={(value) => {
                            dispatchSelectedData({ type: 'setResourceReference', value })
                          }}
                          minWidth='17rem'
                          disabled={!selectedData.resourceType}
                          label={t_('Reference')}
                          error={selectedData.showErrors && selectedData.resourceType && !selectedData.resourceReference}
                          fontSize='1.2rem'
                        />
                        <SmartSelect
                          value={selectedData.resource?.code}
                          selectableOptions={codes}
                          onChange={(code) => {
                            const resource = resourcesOnCell.find((item) => item.type === selectedData.resourceType.value && item.reference === selectedData.resourceReference && item.code === code)
                            dispatchSelectedData({ type: 'setResource', value: resource })
                          }}
                          minWidth='14rem'
                          disabled={!selectedData.resourceReference}
                          label={t_('Code')}
                          error={selectedData.showErrors && selectedData.resourceType && selectedData.resourceReference && !selectedData.resource}
                          fontSize='1.2rem'
                        />
                      </Stack>
                    </>)
                  : null}
                {moveModeByLocation
                  ? (
                    <>
                      <div style={{ fontSize: '1.3rem' }}>{t_('From')}</div>
                      <SmartSelect
                        value={selectedData.fromDevice?.name}
                        selectableOptions={devicesFrom.map((item) => item.name)}
                        onChange={(item) => { dispatchSelectedData({ type: 'setFromDevice', value: item }) }}
                        minWidth='17rem'
                        label={t_('Asset')}
                        disableClearable={false}
                        autoSelectFirst={false}
                        error={selectedData.showErrors && !selectedData.fromDevice}
                        fontSize='1.2rem'
                      />
                      <MoveRequestShelvePosition
                        positions={nonFreePositions}
                        position={selectedData.fromPosition}
                        disabled={!selectedData.fromDevice || !selectedData.fromDevice.hasPositions}
                        onChange={(action) => { dispatchSelectedData({ ...action, mode: 'from' }) }}
                        showErrors={selectedData.showErrors}
                      />
                    </>)
                  : null}
                <div style={{ fontSize: '1.3rem' }}>{t_('To')}</div>
                <SmartSelect
                  value={selectedData.toDevice?.name}
                  selectableOptions={devicesTo.map((item) => item.name)}
                  onChange={(item) => { dispatchSelectedData({ type: 'setToDevice', value: item }) }}
                  disableClearable={false}
                  autoSelectFirst={false}
                  error={selectedData.showErrors && !selectedData.toDevice}
                  label={t_('Asset')}
                  fontSize='1.2rem'
                />
                <MoveRequestShelvePosition
                  positions={freePositions}
                  position={selectedData.toPosition}
                  disabled={!selectedData.toDevice || !selectedData.toDevice.hasPositions}
                  onChange={(action) => {
                    dispatchSelectedData({ ...action, mode: 'to' })
                  }}
                  showErrors={selectedData.showErrors}
                />
              </div>
            </Stack>
            <CompassButton labelStyle={{ fontSize: '1.8rem' }} onClick={move} label={t_('Request Move')} width='auto' sx={{ paddingLeft: '2rem', paddingRight: '2rem' }} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default MoveRequest
