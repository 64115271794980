import { Grid, Typography } from '@mui/material'
import CompassButton from './CompassButton'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'
import { useEffect } from 'react'

const CompassWorkerSelector = (props) => {
  const { workers, selectedWorker, onChange } = props
  const { t_ } = useSmartTranslation()

  const formLabelStyle = {
    fontSize: '1.5rem'
  }

  useEffect(() => {
    // Select if only one available
    if (workers && workers.length === 1 && !selectedWorker) {
      onChange(workers[0])
    }
  }, [workers, selectedWorker, onChange])

  return (
    workers?.length > 0 &&
      <Grid container justifyContent='left' alignItems='center' spacing={1}>
        <Grid item xs={12}>
          <Typography color='primary' sx={{ textAlign: '' }}>{t_('Workers')}:</Typography>
        </Grid>
        {workers?.map(worker =>
          <Grid
            item
            xs={4}
            key={worker.id}
            style={{ height: '6rem' }}
          >
            <CompassButton
              isSelected={selectedWorker?.code === worker.code}
              label={worker.name + ' ' + worker.surname}
              labelStyle={formLabelStyle}
              color='secondary'
              isSelectedColor='primary'
              onClick={() => { onChange(worker) }}
            />
          </Grid>
        )}
      </Grid>
  )
}

export default CompassWorkerSelector
