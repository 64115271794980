import React from "react";
import ImageLogoGreenSmall from "../../images/LogoGreenSmall.png";
import { getBackendUrl } from "./Api_Functions";

const logoStyle = {
  paddingTop: 5,
}

const LogoGreenSmall = () => {
  return (
    <div>
      <div style={logoStyle}>
        <img src={getBackendUrl() + "logoSmall"} height="100rem" alt="Logo" onError={(e) => { e.target.src = ImageLogoGreenSmall; }} />
      </div>
    </div>
  );
};
export default LogoGreenSmall;
