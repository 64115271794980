import React, { useEffect, useRef, useState } from 'react'
import { IconButton, Button, Typography, Tooltip, List, ListItem, Grid } from '@mui/material'
import { apiGet, apiPost, apiDelete } from '../../generic/Api_Functions'
import { Edit, DeleteOutlineRounded } from '@mui/icons-material'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import GlobalConfig from '../../generic/GlobalConfig'
import AlertUI from '../../generic/AlertUI'
import SmartDialog from './../../generic/utilities/SmartDialog/SmartDialog'
import createDialogTitle from '../../generic/utilities/dialogUtil'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const Devices = (props) => {
  const navigate = useNavigate()
  const state = useLocation()
  const { unChecked } = props
  const [AlertElement, showAlert] = AlertUI()
  const [resetMultipleSelectionValue, setResetMultipleSelectionValue] = useState(false)
  const show = useRef(showAlert)
  const [showDialog, setShowDialog] = useState(false)
  const [workflowsList, setWorkflowsList] = useState(null)
  const [deviceList, setDeviceList] = useState(null)
  const [alertElementDeleteError, showAlertDeleteError] = AlertUI()
  const { t_ } = useSmartTranslation()

  useEffect(() => {
    if (state.state === 'BACK') {
      show.current({
        severity: 'info'

      })
    } else if (state.state === 'EDIT') {
      show.current({
        title: 'Created correctly.',
        severity: 'success'
      })
    }
  }, [state])

  const actions = {
    custom: [
      {
        render: (row) => (
          <IconButton
            onClick={() => navigate('/configuration/assets/' + row.id, { replace: true })}
          >
            <Edit />
          </IconButton>
        )
      },
      {
        render: (row) => (
          <IconButton onClick={() => { dialogAction('', '', row) }}>
            <DeleteOutlineRounded />
          </IconButton>
        )
      }
    ],
    create: {
      fetchFunction: (newRow) => {
        return apiPost('devices', formatDeviceToPost(newRow))
      }
    }
  }

  const formatDeviceToPost = (device) => {
    const dev = { ...device }
    dev.toolChangeEnabled = false
    dev.deviceType = {
      id: device.deviceType.id,
      name: device.deviceType.name
    }
    return dev
  }

  const columns = [
    {
      name: t_('Name'),
      field: 'name',
      defaultNewValue: '',
      mandatory: true
    },
    {
      name: t_('Type'),
      field: 'deviceType.name',
      value: 'deviceType.id',
      mandatory: true,
      type: 'select',
      edit: {
        source: async () => {
          const deviceTypes = await apiGet('deviceTypes')
          return deviceTypes
        },
        id: 'id',
        field: 'name'
      }
    },
    {
      name: t_('Mode'),
      field: 'mode',
      mandatory: true,
      type: 'select-plain',
      edit: {
        source: async () => {
          const response = await fetch(
            GlobalConfig['back-end_address'] + 'devices/mode'
          )
          let mode = await response.text()
          mode = await JSON.parse(mode)
          return mode.filter((st) => st !== 'NON_OPERATIVE')
        },
        id: 'id',
        field: 'mode'
      }
    },
    {
      name: t_('Status'),
      field: 'status',
      defaultNewValue: 'OFF'
      // readOnly: true,
      // readOnly: true,
    },
    {
      name: t_('Description'),
      field: 'description',
      defaultNewValue: ''
    }
  ]

  const handleDelete = async (rows) => {
    try {
      if (rows.length === 1) {
        await apiDelete('device/' + rows[0].id)
      } else {
        await apiDelete('devicesMulti', rows)
      }
    } catch (error) {
      showAlertDeleteError({
        title: error.message,
        severity: 'error'
      })
    }
  }

  const dialogAction = async (dataFetch, title, row) => {
    let list = null
    let idListObject = null
    if (row !== undefined) {
      idListObject = { idList: row.id }
      dataFetch = [row]
    } else {
      idListObject = { idList: dataFetch.map(data => data.id) }
    }
    list = await apiGet('deviceTechnology/orphanWorkflowsIfDeviceDeleted', idListObject)

    setWorkflowsList(list)
    setDeviceList(dataFetch)
    setShowDialog(true)
  }

  const WorkflowsListDialog = (props) => {
    const { list } = props
    if (list !== null && list.length !== 0) {
      return (
        <Grid container alignItems='center' justifyContent='center'>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography color='error'>{t_('The following workflow(s) have at least one technology configured that wont be available after delete:')}</Typography>
          </Grid>
          <Grid>
            <List sx={{
              listStyleType: 'disc',
              pl: 2,
              '& .MuiListItem-root': {
                display: 'list-item'
              }
            }}
            >
              {list.map((item, index) => (
                <ListItem key={index}> {item.name}</ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      )
    }
  }

  const multipleSelectionActions = [
    {
      field: 'checked',
      render: (datafetch, index) => {
        return (
          <Tooltip arrow title='delete selections'>
            <Button
              variant='outlined'
              sx={{ border: '0px' }}
              color='error'
              key={'key' + index}
              onClick={() => { dialogAction(datafetch, '') }}
            >
              <DeleteOutlineRounded sx={{ marginRight: '0.5em' }} color='error' />
              <Typography color='error'>DELETE</Typography>
            </Button>
          </Tooltip>
        )
      }
    }
  ]

  return (
    <>
      {AlertElement}
      {alertElementDeleteError}
      <SmartDialog
        message={t_("Are you sure you want to delete the asset(s)? You can't undo this action.")}
        title={createDialogTitle({ color: 'error', title: t_('Delete data?'), icon: DeleteOutlineRounded })}
        accept={false}
        renderCustomButtons={
          <Button
            color='error' onClick={() => {
              handleDelete(deviceList)
              setResetMultipleSelectionValue(!resetMultipleSelectionValue)
              setShowDialog(false)
            }} variant='outlined'
          >
            <Typography color='error'> {t_('DELETE')} </Typography>
            <DeleteOutlineRounded color='error' sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
          </Button>
}
        cancelCallback={() => setShowDialog(false)}
        setOpen={showDialog}
        renderComponent={
          <>
            <WorkflowsListDialog list={workflowsList} />
            <SmartMateriaUITable
              columns={columns}
              dataFetch={deviceList}
            />
          </>
}
      />
      <SmartMateriaUITable
        title={t_('Assets')}
        columns={columns}
        dataFetch='deviceConfiguration'
        actions={actions}
        dense
        multipleSelection={!unChecked}
        multipleSelectionActions={multipleSelectionActions}
        deleteMultipleSelectionDisabled
        resetMultipleSelectionValue={resetMultipleSelectionValue}
      />
    </>
  )
}

export default Devices
