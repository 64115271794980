import { useEffect, useMemo, useState } from 'react'
import SmartPalette from './../../generic/smartPalette/SmartPalette'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { apiDelete, apiGet, apiPost, apiPut } from './../../generic/Api_Functions'
import { Stack, TextField } from '@mui/material'
import { SmartToolBar } from '../../generic/utilities/smarttoolbar'
import { useToggle } from '../../generic/hooks/useToggle'
import { FULL_HEIGHT } from '../../generic/utilities/size_utils'
import AlertUI from '../../generic/AlertUI'
import { SmartDeleteDialog } from '../../generic/utilities/SmartDialog'

const Formula = () => {
  const { t_ } = useSmartTranslation()

  const newFormula = { id: null, name: '', description: '', formula: '' }

  const [AlertElement, showAlert] = AlertUI()

  const [formulas, setFormulas] = useState([])
  const [currentFormula, setCurrentFormula] = useState({ ...newFormula })
  const [mustReloadFormulas, reloadFormulas] = useToggle()
  const [checkedItems, setCheckedItems] = useState([])
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  useEffect(() => {
    const loadFormulas = async () => {
      setFormulas(await apiGet('formula/all'))
    }
    loadFormulas()
  }, [mustReloadFormulas])

  const onDelete = () => {
    const formulasToDelete = checkedItems.length > 0 ? checkedItems : [currentFormula]
    const idList = formulasToDelete.map((item) => item.id)
    apiDelete('formula/deleteMulti', idList).then(() => {
      showAlert({
        title: '',
        message: t_('Deleted correctly.'),
        severity: 'success'
      })
      if (checkedItems.length > 0) {
        setCheckedItems([])
      }

      if (idList.find(id => id === currentFormula.id)) {
        setCurrentFormula({ ...newFormula })
      }

      reloadFormulas()
    }).catch((error) => {
      showAlert({
        title: '',
        message: t_(error.message),
        severity: error.name
      })
    })
  }

  const columns = [
    {
      name: t_('Name'),
      field: 'name'
    }
  ]

  const sections = useMemo(() => { return [{ id: 'formula', items: formulas, title: t_('Formula') }] }, [formulas, t_])
  const labelsToolBar = [
    { title: t_('Name'), value: currentFormula.name, onChange: (newValue) => setCurrentFormula({ ...currentFormula, name: newValue }) },
    { title: t_('Description'), value: currentFormula.description, onChange: (newValue) => setCurrentFormula({ ...currentFormula, description: newValue }) }
  ]

  return (
    <>
      <SmartDeleteDialog
        show={showDeleteDialog}
        cancelCallback={() => {
          setShowDeleteDialog(false)
        }}
        deleteCallBack={() => {
          setShowDeleteDialog(false)
          onDelete()
        }}
        rows={checkedItems.length > 0 ? checkedItems : [currentFormula]}
        columns={columns}
      />
      <div style={{ width: '100%', height: FULL_HEIGHT.WITH_NAV_BAR_AND_SMART_NAVIGATOR_AND_FOOTER, display: 'grid', gridTemplateColumns: 'max-content 1fr', gridTemplateRows: 'max-content 1fr', gap: '1rem' }}>
        <SmartPalette
          sx={{ gridRow: 'span 2' }}
          sections={sections}
          onItemClick={(section, item) => setCurrentFormula(item)}
          getDragProperties={(section, item) => { return { 'smartpm/formula/name': item.name } }}
          selectedSection={{ id: 'formula' }}
          selectedItem={currentFormula}
          isItemChecked={(section, item) => !!checkedItems.find((current) => current.id === item.id)}
          onCheckedChange={(section, item, checked) => {
            if (checked) {
              setCheckedItems([...checkedItems, item])
            } else {
              setCheckedItems(checkedItems.filter((current) => item.id !== current.id))
            }
          }}
          filterItem={(section, item, filter) => {
            if (currentFormula && currentFormula.id === item.id) {
              return true
            }
            if (checkedItems.find((current) => current.id === item.id)) {
              return true
            }
            return item.name.toLowerCase().includes(filter)
          }}
        />

        <Stack direction='column' gap={3}>

          {AlertElement}

          <SmartToolBar
            labels={labelsToolBar}
            onAddElementClick={() => { setCurrentFormula({ ...newFormula }) }}
            disableSaveButton={!currentFormula.id && checkedItems.length === 0}
            onSaveElementClick={() => {
              if (currentFormula.id === null) {
                apiPost('formula/create', currentFormula).then((response) => {
                  setCurrentFormula(response)
                  showAlert({ message: t_('Created correctly.'), severity: 'success' })
                  reloadFormulas()
                }).catch((error) => {
                  showAlert({ title: t_('Error updating formula'), message: error.message, severity: error.name })
                })
              } else {
                apiPut('formula/update', currentFormula).then((response) => {
                  showAlert({ message: t_('Updated correctly.'), severity: 'success' })
                  reloadFormulas()
                }).catch((error) => {
                  showAlert({ title: t_('Error updating formula'), message: error.message, severity: error.name })
                })
              }
            }}
            onDeleteElementClick={() => setShowDeleteDialog(true)}
          />
        </Stack>

        <TextField
          label={t_('Formula')}
          value={currentFormula.formula}
          InputLabelProps={{
            shrink: true
          }}
          onChange={(event) => setCurrentFormula({ ...currentFormula, formula: event.target.value })}
          onDragOver={(event) => event.preventDefault()}
          onDrop={(event) => {
            const name = event.dataTransfer.getData('smartpm/formula/name')
            setCurrentFormula({ ...currentFormula, formula: currentFormula.formula + name })
          }}
        />
      </div>
    </>
  )
}

export default Formula
