import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SmartSelect from '../smartSelect/SmartSelect'
import { SmartDialog } from '../utilities/SmartDialog'
import { Alert, Stack, TextField, Typography } from '@mui/material'
import { apiGet, apiPut } from '../Api_Functions'
import GlobalConfig from '../GlobalConfig'
import { useSmartTranslation } from '../hooks/useSmartTranslation'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

/**
 * AddProgramDialog
 * @param {*} props
 * @param {Boolean} props.open If the dialog must be open
 * @param {Function} props.onClose Function called when the dialog closes
 * @param {Object} props.technology Technology. Will be used to filter the available programs
 * @param {Number} props.taskId Task Id to associate the selected program
 * @param {String} props.referenceName Reference name
 * @returns
 */
const AddProgramDialog = ({ open, onClose, technology, taskId, referenceName }) => {
  const baseEndpointUrl = GlobalConfig['back-end_address']

  const { t_ } = useSmartTranslation()

  const [showErrors, setShowErrors] = useState(false)
  const [program, setProgram] = useState(null)
  const [availablePrograms, setAvailablePrograms] = useState([])
  const [duration, setDuration] = useState('')
  const fileInputRef = useRef()
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    if (open) {
      setShowErrors(false)
      setProgram(null)
      setDuration('')
    }
  }, [open])

  const loadAvailablePrograms = useCallback(async () => {
    if (technology) {
      setAvailablePrograms([])
      setAvailablePrograms(await apiGet('programsByTechnology/' + technology.id))
    }
  }, [technology])

  useEffect(() => {
    loadAvailablePrograms()
  }, [loadAvailablePrograms])

  const availableProgramNames = useMemo(() => {
    return availablePrograms.map((item) => item.name)
  }, [availablePrograms])

  const timeError = useMemo(() => {
    if (duration === null || duration === '') {
      return t_('Time must have hh:mm:ss structure')
    }
    let errorMsg = null
    const splittedTime = duration.split(':')
    let splitCount = 0

    if (splittedTime.length !== 3) {
      return t_('Time must have hh:mm:ss structure')
    }

    let allZero = true

    splittedTime.forEach(element => {
      if (isNaN(element) || element === '' || element === null) {
        errorMsg = t_('Hours, seconds and minute values must be numeric')
        return
      }
      if (element > 0) {
        allZero = false
      }
      if (splitCount !== 0) {
        if (element < 0 || element > 59) {
          errorMsg = t_('Seconds and minute values must be between 0 and 59')
          return
        }
      }
      splitCount += 1
    })

    if (allZero) {
      errorMsg = t_('Duration cannot be 0')
    }

    return errorMsg
  }, [duration, t_])

  const onProgramChange = async (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      return
    }

    // Upload the program
    const formData = new FormData()
    const file = event.target.files[0]
    formData.append('file', file)
    formData.append('technology', technology.id)

    const response = await fetch(baseEndpointUrl + 'programs', {
      method: 'POST',
      body: formData
    })
    if (response.ok === true) {
      // Reload programs
      await loadAvailablePrograms()
      // Select added program
      setProgram(file.name)
    } else {
      setErrorMessage(t_('Could not upload program'))
    }
  }

  const acceptCallback = async () => {
    // Check for errors
    if (timeError || program === null) {
      setShowErrors(true)
      return
    }

    // Set new program
    const selectedProgram = availablePrograms.find((item) => item.name === program)
    await apiPut('taskReference/' + taskId + '/program/' + selectedProgram.id + '/' + duration)

    // Close dialog
    onClose()
  }

  return (
    <SmartDialog
      setOpen={open}
      cancelCallback={onClose}
      acceptCallback={acceptCallback}
      title={{ render: <Stack direction='row' alignItems='center' gap={1}><div>{t_('Add program') + ':'}</div><Typography variant='h6' color='primary'>{referenceName + ' - ' + technology?.name}</Typography></Stack>, icon: <InsertDriveFileIcon fontSize='large' /> }}
      renderComponent={
        <>
          {errorMessage !== null ? <Alert severity='error' sx={{ marginBottom: '2rem' }} onClose={() => setErrorMessage(null)}>{errorMessage}</Alert> : null}
          <input
            id='changeProgram'
            onClick={(event) => {
              event.target.value = null
            }}
            onChange={onProgramChange}
            multiple={false}
            ref={fileInputRef}
            type='file'
            hidden
          />
          <Stack direction='row' gap={3}>
            <div style={{ flexGrow: 1 }}>
              <SmartSelect
                label={t_('Program')}
                value={program}
                selectableOptions={availableProgramNames}
                addButtonVisible
                onChange={setProgram}
                error={showErrors && program === null}
                onAddClicked={() => {
                  fileInputRef.current.click()
                }}
                minWidth='12rem'
              />
            </div>
            <TextField
              label='Duration'
              error={showErrors && timeError !== null}
              helperText={showErrors ? timeError : null}
              onChange={(event) => setDuration(event.target.value)}
              placeholder='hh:mm:ss'
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </>
    }
    />
  )
}

export default AddProgramDialog
