import React, { useState } from 'react'
import EDM from '../../images/EDM.png'
import CMM from '../../images/CMM.png'
import STORAGE from '../../images/Storage.png'
import CNC from '../../images/CNC.png'
import POLISH from '../../images/Polish.png'
import HANDLER from '../../images/Handler.png'
import ON from '../../images/ON.png'
import OFF from '../../images/OFF.png'
import AUTO from '../../images/AUTO.png'
import MANUAL from '../../images/MANUAL.png'
import OFFLINE from '../../images/OFFLINE.png'
import Gears from '../../images/Gears.gif'
import CLOCK from '../../images/clock.gif'
import OPERATOR from '../../images/OPERATOR.png'
import IDENTIFICATION from '../../images/CAMERA.png'
import MARKING from '../../images/MARKING.png'
import CLEANING from '../../images/CLEANING.png'
import TREATMENT from '../../images/TREATMENT_CENTER.png'
import { NoMeetingRoomRounded, Error, MeetingRoomRounded, SensorDoorRounded, StorageRounded } from '@mui/icons-material'
import { Card, CardContent, Chip, Grid, Icon, Paper, Stack, TextField, Typography, IconButton, useTheme, Slide } from '@mui/material'
import { SmartDialog } from './utilities/SmartDialog'
import SmartMateriaUITable from './SmartMateriaUITable'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { useNavigate, generatePath } from 'react-router'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import VerticalResourceAssembly from '../management/VerticalResourceAssembly'
import { useSmartTranslation } from './hooks/useSmartTranslation'

const rootStyle = {
  height: 400
}
const errorStyle = {
  ...rootStyle,
  borderWidth: 4,
  borderStyle: 'solid',
  borderColor: theme => theme.palette.error.main
}
const warningStyle = {
  ...rootStyle,
  borderWidth: 4,
  borderStyle: 'solid',
  borderColor: theme => theme.palette.warning.main
}

const coverStyle = {
  padding: '1rem',
  width: '100%',
  height: '100%',
  objectFit: 'scale-down'
}
const deviceTitleStyle = {
  paddingTop: '10px',
  paddingLeft: '20px'
}
const gifStyle = {
  width: 50,
  height: 50
}
const iconButtonStyle = {
  color: '#7B7B7B !important',
  filter: 'drop-shadow(1px 1px #282827) !important',
  fontSize: '2.1rem !important'
}

export default function HomeCard (props) {
  const { device, locations, onItemClick } = props
  const [dialogOpen, setDialogOpen] = useState(false)
  const [actionsPanelOpen, setActionsPanelOpen] = useState(false)
  const { t_ } = useSmartTranslation()

  const navigate = useNavigate()

  const theme = useTheme()

  function HandleImage () {
    let image = ''
    if (device.deviceType.name === 'MACHINING_CENTER') {
      image = CNC
    } else if (device.deviceType.name === 'EDM') {
      image = EDM
    } else if (device.deviceType.name === 'CMM') {
      image = CMM
    } else if (device.deviceType.name === 'STORAGE') {
      image = STORAGE
    } else if (device.deviceType.name === 'HANDLER') {
      image = HANDLER
    } else if (device.deviceType.name === 'POLISHING_CENTER') {
      image = POLISH
    } else if (device.deviceType.name === 'OPERATOR') {
      image = OPERATOR
    } else if (device.deviceType.name === 'IDENTIFICATION') {
      image = IDENTIFICATION
    } else if (device.deviceType.name === 'MARKING') {
      image = MARKING
    } else if (device.deviceType.name === 'CLEANING_CENTER') {
      image = CLEANING
    } else if (device.deviceType.name === 'TREATMENT_CENTER') {
      image = TREATMENT
    }
    return image
  }

  const getStatusIcon = () => {
    return (
      <>
        {device.mode === 'NON_OPERATIVE'
          ? <Error sx={{ color: theme => `${theme.palette.warning.main}` }} />
          : device.status === 'WORKING'
            ? (
              <img style={gifStyle} src={Gears} alt='WORKING' />
              )
            : device.status === 'ERROR'
              ? (
                <Error color='error' />
                )
              : device.status === 'IDLE' || device.status === 'STOPPED'
                ? (
                  <img style={gifStyle} src={CLOCK} alt={device.status} />
                  )
                : null}

      </>
    )
  }

  const showParts = () => {
    return (
      <Stack
        direction='row' gap='1' style={{
          height: '7rem', display: 'flex', marginTop: '1rem', flexWrap: 'wrap'
        }}
      >
        {locations.length === 0 ? <Chip label={t_('Empty')} /> : null}
        {locations.length > 0 ? <VerticalResourceAssembly items={locations[0].resources} renderTopItem={(item) => <Chip label={item.code} onClick={() => onItemClick(locations[0].resources[0])} />} /> : null}
        {locations.length > 1 ? <VerticalResourceAssembly items={locations[1].resources} renderTopItem={(item) => <Chip label={item.code} onClick={() => onItemClick(locations[1].resources[0])} />} /> : null}
        {locations.length > 2 ? <Chip label={'+ ' + (locations.length - 2)} onClick={() => setDialogOpen(true)} /> : null}
      </Stack>
    )
  }

  const getStatusVisualization = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', height: '3.2rem' }}>
        <div style={{ height: '100%' }}>{getStatusFirstBadge()}</div>
        <div style={{ height: '100%' }}>{getStatusSecondBadge()}</div>
      </div>
    )
  }

  const getStatusFirstBadge = () => {
    let src = ON
    let alt = 'on'

    if (device.status === 'OFF') {
      src = OFF
      alt = 'off'
    }

    return (
      <img
        style={{ height: '100%' }}
        src={src}
        alt={alt}
      />
    )
  }

  const getStatusSecondBadge = () => {
    if (device.mode !== 'AUTO' && device.mode !== 'OFFLINE' && device.mode !== 'MANUAL') {
      return null
    }

    let src = AUTO
    let alt = 'auto'

    if (device.mode === 'OFFLINE') {
      src = OFFLINE
      alt = 'offline'
    }

    if (device.mode === 'MANUAL') {
      src = MANUAL
      alt = 'manual'
    }

    return (
      <img
        style={{ height: '100%' }}
        src={src}
        alt={alt}
      />
    )
  }

  return (
    <div>
      <SmartDialog
        setOpen={dialogOpen}
        close
        accept={false}
        cancel={false}
        closeCallback={() => setDialogOpen(false)}
        title={{ icon: <StorageRounded color='primary' />, render: <Typography variant='h5' color='primary' sx={{ marginLeft: '0.3em' }}>{device.name}</Typography> }}
        renderComponent={
          <SmartMateriaUITable
            columns={[{
              name: 'Code',
              field: 'id',
              edit: false,
              render: (row) => {
                return <VerticalResourceAssembly items={row.resources} renderTopItem={(item) => <div>{item.code}</div>} />
              }
            },
            {
              name: 'Type',
              field: 'resources.0.type.name'
            }
            ]}
            dataFetch={locations}
            dense
            toolbarDisplay='none'
            onRowClick={(row) => { onItemClick(row.resources[0]) }}
          />
        }
      />
      <Card
        sx={
          { ...device.status === 'ERROR' ? errorStyle : device.status === 'NON_OPERATIVE' ? warningStyle : rootStyle, position: 'relative' }
        }
      >
        {device.ioElementSets.length !== 0
          ? (
            <Slide direction='right' in={actionsPanelOpen} mountOnEnter unmountOnExit>
              <Paper sx={{ position: 'absolute', height: '100%', zIndex: 100 }}>
                <Stack direction='row' sx={{ height: '100%' }}>
                  <Stack direction='column' sx={{ paddingRight: '1rem' }}>
                    {
                    device.ioElementSets.map((ioElementSet, key) => ioElementSet.ioElementSetFunctions.map((ioElementSetFunction, key) =>
                      <Icon
                        sx={iconButtonStyle}
                        key={ioElementSet.id}
                      >
                        {ioElementSetFunction.function.code === 'CHECK_DOOR'
                          ? ioElementSetFunction.value === 'UNKNOWN'
                            ? <NoMeetingRoomRounded key={key} title='Sensor disconnected' sx={iconButtonStyle} />
                            : ioElementSetFunction.value === 'true'
                              ? <MeetingRoomRounded key={key} title='Opened door' sx={iconButtonStyle} />
                              : <SensorDoorRounded key={key} title='Closed Door' sx={iconButtonStyle} />
                          : null}
                      </Icon>
                    ))
                  }
                  </Stack>
                  <IconButton sx={{ borderRadius: 0, position: 'absolute', right: 0, top: 0, bottom: 0, paddingLeft: 0, paddingRight: 0 }} onClick={() => setActionsPanelOpen(false)}><ChevronLeftIcon /></IconButton>
                </Stack>
              </Paper>
            </Slide>
            )
          : null}

        <Stack direction='row' sx={{ height: '100%' }}>

          {device.ioElementSets.length !== 0
            ? (
              <IconButton
                sx={{
                  zIndex: 40,
                  borderRadius: 0,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0
                }} onClick={() => setActionsPanelOpen(true)}
              ><ChevronRightIcon />
              </IconButton>
              )
            : null}

          <Grid container direction='row' sx={{ flexBasis: { xs: window.innerWidth / 3, sm: window.innerWidth / 3, md: window.innerWidth / 3, lg: window.innerWidth / 6, xl: window.innerWidth / 8 }, flexGrow: 0, flexShrink: 0 }}>
            <Stack sx={{ backgroundColor: theme => theme.palette.background.card }}>
              <Typography style={deviceTitleStyle}>
                {device.name}
              </Typography>
              <img style={coverStyle} src={HandleImage()} alt='' />
            </Stack>
          </Grid>

          <CardContent sx={{ flexGrow: 1, flexShrink: 1 }}>

            <Stack direction='column' justifyContent='space-between' sx={{ width: '100%', height: '100%' }}>
              {
                getStatusVisualization(device)
              }

              <Stack>

                <TextField
                  variant='standard'
                  label={t_('Type')}
                  InputProps={{
                    readOnly: true
                  }}
                  sx={{ width: '100%' }}
                  fullWidth
                  value={device.deviceType.name}
                />
                <TextField
                  variant='standard'
                  label={t_('Description')}
                  fullWidth
                  sx={{ width: '100%' }}
                  InputProps={{
                    readOnly: true
                  }}
                  value={device.description}
                />
                {showParts()}
              </Stack>
              <Stack direction='row-reverse' spacing={1} justifyContent='space-between' alignItems='center'>
                <IconButton
                  color='primary'
                  sx={{ color: device.working ? null : theme.palette.error.dark }}
                  onClick={() => {
                    navigate(generatePath('/configuration/calendarInstance/:id', { id: device.calendarId }))
                  }}
                >
                  <CalendarMonthIcon />
                </IconButton>
                {getStatusIcon()}
              </Stack>

            </Stack>

          </CardContent>

        </Stack>
      </Card>
    </div>
  )
}
