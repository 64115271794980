import React, { useState } from 'react'
import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import AlertUI from '../../generic/AlertUI'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import CreateUserPasswordDialog from './CreateUserPasswordDialog'
import PasswordIcon from '@mui/icons-material/Password'
import { IconButton, Button } from '@mui/material'
import bcrypt from 'bcryptjs-react'

const Users = () => {
  const { t_ } = useSmartTranslation()
  const [AlertElement, showAlert] = AlertUI()
  const [dialogStatus, setDialogStatus] = useState({ open: false, row: null })
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [selectedDialogTab, setSelectedDialogTab] = useState(0)

  const columns = [{
    name: t_('Username'),
    field: 'username',
    charLimit: 70,
    mandatory: true,
    unique: true,
    edit: false
  },
  {
    name: t_('Name'),
    field: 'name',
    charLimit: 70,
    mandatory: true
  },
  {
    name: t_('Last name'),
    field: 'surname',
    charLimit: 70,
    mandatory: true
  },
  {
    name: t_('Phone'),
    field: 'phoneNumber',
    charLimit: 70
  },
  {
    name: t_('Email'),
    field: 'email',
    charLimit: 70
  },
  {
    name: t_('Profile'),
    field: 'profile.name',
    value: 'profile.id',
    type: 'select',
    edit: {
      source: async () => {
        const profileOptions = await apiGet('profilesNoPagination')
        return profileOptions
      },
      id: 'id',
      field: 'name'
    },
    mandatory: true
  },
  {
    name: t_('Allow change password'),
    field: 'allowChangePassword',
    defaultNewValue: true,
    type: 'boolean'
  }
  ]

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('user', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        return apiPost('user', row)
          .then(response => {
            setDialogStatus({ open: true, row: response })
          })
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('user/' + rows[0].id, rows)
        } else {
          return apiDelete('userMulti', rows)
        }
      }
    },
    custom: [
      {
        name: '',
        render: (row) => {
          return (
            <IconButton
              onClick={(event) => {
                setDialogStatus({ open: true, row })
              }}
            >
              <PasswordIcon />
            </IconButton>
          )
        }
      }
    ]
  }

  const handleSave = () => {
    if (selectedDialogTab === 0) {
      if (password.length < 5) {
        setPasswordError(t_('Password must have at least 5 characters'))
        return
      }

      if (password !== confirmPassword) {
        setPasswordError(t_("Passwords don't match"))
        return
      }
    }
    const salt = bcrypt.genSaltSync(10)
    const hashedPassword = bcrypt.hashSync(password, salt)

    apiPut('user/setPassword/' + false, { username: dialogStatus.row.username, hashedPassword, salt, challenge: null })
      .then(() => {
        setDialogStatus({ ...dialogStatus, open: false })
        showAlert({ severity: 'success', title: t_('Password setted correctly') })
      })
      .catch(() => {
        setDialogStatus({ ...dialogStatus, open: false })
        showAlert({ severity: 'error', title: t_('Error while setting password') })
      })

    resetPassword()
  }

  const resetPassword = () => {
    setPassword('')
    setConfirmPassword('')
    setPasswordError('')
  }

  const fetchFunction = async (pagination) => {
    const users = await apiGet('user', pagination)
    return users
  }
  return (
    <>

      <SmartDialog
        renderComponent={
          <CreateUserPasswordDialog
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            passwordError={passwordError}
            setSelectedDialogTab={setSelectedDialogTab}
            resetPassword={resetPassword}
          />
}
        setOpen={dialogStatus.open}
        cancel={false}
        accept={false}
        close
        closeCallback={() => {
          resetPassword()
          showAlert({ severity: 'info', title: t_("Password hasn't been setted") })
          if (dialogStatus.open) {
            setDialogStatus({ ...dialogStatus, open: false })
          }
        }}
        renderCustomButtons={
          <Button
            variant='contained'
            color='primary'
            onClick={handleSave}
            style={{ marginRight: '10px' }}
          >
            {t_('Save')}
          </Button>
        }
      />
      {AlertElement}
      <SmartMateriaUITable
        columns={columns}
        title={t_('Users')}
        dataFetch={fetchFunction}
        actions={actions}
        multipleSelection
        multipleOptions
        autocompleteAddButtonDisabled
        pageable
      />
    </>
  )
}

export default Users
