import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Alert } from '@mui/material'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

const FixedBottomDiv = (props) => (
  <div
    sx={{
      position: 'fixed',
      bottom: theme => theme.spacing(2),
      right: theme => theme.spacing(35)
    }}
  />
)

const AlertUI = () => {
  const [showAlert, setShowAlert] = useState(false)
  const [mode, setMode] = useState('error')
  const [alertTitle, setAlertTitle] = useState(undefined)
  const [alertMessage, setAlertMessage] = useState(undefined)
  const [fixedBottom, setFixedBottom] = useState(false)
  const location = useLocation()
  const { t_ } = useSmartTranslation()

  const defaultValues = {
    error: {
      title: '¡Ups, something has gone wrong!',
      message: 'Try again, if the problem persists contact with support.'
    },
    info: {
      title: t_('There have been no modifications.'),
      message: ''
    },
    success: {
      title: 'Operation successful.',
      message: ' '
    },
    warning: {
      title: 'Warning!',
      message: ''
    }
  }

  useEffect(() => {
    if (showAlert && (mode === 'info' || mode === 'success')) {
      setTimeout(function () {
        setShowAlert(false)
      }, 5000) // 5 Second delay
    }
  }, [showAlert, mode])

  useEffect(() => {
    setShowAlert(false)
  }, [location])

  const handleCloseAlert = () => {
    setShowAlert(false)
  }

  const AlertElement = () => (
    showAlert
      ? (
        <Alert
          variant='outlined' severity={mode} onClose={handleCloseAlert} sx={{ backgroundColor: theme => theme.palette.background.color_212121, width: '100%' }}
        >
          <strong>{alertTitle}</strong> {alertMessage}
        </Alert>
        )
      : null
  )

  const show = (
    { severity = 'success', title = undefined, message = undefined, fixedBottom = undefined }
  ) => {
    setMode(severity)
    setAlertTitle(getTitle(title, severity))
    setAlertMessage(getMessage(message, severity))
    setShowAlert(true)
    setFixedBottom(fixedBottom)
  }

  const getTitle = (title, severity) => {
    if (title === undefined) {
      return defaultValues[severity].title
    }
    return title
  }

  const getMessage = (message, severity) => {
    if (message === undefined || message === '') {
      return defaultValues[severity].message
    }
    return message
  }

  return [
    fixedBottom
      ? <FixedBottomDiv><AlertElement /></FixedBottomDiv>
      : <AlertElement />,
    show
  ]
}
export default AlertUI
