import React, { useEffect, useState } from "react";
import { apiGet } from "../../generic/Api_Functions";
import { TextField, MenuItem, Grid } from "@mui/material";
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const SensorsActuatorsComponentsProperties = (props) => {

  const { ioElementSet, setSaveDisabled } = props;
  const [ioElementSetTypes, setIoElementSetTypes] = useState([]);
  const [devices, setDevices] = useState([]);
  const [requiredName, setRequiredName] = useState(false)
  const { t_ } = useSmartTranslation();

  useEffect(() => {
    const fetchData = async () => {
      let ioElementSetTypeDBs = await apiGet("ioElementSetTypes");
      let deviceDB = await apiGet("devicesBasicData");
      setIoElementSetTypes(ioElementSetTypeDBs);
      setDevices(deviceDB);
    };

    fetchData()

  }, [ioElementSet]);

  const handleSelectValue = (value) => {
    if (devices.length === 0 || ioElementSetTypes.length === 0)
      return ""
    else
      return value
  }

  const handleNameChange = (event) => {
    if (event.target.value === "") {
      setRequiredName(true)
      setSaveDisabled(true)
    }
    else {
      setRequiredName(false)
      setSaveDisabled(false)
    }
  }

  return (
    <Grid container direction="row" spacing={3} columns={{ xs: 1, sm: 4, md: 8, lg: 12 }}>
      <Grid item xs={2}>
        <TextField label={t_("Code")} fullWidth disabled value={ioElementSet.code}
          InputLabelProps={{
            shrink: ioElementSet.code ? true : false,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField label={t_("Name")} fullWidth defaultValue={ioElementSet.name}
          required
          error={requiredName}
          onChange={(event) => handleNameChange(event)}
          InputLabelProps={{
            shrink: ioElementSet.name ? true : false,
          }} />
      </Grid>
      <Grid item xs={4}>
        <TextField label={t_("Description")} fullWidth defaultValue={ioElementSet.description}
          InputLabelProps={{
            shrink: ioElementSet.description ? true : false,
          }} />
      </Grid>
      <Grid item xs={2}>
        <TextField select label={t_("Asset")} fullWidth value={handleSelectValue(ioElementSet.device.name)}  >
          {devices.map((device) => (
            <MenuItem value={device.name} key={device.id}>
              {device.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField select fullWidth label={t_("Type")} value={handleSelectValue(ioElementSet.ioElementSetType.code)}>
          {ioElementSetTypes.map((ioElementSetType) => (
            <MenuItem value={ioElementSetType.code} key={ioElementSetType.id}>
              {ioElementSetType.code}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
};

export default SensorsActuatorsComponentsProperties;
