import {
  CircularProgress,
  Input,
  InputLabel,
  Grid,
  Typography,
  MenuItem,
  Divider,
  TextField,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  Paper
  , Button
} from '@mui/material'

import React, { useEffect, useRef, useState } from 'react'

import { apiGet, apiPut } from '../../generic/Api_Functions'
import { useNavigate, generatePath } from 'react-router'
import ButtonBackSave from '../../generic/buttons/floatingButtons/ButtonBackSave'
import { PositionCard } from '../../generic/utilities/PositionCard'
import { DefaultAccordion } from '../../generic/DefaultAccordion'
import { TextFieldWithUnit } from '../../generic/utilities/TextFieldWithUnit'
import { SmartSaveDialog, SmartDialog } from '../../generic/utilities/SmartDialog'
import createDialogTitle from '../../generic/utilities/dialogUtil'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { SmartSelect } from '../../generic/smartSelect'

const DeviceConfiguration = (props) => {
  const { deviceId, device, setDevice } = props
  const [loaded, setLoaded] = useState(false)
  const [deviceTypes, setDeviceTypes] = useState([])
  const [deviceMode, setDeviceMode] = useState([])
  const [protocols, setProtocols] = useState([])
  const [technologies, setTechnologies] = useState([])
  const [toolholderStandards, setToolholderStandards] = useState([])
  const [deviceParents, setDeviceParents] = useState([])
  const [storageTypes, setStorageTypes] = useState([])
  const backSaveButtonRef = useRef()
  const [editMode, setEditMode] = useState(false)
  const [parameterIsInError, setParameterIsInError] = useState({})
  const [showSaveConfirm, setShowSaveConfirm] = useState(false)
  const [showSaveErrorModal, setShowSaveErrorModal] = useState(false)
  const { t_ } = useSmartTranslation()

  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      const deviceTypesDB = await apiGet('deviceTypes')
      const deviceModeDB = await apiGet('devices/mode')
      const protocolsDB = await apiGet('protocols')
      const toolHolderStandardsDB = await apiGet('unitTypeValuesByUnitType/' + device.parameterList.find(parameter => parameter.code === 'TOOLHOLDER_STANDARD').unitType.id)
      const deviceParentsDB = await apiGet('devices/deviceParents/' + deviceId)
      const storageTypesDB = await apiGet('storageTypes')
      setDeviceTypes(deviceTypesDB)
      setDeviceMode(deviceModeDB)
      setProtocols(protocolsDB)
      setToolholderStandards(toolHolderStandardsDB)
      setTechnologies(device.deviceType.technologyDeviceTypes.map(a => a.technology))
      setDeviceParents(deviceParentsDB)
      setStorageTypes(storageTypesDB)
    }
    if (!loaded) {
      fetchData()
      setLoaded(true)
    }
  }, [loaded, deviceId, device])

  const changeBetweenVisualizeModes = (edit) => {
    setEditMode(edit)
  }

  const formatDeviceToPut = (deviceCopy) => {
    const dev = Object.assign(deviceCopy)
    dev.deviceType = {
      name: device.deviceType.name
    }
    return dev
  }

  const handleValue = (deviceTypeFunction, protocol) => {
    const deviceFunctionProtocol = device.deviceFunctionProtocols.find(
      (deviceFunctionProtocol) =>
        deviceFunctionProtocol.functionProtocol.function.id ===
        deviceTypeFunction.function.id
    )

    const functionProtocolConfiguration = deviceFunctionProtocol.functionProtocol.functionProtocolConfigurations.find(
      (configured) => {
        if (configured.protocolParameter.id ===
          protocol.id) {
          return configured.protocolValue
        } else return ''
      })
    if (functionProtocolConfiguration !== undefined) {
      return functionProtocolConfiguration.protocolValue
    }
    return ''
  }

  const goBack = (state) => {
    navigate('/configuration/assets', { replace: true, state })
  }

  const updateDevice = () => {
    const deviceCopy = { ...device }
    apiPut('devices/config', formatDeviceToPut(deviceCopy))
      .then((data) => {
        setDevice(data)
        if (device.deviceType.name !== 'STORAGE' || device.deviceTechnologies > 1 || device.deviceTechnologies[0] === undefined) {
          goBack('EDIT')
        } else {
          apiPut('shelveStorageConfigurationsUpdateAllTechnology', device.deviceTechnologies[0]).then((data) => {
            setDevice(data)
            goBack('EDIT')
          })
            .catch((error) => {
              backSaveButtonRef.current.showErrorAlert({
                title: 'An error occurred while editing.',
                message: error.message,
                error: 'error'
              })
            })
        }
      })
      .catch((error) => {
        backSaveButtonRef.current.showErrorAlert({
          title: 'An error occurred while editing.',
          message: error.message,
          error: 'error'
        })
      })
    setShowSaveConfirm(false)
  }

  const saveDevice = () => {
    if (Object.values(parameterIsInError).find(parameter => parameter === true) === undefined) { setShowSaveConfirm(true) } else {
      setShowSaveErrorModal(true)
    }
  }

  const selectedTechnologiesMultiSelect = (device) => {
    let returnValue
    if (device.deviceType.name === 'STORAGE') {
      returnValue = ''
    } else {
      returnValue = []
    }
    if (device.deviceTechnologies === null || device.deviceTechnologies === undefined) {
      return returnValue
    } else if (Object.keys(device.deviceTechnologies).length === 0) {
      return returnValue
    } else if (device.deviceTechnologies !== undefined && device.deviceTechnologies !== null && Array.isArray(device.deviceTechnologies)) {
      let selectedValues
      if (device.deviceType.name !== 'STORAGE') {
        selectedValues = []
      }
      device.deviceTechnologies.forEach((opt) => {
        if (device.deviceType.name === 'STORAGE') {
          selectedValues = opt.technology.name
        } else {
          selectedValues = selectedValues.concat(opt.technology.name)
        }
      })
      return selectedValues
    } else {
      return returnValue
    }
  }

  const optionTechnologiesMultiSelect = () => {
    if (technologies === null || technologies === undefined) {
      return []
    } else if (Object.keys(technologies).length === 0) {
      return []
    } else if (technologies !== undefined && technologies !== null && Array.isArray(technologies)) {
      const selectedValues = technologies.map((opt) => {
        return opt.name
      })
      return selectedValues
    } else {
      return []
    }
  }

  const onChangeTechnologyValues = (newTechnologiesSelectedValues, device) => {
    let newValues = []
    newTechnologiesSelectedValues.forEach((value) => {
      const newValue = technologies.find(elem => elem.name === value)
      const deviceTechnology = {
        device: { id: device.id, name: device.name }, technology: newValue
      }
      newValues = newValues.concat([deviceTechnology])
    })
    setDevice(({ ...device, deviceTechnologies: newValues }))
  }

  const handleChangeTool = (event) => {
    const copyDevice = { ...device }
    copyDevice.toolChangeEnabled = event.target.checked
    setDevice(copyDevice)
  }

  const selectableOptionsStorageTypes = () => {
    if (storageTypes === null || storageTypes === undefined) {
      return []
    } else if (Object.keys(storageTypes).length === 0) {
      return []
    } else if (storageTypes !== undefined && storageTypes !== null && Array.isArray(storageTypes)) {
      const selectedValues = storageTypes.map((opt) => {
        return opt.name
      })
      return selectedValues
    } else {
      return []
    }
  }

  const onChangeStorageTypes = (newStorageTypesSelectedValues) => {
    const newStorageTypesValues = newStorageTypesSelectedValues.map((value) => {
      const newStorageTypeValues = storageTypes.find((elem) => elem.name === value)
      return {
        id: newStorageTypeValues.id,
        code: newStorageTypeValues.code,
        name: newStorageTypeValues.name
      }
    })
    setDevice({ ...device, storageTypes: newStorageTypesValues })
  }

  const handleChangeDeviceParent = (event) => {
    if (event === null) {
      setDevice({
        ...device,
        parent: null
      })
      return
    }
    const parent = deviceParents.find(
      (item) => item.name === event
    )
    setDevice({
      ...device,
      parent
    })
  }

  const onPositionChange = (newValue, id) => {
    const positionAux = device.position
    positionAux[id] = newValue
    setDevice({ ...device, position: positionAux })
  }

  const onParameterChange = (newValue, parameterName) => {
    const parameterAux = device.parameterList
    parameterAux.find(parameter => parameter.name === parameterName).value = newValue
    setDevice({ ...device, parameterList: parameterAux })
  }

  const onParameterUnitChange = (newValue, parameterName) => {
    const parameterListAux = device.parameterList
    const parameter = parameterListAux.find(parameter => parameter.name === parameterName)
    parameter.unit = parameter.unitType.availableUnits.find(unit => unit.code === newValue)
    setDevice({ ...device, parameterList: parameterListAux })
  }

  const onParameterErrorStateChange = (newValue, parameterName) => {
    const parameterIsInErrorAux = parameterIsInError
    parameterIsInErrorAux[parameterName] = newValue
    setParameterIsInError(parameterIsInErrorAux)
  }

  const printDevice = () => {
    return (
      <div>
        <SmartSaveDialog
          show={showSaveConfirm}
          cancelCallback={() => { setShowSaveConfirm(false) }}
          saveCallback={updateDevice}
        />
        <SmartDialog
          setOpen={showSaveErrorModal}
          title={createDialogTitle({ color: 'error', title: 'Error saving', icon: WarningAmberIcon })}
          message='There is a parameter without a unit assigned'
          cancel={false}
          accept={false}
          close
          closeCallback={() => { setShowSaveErrorModal(false) }}

        />
        <DefaultAccordion title={t_('GENERAL PROPERTIES')}>
          <Grid container direction='row' spacing={2} columns={{ xs: 1, sm: 4, md: 8, lg: 12 }}>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label={t_('Name')}
                id='deviceName'
                value={device.name}
                onChange={(event) => {
                  setDevice({ ...device, name: event.target.value })
                }}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label={t_('Description')}
                id='description'
                value={device.description}
                onChange={(event) => {
                  setDevice({ ...device, description: event.target.value })
                }}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                id='type'
                label={t_('Type')}
                value={deviceTypes.length > 0 ? device.deviceType.id : ''}
                onChange={(event) => {
                  const deviceType = deviceTypes.find(
                    (type) => type.id === event.target.value
                  )
                  const deviceFunctionProtocols = []

                  setDevice({
                    ...device,
                    deviceType,
                    driver: deviceType.drivers[0],
                    deviceFunctionProtocols
                  })
                }}
                disabled={!editMode}
              >
                {deviceTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={1}>
              <TextField
                fullWidth
                id='presetAngle'
                label={t_('presetAngle')}
                type='number'
                value={device.presetAngle ? device.presetAngle : 0}
                onChange={(event) => {
                  setDevice({ ...device, presetAngle: event.target.value })
                }}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField id='status' label={t_('Status')} value={device.status} disabled />
            </Grid>
            <Grid item xs={1}>
              <TextField
                fullWidth
                select
                id='micStatus'
                label={t_('Mode')}
                value={deviceMode.length > 0 ? device.mode : ''}
                onChange={(event) => {
                  setDevice({ ...device, mode: event.target.value })
                }}
                disabled={!editMode}
              >
                {deviceMode.map((mode, index) => (
                  <MenuItem key={mode + index} value={mode} disabled={mode === 'NON_OPERATIVE'}>
                    {mode}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container direction='row' spacing={3} columns={{ xs: 1, sm: 4, md: 8, lg: 12 }} sx={{ paddingTop: '2em' }}>
            <Grid item xs={2}>
              <TextField
                fullWidth
                select
                id='driver'
                label={t_('Driver')}
                value={
                  device.deviceType.drivers.length > 0
                    ? device.driver
                      ? device.driver.id
                      : 0
                    : 0
                }
                onChange={(event) => {
                  setDevice({
                    ...device,
                    driver: device.deviceType.drivers.find(
                      (driver) => driver.id === event.target.value
                    )
                  })
                }}
                disabled={!editMode}
              >
                <MenuItem key={0} value={0}>
                  {t_('NONE')}
                </MenuItem>
                {device.deviceType.drivers.map((driver, index) => (
                  <MenuItem key={driver.id + index} value={driver.id}>
                    {driver.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              {
                device.deviceType.name !== 'STORAGE'

                  ? <SmartSelect
                      disabled={!editMode}
                      selectableOptions={optionTechnologiesMultiSelect(device)}
                      value={device.deviceType.name !== 'STORAGE' ? selectedTechnologiesMultiSelect(device) : []}
                      multiple
                      onChange={(newValues) => onChangeTechnologyValues(newValues, device)}
                      label={t_('Technologies')}
                    // functionToChangeTheValueWhenAddANewElement={onChangeTechnologyValues}
                      readOnly={false}
                    />
                  : device.deviceTechnologies.length > 1
                    ? (
                      <><InputLabel htmlFor='technology'>{t_('Technology')}</InputLabel>
                        <Input id='technology' value='MIXED' disabled />
                      </>
                      )
                    : (
                      <TextField
                        fullWidth
                        select
                        id='technology'
                        label={t_('Technology')}
                        value={technologies.length > 0 ? device.deviceTechnologies.length === 1 ? device.deviceTechnologies[0].technology.name : selectedTechnologiesMultiSelect(device) : ''}
                        onChange={(event) => {
                          const deviceTechnology = []
                          const newValue = technologies.find(elem => elem.name === event.target.value)
                          deviceTechnology.push({ device: { id: device.id, name: device.name }, technology: newValue })
                          setDevice({ ...device, deviceTechnologies: deviceTechnology })
                        }}
                        disabled={!editMode}
                      >
                        {technologies.map((technology, index) => (
                          <MenuItem key={'technology_select' + technology.id + index} value={technology.name}>
                            {technology.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      )
              }
            </Grid>
            <Grid item xs={2}>
              {device.deviceType.name === 'STORAGE'
                ? (
                  <>
                    <SmartSelect
                      label={t_('Storage type')}
                      disabled={!editMode}
                      multiple
                      value={device.storageTypes.map((opt) => opt.name)}
                      onChange={onChangeStorageTypes}
                      selectableOptions={selectableOptionsStorageTypes()}
                    />
                  </>
                  )
                : <FormControlLabel
                    control={
                      <Checkbox
                        checked={device.toolChangeEnabled}
                        onChange={handleChangeTool}
                        disabled={!editMode}
                      />
                    } label={t_('Tool change')}
                  />}
            </Grid>
            <Grid item xs={2}>
              {deviceParents?.length !== 0
                ? (
                  <SmartSelect
                    disabled={!editMode}
                    value={device.parent?.name}
                    selectableOptions={deviceParents.map((item) => item.name)}
                    label={t_('Parent asset')}
                    onChange={handleChangeDeviceParent}
                    disableClearable={false}
                    autoSelectFirst={false}
                  />
                  )
                : <TextField
                    disabled
                    value=''
                    label={t_('Parent asset')}
                  />}
            </Grid>
            <Grid item xs={2}>
              <Button variant='outlined' disabled={!editMode} endIcon={<CalendarMonthIcon />} onClick={() => navigate(generatePath('/configuration/calendarInstance/:id', { id: device.calendarId }))}>{t_('Calendar')}</Button>
            </Grid>
          </Grid>
        </DefaultAccordion>
        <DefaultAccordion title={t_('POSITION PROPERTIES')}>
          <Grid container direction='row' spacing={2} columns={{ xs: 1, sm: 4, md: 8, lg: 12 }}>
            <PositionCard position={device.position} onChange={(newValue, id) => onPositionChange(newValue, id)} editMode={editMode} />
          </Grid>
        </DefaultAccordion>
        <DefaultAccordion title={t_('GEOMETRICAL PROPERTIES')}>
          <Grid container direction='row' spacing={2} columns={{ xs: 1, sm: 4, md: 8, lg: 12 }}>
            {device.parameterList.map(
              (parameter) => (
                <Grid item xs={2} key={'geometric-' + parameter.code}>
                  <Paper elevation={6}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          {
                            parameter.code === 'TOOLHOLDER_STANDARD'
                              ? (
                                <TextField
                                  key={parameter.code}
                                  label={t_(parameter.name)}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  onChange={(event) => onParameterChange(event.target.value, parameter.name)}
                                  select
                                  value={parameter.value === null || toolholderStandards.length === 0 ? '' : parameter.value}
                                  disabled={!editMode}
                                >
                                  {
                                  parameter.code === 'TOOLHOLDER_STANDARD'
                                    ? [
                                      <MenuItem key='toolholderStandardNone' value=''>{t_('NONE')}</MenuItem>,
                                      toolholderStandards.map((toolholderStandard, index) => (
                                        <MenuItem key={'toolholderStandard' + index} value={toolholderStandard.value}>
                                          {toolholderStandard.value}
                                        </MenuItem>
                                      ))
                                      ]
                                    : null
                                }
                                </TextField>
                                )
                              : <TextFieldWithUnit
                                  key={parameter.code}
                                  name={t_(parameter.name)}
                                  value={parameter.value}
                                  onValueChange={onParameterChange}
                                  unitValue={parameter.unit !== null ? parameter.unit.code : ''}
                                  unitOptions={parameter.unitType.availableUnits}
                                  onUnitValueChange={onParameterUnitChange}
                                  disabled={!editMode}
                                  onParameterErrorStateChange={onParameterErrorStateChange}
                                />
                          }
                        </Grid>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              ))}
            <Grid item xs={2}>
              <Paper elevation={6}>
                <Card>
                  <CardContent>
                    <Grid container>
                      <TextField
                        fullWidth
                        label={t_('Asset model file name')}
                        id='deviceModelFile'
                        value={device.modelFile}
                        onChange={(event) => {
                          setDevice({ ...device, modelFile: event.target.value })
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        disabled={!editMode}
                      />
                    </Grid>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          </Grid>
        </DefaultAccordion>
        {
          device.deviceType.deviceTypeFunctions.length < 1
            ? null
            : (
              <DefaultAccordion title={t_('FUNCTION PROPERTIES')}>
                {device.deviceType.deviceTypeFunctions.map(
                  (deviceTypeFunction, index) => (
                    <React.Fragment key={'deviceTypeFunction' + index}>
                      <Grid container direction='row' spacing={3} columns={{ xs: 1, sm: 4, md: 8, lg: 12 }} sx={{ padding: '0.5em' }}>
                        <Grid item xs={12}>
                          <Typography sx={{ flex: '1 1 100%' }} component='div'>
                            {t_(deviceTypeFunction.function.name)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            select
                            id={deviceTypeFunction.function.id + '_protocol'}
                            label={t_('Protocol')}
                            value={
                            protocols.length === 0
                              ? 0
                              : device.deviceFunctionProtocols.find(
                                (configuredProtocol) =>
                                  configuredProtocol.functionProtocol.function
                                    .id === deviceTypeFunction.function.id
                              ) &&
                                device.deviceFunctionProtocols.find(
                                  (configuredProtocol) =>
                                    configuredProtocol.functionProtocol.function
                                      .id === deviceTypeFunction.function.id
                                ).functionProtocol.protocol
                                ? device.deviceFunctionProtocols.find(
                                  (configuredProtocol) =>
                                    configuredProtocol.functionProtocol.function
                                      .id === deviceTypeFunction.function.id
                                ).functionProtocol.protocol.id
                                : 0
                          }
                            onChange={
                            (event) => {
                              const distinctDeviceFunctionProtocols =
                                device.deviceFunctionProtocols.filter(
                                  (pr) => {
                                    return pr.functionProtocol.function.id !==
                                      deviceTypeFunction.function.id
                                  }
                                )

                              const protocol = protocols.find(
                                (pr) => {
                                  return pr.id === event.target.value
                                }
                              )
                              if (protocol !== undefined) {
                                const configs = []
                                protocol.protocolParameters.forEach(
                                  (pr) => {
                                    configs.push({
                                      protocolValue: '',
                                      protocol: pr,
                                      protocolParameter: pr,
                                      deviceTypeFunction,
                                      functionProtocol: {
                                        function: deviceTypeFunction.function,
                                        protocol: protocols.find(
                                          (pr) => pr.id === event.target.value
                                        )
                                      }
                                    })
                                  })
                                if (event.target.value > 0) {
                                  const dfProtocol = {
                                    functionProtocol: {
                                      function: deviceTypeFunction.function,
                                      protocol: protocols.find(
                                        (pr) => pr.id === event.target.value
                                      ),
                                      functionProtocolConfigurations: configs
                                    }
                                  }

                                  distinctDeviceFunctionProtocols.push(dfProtocol)
                                }
                              }
                              setDevice({
                                ...device,
                                deviceFunctionProtocols: distinctDeviceFunctionProtocols
                              })
                            }
                          }
                            disabled={!editMode}
                          >
                            <MenuItem key={0} value={0}>
                              {t_('NONE')}
                            </MenuItem>
                            {
                            protocols.map((pr, index) => (
                              <MenuItem key={pr.id + index} value={pr.id}>
                                {pr.name}
                              </MenuItem>
                            ))
                          }
                          </TextField>
                        </Grid>
                        {
                        device.deviceFunctionProtocols.find(
                          (configuredProtocol) =>
                            configuredProtocol.functionProtocol.function.id ===
                            deviceTypeFunction.function.id
                        )
                          ? (
                              device.deviceFunctionProtocols
                                .find(
                                  (configuredProtocol) =>
                                    configuredProtocol.functionProtocol.function.id ===
                                deviceTypeFunction.function.id
                                )
                                .functionProtocol.protocol.protocolParameters.map(
                                  (protocol, index) => (
                                    <Grid key={index} item xs={12} md={4}>
                                      <TextField
                                        id={
                                      'function_' +
                                      deviceTypeFunction.function.id +
                                      '_parameter_' +
                                      protocol.id
                                    }
                                        label={t_(protocol.parameter)}
                                        value={handleValue(deviceTypeFunction, protocol)}
                                        onChange={(event) => {
                                          const copyDevice = { ...device }
                                          if (copyDevice.deviceFunctionProtocols.find((deviceFunctionProtocol) => {
                                            return deviceFunctionProtocol.functionProtocol.function.id ===
                                          deviceTypeFunction.function.id
                                          })) {
                                            device.deviceFunctionProtocols.map((deviceFunctionProtocol) => {
                                              return deviceFunctionProtocol.functionProtocol.functionProtocolConfigurations.find(
                                                (configured) => {
                                                  if (configured.protocolParameter.id ===
                                                protocol.id && configured.functionProtocol.function.id === deviceTypeFunction.function.id) {
                                                    configured.protocolValue = event.target.value
                                                  }
                                                  return false
                                                })
                                            })
                                          }
                                          setDevice({ ...device, copyDevice })
                                        }}
                                        disabled={!editMode}
                                      />
                                    </Grid>
                                  )
                                )
                            )
                          : (
                            <div />
                            )
                      }
                        <Grid item xs={12}>
                          <Divider spacing={2} />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )
                )}
              </DefaultAccordion>
              )
        }
        <ButtonBackSave
          editControl={() => changeBetweenVisualizeModes(true)}
          backButtonAction={() => goBack('BACK')}
          saveButtonAction={() => saveDevice()}
          ref={backSaveButtonRef}
        />
      </div>
    )
  }

  if (device.id) {
    return printDevice()
  }

  return <CircularProgress />
}

export default DeviceConfiguration
