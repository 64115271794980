import React, { useCallback, useReducer, useState } from 'react'
import { apiDelete, apiGet, apiPost, apiPut } from '../generic/Api_Functions'
import { SmartDialog } from '../generic/utilities/SmartDialog'
import Part from '../configuration/parts/Part'
import { StorageRounded, WindowRounded, FileDownloadOutlined, Lock, LockOpenRounded } from '@mui/icons-material'
import AlertUI from '../generic/AlertUI'
import SmartMateriaUITable from '../generic/SmartMateriaUITable'
import {
  Box, Button,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import createDialogTitle from '../generic/utilities/dialogUtil'
import { useNavigate, useParams } from 'react-router-dom'
import ButtonBack from '../generic/buttons/floatingButtons/ButtonBack'
import CheckDialog from '../generic/CheckDialog'
import VerticalResourceAssembly from './VerticalResourceAssembly'
import MoveUpIcon from '@mui/icons-material/MoveUp'
import Fixture from './../configuration/fixture/Fixture'
import Tool from '../configuration/tools/Tool'
import RawMaterial from '../configuration/parts/RawMaterial'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import { useSmartInterval } from '../generic/hooks/useSmartInterval'

const titleIconStyle = {
  alignSelf: 'center',
  marginLeft: '1rem'
}

const StoragePosition = () => {
  const [AlertElement, showAlert] = AlertUI()
  const [emptyAllDialogOpen, setEmptyAllDialogOpen] = useState(false)
  const [emptyPositionToInOutModule, setEmptyPositionToInOutModule] = useState(false)
  const [unlockStorageDialogOpen, setUnlockStorageDialogOpen] = useState(false)
  const [selectedRowToInOut, setSelectedRowToInOut] = useState({})
  const [rowToInOutStorage, setRowToInOutStorage] = useState('')
  const [storageList, setStorageList] = useState({})
  const [storage, setStorage] = useState({})
  const params = useParams()
  const navigate = useNavigate()
  const [showCheckDialog, setShowCheckDialog] = useState(false)
  const [alertResourceRemove, showAlertResourceRemove] = AlertUI()
  const { t_ } = useSmartTranslation()

  const onDispatchResourceRemoveStatus = (state, action) => {
    if (action.type === 'OPEN') {
      return { ...state, open: true, resources: action.resources, selectedIndex: null, selectedResource: action.resources.length === 1 ? action.resources[0] : null, selectedPositionId: action.selectedPositionId }
    }
    if (action.type === 'CLOSE') {
      return { ...state, open: false }
    }
    if (action.type === 'SET_SELECTED') {
      return { ...state, selectedIndex: action.selectedIndex, selectedResource: action.selectedResource }
    }
    if (action.type === 'SHOW_ERROR') {
      return
    }
    return state
  }

  const [resourceRemoveStatus, dispatchResourceRemoveStatus] = useReducer(onDispatchResourceRemoveStatus, { open: false, resources: null, selectedIndex: null, selectedResource: null, selectedPositionId: null })

  useSmartInterval(
    async () => {
      const response = await apiGet('storages/' + params.storageId)
        .catch((error) => {
          showAlert({
            title: 'Error getting storage.',
            severity: error.name
          })
        })
      return response
    }, 1000,
    (data) => {
      if (storage.id === undefined || storage.id === null || storage.name !== data.name || storage.deviceIsBooked !== data.deviceIsBooked) {
        setStorage(data)
      }
    }
  )

  const isCellRunning = async () => {
    const status = await apiGet('cellGeneralStatus')
    return status === 'AUTOMATIC'
  }

  const onEditingDataChangedCall = (state, action) => {
    switch (action.type) {
      case 'EDIT_START': {
        return { row: action.value, rowEditing: true, selectedType: action.selectedType, selectedReference: null, selectedResource: action.value.topResource, resourceAccept: false, dialogOpen: false, findFixtureReference: false, alternateFixture: null }
      }
      case 'ACCEPT': {
        if (!state.selectedResource) {
          return state
        }
        if (state.selectedResource && state.selectedResource.location) {
          return { ...state, showWarningResourceInCell: true }
        }
        return { ...state, resourceAccept: true, dialogOpen: false }
      }
      case 'CLOSE_WARNING_RESOURCE_IN_CELL': {
        return { ...state, showWarningResourceInCell: false, selectedResource: null }
      }
      case 'ACCEPT_FORCE': {
        return { ...state, resourceAccept: true, dialogOpen: false, showWarningResourceInCell: false }
      }
      case 'SET_TYPE': {
        return { ...state, selectedType: action.value, resourceAccept: false, selectedReference: null, selectedResource: null }
      }
      case 'SET_RESOURCE': {
        const resource = { id: action.value.id, code: action.value.code, name: action.value.name, status: action.value.status, location: action.value.location }
        resource.reference = state.selectedReference
        return { ...state, selectedResource: resource, resourceAccept: false }
      }
      case 'SET_REFERENCE': {
        return { ...state, selectedReference: action.value, selectedResource: null, resourceAccept: false, dialogOpen: true }
      }
      case 'EDIT_END': {
        return { ...state, rowEditing: false }
      }
      case 'CLOSE': {
        return { ...state, dialogOpen: false, findFixtureReference: false, alternateFixture: null }
      }
      case 'ALTERNATE_FIND_FIXTURE': {
        return { ...state, findFixtureReference: action.value }
      }
      case 'ALTERNATE_FIXTURE_SET': {
        return { ...state, alternateFixture: action.value }
      }
      default: return { ...state }
    }
  }

  const [editingData, onEditingDataChanged] = useReducer(onEditingDataChangedCall, { row: null, rowEditing: false, selectedType: null, selectedReference: null, selectedResource: null, resourceAccept: false, dialogOpen: false })

  const fetchData = useCallback(async (pagination) => {
    const paginationColumnMapper = { 'topResourceType.name': 'topResource.type.name', 'topResourceReference.name': 'topResource.reference.name' }

    // Change pagination data
    pagination.sortKey = pagination.sortKey.map((item) => {
      if (item in paginationColumnMapper) {
        return paginationColumnMapper[item]
      }
      return item
    })

    if (pagination.columnFilters) {
      pagination.columnFilters = pagination.columnFilters.map((item) => {
        if (item in paginationColumnMapper) {
          return paginationColumnMapper[item]
        }
        return item
      })
    }

    pagination.columns = pagination.columns.map((item) => {
      if (item in paginationColumnMapper) {
        return paginationColumnMapper[item]
      }
      return item
    })

    let dataList = []

    dataList = await apiGet('shelveStoragePositionsPagination/' + params.storageId, pagination)
      .catch((error) => {
        showAlert({
          title: 'Error getting data.',
          severity: error.name
        })
      })
    dataList.content = dataList.content.map((element) => { return { ...element, topResourceType: element.topResource ? element.topResource.type : null, topResourceReference: element.topResource ? element.topResource.reference : null } })
    return dataList
  }, [showAlert, params.storageId])

  const sortOptions = [
    {
      field: 'shelve',
      direction: 1
    },
    {
      field: 'position',
      direction: 1
    },
    {
      field: 'depth',
      direction: 1
    }
  ]

  const emptyAll = () => {
    apiPost('shelveStoragePositions/emptyAllByStorage/' + params.storageId)
      .then(() => {
        showAlert({
          title: t_('Success'),
          message: t_('Storage has been succesfully emptied'),
          severity: 'success'
        })
      })
      .catch((error) => {
        showAlert({
          title: t_('Error emptying storage'),
          message: error.message,
          severity: error.name
        })
      })
    setEmptyAllDialogOpen(false)
  }

  const defaultToolbar = {
    render: {
      fetchFunction: () => {
        return (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <Button startIcon={<WindowRounded />} color='primary' variant='text' onClick={() => { setEmptyAllDialogOpen(true) }}>{t_('Empty positions')}</Button>
          </>
        )
      }
    }
  }

  const onAcceptResourceRemove = async () => {
    if (resourceRemoveStatus.selectedResource === null) {
      showAlertResourceRemove({ title: t_('At least one item must be selected'), severity: 'error', message: t_('Please select an item') })
      return
    }
    try {
      await apiPost('storageRemoveResource/' + resourceRemoveStatus.selectedPositionId, resourceRemoveStatus.selectedResource)
      dispatchResourceRemoveStatus({ type: 'CLOSE' })
    } catch (error) {
      showAlertResourceRemove({ title: t_('Error trying to remove resource'), severity: 'error', message: t_(error.message) })
    }
  }

  const onEditIndexChanged = (editIndex, row) => {
    if (editIndex >= 0) {
      onEditingDataChanged({ type: 'EDIT_START', value: row, selectedType: row.topResourceType ? row.topResourceType : null })
    } else {
      onEditingDataChanged({ type: 'EDIT_END' })
    }
  }

  const handleResourcePopupAcceptButton = () => {
    if (editingData.selectedResource) {
      onEditingDataChanged({ type: 'ACCEPT' })
    } else {
      showAlert({
        title: t_('Operation canceled. Resource has not been choosen'),
        severity: 'info'
      })
    }
  }

  const handleFixturesPopupCancelButton = () => {
    onEditingDataChanged({ type: 'CLOSE' })
  }

  const onRowClick = (resource) => {
    onEditingDataChanged({ type: 'SET_RESOURCE', value: resource })
  }

  const columns = [
    {
      name: t_('Shelf'),
      field: 'shelve',
      edit: false
    },
    {
      name: t_('Row'),
      field: 'position',
      edit: false
    },
    {
      name: t_('Column'),
      field: 'depth',
      edit: false
    },

    {
      name: t_('Type'),
      field: 'topResourceType.name',
      type: 'select',
      mandatory: true,
      edit: {
        source: async () => Promise.resolve([{ name: 'Fixture', label: 'Fixture' }, { name: 'Tool', label: 'Tool' }, { name: 'RawMaterial', label: 'RawMaterial' }, { name: 'Part', label: 'Part' }].sort((i1, i2) => i1.label.localeCompare(i2.label))),
        id: 'name',
        field: 'label',
        onClick_render: async (row) => {
          if (row && row.topResourceType) {
            onEditingDataChanged({ type: 'SET_TYPE', value: row.topResourceType })
          }
        }
      }
    },
    {
      name: t_('Reference'),
      field: 'topResourceReference.name',
      type: 'select',
      sortable: true,
      mandatory: true,
      edit: {
        source: async () => {
          if (!editingData.selectedType) {
            return []
          }
          if (editingData.selectedType.name === 'Part') {
            return await apiGet('partReferencesDto')
          }
          if (editingData.selectedType.name === 'Fixture') {
            return await apiGet('fixtureReferences')
          }
          if (editingData.selectedType.name === 'Tool') {
            return await apiGet('toolReferencesDto')
          }
          if (editingData.selectedType.name === 'RawMaterial') {
            return await apiGet('rawMaterialsReference')
          }
        },
        id: 'id',
        field: 'name',
        onClick_render: async (row) => {
          if (row && row.topResourceReference) {
            onEditingDataChanged({ type: 'SET_REFERENCE', value: row.topResourceReference })
          }
        }
      }
    },
    {
      name: t_('Code'),
      field: 'topResource.code',
      render: (row) => {
        const editing = editingData.rowEditing && editingData.row && editingData.row.id === row.id
        if (editing) {
          return <TextField variant='standard' disabled={editing} value={editingData.resourceAccept ? editingData.selectedResource.code : ''} />
        } else {
          return <VerticalResourceAssembly items={row.resources} renderTopItem={(item) => item ? item.code : null} />
        }
      },
      readOnly: true
    },
    {
      name: t_('Status'),
      field: 'status',
      render: (row) => {
        const editing = editingData.rowEditing && editingData.row && editingData.row.id === row.id
        if (editing) {
          return <TextField variant='standard' disabled={editing} value={editingData.resourceAccept ? editingData.selectedResource.status : ''} />
        } else {
          return row.status
        }
      },
      readOnly: true
    }
  ]

  const actions = {
    custom: [
      {
        render: (row) => {
          // Show only if
          return (
            <IconButton
              onClick={() => { dispatchResourceRemoveStatus({ type: 'OPEN', resources: row.resources, selectedPositionId: row.id }) }}
              disabled={row.resources === null || row.resources.length === 0 || editingData.rowEditing}
            >
              <MoveUpIcon />
            </IconButton>
          )
        }
      },
      {
        render: (row) => (
          <>
            <IconButton
              onClick={async () => {
                if (row.isFixtureRequestOut) {
                  setSelectedRowToInOut(row)
                  setEmptyPositionToInOutModule(true)
                  return
                }
                apiGet('storagesBasicData/inOut').then(data => {
                  if (data.length === 0) {
                    showAlert({
                      title: t_('There is not a storage set as an In/Out.'),
                      severity: 'warning'
                    })
                    return
                  }

                  setRowToInOutStorage(data[0].id)
                  setStorageList(data)
                  setSelectedRowToInOut(row)
                  setEmptyPositionToInOutModule(true)
                }).catch((error) =>
                  showAlert({
                    title:
                      t_('An error occurred while retrieving storages.'),
                    message: error.message,
                    severity: 'error'
                  }))
              }}
              disabled={(row.storageIsInOut || row.topResource === null) || editingData.rowEditing}
              color={row.isFixtureRequestOut === true ? 'warning' : 'primary'}
            >
              <FileDownloadOutlined />
            </IconButton>
          </>
        )
      }
    ],
    edit: {
      fetchFunction: (editRow) => {
        if (!editingData.resourceAccept || editingData.selectedResource === null) {
          showAlert({
            title: t_('Operation canceled. Resource has not been choosen'),
            severity: 'info'
          })
          return
        }
        const resource = { ...editingData.selectedResource, type: editingData.selectedType }
        return apiPost('storageSetResourceInPosition/' + editRow.id, resource).then((data) => { }).catch(async (error, data) => {
          if (error.status !== 409) {
            throw error
          }
          const reference = await apiPost('storageGetDefaultFixtureReferenceForResource', resource)
          if (!reference) {
            throw error
          }
          onEditingDataChanged({ type: 'ALTERNATE_FIND_FIXTURE', value: reference })
          error.name = 'warning'
          error.message = t_('Could not find suitable Fixture. Please select one')
          throw error
        })
      },
      condition: (shelveStoragePosition) => {
        return !shelveStoragePosition.locked
      }
    }
  }

  const emptyPositionToInOut = async () => {
    await apiPost('fixtureRequestOut/' + selectedRowToInOut.resources[selectedRowToInOut.resources.length - 1].id + '/' + rowToInOutStorage).then(() => {
      showAlert({
        title: t_('Fixture request out set correctly.'),
        severity: 'success'
      })
    }).catch((error) => {
      showAlert({
        title:
          t_('An error occurred while setting fixture as request out.'),
        message: error.message,
        severity: error.name
      })
    })
    setEmptyPositionToInOutModule(false)
  }

  const cancelEmptyPositionToInOut = async () => {
    await apiDelete('fixtureRequestOut/' + selectedRowToInOut.resources[selectedRowToInOut.resources.length - 1].id).then(() => {
      showAlert({
        title: t_('Fixture request out canceled correctly.'),
        severity: 'success'
      })
    }).catch((error) => {
      showAlert({
        title:
          t_('An error occurred while canceling fixture as request out.'),
        message: error.message,
        severity: error.name
      })
    })
    setEmptyPositionToInOutModule(false)
  }

  const smartTableTitle = () => {
    return (
      <>
        {storage?.name}
        {storage?.deviceIsBooked
          ? <IconButton sx={titleIconStyle} onClick={() => checkBookedStorage(storage.id)}> <Lock color='error' /></IconButton>
          : <LockOpenRounded color='primary' sx={titleIconStyle} style={{ verticalAlign: 'text-bottom' }} />}
      </>
    )
  }
  const unlockStorage = async () => {
    await apiPut('unlockStorage/' + storage.id).then(() => {
      showAlert({
        title: t_('Storage successfully unlocked.')
      })
    }).catch((error) => {
      showAlert({
        title:
          t_('An error occurred while unlocking storage.'),
        message: error.message,
        severity: error.name
      })
    })
  }

  const getResourceSelectTable = () => {
    if (editingData.selectedType?.name === 'Part') {
      return <Part partReference={editingData.selectedReference} hideColumns forceLoad setSelectedPart={onRowClick} />
    } else if (editingData.selectedType?.name === 'Fixture') {
      return <Fixture referenceName={editingData.selectedReference?.name} parameter={editingData.selectedReference?.id} endpoint='fixtures' hideColumns onRowClick={onRowClick} />
    } else if (editingData.selectedType?.name === 'Tool') {
      return <Tool row={editingData.selectedReference} endpoint='tools' hideColumns units='' onRowClick={onRowClick} />
    } else if (editingData.selectedType?.name === 'RawMaterial') {
      return <RawMaterial reference={editingData.selectedReference} endpoint='rawMaterials' hideColumns onRowClick={onRowClick} />
    }
  }

  const onAlternateFixtureSelected = async () => {
    if (!editingData.resourceAccept || editingData.selectedResource === null || !editingData.alternateFixture) {
      showAlert({
        title: t_('Operation canceled. Resource has not been choosen'),
        severity: 'info'
      })
      onEditingDataChanged({ type: 'CLOSE' })
      return
    }
    const resource = { ...editingData.selectedResource, type: editingData.selectedType }
    await apiPost('storageSetResourceInPosition/' + editingData.row.id + '/' + editingData.alternateFixture.id, resource).then((data) => {

    }).catch(async (error, data) => {
      showAlert({
        title: t_('Could not set resource in position'),
        message: t_(error.message),
        severity: 'error'
      })
    })
    onEditingDataChanged({ type: 'CLOSE' })
  }
  const checkBookedStorage = async (storageId) => {
    const response = await apiGet('storage/isBooked/' + storageId)
    if (response) {
      setUnlockStorageDialogOpen(true)
    } else {
      showAlert({
        title: t_('Error'),
        message: t_('Storage is locked by the system, please wait'),
        severity: 'error'
      })
    }
  }

  return (
    <div>
      {resourceRemoveStatus.resources
        ? <SmartDialog
            setOpen={resourceRemoveStatus.open}
            message={resourceRemoveStatus.resources.length > 1 ? t_('Select the resource(s) you want to remove') : t_('Are you sure you want to remove the fixture') + " '" + resourceRemoveStatus.resources[0].code + "' ?"}
            cancelCallback={() => dispatchResourceRemoveStatus({ type: 'CLOSE' })}
            acceptCallback={onAcceptResourceRemove}
            renderComponent={
              <Stack gap={3} alignItems='center'>
                {alertResourceRemove}
                {resourceRemoveStatus.resources.length > 1
                  ? <VerticalResourceAssembly
                      items={resourceRemoveStatus.resources ? resourceRemoveStatus.resources : []}
                      showAll
                      selectedIndex={resourceRemoveStatus.selectedIndex}
                      onResourceClick={(resource, index) => dispatchResourceRemoveStatus({ type: 'SET_SELECTED', resource, selectedIndex: index, selectedResource: resource })}
                    />
                  : null}
              </Stack>
          }
          />
        : null}
      <SmartDialog
        setOpen={emptyPositionToInOutModule}
        message={selectedRowToInOut.isFixtureRequestOut ? t_('Are you sure you want to cancel the movement of this fixture to the Input/Output module? This action may have no effect') : t_('Are you sure you want to empty current position to the in/Out module?')}
        title={createDialogTitle({ title: selectedRowToInOut.isFixtureRequestOut ? t_('Cancel In/Out movement?') : t_('Empty to In/Out?'), icon: FileDownloadOutlined, color: selectedRowToInOut.isFixtureRequestOut ? 'warning' : 'primary' })}
        renderComponent={!selectedRowToInOut.isFixtureRequestOut
          ? (
            <>
              {storageList.length > 1
                ? (
                  <><Typography>Please, select the storage where the fixture will be emptied</Typography>
                    <TextField InputLabelProps={{ shrink: true }} sx={{ minWidth: '100px', marginTop: '20px' }} select label={t_('Storage')} value={rowToInOutStorage} onChange={(event) => { setRowToInOutStorage(event.target.value) }}>
                      {storageList?.map((storage) => (
                        <MenuItem value={storage.id} key={storage.id}>
                          {storage.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                  )
                : null}
            </>)
          : null}
        accept
        cancel
        close={false}
        cancelCallback={() => setEmptyPositionToInOutModule(false)}
        acceptCallback={() => selectedRowToInOut.isFixtureRequestOut ? cancelEmptyPositionToInOut() : emptyPositionToInOut()}
      />
      <SmartDialog
        setOpen={emptyAllDialogOpen} message={t_('Are you sure you want to empty all from this storage?')} title={createDialogTitle({ title: t_('Empty all positions?'), icon: WindowRounded })}
        accept={false} cancelCallback={() => setEmptyAllDialogOpen(false)} close={false} renderCustomButtons={
          <Button style={{ borderColor: theme => theme.palette.primary.main }} onClick={() => { emptyAll() }} variant='outlined'>
            <Typography color='primary'>{t_('EMPTY')}</Typography>
            <WindowRounded color='primary' sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
          </Button>
}
      />
      <SmartDialog
        setOpen={editingData.showWarningResourceInCell === undefined ? false : editingData.showWarningResourceInCell}
        message={t_('The selected resource is in') + ' ' + (editingData.selectedResource && editingData.selectedResource.location ? editingData.selectedResource.location.name : t_('cell')) + t_('. Are you sure you want to move the resource?')}
        accept
        cancel
        close={false}
        cancelCallback={() => onEditingDataChanged({ type: 'CLOSE_WARNING_RESOURCE_IN_CELL' })}
        acceptCallback={() => onEditingDataChanged({ type: 'ACCEPT_FORCE' })}
      />

      {editingData.findFixtureReference
        ? <SmartDialog
            setOpen={editingData.findFixtureReference}
            acceptCallback={() => onAlternateFixtureSelected()} cancelCallback={() => onEditingDataChanged({ type: 'CLOSE' })} close={false}
            renderComponent={<Fixture referenceName={editingData.findFixtureReference?.name} parameter={editingData.findFixtureReference?.id} endpoint='fixturesAvailableByReference' hideColumns onRowClick={(row) => onEditingDataChanged({ type: 'ALTERNATE_FIXTURE_SET', value: row })} />}
            title={
            {
              icon:
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <StorageRounded color='primary' />
            <Typography variant='h5' color='primary' sx={{ marginLeft: '0.3em' }}>{params.storageName}</Typography>
          </Stack>,
              render: <h4>{t_('Select the Fixture to set')}</h4>
            }
          }
          />
        : null}

      {editingData.dialogOpen
        ? <SmartDialog
            setOpen={editingData.dialogOpen}
            acceptCallback={() => handleResourcePopupAcceptButton()} cancelCallback={() => handleFixturesPopupCancelButton()} close={false}
            renderComponent={getResourceSelectTable()}
            title={
            {
              icon:
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <StorageRounded color='primary' />
            <Typography variant='h5' color='primary' sx={{ marginLeft: '0.3em' }}>{params.storageName}</Typography>
          </Stack>,
              render:
          <Stack
            direction='row'
            justifyContent='space-evenly'
            alignItems='center'
          >
            <Typography color='primary' sx={{ marginRight: '0.3em' }}>{t_('SHELF')}:   </Typography>
            <Typography sx={{ marginRight: '1.1em', fontSize: '1.1em' }}> {editingData.row.shelve}</Typography>
            <Typography color='primary' sx={{ marginRight: '0.3em' }}>{t_('ROW')}:</Typography>
            <Typography sx={{ marginRight: '1em', fontSize: '1.1em' }}>{editingData.row.position}</Typography>
            <Typography color='primary' sx={{ marginRight: '0.3em' }}>{t_('COLUMN')}:</Typography>
            <Typography sx={{ marginRight: '0.3em', fontSize: '1.1em' }}>{editingData.row.depth}</Typography>
          </Stack>
            }
}
          />
        : null}
      <SmartDialog
        setOpen={unlockStorageDialogOpen}
        message={t_('Are you sure you want to unlock this storage?')}
        title={createDialogTitle({ title: t_('Unlock storage?'), icon: LockOpenRounded })}
        accept
        cancel
        close={false}
        acceptCallback={async () => {
          const running = await isCellRunning()
          running
            ? setShowCheckDialog(true)
            : unlockStorage()
          setUnlockStorageDialogOpen(false)
        }}
        cancelCallback={() => setUnlockStorageDialogOpen(false)}
      />
      <CheckDialog
        open={showCheckDialog}
        onClose={() => setShowCheckDialog(false)}
        onAccept={() => unlockStorage()}
      />
      {AlertElement}
      <SmartMateriaUITable
        title={smartTableTitle()}
        dataFetch={fetchData}
        sortOptions={sortOptions}
        columns={columns}
        actions={actions}
        toolbar={defaultToolbar}
        forceLoad
        onEditIndexChanged={onEditIndexChanged}
        disableFlexGrow
        tablePageSize={50}
        pageable
        dense
      />
      <div style={{ float: 'right' }}>
        <ButtonBack buttonAction={() => navigate('/process/storage')} />
      </div>
    </div>
  )
}

export default StoragePosition
