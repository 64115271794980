import React, { useEffect, useCallback, useRef, useState } from 'react'
import { Bubble } from 'react-chartjs-2'
import { apiGet, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import ButtonBackSave from '../../generic/buttons/floatingButtons/ButtonBackSave'
import { SmartDialog, SmartSaveDialog } from '../../generic/utilities/SmartDialog'
import { Chart as ChartJS, Tooltip, Legend, LinearScale, CategoryScale, BarElement, PointElement } from 'chart.js'
import {
  TextField, Box,
  Grid,
  Typography
} from '@mui/material'
import { WarningRounded } from '@mui/icons-material'
import createDialogTitle from '../../generic/utilities/dialogUtil'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const shelveConfigurationPositionDoneButtonStyle = {
  textAlign: 'right'
}

const infoShelveStyle = {
  justifyContent: 'space-evenly',
  display: 'flex'
}
const modalTitlePositionDescriptionStyle = {
  borderBottomStyle: 'groove',
  textAlign: 'center',
  marginBottom: '1em',
  marginTop: 75
}
const finalPositionStyle = {
  display: 'flex',
  justifyContent: 'space-evenly',
  marginBottom: '1.25em'
}
const defaultValueAndOffsetPositionStyle = {
  display: 'flex',
  justifyContent: 'space-evenly',
  marginBottom: '1em'
}

const ShelveStoragePositions = () => {
  const [coordinates, setCoordinates] = useState([])
  const [storageLoaded, setStorageLoaded] = useState(false)
  const [positionsDB, setPositionsDB] = useState([])
  const [positions, setPositions] = useState([])
  const [distanceBtwPosX, setDistanceBtwPosX] = useState(0)
  const [storageConfiguration, setStorageConfiguration] = useState([])
  const [storageShelveConfiguration, setStorageShelveConfiguration] = useState({})
  const [storageConfigurationLoaded, setStorageConfigurationLoaded] = useState(false)
  const [distanceBtwPosY, setDistanceBtwPosY] = useState(0)
  const [width, setWidth] = useState(null)
  const [length, setLength] = useState(null)
  const urlParams = useParams()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorValues, setErrorValues] = useState(null)
  const [errorMessageValue, setErrorMessageValue] = useState({
    width: 0,
    length: 0,
    distanceX: 0,
    distanceY: 0
  })
  const [chartLoad, setChartLoad] = useState(false)
  const navigate = useNavigate()
  const backSaveButtonRef = useRef()
  const [showSaveConfirm, setShowSaveConfirm] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const { t_ } = useSmartTranslation()

  const getStorageConfiguration = useCallback(
    (storageConfiguration) => {
      const search = (obj) => obj.id === parseInt(urlParams.shelveId)
      const index = storageConfiguration.findIndex(search)

      if (storageConfiguration[index] !== undefined) {
        setStorageShelveConfiguration(storageConfiguration[index])
        setWidth(storageConfiguration[index].width)
        setLength(storageConfiguration[index].length)
        setErrorMessageValue({ ...errorMessageValue, width: storageConfiguration[index].width, length: storageConfiguration[index].length })
        setDistanceBtwPosX(storageConfiguration[index].distanceX.toFixed(2))
        setDistanceBtwPosY(storageConfiguration[index].distanceY.toFixed(2))
      }
    },
    [urlParams.shelveId, errorMessageValue]
  )

  useEffect(
    () => {
      const handleCoordinates = () => {
        const copyCoordinates = []
        positions.forEach(
          (position) =>
            (copyCoordinates[copyCoordinates.length] = {
              id: position.pseudoId,
              x: (parseFloat(position.coordinateX) + parseFloat(position.offsetX)).toFixed(2),
              y: (parseFloat(position.coordinateY) + parseFloat(position.offsetY)).toFixed(2),
              r: 20,
              locked: position.locked,
              label: [
                t_('Row') + ' ' + position.position + ' ' + t_('Column') + ' ' + position.depth,
                ' X: ' + (parseFloat(position.coordinateX) + parseFloat(position.offsetX)).toFixed(2) + ' Y: ' + (parseFloat(position.coordinateY) + parseFloat(position.offsetY)).toFixed(2)
              ]
            })
        )

        setCoordinates(copyCoordinates)
      }

      if (
        positions.length !== null &&
        positions.length !== 0
      ) {
        handleCoordinates()
        setStorageLoaded(true)
        setChartLoad(false)
      }
    },
    [positions, chartLoad, t_]
  )

  useEffect(() => {
    if (
      storageConfiguration != null &&
      storageConfiguration !== undefined &&
      storageConfiguration.length !== 0 &&
      !storageConfigurationLoaded
    ) {
      setStorageConfigurationLoaded(true)
      getStorageConfiguration(storageConfiguration)
    }

    return () => { }
  }, [
    storageLoaded,
    urlParams,
    positions,
    storageConfiguration,
    storageConfigurationLoaded,
    getStorageConfiguration
  ])

  useEffect(() => {
    const fetchData = async () => {
      await apiGet('shelveStorageConfigurations/' + urlParams.deviceId).then(
        (data) => {
          setStorageConfiguration(data)
        }
      )
    }
    fetchData()
  }, [urlParams, storageLoaded, storageConfigurationLoaded])

  const fetchTableData = async () => {
    const positions = await apiGet(
      'shelveStoragePositions/orderByPositionAndDepth/' + urlParams.shelveId
    )
    positions.forEach((position, index) => (position.pseudoId = index + 1))
    setPositionsDB(positions)
    setPositions(positions)
    return positions
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    BarElement,
    PointElement
  )

  const bubbleData = {
    datasets: [
      {
        label: t_('coordinate'),
        fill: false,
        // lineTension: 0.1,
        backgroundColor: function (context) {
          const index = context.dataIndex
          const value = context.dataset.data[index]
          if (typeof value === 'undefined') {
            return 'rgba(75,192,192,0.4)'
          } else if (value.locked === true) {
            return 'rgba(248,131,121,0.4)'
          } else {
            return 'rgba(75,192,192,0.4)'
          }
        },
        data: coordinates
      }
    ]
  }

  const bubbleOptions = {
    scales: {
      x:
        {
          min: 0,
          max: width
        },
      y:
        {
          min: 0,
          max: length
        }

    },

    legend: {
      display: false
    },
    layout: {
      padding: {
        right: 50,
        top: 25
      }
    },

    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            return t_('Position')
          },
          label: function (tooltipItem, data) {
            return tooltipItem.dataset.data[tooltipItem.dataIndex].label
          }
        }
      }
    }
  }

  const columns = [
    {
      name: '#',
      field: 'pseudoId',
      readOnly: true
    },
    {
      name: t_('Row'),
      field: 'position',
      readOnly: true
    },
    {
      name: t_('Column'),
      field: 'depth',
      readOnly: true
    },
    {
      name: t_('coordinate X'),
      field: 'coordinateX',
      numeric: {
        default: 0
      }
    },
    {
      name: t_('coordinate Y'),
      field: 'coordinateY',
      numeric: {
        default: 0
      }
    },
    {
      name: t_('coordinate Z'),
      field: 'coordinateZ',
      numeric: {
        default: 0
      }
    },
    {
      name: t_('offset X'),
      field: 'offsetX',
      numeric: {
        default: 0
      }
    },
    {
      name: t_('offset Y'),
      field: 'offsetY',
      numeric: {
        default: 0
      }
    },
    {
      name: t_('offset Z'),
      field: 'offsetZ',
      numeric: {
        default: 0
      }
    },
    {
      name: t_('available'),
      field: 'available',
      type: 'boolean'
    },
    {
      name: t_('Function'),
      field: 'storageFunctions.code',
      type: 'smartMultiSelect',
      edit: {
        source: async () => {
          return await apiGet('storageFunctions')
        },
        id: 'id',
        field: 'code'
      }
    }
  ]

  const handleEquitableDistance = (positionsEntry, distancePosX, distancePosY) => {
    let copyPositions = null
    if (positionsEntry === null) {
      copyPositions = [...positionsDB]
    } else {
      copyPositions = positionsEntry
    }
    let error = false
    if (copyPositions !== undefined && copyPositions.length > 0) {
      copyPositions.forEach((position) => {
        position.coordinateX = position.depth * distancePosX
        position.coordinateY = position.position * distancePosY

        if (storageShelveConfiguration.numColumns * distancePosX > width ||
          storageShelveConfiguration.numRows * distancePosY > length) {
          setErrorMessage(
            t_('The shelf positions will be fixed outside of the shelf dimensions, try another distance between positions')
          )
          error = true
          position.coordinateX = position.depth * distanceBtwPosX
          position.coordinateY = position.position * distanceBtwPosY
        }
      })

      if (error) {
        setShowErrorModal(true)
      } else {
        setPositions(copyPositions)
        setChartLoad(true)
      }
    }
    return error
  }

  const handleClickModal = () => {
    setShowErrorModal(false)
    setErrorValues(null)
  }

  const handleChangeEvent = axis => (event) => {
    const copyStorageShelveConfiguration = { ...storageShelveConfiguration }
    let distanceBtwY = distanceBtwPosY
    let distanceBtwX = distanceBtwPosX

    if (axis === 'Y') {
      setDistanceBtwPosY(event.target.value)
      setErrorMessageValue({ ...errorMessageValue, distanceY: event.target.value, distanceX: distanceBtwPosX })
      distanceBtwY = event.target.value
      copyStorageShelveConfiguration.distanceY = distanceBtwY
    } else if (axis === 'X') {
      setDistanceBtwPosX(event.target.value)
      setErrorMessageValue({ ...errorMessageValue, distanceX: event.target.value, distanceY: distanceBtwPosY })
      distanceBtwX = event.target.value
      copyStorageShelveConfiguration.distanceX = distanceBtwX
    }

    setStorageShelveConfiguration(copyStorageShelveConfiguration)
    const error = handleEquitableDistance(positions, distanceBtwX, distanceBtwY)
    if (error) {
      setDistanceBtwPosX(distanceBtwPosX)
      setDistanceBtwPosY(distanceBtwPosY)
    }
  }

  const updatePosition = () => {
    apiPut('shelveStoragePositions', positions)
      .catch((error) => {
        backSaveButtonRef.current.showErrorAlert(error)
      })
    apiPut('shelveStorageConfigurations', storageShelveConfiguration)
      .catch((error) => {
        backSaveButtonRef.current.showErrorAlert(error)
      })
    goBack('EDIT')
  }

  const checkAllRows = () => {
    const rows = positions.map(row => ({
      ...row,
      coordinateX: row.coordinateX === '' ? 0 : row.coordinateX,
      coordinateY: row.coordinateY === '' ? 0 : row.coordinateY,
      coordinateZ: row.coordinateZ === '' ? 0 : row.coordinateZ,
      offsetX: row.offsetX === '' ? 0 : row.offsetX,
      offsetY: row.offsetY === '' ? 0 : row.offsetY,
      offsetZ: row.offsetZ === '' ? 0 : row.offsetZ,
      available: row.available === '' ? false : row.available,
      storageFunctions: row.storageFunctions === '' ? null : row.storageFunctions
    }))

    setPositionsDB(rows)
    setPositions(rows)

    /* Comment dimension checker temporally
      const xCalculated =
        (parseFloat(row.coordinateX) + parseFloat(row.offsetX)).toFixed(2)
      const yCalculated =
        (parseFloat(row.coordinateY) + parseFloat(row.offsetY)).toFixed(2)

      if (
        xCalculated > width ||
        xCalculated < 0 ||
        yCalculated > length ||
        yCalculated < 0
      ) {
        setErrorMessage(
          t_('The position would be out of the shelve, check offset')
        )
        setShowErrorModal(true)
        setErrorValues({
          resultX: !xCalculated ? 0 : xCalculated,
          resultY: !yCalculated ? 0 : yCalculated,
          coordinateX: rows[index].coordinateX,
          coordinateY: rows[index].coordinateY,
          newOffsetX: !row.offsetX ? 0 : row.offsetX,
          newOffsetY: !row.offsetY ? 0 : row.offsetY,
          lastOffsetX: !rows[index].offsetX
            ? 0
            : rows[index].offsetX,
          lastOffsetY: !rows[index].offsetY
            ? 0
            : rows[index].offsetY,
          id: row.pseudoId
        })
      } */
  }

  const savePositions = () => {
    checkAllRows()
    updatePosition()
    setShowSaveConfirm(false)
    setEditMode(false)
  }

  const goBack = (state) => {
    navigate('/configuration/assets/' + urlParams.deviceId, { state })
  }

  const bodyModal = (
    <div>
      <div id='infoShelve' style={infoShelveStyle}>
        <div>{t_('Width')} = {errorMessageValue.width}</div>
        <div>{t_('Length')} = {errorMessageValue.length}</div>
      </div>
      {errorValues !== null
        ? (
          <>
            <Typography
              sx={modalTitlePositionDescriptionStyle}
              variant='h5'
            >
              #{errorValues.id} {t_('Position description')}
            </Typography>
            <div style={defaultValueAndOffsetPositionStyle}>
              <div>
                {t_('Actual position')} ({errorValues.coordinateX},{errorValues.coordinateY})
              </div>
              <div>
                {t_('Old offset')}: ({errorValues.lastOffsetX},{errorValues.lastOffsetY})
              </div>
            </div>
            <div style={finalPositionStyle}>
              <div style={{ display: 'flex' }}>
                {t_('Final position')}: (
                <Typography
                  color={
                  width < errorValues.resultX || errorValues.resultX < 0
                    ? 'error'
                    : 'inherit'
                }
                >
                  {errorValues.resultX}
                </Typography>
                ,
                <Typography
                  color={
                  length < errorValues.resultY || errorValues.resultY < 0
                    ? 'error'
                    : 'inherit'
                }
                >
                  {errorValues.resultY}
                </Typography>
                )
              </div>
              <div>
                {t_('Introduced offset')}: ({errorValues.newOffsetX},
                {errorValues.newOffsetY})
              </div>
            </div>
          </>
          )
        : (
          <div style={defaultValueAndOffsetPositionStyle}>
            <div>´{t_('distance X')}= {errorMessageValue.distanceX}</div>
            <div>{t_('distance Y')}= {errorMessageValue.distanceY}</div>
          </div>
          )}
      <p id='simple-modal-description'>{errorMessage}</p>
    </div>
  )

  return (
    <div>
      <SmartSaveDialog
        show={showSaveConfirm}
        cancelCallback={() => { setShowSaveConfirm(false) }}
        saveCallback={savePositions}
      />
      <SmartDialog
        title={createDialogTitle({ color: 'error', title: t_('SHELVE ERROR'), icon: WarningRounded })}
        renderComponent={bodyModal}
        setOpen={showErrorModal}
        accept={false}
        cancel={false}
        close
        closeCallback={handleClickModal}
      />
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography variant='h5'>
            {t_('SHELVE STORAGE POSITIONS')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Box component='span' display='flex' mb={5}>
            <Typography>{t_('Distance between positions')}</Typography>
          </Box>
          <Box component='span' display='flex' mb={2}>
            <TextField
              label={t_('Distance X:')}
              value={distanceBtwPosX}
              onChange={handleChangeEvent('X')}
            />
          </Box>
          <Box component='span' display='flex' mb={2}>
            <TextField
              label={t_('Distance Y:')}
              value={distanceBtwPosY}
              onChange={handleChangeEvent('Y')}
            />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Bubble options={bubbleOptions} data={bubbleData} />
        </Grid>
        <Grid item xs={12}>
          <SmartMateriaUITable
            columns={columns}
            dataFetch={fetchTableData}
            dense
            // setMultiEdit deletes smart-table-index
            setMultiEdit={editMode === false ? undefined : setPositions}
          />
        </Grid>
        <Grid
          sx={shelveConfigurationPositionDoneButtonStyle}
          item
          xs={12}
        >
          <ButtonBackSave
            editControl={() => (setEditMode(true))}
            backButtonAction={() => goBack('BACK')}
            saveButtonAction={() => setShowSaveConfirm(true)}
            saveDisabled={false}
            ref={backSaveButtonRef}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ShelveStoragePositions
