import React from 'react'
import Iframe from 'react-iframe'
import { useWindowDimensions } from '../generic/hooks/useWindowDimensions'

/* A function that returns a div with an iframe inside. */
const DisplayThirdPartiesSoftware = (props) => {
  const { url } = props
  /* A hook that returns the height and width of the window. */
  const { height } = useWindowDimensions();
  return (
    <div>
      <Iframe
        url={url}
        width="100%"
        height={height - 150}
        display="initial"
        position="relative"
        allowFullScreen
      />
    </div>
  )
}

export default DisplayThirdPartiesSoftware