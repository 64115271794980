import { Box, IconButton } from '@mui/material'
import React, { useState, useRef } from 'react'
import { apiDelete, apiPut, apiGet } from '../../generic/Api_Functions'
import AddElement from '../../generic/smartAddElement/AddElement'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import { Edit } from '@mui/icons-material'
import ToolReferenceParameterValue from './ToolReferenceParameterValue'
import { useNavigate } from 'react-router-dom'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const Tool = (props) => {
  const { row, endpoint, units, hideColumns, onRowClick } = props
  const [editingRow, setEditingRow] = useState(null)
  const { t_ } = useSmartTranslation()
  const [showToolParametersDialog, setShowToolParametersDialog] = useState(false)

  const tool = useRef(null)

  const navigate = useNavigate()

  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      readOnly: true,
      unique: true
    },
    {
      name: t_('Name'),
      field: 'name',
      mandatory: true
    },
    {
      name: t_('Description'),
      field: 'description'
    },
    {
      name: t_('Location'),
      field: 'location',
      readOnly: true,
      hide: hideColumns
    },
    {
      name: 'D',
      field: '',
      edit: false,
      render: (row) => {
        if (row.parameterValueList === undefined) {
          return null
        } else {
          const valueFound = row.parameterValueList.find((parameterValue) => parameterValue.name === 'Diameter')
          return (valueFound !== undefined ? valueFound.value : null)
        }
      }
    },
    {
      name: 'RC',
      field: 'description',
      edit: false,
      render: (row) => {
        if (row.parameterValueList === undefined) {
          return null
        } else {
          const valueFound = row.parameterValueList.find((parameterValue) => parameterValue.name === 'Corner Radius')
          return (valueFound !== undefined ? valueFound.value : null)
        }
      }
    },
    {
      name: 'TL',
      field: 'description',
      edit: false,
      render: (row) => {
        if (row.parameterValueList === undefined) {
          return null
        } else {
          const valueFound = row.parameterValueList.find((parameterValue) => parameterValue.name === 'Total Length')
          return (valueFound !== undefined ? valueFound.value : null)
        }
      }
    },
    {
      name: 'CL',
      field: 'description',
      charLimit: 200,
      edit: false,
      render: (row) => {
        if (row.parameterValueList === undefined) {
          return null
        } else {
          const valueFound = row.parameterValueList.find((parameterValue) => parameterValue.name === 'Cutting Length')
          return (valueFound !== undefined ? valueFound.value : null)
        }
      }
    },
    {
      name: 'Z',
      field: 'description',
      edit: false,
      render: (row) => {
        if (row.parameterValueList === undefined) {
          return null
        } else {
          const valueFound = row.parameterValueList.find((parameterValue) => parameterValue.name === 'Number of Flutes')
          return (valueFound !== undefined ? valueFound.value : null)
        }
      }
    },
    {
      name: t_('Life\n(Current/Total)'),
      field: 'currentLife',
      readOnly: true
    },
    {
      name: t_('Holder'),
      field: 'fixture.code',
      type: 'select',
      edit: {
        source: async () => {
          if (editingRow !== null) { return await apiGet('fixturesAvailableByToolReference/' + editingRow.id) }
        },
        id: 'id',
        field: 'code'
      }
    },
    {
      name: t_('Entry date'),
      field: 'entryDate',
      type: 'dateTimePicker',
      readOnly: false,
      numeric: true,
      mandatory: true
    }
  ]

  const actionsToolInstancesToolParameter = {
    edit: {
      fetchFunction: (editingRow) => {
        editingRow.tool = tool.current
        const temp = { tool: { id: editingRow.tool.id }, value: editingRow.value, unit: editingRow.unit, toolParameter: { id: editingRow.id } }
        return apiPut('toolParameterValues/' + editingRow.tool.id, temp)
      }
    }
  }

  const handleDataVisibleInNextWindow = (row, userPermission) => {
    delete row['smart-table-index']
    delete row.quantity
    row.active = true
    row.userPermision = userPermission

    const index = row.currentLife.indexOf('/')
    if (index !== -1) {
      row.currentLife = row.currentLife.substring(0, index).trim()
    }

    return row
  }

  const actions = {
    delete: {
      fetchFunction: (rows) => {
        let deleteEndpoint = 'tools'
        let params = rows[0].id
        if (rows.length !== 1) {
          deleteEndpoint = 'toolsMulti'
          params = rows.map(row => row.id)
        }
        return apiDelete(deleteEndpoint, params)
      }
    },

    custom: [

      {
        render: (toolInstance) => {
          return (
            <IconButton
              onClick={() => navigate('/resources/toolManager/' + row.id + '/toolInstance/' + toolInstance.id, { state: { row: handleDataVisibleInNextWindow(toolInstance, true) } })}
            >
              <Edit />
            </IconButton>
          )
        }
      }
    ]

  }
  const defaultToolbar = {
    render: {
      fetchFunction: (extraFunctions) => {
        return (
          <Box component='span' sx={{ flexGrow: 1 }} style={{ textAlign: 'end' }}>
            <AddElement
              parameter={row.id}
              endpoint={endpoint}
              referenceName={row.name}
              title={t_('Add tools')}
              onAlert={extraFunctions.showAlert}
            />
          </Box>
        )
      }
    }
  }

  const dataFetch = async () => {
    const tools = await apiGet(endpoint + '/' + row.id)

    return tools
  }

  const onEditIndexChanged = (editIndex, row) => {
    if (editIndex >= 0) {
      tool.current = row
      setEditingRow(row)
    }
  }

  return (
    <div>
      <SmartDialog
        accept={false}
        cancel={false}
        close
        closeCallback={() => setShowToolParametersDialog(false)}
        setOpen={showToolParametersDialog}
        renderComponent={<ToolReferenceParameterValue
          actions={actionsToolInstancesToolParameter}
                         />}
      />
      <SmartMateriaUITable
        columns={columns}
        title={row.name}
        dataFetch={dataFetch}
        actions={actions}
        multipleSelection={!hideColumns}
        dense
        toolbar={defaultToolbar}
        disableFlexGrow
        onEditIndexChanged={onEditIndexChanged}
        onRowClick={(row) => {
          if (onRowClick && typeof onRowClick === 'function' && row.id) {
            onRowClick(row)
          }
        }}
      />
    </div>
  )
}

export default Tool
