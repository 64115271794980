import GlobalConfig from './GlobalConfig'

const baseEndpointUrl = GlobalConfig['back-end_address']

export const apiGet = async (url, body = '') => {
  const result = await apiF(url, body, 'GET').catch((err) => {
    throw err
  })
  return result
}

export const apiGetSync = (url) => {
  const result = apiF(url, '', 'GET').catch((err) => {
    throw err
  })
  return result
}

export const apiPost = async (url, body) => {
  const result = await apiF(url, body, 'POST').catch((err) => {
    throw err
  })

  return result
}

export const apiPut = async (url, body) => {
  const result = await apiF(url, body, 'PUT').catch((err) => {
    throw err
  })
  return result
}

export const apiDelete = async (url, body) => {
  const result = await apiF(url, body, 'DELETE').catch((err) => {
    throw err
  })
  return result
}

export const apiImportFile = async (url, beanParams, file) => {
  const result = await apiF(url, beanParams, 'FILE_IMPORT', file).catch((err) => {
    throw err
  })
  return result
}

export const apiFile = async (url, body, file) => {
  const result = await apiF(url, body, 'FILE', file).catch((err) => {
    throw err
  })
  return result
}

export const getBackendUrl = () => {
  return baseEndpointUrl
}

const apiF = async (url, body, method, file) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  let getOptions = ''

  if (method === 'FILE_IMPORT' || method === 'FILE') {
    const formData = new FormData()
    formData.append('file', file)
    if (method === 'FILE_IMPORT') {
      formData.append('beanDtoName', body.beanDtoName)
      formData.append('beanName', body.beanName)
      formData.append('beanPackage', body.beanPackage)
      formData.append('serviceName', body.serviceName)
      formData.append('servicePackage', body.servicePackage)
    }
    if (method === 'FILE') {
      formData.append('documentName', body.documentName)
      formData.append('documentType', body.documentType.id)
      formData.append('description', body.description)
    }
    options.body = formData
    options.method = 'PUT'
    delete options.headers
  } else if (method !== 'GET') {
    options.body = JSON.stringify(body)
  } else {
    getOptions = body !== '' ? '?' + new URLSearchParams(body) : body
  }
  let result = {}
  try {
    result = await fetch(baseEndpointUrl + url + getOptions, options)
  } catch (exception) {
    const error = {}
    error.status = 500
    error.name = getStatusCodeName(500)
    error.message = exception.message
    throw error
  }

  if (isHttpStatusOk(result.status)) {
    result = await result.json().catch(() => {
      if (method === 'GET') {
        return []
      } else {
        return {}
      }
    })
    return result
  } else {
    const error = {}
    error.status = result.status
    await result.json()
      .then((data) => {
        error.name = getStatusCodeName(error.status)
        error.message = data.message
      })
      .catch(() => {
        if (method === 'GET') {
          return []
        } else {
          return {}
        }
      })

    throw error
  }
}

export const apiDeleteProgram = async (url, body) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  }

  let result = await fetch(baseEndpointUrl + url, options)
  if (result.status === 200) {
    result = await result.json().catch(() => {
      return {}
    })
    return result
  } else {
    throw new Error(result.status)
  }
}

const isHttpStatusOk = statusCode => statusCode >= 200 && statusCode < 300
const getStatusCodeName = statusCode => {
  if (isHttpStatusOk(statusCode)) return 'success'
  if (statusCode >= 300 && statusCode < 400) return 'redirection'
  if (statusCode >= 400 && statusCode < 500) return 'warning'
  if (statusCode >= 500) return 'error'
  return undefined
}
