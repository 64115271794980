import { Button, Grid } from '@mui/material'
import React from 'react'
import { apiPut } from './Api_Functions'

const TestNewDevice = () => {


    const data = {

        status: "",
        sendProgram: "",
        startProgram: ""

    }



    const handleGetStatus = () => {

        apiPut("testNewDevice", data)


    }

    const handleSendProgram = () => {




    }


    const handleStartProgram = () => {




    }







    return (
        <Grid container spacing={2}>

            <Grid item xs={3}>

                <Button
                    onClick={() => handleGetStatus()}
                >GetStatus</Button>
            </Grid>
            <Grid item xs={3}>
                <Button
                    onClick={() => handleSendProgram()}

                >SendProgram</Button>
            </Grid>
            <Grid item xs={3}>
                <Button
                    onClick={() => handleStartProgram()}

                >Start Program</Button>
            </Grid>
        </Grid>
    )
}

export default TestNewDevice