import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import Proptypes from 'prop-types'
import { Box } from '@mui/system'

/**
* SmartAccordion component
* @param {Object} props Props object
* @param {Boolean} props.visible Visibility of the component
* @param {JSX} props.renderComponent Component to render
* @param {Number} props.colSpan Number of columns to span on a cell
* @param {React.CSSProperties} props.tableStyle Style used in table
* @param {React.CSSProperties} props.boxStyle Style used in box
*/
const SmartAccordion = ({
  renderComponent,
  visible,
  colSpan,
  tableStyle = {},
  boxStyle = {}
}) => {
  const defaultStyleTable = {
    paddingLeft: '3em',
    paddingRight: '0',
    paddingTop: '0',
    paddingBottom: '0'
  }

  const defaultStyleBox = {
    width: '100%',
    border: '3px solid #ddd',
    radius: '10px',
    paddingLeft: '0em',
    paddingRigh: '0em',
    paddingTop: '0em',
    paddingBottom: '0em'
  }

  return (
    visible
      ? (
        <TableRow>
          <TableCell colSpan={colSpan} style={{ ...defaultStyleTable, ...tableStyle }}>
            <Box sx={{ ...defaultStyleBox, ...boxStyle }}>
              {renderComponent}
            </Box>
          </TableCell>
        </TableRow>
        )
      : null
  )
}

SmartAccordion.propTypes = {
  visible: Proptypes.bool.isRequired,
  renderComponent: Proptypes.element.isRequired,
  colSpan: Proptypes.number.isRequired,
  tableStyle: Proptypes.object,
  boxStyle: Proptypes.object
}
export default SmartAccordion
