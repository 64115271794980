import React, { useEffect, useRef, useState } from "react";

import { apiGet } from "../../generic/Api_Functions";
import { InputLabel, Select, FormControl } from "@mui/material";
import { Chip, Grid, IconButton, MenuItem, Stack, } from "@mui/material";
import { AddCircleRounded, DeleteRounded } from "@mui/icons-material";
import SensorsActuatorsComponentsFunctionsProtocols from "./SensorsActuatorsComponentsFunctionsProtocols";
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const SensorsActuatorsComponentsFunctions = (props) => {

  const { ioElementSet, setIoElementSet, ioElementSetFunction, addNewFunction, setAddNewFunction, setSaveDisabled } = props;
  const [functions, setFunctions] = useState([])
  const [addIoElementSetFunctionIoElement, setAddIoElementSetFunctionIoElement] = useState(false)
  const oldValue = useRef()
  const { t_ } = useSmartTranslation();

  useEffect(() => {
    const fetchData = async () => {
      let functionsDB = await apiGet("functions/ioElementSetType/" + ioElementSet.ioElementSetType.id);
      setFunctions(functionsDB);
    };

    fetchData()

  }, [ioElementSet]);


  useEffect(() => {
    setSaveDisabled(addIoElementSetFunctionIoElement)
  }, [addIoElementSetFunctionIoElement, setSaveDisabled])


  let funcion = ioElementSetFunction !== undefined ? ioElementSetFunction.function : ""

  const handleValue = (value) => {
    if (functions.length === 0) return ""
    if (value !== undefined) {
      oldValue.current = value
      return value
    }
    if (ioElementSetFunction !== undefined) {
      oldValue.current = ioElementSetFunction.function.code
      return ioElementSetFunction.function.code
    }
    return ""
  }

  const getFunctionsToShow = () => {
    if (ioElementSetFunction === undefined && ioElementSet.ioElementSetFunctions.length !== 0) {
      // * Get all functions and remove used function's for this function
      return functions.filter((element) => !ioElementSet.ioElementSetFunctions.find(compare => compare.function.id === element.id))
    } else {
      let notUsedFunctions = functions.filter((element) => !ioElementSet.ioElementSetFunctions.find(compare => compare.function.id === element.id))
      let functionValue = ioElementSet.ioElementSetFunctions.find(compare => compare.function.code === handleValue())
      if (functionValue !== undefined)
        notUsedFunctions.push(functionValue.function)
      return notUsedFunctions
    }
  }

  const deleteFunction = () => {


    if (funcion === undefined) {
      setAddNewFunction(false)
    }
    let copyIoELementSet = { ...ioElementSet }
    copyIoELementSet.ioElementSetFunctions = ioElementSet.ioElementSetFunctions.filter((ioElementSetFunction) => {

      return (ioElementSetFunction.function.code !== funcion.code)
    })
    setIoElementSet(copyIoELementSet)
  }
  return (
    <>
      <Grid item xs={11}>
        <Grid container sx={{ border: addNewFunction ? "0.5px solid #7EE1A7" : "0.5px solid rgba(255, 255, 255, 0.12)", padding: "2em", borderRadius: "5px" }}  >
          <Grid item xs={12} sx={{ paddingBottom: "2em", borderBottom: "0.5px solid rgba(255, 255, 255, 0.12)" }}>
            <Stack direction="row"
              justifyContent="space-between">
              <Stack direction="row" spacing={4}>
                <FormControl variant="standard" >
                  <InputLabel id="function-label">{t_("Function")}</InputLabel>
                  <Select
                    value={handleValue()}
                    disabled={ioElementSetFunction !== undefined && ioElementSetFunction.function.code !== undefined}
                    onChange={(event) => {
                      handleValue(event.target.value)
                      let ioElementSetFunctionNew = {
                        ioElementSet: {
                          id: ioElementSet.id,
                          code: ioElementSet.code,
                          name: ioElementSet.name,
                        },
                        function: functions.find(functionObject => functionObject.code === event.target.value),
                        ioElementSetFunctionIoElements: []
                      }
                      ioElementSet.ioElementSetFunctions.unshift(ioElementSetFunctionNew)
                      setIoElementSet(ioElementSet)
                      setAddNewFunction(false)
                    }}
                    style={{ minWidth: "6em" }} >
                    {getFunctionsToShow().map((functio) => (
                      <MenuItem value={functio.code} key={functio.code}>
                        {functio.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Chip disabled={ioElementSetFunction === undefined} icon={<AddCircleRounded />} label={t_("Add sensor/actuator")} onClick={() => setAddIoElementSetFunctionIoElement(prev => true)} sx={{ backgroundColor: (theme) => theme.palette.background.chip, margin: "1em" }} />
            </Stack>
          </Grid>

          {
            addIoElementSetFunctionIoElement ?

              <SensorsActuatorsComponentsFunctionsProtocols ioElementSet={ioElementSet} funcion={funcion} setIoElementSet={setIoElementSet} addIoElementSetFunctionIoElement={addIoElementSetFunctionIoElement} setAddIoElementSetFunctionIoElement={setAddIoElementSetFunctionIoElement} setSaveDisabled={setSaveDisabled} />
              : null}
          {
            ioElementSet.ioElementSetFunctions
              .sort((a, b) => a.id - b.id)
              .filter((ioElementSetFunctionH) => ioElementSetFunctionH.function.code === (funcion.code))
              .map((ioElementSetFunction) => (
                ioElementSetFunction.ioElementSetFunctionIoElements
                  .sort((a, b) => a.id - b.id)
                  .map((ioElementSetFunctionIoElement, index) => (
                    < SensorsActuatorsComponentsFunctionsProtocols key={ioElementSetFunctionIoElement.ioElement.code.concat(index)} ioElementSet={ioElementSet} setIoElementSet={setIoElementSet} funcion={ioElementSetFunction.function} ioElementSetFunctionIoElement={ioElementSetFunctionIoElement} setSaveDisabled={setSaveDisabled} />

                  ))))
          }

        </Grid >
      </Grid>
      <Grid item xs={1}>
        <IconButton style={{ color: "white" }} onClick={() => deleteFunction()}>
          <DeleteRounded />
        </IconButton>
      </Grid>
    </>
  )
}

export default SensorsActuatorsComponentsFunctions;
