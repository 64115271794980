import { Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const getLabelText = (label, maxLabelLength) => {
  if (label.length <= maxLabelLength) {
    return label
  }
  return label.substr(0, maxLabelLength) + '...'
}
const RenderLabel = ({ label, maxLabelLength, color, style }) => {
  const defaultStyle = {
    wordBreak: 'break-word',
    marginRight: '0.3em',
    marginLeft: '0.3em',
    fontSize: '0.8rem',
    ...style
  }

  return (
    <Typography
      color={color}
      sx={defaultStyle}
    >
      {getLabelText(label, maxLabelLength)}
    </Typography>
  )
}

const CompassButton = (props) => {
  const {
    onClick,
    color = 'primary',
    opacity = 1,
    label,
    icon,
    disabled = false,
    iconPosition = 'left',
    maxLabelLength = 20,
    width = '100%',
    height = '100%',
    labelStyle,
    backgroundColor = theme => theme.palette.background.button,
    sx = {},
    isSelected = false,
    isSelectedColor = 'primary'
  } = props

  const buttonStyle = {
    ...sx,
    color: (theme) => theme.palette[color].main,
    backgroundColor,
    border: isSelected ? '4px solid' : '1px solid',
    borderColor: isSelected && isSelectedColor ? (theme) => theme.palette[isSelectedColor].main : (theme) => theme.palette[color].main,
    '&:hover': {
      backgroundColor: theme => theme.palette.background.buttonHover
    },
    minWidth: `${width}`,
    maxWidth: `${width}`,
    minHeight: `${height}`,
    maxHeight: `${height}`,
    borderRadius: '1.3rem',
    opacity
  }

  const buttonLabelStyle = {
    ...labelStyle,
    fontWeight: isSelected ? 'bold' : ''
  }

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant='extended'
      color={isSelected && isSelectedColor ? isSelectedColor : color}
      sx={{ ...buttonStyle }}
    >
      {iconPosition === 'left' && icon}
      {label ? typeof label === 'string' ? <RenderLabel style={buttonLabelStyle} label={label} color={isSelected && isSelectedColor ? isSelectedColor : color} maxLabelLength={maxLabelLength} /> : label : null}
      {iconPosition === 'right' && icon}
    </Button>

  )
}

CompassButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.any.isRequired,
  opacity: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.any,
  disabled: PropTypes.bool,
  iconPosition: PropTypes.string,
  maxLabelLength: PropTypes.number,
  width: PropTypes.any,
  height: PropTypes.any,
  labelStyle: PropTypes.any,
  backgroundColor: PropTypes.any,
  isSelected: PropTypes.bool,
  isSelectedColor: PropTypes.string
}

export default CompassButton
