import { useState } from 'react'

/**
 *  @typedef {import('../../utilities/objectStructures/globalStructures').SmartAccordionVisibility} SmartAccordionVisibility SmartAccordionVisibility object
 */

const useToggleSmartAccordionVisibility = () => {
  const [smartAccordionVisibility, setSmartAccordionVisibility] = useState([])

  /**
   * Get the visibility of a indicated id value
   * @param {number} id id number to search
   * @returns
   */
  const getSmartAccordionVisibility = (id) => {
    const visibility = smartAccordionVisibility.find((item) => item.id === id)
    if (visibility === null || visibility === undefined) {
      setSmartAccordionVisibility((prevVisibility) => [
        ...prevVisibility,
        { id, visible: false, addMode: false }
      ])
      return false
    }
    return visibility.visible
  }

  /**
   * Get the addMode of a indicated id value
   * @param {number} id id number to search
   * @returns
   */
  const getSmartAccordionAddMode = (id) => {
    const addMode = smartAccordionVisibility.find((item) => item.id === id)
    if (addMode === null || addMode === undefined) {
      setSmartAccordionVisibility((prevVisibility) => [
        ...prevVisibility,
        { id, visible: false, addMode: false }
      ])
      return false
    }
    return addMode.addMode
  }

  /**
   * Toggle the visibility of a indicated id value
   * @param {number} id id number to search
   */
  const toggleVisibility = (id) => {
    setSmartAccordionVisibility((prevVisibility) =>
      prevVisibility.map((item) => {
        if (item.id === id) {
          return { ...item, visible: !item.visible, addMode: false }
        }
        return item
      })
    )
  }

  /**
   * Set accordion of a indicated id value
   * @param {number} id id number to search
   * @param {Boolean} visibility visibility value
   * @param {Boolean} newAddMode add mode value
   */
  const setAccordion = (id, visibility, newAddMode) => {
    setSmartAccordionVisibility((prevVisibility) =>
      prevVisibility.map((item) => {
        if (item.id === id) {
          return { ...item, visible: visibility, addMode: newAddMode }
        }
        return item
      })
    )
  }

  return { getSmartAccordionVisibility, getSmartAccordionAddMode, toggleVisibility, setAccordion }
}

export default useToggleSmartAccordionVisibility
