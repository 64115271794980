import Checkbox from '@mui/material/Checkbox'
import { Card, Stack } from '@mui/material'

const SmartPaletteItem = ({ label, dragProperties, disabled, onClick, selected, checked, onCheckedChange }) => {
  const onDragStart = (event, dragProperties) => {
    for (const [key, value] of Object.entries(dragProperties)) {
      event.dataTransfer.setData(key, value)
    }
    event.dataTransfer.effectAllowed = 'move'
  }

  const draggable = !disabled && dragProperties !== false
  const showCheckbox = checked !== undefined

  return (
    <Card
      disabled={disabled}
      onClick={() => { if (!disabled) { onClick() } }}
      title={label}
      sx={
        {
          paddingTop: showCheckbox ? 0 : '0.5rem',
          paddingBottom: showCheckbox ? 0 : '0.5rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'block',

          cursor: draggable ? 'grab' : onClick ? 'pointer' : 'initial',
          opacity: disabled ? 0.5 : 1,
          color: (theme) => selected ? theme.palette.primary.main : null,
          border: '2px solid',
          borderColor: (theme) => selected ? theme.palette.primary.main : 'transparent'

        }
}
      draggable={draggable}
      onDragStart={(event) => onDragStart(event, dragProperties)}
    >
      <Stack direction='row' alignItems='center'>
        {showCheckbox
          ? <Checkbox
              disabled={disabled}
              checked={checked}
              onChange={(e) => onCheckedChange(e.target.checked)}
              onClick={(e) => e.stopPropagation()}
            />
          : null}
        <div style={{ flexGrow: 1, textAlign: 'center' }}>{label}</div>
      </Stack>

    </Card>
  )
}

export default SmartPaletteItem
