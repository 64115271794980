import React from "react";
import { apiGet } from "../../generic/Api_Functions";
import SmartMateriaUITable from "../../generic/SmartMateriaUITable";
import GlobalConfig from "../../generic/GlobalConfig";
import { IconButton } from "@mui/material";
import Ok from "../../../images/OK.png";
import NoOk from "../../../images/NO_OK.png";
import { PictureAsPdfRounded } from "@mui/icons-material";
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const endpointUrl = GlobalConfig["back-end_address"] + "inspectionReports/";

const imgStyle = {
  verticalAlign: "middle",
  height: "1.8rem",
  width: "1.8rem",
}

const Inspection = () => {
  const { t_ } = useSmartTranslation();

  const columns = [
    {
      name: t_("Part Code"),
      field: "partCode"
    },
    {
      name: t_("Part Reference"),
      field: "partReferenceName"
    },
    {
      name: t_("Name"),
      field: "reportName"
    },
    {
      name: t_("Asset"),
      field: "deviceName"
    },
    {
      name: t_("Date"),
      field: "reportDate",
      type: "datetime"
    },
    {
      name: t_("Result"),
      field: "inspectionResult",
      render: (rowData) => (
        <img
          style={imgStyle}
          src={rowData.inspectionResult === "OK" ? Ok : NoOk}
          alt=""
        />
      ),
    },
  ];

  const actions = {
    custom: [
      {
        name: "DownloadPDF",
        render: (inspection) => (
          <IconButton
            onClick={() => {
              var a = document.createElement("a");
              a.href = endpointUrl + inspection.id;
              a.setAttribute("target", "_blank")
              a.click();
            }}
          >
            <PictureAsPdfRounded />
          </IconButton>
        ),
      },
    ],
  };

  const sortOptions = [{
    field: 'reportDate',
    direction: -1
  }]

  const fetchFunction = async (pagination) => {
    return await apiGet("inspectionReports", pagination);
  };

  const title = t_("Inspection");

  return (
    <SmartMateriaUITable
      columns={columns}
      title={title}
      dataFetch={fetchFunction}
      actions={actions}
      pageable={true}
      sortOptions={sortOptions}
    />
  );
};

export default Inspection;
