import { Stack } from "@mui/system";
import { FormControl, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { apiGet, apiPost } from '../../generic/Api_Functions';
import SmartDialog from '../../generic/utilities/SmartDialog/SmartDialog';
import AlertUI from "../../generic/AlertUI";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const UnloadActionDialog = (props) => {

  const { action, open, setOpen, onDone } = props;
  const [AlertElement, showAlert] = AlertUI();
  const [unloadAction, setUnloadAction] = useState(null);
  const { t_ } = useSmartTranslation();

  useEffect(() => {

    const loadData = async () => {
      // Find all storage positions that are in/out and are free
      let response = await apiGet('actionUnloadByActionId/' + action.id);
      setUnloadAction(response)
    }

    if (open) {
      // Reset variables
      loadData();
    }
  }, [action, open]);

  const titleDialogOF = {
    icon: <MoveUpIcon fontSize='large' color={"info"} />,
    render: t_("Empty position?")
  }

  const onAccept = async () => {
    await apiPost("actionUnloadSetDoneByActionId/" + action.id).then(() => {
      setOpen(false);
      onDone();
    }).catch(async (error) => {
      showAlert({
        title: "",
        severity: "error",
        message: t_(error.message),
      });
    }
    );

  }

  return (

    <SmartDialog
      message=""
      setOpen={open}
      title={titleDialogOF}
      accept={true}
      acceptCallback={onAccept}
      cancel={true}
      close={false}
      cancelCallback={() => setOpen(false)}
      renderComponent={
        <Stack gap={3}>
          {AlertElement}
          <Stack direction="row" gap={3} justifyContent="space-between" alignItems="flex-end">
            <FormControl sx={{ minWidth: "10rem" }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                value={unloadAction ? unloadAction.device : ""}
                label={t_("Storage")}
                disabled={true}
              />
            </FormControl>

            <FormControl sx={{ maxWidth: "6rem" }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                value={unloadAction ? unloadAction.shelve : ""}
                label={t_("Shelf")}
                disabled={true}
              />
            </FormControl>

            <FormControl sx={{ maxWidth: "6rem" }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                value={unloadAction ? unloadAction.row : ""}
                label={t_("Row")}
                disabled={true}
              />
            </FormControl>

            <FormControl sx={{ maxWidth: "6rem" }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                value={unloadAction ? unloadAction.column : ""}
                label={t_("Column")}
                disabled={true}
              />
            </FormControl>


            <FormControl sx={{ maxWidth: "6rem" }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                value="Empty"
                label={t_("Column")}
                disabled={true}
              />
            </FormControl>
          </Stack>
        </Stack>}

    />
  );
}

export default UnloadActionDialog;