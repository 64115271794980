import ERROR from "./../../../images/NO_OK.png";
import WARNING from "./../../../images/Warning.png";
import OK from "./../../../images/OK.png";
import PropTypes from "prop-types";

/**
 * ChecklistIndicator component
 * @param {Object} props 
 * @param {'OK'|'WARNING'|'ERROR'} props.status 
 * @returns 
 */
const ChecklistIndicator = ({ status }) => {
  let src = null;
  if (status === "OK") {
    src = OK;
  } else if (status === "WARNING") {
    src = WARNING;
  } else {
    src = ERROR;
  }
  return <img src={src} alt={status} style={{ width: "1.6rem" }} />
};

ChecklistIndicator.propTypes = {
  /**
   * Status to set to the indicator
   */
  status: PropTypes.oneOf(['OK', 'WARNING', 'ERROR']).isRequired,
}

export default ChecklistIndicator;