import { InputAdornment, MenuItem, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import PropTypes from 'prop-types';

/**
 * This component will create a textbox with a passed unit on the right side. If the unitOptions param is set, the unit will be selectable bewtween the available options
 */
const TextFieldWithUnit = (props) => {
  const {
    name,
    onValueChange,
    type = "number",
    value,
    disabled = false,
    unitValue = null,
    unitOptions = [],
    onUnitValueChange,
    onParameterErrorStateChange
  } = props
  const [errorTextFieldValue, setErrorTextFieldValue] = useState(false)

  useEffect(() => {
    if ((value !== null && value !== "") && (unitValue === null || unitValue === "")) {
      setErrorTextFieldValue(true)
    }
    else {
      setErrorTextFieldValue(false)
    }
  }, [value, unitValue])

  useEffect(() => {
    if (typeof onParameterErrorStateChange === "function") {
      onParameterErrorStateChange(errorTextFieldValue, name)
    }
  }, [errorTextFieldValue, onParameterErrorStateChange, name])

  return (
    <TextField
      label={name}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(event) => onValueChange(event.target.value, name)}
      type={type}
      value={value === null ? "" : value}
      disabled={disabled}
      error={errorTextFieldValue}
      InputProps={{
        endAdornment:
          < InputAdornment position="end">
            <TextField variant={'standard'} disabled={disabled}
              select={unitOptions.length > 0 ? true : false} value={unitValue === null ? "" : unitValue} InputProps={{ disableUnderline: true }} onChange={(event) => onUnitValueChange(event.target.value, name)}>
              {
                unitOptions.length > 0 ?
                  unitOptions.map(unitOption => (
                    <MenuItem key={"unit-option-" + unitOption.code} value={unitOption.code}>
                      {unitOption.code}
                    </MenuItem>
                  )) : null}
            </TextField></InputAdornment>
      }}
    >
    </TextField >
  )
}

TextFieldWithUnit.propTypes = {
  /**
   * Parameter that will be set as a label parameter on the component.'
   */
  name: PropTypes.string.isRequired,
  /**
   * Value that will be displayed on the component.'
   */
  value: PropTypes.string,
  /**
   * Function that will be triggered after changing component value
   */
  onValueChange: PropTypes.func.isRequired,
  /**
   * The components type. By default will be 'number'
   */
  type: PropTypes.string,
  /**
   * Parameter that will be used to set if the comment is disabled or not. By default will be 'false'
   */
  disabled: PropTypes.bool,
  /**
   * Unit value that will be displayed
   */
  unitValue: PropTypes.string,
  /**
   * Function that will be triggered after changing unit.
   */
  onUnitValueChange: PropTypes.func,
  /**
   * Array that will contain all available units. The object will have at least 'id' and 'code' fields
   */
  unitOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  /**
   * Function that will be triggered after changing error state.
  */
  isInErrorState: PropTypes.func,
}

export default TextFieldWithUnit



