import React from "react";
import { apiDelete, apiGet, apiPost, apiPut } from "../../generic/Api_Functions";
import SmartMateriaUITable from "../../generic/SmartMateriaUITable";
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const TechnologyGroup = () => {

  const { t_ } = useSmartTranslation();

  const columns = [{
    name: t_("Code"),
    field: "code",
    charLimit: 60,
    mandatory: true,
    unique: true,
  },
  {
    name: t_("Name"),
    field: "name",
    charLimit: 80,
    mandatory: true,
    unique: true,
  },
  {
    name: t_("Description"),
    field: "description",
    charLimit: 200,
  },
  {
    name: t_("Technologies"),
    field: "technologies.name",
    type: "smartMultiSelect",
    edit: {
      source: async () => {
        return await apiGet("technologiesBasicData");
      },
      id: "id",
      field: "name",
    },
    mandatory: true,
  },
  ]

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut("technologyGroup", row)
      },

    },
    create: {
      fetchFunction: (row) => {
        return apiPost("technologyGroup", row)
      },
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete("technologyGroup/" + rows[0].id, rows)
        }
        else {
          return apiDelete("technologiesGroupsMulti", rows)
        }
      },
    },
  }

  const fetchFunction = async (pagination) => {
    let technologies = await apiGet('technologyGroups', pagination)
    return technologies
  }
  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_("Technology Groups")}
      dataFetch={fetchFunction}
      actions={actions}
      multipleSelection
      multipleOptions={true}
      pageable={true}
      validator={(row) => {
        if (row.technologies.length < 2) {
          let errorFields = ["technologies.name"];
          errorFields.alertMessage = t_("At least two technologies must be selected");
          return errorFields;
        }
        return false;
      }}
    />
  );
}

export default TechnologyGroup;