import { Stack } from "@mui/system";
import React, { useState, useEffect } from "react";

const clockStyle = {
  color: "#FFF",
  fontSize: "18px",
}


export const ClockDisplay = () => {
  var [date, setDate] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <Stack direction="column" alignItems="center" sx={clockStyle}>
      <div> {date.toLocaleTimeString()} </div>
      <div>  {date.toLocaleDateString()}</div>
    </Stack>
  );
};

export default ClockDisplay;
