import {
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableFooter,
  TableSortLabel,
  Box,
  FormHelperText,
  Checkbox,
  InputLabel,
  Stack,
  CircularProgress,
  Tooltip
} from '@mui/material'
import {
  Close,
  Edit,
  Save,
  Search,
  LibraryAddCheck,
  CheckBox,
  DeleteOutlineRounded,
  ArrowUpwardOutlined,
  ArrowDownwardOutlined,
  Done
} from '@mui/icons-material'
import WarningIcon from '@mui/icons-material/Warning'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { apiGet } from './Api_Functions'
import AlertUI from './AlertUI'
import { SmartDialog, SmartDeleteDialog } from './utilities/SmartDialog'
import { GetDate, GetDateTime, GetTime } from './DateTimeFunctions'
import SmartMaterialUIToolbar from './SmartMateriaUIToolbar'
import { useSmartInterval } from './hooks/useSmartInterval'
import SmartMateriaUITablePagination from './SmartMaterialUITablePagination'
import moment from 'moment/moment.js'
import ParameterFilters from './SmartMaterialUITableParameterFilters'
import { usePagination } from '../generic/hooks/usePagination'
import { usePaginationMultiSelection } from './hooks/usePaginationMultiSelection'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import createDialogTitle from '../generic/utilities/dialogUtil'
import SmartSelect from './smartSelect/SmartSelect'
import { useTheme } from '@emotion/react'
import { SmartAccordion } from './smartAccordion'
import useToggleSmartAccordionVisibility from './hooks/useToggleSmartAccordionVisibility/useToggleSmartAccordionVisibility'
import { DateTimePicker } from '@mui/x-date-pickers'
const SmartTablePaper = (props) => (
  <Paper
    sx={{
      background: theme => theme.palette.background.color_373636
    }}
  >
    {props.children}
  </Paper>
)

const SmartTableRow = (props) => {
  return (
    <TableRow
      sx={{
        backgroundColor: props.selected ? theme => theme.palette.background.table_row_hover : props.row ? theme => theme.palette.background.color_373636 : theme => theme.palette.background.row2,
        '&:hover': {
          backgroundColor: theme => theme.palette.background.table_row_hover
        }
      }}
      onClick={props.onClick}
    >
      {props.children}
    </TableRow>
  )
}

const SmartTableHead = (props) => (
  <TableCell
    padding={props.padding}
    sx={{
      fontSize: 15,
      borderBottom: theme => `1px solid ${theme.palette.primary.main}99`,
      borderTop: theme => `1px solid ${theme.palette.primary.main}99`,
      backgroundColor: theme => `${theme.palette.background.color_212121}95`,
      verticalAlign: 'baseline'
    }}
  >
    {props.children}
  </TableCell>
)

const tableCellStyle = {
  borderBottom: '0px !important',
  verticalAlign: 'center !important'

}
const tableSearchStyle = {
  '& input::placeholder': {
    fontSize: '13px !important'
  },
  '& .MuiInput-underline:before': {
    borderBottom: '0px !important'
  },
  fontSize: '14px !important'
}
const tableSearchIconStyle = {
  fontSize: '18px !important'

}
const textFieldUnderlineStyle = {
  '& .MuiInputBase-input': {
    color: '#979797 !important' // Text color

  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'transparent' // Semi-transparent underline
  },
  '& .MuiInput-underline:hover:before': {
    borderBottomColor: 'transparent' // Solid underline on hover
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent' // Solid underline on focus
  }
}
const textFieldStyle = {
  '&.Mui-disabled:after': {
    borderBottom: '1px dotted red',
    transform: 'scaleX(0)'
  },
  '& .MuiInput-underline.readonly': {
    borderBottomColor: 'transparent' // Solid underline on focus
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '13px'
  }
}

const smartTableIndexKey = 'smart-table-index'
const rowHasTableIndexDefined = (row) => {
  return row && row !== undefined && row !== null && row[smartTableIndexKey] !== undefined && row[smartTableIndexKey] !== null
}

const primaryKeysDefault = ['id']
const extraFilterParameterDefault = { name: 'systemDefault', value: true }
const SmartMateriaUITable = (props) => {
  const {
    columns,
    title,
    dataFetch,
    actions,
    onRowClick,
    sortOptions,
    dense,
    multipleSelection,
    searchAndPaginationVisible,
    searchDisplay,
    toolbarDisplay,
    toolbar,
    multipleSelectionActions,
    deleteMultipleSelectionDisabled,
    disableFlexGrow,
    fetchInterval = 1,
    editingRow,
    resetMultipleSelectionValue,
    forceLoad,
    pageable = false,
    setDisableRowActions,
    onAddElementClick,
    tablePageSize = 10,
    primaryKeys = primaryKeysDefault,
    pagination,
    importFile,
    addModeByDefault,
    validator,
    multipleSelectionChange,
    handleRowCreatedInOtherSmartTable,
    rowCreatedInOtherSmartTable,
    setRowCreatedInOtherSmartTable,
    multipleOptions,
    addButtonVisible,
    disableSave,
    forceDisableSaveButton,
    onEditIndexChanged,
    resetPage,
    setMultiEdit,
    filters,
    onFilterToggleClick,
    extraFilterParameter = extraFilterParameterDefault,
    sticky = true,
    resetSelectedRow
  } = props
  const accordionVisibility = useToggleSmartAccordionVisibility()
  const [filterText, setFilterText] = useState('')
  const columnFilters = useRef({})
  const [data, setData] = useState([])
  const [pageableObject, setPageableObject] = useState({ number: 0, size: tablePageSize })
  const [editIndex, setEditIndex] = useState(-2)
  const [selectedRow, setSelectedRow] = useState(-1)
  const [selectedItem, setSelectedItem] = useState(null)
  const [columnSelectOptions, setColumnSelectOptions] = useState([])
  const [page, setPage] = useState(0) // page initialize
  const [rowsPerPage, setRowsPerPage] = useState(tablePageSize) // number of rows initialize
  const [sortColumnField, setSortColumnField] = useState('')
  const [sortDirection, setSortDirection] = useState(1)
  const [sortColumnFieldPageable, setSortColumnFieldPageable] = useState('')
  const [notSavedEditingRow, setNotSavedEditingRow] = useState({})
  const [AlertElement, showAlert] = AlertUI()
  const [multipleSelectionValue, setMultipleSelectionValue] = useState({
    rows: [],
    allSelected: false
  })
  const [loadTable, setLoadTable] = useState(false)
  const [loadTableColumn, setLoadTableColumn] = useState(false)
  const [
    switchBtwDataPageAndAllDataCheckbox,
    setSwitchBtwDataPageAndAllDataCheckbox
  ] = useState(false)
  const multipleSelectionChangeRef = useRef(multipleSelectionChange)

  useEffect(() => {
    multipleSelectionChangeRef.current = multipleSelectionChange
  }, [multipleSelectionChange])

  const [reloadAutocompleteOptions, setReloadAutocompleteOptions] = useState(false)

  const [firstLoad, setFirstLoad] = useState()
  const [dataForMultipleEdit, setDataForMultipleEdit] = useState([])

  const [messageToolbarMultipleSelection, setMessageToolbarMultipleSelection] =
    useState('')
  const [totalElements, setTotalElements] = useState(0)
  const requestPagination = usePagination(filters, pagination, pageableObject, columns, primaryKeys, sortColumnFieldPageable, sortOptions, filterText, columnFilters.current)
  const [firstLoadRequestPagination, setFirstLoadRequestPagination] = useState(true)
  const requestPaginationMultiSelection = usePaginationMultiSelection(requestPagination, multipleSelectionValue, extraFilterParameter)
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [currentSelectedRow, setCurrentSelectedRow] = useState({})
  const { t_ } = useSmartTranslation()
  const theme = useTheme()
  const requestPaginationRef = useRef(requestPagination)

  useEffect(() => {
    requestPaginationRef.current = requestPagination
  }, [requestPagination])

  useEffect(() => {
    setSelectedRow(-1)
  }, [resetSelectedRow])

  useEffect(() => {
    setPage(0)
  }, [resetPage])

  const handleColumnsErrors = useCallback(() => {
    const arrayColumns = []
    columns.forEach((column) => {
      arrayColumns[arrayColumns.length] = {
        field: column.field,
        error: false,
        errorLabel: '',
        label: ''
      }
    })
    return arrayColumns
  }, [columns])

  const handleSelectValue = (row, column) => {
    return (columnSelectOptions[column.name] &&
      columnSelectOptions[column.name]
        .data &&
      getObjectPropertyFromString(
        row,
        column.field
      ) !== undefined
      ? getObjectPropertyFromString(
        row,
        column.field
      )
      : ''
    )
  }

  const [errorTextfield, setErrorTextfield] = useState(handleColumnsErrors)
  const columnFilterChanged = useRef([])

  const createNewElement = () => {
    const newElement = {}
    columns.forEach((column) => {
      const sp = column.field.split('.')
      let def = ''
      if (sp.length > 1) {
        newElement[sp[0]] = {}
        def = {}
      }
      if (column.defaultNewValue !== undefined) {
        newElement[sp[0]] = column.defaultNewValue
      } else {
        newElement[sp[0]] = def
      }
    })
    newElement[smartTableIndexKey] = -1
    return newElement
  }

  const getIntervalTableData = async () => {
    let dbData = []
    if (pageable && Object.keys(requestPagination).length === 0) {
      return []
    }
    if (typeof dataFetch === 'function') {
      dbData = await dataFetch(requestPagination)
    } else if (typeof dataFetch === 'string') {
      dbData = await apiGet(dataFetch, pageable ? requestPagination : null)
    } else if (Array.isArray(dataFetch)) {
      dbData = [...dataFetch]
    }
    if (dbData === undefined) {
      setFirstLoad(true)
      return []
    }
    if (pageable) {
      const copyContent = { ...dbData }
      if (copyContent.content !== undefined) {
        dbData = copyContent.content
      }
      delete copyContent.content
      // Check if the request is not from an old page
      if (requestPagination.page === requestPaginationRef.current.page) {
        setPageableObject(copyContent)
      }
    }

    if (multipleSelectionValue.allSelected) {
      if (totalElements !== pageableObject.totalElements) {
        multipleSelectionValue.rows = []
      }
    }

    setTotalElements(pageableObject.totalElements)
    setFirstLoad(true)
    if (!firstLoad) {
      setDataForMultipleEdit(dbData)
    }
    return dbData.map((row, index) => {
      return { ...row, [smartTableIndexKey]: index }
    })
  }

  const addModeByDefaultSetDone = useRef(false)

  const updateSmartInterval = (intervalData) => {
    // Check if the request is not from an old page
    if (pageable && requestPagination.page !== requestPaginationRef.current.page) {
      return
    }

    // Ignore updates if we are adding a new row (SmartInterval is cancelled but it can call the method if it was allready running)
    if (editIndex !== -1) {
      if (!firstLoad) {
        setData((prev) => {
          const addElement = prev.find(item => item[smartTableIndexKey] === -1)
          if (addElement === undefined) {
            return intervalData
          }
          return [addElement, ...intervalData]
        })
      } else {
        setData(intervalData)
      }

      // On first load set in create new element mode if required
      if (!addModeByDefaultSetDone.current) {
        if (addModeByDefault) {
          onAddNewElementClick()
        }
        addModeByDefaultSetDone.current = true
      }
    }
  }

  const checkSelectedItemInData = (indexedData) => {
    getTableDataFiltered(indexedData).every((item, index) => {
      if (checkIsSelectedItem(item)) {
        const newPage = Math.floor(index / rowsPerPage)
        const selectedRowIndex = index % rowsPerPage
        setPage(newPage)
        setSelectedRow(selectedRowIndex)
        return false
      }
      return true
    })
  }

  const checkIsSelectedItem = (item) => {
    let found = true
    columns.every(column => {
      const selectedItemValue = getObjectPropertyFromString(selectedItem, column.field)
      const itemValue = getObjectPropertyFromString(item, column.field)
      if (selectedItemValue !== itemValue) {
        found = false
        return false
      }
      return true
    })

    return found
  }

  const reduceRowPropertiesToDesired = (keys, row) => {
    const copyRowReduced = {}
    keys.forEach(name => {
      if (row[name] !== undefined) { copyRowReduced[name] = row[name].toString() }
    })
    return copyRowReduced
  }

  const handleOnChange = (row) => {
    let copyMultipleSelectionValue = multipleSelectionValue
    const copyRow = reduceRowPropertiesToDesired(primaryKeys, row)

    const findRowOnMultipleSelectionValue = copyMultipleSelectionValue.rows.find(row => JSON.stringify(row) === JSON.stringify(copyRow))

    if (findRowOnMultipleSelectionValue === undefined) {
      copyMultipleSelectionValue = { ...copyMultipleSelectionValue, rows: [...copyMultipleSelectionValue.rows, copyRow] }
    } else {
      copyMultipleSelectionValue = { ...copyMultipleSelectionValue, rows: copyMultipleSelectionValue.rows.filter(row => JSON.stringify(row) !== JSON.stringify(copyRow)) }
    }

    setNewMultipleSelectionValue(copyMultipleSelectionValue)
  }

  const setNewMultipleSelectionValue = (multipleSelectionValue) => {
    setMultipleSelectionValue(multipleSelectionValue)
    if (multipleSelectionChangeRef.current !== undefined && multipleSelectionChangeRef.current !== null && typeof multipleSelectionChangeRef.current === 'function') { multipleSelectionChangeRef.current(multipleSelectionValue) }
  }

  const resetMultiSelectionValue = () => {
    const multiSelection = {
      rows: [],
      allSelected: false
    }
    setMultipleSelectionValue(multiSelection)
    if (multipleSelectionChangeRef.current !== undefined && multipleSelectionChangeRef.current !== null && typeof multipleSelectionChangeRef.current === 'function') { multipleSelectionChangeRef.current(multiSelection) }
  }

  const startTableInterval = useSmartInterval(getIntervalTableData, fetchInterval * 1000, updateSmartInterval)

  const onAddNewElementClick = () => {
    setEditIndex(-1)
    if (onEditIndexChanged) {
      onEditIndexChanged(-1)
    }
    const newElement = createNewElement()
    if (onAddElementClick && typeof onAddElementClick === 'function') onAddElementClick(newElement)
    setData(prevData => [newElement, ...prevData])
    startTableInterval(false)
  }

  const getIntervalColumnSelectOptions = async () => {
    const options = []
    const errors = []
    for (const column of columns) {
      if (
        column.edit &&
        column.type &&
        (column.type === 'select' || column.type === 'select-plain' || column.type === 'smartMultiSelect' || column.type === 'smartSelect') &&
        typeof column.edit.source === 'function'
      ) {
        const dataOptions = await column.edit.source().catch((error) => {
          errors.push(error)
        })
        options[column.name] = {
          id: column.edit.id,
          field: column.edit.field,
          data: dataOptions
        }
      }
    }
    return { options, errors }
  }

  const setIntervalColumnSelectOptionsData = (columnData) => {
    if (columnData.errors.length > 0) {
      showAlert({ message: columnData.errors[0].message, severity: columnData.errors[0].name })
    }
    setColumnSelectOptions(columnData.options)
  }

  useEffect(() => {
    if (!pageable) {
      console.warn('%cPageable in frontend is deprecated  🚙by Kristina ', 'color:blue; font-size:14px')
    }
  }, [pageable])

  const startColumnSelectOptionsInterval = useSmartInterval(getIntervalColumnSelectOptions, fetchInterval * 1000, setIntervalColumnSelectOptionsData, false)

  const startTableIntervalRef = useRef()
  const startColumnTableIntervalRef = useRef()

  useEffect(() => {
    const getColumnsData = (editIndex > -2)
    if (typeof startTableIntervalRef.current === 'function') {
      startTableIntervalRef.current(!getColumnsData)
    }
    if (typeof startColumnTableIntervalRef.current === 'function') {
      startColumnTableIntervalRef.current(getColumnsData, true)
    }
  }, [editIndex])

  useEffect(() => {
    startTableIntervalRef.current = startTableInterval
  }, [startTableInterval])

  useEffect(() => {
    startColumnTableIntervalRef.current = startColumnSelectOptionsInterval
  }, [startColumnSelectOptionsInterval])

  useEffect(() => {
    startTableIntervalRef.current(true, true)
  }, [forceLoad, loadTable])

  useEffect(() => {
    startColumnTableIntervalRef.current(true, true)
  }, [forceLoad, loadTable, loadTableColumn])

  useEffect(() => {
    if (setMultiEdit !== undefined) {
      startTableInterval(false)
    }

    return () => {

    }
  }, [setMultiEdit, startTableInterval])
  useEffect(() => {
    if (!pageable) {
      if (sortColumnField === '') {
        if (sortOptions && sortOptions.default && sortOptions.default.field) {
          setSortColumnField(sortOptions.default.field)

          if (sortOptions.default.direction && sortOptions.default.direction === -1) {
            setSortDirection(-1)
          }
        } else if (columns && columns.length > 0) {
          setSortColumnField(columns[0].field)
        }
      }
    } else {
      if (sortOptions && sortOptions.default && sortOptions.default.field) {
        if (!sortColumnFieldPageable.direction) {
          const columnSortable = {
            field: sortOptions.default.field,
            direction: sortOptions.default.direction
          }
          setSortColumnFieldPageable(columnSortable)
        }
      }
    }
  }, [columns, sortColumnField, sortOptions, pageable, setSortColumnFieldPageable, sortColumnFieldPageable.direction])

  useEffect(() => {
    if (Object.keys(requestPagination).length !== 0 && firstLoadRequestPagination) {
      startTableIntervalRef.current(true, true)
      setFirstLoadRequestPagination(false)
    }
  }, [requestPagination, firstLoadRequestPagination])

  const totalElementsMainTable = useRef(0)

  useEffect(() => {
    const fetch = async () => {
      let selectableRecords, selectedQuantity
      if (typeof dataFetch === 'function') {
        selectableRecords = await dataFetch(requestPaginationMultiSelection)
      } else if (typeof dataFetch === 'string') {
        selectableRecords = await apiGet(dataFetch, pageable ? requestPaginationMultiSelection : null)
      }

      if (pageable) {
        selectedQuantity = selectableRecords.totalElements
      } else {
        selectedQuantity = requestPaginationMultiSelection.rows.length
      }

      setMessageToolbarMultipleSelection(selectedQuantity + ' ' + t_('items selected'))
    }
    if (Object.keys(requestPaginationMultiSelection).length !== 0) {
      fetch()
    }
  }, [requestPaginationMultiSelection, dataFetch, pageable, data.length, pageableObject.totalElements, t_])

  useEffect(() => {
    totalElementsMainTable.current = data.length
  }, [data.length])
  useEffect(() => {
    totalElementsMainTable.current = pageableObject.totalElements
  }, [pageableObject.totalElements])

  const getObjectPropertyFromString = (obj, str, defaultValue) => {
    str = str.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    str = str.replace(/^\./, '') // strip a leading dot
    const a = str.split('.')
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i]
      if (obj !== null && obj !== undefined && obj !== 'undefined' && k in obj && obj[k] !== null) {
        obj = obj[k]
      } else {
        return defaultValue
      }
    }

    if (obj == null || obj === undefined) {
      return ''
    }
    const column = columns.find(col => col.field === str)
    return column.type === 'datetime' || column.type === 'dateTimePicker'
      ? GetDateTime(obj)
      : column.type === 'date'
        ? GetDate(obj)
        : column.type === 'time' ? GetTime(obj) : obj
  }

  const dataAvailable = useRef(false)

  const getVisualizedData = (rawData) => {
    const rData = rawData || (setMultiEdit !== undefined ? dataForMultipleEdit : data)
    const visualizedData = []
    let offset = 0
    if (editIndex === -1 && !pageable) {
      offset = 1
      const editingRow = getEditingRow()
      if (editingRow) {
        visualizedData.push(editingRow)
      }
    }

    let value = [...visualizedData, ...rData]
    if (!pageable && setMultiEdit === undefined) {
      //   return [...visualizedData, ...rData]
      // } else {
      value = [
        ...visualizedData,
        ...getTableDataFiltered(rData).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage - offset
        )
      ]
    } else if (!pageable) {
      value = [
        ...visualizedData,
        ...getTableDataFiltered(rData)
      ]
    }
    if (selectedItem) {
      checkSelectedItemInData(value)
      setSelectedItem(null)
    }

    if (value.length > 0) {
      dataAvailable.current = true
    } else {
      dataAvailable.current = false
    }

    return value
  }
  const getEditingRow = () => {
    return data.find((row) => rowHasTableIndexDefined(row) && row[smartTableIndexKey] === editIndex)
  }

  const validateMultiSelectColumn = (row, column) => {
    let result

    if (column.type === 'smartMultiSelect' && multipleOptions) {
      const editingRowProperty = getEditingRowProperty(column.field)
      const value = row[editingRowProperty.editingRowProperty]
      if (value.length > 0) {
        result = false
      } else {
        result = true
      }
    } else {
      result = false
    }
    return result
  }

  const validateSingleAutocompleteSelectColumn = (row, column) => {
    let result
    if (column.type === 'smartMultiSelect' && !multipleOptions) {
      const editingRowProperty = getEditingRowProperty(column.field)
      const value = row[editingRowProperty.editingRowProperty]
      if (Object.keys(value).length > 0) {
        result = false
      } else {
        result = true
      }
    }

    return result
  }

  const resetPageCount = async () => {
    if (page !== 0 || (pageable && pageableObject.number !== 0)) {
      setPage(0)
    }
  }

  useEffect(() => {
    resetMultiSelectionValue()
  }, [resetMultipleSelectionValue])

  const validateColumError = (row, columns) => {
    for (let i = 0; i < columns.length; i++) {
      if (
        mandatory(columns[i], row, i) &&
        (((getObjectPropertyFromString(row, columns[i].field) === undefined ||
          getObjectPropertyFromString(row, columns[i].field) === '') && columns[i].type !== 'smartMultiSelect') ||
          (validateMultiSelectColumn(row, columns[i]) || validateSingleAutocompleteSelectColumn(row, columns[i])))) {
        setErrorValidation(errorTextfield, i, setErrorTextfield)
      } else if (Object.prototype.hasOwnProperty.call(columns[i], 'type') &&
        columns[i].type === 'dateTimePicker') {
        if (!isValidDate(getObjectPropertyFromString(row, columns[i].field))) {
          setErrorValidation(errorTextfield, i, setErrorTextfield)
        }
      }
    }

    // Check if custom validator has been provided
    if (typeof validator === 'function') {
      const error = validator(row)
      if (error) {
        let errorFieldList
        if (Array.isArray(error)) {
          errorFieldList = error
        } else if (Array.isArray(error.errorFields)) {
          errorFieldList = error.errorFields
        }
        if (errorFieldList.length > 0) {
          for (let i = 0; i < errorFieldList.length; i++) {
            let j = 0
            while (j < columns.length) {
              if (columns[j].field === errorFieldList[i]) {
                setErrorValidation(errorTextfield, j, setErrorTextfield)
                break
              } else {
                j++
              }
            }
          }
        }
        if (typeof error.alertMessage === 'string') {
          showAlert(
            {
              title: error.alertMessage,
              severity: 'warning'
            }
          )
        }
      }
    }
    const error = errorTextfield.find(
      (column) => column.error === true
    )
    if (error) return true
    return false
  }

  const isValidDate = (value) => {
    return moment(GetDateTime(value).isValid).isValid()
  }
  const handleLabel = (column, row, columnIndex) => {
    if (mandatory(column, row, columnIndex)) {
      return t_('Required *')
    } else {
      return ''
    }
  }

  const mandatory = (column, row, columnIndex) => {
    let mandatory = false
    if (column.mandatory && typeof column.mandatory === 'function') {
      const mandatoryFunction = (elem) => {
        mandatory = column.mandatory(elem)
      }
      mandatoryFunction(row)
    } else {
      mandatory = column.mandatory
    }
    return mandatory
  }

  const condition = (column, row) => {
    let condition = ''
    if (column.condition && typeof column.condition === 'function') {
      const conditionFunction = (row, column) => {
        const columnField = getEditingRowProperty(column.field).editingRowProperty
        condition = column.condition(row, columnField)
      }
      conditionFunction(row, column)
    }
    return condition
  }

  const changeTextValue = (value, column, columnIndex, row) => {
    const copyErrorTextfield = [...errorTextfield]
    if (column.unique) {
      const found = data.find(
        (element) => {
          const dataValue = getObjectPropertyFromString(element, column.field)
          return dataValue !== undefined ? dataValue.toString() === value : false
        }
      )

      if (!found) {
        copyErrorTextfield[columnIndex].error = false
        copyErrorTextfield[columnIndex].errorLabel = ''
      } else {
        copyErrorTextfield[columnIndex].error = true
        copyErrorTextfield[columnIndex].errorLabel = t_('Key duplicated')
        setErrorTextfield(columnIndex)
      }
      setErrorTextfield(copyErrorTextfield)
    } else if (mandatory(column, row) && value !== undefined && value !== '') {
      copyErrorTextfield[columnIndex].error = false
      copyErrorTextfield[columnIndex].errorLabel = ''
      copyErrorTextfield[columnIndex].label = ''
      setErrorTextfield(copyErrorTextfield)
    }

    // Check dateTimePicker if value is valid
    if (column.type === 'dateTimePicker') {
      if (value instanceof Date && isNaN(value)) {
        copyErrorTextfield[columnIndex].error = true
        copyErrorTextfield[columnIndex].errorLabel = 'Invalid format'
        setErrorTextfield(copyErrorTextfield)
      } else {
        copyErrorTextfield[columnIndex].error = false
      }
    }

    // Check if manadatory and is empty or null
    if (mandatory(column, row) && (!value || (typeof value === 'string' && value.trim().length === 0))) {
      copyErrorTextfield[columnIndex].error = true
      copyErrorTextfield[columnIndex].errorLabel = t_('Required *')
      setErrorTextfield(copyErrorTextfield)
    }

    const propertySplit = column.field.split('.')

    if (setMultiEdit === undefined) {
      const editingRowProperty = getEditingRowProperty(column.field)
      let propertyParent = editingRowProperty.editingRow

      for (let i = 0; i < propertySplit.length - 1; i++) {
        propertyParent = propertyParent[propertySplit[i]]
      }

      propertyParent[propertySplit[propertySplit.length - 1]] = value

      if (column.condition !== undefined) {
        checkColumnsCondition(propertyParent)
      }
      setData((previousData) =>
        previousData.map((row) => {
          if (rowHasTableIndexDefined(row) && row[smartTableIndexKey] === editIndex) {
            return editingRowProperty.editingRow
          }
          return row
        })
      )
    } else {
      const copyDataForMultipleEdit = [...dataForMultipleEdit]

      copyDataForMultipleEdit.map((dataItem) => {
        if (row.id === dataItem.id) {
          dataItem[column.field] = value
        }
        return dataItem
      })

      setMultiEdit(copyDataForMultipleEdit)
      setDataForMultipleEdit(copyDataForMultipleEdit)
    }

    // This line was substituted because it was not valid for nested properties
    // editingRowProperty.editingRow[editingRowProperty.editingRowProperty] = value;
  }

  const changeSelectValue = (text, column, columnIndex, row) => {
    changeEditedSelect(
      column,
      getValueFromColumnSelectOptionsData(
        columnSelectOptions[column.name].data,
        text,
        column.edit.field
      ),
      columnIndex,
      row
    )

    setLoadTableColumn(!loadTableColumn)
  }

  const getValueFromColumnSelectOptionsData = (data, text, columnEditField) => {
    return data.find((d) => {
      if (typeof d === 'string') {
        return d === text
      } else {
        return d[columnEditField] === text
      }
    })
  }

  const changeEditedSelect = (column, value, columnIndex, row) => {
    const copyErrorTextfield = [...errorTextfield]
    const editingRowProperty = getEditingRowProperty(column.field)
    const currentRow = row
    currentRow[editingRowProperty.editingRowProperty] = value
    if (mandatory(column, row) && value !== undefined && value !== '') {
      copyErrorTextfield[columnIndex].error = false
      copyErrorTextfield[columnIndex].errorLabel = ''
      setErrorTextfield(copyErrorTextfield)
    }

    if (column.condition !== undefined) {
      checkColumnsCondition(currentRow, value)
    }

    editingRowProperty.editingRow[editingRowProperty.editingRowProperty] =
      value
    setData((previousData) =>
      previousData.map((row) => {
        if (rowHasTableIndexDefined(row) && row[smartTableIndexKey] === editIndex) {
          return editingRowProperty.editingRow
        }
        return row
      })
    )
    if (column.onChange && typeof column.onChange === 'function') {
      column.onChange(editingRowProperty.editingRow)
    }
  }

  const getEditingRowProperty = (property) => {
    let editingRow = getEditingRow()
    let pList = property.split('.')
    if (pList.length > 1) {
      pList = pList.slice(0, pList.length - 1)
    }

    for (let i = 0; i < pList.length - 1; i++) {
      const elem = pList[i]
      if (!editingRow[elem]) editingRow[elem] = {}
      editingRow = editingRow[elem]
    }
    return {
      editingRow,
      editingRowProperty: pList[pList.length - 1]
    }
  }

  const handleRowClick = (onRowClickProperty, row, rowIndex) => {
    if (!onRowClickProperty) return
    if (typeof onRowClickProperty === 'function') {
      const onRowClickFunction = (elem, elemIndex) => {
        onRowClickProperty(elem, elemIndex)
      }
      onRowClickFunction(row, rowIndex)
      setSelectedRow(rowIndex)
    }
  }

  const handleColumnHeaderClick = (column) => {
    if (pageable) {
      const columnSortable = {}
      if (column.field === sortColumnFieldPageable.field) {
        columnSortable.field = sortColumnFieldPageable.field
        columnSortable.direction = sortColumnFieldPageable.direction * -1
      } else {
        columnSortable.field = column.field
        columnSortable.direction = -1
      }
      setSortColumnFieldPageable(columnSortable)
    } else {
      if (column.field === sortColumnField) {
        setSortDirection(sortDirection * -1)
      } else {
        setSortColumnField(column.field)
        setSortDirection(-1)
      }
      if (column.sortOptions !== undefined) {
        column.sortOptions = column.sortOptions * -1
      } else {
        column.sortOptions = 1
      }
    }

    if (pageable) setLoadTable(!loadTable)
  }

  const getDataSorted = (dataToSort) => {
    if (actions && actions.edit && actions.edit.cancelSorting && editIndex > -2) return dataToSort
    return dataToSort.sort((one, two) => {
      let sortResult = 0
      const a = getObjectPropertyFromString(one, sortColumnField)
      const b = getObjectPropertyFromString(two, sortColumnField)

      const column = columns.find(col => col.field === sortColumnField)
      if (column === undefined) return 1
      if (column.type === 'datetime') {
        // Check if values are Date
        const dateA = Date.parse(a)
        const dateB = Date.parse(b)

        if (!isNaN(dateA) && !isNaN(dateB)) {
          return (dateA - dateB) * sortDirection
        }
      }

      if (a < b) {
        sortResult = -1
      }
      if (a > b) {
        sortResult = 1
      }

      return sortResult * sortDirection
    })
  }

  const getTableDataSorted = () => {
    return getDataSorted(setMultiEdit !== undefined ? dataForMultipleEdit : data)
  }

  const getTableDataFilteredFromData = () => {
    return getTableDataFiltered(data)
  }

  const getTableDataFiltered = (rawData) => {
    return getTableDataSorted(rawData).filter((row) => {
      if (rowHasTableIndexDefined(row) && row[smartTableIndexKey] === editIndex) {
        if (editIndex === -1) {
          return false
        }
        return true
      }
      let filterResult = false
      let columnFilterCheck = true
      columns.forEach((column) => {
        let columnVal = getObjectPropertyFromString(row, column.field)
        columnVal = columnVal === undefined ? '' : columnVal
        columnFilterCheck =
          columnFilterCheck &&
          (!columnFilters.current[column.field] ||
            columnVal
              .toString()
              .toLowerCase()
              .indexOf(columnFilters.current[column.field].toLowerCase()) > -1)

        if (
          typeof column.filterSearch === 'undefined' &&
          columnVal &&
          columnVal.toString().toLowerCase().indexOf(filterText.toLowerCase()) > -1
        ) {
          filterResult = true
        }
        if (filterText !== '' && (pageable ? pageableObject.number !== 0 : page !== 0)) {
          pageableObject.number = 0
          setPage(0)
        }
      })
      return filterResult && columnFilterCheck
    })
  }

  const changeColumnFilter = (column, value) => {
    columnFilterChanged.current = [...columnFilterChanged.current, column.field]
    const prevColumnFilters = Object.assign(columnFilters.current)
    if (value !== '' && (page !== 0 || (pageable && pageableObject.number !== 0))) {
      pageableObject.number = 0
      if (!pageable) setPage(0)
    }

    columnFilters.current = { ...columnFilters.current, [column.field]: value }

    prevColumnFilters[column.field] = value
    // columnFilters.current = prevColumnFilters
    if (pageable) setLoadTable(!loadTable)
  }

  const handleHelperText = (object, limit, dataType) => {
    if (
      typeof limit === 'undefined' ||
      dataType === 'number' ||
      object === undefined
    ) {
      return ' '
    } else if (typeof object.length === 'undefined') {
      return `${object.toString().length} / ${limit}`
    } else {
      return `${object.length} / ${limit}`
    }
  }
  const handleValue = (value, defaultValue) => {
    return value
  }

  // Set default value when field value isn't filled on onBLur event
  const onBlurField = (value, row, field, defaultValue) => {
    if (
      (defaultValue !== null || defaultValue !== undefined) &&
      (value === '' || value === null || value === undefined)
    ) {
      row[field] = defaultValue
      return defaultValue
    }
    row[field] = value
    return value
  }

  const handleReadOnly = (row, columnIndex, columnField, readOnlyProperty, resetProperty) => {
    if (readOnlyProperty === undefined) return false
    let readOnly = false
    if (typeof readOnlyProperty === 'function') {
      const readOnlyFunction = (elem) => {
        readOnly = readOnlyProperty(elem)
      }
      readOnlyFunction(row)
    } else {
      readOnly = readOnlyProperty
    }
    handleReset(row, columnIndex, columnField, resetProperty)
    return readOnly
  }

  const setObjectValueByColumnField = (obj, columnField, value) => {
    let tempObj = obj
    const fields = columnField.split('.')
    for (let i = 0; i < fields.length - 1; i++) {
      const elem = fields[i]
      if (!tempObj[elem]) {
        tempObj[elem] = {}
      }
      tempObj = tempObj[elem]
    }
    tempObj[fields[fields.length - 1]] = value
  }

  const handleReset = (row, columnIndex, columnField, resetProperty) => {
    if (resetProperty === undefined) return false
    if (typeof resetProperty === 'function') {
      const resetFunction = (elem) => {
        const reset = resetProperty(elem)
        if (reset) {
          setObjectValueByColumnField(row, columnField, undefined)
          errorTextfield[columnIndex].error = false
          errorTextfield[columnIndex].errorLabel = ''
        }
      }
      resetFunction(row)
    }
  }

  const handleOnSelectAllDataClick = async (event) => {
    let copyMultipleSelectionValue = multipleSelectionValue
    if (!event.target.checked && getVisualizedData().length !== 0) {
      copyMultipleSelectionValue.rows = []
      if (pageable) {
        copyMultipleSelectionValue = { ...copyMultipleSelectionValue, allSelected: true }
      } else {
        data.forEach(row => {
          const copyRow = reduceRowPropertiesToDesired(primaryKeys, row)
          copyMultipleSelectionValue.rows.push(copyRow)
        })
      }
      setSwitchBtwDataPageAndAllDataCheckbox(true)
      setNewMultipleSelectionValue(copyMultipleSelectionValue)
      return
    }
    setSwitchBtwDataPageAndAllDataCheckbox(false)
    resetMultiSelectionValue()
  }

  const handleOnSelectAllDataPageClick = (event) => {
    if (event.target.checked && !handleIndeterminateCheckedStatus()) {
      let copyMultipleSelectionValue = multipleSelectionValue
      setSwitchBtwDataPageAndAllDataCheckbox(false)
      getVisualizedData().forEach((row) => {
        if (row[extraFilterParameter.name] !== extraFilterParameter.value) {
          const copyRow = reduceRowPropertiesToDesired(primaryKeys, row)
          if (copyMultipleSelectionValue.rows.find(row => JSON.stringify(row) === JSON.stringify(copyRow)) === undefined) {
            copyMultipleSelectionValue = { ...copyMultipleSelectionValue, rows: [...copyMultipleSelectionValue.rows, copyRow] }
          }
        }
      })
      setNewMultipleSelectionValue(copyMultipleSelectionValue)
      return
    }
    resetMultiSelectionValue()
  }

  const selectedValuesMultiSelect = (row, column) => {
    const editingRowProperty = getEditingRowProperty(column.field)
    const value = row[editingRowProperty.editingRowProperty]
    if (value === null || value === undefined) {
      return []
    } else if (Object.keys(value).length === 0 && Object.getPrototypeOf(value) === Object.prototype) {
      return []
    } else if (value !== undefined && value !== null && Array.isArray(value) === false) {
      const returnValue = []
      returnValue[0] = value[column.edit.field]
      return returnValue
    } else if (value !== undefined && value !== null && Array.isArray(value)) {
      const selectedValues = value.map((opt) => {
        return opt[column.edit.field]
      })
      return selectedValues
    } else {
      return []
    }
  }

  const optionValuesMultiSelect = (columnSelectOptions, column) => {
    if (columnSelectOptions === null || columnSelectOptions === undefined) {
      return []
    } else if (Object.keys(columnSelectOptions).length === 0 && Object.getPrototypeOf(columnSelectOptions) === Object.prototype) {
      return []
    } else if (reloadAutocompleteOptions) {
      setReloadAutocompleteOptions(false)
      return handleReloadOptions(column)
    } else if (columnSelectOptions !== undefined && columnSelectOptions !== null && Array.isArray(columnSelectOptions)) {
      const selectedValues = columnSelectOptions[column.name]?.data?.map((opt) => {
        return opt[column.edit.field]
      })
      return selectedValues
    } else {
      return []
    }
  }

  const onChangeMultiSelect = (newSelectedValues, column, row, columnSelectOptions, columnIndex) => {
    const editingRowProperty = getEditingRowProperty(column.field)
    const copyErrorTextfield = [...errorTextfield]
    let newRowValues = []

    newSelectedValues.forEach((value) => {
      newRowValues = newRowValues.concat(
        columnSelectOptions[column.name].data.find(elem => elem[column.edit.field] === value)
      )
    })

    row[editingRowProperty.editingRowProperty] = newRowValues

    // Update error text field state if mandatory condition is met
    if (mandatory !== undefined || mandatory !== null) {
      if (mandatory(column, row)) {
        copyErrorTextfield[columnIndex].error = false
        copyErrorTextfield[columnIndex].errorLabel = ''
        setErrorTextfield(copyErrorTextfield)
      }
    }

    if (setMultiEdit !== undefined) {
    // Add logic to update multi-editing variables
      const copyDataForMultipleEdit = [...dataForMultipleEdit]
      copyDataForMultipleEdit.map((dataItem) => {
        if (row.id === dataItem.id) {
          dataItem[column.field] = newSelectedValues
        }
        return dataItem
      })
      setMultiEdit(copyDataForMultipleEdit)
      setDataForMultipleEdit(copyDataForMultipleEdit)
    }
  }

  const disableSaveButton = () => {
    if (forceDisableSaveButton) {
      return true
    }
    let returnValue = false
    errorTextfield.forEach((elem) => {
      if (elem.error) {
        returnValue = true
      }
    })
    return returnValue
  }

  const setFilterTextValue = (column) => {
    if (column.filter === undefined || columnFilterChanged.current.length > 0) {
      return
    }
    columnFilters.current[column.field] = column.filter
    return column.filter
  }

  const checked = (row) => {
    if (row[extraFilterParameter.name] === extraFilterParameter.value) {
      return false
    }
    const copyRow = reduceRowPropertiesToDesired(primaryKeys, row)
    const findRowOnMultipleSelectionValue = multipleSelectionValue.rows.find(row => JSON.stringify(row) === JSON.stringify(copyRow)) !== undefined

    if (multipleSelectionValue.allSelected) {
      return !findRowOnMultipleSelectionValue
    } else {
      return findRowOnMultipleSelectionValue
    }
  }

  const handleIndeterminateCheckedStatus = () => {
    if (pageable) {
      if (pageableObject.totalElements !== multipleSelectionValue.rows.length && multipleSelectionValue.rows.length > 0) { return true } else { return false }
    } else {
      if (data.length !== multipleSelectionValue.rows.length && multipleSelectionValue.rows.length > 0) {
        return true
      } else { return false }
    }
  }

  function filter (arr, criteria) {
    return arr.filter(function (obj) {
      return Object.keys(criteria).every(function (c) {
        return criteria[c] === obj[c].toString()
      })
    })
  }

  const getDatafetch = () => {
    if (pageable) {
      return dataFetch
    } else {
      const datafetchRows = []
      multipleSelectionValue.rows.forEach(element => {
        datafetchRows.push(filter(data, element)[0])
      })
      return datafetchRows
    }
  }

  const handleReloadOptions = async (column) => {
    const options = await getIntervalColumnSelectOptions()

    const selectedValues = options.options[column.name].data.map((opt) => {
      return opt[column.edit.field]
    })
    return (selectedValues)
  }

  const functionToChangeTheValueWhenAddANewElementByModal = (column, row) => {
    const copyRowCreatedInOtherSmartTable = { ...rowCreatedInOtherSmartTable }

    const copyErrorTextfield = [...errorTextfield]

    if (column.field.includes('.')) {
      row[column.field.split('.')[0]] = copyRowCreatedInOtherSmartTable
    } else {
      row[column.field] = copyRowCreatedInOtherSmartTable
    }
    const index = copyErrorTextfield.findIndex(item => item.field === column.field)
    copyErrorTextfield[index].error = false
    setErrorTextfield(copyErrorTextfield)
  }

  const checkColumnsCondition = (currentRow, value) => {
    const copyErrorTextfield = [...errorTextfield]
    for (let x = 0; x < columns.length; x++) {
      if (columns[x].condition !== undefined) {
        const errorMsg = condition(columns[x], currentRow, value)
        if ((errorMsg !== '' && errorMsg !== undefined)) {
          copyErrorTextfield[x].error = true
          copyErrorTextfield[x].errorLabel = errorMsg
          setErrorTextfield(copyErrorTextfield)
        } else {
          copyErrorTextfield[x].error = false
          copyErrorTextfield[x].errorLabel = ''
          setErrorTextfield(copyErrorTextfield)
        }
      }
    }
  }

  const [openFilters, setOpenFilters] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const closeFilters = (event) => {
    setOpenFilters(false)
  }

  const handleFilterToggleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenFilters((previousOpen) => !previousOpen)
  }

  const cancelOperation = () => {
    if (setDisableRowActions !== undefined) setDisableRowActions(false)
    showAlert({
      title: t_('Operation cancelled'),
      severity: 'info'
    })
  }

  const saveFetch = (elem) => {
    const fetchFunction = actions.edit
      .fetchFunction(elem)
    if (fetchFunction !== undefined) {
      fetchFunction
        .then(() => {
          showAlert({
            title: t_('Updated correctly.'),
            severity: 'success'
          })
        })
        .catch((error) => {
          showAlert({
            severity: error.name ? error.name : 'error',
            title: '',
            message: t_(error.message)
          })
        })
    }
  }

  const deleteFunction = async (row) => {
    if (setDisableRowActions !== undefined) setDisableRowActions(false)
    await actions.delete.fetchFunction(
      [row]
    ).then((params) => {
      if (params && params.disableAlert) {
        return
      }
      showAlert({
        title: t_('Deleted correctly.'),
        severity: 'success'
      })
    }).catch((error) => {
      showAlert({
        title: '',
        message: t_(error.message),
        severity: error.name
      })
    })
  }

  function setErrorValidation (errorTextfield, i, setErrorTextfield) {
    const copyErrorTexfield = [
      ...errorTextfield
    ]
    copyErrorTexfield[i].errorLabel = t_('Required *')
    copyErrorTexfield[i].error = true
    setErrorTextfield(copyErrorTexfield)
  }

  const tableRef = useRef()
  const [tableWidth, setTableWidth] = useState(0)

  const newTableWidth = tableRef.current ? tableRef.current.offsetWidth : 0
  if (newTableWidth !== tableWidth) {
    setTableWidth(newTableWidth)
  }

  const getBooleanType = (row, field, isEnabled, rowEditing) => {
    return (
      <>
        <IconButton
          disabled={!isEnabled}
          onClick={() => {
            // Update the main data state
            setData((previousData) =>
              previousData.map((row) => {
                if (rowHasTableIndexDefined(row) && row[smartTableIndexKey] === editIndex) {
                  const newRow = { ...row }
                  newRow[field] = !row[field]
                  return newRow
                }
                return row
              })
            )

            // Toggle the boolean field in the current row
            row[field] = !row[field]
            if (setMultiEdit !== undefined) {
            // Add logic to update multi-editing variables
              const copyDataForMultipleEdit = [...dataForMultipleEdit]
              copyDataForMultipleEdit.map((dataItem) => {
                if (row.id === dataItem.id) {
                  dataItem[field] = row[field]
                }
                return dataItem
              })

              setMultiEdit(copyDataForMultipleEdit)
              setDataForMultipleEdit(copyDataForMultipleEdit)
            }
          }}
        >
          {getObjectPropertyFromString(row, field) ? <Done color='primary' /> : <Close color='error' />}
        </IconButton>
        {rowEditing ? <FormHelperText> </FormHelperText> : null}
      </>
    )
  }

  return (
    <SmartTablePaper>
      {AlertElement}
      <SmartDialog
        setOpen={openCreateDialog || openEditDialog}
        close={false}
        accept
        cancelCallback={() => { setOpenCreateDialog(false); setOpenEditDialog(false) }}
        acceptCallback={() => {
          saveFetch(currentSelectedRow)
          setOpenCreateDialog(false)
          setOpenEditDialog(false)
        }}
        cancel
        closeCallback={() => setOpenCreateDialog(false)}
        title={createDialogTitle({ color: 'primary', title: openCreateDialog ? t_('Create data?') : t_('Edit data?'), icon: DeleteOutlineRounded })}
        message={openCreateDialog ? t_('You are going to create new data, are you sure?') : t_('You are going to edit data, are you sure?')}
        renderComponent={
          <SmartMateriaUITable
            columns={columns}
            dataFetch={currentSelectedRow}
            searchAndPaginationVisible='none'
          />
        }
      />
      <SmartDeleteDialog
        show={openDeleteDialog}
        cancelCallback={() => {
          cancelOperation()
          setOpenDeleteDialog(false)
        }}
        deleteCallBack={() => {
          deleteFunction(currentSelectedRow)
          setOpenDeleteDialog(false)
        }}
        rows={[currentSelectedRow]}
        columns={columns}
      />
      <div style={{ position: 'relative' }}>
        <div style={{
          position: sticky ? 'sticky' : 'initial',
          top: 0,
          zIndex: 10,
          minWidth: tableWidth + 'px',
          backgroundColor: theme.palette.background.color_373636
        }}
        >
          <SmartMaterialUIToolbar
            multipleSelectionValue={multipleSelectionValue}
            setMultipleSelectionValue={setMultipleSelectionValue}
            datafetch={getDatafetch()}
            title={title}
            setFilterText={setFilterText}
            filterText={filterText}
            actions={actions}
            columns={columns}
            editIndex={editIndex}
            messageToolbarMultipleSelection={messageToolbarMultipleSelection}
            searchAndPaginationVisible={searchAndPaginationVisible}
            toolbarDisplay={toolbarDisplay}
            smartTableIndexKey={smartTableIndexKey}
            toolbar={toolbar}
            multipleSelectionActions={multipleSelectionActions}
            deleteMultipleSelectionDisabled={deleteMultipleSelectionDisabled}
            disableFlexGrow={disableFlexGrow}
            setLoadTable={setLoadTable}
            loadTable={loadTable}
            setDisableRowActions={setDisableRowActions}
            onAddElementClick={onAddNewElementClick}
            resetPageCount={resetPageCount}
            pagination={requestPaginationMultiSelection}
            pageable={pageable}
            importFile={importFile}
            renderingFilters={filters
              ? () => <ParameterFilters
                  onToggleClick={(toggleButton, filter) => onFilterToggleClick(toggleButton, filter)}
                  openFilters={openFilters}
                  filters={filters}
                  closeFilters={closeFilters}
                  handleClick={handleFilterToggleClick}
                  anchorEl={anchorEl}
                      />
              : null}
          />
        </div>
        <TableContainer sx={{ overflow: 'initial' }}>
          {columns
            ? (
              <Table stickyHeader={sticky} size={dense ? 'small' : 'medium'} ref={tableRef}>
                <TableHead sx={{
                  position: sticky ? 'sticky' : 'initial',
                  // Top is toolbar height
                  top: toolbarDisplay === 'none' ? 0 : 100,
                  zIndex: 10,
                  backgroundColor: (theme) => theme.palette.background.color_373636
                }}
                >
                  {/* Search row from table head */}
                  <TableRow style={{ display: [searchDisplay !== undefined ? searchDisplay : searchAndPaginationVisible] }}>
                    {/* Add an empty cell on a table hade if is an  multiSelect */}
                    {multipleSelection
                      ? (
                        <TableCell />
                        )
                      : null}
                    {columns.map((column, index) => (
                      <React.Fragment key={'columnTable' + index}>
                        {
                        // Add a column if hasn't set to false hide property
                        !column.hide
                          // If the column search parameter is false, add an empty cell, otherwise add a search textField to that column
                          ? column.search === false
                            ? <TableCell key='column_header_actions' />
                            : (
                              <TableCell key={'filterRow_' + index}>
                                <TextField
                                  variant='standard'
                                  sx={tableSearchStyle}
                                    // id={column.field}
                                  value={setFilterTextValue(column)}
                                  placeholder={t_('Search')}
                                  disabled={!(column.sortable === undefined || column.sortable === true)}
                                  onChange={(event) => {
                                    changeColumnFilter(column, event.target.value)
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position='start'>
                                        <Search sx={tableSearchIconStyle} />
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </TableCell>
                              )
                          : null
                      }
                      </React.Fragment>
                    ))}
                    {/* If the table has any actions, add an empty cell on the table head */}
                    {actions &&
                    ((actions.edit && (actions.edit.fetchFunction || actions.edit.redirect)) ||
                      (actions.custom && actions.custom.length > 0) || actions.accordion)
                      ? <TableCell key='column_header_actions' />
                      : null}
                  </TableRow>
                  {/* Column name row from table head */}
                  <TableRow>
                    {/* Add a global checkbox if the table has multi selection */}
                    {multipleSelection
                      ? (
                        <SmartTableHead padding='checkbox'>
                          <Checkbox
                            color='primary'
                            onDoubleClick={(event) => {
                              handleOnSelectAllDataClick(event)
                            }}
                            onClick={(event) => handleOnSelectAllDataPageClick(event)}
                            indeterminate={handleIndeterminateCheckedStatus()}
                            checkedIcon={switchBtwDataPageAndAllDataCheckbox ? <LibraryAddCheck color='primary' /> : <CheckBox />}
                            checked={pageable ? (multipleSelectionValue.allSelected && multipleSelectionValue.rows.length === 0) || multipleSelectionValue.rows.length === pageableObject.totalElements : multipleSelectionValue.rows.length === data.length}
                            inputProps={{ 'aria-labelledby': '' }}
                            disabled={editIndex > -2}
                          />
                        </SmartTableHead>
                        )
                      : null}
                    {columns.map((column, index) => (
                    // Add a column that contains a sortable component if the column hasn't got a hide property
                      !column.hide
                        ? (
                          <SmartTableHead
                            key={'smart-table-head-' + index}
                          >
                            {column.sortable === undefined || column.sortable === true
                              ? (
                                <TableSortLabel
                                  key={'smart-table-sortLabel-' + index}
                                  active={column.field === (pageable ? sortColumnFieldPageable.field : sortColumnField)}
                                  direction={(pageable ? sortColumnFieldPageable.direction : sortDirection) === -1 ? 'desc' : 'asc'}
                                  onClick={(event) =>
                                    handleColumnHeaderClick(column)}
                                >
                                  {column.name}
                                </TableSortLabel>)
                              : (column.name)}

                          </SmartTableHead>
                          )
                        : (null)
                    ))}
                    {/* If the table has any actions, add an empty cell on the table head */}
                    {actions &&
                    ((actions.edit && (actions.edit.fetchFunction || actions.edit.redirect)) ||
                      (actions.custom && actions.custom.length > 0) || actions.accordion)
                      ? (
                        <SmartTableHead key='smart-table-head-hola' />
                        )
                      : null}
                  </TableRow>

                </TableHead>
                {/* Here starts the table body */}
                <TableBody key='table_body'>
                  {/* If the table is still loading for the first time,show a CircularProgress icon */}
                  {!firstLoad
                    ? <TableRow><TableCell align='center' sx={{ paddingTop: '2rem', paddingBottom: '2rem' }} colSpan={multipleSelection ? columns.length + 1 : columns.length}><CircularProgress /></TableCell></TableRow>
                    : null}

                  {/* Loop getVisualizedData() method content and add a row per each record to the table body */}
                  {getVisualizedData().map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      <SmartTableRow
                        hover
                        onClick={
                        onRowClick
                          ? () => handleRowClick(onRowClick, row, rowIndex)
                          : null
                      }
                        row={
                        rowIndex % 2 !== 0
                      }
                        key={'data' + rowIndex}
                        selected={
                        (row && rowHasTableIndexDefined(row) &&
                          editIndex === row[smartTableIndexKey]) ||
                        selectedRow === rowIndex
                      }
                      >
                        {/* Add checkBox to the table body row if multiSelection parameter is set */}
                        {multipleSelection
                          ? (
                            <TableCell padding='checkbox' sx={tableCellStyle}>
                              <>
                                <Checkbox
                                  color='primary'
                                  onChange={() => handleOnChange(row)}
                                  checked={checked(row)}
                                  inputProps={{
                                    'aria-labelledby': ''
                                  }}
                                  disabled={row[extraFilterParameter.name] === extraFilterParameter.value || editIndex > -2}
                                />
                                {editIndex > -2 ? <FormHelperText> </FormHelperText> : null}
                              </>
                            </TableCell>
                            )
                          : null}
                        {columns.map((column, columnIndex) => (
                        // Add columns to the table body row if the column hide parameter is not set
                          !column.hide
                            ? (
                              <TableCell key={'data' + rowIndex + '_column' + columnIndex} sx={tableCellStyle}>
                                <Box sx={{ paddingTop: dense && editIndex >= -1 ? '1em' : '' }}>
                                  {/* If the content is not and object or it can't retrieve the data from the column check if it the column has an render parameter set and show it.
                                    Otherwise print null */}
                                  {typeof getObjectPropertyFromString(
                                    row,
                                    column.field
                                  ) !== 'object' || getObjectPropertyFromString(row, column.field) || getObjectPropertyFromString(row, column.field) === null
                                    ? (
                                        column.render
                                          ? (column.render(row, { showAlert }))
                                          : (rowHasTableIndexDefined(row) && editIndex === row[smartTableIndexKey]) || setMultiEdit !== undefined
                                              ? (
                                                  (// Check if the content must be shown as readOnly. Condition = Not creating a new row and column edit property is settrue, or not creating and check reandOnly propery
                                                    Object.prototype.hasOwnProperty.call(column, 'edit') &&
                                      !column.edit &&
                                      editIndex !== -1) || ((typeof column.readOnly === 'function' || editIndex !== -1) && handleReadOnly(
                                                    row,
                                                    columnIndex,
                                                    column.field,
                                                    column.readOnly,
                                                    column.reset
                                                  )) // When the content is going to display as read only check if it is an autocomplete type. It it's not, display the content as a default textbox, otherwise use the autocomplete component as read only
                                                    ? (column.type === 'boolean')
                                                        ? getBooleanType(row, column.field, false, true)
                                                        : (column.type !== 'smartMultiSelect'
                                                            ? <TextField
                                                                value={getObjectPropertyFromString(
                                                                  row,
                                                                  column.field
                                                                )}
                                                                variant='standard'
                                                                sx={setMultiEdit === undefined ? textFieldUnderlineStyle : null}
                                                                InputProps={setMultiEdit !== undefined ? { disableUnderline: true } : null}
                                                                InputLabelProps={{
                                                                  shrink: true
                                                                }}
                                                                helperText={setMultiEdit !== undefined ? null : ' '}
                                                              />
                                                            : <SmartSelect
                                                                readOnly
                                                                multiple
                                                                width={column.width}
                                                                value={selectedValuesMultiSelect(row, column)}
                                                              />) // From here above the desired component to be edited will be select for the type defined
                                                    : (column.type === 'boolean')
                                                        ? getBooleanType(row, column.field, true, true)
                                                        : (Object.prototype.hasOwnProperty.call(column, 'edit') &&
                                        column.type === 'smartMultiSelect' && columnSelectOptions[column.name] &&
                                        columnSelectOptions[column.name].data)
                                                            ? <SmartSelect
                                                                multiple
                                                                functionToChangeTheValueWhenAddANewElement={() => functionToChangeTheValueWhenAddANewElementByModal(column, row)}
                                                                setReloadAutocompleteOptions={setReloadAutocompleteOptions}
                                                                value={selectedValuesMultiSelect(row, column)}
                                                                selectableOptions={optionValuesMultiSelect(columnSelectOptions, column)}
                                                                textFieldLabel={column.name}
                                                                content={column.content}
                                                                addButtonVisible={addButtonVisible}
                                                                label={errorTextfield[columnIndex].error
                                                                  ? (
                                                                    <Tooltip placement='top' title={errorTextfield[columnIndex].errorLabel}>
                                                                      <WarningIcon />
                                                                    </Tooltip>
                                                                    )
                                                                  : handleLabel(
                                                                    column,
                                                                    row,
                                                                    columnIndex
                                                                  )}
                                                                InputLabelProps={{
                                                                  shrink: true
                                                                }}
                                                                onChange={(newValues) => {
                                                                  onChangeMultiSelect(newValues, column, row, columnSelectOptions, columnIndex)
                                                                  if (column.edit.onClick_render) { column.edit.onClick_render(row, newValues) }
                                                                }}
                                                                error={
                                            errorTextfield[columnIndex].error
                                              ? errorTextfield[columnIndex].error
                                              : null
                                          }
                                                                helperText=' '
                                                              />
                                                            : (Object.prototype.hasOwnProperty.call(column, 'edit') &&
                                          column.type === 'smartSelect')
                                                                ? (
                                                                  <div>
                                                                    <SmartSelect
                                                                      multiple={false}
                                                                      functionToChangeTheValueWhenAddANewElement={() => functionToChangeTheValueWhenAddANewElementByModal(column, row)}
                                                                      setReloadAutocompleteOptions={setReloadAutocompleteOptions}
                                                                      value={handleSelectValue(row, column)}
                                                                      selectableOptions={optionValuesMultiSelect(columnSelectOptions, column)}
                                                                      textFieldLabel={column.name}
                                                                      content={column.content}
                                                                      addButtonVisible={addButtonVisible}
                                                                      label={errorTextfield[columnIndex].error
                                                                        ? (
                                                                          <Tooltip placement='top' title={errorTextfield[columnIndex].errorLabel}>
                                                                            <WarningIcon />
                                                                          </Tooltip>
                                                                          )
                                                                        : handleLabel(column, row, columnIndex)}
                                                                      InputLabelProps={{
                                                                        shrink: true
                                                                      }}
                                                                      onChange={(newValue) => {
                                                                        changeSelectValue(newValue, column, columnIndex, row)
                                                                        if (column.edit.onClick_render) { column.edit.onClick_render(row, newValue) }
                                                                      }}
                                                                      helperText=' '
                                                                      error={errorTextfield[columnIndex].error ? errorTextfield[columnIndex].error : null}
                                                                      disableClearable={mandatory(column, row, columnIndex) !== undefined}
                                                                      width={column.width}
                                                                    />
                                                                  </div>)
                                                                : Object.prototype.hasOwnProperty.call(column, 'edit') &&
                                            (column.type === 'select' ||
                                              column.type === 'select-plain')
                                                                  ? (
                                                                    <div>
                                                                      <TextField
                                                                        select
                                                                        variant='outlined'
                                                                        style={{ minWidth: '6em' }}
                                                                        label={errorTextfield[columnIndex].error
                                                                          ? (
                                                                            <Tooltip placement='top' title={errorTextfield[columnIndex].errorLabel}>
                                                                              <WarningIcon />
                                                                            </Tooltip>
                                                                            )
                                                                          : handleLabel(
                                                                            column,
                                                                            row,
                                                                            columnIndex
                                                                          )}
                                                                        InputLabelProps={{
                                                                          shrink: true
                                                                        }}
                                                                        key={
                                                  'select' +
                                                  rowIndex +
                                                  '_' +
                                                  columnIndex
                                                }
                                                                        error={
                                                  errorTextfield[columnIndex].error
                                                    ? errorTextfield[columnIndex].error
                                                    : null
                                                }
                                                                        value={handleSelectValue(row, column)}
                                                                        onChange={(event) => {
                                                                          changeSelectValue(
                                                                            event.target.value,
                                                                            column,
                                                                            columnIndex,
                                                                            row
                                                                          )
                                                                        }}
                                                                        onClick={column.edit.onClick_render ? (event) => column.edit.onClick_render(row) : null}
                                                                      >
                                                                        {[
                                                                          !mandatory(column, row, columnIndex)
                                                                            ? (
                                                                              <MenuItem key={'none1' + columnIndex} value='None'>
                                                                                <em>{t_('None')}</em>
                                                                              </MenuItem>
                                                                              )
                                                                            : null,
                                                                          columnSelectOptions[column.name] &&
                                                    columnSelectOptions[column.name].data
                                                                            ? column.type === 'select-plain'
                                                                              ? columnSelectOptions[
                                                                                column.name
                                                                              ].data.map((opt, index) => (
                                                                                <MenuItem
                                                                                  key={
                                                            'select' +
                                                            index +
                                                            rowIndex +
                                                            '_' +
                                                            columnIndex +
                                                            '_' +
                                                            opt
                                                          }
                                                                                  value={opt}
                                                                                >
                                                                                  {opt}
                                                                                </MenuItem>
                                                                              ))
                                                                              : columnSelectOptions[
                                                                                column.name
                                                                              ].data.map((opt, index) => {
                                                                                return (
                                                                                  <MenuItem
                                                                                    key={
                                                              'select2' +
                                                              index +
                                                              rowIndex +
                                                              '_' +
                                                              columnIndex +
                                                              '_' +
                                                              opt[column.edit.id]
                                                            }
                                                                                    value={
                                                              opt[column.edit.field]
                                                            }
                                                                                  >
                                                                                    {opt[column.edit.field]}
                                                                                  </MenuItem>
                                                                                )
                                                                              })
                                                                            : null]}
                                                                      </TextField>
                                                                      <FormHelperText>{' '}
                                                                      </FormHelperText>
                                                                    </div>
                                                                    ) // Multi select type.
                                                                  : (Object.prototype.hasOwnProperty.call(column, 'edit') &&
                                              column.type === 'smartMultiSelect' && columnSelectOptions[column.name] &&
                                              columnSelectOptions[column.name].data
                                                                      ? (
                                                                        <div>
                                                                          <SmartSelect
                                                                            readOnly={false}
                                                                            multiple
                                                                            width={column.width}
                                                                            value={selectedValuesMultiSelect(row, column)}
                                                                            selectableOptions={optionValuesMultiSelect(columnSelectOptions, column)}
                                                    // parameterName={getEditingRowProperty(column.field).editingRowProperty}
                                                    // columnIndex={columnIndex}
                                                                            errorTextfield={errorTextfield}
                                                                            onChange={(newValues) => {
                                                                              onChangeMultiSelect(newValues, column, row, columnSelectOptions, columnIndex)
                                                                              if (column.edit.onClick_render) { column.edit.onClick_render(row, newValues) }
                                                                            }}
                                                                          />
                                                                          <FormHelperText
                                                                            error={
                                                      errorTextfield[columnIndex].error
                                                        ? errorTextfield[columnIndex].error
                                                        : null
                                                    }
                                                                          >
                                                                            {errorTextfield[columnIndex].error
                                                                              ? errorTextfield[columnIndex]
                                                                                .errorLabel
                                                                              : handleLabel(
                                                                                column,
                                                                                row,
                                                                                columnIndex
                                                                              )}
                                                                          </FormHelperText>
                                                                        </div>
                                                                        )
                                                                      : (column.type === 'dateTimePicker'
                                                                          ? (
                                                                            <>

                                                                              <DateTimePicker
                                                                                sx={{ width: '12rem' }}
                                                                                label={errorTextfield[columnIndex].error
                                                                                  ? errorTextfield[columnIndex]
                                                                                    .errorLabel
                                                                                  : handleLabel(
                                                                                    column,
                                                                                    row,
                                                                                    columnIndex
                                                                                  )}
                                                                                value={(new Date(getObjectPropertyFromString(
                                                                                  row,
                                                                                  column.field,
                                                                                  null
                                                                                )))}
                                                                                slotProps={{
                                                                                  actionBar: {
                                                                                    actions: ['cancel', 'accept']
                                                                                  }
                                                                                }}
                                                                                localeText={{ cancelButtonLabel: t_('CANCEL'), okButtonLabel: t_('ACCEPT') }}
                                                                                closeOnSelect={false} showToolbar={false}
                                                                                format='dd/MM/yyyy HH:mm'
                                                                                ampm={false}
                                                                                timeSteps={{ minutes: 1 }}
                                                                                onChange={(value) => {
                                                                                  changeTextValue(
                                                                                    value,
                                                                                    column,
                                                                                    columnIndex,
                                                                                    row
                                                                                  )
                                                                                }}
                                                                                renderInput={(params) =>
                                                                                  <TextField
                                                                                    {...params}
                                                                                    error={
                                                            errorTextfield[columnIndex].error
                                                              ? errorTextfield[columnIndex].error
                                                              : null
                                                          }
                                                                                  />}
                                                                              />

                                                                              <FormHelperText
                                                                                error={
                                                      errorTextfield[columnIndex].error
                                                        ? errorTextfield[columnIndex].error
                                                        : null
                                                    }
                                                                              >
                                                                                {' '}
                                                                              </FormHelperText>
                                                                            </>
                                                                            ) // Default type
                                                                          : (
                                                                            <TextField
                                                                              sx={
                                                      textFieldStyle
                                                    }
                                                                              key={setMultiEdit === undefined
                                                                                ? 'textfield' + column.name
                                                                                : handleValue(
                                                                                  getObjectPropertyFromString(
                                                                                    row,
                                                                                    column.field
                                                                                  ),
                                                                                  column.numeric &&
                                                        column.numeric.default !== undefined
                                                                                    ? column.numeric.default
                                                                                    : null
                                                                                )}
                                                                              label={
                                                      errorTextfield[columnIndex].error
                                                        ? <Tooltip placement='top' title={errorTextfield[columnIndex].errorLabel}><WarningIcon /></Tooltip>
                                                        : handleLabel(
                                                          column,
                                                          row,
                                                          columnIndex
                                                        )
                                                    }
                                                                              error={
                                                      errorTextfield[columnIndex].error
                                                        ? errorTextfield[columnIndex].error
                                                        : null
                                                    }
                                                                              defaultValue={handleValue(
                                                                                getObjectPropertyFromString(
                                                                                  row,
                                                                                  column.field
                                                                                ),
                                                                                column.numeric &&
                                                        column.numeric.default !== undefined
                                                                                  ? column.numeric.default
                                                                                  : null
                                                                              )}
                                                                              onBlur={setMultiEdit !== undefined
                                                                                ? (event) => changeTextValue(
                                                                                    event.target.value,
                                                                                    column,
                                                                                    columnIndex,
                                                                                    row)
                                                                                : () =>
                                                                                    onBlurField(
                                                                                      getObjectPropertyFromString(
                                                                                        row,
                                                                                        column.field
                                                                                      ),
                                                                                      row,
                                                                                      column.field,
                                                                                      column.numeric &&
                                                          column.numeric.default !== undefined
                                                                                        ? column.numeric.default
                                                                                        : null
                                                                                    )}
                                                                              placeholder={
                                                      column.placeholder == null
                                                        ? ''
                                                        : column.placeholder
                                                    }
                                                                              InputLabelProps={{
                                                                                shrink: true
                                                                              }}
                                                                              inputProps={{
                                                                                maxLength: column.charLimit
                                                                              }}
                                                                              type={column.numeric ? 'number' : 'text'}
                                                                              helperText={setMultiEdit !== undefined
                                                                                ? null
                                                                                : handleHelperText(
                                                                                  getObjectPropertyFromString(
                                                                                    row,
                                                                                    column.field
                                                                                  ),
                                                                                  column.charLimit,
                                                                                  column.numeric ? 'number' : 'text'
                                                                                )}
                                                                              onChange={setMultiEdit
                                                                                ? null
                                                                                : (event) =>
                                                                                    changeTextValue(
                                                                                      event.target.value,
                                                                                      column,
                                                                                      columnIndex,
                                                                                      row
                                                                                    )}
                                                                            />
                                                                            )
                                                                        )
                                                                    )
                                                )
                                              : ((getObjectPropertyFromString(
                                                  row,
                                                  column.field
                                                ) ||
                                      (getObjectPropertyFromString(
                                        row,
                                        column.field
                                      ) === 0)) && column.type !== 'smartMultiSelect' && column.type !== 'boolean')
                                                  ? getObjectPropertyFromString(row, column.field)
                                                  : (column.type === 'smartMultiSelect'
                                                      ? (
                                                        <SmartSelect
                                                          readOnly
                                                          multiple
                                                          width={column.width}
                                                          value={selectedValuesMultiSelect(row, column)}
                                                        />
                                                        )
                                                      : column.type === 'boolean'
                                                        ? getBooleanType(row, column.field, false, false)
                                                        : '')
                                      )
                                    : null}
                                </Box>
                              </TableCell>
                              )
                            : null
                        ))}
                        {/* Actios columns */}
                        {actions &&
                        ((actions.edit && (actions.edit.fetchFunction || actions.edit.redirect)) || (actions.custom && actions.custom.length > 0) || actions.accordion)
                          ? (
                            <InputLabel />,
                              <TableCell
                                key={'action' + rowIndex}
                                sx={tableCellStyle}
                              >
                                <Stack
                                  direction='row'
                                  justifyContent='center'
                                  alignItems='center'
                                >
                                  {actions?.accordion && (!actions?.accordion?.hide || !actions?.accordion.hide(row))
                                    ? (
                                      <IconButton onClick={(event) => { accordionVisibility.toggleVisibility(row.id) }} style={{ visibility: (actions.accordion.visibilityButton !== undefined ? (!actions.accordion.visibilityButton(row) ? 'hidden' : 'visible') : 'visible') }}>
                                        {accordionVisibility.getSmartAccordionVisibility(row.id)
                                          ? <ArrowUpwardOutlined />
                                          : <ArrowDownwardOutlined />}
                                      </IconButton>
                                      )
                                    : null}
                                  {actions.custom && actions.custom.length > 0
                                    ? actions.custom.map((action, actionIndex) => {
                                      let actionRender = null
                                      if (
                                        action.render &&
                                  typeof action.render === 'function' &&
                                  rowHasTableIndexDefined(row) &&
                                  (row[smartTableIndexKey] !== -1 ||
                                    (row[smartTableIndexKey] === -1 &&
                                      (action.showOnNew === '' ||
                                        action.showOnNew === 'true')))
                                      ) {
                                        actionRender = action.render(row, dataFetch, requestPagination)
                                      }
                                      return (
                                        <span
                                          key={
                                      'action' +
                                      actionIndex +
                                      '_row' +
                                      rowIndex
                                    }
                                        >
                                          {actionRender}
                                        </span>
                                      )
                                    })
                                    : null}
                                  {/* If a record is been editing, show close and save icons instead edit */}
                                  {(actions.edit && (actions.edit.fetchFunction || actions.edit.redirect)) || actions.custom
                                    ? (
                                        rowHasTableIndexDefined(row) && editIndex === row[smartTableIndexKey]
                                          ? (
                                            <>
                                              <IconButton
                                                onClick={() => {
                                                  showAlert({
                                                    title: t_('There have not been modifications.'),
                                                    severity: 'info'
                                                  })
                                                  if (editIndex === -1) {
                                                    const previous = page * rowsPerPage
                                                    setData((previousData) => [
                                                      ...previousData.slice(0, previous),
                                                      ...previousData.slice(previous + 1)
                                                    ])
                                                    setTimeout(() => setSelectedRow(-1), 120)
                                                  } else {
                                                    setData((previous) =>
                                                      previous.map((previousRow) =>
                                                        rowHasTableIndexDefined(previousRow) && previousRow[smartTableIndexKey] ===
                                              editIndex
                                                          ? notSavedEditingRow
                                                          : previousRow
                                                      )
                                                    )
                                                  }
                                                  setEditIndex(-2)
                                                  if (onEditIndexChanged) {
                                                    onEditIndexChanged(-2)
                                                  }
                                                  setErrorTextfield(handleColumnsErrors)
                                                  if (editingRow !== undefined) editingRow(false)
                                                  setLoadTable(!loadTable)
                                                  if (setRowCreatedInOtherSmartTable !== null && setRowCreatedInOtherSmartTable !== undefined) {
                                                    setRowCreatedInOtherSmartTable(null)
                                                  }
                                                }}
                                              >
                                                <Close />
                                              </IconButton>
                                              {/* Save button */}
                                              <IconButton
                                                disabled={disableSaveButton()}
                                                onClick={() => {
                                                  const error = validateColumError(row, columns)
                                                  if (error || disableSave) return
                                                  if (editIndex === -1) {
                                                    if (
                                                      typeof actions.create
                                                        .fetchFunction === 'function'
                                                    ) {
                                                      setEditIndex(-2)
                                                      if (onEditIndexChanged) {
                                                        onEditIndexChanged(-2)
                                                      }
                                                      const saveFetch = (elem) => {
                                                        actions.create
                                                          .fetchFunction(elem)
                                                          .then((data) => {
                                                            if (handleRowCreatedInOtherSmartTable !== undefined) handleRowCreatedInOtherSmartTable(data)
                                                            resetPageCount()
                                                            showAlert({
                                                              title: t_('Created correctly.'),
                                                              severity: 'success'
                                                            })
                                                            setSelectedItem(elem)
                                                          })
                                                          .catch((error) => {
                                                            showAlert({
                                                              title:
                                                    '',
                                                              message: error.message,
                                                              severity: error.name
                                                            })
                                                          })
                                                        setLoadTable(!loadTable)
                                                      }
                                                      if (actions.create.validate) {
                                                        setOpenCreateDialog(true)
                                                        setCurrentSelectedRow(row)
                                                      } else {
                                                        saveFetch(row)
                                                      }
                                                    }
                                                  } else {
                                                    if (
                                                      typeof actions.edit.fetchFunction ===
                                          'function'
                                                    ) {
                                                      if (editingRow !== undefined) editingRow(false)
                                                      if (actions.edit.validate) {
                                                        setOpenEditDialog(true)
                                                        setCurrentSelectedRow(true)
                                                      } else {
                                                        saveFetch(row)
                                                      }
                                                      setEditIndex(-2)
                                                      if (onEditIndexChanged) {
                                                        onEditIndexChanged(-2)
                                                      }
                                                      setLoadTable(!loadTable)
                                                    }
                                                  }
                                                }}
                                              >
                                                <Save />
                                              </IconButton>
                                            </>
                                            )
                                          : actions.edit || actions.delete
                                            ? (
                                              <>
                                                {actions.edit !== undefined && actions.edit && !actions.edit.redirect
                                                  ? (
                                                    <IconButton
                                                      disabled={editIndex >= -1 || (actions.edit.condition && typeof actions.edit.condition === 'function' && !actions.edit.condition(row))}
                                                      style={{ visibility: (actions.edit.visibilityButton !== undefined ? (!actions.edit.visibilityButton(row) ? 'hidden' : 'visible') : 'visible') }}
                                                      onClick={() => {
                                                        if (rowHasTableIndexDefined(row)) {
                                                          if (editingRow !== undefined) {
                                                            editingRow(true)
                                                          }
                                                          setEditIndex(row[smartTableIndexKey])
                                                          if (onEditIndexChanged) {
                                                            onEditIndexChanged(row[smartTableIndexKey], row)
                                                          }
                                                          setNotSavedEditingRow({ ...row })
                                                        }
                                                      }}
                                                    >
                                                      <Edit />
                                                    </IconButton>
                                                    )
                                                  : actions.edit !== undefined && actions.edit.redirect
                                                    ? (
                                                      <IconButton
                                                        disabled={editIndex >= -1 || (actions.edit.condition && typeof actions.edit.condition === 'function' && !actions.edit.condition(row))}
                                                        onClick={() => {
                                                          if (editingRow !== undefined) {
                                                            editingRow(true)
                                                          }
                                                          const navigate = (elem) => {
                                                            actions.edit
                                                              .redirectFunction(elem)
                                                          }
                                                          navigate(row)
                                                        }}
                                                      >
                                                        <Edit />
                                                      </IconButton>
                                                      )
                                                    : null}
                                                {actions.delete
                                                  ? (
                                                    <IconButton
                                                      disabled={editIndex >= -1 || multipleSelectionValue.rows.length !== 0 || multipleSelectionValue.allSelected || (actions.delete.disabledButton !== undefined ? actions.delete.disabledButton(row) : false)}
                                                      style={{ visibility: (actions.delete.visibilityButton !== undefined ? (!actions.delete.visibilityButton(row) ? 'hidden' : 'visible') : 'visible') }}
                                                      onClick={() => {
                                                        if (
                                                          typeof actions.delete
                                                            .fetchFunction === 'function'
                                                        ) {
                                                          const auxSelectionValue = []
                                                          auxSelectionValue[0] = row
                                                          if (setDisableRowActions !== undefined) setDisableRowActions(true)
                                                          setOpenDeleteDialog(true)
                                                          setCurrentSelectedRow(row)
                                                        }
                                                      }}
                                                    >
                                                      <DeleteOutlineRounded />
                                                    </IconButton>
                                                    )
                                                  : null}
                                              </>
                                              )
                                            : null
                                      )
                                    : null}
                                </Stack>
                              </TableCell>
                            )
                          : null}
                      </SmartTableRow>
                      {actions?.accordion ? <SmartAccordion visible={accordionVisibility.getSmartAccordionVisibility(row.id)} renderComponent={actions.accordion.render(row)} colSpan={columns.length + 1 + (multipleSelection ? 1 : 0)} /> : null}
                    </React.Fragment>
                  ))}
                  {dataAvailable.current === false && firstLoad
                    ? <TableRow><TableCell align='center' sx={{ paddingTop: '2rem', paddingBottom: '2rem' }} colSpan={multipleSelection ? columns.length + 1 : columns.length}><Typography color='primary' fontSize='1.5rem'>{t_('No data to display')}</Typography></TableCell></TableRow>
                    : null}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <SmartMateriaUITablePagination
                      pageable={pageable}
                      searchAndPaginationVisible={searchAndPaginationVisible}
                      setPage={setPage}
                      setRowsPerPage={setRowsPerPage}
                      pageableObject={pageableObject}
                      setPageableObject={setPageableObject}
                      editIndex={editIndex}
                      getTableDataFiltered={getTableDataFilteredFromData}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      loadTable={loadTable}
                      setLoadTable={setLoadTable}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
              )
            : (
              <Typography
                variant='h6'
                component='div'
              >
                NO COLUMNS DEFINED
              </Typography>
              )}
        </TableContainer>
      </div>
    </SmartTablePaper>
  )
}

export default SmartMateriaUITable
