import React from 'react'
import theme from './themeConfig.js'
import MainContainer from './components/layoutStructure/MainContainer'
import { CssBaseline } from '@mui/material'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import './i18n'
import { UserContextProvider } from './context/UserContextProvider.js'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useLocalizationProviderAdapterLocale } from './components/generic/hooks/useSmartTranslation/useSmartTranslation.jsx'

function App () {
  return (

    <MUIThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <UserContextProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={useLocalizationProviderAdapterLocale()}>
          <MainContainer />
        </LocalizationProvider>
      </UserContextProvider>
    </MUIThemeProvider>

  )
}

export default App
