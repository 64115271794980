import { useLoader } from '@react-three/fiber'
import { useEffect, useMemo } from 'react'
import { EdgesGeometry, Mesh, MeshPhongMaterial } from 'three'
import { getBackendUrl } from '../../../../generic/Api_Functions'
/**
 *
 * @param {object} props Props object
 * @param {object} props.loader Loader object
 * @param {number} props.documentId Document ID
 * @param {'STL'|'PLY'|'OBJ'} props.modelType Model type
 * @param {function(number)} props.setModelSize Set model size function
 * @param {boolean} props.solid Show solid model
 * @param {boolean} props.edges Show edges
 * @param {number} props.edgesMinAngle Min angle for edges
 * @param {string} props.edgesColor Color for edges
 * @returns
 */
const ModelLoader = ({ loader, documentId, modelType, setModelSize, solid = true, edges = false, edgesMinAngle = 15, edgesColor }) => {
  const object = useLoader(loader, getBackendUrl() + 'document/file/download/' + documentId)

  useEffect(() => {
    if (modelType === 'PLY' || modelType === 'STL') {
      object.computeBoundingSphere()
      if (modelType === 'PLY') {
        object.computeVertexNormals()
      }
      setModelSize(object.boundingSphere.radius)
    }
  }, [object, setModelSize, modelType])

  const mesh = useMemo(() => {
    if (modelType === 'OBJ') {
      return object.children[0]
    }
    const mesh = new Mesh(object)
    mesh.material = new MeshPhongMaterial()
    return mesh
  }, [object, modelType])

  const calculatedEdges = useMemo(() => {
    if (!edges || (modelType === 'OBJ' && !mesh)) {
      return null
    }
    if (modelType === 'OBJ') {
      return new EdgesGeometry(mesh.geometry, edgesMinAngle)
    }
    return new EdgesGeometry(object, edgesMinAngle)
  }, [object, edges, edgesMinAngle, modelType, mesh])

  useEffect(() => {
    if (modelType === 'OBJ') {
      if (!mesh) {
        return
      }
      const geometry = mesh.geometry
      geometry.computeBoundingSphere()
      setModelSize(geometry.boundingSphere.radius)
    }
  }, [mesh, setModelSize, modelType])

  return (
    <>
      {solid ? <primitive object={mesh} /> : null}
      {
        calculatedEdges
          ? (
            <lineSegments geometry={calculatedEdges}>
              <lineBasicMaterial color={edgesColor} />
            </lineSegments>
            )
          : null
      }
    </>
  )
}

export default ModelLoader
