import { apiDelete, apiPost, apiPut } from "../../generic/Api_Functions";
import SmartMateriaUITable from "../../generic/SmartMateriaUITable";
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const FixtureTypes = () => {
  const { t_ } = useSmartTranslation();

  const actions = {
    edit: {
      fetchFunction: (fixtureType) => apiPut("fixtureTypes", fixtureType),
    },
    create: {
      fetchFunction: (fixtureType) => apiPost("fixtureTypes", fixtureType),
    },
    delete: {
      fetchFunction: (fixtureType) => {
        if (fixtureType.length === 1) {
          return apiDelete("fixtureTypes/" + fixtureType[0].id)
        } else {
          return apiDelete("fixtureTypesMulti", fixtureType)
        }
      },
      visibilityButton: (fixtureType) => fixtureType.userDefined,
    },
  };

  const columns = [
    {
      name: t_("Code"),
      field: "code",
      edit: false,
      charLimit: 60,
      mandatory: true,
      unique: true,
    },
    {
      name: t_("Name"),
      field: "name",
      edit: true,
      charLimit: 80,
      mandatory: true,
      unique: true,
      readOnly: (fixtureType) => {
        if (fixtureType.userDefined === undefined || fixtureType.userDefined === null) {
          return false
        }
        return !fixtureType.userDefined
      }
    },
    {
      name: t_("Description"),
      field: "description",
      charLimit: 200,
    },
  ];
  return (
    <>
      <SmartMateriaUITable
        title={t_("Fixture types")}
        columns={columns}
        dataFetch="fixtureTypes"
        actions={actions}
        multipleSelection
        dense={true}
      />
    </>
  );
};

export default FixtureTypes;
