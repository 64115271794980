import { GetActualDateTimeToExport, GetDateTime } from '../generic/DateTimeFunctions'

/**
 * @param {*} columns - columns to be exported
 * @param {*} data - data of the table
 * @param {*} filename - name of the file
 * @param {*} CSV_FIELD_SEPARATOR - separator for the csv file
 */
const downloadAsCsv = (columns, data, filename, CSV_FIELD_SEPARATOR) => {
  const CSV_NEW_LINE = '\n'

  let csvData = ''

  // Set column headers
  columns.forEach(element => {
    csvData += element.name + CSV_FIELD_SEPARATOR
  })
  csvData += CSV_NEW_LINE

  data.forEach(line => {
    columns.forEach(column => {
      if (line[column.field] === undefined) {
        csvData += CSV_FIELD_SEPARATOR
      } else {
        if ((new Date(line[column.field]).toString() !== 'Invalid Date')) {
          csvData += GetDateTime(line[column.field]) + CSV_FIELD_SEPARATOR
        } else {
          csvData += line[column.field] + CSV_FIELD_SEPARATOR
        }
      }
    })
    csvData += CSV_NEW_LINE
  })

  downloadAsFile(filename, csvData)
}

const downloadAsFile = (filename, content) => {
  const element = document.createElement('a')
  element.setAttribute('id', 'download-link')
  const file = new Blob([content], { type: 'application/csv' })
  element.href = URL.createObjectURL(file)
  element.download = filename + '_' + GetActualDateTimeToExport() + '.csv'
  // simulate link click
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export default downloadAsCsv
