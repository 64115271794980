import { apiGet, apiPost, apiPut, apiDelete } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { useState } from 'react'
import { IconButton } from '@mui/material'
import { ManageSearchOutlined } from '@mui/icons-material'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import MaterialStandard from './MaterialStandard'

const Materials = () => {
  const { t_ } = useSmartTranslation()
  const [materialStandardName, setMaterialStandardName] = useState(null)
  const [showMaterials, setShowMaterials] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [editing, setEditing] = useState(false)

  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Name'),
      field: 'name',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    },
    {
      name: t_('Hardness'),
      field: 'hardness',
      charLimit: 200,
      mandatory: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Heat treatment'),
      field: 'heatTreatment',
      charLimit: 200,
      mandatory: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Material type'),
      field: 'materialType.name',
      type: 'select',
      edit: {
        source: async () => {
          return await apiGet('materialTypesBasicData')
        },
        id: 'id',
        field: 'name'
      },
      mandatory: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    }
  ]

  const dataFetch = async (pagination) => {
    const materials = await apiGet('getMaterials', pagination)
    if (materials.content.length > 0) {
      setMaterialStandardName(materials.content[0].materialStandard.name)
    }
    return materials
  }

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('material', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        return apiPost('material', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('material/' + rows[0].id, rows)
        } else {
          return apiDelete('materialMulti', rows)
        }
      },
      visibilityButton: (row) => {
        return !row.systemDefault
      }
    },
    custom: [
      {
        name: 'Add Material',
        render: (row) => {
          return (
            <IconButton
              disabled={!!editing}
              onClick={(event) => {
                setSelectedRow(row)
                setShowMaterials(true)
              }}
            >
              <ManageSearchOutlined />
            </IconButton>
          )
        }
      }
    ]
  }

  return (
    <div>
      <SmartDialog
        renderComponent={<MaterialStandard selectedMaterial={selectedRow} />}
        setOpen={showMaterials}
        cancel={false}
        accept={false}
        close
        closeCallback={() => { setShowMaterials(false) }}
      />
      <SmartMateriaUITable
        columns={columns}
        title={t_('Materials with standard:') + ' ' + materialStandardName}
        dataFetch={dataFetch}
        actions={actions}
        pageable
        multipleSelection
        editingRow={(event) => setEditing(event)}
      />
    </div>
  )
}

export default Materials
