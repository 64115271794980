import SmartNavigator from './../generic/SmartNavigator'
import EditCalendarEventsComponent from './../configuration/calendars/EditCalendarEventsComponent'
import { useParams } from 'react-router-dom'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

const MainCalendarsEdit = (props) => {
  const params = useParams()

  const { isReference } = props
  const { t_ } = useSmartTranslation()

  const componentsInfo = [
    {
      title: params.calendarName ? t_('Calendar') + ' - ' + params.calendarName : t_('Calendar'),
      component: <EditCalendarEventsComponent isReference={isReference} calendarId={params.calendarId} />
    }
  ]

  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  )
}

export default MainCalendarsEdit
