import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { apiGet, apiPost } from "../../generic/Api_Functions";
import SensorsActuatorsComponentsConfiguration from "./SensorsActuatorsComponentsConfiguration";
import { useNavigate } from "react-router";
import ButtonBackSave from "../../generic/buttons/floatingButtons/ButtonBackSave";
import { Button, Paper, Typography } from "@mui/material"; 
import { SmartDialog } from "../../generic/utilities/SmartDialog";
import { SaveRounded } from "@mui/icons-material";



//import { useRef } from 'react';


const SensorsActuatorsComponentsEdit = () => {
  const [dialogStatus, setDialogStatus] = useState({open: false})
  const [ioElementSet, setIoElementSet] = useState({});
  const [saveDisabled, setSaveDisabled] = useState(false)
  let { sensorActuatorComponentId } = useParams();
  // const classes = useStyles();
  const backSaveButtonRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {

    const fetchIoElementSet = async () => {
      let ioElementSetDB = await apiGet("ioElementSet/" + sensorActuatorComponentId);
      setIoElementSet(ioElementSetDB);
    };
    fetchIoElementSet();
  }, [sensorActuatorComponentId]);

  const goBack = (value) => {
    navigate("/configuration/sensorsActuatorsComponents/", { state: value })
  }

  const updateIoElementSet = () => {
    apiPost("ioElementSets", ioElementSet).then(() => {
      goBack("EDIT")

    }).catch((error) => {
      backSaveButtonRef.current.showErrorAlert(error)
    })
  }

  const saveIoElementSet = () => {
    setDialogStatus({open:true})
  }


  return (
    <div>
      <SmartDialog
        title={{  render: <Typography  color="primary" variant="h6">Save data? </Typography> , icon: <SaveRounded color="primary" sx={{ fontSize: '1.8em', marginLeft: '0.3rem' }}/> }}
        message="Are you sure you want to save data?"
        cancel={true}
        cancelCallback={()=> setDialogStatus({open:false})}
        accept={false}
        close={false}
        renderCustomButtons={
          <Button color="primary" onClick={() => { updateIoElementSet([ioElementSet]) }} variant="outlined">
            <Typography color="primary" >SAVE </Typography>
            <SaveRounded color="primary" sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
          </Button >
        }
        setOpen={dialogStatus.open}
      />
        
        

      <Paper sx={{
        padding: theme => theme.spacing(2),
        "&.MuiPaper-root": {
          backgroundColor: theme => theme.palette.background.color_373636,
        }
      }} >
        
        {Object.keys(ioElementSet).length !== 0 ?
          <SensorsActuatorsComponentsConfiguration
            ioElementSet={ioElementSet}
            setIoElementSet={setIoElementSet}
            setSaveDisabled={setSaveDisabled}
            errorAlert={backSaveButtonRef.current.showErrorAlert}
          />
          : null}
        <ButtonBackSave backButtonAction={() => goBack("BACK")} saveButtonAction={() => saveIoElementSet()} saveDisabled={saveDisabled}
          ref={backSaveButtonRef} />
      </Paper>
    </div >
  )
};
export default SensorsActuatorsComponentsEdit;
