import React from 'react'
import SmartNavigator from '../generic/SmartNavigator'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import Settings from '../configuration/settings/Settings'

const MainSettings = (props) => {
  const { reloadTopBar, setReloadTopBar } = props
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('Settings'),
      component: <Settings setReloadTopBar={setReloadTopBar} reloadTopBar={reloadTopBar} />
    }
  ]
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  )
}
export default MainSettings
