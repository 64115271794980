import { SmartDialog } from '../../generic/utilities/SmartDialog';
import { ManageSearchOutlined } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import React, { useRef } from "react";
import { apiDelete, apiGet, apiPost, apiPut } from "../../generic/Api_Functions";
import SmartMateriaUITable from "../../generic/SmartMateriaUITable";
import AddElement from "../../generic/smartAddElement/AddElement";
import Fixture from './Fixture';
import { useState } from "react";
import { IconButton, TextField, Button } from '@mui/material';
import FixtureLinks from './FixtureLinks';
import AlertUI from './../../generic/AlertUI';
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation';

const FixtureReference = () => {
  const createFixtureEndPoint = "fixtures"
  const [fixtureLinksDialogOpen, setFixtureLinksDialogOpen] = useState(false);
  const [linkErrorMessage, setLinkErrorMessage] = useState(null);
  const [editingFixturesLinks, setEditingFixturesLinks] = useState(null);
  const editingFixturesLinksTemporalData = useRef(null);
  const [alert, showAlert] = AlertUI();
  const [resetMultipleSelectionValue, setResetMultipleSelectionValue] = useState(false);
  const [multiSelectionActive, setMultiSelectionActive] = useState(false);
  const [quantityValue, setQuantityValue] = useState(0)
  const [editing, setEditing] = useState(false)
  const disableRowActions = useRef(false)
  const [fixtureDialogOpen, setFixtureDialogOpen] = useState(false);
  const [rowfixtureDialog, setRowFixtureDialog] = useState({});
  const { t_ } = useSmartTranslation();

  const columns = [
    {
      name: t_("Code"),
      field: "code",
      edit: false,
      charLimit: 60,
      mandatory: true,
      unique: true,
    },
    {
      name: t_("Name"),
      field: "name",
      charLimit: 80,
      mandatory: true,
      unique: true,
    },
    {
      name: t_("Description"),
      field: "description",
      charLimit: 200,
    },
    {
      name: t_("Fixture type"),
      field: "fixtureType.name",
      type: "select",
      edit: {
        source: async () => {
          return await apiGet("fixtureTypes");
        },
        id: "id",
        field: "name",
      },
      mandatory: true,
    },
    {
      name: t_("Material type"),
      field: "materialType.name",
      type: "select",
      edit: {
        source: async () => {
          return await apiGet("materialTypes");
        },
        id: "id",
        field: "name",
      },
    },
    {
      name: t_("Quantity"),
      field: "quantity",
      numeric: true,
      defaultNewValue: 0,
      mandatory: true,

      render: (row, extraFunctions) => {
        return (
          row !== undefined && row.id === undefined && row.quantity === 0 ?
            <TextField
              value={quantityValue}
              onChange={(event) => {
                setQuantityValue(event.target.value)
              }}
              helperText={" "}
              type="number"
              inputProps={{ min: 0 }}

            /> :

            <div id={"quantity" + row.id} style={{ alignItems: "center", display: "flex" }}>
              {row.quantity}
              <AddElement
                endpoint={createFixtureEndPoint}
                parameter={row.id}
                referenceName={row.name}
                row={row}
                disable={editing || disableRowActions.current}
                onAlert={extraFunctions.showAlert}
              />
            </div>)

      },
    }
  ];

  const validateEditingFixturesLink = () => {
    let data = editingFixturesLinksTemporalData.current;

    if ((data.toolGroups.length > 0 || data.linkAllToolGroups) && (data.toolReferences.length === 0 && !data.linkAllToolReferences)) {
      // If tool group is selected tool reference must be also selected
      return false;
    }

    if (data.rawMaterialReferences.length > 0 || data.linkAllRawMaterialReferences) {
      return true;
    }
    if (data.partReferences.length > 0 || data.linkAllPartReferences) {
      return true;
    }
    if (data.fixtureReferences.length > 0 || data.linkAllFixtureReferences) {
      return true;
    }
    if (data.toolReferences.length > 0 || data.linkAllToolReferences) {
      return true;
    }
    if (data.devices.length > 0 || data.linkAllDevices) {
      return true;
    }

    return false;
  }

  const onAcceptLinks = async () => {
    // Check that at least one item is selected

    if (!validateEditingFixturesLink()) {
      setLinkErrorMessage(t_("At least one option must be selected"));
      return;
    }

    try {
      // Send the new data to the backend
      let idList = [];
      editingFixturesLinks.forEach(element => {
        idList.push(element.id);
      });
      await apiPost('fixtureReferences/' + idList.join(',') + '/links', editingFixturesLinksTemporalData.current);
      showAlert({ severity: "success", title: "Successfully saved" });
    } catch (error) {
      setLinkErrorMessage("There was an error. Could not save data");
    }
    setFixtureLinksDialogOpen(false);
  }

  const onCancelLinks = () => {
    setFixtureLinksDialogOpen(false);
    showAlert({ severity: "info", title: t_("Operation cancelled") });
  }

  const onShowLinksClicked = async (idList) => {
    setResetMultipleSelectionValue(!resetMultipleSelectionValue);
    editingFixturesLinksTemporalData.current = null;
    setEditingFixturesLinks(idList);
    setLinkErrorMessage(null);
    setFixtureLinksDialogOpen(true);
  }

  const actions = {
    edit: {
      fetchFunction: (row) => {
        setEditing(false)
        return apiPut("fixtureReferences", row);
      },
    },
    create: {
      fetchFunction: (row) => {

        if (row.materialType !== undefined && row.materialType !== null && Object.keys(row.materialType).length === 0) row.materialType = null;
        row.quantity = quantityValue
        setQuantityValue(0)
        return apiPost("fixtureReferences", row);
      },
    },
    delete: {
      fetchFunction: (row) => {
        if (row.length === 1) {
          return apiDelete("fixtureReferences/" + row[0].id, row);
        }
        else {
          return apiDelete("fixtureReferencesMulti", row);
        }
      },
    },
    custom: [
      {
        name: "ShowFixtures",
        render: (row) => {
          return <IconButton
            disabled={editing ? true : false}
            onClick={(event) => {
              setRowFixtureDialog(row);
              setFixtureDialogOpen(true)
            }}
          >
            <ManageSearchOutlined />
          </IconButton>
        },
      },
      {
        name: "ShowLinks",
        render: (row) => {
          return <IconButton
            disabled={editing || multiSelectionActive ? true : false}
            onClick={(event) => {
              onShowLinksClicked([{ id: row.id }])
            }}
          >
            <LinkIcon />
          </IconButton>
        },
      },
    ],
  };

  const multipleSelectionActions = [
    {
      render: (pendingChanges, index, pageable, multipleSelectionValue) => {
        return (<Button color="primary" sx={{ borderColor: "transparent" }} key={"key" + index} onClick={() => onShowLinksClicked(multipleSelectionValue.rows)}><LinkIcon style={{ marginRight: "0.5em", color: "#7FE1A7" }} /> {t_("Set Links")}</Button>)
      }

    },
  ]

  return (
    <div>
      {alert}
      <SmartDialog close={true} cancel={false} accept={false} closeCallback={() => setFixtureDialogOpen(false)} setOpen={fixtureDialogOpen} renderComponent={<Fixture referenceName={rowfixtureDialog.name} parameter={rowfixtureDialog.id} endpoint={"fixtures"} />} />
      <SmartDialog
        acceptCallback={() => onAcceptLinks()}
        acceptCallbackArgsArray={[]}
        cancelCallback={() => onCancelLinks()}
        title={{ visible: true, render: t_("Fixture Links"), icon: <LinkIcon /> }}
        setOpen={fixtureLinksDialogOpen}
        renderComponent={
          <FixtureLinks
            links={editingFixturesLinks}
            onLinksChanged={(newData) => editingFixturesLinksTemporalData.current = newData}
            errorMessage={linkErrorMessage}
          />} />
      <SmartMateriaUITable
        columns={columns}
        title={t_("Fixtures")}
        dataFetch={"fixtureReferences"}
        actions={actions}
        multipleSelection
        multipleSelectionActions={multipleSelectionActions}
        editingRow={(event) => setEditing(event)}
        setDisableRowActions={(event) => disableRowActions.current = event}
        resetMultipleSelectionValue={resetMultipleSelectionValue}
        multipleSelectionChange={(multipleSelectionValue) => {
          multipleSelectionValue.rows.length === 0 || multipleSelectionValue.AllSelected === true ? setMultiSelectionActive(false) : setMultiSelectionActive(true)
        }}
        importFile={[{ bean: { beanName: 'FixtureReference' }, name: t_("Fixture Reference") }, { bean: { beanName: 'Fixture' }, name: t_("Fixture") }]}
      />
    </div>
  );
};

export default FixtureReference;
