import React, { useRef, useCallback } from "react";
import { apiGet, apiPost } from "../generic/Api_Functions";

import AlertUI from "../generic/AlertUI";
import SmartMateriaUITable from "../generic/SmartMateriaUITable";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";


export default function StorageFillPosition() {
  const [AlertElement, showAlert] = AlertUI();
  const { t_ } = useSmartTranslation();

  const fetchData = useCallback(async (pagination) => {
    let dataList = [];
    if (pagination && (pagination.sortKey === null || pagination.sortKey === undefined || pagination.sortKey.length === 0)) {
      const columns = ["shelveStorageConfiguration.storage.device.name", "shelveStorageConfiguration.shelve", "shelveStorageConfiguration.shelve", "position", "depth"];
      pagination.sortKey = columns
      const orders = [1, 1, 1, 1, 1]
      pagination.sortMode = orders
    }
    dataList = await apiGet("shelveStoragePositionsPagination", pagination)
      .catch((error) => {
        showAlert({
          title: "Error getting data.",
          message: error
        })
      })

    let storageFillPositions = await apiGet("storageFillPosition")
    if (storageFillPositions !== undefined) {

      dataList.content.forEach((pos) => {
        let find = storageFillPositions.find(
          (position) =>
            position.shelveStoragePositionId === pos.id
        )
        if (find !== undefined) {
          pos.partReference = find.partReference
        }
      })
    }

    return dataList
  }, [showAlert])


  const selectedShelveStoragePosition = useRef(null)
  const onRowClick = (row) => {
    selectedShelveStoragePosition.current = row
  }

  const columns = [

    {
      name: t_("Storage"),
      field: "storageName",
      edit: false
    },
    {
      name: t_("Shelf"),
      field: "shelve",
      edit: false
    },
    {
      name: t_("Row"),
      field: "position",
      edit: false
    },
    {
      name: t_("Column"),
      field: "depth",
      edit: false
    },
    {
      name: t_("Reference"),
      field: "partReference.name",
      type: "select",
      sortable: false,
      edit: {
        source: async () => {
          let references = await apiGet("partReferences/rawMaterialReferenceNotNull");
          return references;
        },
        id: "id",
        field: "name",
      },
    },
  ]

  const actions = {
    edit: {
      fetchFunction: (editRow) => {
        let idPartRef = -1
        if (editRow.partReference) {
          idPartRef = editRow.partReference.id
        }
        return apiPost("storageFillPosition/" + idPartRef, editRow)
      },
      condition: (shelveStoragePosition) => {
        return !shelveStoragePosition.locked
      }
    }
  }

  return (
    <div>
      {AlertElement}
      <SmartMateriaUITable
        title={t_("Initial Configuration")}
        dataFetch={fetchData}
        columns={columns}
        actions={actions}
        forceLoad={true}
        dense
        onRowClick={(row) => onRowClick(row)}
        disableFlexGrow
        tablePageSize={50}
        pageable={true}
      />
    </div>
  );
}
