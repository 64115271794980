import React, { useState } from 'react'
import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { Checkbox } from '@mui/material'

const Technologies = () => {
  const { t_ } = useSmartTranslation()
  const [editingRow, setEditingRow] = useState(undefined)
  const [isAddingNewRow, setIsAddingNewRow] = useState(false)
  const columns = [{
    name: t_('Code'),
    field: 'code',
    charLimit: 60,
    mandatory: true,
    unique: true,
    readOnly: true
  },
  {
    name: t_('Name'),
    field: 'name',
    charLimit: 80,
    mandatory: true,
    unique: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_('Description'),
    field: 'description',
    charLimit: 200
  },
  {
    name: t_('Asset type'),
    field: 'deviceTypes.name',
    type: 'smartMultiSelect',
    edit: {
      source: async () => {
        return await apiGet('deviceTypes')
      },
      id: 'id',
      field: 'name'
    },
    mandatory: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_('Part process'),
    field: 'partProcess',
    render: (row) => {
      return <Checkbox checked={row.partProcess} disabled={handleDisabled(row)} onClick={() => { handleCheckBoxValue(row) }} />
    }
  }
  ]
  const handleDisabled = (row) => {
    if (row.systemDefault) {
      return true
    }
    if (editingRow?.id === row.id) {
      return false
    }
    if (isAddingNewRow && editingRow === undefined && row === null) {
      return false
    }
    if (!isAddingNewRow && editingRow === undefined) {
      return true
    }
    return true
  }
  const handleCheckBoxValue = (row) => {
    row.partProcess = !row.partProcess
  }
  const mapTechnologyDeviceTypes = (row) => {
    row.technologyDeviceTypes = []
    row.deviceTypes.forEach((deviceType) => {
      row.technologyDeviceTypes.push({ deviceType: { id: deviceType.id, name: deviceType.name }, technology: { code: row.code, id: row.id, name: deviceType.name } })
    })
    return row
  }

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('technologies', mapTechnologyDeviceTypes(row))
      }

    },
    create: {
      fetchFunction: (row) => {
        return apiPost('technologies', mapTechnologyDeviceTypes(row))
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('technologies/' + rows[0].id, rows)
        } else {
          return apiDelete('technologiesMulti', rows)
        }
      },
      visibilityButton: (row) => {
        return !row.systemDefault
      }
    }
  }

  const fetchFunction = async (pagination) => {
    const technologies = await apiGet('technologies', pagination)
    return technologies
  }
  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Technologies')}
      dataFetch={fetchFunction}
      actions={actions}
      multipleSelection
      multipleOptions
      pageable
      onEditIndexChanged={(editIndex, row) => { setIsAddingNewRow(editIndex === -1); setEditingRow(row) }}
    />
  )
}

export default Technologies
