import { IconButton, TextField } from '@mui/material'
import React, { useState, useRef } from 'react'
import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import AddElementPart from '../../generic/smartAddElement/AddElementPart'
import { Assignment, ManageSearchOutlined, SquareFoot, TextSnippetOutlined } from '@mui/icons-material'
import Part from './Part'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import Features from './Features'
import ChecklistPartReferenceDialog from '../../generic/checklist/ChecklistPartReferenceDialog'
import Document from '../document/Document'

const Reference = () => {
  const parallelepiped = 'PARALLELEPIPED'
  const cylindrical = 'CYLINDRICAL'
  const createPartEndPoint = 'parts'
  const [showPartReference, setShowPartReference] = useState(false)
  const [showCheckList, setShowCheckList] = useState(false)
  const [showFeatures, setShowFeatures] = useState(false)
  const [showDocument, setShowDocument] = useState(false)
  const [editing, setEditing] = useState(false)
  const disableRowActions = useRef(false)
  const [quantityValue, setQuantityValue] = useState(0)
  const [selectedRow, setSelectedRow] = useState({})
  const [currentEditingRow, setCurrentEditingRow] = useState(null)
  const [hideColumn, setHideColumn] = useState(false)
  const { t_ } = useSmartTranslation()

  const mandatoryCondition = (row, property) => {
    if (row.shape.code === property) return true
    return false
  }

  const checkDimensionCondition = (row, column) => {
    let errorMsg = ''
    if ((row.rawMaterialReference !== null && row.rawMaterialReference !== undefined && row.rawMaterialReference !== '') && (Object.keys(row.rawMaterialReference).length > 0) &&
      (Object.keys(row.shape).length > 0)) {
      if (row.shape.code === row.rawMaterialReference.shape.code) {
        if (row.shape.code === cylindrical) {
          if (row.diameter > row.rawMaterialReference.diameter || row.length > row.rawMaterialReference.length) {
            errorMsg = 'Part reference dimensions must be smaller than those of the raw material reference'
          }
        } else if (row.shape.code === parallelepiped) {
          if (row.lengthX > row.rawMaterialReference.lengthX || row.lengthY > row.rawMaterialReference.lengthY || row.lengthZ > row.rawMaterialReference.lengthZ) {
            errorMsg = 'Part reference dimensions must be smaller than those of the raw material reference'
          }
        }
      } else {
        errorMsg = 'Part reference shape and raw material reference shape must be equals'
      }
    }
    return errorMsg
  }

  const handleAlert = (extraFunctions) => {
    return extraFunctions.showAlert
  }
  const readOnlyCondition = (row, property) => {
    if (
      row.shape.code !== property ||
      row.shape.code === '' ||
      row.shape.code === undefined
    ) { return true }
    return false
  }

  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      readOnly: true,
      charLimit: 60,
      mandatory: true,
      unique: true
    },
    {
      name: t_('Name'),
      field: 'name',
      charLimit: 80,
      mandatory: true,
      unique: true
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    },
    {
      name: t_('Material type'),
      field: 'materialType.name',
      type: 'smartSelect',
      edit: {
        source: async () => {
          return await apiGet('materialTypes')
        },
        id: 'id',
        field: 'name'
      },
      mandatory: true
    },
    {
      name: t_('Raw Material Reference'),
      field: 'rawMaterialReference.name',
      type: 'smartSelect',
      edit: {
        source: async () => {
          return await apiGet('rawMaterialsReference')
        },
        id: 'id',
        field: 'name'
      },
      condition: (rowData, column) => {
        return checkDimensionCondition(rowData, column)
      }
    },
    {
      name: t_('Shape'),
      field: 'shape.name',
      type: 'smartSelect',
      edit: {
        source: async () => {
          return await apiGet('shapes')
        },
        id: 'id',
        field: 'name'
      },
      condition: (rowData, column) => {
        return checkDimensionCondition(rowData, column)
      },
      mandatory: true
    },
    {
      name: t_('X Length (mm)'),
      field: 'lengthX',
      readOnly: (rowData) => {
        return readOnlyCondition(rowData, parallelepiped)
      },
      mandatory: (rowData) => {
        return mandatoryCondition(rowData, parallelepiped)
      },
      reset: (rowData) => {
        return readOnlyCondition(rowData, parallelepiped)
      },
      condition: (rowData, column) => {
        return checkDimensionCondition(rowData, column)
      },
      numeric: true
    },
    {
      name: t_('Y Length (mm)'),
      field: 'lengthY',
      readOnly: (rowData) => {
        return readOnlyCondition(rowData, parallelepiped)
      },
      mandatory: (rowData) => {
        return mandatoryCondition(rowData, parallelepiped)
      },
      reset: (rowData) => {
        return readOnlyCondition(rowData, parallelepiped)
      },
      condition: (rowData, column) => {
        return checkDimensionCondition(rowData, column)
      },
      numeric: true
    },

    {
      name: t_('Z Length (mm)'),
      field: 'lengthZ',
      readOnly: (rowData) => {
        return readOnlyCondition(rowData, parallelepiped)
      },
      mandatory: (rowData) => {
        return mandatoryCondition(rowData, parallelepiped)
      },
      reset: (rowData) => {
        return readOnlyCondition(rowData, parallelepiped)
      },
      condition: (rowData, column) => {
        return checkDimensionCondition(rowData, column)
      },
      numeric: true
    },

    {
      name: t_('Diam (mm)'),
      field: 'diameter',
      readOnly: (rowData) => {
        return readOnlyCondition(rowData, cylindrical)
      },
      mandatory: (rowData) => {
        return mandatoryCondition(rowData, cylindrical)
      },
      reset: (rowData) => {
        return readOnlyCondition(rowData, cylindrical)
      },
      condition: (rowData, column) => {
        return checkDimensionCondition(rowData, column)
      },
      numeric: true
    },

    {
      name: t_('Length (mm)'),
      field: 'length',
      readOnly: (rowData) => {
        return readOnlyCondition(rowData, cylindrical)
      },
      mandatory: (rowData) => {
        return mandatoryCondition(rowData, cylindrical)
      },
      reset: (rowData) => {
        return readOnlyCondition(rowData, cylindrical)
      },
      condition: (rowData, column) => {
        return checkDimensionCondition(rowData, column)
      },
      numeric: {
        default: 0
      }
    },
    {
      name: t_('Fixture'),
      field: 'defaultFixtureReference.name',
      type: 'smartSelect',
      edit: {
        source: async () => {
          if (currentEditingRow !== null) { return await apiGet('fixtureReferencesByPartReference/' + currentEditingRow.id) }
        },
        id: 'id',
        field: 'name'
      },
      hide: hideColumn
    },
    {
      name: t_('Client'),
      field: 'client.name',
      type: 'smartSelect',
      edit: {
        source: async () => {
          return await apiGet('clients')
        },
        id: 'id',
        field: 'name'
      }
    },
    {
      name: t_('Quantity'),
      field: 'quantity',
      numeric: true,
      defaultNewValue: 0,
      mandatory: true,

      render: (row, extraFunctions) => {
        return (
          row !== undefined && row.id === undefined && row.quantity === 0
            ? (
              <TextField
                value={quantityValue}
                onChange={(event) => {
                  setQuantityValue(event.target.value)
                }}
                helperText={' '}
                type='number'
                inputProps={{ min: 0 }}
              />)
            : (
              <div id={'quantity' + row.id} style={{ alignItems: 'center', display: 'flex' }}>
                {row.quantity}
                <AddElementPart
                  endpoint={createPartEndPoint}
                  partReference={row}
                  referenceName={row.name}
                  row={row}
                  disable={editing || disableRowActions.current}
                  onAlert={handleAlert(extraFunctions)}
                />
              </div>))
      }
    }
  ]

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('partReferences', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        if (row.client === undefined || Object.keys(row.client).length === 0) row.client = null
        if (row.defaultFixtureReference === undefined || Object.keys(row.defaultFixtureReference).length === 0) row.defaultFixtureReference = null
        row.quantity = quantityValue
        setQuantityValue(0)
        return apiPost('partReferences', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('partReferences/' + rows[0].id, rows)
        } else {
          return apiDelete('partReferencesMulti', rows)
        }
      }
    },
    custom: [
      {
        name: 'Documents',
        render: (row) => {
          return (
            <IconButton
              disabled={editing}
              onClick={() => {
                setSelectedRow(row)
                setShowDocument(true)
              }}
            >
              <TextSnippetOutlined />
            </IconButton>
          )
        }
      },
      {
        name: 'Add Part',
        render: (row) => {
          return (
            <IconButton
              disabled={!!editing}
              onClick={(event) => {
                setSelectedRow(row)
                setShowPartReference(true)
              }}
            >
              <ManageSearchOutlined />
            </IconButton>
          )
        }
      },
      {
        name: 'CheckList',
        render: (row) => (
          <IconButton
            onClick={() => {
              setSelectedRow(row)
              setShowCheckList(true)
            }}
            disabled={!row.workflow}
          >
            <Assignment />
          </IconButton>
        )
      },
      {
        name: 'Features',
        render: (row) => {
          return (
            <IconButton
              disabled={!!editing}
              onClick={(event) => {
                setSelectedRow(row)
                setShowFeatures(true)
              }}
            >
              <SquareFoot />
            </IconButton>
          )
        }
      }

    ]
  }

  const onEditIndexChanged = (editIndex, row) => {
    setHideColumn(false)
    if (editIndex === -1) {
      // Creating new element
      setHideColumn(true)
    } else if (editIndex >= 0) {
      // Editing element. Row will have data
      setCurrentEditingRow(row)
      setHideColumn(false)
    } else {
      setCurrentEditingRow(null)
    }
  }

  return (
    <div>
      <ChecklistPartReferenceDialog partReferenceId={selectedRow.id} open={showCheckList} onClose={() => setShowCheckList(false)} />
      <SmartDialog
        closeCallback={() => setShowDocument(false)}
        accept={false}
        cancel={false}
        close
        renderComponent={<Document reference={selectedRow} resourceType='PART_REFERENCE' />}
        setOpen={showDocument}
      />
      <SmartDialog
        closeCallback={() => setShowFeatures(false)}
        accept={false}
        cancel={false}
        close
        renderComponent={<Features partReference={selectedRow} />}
        setOpen={showFeatures}
      />
      <SmartDialog
        renderComponent={<Part partReference={selectedRow} />}
        setOpen={showPartReference}
        cancel={false}
        accept={false}
        close
        closeCallback={() => { setShowPartReference(false) }}
      />
      <SmartMateriaUITable
        columns={columns}
        title={t_('Parts')}
        dataFetch='partReferences'
        actions={actions}
        multipleSelection
        editingRow={(event) => setEditing(event)}
        onEditIndexChanged={onEditIndexChanged}
        setDisableRowActions={(event) => disableRowActions.current === event}
        importFile={[{
          bean: {
            beanDtoName: 'ImportPartReference', beanName: 'PartReference', beanPackage: 'com.smartpm.sun.dto.', servicePackage: 'com.smartpm.sun.dbservices.', serviceName: 'PartReferenceService'
          },
          name: t_('Part Reference')
        },
        { bean: { beanDtoName: 'ImportPart', beanName: 'Part', beanPackage: 'com.smartpm.sun.dto.', servicePackage: 'com.smartpm.sun.dbservices.', serviceName: 'PartService' }, name: t_('Part') }

        ]}
      />
    </div>
  )
}

export default Reference
