import { Fab, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const GenericFloatingButton = (props) => {
  const {
    onClick,
    color,
    opacity = 1,
    label,
    icon,
    disabled = false
  } = props

  return (
    <Fab
      disabled={disabled}
      onClick={onClick}
      variant='extended'
      sx={{
        backgroundColor: theme => theme.palette.background.button,
        color: disabled ? theme => theme.palette.text.disabled : theme => theme.palette.text.primary,
        border: `1px solid ${disabled ? theme => theme.palette.text.disabled : color}`,
        '&:hover': {
          backgroundColor: theme => theme.palette.background.buttonHover
        },
        opacity
      }}
    >
      <Typography color={disabled ? theme => theme.palette.text.disabled : color} style={{ marginRight: '0.3em', fontSize: '0.875rem' }}>{label}</Typography>
      {icon}
    </Fab>

  )
}

GenericFloatingButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.any.isRequired,
  opacity: PropTypes.number,
  label: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  disabled: PropTypes.bool
}

export default GenericFloatingButton
