import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'



const MachinedPartsHistory = (props) => {

  const { parts } = props
  const [dataChart, setDataChart] = useState([])

  useEffect(() => {
    const handleLabels = () => {
      let allReferences = [];
      let countingArray = [];
      let partCount = 0;

      parts.forEach((part) => {
        if (part !== undefined && part.operationResult !== null) {
          if (part.operationResult === "OK") {
            partCount++
          }
        }
      })
      props.handleTotalPart(partCount)

      let partOkCount = 0;
      for (let index = 0; index < parts.length; index++) {
        let partReference = parts[index].partReference;

        if (!allReferences[partReference.id]) {
          allReferences[partReference.id] = partReference;
        }
        if (!countingArray[partReference.id]) {
          let dataCount = {
            total: 0,
            fail: 0,
            ok: 0,
            name: partReference.name
          }
          countingArray[partReference.id] = dataCount
        }
        countingArray[partReference.id].total++;

        if (parts[index].operationResult === "FAIL") {
          countingArray[partReference.id].fail++;
        } else if (parts[index].operationResult === "OK") {
          countingArray[partReference.id].ok++;
          partOkCount++;
        }
      }

      //Set OK percentage
      props.handleSuccessPartPercentage(partCount === 0 ? "" : Math.round(partOkCount * 100 / partCount))

      let datasets = { fail: [], ok: [], label: [] };
      countingArray.forEach((countItem) => {
        datasets.fail.push(countItem.fail)
        datasets.ok.push(countItem.ok)
        datasets.label.push(countItem.name)

      })

      setDataChart({
        datasets: datasets,
      });
    }

    handleLabels()

    return () => {

    }
  }, [parts, props])

  const options = {
    scaleFontColor: "red",
    responsive: true,
    tooltips: {
      mode: "single",
    },
    title: {
      display: false,
      text: "CELL PARTS",
      fontColor: "#fff",
      fontSize: 20,
    },
    scales: {
      x:
      {
        gridLines: {
          display: true,
        },
        // stacked: true,
        ticks: {
          fontColor: "#fff", // this here
        },
      },
      y:
      {
        display: true,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          suggestedMax: parts.length,
          fontColor: "#fff", // this here
        },
        gridLines: {
          display: true,
        },
      },
    },
  };



  return (
    <div className="App">

      <Bar
        data={{
          labels: dataChart.length === 0 ? null : dataChart.datasets.label,
          responsive: true,
          offset: true,
          datasets: [
            {
              label: "OK",
              data: dataChart.length === 0 ? null : dataChart.datasets.ok, //From API
              barThickness: 20,
              backgroundColor: "rgb(126,225,167,0.2)",
              borderColor: "rgba(126,225,167,1)",
              borderWidth: 1,
              hoverBackgroundColor: "rgb(126,225,167,0.4)",
              hoverBorderColor: "rgb(126,225,167)",

            },
            {
              label: "FAIL",
              data: dataChart.length === 0 ? null : dataChart.datasets.fail, //From API
              barThickness: 20,
              backgroundColor: "rgba(255,99,132,0.2)",
              borderColor: "rgba(255,99,132,1)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
            }
          ]
        }}
        options={options}

        height={220}
      >
      </Bar>
    </div >
  )
}

export default MachinedPartsHistory
