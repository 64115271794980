import React from 'react'
import ToolReference from '../configuration/tools/ToolReference'
import SmartNavigator from '../generic/SmartNavigator'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import ToolTables from '../configuration/tools/ToolTables'

const MainToolManager = (props) => {
  const { t_ } = useSmartTranslation()
  const { activePath } = props
  const componentsInfo = [
    {
      title: t_('Tool'),
      component: <ToolReference />,
      to: '/resources/toolManager'
    },
    {
      title: t_('Tool Table'),
      component: <ToolTables />,
      to: '/resources/toolTables'
    }
  ]
  return <SmartNavigator componentsInfo={componentsInfo} selected={activePath} />
}

export default MainToolManager
