import { Badge, Stack } from "@mui/material";
import CompassButton from './../../Compass/Common/CompassButton/CompassButton';
import { useNavigate } from "react-router-dom";
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";
import { useUncheckedActions } from "../../generic/hooks/useUncheckedActions";
import actionBadgeStyle from "./ActionBadgeStyle";

const ActionCenterMenu = () => {

  const navigate = useNavigate();
  const { t_ } = useSmartTranslation();
  const uncheckedActions = useUncheckedActions();


  return (
    <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{ height: "80%", paddingLeft: "20%", paddingRight: "20%" }} >
      <Badge invisible={uncheckedActions <= 0} badgeContent={uncheckedActions} sx={{ ...actionBadgeStyle, "& .MuiBadge-badge": { fontSize: "2rem", height: "2rem", borderRadius: "2rem", transform: uncheckedActions > 0 ? "translate(0.4rem, -0.4rem)" : null } }}>
        <CompassButton
          label={t_("Pending Actions")}
          width="auto"
          height="auto"
          labelStyle={{ fontSize: '1.8rem' }}
          onClick={() => { navigate("/compass/process/action") }}
          sx={{ padding: "1.5rem" }}
        />
      </Badge>
      <CompassButton
        label={t_("Send Command")}
        width="auto"
        height="auto"
        labelStyle={{ fontSize: '1.8rem' }}
        onClick={() => { navigate("/compass/process/command") }}
        sx={{ padding: "1.5rem" }}
      />
    </Stack >
  );
};

export default ActionCenterMenu;