import React from "react";
import SmartNavigator from "../generic/SmartNavigator";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";
import IncidenceType from "../configuration/incidence/IncidenceType";
import Incidence from "../configuration/incidence/Incidence";

const MainIncidence = () => {
  const { t_ } = useSmartTranslation();
  const componentsInfo = [
    {
      title: t_("Incidence"),
      component: <Incidence />,
    },
    {
      title: t_("Incidence Types"),
      component: <IncidenceType />,
    }
  ];
  return <SmartNavigator componentsInfo={componentsInfo} />;
};
export default MainIncidence;