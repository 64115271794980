import React from 'react'
import SmartNavigator from '../generic/SmartNavigator'

import Programs from '../configuration/programs/Programs'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import JobLinker from '../configuration/jobLinker/JobLinker'

export default function Workflows () {
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('JOB LINKER'),
      component: <JobLinker />
    },
    {
      title: t_('PROGRAMS'),
      component: <Programs />
    }
  ]

  return <SmartNavigator componentsInfo={componentsInfo} />
}
