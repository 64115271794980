import { Box, Button, IconButton, MenuItem, Stack, TextField, Typography, useTheme, Checkbox, FormControlLabel, FormHelperText, Tooltip } from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import { apiGet, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartInterval } from '../../generic/hooks/useSmartInterval'
import GenericFloatingButton from '../../generic/buttons/floatingButtons/GenericFloatingButton'
import { AddCircleRounded, DeleteOutlineRounded, EditRounded, HistoryOutlined, InsertDriveFile, SaveRounded, Sort, WarningRounded, WindowRounded, ErrorRounded, ListAltOutlined, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { SmartDialog, SmartSaveDialog } from '../../generic/utilities/SmartDialog'
import createDialogTitle from '../../generic/utilities/dialogUtil'
import TaskListSorting from '../parts/TaskListSorting'
import AlertUI from '../../generic/AlertUI'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import SmartSelect from '../../generic/smartSelect/SmartSelect'
import RestoreIcon from '@mui/icons-material/Restore'
import SaveIcon from '@mui/icons-material/Save'
import TaskToolInfo from '../parts/tasktoolInfo/TaskToolInfo'
import { CheckCorrectTimeFormat, GetUtcTime } from '../../generic/DateTimeFunctions'
import WarningIcon from '@mui/icons-material/Warning'
import { SmartReactflow } from '../workflows/smartreactflow'
import JobLinkerNode from './JobLinkerNode'
import { useEdgesState, useNodesState } from 'reactflow'
import getWorkflowNodesAndEdges from '../workflows/utils'
import { useToggle } from '../../generic/hooks/useToggle'

// Import typedefs from another file
/**
 * @typedef {import('../../generic/utilities/objectStructures/JobLinkerWindow/jobLinker').EditingData} EditingData EditingData object
 * @typedef {import('../../generic/utilities/objectStructures/JobLinkerWindow/jobLinker').EditingDataAction} EditingDataAction EditingDataAction object
 * @typedef {import('../../generic/utilities/objectStructures/JobLinkerWindow/jobLinker').ProgramEditActionData} ProgramEditActionData ProgramEditActionData object
 * @typedef {import('../../generic/utilities/objectStructures/JobLinkerWindow/jobLinker').ProgramEditActionData} ProgramEditActionData ProgramEditActionData object
 * @typedef {import('../../generic/utilities/objectStructures/JobLinkerWindow/jobLinker').ProgramEditData} ProgramEditData ProgramEditData object
 */

const JobLinker = () => {
  const [partReferenceList, setPartReferenceList] = useState([])
  const [workflowList, setWorkflowList] = useState([])
  const [toolReferences, setToolReferenceList] = useState([])
  const [currentPartReferenceId, setCurrentPartReferenceId] = useState('')
  const [currentWorkflowId, setCurrentWorkflowId] = useState('')
  const [openSaveDialog, setOpenSaveDialog] = useState(false)
  const [openRevertChangesDialog, setOpenRevertChangesDialog] = useState(false)
  const [openChangeOrderDialog, setOpenChangeOrderDialog] = useState(false)
  const [openDeleteTaskConfirmDialog, setOpenDeleteTaskConfirmDialog] = useState(false)
  const [openCheckTaskProgramsDialog, setOpenCheckTaskProgramsDialog] = useState(false)
  const [openToolRequiredDialog, setOpenToolRequiredDialog] = useState(false)
  const [openCheckJobToolsDialog, setOpenCheckJobToolsDialog] = useState(false)
  const [openTaskToolInfoDialog, setOpenTaskToolInfoDialog] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [smartTableEditIndex, setSmartTableEditIndex] = useState(-1)
  const [warningDialogEditing, setWarningDialogEditing] = useState(false)
  const [editingTaskName, setEditingTaskName] = useState(null)
  const [editingTheoreticalTime, setEditingTheoreticalTime] = useState(null)
  const [editingToolReferences, setEditingToolReferences] = useState(null)
  const theme = useTheme()
  const [AlertElement, showAlert] = AlertUI()
  const { t_ } = useSmartTranslation()
  const [nextSelectedPartReferenceId, setNextSelectedPartReferenceId] = useState('')
  const [viewTaskTable, setViewTaskTable] = useState(false)
  const [collapsedTaskTable, setCollapsedTaskTable] = useState(false)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges] = useEdgesState([])
  const [fitView, toogleFitView] = useToggle()

  /** Method used to update editingData variable
   * @param {EditingData} state current editing data object
   * @param {EditingDataAction} action New values to be updated on programEditData object
   * @returns state object
   */
  const onDispatchEditingData = (state, action) => {
    const newState = { ...state }
    if (action.type !== 'SET_CURRENT_JOB_REFERENCE_BAR_DATA' && action.type !== 'SET_PART_REFERENCE_JOB_REFERENCE') {
      newState.edited = true
    }
    switch (action.type) {
      case 'SET_PART_REFERENCE_JOB_REFERENCE':
        newState.currentPartReferencejobReferenceList = action.currentPartReferencejobReferenceList
        newState.currentPartReferencejobReferenceList.forEach((partReferenceJobReference) => { partReferenceJobReference.jobReference.currentSelectedRule = null })
        return newState
      case 'SET_CURRENT_SORTING_TASK_LIST':
        newState.currentSortingTaskList = action.currentSortingTaskList.sort((a, b) => a.taskOrder - b.taskOrder)
        return newState
      case 'SET_CURRENT_TASK':
        newState.currentTask = action.currentTask
        return newState
      case 'SET_CURRENT_PART_REFERENCE_JOB_REFERENCE_INDEX':
        newState.currentPartReferenceJobReferenceIndex = action.currentPartReferenceJobReferenceIndex
        return newState
      case 'SET_CURRENT_EDITING_TASK_PROGRAM':
        newState.currentEditingTaskProgram = action.currentEditingTaskProgram
        return newState
      case 'SET_CURRENT_JOB_REFERENCE_BAR_DATA':
        newState.currentJobReferenceDataList = action.currentJobReferenceDataList
        return newState
      case 'ADD_TASK_LIST': {
        const copyCurrentPartReferencejobReference = { ...newState.currentPartReferencejobReferenceList[newState.currentPartReferenceJobReferenceIndex] }
        copyCurrentPartReferencejobReference.taskReferenceList = copyCurrentPartReferencejobReference.taskReferenceList.concat(action.newTaskList)
        newState.currentPartReferencejobReferenceList = [...newState.currentPartReferencejobReferenceList]
        newState.currentPartReferencejobReferenceList[newState.currentPartReferenceJobReferenceIndex] = copyCurrentPartReferencejobReference
        return newState
      }
      case 'UPDATE_JOB_REFERENCE_TASK_LIST_SORTING':
        newState.currentPartReferencejobReferenceList[newState.currentPartReferenceJobReferenceIndex].taskReferenceList = newState.currentSortingTaskList
        return newState
      case 'UPDATE_JOB_REFERENCE_TASK': {
        const taskIndex = newState.currentPartReferencejobReferenceList[newState.currentPartReferenceJobReferenceIndex].taskReferenceList.findIndex(taskReference => taskReference.taskOrder === action.currentTask.taskOrder)
        newState.currentPartReferencejobReferenceList[newState.currentPartReferenceJobReferenceIndex].taskReferenceList[taskIndex] = action.currentTask
        return newState
      }
      case 'UPDATE_JOB_REFERENCES_BAR_DATA': {
        const copyCurrentJobReferenceDataList = [...newState.currentJobReferenceDataList]
        copyCurrentJobReferenceDataList[newState.currentPartReferenceJobReferenceIndex] = newState.currentJobReferenceDataList[newState.currentPartReferenceJobReferenceIndex]
        newState.currentJobReferenceDataList = copyCurrentJobReferenceDataList
        return newState
      }
      case 'DELETE_CURRENT_TASK': {
        const index = newState.currentPartReferencejobReferenceList[newState.currentPartReferenceJobReferenceIndex].taskReferenceList.findIndex(taskReference => taskReference.taskOrder === newState.currentTask.taskOrder)
        const taskReferenceListNew = [...newState.currentPartReferencejobReferenceList[newState.currentPartReferenceJobReferenceIndex].taskReferenceList]
        newState.currentPartReferencejobReferenceList = [...newState.currentPartReferencejobReferenceList]
        newState.currentPartReferencejobReferenceList[newState.currentPartReferenceJobReferenceIndex] = { ...newState.currentPartReferencejobReferenceList[newState.currentPartReferenceJobReferenceIndex] }
        taskReferenceListNew.splice(index, 1)
        newState.currentPartReferencejobReferenceList[newState.currentPartReferenceJobReferenceIndex].taskReferenceList = taskReferenceListNew
        return newState
      }
      case 'CHANGE_JOB_REFERENCE_RULE':
        newState.currentPartReferencejobReferenceList[action.currentPartReferenceJobReferenceIndex].rule = action.currentSelectedRule
        return newState
      case 'SET_CURRENT_PART_REFERENCE_JOB_REFERENCE_INDEX_BY_JOB_ID': {
        const index = newState.currentPartReferencejobReferenceList.findIndex((partReferenceJobReference) => partReferenceJobReference.jobReference.id === action.jobReferenceId)
        newState.currentPartReferenceJobReferenceIndex = index
        return newState
      }
      default:
        return newState
    }
  }

  /** @type {[EditingData, React.Dispatch<EditingDataAction>]} */
  const [editingData, dispatchEditingData] = useReducer(onDispatchEditingData,
    {
      currentPartReferencejobReferenceList: [],
      currentSortingTaskList: [],
      currentTask: {},
      currentEditingTaskProgram: null,
      currentJobReferenceDataList: [],
      edited: false
    }
  )

  /** Method used to update programEditData variable
   * @param {ProgramEditActionData} action New values to be updated on programEditData object
   * @param {ProgramEditData} state Current programEditData object
  */
  const onDispatchProgramEdit = (state, action) => {
    const newState = { ...state }
    switch (action.type) {
      case 'OPEN':
        newState.dialogMultipleMode = action.modeMultiple
        newState.currentProgramList = action.programs
        newState.open = true
        return newState
      case 'CLOSE':
        newState.open = false
        return newState
      case 'SET_PROGRAMS':
        newState.currentSelectedPrograms = action.currentSelectedPrograms
        return newState
      default:
        return state
    }
  }

  /** @type {[ProgramEditData, React.Dispatch<ProgramEditActionData>]} */
  const [programEditData, dispatchProgramEdit] = useReducer(onDispatchProgramEdit,
    {
      currentProgramList: [],
      currentSelectedPrograms: null,
      dialogMultipleMode: false,
      open: false
    }
  )

  /**
   * Method used to get last partReferences and workflows objectw from the backend
   * @returns
   */
  const getIntervalData = async () => {
    const partReferences = await apiGet('partReferencesShortData')
    const workflows = await apiGet('workflowsAllVersionsSimpleData')
    return {
      partReferences,
      workflows
    }
  }

  /** Method used to setnew objects retrieved on getIntervalData method
   * @param {Object} intervalData object returned on getIntervalData method
   * @param {Object[]} intervalData.partReferences partReference list object
   * @param {Object[]} intervalData.workflows workflows list object
   * @param {Object[]} intervalData.toolReferences toolReference list object
   */
  const setIntervalData = (intervalData) => {
    setPartReferenceList(intervalData.partReferences)
    setWorkflowList(intervalData.workflows)
  }

  const startFetchInterval = useSmartInterval(getIntervalData, 5000, setIntervalData)

  /**
   * Use effect to retrieve partReferencesJobReference from the backend every time currentPartReferenceId value is changed
   */
  const setCurrentPartReference = (newPartReferenceId) => {
    const loadJobReferencePartReference = async () => {
      setViewTaskTable(false)
      setCollapsedTaskTable(false)
      const partReferencejobReferenceList = await apiGet('partReferencesJobReference/' + newPartReferenceId)
      dispatchEditingData({ type: 'SET_PART_REFERENCE_JOB_REFERENCE', currentPartReferencejobReferenceList: partReferencejobReferenceList })
      setCurrentWorkflow(partReferencejobReferenceList.length > 0 ? partReferencejobReferenceList[0].jobReference.workflow.id : '', newPartReferenceId, partReferencejobReferenceList)
    }
    setCurrentPartReferenceId(newPartReferenceId)

    if (newPartReferenceId !== null && newPartReferenceId !== '') {
      loadJobReferencePartReference()
    }
  }

  /**
   * Mehod to change job reference checkbox value
   * @param {Number} index current job index number, is not required
   */
  const saveToolCheckbox = (index, isChecked) => {
    if (index !== -1) {
      const updatedJobReferenceData = [...editingData.currentJobReferenceDataList]
      updatedJobReferenceData[index].toolRequired = isChecked
      updatedJobReferenceData[index].jobToolQuantity = 0
      editingData.currentPartReferencejobReferenceList[index].taskReferenceList.forEach((task) => {
        task.toolRequired = false
        task.toolReferenceList = []
        dispatchEditingData({ type: 'UPDATE_JOB_REFERENCE_TASK', currentTask: task })
      })
      updateJobReferenceDataList(updatedJobReferenceData)
      setOpenCheckJobToolsDialog(false)
    }
  }

  const createNodeData = (jobReference, jobs, partReferencesJobReferences, ruleList) => {
    const currentJobReferenceData = jobs?.find((jobReferenceData) => jobReferenceData.job?.jobReference?.id === jobReference.id)
    const partReferenceJobReference = partReferencesJobReferences?.find((partReferenceJobReference) => partReferenceJobReference.jobReference.id === jobReference.id)
    const index = partReferencesJobReferences?.findIndex((partReferenceJobReference) => partReferenceJobReference.jobReference.id === jobReference.id)
    return {
      jobName: jobReference.name,
      technologyNane: jobReference.technology.name,
      editMode,
      toolQuantity: currentJobReferenceData?.jobToolQuantity,
      toolRequired: currentJobReferenceData?.toolRequired,
      totalTime: currentJobReferenceData?.jobTotalTime,
      visible: false,
      ruleList,
      currentRule: partReferenceJobReference?.rule,
      dispatchEditingData,
      jobReferenceId: jobReference.id,
      partReferenceJobReferenceIndex: index
    }
  }

  useEffect(() => {
    if (!(smartTableEditIndex >= 0)) {
      setEditingTaskName(null)
      setEditingTheoreticalTime(null)
      setEditingToolReferences(null)
    }
  }, [smartTableEditIndex])

  const setCurrentWorkflow = async (newWorkflowId, partReferenceId, partReferencesJobReferences) => {
    const loadJobs = async (workflowId) => {
      const workflow = await apiGet('workflows/' + workflowId)
      const ruleList = await apiGet('rules/' + partReferenceId)
      const result = getWorkflowNodesAndEdges({ workflow, technologyNodeData: (jobReference, position) => createNodeData(jobReference, getJobReferenceDataList(partReferencesJobReferences), partReferencesJobReferences, ruleList), isConnectable: false, edgeCardVisible: false })
      setNodes(result.nodes)
      setEdges(result.edges)
      setToolReferenceList(await apiGet('toolReferencesByWorkflow/' + newWorkflowId))
      toogleFitView()
    }

    setCurrentWorkflowId(newWorkflowId)

    if (newWorkflowId === '' || newWorkflowId === null) {
      setNodes([])
      setEdges([])
      return
    }
    await loadJobs(newWorkflowId)
  }

  useEffect(() => {
    let changedValue = false
    const newNodeValues = nodes.map((node) => {
      if (node.data.editMode !== editMode && !changedValue) {
        changedValue = true
      }
      const data = { ...node.data }
      data.editMode = editMode
      node.data = data
      return node
    })
    if (!changedValue) {
      return
    }
    setNodes(newNodeValues)
  }, [editMode, setNodes, nodes])

  /**
   * Method used to save all data changed.
   */
  const saveJobLinker = async () => {
    getJobReferenceDataList(editingData.currentPartReferencejobReferenceList)
    apiPut('partReferencesJobReference', editingData.currentPartReferencejobReferenceList).then((data) => {
      showAlert({
        title: t_('Successfully saved'),
        severity: 'success'

      })
      getSavedData()
    }).catch((error) => {
      showAlert({
        message: error.message,
        severity: error.name
      })
    })
    setEditMode(false)
    setOpenSaveDialog(false)
    setOpenCheckTaskProgramsDialog(false)
  }

  /**
   * Method used to check if there are tasks without programs
   * and if there are tasks without tools
   * before saving data
   */
  const checkTaskPrograms = () => {
    let taskWithoutProgram = false
    let taskWithoutTool = false
    editingData.currentPartReferencejobReferenceList.forEach((partReferenceJobReference) => {
      partReferenceJobReference.taskReferenceList.forEach((taskReference) => {
        if (taskReference.toolRequired && taskReference.toolReferenceList.length === 0) {
          taskWithoutTool = true
        }
        if (taskReference.defaultProgram === null) {
          taskWithoutProgram = true
        }
      })
    })
    if (taskWithoutTool) {
      setOpenToolRequiredDialog(true)
    } else if (taskWithoutProgram) {
      setOpenCheckTaskProgramsDialog(true)
    } else {
      saveJobLinker()
      setCurrentPartReference(nextSelectedPartReferenceId)
      setOpenSaveDialog(false)
    }
  }

  /**
   * Method used to create new PartReferenceJobReference structure
   * @param {number} workflowId worklow id
   */
  const createNewPartReferenceJobReference = async (workflowId) => {
    const jobReferenceList = await apiGet('jobReferencesByWorkflow/' + workflowId)
    const newObject = []
    jobReferenceList.forEach(jobReference => {
      newObject.push({
        id: null,
        partReference: { id: currentPartReferenceId },
        jobReference,
        taskReferenceList: [{ taskOrder: 1, theoreticalTime: '00:00:00', toolRequired: false, toolReferenceList: [], defaultProgram: null, featureList: [], taskToolInfoDto: { parsed: false, taskToolInfoList: [] } }]
      })
    })
    getJobReferenceDataList(jobReferenceList)
    dispatchEditingData({ type: 'SET_PART_REFERENCE_JOB_REFERENCE', currentPartReferencejobReferenceList: newObject })
    return newObject
  }

  /**
   * Method used to update program from the current task
   * @param {Object} currentTaskRow current Task object
   * @param {*} currentJob current Job object
   * @param {number} currentPartReferenceJobReferenceIndex  current PartReferenceJobReference index number
   */
  const changeProgram = async (currentTaskRow, currentJob, currentPartReferenceJobReferenceIndex) => {
    const programList = await apiGet('programsByTechnology/' + currentJob.technology.id)
    dispatchProgramEdit({ type: 'OPEN', modeMultiple: false, programs: programList })
    dispatchEditingData({ type: 'SET_CURRENT_TASK', currentTask: currentTaskRow })
    dispatchEditingData({ type: 'SET_CURRENT_PART_REFERENCE_JOB_REFERENCE_INDEX', currentPartReferenceJobReferenceIndex })
  }

  /**
   * Change checkbox value on the current task
   * @param {Object} row current task object
   * @param {boolean} isChecked checkbox value
   */
  const changeCheckbox = (row, isChecked, partReferenceJobReferenceIndex) => {
    if (!isChecked) {
      row.toolReferenceList = []
    }

    row.toolRequired = isChecked
    dispatchEditingData({ type: 'SET_CURRENT_PART_REFERENCE_JOB_REFERENCE_INDEX', currentPartReferenceJobReferenceIndex: partReferenceJobReferenceIndex })
    dispatchEditingData({ type: 'UPDATE_JOB_REFERENCE_TASK', currentTask: row })
  }

  /**
   * Change values of toolReferenceList on the current task
   * @param {Object} row current task object
   * @param {Object[]} newToolSelectedValues selected tool list
   */
  const changeToolReferenceList = (row, newToolSelectedValues) => {
    const updatedToolReferenceList = toolReferences.filter((tool) =>
      newToolSelectedValues.includes(tool.toolReferenceName)
    )
    row.toolReferenceList = updatedToolReferenceList
    setEditingToolReferences(updatedToolReferenceList)
    dispatchEditingData({ type: 'UPDATE_JOB_REFERENCE_TASK', currentTask: row })
  }

  const getSavedData = async () => {
    const partReferencejobReferenceList = await apiGet('partReferencesJobReference/' + currentPartReferenceId)
    dispatchEditingData({ type: 'SET_PART_REFERENCE_JOB_REFERENCE', currentPartReferencejobReferenceList: partReferencejobReferenceList })
    let workflowId = partReferencejobReferenceList[0]?.jobReference?.workflow?.id
    if (workflowId === undefined || workflowId === null) {
      workflowId = ''
    }
    setCurrentWorkflow(workflowId, currentPartReferenceId, partReferencejobReferenceList)
  }

  /**
   * Method used to create a SmartTable componetent on the given PartReferencejobReference
   * @param {Object} currentPartReferencejobReference current PartReferencejobReference object
   * @param {Number} index current PartReferencejobReference index number
   * @returns
   */
  const addSmartTable = (currentPartReferencejobReference, index) => {
    const columns = [
      {
        name: t_('Task order'),
        field: 'taskOrder',
        readOnly: true
      },
      {
        name: t_('Task name'),
        field: 'name',
        render: (row) => {
          if (row['smart-table-index'] === smartTableEditIndex && editingData.currentPartReferenceJobReferenceIndex === index) {
            return (
              <TextField
                value={editingTaskName}
                helperText={' '}
                disabled={!(smartTableEditIndex >= 0 && editingData.currentPartReferenceJobReferenceIndex === index)}
                variant='outlined'
                onChange={(event) => { setEditingTaskName(event.target.value) }}
              />
            )
          }
          return (
            <Typography>{row.name}</Typography>
          )
        }
      },
      {
        name: t_('Duration') + '(hh:mm:ss)',
        field: 'theoreticalTime',
        mandatory: true,
        placeholder: 'hh:mm:ss',
        render: (row) => {
          if (row['smart-table-index'] === smartTableEditIndex && editingData.currentPartReferenceJobReferenceIndex === index) {
            const checkTime = CheckCorrectTimeFormat(editingTheoreticalTime, t_)
            return (
              <TextField
                value={editingTheoreticalTime}
                disabled={!(smartTableEditIndex >= 0 && editingData.currentPartReferenceJobReferenceIndex === index)}
                variant='outlined'
                onChange={(event) => { setEditingTheoreticalTime(event.target.value) }}
                error={checkTime}
                label={editingTheoreticalTime === ''
                  ? 'Requiered *'
                  : checkTime
                    ? (
                      <Tooltip placement='top' title={checkTime}>
                        <WarningIcon />
                      </Tooltip>
                      )
                    : ''}
                helperText={' '}
              />
            )
          }
          return <Typography>{row.theoreticalTime}</Typography>
        }
      },
      {
        name: t_('Tool'),
        field: 'toolReferenceList.name',
        render: (row) => {
          if (row['smart-table-index'] === smartTableEditIndex && editingData.currentPartReferenceJobReferenceIndex === index) {
            return (
              <SmartSelect
                multiple
                value={editingToolReferences ? editingToolReferences.map((tool) => tool.toolReferenceName) : []}
                selectableOptions={toolReferences.filter((toolReference) => toolReference.technologyList.some(technology => technology.id === currentPartReferencejobReference.jobReference.technology.id)).map((tool) => tool.toolReferenceName)}
                onChange={(newToolSelectedValues) => { changeToolReferenceList(row, newToolSelectedValues) }}
                helperText=' '
              />
            )
          }

          return (
            <div key={'check' + row.id} style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={row.toolRequired}
                    onChange={(e) => changeCheckbox(row, e.target.checked, index)}
                    disabled={!editMode}
                  />
                }
              />
              <SmartSelect
                readOnly
                multiple
                value={row.toolRequired && row.toolReferenceList ? row.toolReferenceList.map((tool) => tool.toolReferenceName) : []}
              />
            </div>
          )
        }

      },

      {
        name: t_('Program'),
        field: 'defaultProgram.name',
        readOnly: true,
        render: (row) => {
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {row['smart-table-index'] === smartTableEditIndex && editingData.currentPartReferenceJobReferenceIndex === index ? editingData.currentEditingTaskProgram?.name : row.defaultProgram?.name}
                {smartTableEditIndex >= 0 && editingData.currentPartReferenceJobReferenceIndex === index
                  ? (
                    <IconButton onClick={() => changeProgram(row, currentPartReferencejobReference.jobReference, index)}>
                      <InsertDriveFile />
                    </IconButton>)
                  : null}
              </div>
              <FormHelperText>{' '}</FormHelperText>
            </>
          )
        }
      },
      {
        name: t_('Feature'),
        field: 'featureList.name',
        type: 'smartMultiSelect',
        edit: {
          source: async () => {
            return await apiGet('taskReferenceFeatures/' + editingData.currentPartReferencejobReferenceList[index].partReference.id)
          },
          id: 'id',
          field: 'name'
        }
      }
    ]

    const defaultToolbar = {
      render: {
        fetchFunction: () => {
          return (
            <>
              <Box sx={{ flexGrow: 1 }}><IconButton sx={{ borderRadius: 0, paddingLeft: 0, paddingRight: 0 }} onClick={() => setCollapsedTaskTable(true)}><KeyboardArrowDown /></IconButton></Box>
              <Button
                disabled={!editMode}
                onClick={() => {
                  dispatchEditingData({ type: 'SET_CURRENT_PART_REFERENCE_JOB_REFERENCE_INDEX', currentPartReferenceJobReferenceIndex: index })
                  dispatchEditingData({ type: 'SET_CURRENT_SORTING_TASK_LIST', currentSortingTaskList: JSON.parse(JSON.stringify(editingData.currentPartReferencejobReferenceList[index]?.taskReferenceList)) })
                  setOpenChangeOrderDialog(true)
                }}
              >
                <Sort sx={{ fontSize: '1.2em' }} />
                <Typography sx={{ marginLeft: '0.4em', fontSize: '0.875rem' }}>{t_('Change order')}</Typography>
              </Button>
              <Button
                disabled={!editMode}
                onClick={() => {
                  dispatchEditingData({ type: 'SET_CURRENT_PART_REFERENCE_JOB_REFERENCE_INDEX', currentPartReferenceJobReferenceIndex: index })
                  openAddProgram(currentPartReferencejobReference.jobReference)
                }}
              >
                <AddCircleRounded sx={{ fontSize: '1.2em' }} />
                <Typography sx={{ marginLeft: '0.4em', fontSize: '0.875rem' }}>{t_('Add program')}</Typography>
              </Button>
            </>
          )
        }
      }
    }

    const actions = {
      edit: {
        fetchFunction: (row) => {
          row.defaultProgram = editingData.currentEditingTaskProgram
          row.theoreticalTime = editingTheoreticalTime
          row.name = editingTaskName
          row.toolReferenceList = editingToolReferences
          delete row['smart-table-index']
          dispatchEditingData({ type: 'UPDATE_JOB_REFERENCE_TASK', currentTask: row })
          updateJobReferenceDataList(editingData.currentJobReferenceDataList)
        },
        condition: () => {
          return editMode
        }
      },
      custom: [
        {
          name: 'Delete',
          render: (row) => {
            return (
              <IconButton
                disabled={!editMode || smartTableEditIndex >= 0}
                onClick={() => {
                  dispatchEditingData({ type: 'SET_CURRENT_PART_REFERENCE_JOB_REFERENCE_INDEX', currentPartReferenceJobReferenceIndex: index })
                  dispatchEditingData({ type: 'SET_CURRENT_TASK', currentTask: row })
                  setOpenDeleteTaskConfirmDialog(true)
                }}
              >
                <DeleteOutlineRounded />
              </IconButton>
            )
          }
        },
        {
          name: 'taskToolInfo',
          render: (row) => {
            return (
              <IconButton
                disabled={!editMode || smartTableEditIndex >= 0}
                onClick={() => {
                  dispatchEditingData({ type: 'SET_CURRENT_PART_REFERENCE_JOB_REFERENCE_INDEX', currentPartReferenceJobReferenceIndex: index })
                  dispatchEditingData({ type: 'SET_CURRENT_TASK', currentTask: row })
                  setOpenTaskToolInfoDialog(true)
                }}
              >
                <ListAltOutlined />
              </IconButton>
            )
          }
        }
      ]
    }

    return (
      <SmartMateriaUITable
        fetchInterval={1}
        columns={columns}
        dataFetch={currentPartReferencejobReference.taskReferenceList}
        actions={actions}
        toolbar={defaultToolbar}
        multipleOptions
        disableFlexGrow
        dense
        tablePageSize={3}
        onEditIndexChanged={(tableIndex, row) => {
          setSmartTableEditIndex(tableIndex)
          dispatchEditingData({ type: 'SET_CURRENT_PART_REFERENCE_JOB_REFERENCE_INDEX', currentPartReferenceJobReferenceIndex: index })
          dispatchEditingData({ type: 'SET_CURRENT_EDITING_TASK_PROGRAM', currentEditingTaskProgram: row?.defaultProgram })
          setEditingTheoreticalTime(row?.theoreticalTime)
          setEditingTaskName(row?.name)
          setEditingToolReferences(row?.toolReferenceList)
        }}
      />
    )
  }

  /**
   * Method used to open Add program dialog
   * @param {Object} jobReference current JobReference object
   */
  const openAddProgram = async (jobReference) => {
    const programList = await apiGet('programsByTechnology/' + jobReference.technology.id)
    dispatchProgramEdit({ type: 'OPEN', modeMultiple: true, programs: programList })
  }

  /**
   * Method used to revert all unsaved changes
   */
  const revertChanges = async () => {
    const partReferencejobReferenceList = await apiGet('partReferencesJobReference/' + currentPartReferenceId)
    dispatchEditingData({ type: 'SET_PART_REFERENCE_JOB_REFERENCE', currentPartReferencejobReferenceList: partReferencejobReferenceList })
    await getSavedData()
    showAlert({
      title: t_('Successfully reverted'),
      severity: 'success'
    })
    setOpenRevertChangesDialog(false)
    setEditMode(false)
  }

  const revertAndChangePartReference = async () => {
    await revertChanges()
    setWarningDialogEditing(false)
    setCurrentPartReference(nextSelectedPartReferenceId)
  }

  /**
   * Method used to add program on the current PartReferenceJobReference (it will create a new task)
   */
  const addProgram = async () => {
    const programIdList = []
    programEditData.currentSelectedPrograms.forEach(program => { programIdList.push(parseInt(program)) })
    const programList = await apiGet('programLists', { idList: programIdList })
    const newTasks = []

    let nextOrder = editingData.currentPartReferencejobReferenceList[editingData.currentPartReferenceJobReferenceIndex]?.taskReferenceList.length + 1
    const operationList = await apiGet('getOperationByProgramIds/', { idList: programIdList })

    programList.forEach((program) => {
      let theoreticalTime = 0
      const toolReferenceDict = {}
      // Filter operations by program
      const operationListCurrentProgram = operationList.filter((operation) => {
        return operation.program.id === program.id
      })
      operationListCurrentProgram.forEach(operation => {
        theoreticalTime += operation.duration
        // Check that toolReference is not allready there
        if (!(operation.toolReference.toolReferenceId in toolReferenceDict)) {
          toolReferenceDict[operation.toolReference.toolReferenceId] = operation.toolReference
        }
      })
      const toolReferenceList = Object.values(toolReferenceDict)
      const taskToolInfoList = []
      toolReferenceList.forEach((toolReference) => {
        taskToolInfoList[taskToolInfoList.length] = getTaskToolInfo(toolReference, operationListCurrentProgram)
      })
      const taskToolInfoDto = { parsed: operationListCurrentProgram.length > 0, taskToolInfoList }

      theoreticalTime = GetUtcTime(parseInt(theoreticalTime))
      const newObject = { taskOrder: nextOrder, name: program.description, theoreticalTime, toolReferenceList, toolRequired: toolReferenceList.length > 0, defaultProgram: program, featureList: [], taskToolInfoDto }
      newTasks.push(newObject)
      nextOrder += 1
    }

    )
    dispatchEditingData({ type: 'ADD_TASK_LIST', newTaskList: newTasks })
    dispatchProgramEdit({ type: 'CLOSE' })
  }

  /**
   * Method used to update taskOrder field on a given task list
   * @param {Object[]} newTaskListSort Task list
   */
  const changeTaskSorting = (newTaskListSort) => {
    newTaskListSort.forEach((newTask, index) => {
      newTask.taskOrder = index + 1
    })
  }

  /**
   * Method used to update current task list sorting changes
   */
  const acceptSortingDialog = () => {
    dispatchEditingData({ type: 'UPDATE_JOB_REFERENCE_TASK_LIST_SORTING' })
    setOpenChangeOrderDialog(false)
  }

  /**
   * Method used to delete current task
   */
  const deleteTask = () => {
    dispatchEditingData({ type: 'DELETE_CURRENT_TASK' })
    setOpenDeleteTaskConfirmDialog(false)
  }
  const getTaskToolInfo = (toolReference, operations) => {
    let diameter = ''
    let length = ''
    let duration = 0
    operations.forEach((operation) => {
      if (operation.toolReference.toolReferenceId === toolReference.toolReferenceId) {
        duration += operation.duration
        diameter = operation.diameter
        length = operation.minimumLength
      }
    })
    // TODO Consumption will be duration on program parse, this should be changed
    return { toolReferenceName: toolReference.toolReferenceName, duration: GetUtcTime(duration), consumption: duration, diameter, length }
  }
  /**
   * Method used to update current editing task program
   */
  const updateTaskProgram = async () => {
    dispatchEditingData({ type: 'SET_CURRENT_EDITING_TASK_PROGRAM', currentEditingTaskProgram: programEditData.currentSelectedPrograms })
    const operationList = await apiGet('getOperationByProgramIds/', { idList: [programEditData.currentSelectedPrograms.id] })
    if (operationList.length > 0) {
      let theoreticalTime = 0
      const toolReferenceDict = {}

      operationList.forEach((operation) => {
        theoreticalTime += operation.duration
        if (!(operation.toolReference.toolReferenceId in toolReferenceDict)) { toolReferenceDict[operation.toolReference.toolReferenceId] = operation.toolReference }
      })

      editingData.currentTask.duration = GetUtcTime(parseInt(theoreticalTime))
      editingData.currentTask.taskName = programEditData.currentProgramList.find((program) => program.id === programEditData.currentSelectedPrograms.id).description
      const toolReferenceList = Object.values(toolReferenceDict)

      // Get task tool info list for each tool reference
      const taskToolInfoList = []
      toolReferenceList.forEach((toolReference) => {
        taskToolInfoList[taskToolInfoList.length] = getTaskToolInfo(toolReference, operationList)
      })
      editingData.currentTask.taskToolInfoDto = { parsed: true, taskToolInfoList }

      setEditingToolReferences(toolReferenceList)
      setEditingTheoreticalTime(editingData.currentTask.duration)
      setEditingTaskName(editingData.currentTask.taskName)
    }
    dispatchEditingData({ type: 'UPDATE_JOB_REFERENCE_TASK', currentTask: editingData.currentTask })
    dispatchProgramEdit({ type: 'CLOSE' })
  }

  /**
   * Method used to get job references data for top bar(accordion)
   * @param {Object[]} partReferencejobReferenceList jobReferences list of the current partReference
   */
  const getJobReferenceDataList = (partReferencejobReferenceList) => {
    let jobToolRequiredCount = 0
    let jobFormattedTime = '00:00:00'
    const jobCalculations = partReferencejobReferenceList?.map((job) => {
      if (job.taskReferenceList) {
        jobToolRequiredCount = job.taskReferenceList?.filter((task) => task.toolRequired).length

        const totalSeconds = job.taskReferenceList?.reduce((acc, task) => {
          const theoreticalTime = task.theoreticalTime || '00:00:00'
          const [hours, minutes, seconds] = theoreticalTime.split(':').map(Number)
          const taskSeconds = hours * 3600 + minutes * 60 + seconds
          return acc + taskSeconds
        }, 0)

        const jobTotalHours = Math.floor(totalSeconds / 3600)
        const remainingSeconds = totalSeconds % 3600
        const jobTotalMinutes = Math.floor(remainingSeconds / 60)
        const jobSeconds = remainingSeconds % 60

        function formatNumber (num) {
          return num.toString().padStart(2, '0')
        }

        jobFormattedTime = `${formatNumber(jobTotalHours)}:${formatNumber(jobTotalMinutes)}:${formatNumber(jobSeconds)}`
      }

      return {
        job,
        jobToolQuantity: jobToolRequiredCount,
        jobTotalTime: jobFormattedTime
      }
    })
    dispatchEditingData({ type: 'SET_CURRENT_JOB_REFERENCE_BAR_DATA', currentJobReferenceDataList: jobCalculations })
    return jobCalculations
  }

  /**
   * Method used to update current job reference top bar data
   * @param {Object[]} updatedJobReferenceDataList jobReference data list to be updated
   */
  const updateJobReferenceDataList = (updatedJobReferenceDataList) => {
    dispatchEditingData({ type: 'UPDATE_JOB_REFERENCES_BAR_DATA', currentJobReferenceDataList: updatedJobReferenceDataList })
  }

  // Function to check when stack is going sticky to apply css
  function handleScroll () {
    const stackElement = document.querySelector('.sticky-linker')
    if (stackElement) {
      const stackRect = stackElement.getBoundingClientRect()
      if (stackRect.top <= 0) {
        stackElement.classList.add('sticky-linker-css')
      } else {
        stackElement.classList.remove('sticky-linker-css')
      }
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const updateCurrentPartReferenceId = () => {
    if (nextSelectedPartReferenceId !== '') {
      setCurrentPartReference(nextSelectedPartReferenceId)
      setNextSelectedPartReferenceId('')
    }
  }

  return (
    <>
      <SmartDialog
        setOpen={openTaskToolInfoDialog}
        accept={false}
        cancel={false}
        close
        closeCallback={() => setOpenTaskToolInfoDialog(false)}
        renderComponent={<TaskToolInfo currentTask={editingData.currentTask} />}
      />
      <SmartDialog
        setOpen={openChangeOrderDialog}
        acceptCallback={acceptSortingDialog}
        cancelCallback={() => setOpenChangeOrderDialog(false)}
        title={createDialogTitle({ title: t_('Order tasks'), icon: Sort })}
        renderComponent={
          <TaskListSorting
            taskList={editingData.currentSortingTaskList}
            sortingChange={changeTaskSorting}
          />
        }
      />
      <SmartDialog
        setOpen={programEditData.open}
        cancelCallback={() => dispatchProgramEdit({ type: 'CLOSE' })}
        acceptCallback={() => {
          if (programEditData.dialogMultipleMode) {
            addProgram()
          } else {
            updateTaskProgram()
          }
        }}
        title={createDialogTitle({ title: t_('Add selected programs'), icon: InsertDriveFile })}
        renderComponent={
          <SmartMateriaUITable
            title={t_('Program')}
            dataFetch={programEditData.currentProgramList}
            columns={[{ name: t_('Name'), field: 'name' }]}
            deleteMultipleSelectionDisabled
            multipleSelectionActions={[]}
            multipleSelection={programEditData.dialogMultipleMode}
            multipleSelectionChange={(multipleSelection) => dispatchProgramEdit({ type: 'SET_PROGRAMS', currentSelectedPrograms: multipleSelection.rows.map(row => row.id) })}
            onRowClick={
              programEditData.dialogMultipleMode
                ? null
                : (row) => {
                    if (row.id) {
                      dispatchProgramEdit({ type: 'SET_PROGRAMS', currentSelectedPrograms: row })
                    }
                  }
            }
          />
        }
      />
      <SmartDialog
        setOpen={warningDialogEditing}
        title={createDialogTitle({ title: t_('SAVE?'), icon: SaveIcon, color: 'success' })}
        message={t_('Do you want to save changes before changing part reference?')}
        cancel={false}
        accept={false}
        renderCustomButtons={
          <>
            <Button
              color='error' onClick={() => {
                revertAndChangePartReference()
              }} variant='outlined'
            >
              <Typography color='error'>{t_('REVERT')}</Typography>
              <RestoreIcon sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
            </Button>
            <Button color='success' onClick={() => { checkTaskPrograms(); setWarningDialogEditing(false) }} variant='outlined'>
              <Typography color='success'>{t_('SAVE')}</Typography>
              <SaveIcon sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
            </Button>

          </>
        }
      />
      <SmartDialog
        setOpen={openDeleteTaskConfirmDialog} message={t_('Are you sure you want to delete this task?')} title={createDialogTitle({ title: t_('Delete?'), icon: WindowRounded, color: 'error' })}
        accept={false} cancelCallback={() => setOpenDeleteTaskConfirmDialog(false)} close={false} renderCustomButtons={
          <Button color='error' onClick={() => { deleteTask() }} variant='outlined'>
            <Typography color='error'>{t_('DELETE')}</Typography>
            <WindowRounded color='error' sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
          </Button>
        }
      />
      <SmartDialog
        setOpen={openRevertChangesDialog}
        message={t_('Are you sure you want to revert the changes?')}
        title={createDialogTitle({ title: t_('Revert?'), icon: WindowRounded, color: 'error' })}
        accept={false}
        cancelCallback={() => setOpenRevertChangesDialog(false)}
        close={false}
        renderCustomButtons={
          <Button color='error' onClick={() => { revertChanges() }} variant='outlined'>
            <Typography color='error'>{t_('REVERT')}</Typography>
            <WindowRounded color='error' sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
          </Button>
        }
      />
      <SmartDialog
        setOpen={openCheckTaskProgramsDialog} message={t_('There are tasks without programs.') + ' ' + t_('Are you sure you want to save data?')} title={createDialogTitle({ title: t_('Warning'), icon: WarningRounded, color: 'warning' })}
        accept close={false} acceptCallback={() => { saveJobLinker(); updateCurrentPartReferenceId() }} cancelCallback={() => { setOpenSaveDialog(false); setOpenCheckTaskProgramsDialog(false) }}
      />
      <SmartDialog
        setOpen={openCheckJobToolsDialog} message={t_('There are required tools on tasks.') + ' ' + t_('Are you sure you want to save data?')} title={createDialogTitle({ title: t_('Warning'), icon: WarningRounded, color: 'warning' })}
        accept close={false} acceptCallback={() => { saveToolCheckbox(); updateCurrentPartReferenceId() }} cancelCallback={() => { setOpenCheckJobToolsDialog(false) }}
      />
      <SmartDialog
        setOpen={openToolRequiredDialog} message={t_('There are required tools on tasks.') + ' ' + t_('You can not save without tools')} title={createDialogTitle({ title: t_('Error'), icon: ErrorRounded, color: 'error' })}
        cancel={false} accept={false} close closeCallback={() => { setOpenSaveDialog(false); setOpenToolRequiredDialog(false) }}
      />
      <SmartSaveDialog
        show={openSaveDialog}
        cancelCallback={() => { setOpenSaveDialog(false) }}
        saveCallback={checkTaskPrograms}
      />
      <div style={{ height: 'calc(100vh - 200px)', display: 'grid', gridTemplateRows: 'min-content 1fr min-content' }}>
        <Stack sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
          {AlertElement}
          <Typography sx={{ fontSize: '25px', marginLeft: '1rem' }}>{t_('Job Linker')}</Typography>
          <Stack direction='row' justifyContent='space-between' sx={{ margin: '1rem' }}>
            <Stack direction='row' gap={2}>
              <TextField
                style={{ width: '30rem' }}
                fullWidth
                select
                key='partReferenceSelector'
                label={t_('Part Reference')}
                value={currentPartReferenceId}
                disabled={smartTableEditIndex >= 0}
                onChange={(event) => {
                  if (editMode === true && currentPartReferenceId !== event.target.value && editingData.edited === true) {
                    setWarningDialogEditing(true)
                    editingData.edited = false
                    setNextSelectedPartReferenceId(event.target.value)
                  } else {
                    setCurrentPartReference(event.target.value)
                  }
                  setEditMode(false)
                }}
                InputLabelProps={{
                  shrink: true
                }}
              >
                {partReferenceList.map(partReference => (
                  <MenuItem key={'partReferenceSelector' + partReference.id} value={partReference.id}>
                    {partReference.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                select
                key='workflowSelector'
                label={t_('Workflow')}
                value={currentWorkflowId}
                onChange={async (event) => {
                  const partReferenceJobReferenceList = await createNewPartReferenceJobReference(event.target.value)
                  setCurrentWorkflow(event.target.value, currentPartReferenceId, partReferenceJobReferenceList)
                }}
                disabled={!editMode || currentPartReferenceId === '' || smartTableEditIndex >= 0}
                InputLabelProps={{
                  shrink: true
                }}
              >
                {workflowList.map(workflow => (
                  <MenuItem key={'workflowSelector' + workflow.id} value={workflow.id}>
                    {workflow.name}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack direction='row' gap={2}>
              <GenericFloatingButton
                label={t_('Revert')}
                icon={<HistoryOutlined />}
                color={editMode ? theme.palette.error.light : 'disabled'}
                opacity={1}
                onClick={() => { setOpenRevertChangesDialog(true) }}
                disabled={!editMode}
              />
              <GenericFloatingButton
                disabled={smartTableEditIndex >= 0 || currentPartReferenceId === ''}
                label={editMode ? t_('Save') : t_('Edit')}
                icon={editMode ? <SaveRounded /> : <EditRounded />}
                color={smartTableEditIndex >= 0 || currentPartReferenceId === '' ? 'disabled' : theme.palette.primary.main}
                opacity={1}
                onClick={() => {
                  if (editMode) {
                    setNextSelectedPartReferenceId(currentPartReferenceId)
                    setOpenSaveDialog(true)
                  } else {
                    setEditMode(true)
                    startFetchInterval(false)
                  }
                }}
              />

            </Stack>
          </Stack>
        </Stack>
        <SmartReactflow
          nodes={nodes}
          setNodes={setNodes}
          onNodesChange={onNodesChange}
          edges={edges}
          setEdges={setEdges}
          nodeComponent={JobLinkerNode}
          editMode={editMode}
          onNodeSelectionChange={(nodeList) => {
            if (nodeList.length > 0 && nodeList[0].type === 'technologyNode') {
              setViewTaskTable(true)
              setCollapsedTaskTable(true)
              const index = editingData.currentPartReferencejobReferenceList.findIndex((partReferenceJobReference) => partReferenceJobReference.jobReference.id === nodeList[0].data.jobReferenceId)
              dispatchEditingData({ type: 'SET_CURRENT_PART_REFERENCE_JOB_REFERENCE_INDEX', currentPartReferenceJobReferenceIndex: index })
              return
            }
            if (viewTaskTable) {
              setViewTaskTable(false)
            }
          }}
          isNodeSelected={viewTaskTable}
          fitView={fitView}
          disableFitOnResize
        />
        <div style={{ maxHeight: '500px', overflow: 'auto' }}>
          {viewTaskTable
            ? collapsedTaskTable
              ? (
                <Stack>
                  <IconButton sx={{ borderRadius: 0, paddingLeft: 0, paddingRight: 0 }} onClick={() => setCollapsedTaskTable(false)}><KeyboardArrowUp /></IconButton>
                </Stack>
                )
              : addSmartTable(editingData.currentPartReferencejobReferenceList[editingData.currentPartReferenceJobReferenceIndex], editingData.currentPartReferenceJobReferenceIndex)
            : null}
        </div>
      </div>
    </>
  )
}

export default JobLinker
