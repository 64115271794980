import React, { useState, useEffect } from 'react'
import { Stepper, Step, StepLabel, Box, Typography } from '@mui/material'
import { apiGet } from '../generic/Api_Functions'
import theme from '../../themeConfig'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

/**
* SmartSelect component
* @param {Object} props Props object
* @param {Number} props.partId Part Id
* @param {Object} props.partProgress Parts workflow steps progress info
**/

const PartProgressStepper = ({ partId, partProgress }) => {
  const [partProgressData, setPartProgressData] = useState(partProgress || [])
  const [activeStep, setActiveStep] = useState(0)
  const { t_ } = useSmartTranslation()

  useEffect(() => {
    if (partId) {
      const fetchData = async () => {
        const data = await apiGet('part/actualPartProgress/' + partId)
        setPartProgressData(data)
        setActiveStep(data.findIndex(part => !part.jobReferenceResult))
      }
      fetchData()
      const interval = setInterval(fetchData, 1000)
      return () => clearInterval(interval)
    }
  }, [partId])

  if (partId === '' && partProgressData.length === 0) {
    return (
      <Typography variant='h6' sx={{ textAlign: 'center' }}>
        {t_('No part being processed')}
      </Typography>
    )
  }

  return (
    <Box sx={{ p: 2 }}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {partProgressData.map((partProgress, index) => {
          const errorStyle = {}
          let isError = false
          if (partProgress.jobReferenceResult === 'FAIL') {
            errorStyle.color = theme.palette.error.main
            isError = true
          } else if (!partProgress.jobReferenceResult && partProgress.deviceStatus === 'NON_OPERATIVE') {
            errorStyle.color = theme.palette.warning.main
            isError = true
          }
          const isLastStep = index === partProgressData.length - 1
          const isStepComplete =
            activeStep > index ||
            (isLastStep && partProgress.jobReferenceResult === 'OK' && !isError)
          return (
            <Step key={index} completed={isStepComplete}>
              <StepLabel
                error={isError}
                sx={{ '.Mui-error': errorStyle }}
              >
                {partProgress.technologyName.toUpperCase()}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}

export default PartProgressStepper
