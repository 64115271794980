import { useEffect, useState } from "react";
import { apiGet } from "../Api_Functions";
import { SmartDialog } from "../utilities/SmartDialog";
import { CircularProgress } from "@mui/material";
import { useSmartTranslation } from "../hooks/useSmartTranslation";
import { CheckListPartReference, setOpenJobsOnLoad } from "./ChecklistPartReference";

const ChecklistPartReferenceDialog = ({ partReferenceId, open, onClose }) => {

  const { t_ } = useSmartTranslation();

  const [workflow, setWorkflow] = useState(null);

  useEffect(() => {
    const loadWorkflow = async () => {
      setWorkflow(null);
      let workflow = await apiGet("checklist/partReference/" + partReferenceId);
      setOpenJobsOnLoad(workflow);
      setWorkflow(workflow);
    }
    if (open && partReferenceId !== null) {
      loadWorkflow();
    }
  }, [open, partReferenceId]);

  return <SmartDialog setOpen={open} closeCallback={onClose} accept={false} cancel={false} close={true} renderComponent={
    workflow === null ? <CircularProgress /> : <CheckListPartReference workflow={workflow} title={{ visible: false, render: t_("CheckList") }} forceDisabled={true} onChangeWorkflow={setWorkflow} />} />
};

export default ChecklistPartReferenceDialog;