import * as React from 'react'

import TextField from '@mui/material/TextField'
import { Stack } from '@mui/material'

import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

export default function PlanPublishDialogContent (props) {
  const { setPublishName, publishedPlans, publishName, setIsValidPlannerName } = props
  const { t_ } = useSmartTranslation()

  const checkValidPlanName = () => {
    if (publishName === '') {
      return t_('Name cannot be empty')
    }
    if (publishedPlans?.map(publishedPlan => publishedPlan.fileName)?.includes(publishName)) {
      return t_('The planification given name already exists')
    }

    return t_('Required *')
  }

  const errorCondition = (name) => {
    return publishedPlans?.map(publishedPlan => publishedPlan.fileName)?.includes(name) || name === ''
  }

  return (
    <Stack gap={3}>
      <TextField
        InputLabelProps={{
          shrink: true
        }}
        autoFocus
        fullWidth
        margin='dense'
        id='name'
        label={publishName === null ? t_('Plan name') + ':' : checkValidPlanName()}
        variant='standard'
        onChange={e => { setPublishName(e.target.value); setIsValidPlannerName(!errorCondition(e.target.value)) }}
        error={errorCondition(publishName)}
      />
    </Stack>
  )
}
