import React from 'react'
import Grid from '@mui/material/Grid'
import sample from '../../../../images/sample.png'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ViewPart() {
  const location = useLocation();
  const navigate = useNavigate();
  const { urlBack } = location.state;

  const goBack = () => {
    navigate(urlBack);
  }

  const buttonCss = {
    border: "1px solid #D0D0D0 ",
    backgroundColor: "#303030",
    color: "#D9D9D9",
    float: 'right',
    marginRight: '-13px',
    marginTop: '-25px',
  }
  return (
    <>
      <Grid style={{ marginTop: "35px" }} container justifyContent='center'>
        <Grid>
          <IconButton type='button' sx={{ p: '10px' }} onClick={goBack} style={buttonCss}>
            <CloseIcon />
          </IconButton>
          <img src={sample} alt='' />
        </Grid>
      </Grid >
    </>
  )
}
