import React from 'react'
import SmartNavigator from '../generic/SmartNavigator'
import Production from '../dashboard/Production'
import FakeDashboard from '../../images/DashboardGrafanaV1.png'
import GenericKPIs from '../dashboard/GenericKPIs'
import useFrontEndConfig from '../generic/hooks/useFrontEndConfig/useFrontEndConfig'
import useSmartTranslation from '../generic/hooks/useSmartTranslation/useSmartTranslation'
import DeviceAvailabilityChart from '../dashboard/DeviceAvailabilityChart'

const getKpiElement = (config, t_) => {
  const kpiElement = {
    title: t_('Overview'),
    component: (
      <div style={{ textAlign: 'center' }}>
        <img style={{ width: '100%' }} src={FakeDashboard} alt='Dashboard' />
      </div>
    )
  }

  if (config.dashboards) {
    kpiElement.title = t_('KPIs')
    kpiElement.component = <GenericKPIs dashboardsUrl={config.dashboardsUrl} />
  }

  return kpiElement
}

const MainDashboard = () => {
  const [config] = useFrontEndConfig()

  const { t_ } = useSmartTranslation()

  if (config === null) return null

  const kpiElement = getKpiElement(config, t_)

  const componentsInfo = [
    {
      title: kpiElement.title,
      component: kpiElement.component
    },
    {
      title: t_('Production'),
      component: <Production />
    },
    {
      title: t_('Capacity vs Load'),
      component: <DeviceAvailabilityChart />
    }
  ]

  return (
    <SmartNavigator componentsInfo={componentsInfo} />
  )
}

export default MainDashboard
