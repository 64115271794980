import React from "react";
import SmartNavigator from "../generic/SmartNavigator";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";
import Profiles from "../configuration/profiles/Profiles";

const MainProfiles = () => {
  const { t_ } = useSmartTranslation();

  const componentsInfo = [
    {
      title: t_("Profiles"),
      component: <Profiles />,
    },
  ];

  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  );
};

export default MainProfiles;
