import { Accordion, AccordionDetails, AccordionSummary, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import ChecklistStatusButton from './CheckListStatusButton'
import { ExpandMoreOutlined } from '@mui/icons-material'
import ChecklistIndicator from './ChecklistIndicator'
import PropTypes from 'prop-types'
import { ChecklistTask, getTaskStatus } from './ChecklistTask'
import { useMemo } from 'react'
import TooltipList from './TooltipList'
import ChecklistQuantityIndicator from './ChecklistQuantityIndicator'
import { useSmartTranslation } from '../hooks/useSmartTranslation'

/**
 * ChecklistJob
 * @param {Object} props
 * @param {Number} props.index Index of the job
 * @param {Object} props.job Job object
 * @param {Boolean} props.job.deviceOk If device is okay
 * @param {Array.<Object>} props.job.tasks List of tasks in the job
 * @param {String} props.job.technology Technology of this job
 * @param {Function} props.onAddProgram Function to be called when the user clicks to add a program. Params will be job, task and task index
 * @returns
 *
 */
const ChecklistJob = ({ index, job, onChange, forceDisabled = false, onAddProgram, total }) => {
  const jobStatus = useMemo(() => {
    return getJobStatus(job)
  }, [job])

  const tableHeaderItemsStyle = { borderTop: (theme) => '1px ' + theme.palette.primary.dark + ' solid', borderBottom: (theme) => '1px ' + theme.palette.primary.dark + ' solid' }
  const { t_ } = useSmartTranslation()
  return (
    <Accordion disableGutters TransitionProps={{ unmountOnExit: true }} expanded={job.tasksOpen} onChange={(event, isExpanded) => onChange(index, { ...job, tasksOpen: isExpanded })}>
      <AccordionSummary expandIcon={<ExpandMoreOutlined />} sx={{ '& .MuiAccordionSummary-content': { margin: 0 } }}>
        <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', flexGrow: 1 }}>
          <Stack direction='row' alignItems='center' gap={1}>
            <ChecklistIndicator status={jobStatus} />
            <Typography>{(index + 1) + ' - ' + job.technology.name}</Typography>

            {forceDisabled ? null : <ChecklistQuantityIndicator quantity={job.quantity} total={total} sx={{ marginLeft: '2rem' }} />}

          </Stack>
          <Stack direction='row' alignItems='center' gap={1} justifyContent='center'>
            <Typography>-</Typography>
            <ChecklistStatusButton ok={job.fixtureOk} force={job.fixtureForce} onForceChange={(newForce) => onChange(index, { ...job, fixtureForce: newForce })} disabled={forceDisabled} />
          </Stack>
          <Stack direction='row-reverse' alignItems='center' gap={3}>
            <ChecklistStatusButton ok={job.deviceOk} force={job.deviceForce} onForceChange={(newForce) => onChange(index, { ...job, deviceForce: newForce })} disabled={forceDisabled} />
            <TooltipList items={job.devices} getItemId={(item) => item.id} getItemLabel={(item) => item.name} getItemOk={(item) => item.ok} />
          </Stack>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Table
          size='small' sx={{
            backgroundColor: (theme) => {
              return theme.palette.background.card
            }
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeaderItemsStyle}>{t_('Task')}</TableCell>
              <TableCell sx={tableHeaderItemsStyle}>{t_('Program')}</TableCell>
              <TableCell sx={tableHeaderItemsStyle}>{t_('Tool')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderTop: (theme) => '1px ' + theme.palette.primary.dark + ' solid' }}>
            {job.tasks.map((task, taskIndex) =>
              <ChecklistTask
                key={task.id} task={task} index={taskIndex} onChange={(taskIndex, task) => {
                  const newJob = { ...job }
                  newJob.tasks = [...job.tasks]
                  newJob.tasks[taskIndex] = task
                  onChange(index, newJob)
                }}
                forceDisabled={forceDisabled}
                onAddProgram={(task, index) => { onAddProgram(job, task, index) }}
              />)}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}

const getJobStatus = (job) => {
  let warning = false
  let error = false

  // Check tasks status
  job.tasks.forEach(task => {
    const taskStatus = getTaskStatus(task)
    if (taskStatus === 'ERROR') {
      error = true
    } else if (taskStatus === 'WARNING') {
      warning = true
    }
  })

  // Check fixture
  if (!job.fixtureOk) {
    if (!job.fixtureForce) {
      error = true
    } else {
      warning = true
    }
  }

  // Check device
  if (!job.deviceOk) {
    if (!job.deviceForce) {
      error = true
    } else {
      warning = true
    }
  }

  if (error) {
    return 'ERROR'
  }
  if (warning) {
    return 'WARNING'
  }
  return 'OK'
}

ChecklistJob.propTypes = {
  index: PropTypes.number.isRequired,
  job: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddProgram: PropTypes.func.isRequired
}

export { ChecklistJob, getJobStatus }
