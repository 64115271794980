import React from 'react'
import SmartNavigator from '../generic/SmartNavigator'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import DeviceStatus from '../configuration/deviceStatus/DeviceStatus'
import DeviceStatusType from '../configuration/deviceStatus/DeviceStatusType'

const MainDeviceStatus = () => {
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('Device Status'),
      component: <DeviceStatus />,
      to: '/configuration/picklist/deviceStatus'
    },
    {
      title: t_('Device Status types'),
      component: <DeviceStatusType />,
      to: '/configuration/picklist/deviceStatusTypes'
    }
  ]
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  )
}

export default MainDeviceStatus
