import React, { Fragment, useRef, useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Box, Stack } from '@mui/material'
import IcoStatus from '../IcoStatus/IcoStatus'
import IcoMachine from '../IcoMachine'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTheme } from '@emotion/react'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import { DefaultCompassPopper } from '../CompassPopper/CompassPopper'
import Tooltip from '@mui/material/Tooltip'

const CompassCard = (props) => {
  const { device, buttons, fabricationOrder, showInterrupted, showPendingTrue, showFabricationOrderExtraData = false, onDoubleClick, isFullScreen } = props
  const [openNextOf, setOpenNextOf] = useState(false)
  const [openNextActiveOf, setOpenNextActiveOf] = useState(false)
  const { t_ } = useSmartTranslation()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const componentRef = useRef(null)
  const buttonCss = {
    border: '1px solid #D0D0D0',
    backgroundColor: '#303030',
    color: '#D9D9D9'
  }
  const spanCss = {
    marginTop: '-14px',
    fontSize: isFullScreen ? '1.1rem' : '10px',
    textAlign: 'center'
  }
  const labelCss = {
    padding: '5px',
    paddingLeft: '0px',
    width: '100%',
    fontSize: '0.675rem'
  }
  const typographyCss = {
    fontSize: isFullScreen ? '1.1rem' : '0.875rem'
  }
  const getGrafico = () => {
    let pend = fabricationOrder?.waitingQuantity
    let inProgress = fabricationOrder?.inProcessQuantity
    let valid = fabricationOrder?.okQuantity
    let nonValid = fabricationOrder?.badQuantity
    const total = pend + inProgress + valid + nonValid

    pend = pend * 100 / total
    inProgress = inProgress * 100 / total
    valid = valid * 100 / total
    nonValid = nonValid * 100 / total
    return (
      <>
        {total > 0 &&
          <>
            <div style={{ width: pend + '%', float: 'left' }}><span style={{ ...spanCss, visibility: pend > 10 ? 'visible' : 'hidden' }}>{t_('Pending')}</span> <div data-p={pend} style={{ backgroundColor: '#7b8187', width: '100%', height: isFullScreen ? '15px' : '5px', float: 'left' }} /></div>
            <div style={{ width: inProgress + '%', float: 'left' }}><span style={{ ...spanCss, visibility: inProgress > 10 ? 'visible' : 'hidden' }}>{t_('In progress')}</span><div data-p={inProgress} style={{ backgroundColor: '#388ed8', width: '100%', height: isFullScreen ? '15px' : '5px', float: 'left' }} /></div>
            <div style={{ width: valid + '%', float: 'left' }}><span style={{ ...spanCss, visibility: valid > 10 ? 'visible' : 'hidden' }}>{t_('Valid')}</span><div data-p={valid} style={{ backgroundColor: '#2ef0a7', width: '100%', height: isFullScreen ? '15px' : '5px', float: 'left' }} /></div>
            <div style={{ width: nonValid + '%', float: 'left' }}><span style={{ ...spanCss, visibility: nonValid > 10 ? 'visible' : 'hidden' }}>{t_('Invalid')}</span><div data-p={nonValid} style={{ backgroundColor: '#ff2a6c', width: '100%', height: isFullScreen ? '15px' : '5px', float: 'left' }} /></div>
          </>}
      </>
    )
  }

  const formatText = (text) => {
    if (isFullScreen && text.length > 20) {
      return `${text.slice(0, 3)}...${text.slice(-14)}`
    }

    if (!isFullScreen && text.length > 11) {
      return `${text.slice(0, 3)}...${text.slice(-4)}`
    }

    return text
  }

  const disableToolTip = (text) => {
    if (isFullScreen && text.length <= 20) {
      return true
    }
    if (!isFullScreen && text.length <= 11) {
      return true
    }
    return false
  }

  const cardHeaderCss = {
    borderRadius: '4px',
    textAlign: 'justify',
    padding: '20px 19px',
    fontSize: '1rem',
    alignItems: 'center'
  }
  const getFabricationOrderDetails = (fabricationOrder, gridSpace) => {
    return (
      <>
        <Grid item xs={gridSpace}>
          <Typography sx={{ ...typographyCss }}>
            {fabricationOrder.fabricationOrder}
          </Typography>
        </Grid>
        <Grid item xs={gridSpace}>
          <Typography sx={{ ...typographyCss }}>
            {fabricationOrder.partReferenceName}
          </Typography>
        </Grid>
        <Grid item xs={gridSpace}>
          <Typography sx={{ ...typographyCss }}>
            {fabricationOrder.okQuantity + '/' + fabricationOrder.totalQuantity}
          </Typography>
        </Grid>
      </>
    )
  }
  const getUserDetails = (user, gridSpace) => {
    return (
      <>
        <Grid item xs={12} sm={12} md={gridSpace} style={{ paddingTop: '2px', width: '100%' }}>
          <Typography fontSize={isFullScreen ? '1.1rem' : undefined} color='primary' style={{ marginBottom: '5px' }} variant='body2'>{t_('Code')}</Typography>
          <Stack style={labelCss}>
            <Tooltip title={user.code} disableHoverListener={disableToolTip(user.code)}>
              <Typography sx={typographyCss} noWrap>
                {formatText(user.code)}
              </Typography>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={gridSpace} style={{ paddingTop: '2px' }}>
          <Typography fontSize={isFullScreen ? '1.1rem' : undefined} color='primary' style={{ marginBottom: '5px' }} variant='body2'>{t_('Name')}</Typography>
          <Stack style={labelCss}>
            <Tooltip title={user.name} disableHoverListener={disableToolTip(user.name)}>
              <Typography sx={typographyCss} noWrap>
                {formatText(user.name)}
              </Typography>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={gridSpace} style={{ paddingTop: '2px' }}>
          <Typography fontSize={isFullScreen ? '1.1rem' : undefined} color='primary' style={{ marginBottom: '5px' }} variant='body2'>{t_('Surname')}</Typography>
          <Stack style={labelCss}>
            <Tooltip title={user.surname} disableHoverListener={disableToolTip(user.surname)}>
              <Typography sx={typographyCss} noWrap>
                {formatText(user.surname)}
              </Typography>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={gridSpace} style={{ paddingTop: '2px' }}>
          {device.workers.length > 1
            ? (
              <>
                <DefaultCompassPopper
                  open={open}
                  setOpen={setOpen}
                  componentRef={componentRef}
                  ariaLabel=''
                  fabSize='small'
                  fabSx={{ ...buttonCss, marginLeft: '10px' }}
                  buttonContent={<GroupAddOutlinedIcon />}
                  render={
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ zIndex: '1000000' }}>
                      <Grid item xs={4}> <Typography color='primary' variant='body2'>{t_('Code')}</Typography></Grid>
                      <Grid item xs={4}> <Typography color='primary' variant='body2'>{t_('Name')}</Typography></Grid>
                      <Grid item xs={4}> <Typography color='primary' variant='body2'>{t_('Surname')}</Typography></Grid>
                      {device?.workers?.slice(1).map((m) => {
                        return (
                          <Fragment key={m.id}>
                            <Grid item xs={4}>
                              <Typography sx={{ ...typographyCss }}>
                                {m.code}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography sx={{ ...typographyCss }}>
                                {m.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography sx={{ ...typographyCss }}>
                                {m.surname}
                              </Typography>
                            </Grid>
                          </Fragment>
                        )
                      })}

                    </Grid>
                  }
                />
              </>)
            : null}
        </Grid>
      </>
    )
  }
  const getFODetails = (fabricationOrder, gridSpace) => {
    return (
      <>
        <Grid item xs={12} sm={12} md={gridSpace} style={{ paddingTop: '2px', width: '100%' }}>
          <Typography fontSize={isFullScreen ? '1.1rem' : undefined} color='primary' style={{ marginBottom: '5px' }} variant='body2'>{t_('FO')}</Typography>
          <Stack style={labelCss}>
            <Tooltip title={fabricationOrder.fabricationOrder} disableHoverListener={disableToolTip(fabricationOrder.fabricationOrder)}>
              <Typography sx={typographyCss} noWrap>
                {formatText(fabricationOrder.fabricationOrder)}
              </Typography>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={gridSpace} style={{ paddingTop: '2px' }}>
          <Typography fontSize={isFullScreen ? '1.1rem' : undefined} color='primary' style={{ marginBottom: '5px' }} variant='body2'>{t_('REF')}</Typography>
          <Stack style={labelCss}>
            <Tooltip title={fabricationOrder.partReferenceName} disableHoverListener={disableToolTip(fabricationOrder.partReferenceName)}>
              <Typography sx={typographyCss} noWrap>
                {formatText(fabricationOrder.partReferenceName)}
              </Typography>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={gridSpace} style={{ paddingTop: '2px' }}>
          <Typography fontSize={isFullScreen ? '1.1rem' : undefined} color='primary' style={{ marginBottom: '5px' }} variant='body2'>{t_('OK / Tot.')}</Typography>
          <Stack style={labelCss}>
            <Typography sx={{ ...typographyCss }}>
              {fabricationOrder.okQuantity + ' / ' + fabricationOrder.totalQuantity}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={gridSpace} style={{ paddingTop: '2px' }}>
          {device.currentFabricationOrders.length > 1 && fabricationOrder
            ? (
              <>
                <DefaultCompassPopper
                  open={openNextActiveOf}
                  setOpen={setOpenNextActiveOf}
                  componentRef={componentRef}
                  ariaLabel=''
                  fabSize='small'
                  fabSx={{ ...buttonCss, marginLeft: '10px' }}
                  buttonContent={'+' + (device.currentFabricationOrders.length - 1)}
                  render={
                    <Stack>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                        <Grid item xs={4}> <Typography color='primary' variant='body2'>{t_('FO')}</Typography></Grid>
                        <Grid item xs={4}> <Typography color='primary' variant='body2'>{t_('REF')}</Typography></Grid>
                        <Grid item xs={4}> <Typography color='primary' variant='body2'>{t_('OK / Tot.')}</Typography></Grid>
                        {device?.currentFabricationOrders?.slice(1).map((activeFabricationOrder) => {
                          return getFabricationOrderDetails(activeFabricationOrder, 4, '2px')
                        })}
                      </Grid>
                    </Stack>
                  }
                />
              </>)
            : null}
        </Grid>
      </>
    )
  }

  return (
    <Card ref={componentRef} style={{ display: 'flex', flexDirection: 'column', height: '100%', overflowX: 'hidden', overflowY: 'auto', alignContent: 'center', width: '100%', maxWidth: '100%' }}>
      <CardHeader
        onDoubleClick={() => {
          onDoubleClick(null)
        }}
        style={{
          cardHeaderCss
        }} title={<Typography fontSize={isFullScreen ? '1.7rem' : undefined} variant='subtitle1'>{device.name}</Typography>} action={
          <IcoStatus status={device.status} mode={device.mode} incidenceQuantity={device.incidenceQuantity} device={device} />
      }
      />
      <CardContent sx={{ backgroundColor: (theme) => theme.palette.background.card, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1, marginTop: '0,6rem', paddingBottom: '10px !important' }}>
        <Stack direction='row' spacing={2}>
          <Stack sx={{ width: '35%', maxHeight: '35vh' }}>

            <IcoMachine machineType={device.deviceType} />
            <Grid container sx={{ marginTop: '1rem' }}>
              {showInterrupted && (fabricationOrder || device.interruptedFabricationOrderList.length > 0)
                ? (
                  <Box display='flex' alignItems='center'>
                    <Typography fontSize={isFullScreen ? '1.1rem' : undefined} variant='body2' color='primary'>{t_('Interrupted FOs') + ': '}</Typography>
                    <Typography style={{ ...typographyCss, marginLeft: '0.3rem' }} variant='body2'> {device.interruptedFabricationOrderList.length}</Typography>
                  </Box>)
                : null}
              {device.nextFabricationOrders.length > 0 && showPendingTrue
                ? (
                  <Grid container alignItems='center' gap={1}>
                    <Grid item md={isFullScreen ? 8 : 9}>
                      <Box display='flex' alignItems='center'>
                        <Typography fontSize={isFullScreen ? '1.1rem' : undefined} variant='body2' color='primary'>{t_('Next FO') + ': '}</Typography>
                        <Typography style={{ ...typographyCss, marginLeft: '0.3rem' }} variant='body2'> {device.nextFabricationOrders[0].fabricationOrder}</Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={3} style={{ width: '100%' }}>
                      <Grid style={{ paddingTop: '2px !important', width: '100%' }}>
                        {device.nextFabricationOrders.length > 1 && fabricationOrder
                          ? (
                            <DefaultCompassPopper
                              open={openNextOf}
                              setOpen={setOpenNextOf}
                              componentRef={componentRef}
                              ariaLabel=''
                              fabSize='small'
                              fabSx={{ ...buttonCss, width: isFullScreen ? '3rem' : '35px', height: isFullScreen ? '3rem' : '35px' }}
                              buttonContent={'+' + (device.nextFabricationOrders.length - 1)}
                              render={
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                                  <Grid item xs={4}> <Typography color='primary' variant='body2'>{t_('FO')}</Typography></Grid>
                                  <Grid item xs={4}> <Typography color='primary' variant='body2'>{t_('REF')}</Typography></Grid>
                                  <Grid item xs={4}> <Typography color='primary' variant='body2'>{t_('Quantity')}</Typography></Grid>
                                  {device?.nextFabricationOrders?.slice(1).map((m) => {
                                    return (
                                      <Fragment key={m.id}>
                                        <Grid item xs={4}>
                                          <Typography sx={{ ...typographyCss }}>
                                            {m.fabricationOrder}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Typography sx={{ ...typographyCss }}>
                                            {m.partReferenceName}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Typography sx={{ ...typographyCss }}>
                                            {m.totalQuantity}
                                          </Typography>
                                        </Grid>
                                      </Fragment>
                                    )
                                  })}
                                </Grid>
                  }
                            />)
                          : null}
                      </Grid>
                    </Grid>
                  </Grid>)
                : null}
            </Grid>
          </Stack>
          <Stack direction='row' style={{ width: '100%' }}>
            <Grid container style={{ width: '100%' }}>
              <Grid container style={{ width: '100%', alignItems: 'center', height: '3rem' }}>
                <Grid item xs={12}>
                  <Typography fontSize={isFullScreen ? '1.7rem' : undefined}>{device.deviceType}</Typography>
                </Grid>
                {device.workers.length > 0
                  ? (
                    <Stack style={{ width: '100%', height: '100%', marginTop: '2rem' }}>
                      <Grid container spacing={2}>
                        {getUserDetails(device.workers[0], 3)}
                      </Grid>
                    </Stack>)
                  : null}
              </Grid>

              {
                fabricationOrder != null
                  ? (
                    <Stack style={{ width: '100%', height: '100%', marginTop: isFullScreen ? '6rem' : '3.8rem' }}>
                      <Grid container spacing={2}>
                        {getFODetails(fabricationOrder, 3)}
                      </Grid>
                      {showFabricationOrderExtraData
                        ? (
                          <Grid container rowSpacing={2} sx={{ width: '100%', marginTop: '5px' }}>
                            <Grid item sm={12}>
                              <span style={{ fontSize: isFullScreen ? '1.1rem' : undefined, color: theme.palette.primary.main }}>{t_('Delivery date') + ': '}</span><Typography style={typographyCss} variant='body2' color='common.white' display='inline-block'>{moment(fabricationOrder?.dateEnd).format('YYYY-MM-DD HH:mm')}</Typography>
                              <br />
                              <span style={{ fontSize: isFullScreen ? '1.1rem' : undefined, color: theme.palette.primary.main }}>{t_('Job') + ': '}</span><Typography variant='body2' color='common.white' display='inline-block'>{fabricationOrder?.jobReferenceName != null ? fabricationOrder.jobReferenceName : fabricationOrder.jobReferenceTechnology}</Typography>
                            </Grid>
                            <Stack direction='row' sx={{ width: '100%', marginTop: '10px' }}>
                              {getGrafico()}
                            </Stack>
                          </Grid>)
                        : null}
                    </Stack>)
                  : null
              }
            </Grid>
          </Stack>
        </Stack>
        <CardActions style={{ marginTop: '1rem', paddingBottom: '0px' }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            justifyContent='space-between'
            style={{ height: '6rem' }}
          >
            {
              buttons || null
            }
          </Grid>
        </CardActions>
      </CardContent>
    </Card>
  )
}
CompassCard.propTypes = {
  device: PropTypes.any.isRequired,
  buttons: PropTypes.any.isRequired,
  fabricationOrder: PropTypes.any
}

export default CompassCard
