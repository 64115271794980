import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Stack } from '@mui/material'
import { CancelRounded, CheckCircleRounded, WarningRounded } from '@mui/icons-material'
import PropTypes from 'prop-types'

import React, { useState, useEffect } from 'react'
import createDialogTitle from '../dialogUtil'
import { useSmartTranslation } from '../../hooks/useSmartTranslation'

const SecondaryButton = (props) => (
  <Button
    onClick={props.onClick}
    variant={props.variant}
    sx={{
      border: '1px solid rgba(255, 255, 255, 0.23) !important',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08) !important'
      }
    }}
  >
    {props.children}
  </Button>
)

const SmartDialog = (props) => {
  const {
    message, acceptCallback, acceptCallbackArgsArray = [], renderComponent, cancelCallback, cancelCallbackArgsArray = [], closeCallback, closeCallbackArgsArray = [],
    cancel = true, accept = true, close, renderCustomButtons, scroll = 'paper', setOpen = false, title = null, autoClose = true, paperWidth
  } = props

  const [showDialog, setShowDialog] = useState(setOpen)
  const { t_ } = useSmartTranslation()

  useEffect(() => {
    setShowDialog(setOpen)
  }, [setOpen])

  const handleAccept = () => {
    if (acceptCallback && typeof acceptCallback === 'function') {
      acceptCallback(...acceptCallbackArgsArray)
    }
  }

  const handleCancel = () => {
    if (autoClose) {
      setShowDialog(false)
    }
    if (cancelCallback && typeof cancelCallback === 'function') {
      cancelCallback(...cancelCallbackArgsArray)
    }
  }

  const handleClose = () => {
    if (autoClose) {
      setShowDialog(false)
    }

    if (closeCallback && typeof closeCallback === 'function') {
      closeCallback(...closeCallbackArgsArray)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (autoClose) {
        setShowDialog(false)
      }
      event.preventDefault()
    }
  }

  const renderTitle = () => {
    if (title === null) return
    if (title === 'default') return getTitleComponent(createDialogTitle({ color: 'warning', title: 'Attention', icon: WarningRounded }))
    return getTitleComponent(title)
  }

  const getTitleComponent = (titleToRender) => {
    return (
      <DialogTitle>
        <Stack spacing={1} alignItems='center' justifyContent='center'>
          {titleToRender.icon}
          {titleToRender.render}
        </Stack>
      </DialogTitle>
    )
  }

  return (
    <Dialog
      id='oi'
      open={showDialog}
      maxWidth='xl'
      scroll={scroll}
      PaperProps={{
        sx: {
          background: theme => `${theme.palette.background.color_373636} !important`,
          minWidth: '30rem',
          width: paperWidth
        }
      }}
    >
      {renderTitle()}
      <DialogContent dividers={scroll === 'paper' && title !== null} sx={{ padding: scroll === 'paper' && title !== null ? '0px 16px 16px 16px ' : 0, margin: scroll === 'paper' && title !== null ? 0 : '0px 0px 0px 16px ' }}>
        <Grid container alignItems='center' justifyContent='center'>
          {message && message !== ''
            ? (
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography sx={{ margin: '0em 1em 1em 1em' }}>{message}</Typography>
              </Grid>
              )
            : null}
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            {renderComponent !== null && renderComponent !== '' ? <div>{renderComponent}</div> : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack
          spacing={1}
          direction='row'
          justifyContent='left'
          alignItems='center'
        >
          {cancel
            ? (
              <SecondaryButton onClick={handleCancel} variant='outlined'>
                <CancelRounded sx={{ color: 'lightgrey !important', fontSize: '1.4em', marginRight: '0.3rem' }} />
                <Typography sx={{ color: 'lightgrey' }}>{t_('CANCEL')}</Typography>
              </SecondaryButton>
              )
            : null}
          {accept
            ? (
              <Button color='primary' onClick={handleAccept} onKeyPress={handleKeyDown} variant='outlined'>
                <Typography> {t_('ACCEPT')} </Typography>
                <CheckCircleRounded color='primary' sx={{ fontSize: '1.4em', marginLeft: '0.3rem' }} />
              </Button>
              )
            : null}
          {renderCustomButtons !== undefined ? <>{renderCustomButtons}</> : null}
          {close
            ? (
              <SecondaryButton onClick={handleClose} variant='outlined'>
                <Typography sx={{ color: 'lightgrey !important' }}>{t_('Close')}</Typography>
                <CancelRounded sx={{ color: 'lightgrey', fontSize: '1.4em', marginLeft: '0.3rem' }} />
              </SecondaryButton>
              )
            : null}
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

SmartDialog.propTypes = {
  /**
   * Boolean variable that will control components visibility.
   */
  setOpen: PropTypes.bool.isRequired,
  /**
   * Dialog title. createDialogTitle type object
   */
  title: PropTypes.object,
  /**
   * Method that will be executed after pressing accept button
   */
  acceptCallback: PropTypes.func,
  /**
   * Message that will be shown on the dialog
   */
  message: PropTypes.string,
  /**
   * Arguments that will be used on acceptCallback
   */
  acceptCallbackArgsArray: PropTypes.array,
  /**
   * Component that will be loaded on the dialog
   */
  renderComponent: PropTypes.element,
  /**
   * Method that will be executed after pressing cancel button
   */
  cancelCallback: PropTypes.func,
  /**
   * Arguments that will be used on cancelCallback
   */
  cancelCallbackArgsArray: PropTypes.array,
  /**
   * Method that will be executed after pressing close button
   */
  closeCallback: PropTypes.func,
  /**
   * Arguments that will be used on closeCallback
   */
  closeCallbackArgsArray: PropTypes.array,
  /**
   * Boolean variable that will control cancel button visibility.
   */
  cancel: PropTypes.bool,
  /**
   * Boolean variable that will control accept button visibility.
   */
  accept: PropTypes.bool,
  /**
   * Boolean variable that will control close button visibility.
   */
  close: PropTypes.bool,
  /**
   * Custom buttons that will be loaded on the dialog
   */
  renderCustomButtons: PropTypes.element,
  /**
   * Dialog scroll type. 'paper' |'body'
   */
  scroll: PropTypes.oneOf(['paper', 'body']),
  /**
   * The dialog will close on close or cancel
   */
  autoClose: PropTypes.bool,

  paperWidth: PropTypes.string
}

export default SmartDialog
