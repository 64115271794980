import React from 'react'

const ConnectionLine = ({ fromX, fromY, toX, toY, type }) => {
  const getColor = () => {
    if (type === 'OK') return 'green'
    if (type === 'NOT_OK') return 'red'
    return 'white'
  }

  return (
    <g>
      <path
        fill='none'
        stroke={getColor()}
        strokeWidth={1.5}
        className='animated'
        d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
      />
      <circle
        cx={toX}
        cy={toY}
        fill='#fff'
        r={3}
        stroke={getColor()}
        strokeWidth={1.5}
      />
    </g>
  )
}

export default ConnectionLine
