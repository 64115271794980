import React, { useState, useEffect } from 'react'
import { apiDelete, apiGet, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import AlertUI from '../../generic/AlertUI'
import AddElementPart from '../../generic/smartAddElement/AddElementPart'
import { Typography, TextField, MenuItem, FormHelperText, IconButton, Button } from '@mui/material'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import PartFeature from './PartFeature'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { PartHistory } from './partHistory'
import { PauseCircle, TextSnippetOutlined } from '@mui/icons-material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { useToggle } from '../../generic/hooks/useToggle'
import PauseResumeFinishedList from './pause/PauseResumeFinishedList'
import PausePartSelectEndJobDialog from './pause/PausePartSelectEndJobDialog'
import Document from '../document/Document'

const Parts = (props) => {
  const [AlertElement, showAlert] = AlertUI()
  const { partReferenceFilter = null, partReference = null, hideColumns = null, setEditingRow, setSelectedPart, forceLoad = false } = props
  const parallelepiped = 'PARALLELEPIPED'
  const cylindrical = 'CYLINDRICAL'
  const [currentEditingRow, setCurrentEditingRow] = useState(null)
  const [editingRawMaterialId, setEditingRawMaterialId] = useState(null)
  const [availableRawMaterials, setAvailableRawMaterials] = useState([])
  const [reloadAvailableMaterials, setReloadAvailableMaterials] = useState(false)
  const [selectedRow, setSelectedRow] = useState([])
  const [showPartHistory, setShowPartHistory] = useState(false)
  const [showPartFeatures, setShowPartFeatures] = useState(false)
  const [showDocument, setShowDocument] = useState(false)
  const { t_ } = useSmartTranslation()
  const [resetMultipleSelectionValue, toogleResetMultipleSelectionValue] = useToggle()

  const [unfinishedPartsPauseResume, setUnfinishedPartsPauseResume] = useState([])
  const [partsOnDevicesToPause, setPartsOnDevicesToPause] = useState([])

  const [filters, setFilters] = useState([
    {
      name: t_('QUALITY'),
      valueName: 'QUALITY',
      key: 'NONE',
      field: 'isOk'
    },
    {
      name: t_('REWORKED'),
      valueName: 'REWORKED',
      key: 'NONE',
      field: 'isReworked'
    },
    {
      name: t_('FINISHED'),
      valueName: 'FINISHED',
      key: 'NONE',
      field: 'status'
    },
    {
      name: t_('DELIVERED'),
      valueName: 'DELIVERED',
      key: 'NONE',
      field: 'status'
    },
    {
      name: t_('SCRAP'),
      valueName: 'SCRAP',
      key: 'NONE',
      field: 'status'
    }
  ])

  const getTableData = async (pagination) => {
    const parts = await apiGet('partsId/' + partReference.id, pagination)
    return parts
  }

  useEffect(() => {
    const loadAvailableRawMaterials = async () => {
      const data = await apiGet('rawMaterialsAvailable')
      setAvailableRawMaterials(data)
    }
    loadAvailableRawMaterials()
  }, [reloadAvailableMaterials])

  const checkDimensionCondition = (row, column) => {
    let errorMsg = ''
    if ((row.rawMaterialReference !== null && row.rawMaterialReference !== undefined) && (Object.keys(row.rawMaterialReference).length > 0) &&
      (Object.keys(row.partReference).length > 0)) {
      if (row.partReference.shape.code === row.rawMaterialReference.shape.code) {
        if (row.partReference.shape.code === cylindrical) {
          if (row.partReference.diameter > row.rawMaterialReference.diameter || row.partReference.length > row.rawMaterialReference.length) {
            if (column === 'rawMaterialReference') {
              errorMsg = t_('Raw material reference should be bigger')
            } else if (column === 'partReference') {
              errorMsg = t_('Part reference should be smaller')
            }
          }
        } else if (row.partReference.shape.code === parallelepiped) {
          if (row.partReference.lengthX > row.rawMaterialReference.lengthX || row.partReference.lengthY > row.rawMaterialReference.lengthY || row.partReference.lengthZ > row.rawMaterialReference.lengthZ) {
            if (column === 'rawMaterialReference') {
              errorMsg = t_('Raw material reference should be bigger')
            } else if (column === 'partReference') {
              errorMsg = t_('Part reference should be smaller')
            }
          }
        }
      }
    }
    return errorMsg
  }

  const columns = [
    {
      name: t_('Status'),
      field: 'status',
      render: (part) => {
        return (
          <>
            <span>{part.paused ? t_('WORKFLOW_PAUSED') : t_(part.status)}</span>
          </>
        )
      }
    },
    {
      name: t_('Code'),
      field: 'code',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: true
    },
    {
      name: t_('Name'),
      field: 'name',
      charLimit: 70,
      mandatory: true
    },
    {
      name: t_('Result'),
      field: 'status',
      render: (part) => {
        if (part.status === 'RAW') {
          return t_('Not processed')
        }

        let first = 'Done'
        if (part.status === 'WORKING') {
          first = 'Processing'
        }

        if (!part.ok) {
          first = 'Not ok'
        }

        const second = part.reworked ? 'Reworked' : ''

        return `${t_(first)}  ${t_(second)}`
      }
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200,
      hide: hideColumns
    },
    {
      name: t_('Raw Material Reference'),
      field: 'rawMaterialReference.name',
      type: 'select',
      edit: {
        source: async () => {
          return await apiGet('rawMaterialsReference')
        },
        id: 'id',
        field: 'name'
      },
      mandatory: true,
      condition: (rowData, column) => {
        return checkDimensionCondition(rowData, column)
      }
    },
    {
      name: t_('Fixture'),
      field: 'fixture.code',
      type: 'select',
      edit: {
        source: async () => {
          if (currentEditingRow !== null) { return await apiGet('fixturesAvailableByPartReference/' + currentEditingRow.id) }
        },
        id: 'id',
        field: 'code'
      }
    },
    {
      name: t_('Raw Material'),
      field: 'rawMaterial.name',
      render: (row) => {
        if (!row.workflowStarted && currentEditingRow !== null && currentEditingRow.id === row.id) {
          return (
            <>
              <TextField
                select
                variant='outlined'
                style={{ minWidth: '6em' }}
                InputLabelProps={{
                  shrink: true
                }}
                key={
                  'select' +
                  currentEditingRow.id +
                  '_raw_material'
                }
                value={editingRawMaterialId || 'not-available'}
                onChange={(event) => {
                  if (event.target.value === 'not-available') {
                    setEditingRawMaterialId(null)
                    row.rawMaterial = null
                  } else {
                    setEditingRawMaterialId(event.target.value)
                  }
                }}
              >
                {row.rawMaterial ? <MenuItem value={row.rawMaterial.id}>{row.rawMaterial.code}</MenuItem> : <MenuItem key='raw-material-not-available' value='not-available'>{t_('Not available')}</MenuItem>}
                {availableRawMaterials.map((item) => <MenuItem key={'raw-material-id' + item.id} value={item.id}>{item.code}</MenuItem>)}
              </TextField><FormHelperText> </FormHelperText>
            </>
          )
        } else {
          return row.rawMaterial ? row.rawMaterial.code : <Typography color='error'>{t_('Not available')}</Typography>
        }
      }
    },
    {
      name: t_('Location'),
      field: 'location.name',
      readOnly: true,
      hide: hideColumns
    },
    {
      name: t_('Shelf'),
      field: 'shelveStorageConfiguration.shelve',
      readOnly: true,
      hide: hideColumns
    },
    {
      name: t_('Row'),
      field: 'shelveStoragePosition.position',
      readOnly: true,
      hide: hideColumns
    },
    {
      name: t_('Column'),
      field: 'shelveStoragePosition.depth',
      readOnly: true,
      hide: hideColumns
    },
    {
      name: t_('Client'),
      field: 'client.name',
      type: 'select',
      edit: {
        source: async () => {
          return await apiGet('clients')
        },
        id: 'id',
        field: 'name'

      }
    },
    {
      name: t_('Fabrication Order'),
      field: 'fabricationOrder.fabricationOrder',
      type: 'smartSelect',
      edit: {
        source: async () => {
          return await apiGet('fabricationOrderByPartReference/' + partReference.id)
        },
        id: 'id',
        field: 'fabricationOrder'
      }
    }

  ]

  const sortOptions = {
    default: {
      field: 'code',
      direction: partReferenceFilter ? -1 : 1
    }
  }

  const forceReloadAvailableMaterials = () => {
    setReloadAvailableMaterials(!reloadAvailableMaterials)
  }

  const actions = {
    edit: {
      fetchFunction: (row) => {
        if (row.location === null || row.location === undefined || ((Object.keys(row.location).length === 0 && Object.getPrototypeOf(row.location) === Object.prototype))) {
          row.shelveStoragePosition = null
        } else {
          row.shelveStoragePosition = row.depth
        }

        // Set raw material
        row.rawMaterial = editingRawMaterialId ? { id: editingRawMaterialId } : null

        return apiPut('parts', row).then(forceReloadAvailableMaterials)
      }
    },
    create: {
      fetchFunction: null,
      render: (extraFunctions) => {
        return (
          <AddElementPart
            title={t_('ADD RECORD')}
            endpoint='parts'
            referenceName='Parts'
            partReference={partReference}
            onAddDone={forceReloadAvailableMaterials}
            onAlert={extraFunctions.handleShowAlert}
          />
        )
      }
    },
    delete: partReferenceFilter
      ? undefined
      : {
          fetchFunction: (rows) => {
            if (rows.length === 1) {
              return apiDelete('parts/' + rows[0].id).then(forceReloadAvailableMaterials)
            } else {
              return apiDelete('partsMulti', rows).then(forceReloadAvailableMaterials)
            }
          }
        },
    custom: [
      {
        name: 'Documents',
        render: (row) => {
          return (
            <IconButton
              onClick={() => {
                setSelectedRow(row)
                setShowDocument(true)
              }}
            >
              <TextSnippetOutlined />
            </IconButton>
          )
        }
      },
      {
        name: t_('View history'),
        render: (row) => {
          return (
            <IconButton
              onClick={(event) => {
                setSelectedRow(row)
                setShowPartHistory(true)
              }}
            >
              <WorkHistoryIcon />
            </IconButton>
          )
        }
      }, {
        name: 'View features',
        render: (row) => {
          return (
            <IconButton
              onClick={(event) => {
                setSelectedRow(row)
                setShowPartFeatures(true)
              }}
            >
              <SquareFootIcon />
            </IconButton>
          )
        }
      }
    ]
  }

  const onEditIndexChanged = (editIndex, row) => {
    setEditingRawMaterialId((row && row.rawMaterial) ? row.rawMaterial.id : null)
    setCurrentEditingRow(row || null)
    if (editIndex >= 0) {
      setCurrentEditingRow(row)
    }
  }

  const handleToggleClick = (toggleButton, filterName) => {
    const copyFilter = [...filters]
    const toggleButtonResult = toggleButton === null ? t_('NONE') : toggleButton

    copyFilter.find(filter => filter.name === filterName).key = toggleButtonResult
    setFilters(copyFilter)
  }

  const multipleSelectionActions = [
    {
      field: 'pause',
      render: (datafetch, index, pageable, multipleSelectionValue) => {
        return (
          <Button
            variant='outlined'
            sx={{ borderColor: 'transparent' }}
            color='warning'
            key={'key' + index}
            onClick={async () => {
              const pagination = { ...pageable }
              pagination.size = -1
              pagination.page = 0
              const data = await apiPut('part/pauseMulti/' + partReference.id, pagination)
              showAlert({
                title: t_('Part(s) has been paused'),
                severity: 'success'
              })
              toogleResetMultipleSelectionValue()
              if (data.length > 0) {
                const finishedParts = []
                const partsOnDevice = []
                // Separate different data
                data.forEach(element => {
                  if (element.status === 'FINISHED') {
                    finishedParts.push(element)
                  } else {
                    partsOnDevice.push(element)
                  }
                })
                setUnfinishedPartsPauseResume(finishedParts)
                setPartsOnDevicesToPause(partsOnDevice)
              }
            }}
          >
            <PauseCircle sx={{ marginRight: '0.5em' }} color='warning' />
            <Typography>{t_('Pause workflow')}</Typography>
          </Button>
        )
      }
    },
    {
      field: 'resume',
      render: (datafetch, index, pageable, multipleSelectionValue) => {
        return (
          <Button
            variant='outlined'
            sx={{ borderColor: 'transparent' }}
            color='success'
            key={'key' + index}
            onClick={async () => {
              const pagination = { ...pageable }
              pagination.size = -1
              pagination.page = 0
              const data = await apiPut('part/resumeMulti/' + partReference.id, pagination)
              showAlert({
                title: t_('Part(s) has been resumed'),
                severity: 'success'
              })
              toogleResetMultipleSelectionValue()
              if (data.length > 0) {
                setUnfinishedPartsPauseResume(data)
              }
            }}
          >
            <PlayCircleOutlineIcon sx={{ marginRight: '0.5em' }} color='success' />
            <Typography>{t_('Resume workflow')}</Typography>
          </Button>
        )
      }
    }
  ]

  const onPauseWorkflowForce = async (partId, endJob) => {
    partsOnDevicesToPause.shift()
    setPartsOnDevicesToPause([...partsOnDevicesToPause])
    await apiPut('part/pause/' + partId + '/' + endJob)
  }

  return (
    <>
      {AlertElement}
      <PausePartSelectEndJobDialog
        setOpen={unfinishedPartsPauseResume.length === 0 && partsOnDevicesToPause.length > 0 ? partsOnDevicesToPause[0].id : false}
        cancelCallback={() => {
          partsOnDevicesToPause.shift()
          setPartsOnDevicesToPause([...partsOnDevicesToPause])
        }}
        part={partsOnDevicesToPause.length > 0 ? partsOnDevicesToPause[0] : null}
        pauseNowCallback={() => { onPauseWorkflowForce(partsOnDevicesToPause[0].id, false) }}
        pauseAtJobEndCallback={() => { onPauseWorkflowForce(partsOnDevicesToPause[0].id, true) }}
      />
      <PauseResumeFinishedList
        setOpen={unfinishedPartsPauseResume.length > 0}
        parts={unfinishedPartsPauseResume}
        closeCallback={() => setUnfinishedPartsPauseResume([])}
      />
      <SmartDialog
        closeCallback={() => setShowDocument(false)}
        accept={false}
        cancel={false}
        close
        renderComponent={<Document reference={selectedRow} resourceType='PART' />}
        setOpen={showDocument}
      />
      <SmartDialog
        renderComponent={<PartHistory partId={selectedRow.id} />}
        setOpen={showPartHistory}
        cancel={false}
        accept={false}
        close
        closeCallback={() => { setShowPartHistory(false) }}
      />
      <SmartDialog
        renderComponent={<PartFeature part={selectedRow} />}
        setOpen={showPartFeatures}
        cancel={false}
        accept={false}
        close
        closeCallback={() => { setShowPartFeatures(false) }}
      />
      <SmartMateriaUITable
        // toolbar={defaultToolbar}
        columns={columns}
        title={t_('Parts')}
        dataFetch={getTableData}
        actions={actions}
        sortOptions={sortOptions}
        multipleSelection={!partReferenceFilter}
        multipleSelectionActions={multipleSelectionActions}
        resetMultipleSelectionValue={resetMultipleSelectionValue}
        onRowClick={(row) => {
          if (setSelectedPart && typeof setSelectedPart === 'function' && row.id) {
            setSelectedPart(row)
          }
        }}
        forceLoad={forceLoad}
        editingRow={(event) => {
          if (setEditingRow && typeof setEditingRow === 'function') {
            setEditingRow(event)
          }
        }}
        onEditIndexChanged={onEditIndexChanged}
        pageable
        filters={filters}
        onFilterToggleClick={(toggleButton, filterName) => handleToggleClick(toggleButton, filterName)}
      />
    </>
  )
}

export default Parts
