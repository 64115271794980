const topBarHeight = '84px'
const smartNavigatorHeaderPadding = '3rem'
const smartNavigatorHeaderHeight = '48px'
const smartNavigatorPadding = '48px'
const footer = '56px'

const FULL_HEIGHT = {
  WITH_NAV_BAR: 'calc(100vh - ' + topBarHeight + ')',
  WITH_NAV_BAR_AND_SMART_NAVIGATOR_AND_FOOTER: 'calc(100vh - ' + topBarHeight + ' - ' + smartNavigatorHeaderPadding + ' - ' + smartNavigatorPadding + ' - ' + smartNavigatorHeaderHeight + ' - ' + footer + ')'
}

export { FULL_HEIGHT }
