import { Sort } from '@mui/icons-material'
import { Grid, MenuItem, Paper, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import theme from '../../themeConfig'
import { PartHistory } from '../configuration/parts/partHistory'
import { apiGet, apiPut } from '../generic/Api_Functions'
import HomeCard from '../generic/HomeCard'
import { useSmartInterval } from '../generic/hooks/useSmartInterval'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import { SmartDialog } from '../generic/utilities/SmartDialog'
import GenericFloatingButton from './../generic/buttons/floatingButtons/GenericFloatingButton'
import ArrangeHomeDevices from './ArrangeHomeDevices'
import createDialogTitle from '../generic/utilities/dialogUtil'
import AlertUI from '../generic/AlertUI'
import useAuthorization from '../generic/hooks/useAuthorization/useAuthorization'
import PartProgressStepper from '../generic/PartProgressStepper'

export default function Home (props) {
  const [devices, setDevices] = useState([])
  const [actualLocations, setActualLocations] = useState({})
  const [actualLocationsPartProgress, setActualLocationsPartProgress] = useState([])
  const [partProgressId, setPartProgressId] = useState('')
  const { t_ } = useSmartTranslation()

  const [renderHistoryComponent, setRenderHistoryComponent] = useState(null)
  const [showResourceHistory, setShowResourceHistory] = useState(false)
  const [showArrangeDialog, setShowArrangeDialog] = useState(false)
  const [arrangeDevices, setArrangeDevices] = useState([])
  const [AlertElement, showAlert] = AlertUI()

  const [countVisibles, setCountVisibles] = useState(0)
  const isUserAuthorized = useAuthorization()

  const getIntervalData = useCallback(async (newPartProgressId) => {
    if (!newPartProgressId) {
      newPartProgressId = partProgressId
    }
    const devs = await apiGet('devicesHomeSummary')
    setCountVisibles(checkVisibleDevices(devs))
    const locs = await apiGet('fixtureLocations/actual')
    let progs = []
    if (newPartProgressId !== '') {
      progs = await apiGet('part/actualPartProgress/' + newPartProgressId)
    }
    return {
      devices: devs,
      locations: locs,
      progress: progs
    }
  }, [partProgressId])

  const setIntervalData = useCallback((intervalData) => {
    setDevices(intervalData.devices)
    const actualLocations = intervalData.locations.filter(actualLocation => actualLocation.showWorkflowSteps).sort((a, b) => a.resources[0].code.localeCompare(b.resources[0].code))
    setActualLocationsPartProgress(actualLocations)

    // Set locations per device
    const locationsPerDevice = {}
    intervalData.locations.forEach(location => {
      if (!(location.deviceId in locationsPerDevice)) {
        locationsPerDevice[location.deviceId] = []
      }
      locationsPerDevice[location.deviceId].push(location)
    })
    // Order per device
    for (const deviceId in locationsPerDevice) {
      locationsPerDevice[deviceId] = locationsPerDevice[deviceId].sort((a, b) => {
        return moment(a.dateIn) - moment(b.dateIn)
      })
    }

    setActualLocations(locationsPerDevice)

    // Check if selected part is still valid
    if (partProgressId !== '') {
      if (actualLocations.filter((location) => location.resources[0].id === partProgressId).length === 0) {
        setPartProgressId('')
      }
    }
  }, [partProgressId])
  const checkVisibleDevices = (devices) => {
    let count = 0
    devices.forEach(element => {
      if (element.homeVisible) {
        count++
      }
    })
    return count
  }
  const onPartProgressIdSelected = useCallback(async (newId) => {
    setPartProgressId(newId)
    const data = await getIntervalData(newId)
    setIntervalData(data)
  }, [getIntervalData, setIntervalData])

  useSmartInterval(getIntervalData, 5000, setIntervalData)

  // Set first item selected by default
  useEffect(() => {
    if (partProgressId === '' && actualLocationsPartProgress.length > 0) {
      if (actualLocationsPartProgress.filter(actualLocation => actualLocation.resources[0].id === partProgressId).length === 0) {
        onPartProgressIdSelected(actualLocationsPartProgress[0].resources[0].id)
      }
    }
  }, [actualLocationsPartProgress, partProgressId, onPartProgressIdSelected])

  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  const unsupportedResourceMessage = (resourceType) => (
    <Typography variant='h6' sx={{ textAlign: 'center' }}>
      {t_('traceabilityNotAvailable', { resourceType: t_(resourceType.toLowerCase()) })}
    </Typography>
  )

  const handleResourceHistory = (resource) => {
    if (resource?.type.name === 'Part') {
      setRenderHistoryComponent(<PartHistory partId={resource.id} />)
    } else {
      setRenderHistoryComponent(unsupportedResourceMessage(resource?.type.name))
    }
    setShowResourceHistory(true)
  }
  const setVisibility = (device, visibility) => {
    arrangeDevices.forEach(element => {
      if (element.id === device) {
        element.homeVisible = visibility
      }
    })
    setArrangeDevices([...arrangeDevices])
  }
  const handleSortingChange = (sortedParameters) => {
    // Sorting change has been performed
    let countOrder = 1
    sortedParameters.forEach(element => {
      element.homeOrder = countOrder
      countOrder++
    })
    setArrangeDevices(sortedParameters)
  }
  const saveSorting = () => {
    apiPut('device/orderAndVisibility', arrangeDevices)
      .then(showAlert(operationSuccessAlert))
      .catch(error => {
        showAlert(operationFailedAlert(error.message))
      }
      )
    setShowArrangeDialog(false)
  }
  const operationFailedAlert = (error) => {
    return {
      title: t_('Update error'),
      severity: error.name,
      message: (
        <div>
          <div>{t_('If error persists, contact with support:')}</div>
          <div>{error}</div>
        </div>)
    }
  }

  const operationSuccessAlert = {
    severity: 'success',
    title: t_('Update successful'),
    message: t_('The operation was correctly performed')
  }

  return (
    <div>
      <SmartDialog
        setOpen={showResourceHistory}
        renderComponent={renderHistoryComponent}
        accept={false}
        close
        cancel={false}
        closeCallback={() => { setShowResourceHistory(false) }}
      />
      <SmartDialog
        title={createDialogTitle({ title: t_('MONITORING ASSET ORDER'), icon: Sort })}
        setOpen={showArrangeDialog}
        renderComponent={<ArrangeHomeDevices devices={arrangeDevices} setVisibility={setVisibility} sortingChange={handleSortingChange} />}
        accept
        cancel
        cancelCallback={() => { setShowArrangeDialog(false) }}
        acceptCallback={() => { saveSorting() }}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction='row'
                  justifyContent={!isSmall ? 'flex-start' : 'center'}
                  alignItems='center'
                >
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    {actualLocationsPartProgress.length === 0
                      ? null
                      : (
                        <TextField
                          select
                          sx={{ marginBottom: '1em' }}
                          id='PartProgressSelect'
                          variant='outlined'
                          value={partProgressId}
                          onChange={(event) => {
                            onPartProgressIdSelected(event.target.value)
                          }}
                        >
                          {
                          actualLocationsPartProgress.map((actualLocation) => (
                            <MenuItem key={actualLocation.resources[0].id} value={actualLocation.resources[0].id}>
                              {actualLocation.resources[0].code}
                            </MenuItem>
                          ))
                        }
                        </TextField>)}
                  </Grid>
                  <Grid item xs={12}>
                    <PartProgressStepper partId={partProgressId} partProgress={actualLocationsPartProgress} />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Paper>
          {AlertElement}
        </Grid>
        {
          devices.map((device, index) => (
            device.homeVisible
              ? (
                <Grid key={device.name + index} item xs={12} md={12} lg={6} xl={4}>
                  <HomeCard
                    locations={device.id in actualLocations ? actualLocations[device.id] : []}
                    device={device}
                    key={device.name + index}
                    onItemClick={(resource) => { handleResourceHistory(resource) }}
                  />
                </Grid>)
              : null
          ))
        }
        {
          countVisibles === 0 && devices.length > 0 ? <Stack direction='row' justifyContent='center' sx={{ width: '100%' }}><h4>{t_('All assets are hidden.To show them use the arrange button below')}</h4></Stack> : null
}
        <Stack direction='row' justifyContent='end' sx={{ position: 'fixed', left: 0, bottom: 50, right: 100, zIndex: '300' }}>
          <GenericFloatingButton
            label={t_('Arrange')}
            onClick={() => { setShowArrangeDialog(true); setArrangeDevices(structuredClone(devices)) }}
            color={theme.palette.primary.main}
            opacity={1}
            icon=''
            disabled={!isUserAuthorized('ARRANGE')}
          />
        </Stack>
      </Grid>
    </div>
  )
}
