import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import { useMediaQuery, useTheme } from '@mui/material'
import {
  PendingActions,
  CoPresent,
  VideoSettings,
  ManageHistory,
  Troubleshoot
} from '@mui/icons-material'
import CompassButton from '../Compass/Common/CompassButton/CompassButton'
import { useAuthorization } from '../generic/hooks/useAuthorization'

const MainMenu = () => {
  const navigate = useNavigate()
  const { t_ } = useSmartTranslation()
  const theme = useTheme()
  const isUpXLarge = useMediaQuery(theme.breakpoints.up('xl'))
  const style = {
    display: 'flex',
    flexDirection: isUpXLarge ? 'row' : 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '5rem',
    marginBlock: '10rem'
  }
  const isUserAuthorized = useAuthorization()

  return (
    <>
      <div style={style}>
        <CompassButton
          onClick={() => navigate('/compass/presence')}
          color='primary'
          backgroundColor={theme => theme.palette.background.default}
          label={t_('Presence')}
          labelStyle={{
            fontSize: '1.5rem',
            color: !isUserAuthorized('COMPASS PRESENCE') ? 'grey' : theme.palette.text.primary
          }}
          width='25rem'
          icon={<CoPresent />}
          disabled={!isUserAuthorized('COMPASS PRESENCE')}
        />
        <CompassButton
          onClick={() => navigate('/compass/production')}
          color='primary'
          backgroundColor={theme => theme.palette.background.default}
          label={t_('Production')}
          labelStyle={{
            fontSize: '1.5rem',
            color: !isUserAuthorized('COMPASS PRODUCTION') ? 'grey' : theme.palette.text.primary
          }}
          width='25rem'
          icon={<VideoSettings />}
          disabled={!isUserAuthorized('COMPASS PRODUCTION')}
        />
      </div>
      <div style={style}>
        <CompassButton
          onClick={() => navigate('/compass/supervision')}
          color='primary'
          backgroundColor={theme => theme.palette.background.default}
          label={t_('Supervision')}
          labelStyle={{
            fontSize: '1.5rem',
            color: !isUserAuthorized('COMPASS SUPERVISION') ? 'grey' : theme.palette.text.primary
          }}
          width='25rem'
          icon={<ManageHistory />}
          disabled={!isUserAuthorized('COMPASS SUPERVISION')}
        />
        <CompassButton
          onClick={() => navigate('/compass/process')}
          color='primary'
          backgroundColor={theme => theme.palette.background.default}
          label={t_('Process')}
          labelStyle={{
            fontSize: '1.5rem',
            color: !isUserAuthorized('COMPASS PROCESS') ? 'grey' : theme.palette.text.primary
          }}
          width='25rem'
          icon={<PendingActions />}
          disabled={!isUserAuthorized('COMPASS PROCESS')}
        />
        <CompassButton
          onClick={() => navigate('/compass/inspect')}
          color='primary'
          backgroundColor={theme => theme.palette.background.default}
          label={t_('Inspection')}
          labelStyle={{
            fontSize: '1.5rem',
            color: !isUserAuthorized('COMPASS INSPECTION') ? 'grey' : theme.palette.text.primary
          }}
          width='25rem'
          icon={<Troubleshoot />}
          disabled={!isUserAuthorized('COMPASS INSPECTION')}
        />
      </div>
    </>
  )
}
export default MainMenu
