import Rule from '../configuration/rule/Rule'
import SmartNavigator from '../generic/SmartNavigator'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

const MainRule = (props) => {
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('Rule'),
      component: <Rule />
    }
  ]
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  )
}

export default MainRule
