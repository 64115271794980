import { apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const FabricationOrderStatusTypes = () => {
  const { t_ } = useSmartTranslation()

  const columns = [

    {
      name: t_('Code'),
      field: 'code',
      edit: false
    },
    {
      name: t_('Name'),
      field: 'name',
      edit: false

    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    }
  ]

  const actions = {

    edit: {
      fetchFunction: (row) => {
        return apiPut('fabricationOrderStatusType', row)
      }
    }

  }

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Fabrication order status types')}
      dataFetch='fabricationOrderStatusType'
      actions={actions}
      pageable

    />
  )
}

export default FabricationOrderStatusTypes
