import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { NotificationsActiveRounded, WarningRounded } from '@mui/icons-material';
import { useRef } from 'react';
import { useSmartTranslation } from '../../hooks/useSmartTranslation';


export default function ToggleButtonAlarmNotifications(props) {
  const { selectedNotifications, selectedAlarms, setSelectedNotifications, setSelectedAlarms } = props
  const [formats, setFormats] = React.useState(() => [selectedNotifications ? "NOTIFICATIONS" : '', selectedAlarms ? "ALARMS" : '']);
  const { t_ } = useSmartTranslation();

  const disableButtons = useRef(false)

  const handleFormat = (event, newFormats) => {
    disableButtons.current = true;
    setSelectedNotifications(newFormats.find(format => format === "NOTIFICATIONS"))
    setSelectedAlarms(newFormats.find(format => format === "ALARMS"))
    setFormats(newFormats);
    // setTimeout(function () {
    //   disableButtons.current = false;
    // }, 1000);
  };

  return (
    <ToggleButtonGroup
      value={formats}
      onChange={handleFormat}
      aria-label="text formatting"
    // disabled={disableButtons.current}
    >
      <ToggleButton value="NOTIFICATIONS" aria-label="NOTIFICATIONS" color="info" >
        <NotificationsActiveRounded />
        {t_("Notifications")}
      </ToggleButton>
      <ToggleButton value="ALARMS" color="warning" >
        <WarningRounded />
        {t_("Alarms")}
      </ToggleButton>
    </ToggleButtonGroup >
  );
}