import React, { useState } from 'react'
import { apiGet } from '../../../generic/Api_Functions'
import SmartMateriaUITable from '../../../generic/SmartMateriaUITable'
import AlertUI from '../../../generic/AlertUI'
import { IconButton } from '@mui/material'
import { SmartDialog } from '../../../generic/utilities/SmartDialog'

import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'

import { PanTool } from '@mui/icons-material'
import RequestControlledStopDialog from './RequestControlledStopDialog'

const WorkingParts = (props) => {
  const [AlertElement, showAlert] = AlertUI()
  const [selectedRow, setSelectedRow] = useState({})
  const [showStopDialog, setShowStopDialog] = useState(false)
  const { t_ } = useSmartTranslation()

  const getTableData = async (pagination) => {
    const parts = await apiGet('workingParts/', pagination)
    if (selectedRow.id) {
      const selected = parts.content.find((p) => p.id === selectedRow.id)
      if (selected) {
        if (selected.status !== 'FINISHED') {
          setSelectedRow(selected)
        }
      } else {
        setSelectedRow({})
        setShowStopDialog(false)
      }
    }
    return parts
  }

  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      readOnly: true
    },
    {
      name: t_('Name'),
      field: 'name',
      readOnly: true
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200,
      readOnly: true
    },
    {
      name: t_('Raw Material Reference'),
      field: 'rawMaterialReference.name',
      readOnly: true
    },
    {
      name: t_('Fixture'),
      field: 'fixture.code',
      readOnly: true
    },
    {
      name: t_('Location'),
      field: 'location.name',
      readOnly: true
    },
    {
      name: t_('Shelf'),
      field: 'shelveStorageConfiguration.shelve',
      readOnly: true
    },
    {
      name: t_('Row'),
      field: 'shelveStoragePosition.position',
      readOnly: true
    },
    {
      name: t_('Column'),
      field: 'shelveStoragePosition.depth',
      readOnly: true
    },
    {
      name: t_('Client'),
      field: 'client.name',
      readOnly: true
    },
    {
      name: t_('Fabrication Order'),
      field: 'fabricationOrder.fabricationOrder',
      readOnly: true
    }
  ]

  const actions = {
    custom: [
      {
        name: 'Stop after this part',
        render: (row) => {
          return (
            <IconButton
              onClick={() => {
                setSelectedRow(row)
                setShowStopDialog(true)
              }}
            >
              <PanTool />
            </IconButton>
          )
        }
      }
    ]
  }

  return (
    <>
      {AlertElement}
      <SmartMateriaUITable
        columns={columns}
        title={t_('Working Parts')}
        dataFetch={getTableData}
        actions={actions}
        multipleSelection={false}
        pageable
      />
      <SmartDialog
        renderComponent={<RequestControlledStopDialog part={selectedRow} closeDialog={() => setShowStopDialog(false)} showAlert={showAlert} />}
        setOpen={showStopDialog}
        cancel={false}
        accept={false}
        onClose={() => setShowStopDialog(false)}
      />

    </>
  )
}

export default WorkingParts
