import { DeleteRounded } from '@mui/icons-material'
import { Card, CardContent, IconButton, MenuItem, TextField } from '@mui/material'
import React from 'react'
import { EdgeLabelRenderer, getBezierPath, useReactFlow } from 'reactflow'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'
import { useTheme } from '@emotion/react'

export default function CustomEdge ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data = {},
  markerEnd,
  source,
  target
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })

  const { getEdges, setEdges } = useReactFlow()
  const { t_ } = useSmartTranslation()

  const strokeWidth = 5

  const theme = useTheme()

  const getStyle = () => {
    switch (data.type) {
      case 'OK':
        return { stroke: data.selected ? theme.palette.success.main : theme.palette.success.dark, strokeWidth }
      case 'NOT_OK':
        return { stroke: data.selected ? theme.palette.error.main : theme.palette.error.dark, strokeWidth }
      default:
        return { stroke: data.selected ? theme.palette.grey['50'] : theme.palette.grey['600'], strokeWidth }
    }
  }

  const onConnectivityChange = (newValue) => {
    const newEdgeValues = getEdges().map((edge) => {
      if (edge.id === id) {
        edge.data = { ...edge.data }
        edge.data.connectivity = newValue
      }
      return edge
    })

    setEdges(newEdgeValues)
  }

  return (
    <>
      <path
        id={id}
        style={getStyle()}
        className='react-flow__edge-path'
        d={edgePath}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <Card
          hidden={!data.selected} style={{
            backgroundColor: theme.palette.background.default,
            color: 'white',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            zIndex: '100000000',
            width: '10rem',
            height: '6rem',
            position: 'absolute'
          }}
        >
          <CardContent>
            <IconButton
              // disabled={data.workflowInUse}
              color='error'
              style={{
                zIndex: '100000',
                position: 'absolute',
                transform: 'translate(150%, 130%)',
                pointerEvents: 'all',
                scale: '70%'
              }}
              className='nodrag'
              onClick={() => {
                setEdges((es) => es.filter((e) => e.id !== id))
              }}
            >
              <DeleteRounded />
            </IconButton>
            <TextField
              select
              label={t_('Connectivity')}
              style={{
                zIndex: '100000',
                position: 'absolute',
                transform: 'translate(-15%, -30%)',
                pointerEvents: 'all',
                width: '10rem',
                scale: '70%'
              }}
              InputLabelProps={{
                shrink: true
              }}
              className='nodrag'
              onChange={(event) => onConnectivityChange(event.target.value)}
              value={data.connectivity}
              disabled={source === 'start' || target === 'end'}
            >
              <MenuItem key='FLEXIBLE' value='FLEXIBLE'>{t_('FLEXIBLE')}</MenuItem>
              <MenuItem key='MANDATORY' value='MANDATORY'>{t_('MANDATORY')}</MenuItem>
            </TextField>
          </CardContent>
        </Card>
      </EdgeLabelRenderer>
    </>
  )
}
