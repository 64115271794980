import { IconButton } from '@mui/material'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { Close, Done } from '@mui/icons-material'
import { useToggle } from '../../generic/hooks/useToggle'
import { apiDelete, apiPut, apiGet } from '../../generic/Api_Functions'

const ProfilePermissionAccordionChilds = ({ profileId, systemDefault, parentPermission, viewAll }) => {
  const { t_ } = useSmartTranslation()
  const [toggle, changeToogle] = useToggle()

  const sortOptions = {
    default: {
      field: 'assigned',
      direction: -1
    }
  }

  const handleClick = async (row, field) => {
    if (row.assigned) {
      await apiDelete('profile/' + profileId + '/permission/' + row.id)
    } else {
      await apiPut('profile/' + profileId + '/permission/' + row.id)
    }
    changeToogle()
  }

  const getTableButton = (row, field) => {
    return (
      <IconButton disabled={systemDefault} onClick={() => handleClick(row, field)}>
        {row[field] ? <Done color='primary' /> : <Close color='error' />}
      </IconButton>
    )
  }

  const columns = [
    {
      name: t_('Code'),
      field: 'code'
    },
    {
      name: t_('Name'),
      field: 'name'
    },
    {
      name: t_('Assigned'),
      field: 'assigned',
      render: row => getTableButton(row, 'assigned')
    }
  ]

  const dataFetch = async (pagination) => {
    const childPermissions = await apiGet('profile/' + profileId + '/childPermissions/' + !viewAll + '/' + parentPermission.id, pagination)
    return childPermissions
  }

  const actions = {
    accordion: {
      render: (row) => {
        return <ProfilePermissionAccordionChilds parentPermission={row} profileId={profileId} systemDefault={systemDefault} viewAll={viewAll} />
      },
      hide: (row) => {
        return !row.hasAnyChild
      }
    }
  }

  return (
    <SmartMateriaUITable
      columns={columns}
      dataFetch={dataFetch}
      pageable
      forceLoad={toggle}
      resetPage={toggle}
      sortOptions={sortOptions}
      actions={actions}
      toolbarDisplay='none'
      searchDisplay='none'
      dense
      sticky={false}
    />
  )
}

export default ProfilePermissionAccordionChilds
