import Typography from '@mui/material/Typography'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { apiPost } from '../../generic/Api_Functions'
import ButtonBack from '../../generic/buttons/floatingButtons/ButtonBack'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ProductionQuantity () {
  const { t_ } = useSmartTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { urlBack, device } = location.state
  const datafetch = async (pagination) => {
    const response = await apiPost('compass/fabricationOrderReportQuantity/' + device.id, pagination)
    return response
  }

  const columns = [
    {
      field: 'fabricationOrder',
      name: t_('Fabrication Order')
    },
    {
      field: 'jobReference',
      name: t_('Job')
    },
    {
      field: 'qualityType',
      name: t_('Quality type')
    },
    {
      field: 'quantity',
      name: t_('Quantity')
    }
  ]
  const goBack = () => {
    navigate(urlBack)
  }
  const actions = {
    create: {
    }
  }
  return (
    <>
      <SmartMateriaUITable
        title={<Typography color='secondary' style={{ width: '100%' }}> {t_('Quantity report')} | {device.name}</Typography>}
        dataFetch={datafetch}
        pageable
        columns={columns}
        actions={actions}
        onAddElementClick={() => { navigate('/compass/production/quantity/report/' + device.id, { state: { urlBack: '/compass/production', device } }) }}
      />

      <div style={{ width: '100%', float: 'right' }}>
        <ButtonBack buttonAction={goBack} />
      </div>
    </>
  )
}
