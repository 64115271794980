import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { Button, ButtonGroup, Paper, Stack, Typography } from '@mui/material'
import { useCallback, useMemo, useRef } from 'react'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { getBackendUrl } from '../../../../generic/Api_Functions'
import Proptypes from 'prop-types'

/**
 *
 * @param {object} props Props object
 * @param {number} props.documentId Document id
 * @param {string} props.documentName Document name
 */
const SmartReactDocViewer = ({ documentId, documentName }) => {
  const stackRef = useRef(null)

  const myHeader = useCallback((state, previousDocument, nextDocument) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
      return null
    }
    return (
      <Paper>
        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>
          <Typography sx={{ padding: '10px', justifyContent: 'flex-start' }}>{documentName}</Typography>
          <ButtonGroup>
            {stackRef.current != null && document.fullscreenElement === stackRef.current ? <Button onClick={() => document.exitFullscreen()}>X</Button> : <Button onClick={() => stackRef.current.requestFullscreen()}>Fullscreen</Button>}
          </ButtonGroup>
        </Stack>
      </Paper>
    )
  }, [documentName])

  const documents = useMemo(() => {
    return [{ uri: getBackendUrl() + 'document/file/download/' + documentId }]
  }, [documentId])

  const config = useMemo(() => {
    return {
      header: {
        overrideComponent: myHeader
      }
    }
  }, [myHeader])

  return (
    <Stack sx={{ color: '#000000 !important' }}>
      <div ref={stackRef}>
        <DocViewer
          documents={documents}
          pluginRenderers={DocViewerRenderers}
          config={config}
        />
      </div>
    </Stack>
  )
}

SmartReactDocViewer.propTypes = {
  documentId: Proptypes.number.isRequired,
  documentName: Proptypes.string.isRequired
}

export default SmartReactDocViewer
