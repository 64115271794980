import { Stack } from '@mui/material'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'
import SmartSelect from '../../../generic/smartSelect/SmartSelect'

const MoveRequestShelvePosition = (props) => {
  const { t_ } = useSmartTranslation()

  const { positions, disabled, position, onChange, showErrors } = props

  const shelves = Array.from(new Set(positions.map(item => item.shelve.toString())))
  const rows = Array.from(new Set(positions.filter(item => position?.shelf === item.shelve).map(item => item.row?.toString())))
  const columns = Array.from(new Set(positions.filter(item => position?.shelf === item.shelve && position?.row === item.row).map(item => item.column?.toString())))
  return (
    <Stack direction='row' gap={5}>
      <SmartSelect
        value={position?.shelf?.toString()}
        selectableOptions={shelves}
        onChange={(newValue) => { onChange({ type: 'setShelf', value: parseInt(newValue, 10) }) }}
        label={t_('Shelf')}
        error={!disabled && showErrors && !position}
        disabled={disabled}
        minWidth='5rem'
        fontSize='1.2rem'
      />
      <SmartSelect
        value={position?.row?.toString()}
        selectableOptions={rows}
        onChange={(newValue) => { onChange({ type: 'setRow', value: parseInt(newValue, 10) }) }}
        label={t_('Row')}
        error={!disabled && showErrors && position && !position.row}
        disabled={disabled || !position || !position.shelf}
        minWidth='5rem'
        fontSize='1.2rem'
      />
      <SmartSelect
        value={position?.column?.toString()}
        selectableOptions={columns}
        onChange={(newValue) => { onChange({ type: 'setColumn', value: parseInt(newValue, 10) }) }}
        label={t_('Column')}
        disabled={disabled || !position || !position.row}
        error={!disabled && showErrors && position && position.row && !position.column}
        minWidth='5rem'
        fontSize='1.2rem'
      />
    </Stack>
  )
}

export default MoveRequestShelvePosition
