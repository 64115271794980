import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const IncidenceType = () => {
  const { t_ } = useSmartTranslation()

  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      edit: false,
      charLimit: 60,
      mandatory: true,
      unique: true
    },
    {
      name: t_('Name'),
      field: 'name',
      edit: true,
      charLimit: 80,
      mandatory: true,
      unique: true
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    },
    {
      name: t_('Device type'),
      field: 'deviceTypeSet.name',
      type: 'smartMultiSelect',
      edit: {
        source: async () => {
          return await apiGet('deviceTypes')
        },
        id: 'id',
        field: 'name'
      },
      mandatory: true

    }
  ]

  const actions = {
    create: {
      fetchFunction: (incidenceType) => apiPost('incidenceType', incidenceType)
    },
    edit: {
      fetchFunction: (incidenceType) => apiPut('incidenceType', incidenceType)
    },

    delete: {
      fetchFunction: (incidenceType) => {
        if (incidenceType.length === 1) {
          return apiDelete('incidenceType/' + incidenceType[0].id)
        } else {
          return apiDelete('incidenceTypeMulti', incidenceType)
        }
      }
    }
  }

  const dataFetch = async (pagination) => {
    const orders = await apiGet('incidenceTypes', pagination)
    return orders
  }

  return (
    <>
      <SmartMateriaUITable
        title={t_('Incidence Types')}
        columns={columns}
        dataFetch={dataFetch}
        actions={actions}
        multipleSelection
        pageable
      />
    </>
  )
}

export default IncidenceType
