// Adapted from https://github.com/ashiguruma/patternomaly
class Shape {
  constructor (size = 20, backgroundColor, patternColor) {
    this._canvas = document.createElement('canvas')
    this._context = this._canvas.getContext('2d')

    this._canvas.width = size
    this._canvas.height = size

    this._context.fillStyle = backgroundColor
    this._context.fillRect(0, 0, this._canvas.width, this._canvas.height)

    this._size = size
    this._patternColor = patternColor

    return this
  }

  setStrokeProps () {
    this._context.strokeStyle = this._patternColor
    this._context.lineWidth = this._size / 10
    this._context.lineJoin = 'round'
    this._context.lineCap = 'round'
  }

  setFillProps () {
    this._context.fillStyle = this._patternColor
  }
}

class Diagonal extends Shape {
  drawTile () {
    const halfSize = this._size / 2

    this._context.beginPath()

    this.setStrokeProps()

    this.drawDiagonalLine()
    this.drawDiagonalLine(halfSize, halfSize)

    this._context.stroke()

    return this._canvas
  }

  drawDiagonalLine (offsetX = 0, offsetY = 0) {
    const size = this._size
    const halfSize = size / 2
    const gap = 1

    this._context.moveTo((halfSize - gap) - offsetX, (gap * -1) + offsetY)
    this._context.lineTo((size + 1) - offsetX, (halfSize + 1) + offsetY)

    this._context.closePath()
  }
}

export function draw (
  backgroundColor,
  patternColor,
  size
) {
  const patternCanvas = document.createElement('canvas')
  const patternContext = patternCanvas.getContext('2d')
  const outerSize = size * 2

  const shape = new Diagonal(size, backgroundColor, patternColor)

  const pattern = patternContext.createPattern(shape.drawTile(), 'repeat')

  patternCanvas.width = outerSize
  patternCanvas.height = outerSize

  return pattern
}
