import { NotificationsNoneRounded, OpenInNewRounded, ReportProblemOutlined } from '@mui/icons-material'
import { Badge, Typography, Button, IconButton } from '@mui/material'
import { useUncheckedAlarms } from '../../generic/hooks/useUncheckedAlarms'
import Alarms from '../../maintenance/alarms&notifications/alarms/Alarms'
import Notifications from '../../maintenance/alarms&notifications/notifications/Notifications'
import { useNavigate } from 'react-router'
import { apiPut } from '../../generic/Api_Functions'
import { useRef, useState } from 'react'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import { useAuthorization } from '../../generic/hooks/useAuthorization'

const warningBadgeStyle = {
  '.MuiBadge-badge': {
    color: 'black'
  }
}

const TopBarBadge = () => {
  const { t_ } = useSmartTranslation()
  const { uncheckedNotifications, uncheckedAlarms } = useUncheckedAlarms() // const { toolbar } = props
  const pendingChanges = useRef({ allSelected: false, allSelectedChecked: false, rows: {} })
  const navigate = useNavigate()
  const isUserAuthorized = useAuthorization()
  const goto = (value) => {
    navigate(value)
  }
  const buttonGoToAlarms = {
    render: {
      fetchFunction: () => {
        return (
          <Button color='primary' onClick={() => { goto('maintenance/alarms'); setDialogStatus({ open: false }) }}>
            <OpenInNewRounded sx={{ fontSize: '1.2em' }} />
            <Typography sx={{ marginLeft: '0.4em', fontSize: '0.875rem' }}> {t_('VIEW ALL')}</Typography>
          </Button>
        )
      }
    }
  }

  const buttonGoToNotifications = {
    render: {
      fetchFunction: () => {
        return (
          <Button color='primary' onClick={() => { goto('/maintenance/notifications'); setDialogStatus2({ open: false }) }}>
            <OpenInNewRounded sx={{ fontSize: '1.2em' }} />
            <Typography sx={{ marginLeft: '0.4em', fontSize: '0.875rem' }}> {t_('VIEW ALL')}</Typography>
          </Button>
        )
      }
    }
  }

  const updateErrorLogNotificationsCheckState = () => {
    apiPut('setAllNotificationsRead')
  }

  const updateErrorLogAlarmsCheckState = () => {
    // Get pending changes and update according
    if (pendingChanges.current.allSelected) {
      const url = 'setErrorLog' + (pendingChanges.current.allSelectedChecked ? 'Checked' : 'UnChecked') + ('/ERR,WAR')
      apiPut(url, pendingChanges.current.pageable)
    } else {
      const idToCheck = []
      const idToUncheck = []
      for (const key in pendingChanges.current.rows) {
        if (pendingChanges.current.rows[key]) {
          idToCheck.push(key)
        } else {
          idToUncheck.push(key)
        }
      }
      // Send the ones that must be checked
      if (idToCheck.length > 0) {
        apiPut('setErrorLogsChecked', idToCheck)
      }

      // Send the ones that must be unchecked
      if (idToUncheck.length > 0) {
        apiPut('setErrorLogsUnChecked', idToUncheck)
      }
    }
  }

  const handleNotificationCLick = () => {
    setDialogStatus2({ open: true })
  }
  const [dialogStatus, setDialogStatus] = useState({ open: false })
  const [dialogStatus2, setDialogStatus2] = useState({ open: false })
  const handleAlarmCLick = () => {
    // Clear pending changes
    pendingChanges.current = { allSelected: false, allSelectedChecked: false, rows: {} }
    setDialogStatus({ open: true })
  }

  return (
    <>
      <SmartDialog
        cancel
        acceptCallback={() => {
          updateErrorLogAlarmsCheckState()
          setDialogStatus({ open: false })
        }}
        cancelCallback={() => setDialogStatus({ open: false })}
        accept
        close={false}
        renderComponent={
          <Alarms toolbar={buttonGoToAlarms} unChecked pendingChanges={pendingChanges} />
        }
        setOpen={dialogStatus.open}
      />
      <SmartDialog
        cancel
        acceptCallback={() => {
          updateErrorLogNotificationsCheckState()
          setDialogStatus2({ open: false })
        }}
        cancelCallback={() => setDialogStatus2({ open: false })}
        accept
        close={false}
        renderComponent={
          <Notifications toolbar={buttonGoToNotifications} unChecked pendingChanges={pendingChanges} />
        }
        setOpen={dialogStatus2.open}
      />

      <div style={{ display: 'flex', justifyContent: 'center', width: '100px', marginLeft: '10px', marginRight: '10px' }}>
        <IconButton onClick={() => handleNotificationCLick()} disabled={!isUserAuthorized('NOTIFICATIONS')}>
          <Badge badgeContent={uncheckedNotifications} color='info'>
            <NotificationsNoneRounded />
          </Badge>
        </IconButton>
        <IconButton onClick={() => handleAlarmCLick()} disabled={!isUserAuthorized('ALARMS')}>
          <Badge badgeContent={uncheckedAlarms} color='warning' sx={warningBadgeStyle}>
            <ReportProblemOutlined />
          </Badge>
        </IconButton>

      </div>
    </>
  )
}
export default TopBarBadge
