import React from 'react'
import SmartNavigator from '../generic/SmartNavigator'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import ReasonType from '../configuration/reasons/ReasonType'
import Reason from '../configuration/reasons/Reason'

const MainReason = () => {
  const { t_ } = useSmartTranslation()
  const componentsInfo = [
    {
      title: t_('Reasons'),
      component: <Reason />
    },
    {
      title: t_('Reason Types'),
      component: <ReasonType />
    }
  ]
  return <SmartNavigator componentsInfo={componentsInfo} />
}
export default MainReason
