import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const FabricationOrderStatus = () => {
  const { t_ } = useSmartTranslation()

  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      unique: true,
      mandatory: true,
      readOnly: true,
      charLimit: 60
    },
    {
      name: t_('Name'),
      field: 'name',
      unique: true,
      mandatory: true,
      charLimit: 80,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    },
    {
      name: t_('FO status type'),
      field: 'fabricationOrderStatusType.name',
      type: 'smartSelect',
      edit: {
        source: async () => {
          return await apiGet('fabricationOrderStatusTypeBasicWithoutWaiting')
        },
        id: 'id',
        field: 'name'
      },
      readOnly: (row) => {
        return row.systemDefault
      },
      mandatory: true
    }
  ]

  const actions = {
    create: {
      fetchFunction: (row) => {
        return apiPost('fabricationOrderStatus/create', row)
      }
    },
    edit: {
      fetchFunction: (row) => {
        return apiPut('fabricationOrderStatus', row)
      }
    },
    delete: {
      visibilityButton: row => !row.systemDefault,
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('fabricationOrderStatus/' + rows[0].id)
        } else {
          return apiDelete('fabricationOrderStatus/deleteMulti/', rows)
        }
      }
    }
  }
  const getData = async (pagination) => {
    return await apiGet('fabricationOrderStatus', pagination)
  }

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Fabrication order status')}
      dataFetch={getData}
      actions={actions}
      pageable
      multipleSelection
    />
  )
}

export default FabricationOrderStatus
