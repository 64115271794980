export const GetDate = (unixTimestamp) => {
  if (unixTimestamp === null || unixTimestamp === undefined || unixTimestamp === '') {
    return null
  }
  const date = new Date(unixTimestamp)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  const year = date.getFullYear()
  const month = months[date.getMonth()]
  const day = date.getDate()

  const time = day + '/' + month + '/' + year

  return time
}

export const GetTime = (unixTimestamp) => {
  const time = new Date(unixTimestamp)
  const hours = time.getHours()
  const minutes = '0' + time.getMinutes()
  const seconds = '0' + time.getSeconds()

  const formattedTime =
    hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)

  return formattedTime
}
export const GetUtcTime = (unixTimestamp) => {
  const date = new Date(unixTimestamp)

  const hours = date.getUTCHours().toString().padStart(2, '0')
  const minutes = date.getUTCMinutes().toString().padStart(2, '0')
  const seconds = date.getUTCSeconds().toString().padStart(2, '0')

  const formattedTime = hours + ':' + minutes + ':' + seconds

  return formattedTime
}

export const GetDateTime = (unixTimestamp) => {
  // Do not parse empty strings or undefined
  if (!unixTimestamp) {
    return ''
  }
  return GetDate(unixTimestamp) + '  ' + GetTime(unixTimestamp)
}

export const GetActualDateTime = () => {
  const time = new Date()
  const n = time.getTime()

  return GetDate(n) + '  ' + GetTime(n)
}

export const GetDateNumeric = (unixTimestamp) => {
  const date = new Date(unixTimestamp)
  const year = date.getFullYear()
  const month = addLeadingZerosToTime(date.getMonth() + 1, 2)
  const day = addLeadingZerosToTime(date.getDate(), 2)

  const time = month + '/' + day + '/' + year

  return time
}

export const GetActualDateTimeToExport = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = addLeadingZerosToTime(date.getMonth() + 1, 2)
  const day = addLeadingZerosToTime(date.getDate(), 2)
  const hours = addLeadingZerosToTime(date.getHours(), 2)
  const minutes = addLeadingZerosToTime(date.getMinutes(), 2)
  const seconds = addLeadingZerosToTime(date.getSeconds(), 2)

  const result = year + '' + month + '' + day + '' + hours + '' + minutes + '' + seconds
  return result
}

function addLeadingZerosToTime (num, totalLength) {
  return String(num).padStart(totalLength, '0')
}

export function CheckCorrectTimeFormat (time, t) {
  if (time === null || time === '') {
    return ''
  }

  time = typeof time === 'number' ? time.toString() : time

  const isUsingTraduction = t !== undefined && typeof t === 'function'
  let errorMsg = ''
  const splittedTime = time.split(':')
  let splitCount = 0

  if (splittedTime.length !== 3) {
    return isUsingTraduction ? t('Time must have hh:mm:ss structure') : 'Time must have hh:mm:ss structure'
  }

  splittedTime.forEach(element => {
    if (isNaN(element) || element === '' || element === null) {
      errorMsg = isUsingTraduction ? t('Hours, seconds and minute values must be numeric') : 'Hours, seconds and minute values must be numeric'
      return
    }
    if (splitCount !== 0) {
      if (element < 0 || element > 59) {
        errorMsg = isUsingTraduction ? t('Seconds and minute values must be between 0 and 59') : 'Seconds and minute values must be between 0 and 59'
        return
      }
    }
    splitCount += 1
  })

  if (errorMsg !== '') {
    return errorMsg
  }
}
export function CheckCorrectTimeFormatHoursAndMinutes (time, t) {
  if (time === null || time === '') {
    return ''
  }

  const isUsingTraduction = t !== undefined && typeof t === 'function'
  let errorMsg = ''
  const splittedTime = time.split(':')
  let splitCount = 0

  if (splittedTime.length !== 2) {
    return isUsingTraduction ? t('Time must have hh:mm structure') : 'Time must have hh:mm structure'
  }

  splittedTime.forEach(element => {
    if (isNaN(element) || element === '' || element === null) {
      errorMsg = isUsingTraduction ? t('Hours and minute values must be numeric') : 'Hours and minute values must be numeric'
      return
    }
    if (splitCount !== 0) {
      if (element < 0 || element > 59 || element.length > 2) {
        errorMsg = isUsingTraduction ? t('Minutes must be between 0 and 59') : 'Minutes must be between 0 and 59'
        return
      }
    }
    splitCount += 1
  })

  if (errorMsg !== '') {
    return errorMsg
  }
}
