import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { apiGet, apiDelete, apiPost, apiPut } from '../../generic/Api_Functions'
import { FormHelperText, IconButton, Stack } from '@mui/material'
import { useState } from 'react'
import { AddCircleRounded } from '@mui/icons-material'
import moment from 'moment/moment'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import LineOrder from './LineOrder'
import useToggleSmartAccordionVisibility from '../../generic/hooks/useToggleSmartAccordionVisibility/useToggleSmartAccordionVisibility'

// Import typedefs from another file
/**
 * @typedef {import('../../generic/utilities/objectStructures/orderWindow/order').Order} Order Order object
 * @typedef {import('../../generic/utilities/objectStructures/orderWindow/order').DialogStatus} DialogStatus DialogStatus object
 */

const Order = (props) => {
  const [editing, setEditing] = useState(false)
  const { t_ } = useSmartTranslation()
  const smartAccordionVisibility = useToggleSmartAccordionVisibility()
  const [editIndex, setEditIndex] = useState(-2)

  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: true
    },
    {
      name: t_('Line order'),
      field: 'lineOrderQuantity',
      readOnly: true,
      defaultNewValue: [],
      sortable: false,
      search: false,
      render: (row) => {
        return (
          <>
            <Stack direction='row' alignItems='center'>
              {row.lineOrderQuantity}
              <IconButton
                color='primary'
                onClick={() => { smartAccordionVisibility.setAccordion(row.id, true, true) }}
                disabled={(editing || row['smart-table-index'] === -1)}
              >
                <AddCircleRounded />
              </IconButton>
            </Stack>
            {editIndex > -2 ? <FormHelperText> </FormHelperText> : null}
          </>
        )
      }
    },
    {
      name: t_('Order date'),
      field: 'date',
      mandatory: true,
      numeric: true,
      type: 'dateTimePicker',
      defaultNewValue: new Date().toISOString()
    },
    {
      name: t_('Client'),
      field: 'client.name',
      type: 'select',
      edit: {
        source: async () => {
          return await apiGet('clients')
        },
        id: 'id',
        field: 'name'
      }
    },
    {
      name: t_('Delivery date'),
      field: 'endDate',
      numeric: true,
      type: 'dateTimePicker',
      defaultNewValue: ''
    }

  ]

  const dataFetch = async (pagination) => {
    const orders = await apiGet('orders', pagination)
    return orders
  }

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('orders', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        if ((row.client != null || row.client !== undefined) && (Object.keys(row.client).length === 0)) {
          row.client = null
        }
        return apiPost('orders', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('orders/' + rows[0].id, rows)
        } else {
          return apiDelete('ordersMulti', rows)
        }
      }
    },
    accordion: {
      render: (row) => {
        return (<LineOrder addModeByDefault={smartAccordionVisibility.getSmartAccordionAddMode(row.id)} order={row} />)
      }
    }
  }

  const validator = (row) => {
    const date = moment(row.date, moment.ISO_8601, true)
    const endDate = moment(row.endDate, moment.ISO_8601, true)
    if (endDate.isValid() && endDate.isValid()) {
      if (endDate.isBefore(date)) {
        return { errorFields: ['endDate'], alertMessage: 'Delivery date must be later than the order date' }
      }
    } else {
      return false
    }
  }

  return (
    <>
      <SmartMateriaUITable
        columns={columns}
        title={t_('Orders')}
        dataFetch={dataFetch}
        actions={actions}
        multipleSelection
        editingRow={(event) => setEditing(event)}
        pageable
        importFile={[{ bean: { beanPackage: 'com.smartpm.sun.dto.orderwindow.', beanName: 'ImportOrderLineOrder' }, name: t_('Order and Line') }, { bean: { beanName: 'FabricationOrder' }, name: t_('Fabrication Order') }]}
        validator={validator}
        accordionVisibility={smartAccordionVisibility}
        onEditIndexChanged={(editIndex) => setEditIndex(editIndex)}
      />
    </>
  )
}

export default Order
