import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Breadcrumbs,
  Divider,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Link,
  useMediaQuery,
  Stack
} from '@mui/material'
import Logo from '../../generic/Logo'
import LogoManufacturing from '../../generic/LogoManufacturing'
import LogoGreenSmall from '../../generic/LogoGreenSmall'
import {
  ArrowDropDown,
  MenuRounded,
  Logout
} from '@mui/icons-material'
import ClockDisplay from '../../generic/ClockDisplay'
import { apiGet, apiPost, apiPut } from '../../generic/Api_Functions'

import Start from '../../../images/Start.svg'
import Stop from '../../../images/Stop.svg'
import Reset from '../../../images/Reset.svg'
import TopBarBadge from './TopBarAlarms&Notifications'
import TopBarUser from './TopBarUser'
import theme from '../../../themeConfig'
import CheckDialog from '../../generic/CheckDialog'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { useUser } from '../../generic/hooks/useUser'
import { useAuthorization } from '../../generic/hooks/useAuthorization'

const DescriptionTypography = (props) => (
  <Typography
    color={props.color}
    sx={{
      fontSize: 20,
      flexGrow: 3,
      paddingLeft: 1
    }}
  >
    {props.children}
  </Typography>
)

const DescriptionTitleTypography = (props) => (
  <Typography
    sx={{ fontSize: 20 }}
  >
    {props.children}
  </Typography>
)
const appBarStyle = {
  width: '100%',
  height: '84px',
  backgroundColor: theme => `${theme.palette.background.appBar} !important`,
  zIndex: theme => theme.zIndex.drawer + 1
}
const buttonStyle = {
  backgroundColor: '#222527 !important',
  marginRight: '0.25em !important',
  marginLeft: '0.25em !important',
  maxWidth: '52.5px !important',
  minWidth: '52.5px !important',
  height: '3.5em !important',
  border: ' 0.125em solid transparent !important',
  width: '200px'
}
const startActiveStyle = {
  color: '#80e0a7  !important',
  filter: 'drop-shadow(0px 0px 10px green)',
  border: '0.125em solid #05fd92  !important',
  backgroundColor: '#222527  !important',
  marginRight: '1em !important',
  marginLeft: '1em !important',
  maxWidth: '52.5px !important',
  minWidth: '52.5px !important',
  height: '3.5em !important'
}
const stopActiveStyle = {
  color: '#ff0707f2  !important',
  filter: 'drop-shadow(0px 0px 10px red)',
  border: '0.125em solid red  !important',
  backgroundColor: '#222527  !important',
  marginRight: '1em !important',
  marginLeft: '1em !important',
  maxWidth: '52.5px !important',
  minWidth: '52.5px !important',
  height: '3.5em !important'
}
const vrActiveStyle = {
  color: '#FFFFFF  !important',
  filter: 'drop-shadow(0px 0px 3px white)',
  border: '0.125em solid #AAAAAA  !important',
  backgroundColor: '#222527  !important',
  marginRight: '1rem !important',
  marginLeft: '1rem !important',
  maxWidth: '40px !important',
  minWidth: '40px !important',
  height: '40px !important',
  fontSize: '1.2rem !important',
  fontWeight: 'bold !important'
}
const dividerStyle = {
  backgroundColor: '#818181  !important'
}

const buttonGroupStyle = {

  display: 'flex',
  justifyContent: 'center',
  width: '300px'
}
const buttonGroup2Style = {
  paddingLeft: '25px',
  paddingRight: '5px '
}
const iconStyle = {
  fontSize: '30px !important'
}

const languagesStyle = {
  fontSize: 15
}

const TopBar = (props) => {
  const [showLogoutDialog, setShowLogoutDialog] = useState({ open: false })
  const { changeHideMenu, reloadTopBar } = props
  const isUserAuthorized = useAuthorization()

  const [state, setState] = useState({
    checked: '',
    loaded: false
  })

  const handleLogoutDialog = () => {
    setShowLogoutDialog({ open: true })
  }

  // SETTINGS AND LANGUAGES
  const [open, setOpen] = React.useState(false)
  const [openLanguages, setOpenLanguages] = useState(false)
  const [openSettings, setOpenSettings] = useState(false)
  const anchorRefSettings = React.useRef(null)
  const anchorRefLanguages = React.useRef(null)
  const [staticConfig, setStaticConfig] = useState([])
  const { user, changeUserLanguage, userLogout } = useUser()
  const [showCheckDialog, setShowCheckDialog] = useState(false)
  const [virtualWareMode, setVirtualWareMode] = useState(false)
  const handleOpenSettings = () => {
    setOpenSettings((prevOpen) => !prevOpen)
    setOpen(false)
    setOpenLanguages(false)
  }
  const { t_, i18n } = useSmartTranslation()
  const [language, setLanguage] = useState(i18n.language)
  const handleOpenlanguages = () => {
    setOpenLanguages((prevOpen) => !prevOpen)
    setOpen(false)
    setOpenSettings(false)
  }
  const navigate = useNavigate()

  function handleListKeyDown (event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }
  useEffect(() => {
    apiGet('frontEndConfig')
      .then(setStaticConfig)
  }, [reloadTopBar])

  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'))
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const isDownLarge = useMediaQuery(theme.breakpoints.down('lg'))
  const isUpXLarge = useMediaQuery(theme.breakpoints.up('xl'))

  useEffect(() => {
    const interval = setInterval(() => {
      apiGet('cellGeneralStatus')
        .then((data) => {
          if (state.checked !== data) {
            setState({ ...state, loaded: true, checked: data })
          }
        })
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [state])

  // START AND STOP
  const handleChangeStart = (event) => {
    setShowCheckDialog(true)
  }

  const closeCheckDialog = (state) => {
    setShowCheckDialog(state)
    setVirtualWareMode(false)
  }

  const handleChangeStop = (event) => {
    const data = 'OFF'
    sendNewStatus(data)
    setShowCheckDialog(false)
  }

  const handleVirtualButton = () => {
    setVirtualWareMode(true)
    setShowCheckDialog(true)
  }

  const handleClickProfiles = () => {
    setOpenSettings(false)
    navigate('/profiles')
  }

  const handleClickUsers = () => {
    setOpenSettings(false)
    navigate('/users')
  }
  const handleClickSettings = () => {
    setOpenSettings(false)
    navigate('/settings')
  }

  const handleLogout = async () => {
    userLogout()
    setShowLogoutDialog({ ...showLogoutDialog, open: false })
  }

  async function sendNewStatus (data) {
    await apiPost('cellGeneralStatus', data)
    setState({ ...state, loaded: false, checked: data })
  }

  const vrTextStyle = {
    color: !isUserAuthorized('VIRTUAL_REALITY') ? '#444' : 'inherit'
  }

  const isDisabled = !isUserAuthorized('CELL_START')

  const playButtonSx = state.checked === 'AUTOMATIC' ? startActiveStyle : buttonStyle

  const stopButtonSx = state.checked === 'OFF' ? stopActiveStyle : buttonStyle

  const buttonImgStyle = {
    filter: isDisabled ? 'grayscale(100%)' : 'none'
  }

  function SimpleBreadcrumbs () {
    const navigate = useNavigate()
    const location = useLocation()
    let currentRoutes = []

    currentRoutes = location.pathname !== '/' ? location.pathname.split('/') : []
    currentRoutes.shift()

    const handleNavigation = (index) => {
      const route = '/' + currentRoutes.slice(0, index + 1).join('/')
      navigate(route)
    }

    return (
      <Breadcrumbs aria-label='breadcrumb'>
        <Link color='inherit' onClick={() => navigate('/home')} style={{ cursor: 'pointer', fontSize: '12px', color: '#818181' }}>
          mic
        </Link>
        {currentRoutes.map((route, index) => (
          <div key={index}>
            {index !== currentRoutes.length - 1
              ? (
                <Link
                  key={index}
                  color='inherit'
                  style={{ fontSize: '12px', cursor: 'pointer', color: '#818181' }}
                  onClick={() => handleNavigation(index)}
                >
                  {t_(route)}
                </Link>
                )
              : (
                <Typography key={index} color='inherit' style={{ fontSize: '12px' }}>
                  {t_(route)}
                </Typography>
                )}
          </div>
        ))}
      </Breadcrumbs>
    )
  }
  // App language is taken from the user, so after user language update in DB
  // The app will retrieve that data and store it in a contextProvider and localStorage
  useEffect(() => {
    if (user != null) {
      setLanguage(user.userConfiguration.languageCode)
    }
  }, [user])

  const changeLanguage = (language) => {
    updateUserLanguage(language)
    setOpenLanguages(false)
  }
  const updateUserLanguage = async (language) => {
    const newUser = changeUserLanguage(language)
    await apiPut('user/update/language', newUser)
  }
  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language, i18n])

  return (
    <div>
      <SmartDialog
        title={{ icon: <Logout color='primary' sx={{ fontSize: '1.5rem' }} />, render: <Typography variant='h5'>{t_('Log out')}</Typography> }}
        message={t_('Are you sure you want to log out?')}
        cancel
        cancelCallback={() => setShowLogoutDialog({ ...showLogoutDialog, open: false })}
        accept
        acceptCallback={() => { handleLogout() }}
        setOpen={showLogoutDialog.open}
      />
      <CheckDialog
        open={showCheckDialog}
        onClose={() => { closeCheckDialog(false) }}
        onAccept={virtualWareMode ? async () => { apiGet('virtualware/startSimulation') } : null}
        virtualWareMode={virtualWareMode}
      />

      <AppBar
        position='sticky'
        sx={appBarStyle}
      >

        <Toolbar>
          <div style={{ marginRight: '30px' }}>
            {isUpXLarge
              ? <Logo />
              : <LogoGreenSmall />}
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '40%', height: '100%' }}>

            <Stack direction='column' justifyContent='center' sx={{ height: '100%', width: '50%' }}>
              <div style={{ height: '85%', paddingTop: '25px' }}>
                {isDownLarge ? null : <LogoManufacturing />}
              </div>
              <div>
                {isDownLarge ? null : <SimpleBreadcrumbs />}
              </div>
            </Stack>

            {isUpMd
              ? (
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                  {isUpXLarge && (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DescriptionTitleTypography>
                          {t_('Site')}:
                        </DescriptionTitleTypography>
                        <DescriptionTypography color='primary'>
                          {staticConfig && staticConfig.site}
                        </DescriptionTypography>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DescriptionTitleTypography>
                          {t_('Cell')}:
                        </DescriptionTitleTypography>
                        <DescriptionTypography color='primary'>
                          {staticConfig && staticConfig.cell}
                        </DescriptionTypography>
                      </div>
                    </>
                  )}
                  {!isUpXLarge && (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DescriptionTitleTypography>
                          {t_('Site')}:
                        </DescriptionTitleTypography>
                        <DescriptionTypography color='primary'>
                          {staticConfig && staticConfig.site}
                        </DescriptionTypography>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DescriptionTitleTypography>
                          {t_('Cell')}:
                        </DescriptionTitleTypography>
                        <DescriptionTypography color='primary'>
                          {staticConfig && staticConfig.cell}
                        </DescriptionTypography>
                      </div>
                    </>
                  )}
                </div>
                )
              : null}
          </div>

          <Divider
            sx={dividerStyle}
            orientation='vertical'
            flexItem
          />

          <Button
            variant='contained'
            sx={vrActiveStyle}
            onClick={handleVirtualButton}
            disabled={!isUserAuthorized('VIRTUAL_REALITY')}
          >
            <div style={vrTextStyle}>VR</div>
          </Button>

          <Divider
            sx={dividerStyle}
            orientation='vertical'
            flexItem
          />

          <div style={isMobile ? buttonGroup2Style : buttonGroupStyle}>
            <Button
              variant='contained'
              sx={playButtonSx}
              onClick={handleChangeStart}
              disabled={isDisabled}
            >
              <img height='75%' width='75%' src={Start} alt='start' style={buttonImgStyle} />
            </Button>
            <Button
              variant='contained'
              sx={stopButtonSx}
              onClick={handleChangeStop}
              disabled={isDisabled}
            >
              <img height='75%' width='75%' src={Stop} alt='stop' style={buttonImgStyle} />
            </Button>
            <Button sx={buttonStyle}><img height='45%' width='45%' src={Reset} alt='reset' /></Button>
          </div>
          {
            !isMobile && !isTablet
              ? <Divider
                  sx={dividerStyle}
                  orientation='vertical'
                  flexItem
                />
              : null
          }
          <div>
            <Popper
              open={openSettings}
              anchorEl={anchorRefSettings.current}
              role={undefined}
              transition
              disablePortal
              placement='bottom-end'
            >
              {({ TransitionProps, placement }) => (
                <div>
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom'
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={() => setOpenSettings(false)}
                      >
                        <MenuList
                          autoFocusItem={openSettings}
                          id='menu-list-grow'
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleClickUsers} disabled={!isUserAuthorized('USERS')}>
                            {t_('Users')}
                          </MenuItem>
                          <Divider />
                          <MenuItem onClick={handleClickProfiles} disabled={!isUserAuthorized('PROFILES')}>
                            {t_('Profiles')}
                          </MenuItem>
                          <Divider />
                          <MenuItem onClick={handleClickSettings} disabled={!isUserAuthorized('SETTINGS')}>
                            {t_('Settings')}
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                </div>
              )}
            </Popper>
          </div>

          {
            !isMobile && !isTablet
              ? (
                <>
                  <TopBarBadge />
                  <Divider
                    sx={dividerStyle}
                    orientation='vertical'
                    flexItem
                  />
                </>
                )
              : null
          }
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1em', justifyContent: 'space-between' }}>
            <TopBarUser
              style={{ color: 'red' }}
              open={open}
              setOpen={setOpen}
              openSettings={openSettings}
              setOpenSettings={setOpenSettings}
              setOpenLanguages={setOpenLanguages}
              handleListKeyDown={handleListKeyDown}
              handleLogout={handleLogoutDialog}
            />
            <div>
              <IconButton
                ref={anchorRefSettings}
                onClick={handleOpenSettings}
                sx={languagesStyle}
                disabled={!isUserAuthorized('MANAGEMENT')}
              >
                <ManageAccountsIcon />
              </IconButton>
            </div>

            <div>
              {!isSmall
                ? (
                  <Button
                    ref={anchorRefLanguages}
                    onClick={handleOpenlanguages}
                    sx={languagesStyle}
                    color='inherit'
                    disabled={!isUserAuthorized('LANGUAGES')}
                  >
                    {language.toUpperCase()} <ArrowDropDown sx={iconStyle} />
                  </Button>
                  )
                : null}
              <Popper
                open={openLanguages}
                anchorEl={anchorRefLanguages.current}
                role={undefined}
                transition
                disablePortal
                placement='bottom-end'
              >
                {({ TransitionProps, placement }) => (
                  <div>
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'center top' : 'center bottom'
                      }}
                    >
                      <Paper>
                        <ClickAwayListener
                          onClickAway={() => setOpenLanguages(false)}
                        >
                          <MenuList
                            autoFocusItem={openLanguages}
                            id='menu-list-grow'
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem onClick={() => { changeLanguage('es') }}>
                              ES
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => { changeLanguage('en') }}>
                              EN
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => { changeLanguage('eus') }}>
                              EUS
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => { changeLanguage('pt') }}>
                              PT
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => { changeLanguage('fr') }}>
                              FR
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => { changeLanguage('ca') }}>
                              CA
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  </div>
                )}
              </Popper>
            </div>
            <div>
              {!isLarge
                ? (
                  <>
                    <Divider
                      sx={dividerStyle}
                      orientation='vertical'
                      flexItem
                    />
                    <ClockDisplay />
                  </>
                  )
                : null}
            </div>
            {isSmall
              ? (
                <IconButton
                  style={{ border: '1px solid #7FE1A7', color: '#7FE1A7' }}
                  aria-label='menu'
                  onClick={changeHideMenu}
                >
                  <MenuRounded />
                </IconButton>
                )
              : null}
          </div>
        </Toolbar>

      </AppBar>
    </div>
  )
}

export default TopBar
