import React from 'react'
import Technology from "../configuration/technologies/Technology";
import TechnologyGroup from "../configuration/technologies/TechnologyGroup";
import SmartNavigator from '../generic/SmartNavigator';
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation';

const MainTechnology = (props) => {

  const { t_ } = useSmartTranslation();

  const activePath = props.activePath;
  const componentsInfo = [
    {
      title: t_("Technology"),
      component: <Technology />,
    },
    {
      title: t_("Technology Groups"),
      component: <TechnologyGroup />,
    }
  ];
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} selected={activePath} />
    </div>
  );
}

export default MainTechnology
