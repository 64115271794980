import { React, useRef, useState } from 'react'
import { TextField, Button, Typography, Stack, Grid, IconButton } from '@mui/material'
import { AddCircleRounded } from '@mui/icons-material'
import { SmartDialog } from '../utilities/SmartDialog'
import { apiPut, apiGet } from '../Api_Functions'

import MenuItem from '@mui/material/MenuItem'
import { useStateSetNewValueOnChangeToTrue } from '../hooks/useStateSetNewValueOnChangeToTrue'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const AddElement = (props) => {
  const { t_ } = useSmartTranslation()
  const {
    endpoint,
    partReference,
    title,
    referenceName,
    disable,
    onAddDone,
    onAlert
  } = props

  const [showDialog, setShowDialog] = useState(false)
  const [rawMaterialReferenceList, setRawMaterialReferenceList] = useState([])
  const quantity = useRef(1)
  const [rawMaterialReference, setRawMaterialReference] = useStateSetNewValueOnChangeToTrue(partReference && partReference.rawMaterialReference ? partReference.rawMaterialReference.id : null, showDialog)

  const updateQuantity = () => {
    if (!endpoint) return

    if (quantity.current <= 0) {
      return
    }
    if (rawMaterialReference === null || rawMaterialReference === '') {
      return
    }
    setShowDialog(false)
    apiPut(endpoint + '/' + partReference.id + '/' + quantity.current + '/' + rawMaterialReference)
      .catch(err => {
        onAlert({
          title: t_('Error updating') + ' ',
          severity: 'error',
          message: t_('Could not be updated:') + ' ' + err
        })
      }).then(() => { if (onAddDone) { onAddDone() } })
    quantity.current = 1
  }

  const onClickFunction = async () => {
    const rawMaterialReferences = await apiGet('rawMaterialsReferences/' + partReference.id)
    setRawMaterialReferenceList(rawMaterialReferences)
    setShowDialog(true)
  }

  return (
    <div>
      <SmartDialog
        setOpen={showDialog}
        acceptCallback={() => {
          updateQuantity()
        }}
        cancelCallback={() => setShowDialog(false)}
        title={
          {
            visible: true,
            icon: <AddCircleRounded color='primary' sx={{ fontSize: '1.5em' }} />,
            render:
  <Stack
    direction='row'
    justifyContent='center'
    alignItems='center'
  >
    <Typography variant='h6' color='primary'> {referenceName}</Typography>
  </Stack>
          }
        }
        renderComponent={
          <>
            <Grid container spacing={3} justifyContent='center' sx={{ padding: (theme) => theme.spacing(2) }}>
              <Grid item xs={3}>
                <TextField
                  label={t_('Quantity')} defaultValue={1} type='number' onChange={(event) => { quantity.current = event.target.value }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  fullWidth
                  value={rawMaterialReference || ''}
                  error={rawMaterialReference === null || rawMaterialReference === ''}
                  label={t_('Raw Material Reference')}
                  onChange={(event) => { setRawMaterialReference(event.target.value) }}
                >
                  {rawMaterialReferenceList.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </>

        }
      />
      {title === undefined
        ? (
          <IconButton
            color='primary'
            onClick={onClickFunction}
            disabled={disable}
          >
            <AddCircleRounded />
          </IconButton>)
        : (
          <Button
            color='primary'
            onClick={onClickFunction}
            disabled={disable}
          >
            <AddCircleRounded style={{ fontSize: '1.2em' }} />
            <Typography style={{ marginLeft: '0.4em', fontSize: '0.875rem' }}>{title}</Typography>
          </Button>)}
    </div>
  )
}

export default AddElement
