import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import SmartMateriaUITable from '../../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'
import { CheckCorrectTimeFormat } from '../../../generic/DateTimeFunctions'

/**
 * Component used to order given task list
 * @param {Object} props Props object
 * @param {Number} props.taskId Task id
 * @param {Number} props.programId Program id
 */
const TaskToolInfo = (props) => {
  const { currentTask } = props
  const [isParsed, setIsParsed] = React.useState(false)
  const { t_ } = useSmartTranslation()

  useEffect(() => {
    if (currentTask !== undefined) {
      if (currentTask.toolReferenceList.length !== 0) {
        let toolReferencesFiltered = [...currentTask.toolReferenceList]

        const taskToolNames = [...currentTask.taskToolInfoDto.taskToolInfoList].map((taskTool) => {
          return taskTool.toolReferenceName
        })
        toolReferencesFiltered = toolReferencesFiltered.filter(toolReference => {
          return !taskToolNames.includes(toolReference.toolReferenceName)
        })
        if (toolReferencesFiltered.length > 0) {
          toolReferencesFiltered.forEach((tool) => {
            currentTask.taskToolInfoDto.taskToolInfoList[currentTask.taskToolInfoDto.taskToolInfoList.length] = { toolReferenceName: tool.toolReferenceName, duration: '00:00:00', consumption: 0, diameter: '', length: '' }
          })
        }
        let oldToolReferences = [...currentTask.toolReferenceList]
        const currentToolReferences = [...currentTask.taskToolInfoDto.taskToolInfoList].map((taskTool) => {
          return taskTool.toolReferenceName
        })
        oldToolReferences = oldToolReferences.map((taskTool) => {
          return taskTool.toolReferenceName
        }).filter((toolReferenceName) => { return currentToolReferences.includes(toolReferenceName) })
        if (oldToolReferences.length > 0) {
          currentTask.taskToolInfoDto.taskToolInfoList = currentTask.taskToolInfoDto.taskToolInfoList.filter((taskTool) => {
            return oldToolReferences.includes(taskTool.toolReferenceName)
          })
        }
      } else {
        currentTask.taskToolInfoDto.taskToolInfoList = []
      }
    }
  }, [currentTask])

  const dataFetch = () => {
    if (currentTask.taskToolInfoDto.taskToolInfoList !== undefined) {
      const taskToolInfoDto = currentTask.taskToolInfoDto
      setIsParsed(taskToolInfoDto.parsed)
      return taskToolInfoDto.taskToolInfoList
    }
    return null
  }
  const checkDoubleCompatible = (consumption) => {
    const number = parseFloat(consumption)
    if (!(!isNaN(number) && /^\d*\.?\d*$/.test(consumption))) {
      return t_('Introduced value is not valid')
    }
  }
  const columns = [
    {
      name: t_('Tool'),
      field: 'toolReferenceName',
      readOnly: true
    },
    {
      name: t_('Duration') + ' (hh:mm:ss)',
      field: 'duration',
      condition: (rowData) => {
        return CheckCorrectTimeFormat(rowData.duration, t_)
      }
    },
    {
      name: t_('Consumption'),
      field: 'consumption',
      readOnly: (row) => {
        return isParsed || row.unitTypeIsDuration
      },
      condition: (row) => {
        if (row.unitTypeIsDuration) {
          return CheckCorrectTimeFormat(row.consumption, t_)
        } else {
          return checkDoubleCompatible(row.consumption)
        }
      }
    },
    {
      name: t_('Diameter'),
      field: 'diameter',
      numeric: 'true',
      readOnly: () => {
        return isParsed
      }
    },
    {
      name: t_('Length'),
      field: 'length',
      numeric: 'true',
      readOnly: () => {
        return isParsed
      }
    }
  ]

  const actions = {
    edit: {
      fetchFunction: (row) => {
        const toolCondition = (tool) => tool.toolReferenceName === row.toolReferenceName
        const index = currentTask.taskToolInfoDto.taskToolInfoList.findIndex(toolCondition)

        // If index is found (not -1), replace the item
        if (index !== -1) {
          currentTask.taskToolInfoDto.taskToolInfoList[index] = row
        }
      }
    }
  }

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Tool info')}
      dataFetch={dataFetch}
      actions={actions}
    />
  )
}

export default TaskToolInfo

TaskToolInfo.propTypes = {
  taskToolInfo: PropTypes.object.isRequired
}
