import React from 'react'
import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation';

const SensorsActuators = () => {
  const { t_ } = useSmartTranslation();

  const columns = [{
    name: t_("Code"),
    field: "code",
    readOnly: true,
    charLimit: 60,
    mandatory: true,
    unique: true
  },
  {
    name: t_("Name"),
    field: "name",
    charLimit: 80,
    mandatory: true
  },
  {
    name: t_("Description"),
    field: "description",
    charLimit: 200,
  },
  {
    name: t_("Controller"),
    field: "ioController.code",
    type: "select",
    edit: {
      source: async () => {
        return await apiGet("ioController");
      },
      id: "id",
      field: "code",
    }
  }
  ]

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut("ioElement", row)
      },
    },
    create: {
      fetchFunction: (row) => {
        return apiPost("ioElement", row)
      },
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete("ioElement/" + rows[0].id, rows)
        }
        else {
          return apiDelete("ioElementMulti", rows)
        }
      },
    },
  }
  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_("SENSORS & ACTUATORS")}
      dataFetch="ioElement"
      actions={actions}
      multipleSelection
    />
  )
}

export default SensorsActuators
