import { Stack, Typography } from '@mui/material'
import { CompassButton } from '../CompassButton'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'
import { DefaultCompassPopper } from '../CompassPopper/CompassPopper'

const CompassFabricationOrderSelector = (props) => {
  const { t_ } = useSmartTranslation()
  const { fabricationOrder, currentFabricationOrders, moreFabricationOrders, open, setOpen, onChange } = props
  const formLabelStyle = {
    fontSize: '1.5rem'
  }
  // Select first fabrication order if only one available
  if (!fabricationOrder && currentFabricationOrders.length === 1) {
    onChange(currentFabricationOrders[0])
  }
  const getFabricationOrderButton = (fo) => {
    const selected = fabricationOrder?.id === fo.id && fabricationOrder.jobReferenceId === fo.jobReferenceId
    return (
      <CompassButton
        key={fo.id + '-' + fo.jobReferenceId}
        isSelected={selected}
        sx={{ minHeight: '6rem' }}
        labelStyle={formLabelStyle}
        label={
          <Stack>
            <Typography color={selected ? 'primary' : 'secondary'} sx={formLabelStyle}>{fo.fabricationOrder + ' - ' + (fo.jobReferenceName !== null ? fo.jobReferenceName : fo.jobReferenceTechnology)}</Typography>
            <Typography color={selected ? 'primary' : 'secondary'}>{t_('Ref') + ': ' + fo.partReferenceName}</Typography>
          </Stack>
                }
        onClick={() => { onChange(fo) }}
        color='secondary'
        isSelectedColor='primary'
      />
    )
  }
  const isSelectedFabricationActive = fabricationOrder && currentFabricationOrders.some((fo) => fo.id === fabricationOrder.id && fo.jobReferenceId === fabricationOrder.jobReferenceId)
  const filteredFabricationOrders = moreFabricationOrders.filter(fo => fo.show === undefined || fo.show === true)
  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '1rem' }}>
      {currentFabricationOrders.map((fo) => getFabricationOrderButton(fo))}
      {!isSelectedFabricationActive && fabricationOrder ? getFabricationOrderButton(fabricationOrder) : null}
      {filteredFabricationOrders.length > 0
        ? filteredFabricationOrders.length === 1 && fabricationOrder && filteredFabricationOrders[0].id === fabricationOrder.id && filteredFabricationOrders[0].jobReferenceId === fabricationOrder.jobReferenceId
          ? open !== false && setOpen(false) && null
          : (
            <DefaultCompassPopper
              open={open}
              setOpen={setOpen}
              sx={{ width: '100%', justifyContent: 'center' }}
              fabSx={{ transform: 'scale(1.5)' }}
              render={filteredFabricationOrders.map((fo) => getFabricationOrderButton(fo))}
            />)
        : null}

    </div>
  )
}
export default CompassFabricationOrderSelector
