import React, { forwardRef, useImperativeHandle } from 'react'
import ButtonBack from './ButtonBack'
import ButtonSave from './ButtonSave'
import AlertUI from '../../AlertUI'
import { useTheme } from '@mui/material'

const ButtonBackSave = forwardRef((props, ref) => {
  const [AlertElement, showAlert] = AlertUI()
  const {
    saveButtonAction,
    saveDisabled,
    backButtonAction,
    buttonFixed,
    useOnSaveClick,
    editControl = false

  } = props
  const theme = useTheme()
  const handleSave = async () => {
    await saveButtonAction()
    if (editControl) {
      editControl(true)
    }
  }

  useImperativeHandle(ref, () => ({
    showErrorAlert (error) {
      showAlert({
        title: 'An error occurred while creating.',
        message: error.message,
        severity: 'error',
        fixedBottom: true
      })
    }
  }))

  return (
    <>
      {AlertElement}
      <div style={{
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(10),
        zIndex: 100
      }}
      >
        <ButtonBack
          buttonAction={() => backButtonAction()}
          buttonFixed={buttonFixed === undefined ? true : buttonFixed}
        />
        <ButtonSave
          buttonAction={() => handleSave()}
          editControl={(value) => editControl(value)}
          saveDisabled={saveDisabled}
          buttonFixed={buttonFixed === undefined ? true : buttonFixed}
          useOnSaveClick={useOnSaveClick}
        />
      </div>
    </>
  )
})
export default ButtonBackSave
