import { NotificationsActiveRounded, Visibility, VisibilityOff, WarningRounded } from '@mui/icons-material'
import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import AlertUI from '../../generic/AlertUI'
import { apiGet, apiPut } from '../../generic/Api_Functions'
import ToggleButtonAlarmNotifications from '../../generic/buttons/toggleButtonGroups/ToggleButtonAlarmNotifications'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { GetDateTime } from '../../generic/DateTimeFunctions'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const AlarmsNotifications = (props) => {
  const { title, state, toolbar, unChecked, pendingChanges } = props

  const [AlertElement, showAlert] = AlertUI()

  const [selectedNotifications, setSelectedNotifications] = useState(state === 'NOTIFICATIONS')
  const [selectedAlarms, setSelectedAlarms] = useState(state === 'ALARMS')

  const forceLoad = useRef(true)

  const [resetMultipleSelectionValue, setResetMultipleSelectionValue] = useState(false)
  const { t_ } = useSmartTranslation()

  useEffect(() => {
    if (state === 'ALARMS') {
      setSelectedAlarms(true)
    } else if (state === 'NOTIFICATIONS') {
      setSelectedNotifications(true)
    }
  }, [state])

  const checkReadAndDisplayField = (rowCheckedStatus, rowId, displayText, isChecked = false) => {
    let currentCheckedStatus = rowCheckedStatus
    if (pendingChanges !== undefined && pendingChanges.current.rows[rowId] !== undefined) {
      currentCheckedStatus = pendingChanges.current.rows[rowId]
    }
    if (!isChecked) { return (<Box sx={{ typography: 'body1', fontWeight: currentCheckedStatus ? '' : 'bold' }}> {displayText}</Box>) }

    return (currentCheckedStatus ? <Visibility sx={{ color: 'darkgrey' }} /> : <VisibilityOff />)
  }

  const columns = [
    {
      // name: "1",
      field: 'level',
      search: false,
      render: (row) => (
        row.level === 'INF'
          ? <NotificationsActiveRounded color='info' />
          : row.level === 'WAR'
            ? <WarningRounded color='warning' />
            : row.level === 'ERR'
              ? <WarningRounded sx={{ color: 'red' }} />
              : null
      )
    },
    {
      name: t_('Code'),
      field: 'code',
      readOnly: true,
      charLimit: 60,
      mandatory: true,
      unique: true,
      render: (row) => (
        checkReadAndDisplayField(row.checked, row.id, row.code)
      )
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200,
      readOnly: true,
      render: (row) => (
        checkReadAndDisplayField(row.checked, row.id, row.description)
      )
    },
    {
      name: t_('Creation date'),
      field: 'date',
      type: 'datetime',
      readOnly: true,
      sortOptions: 'asc',
      render: (row) => (
        checkReadAndDisplayField(row.checked, row.id, GetDateTime(row.date))
      )
    },
    {
      name: t_('Reading date'),
      readOnly: true,
      field: 'readingDate',
      type: 'datetime',
      render: (row) => (
        checkReadAndDisplayField(row.checked, row.id, GetDateTime(row.readingDate))
      )
    },
    {
      field: 'checked',
      name: t_('State'),
      search: false,
      render: (row) => (
        checkReadAndDisplayField(row.checked, row.id, '', true)
      )
    }
  ]

  const sortOptions = [{
    field: 'date',
    direction: -1
  }]

  const defaultToolbar = {
    render: {
      fetchFunction: () => {
        return (
          <ToggleButtonAlarmNotifications
            selectedNotifications={selectedNotifications}
            selectedAlarms={selectedAlarms}
            setSelectedNotifications={setSelectedNotifications}
            setSelectedAlarms={setSelectedAlarms}
          />
        )
      }
    }
  }

  const multipleSelectionActions = [
    {
      render: (pendingChanges, index, pageable, multipleSelectionValue) => {
        return (<Button color='primary' sx={{ borderColor: 'transparent' }} key={'key' + index} onClick={() => updateErrorLogCheckState(pendingChanges, true, pageable, multipleSelectionValue)}><Visibility style={{ marginRight: '0.5em', color: '#7FE1A7' }} /> {t_('Read')}</Button>)
      }

    },
    {
      render: (pendingChanges, index, pageable, multipleSelectionValue) => {
        return (<Button color='primary' sx={{ borderColor: 'transparent' }} key={'key' + index} onClick={() => updateErrorLogCheckState(pendingChanges, false, pageable, multipleSelectionValue)}><VisibilityOff style={{ marginRight: '0.5em', color: '#7FE1A7' }} />{t_('UnRead')}</Button>)
      }
    }
  ]

  const updateErrorLogCheckState = (rows, newCheckedState, pageable, multipleSelectionValue) => {
    if (!unChecked) {
      // Send the changes directly to the backend
      const levels = []
      if (selectedNotifications) {
        levels.push('INF')
      }
      if (selectedAlarms) {
        levels.push('ERR')
        levels.push('WAR')
      }
      const url = 'setErrorLog' + (newCheckedState ? 'Checked' : 'UnChecked') + '/' + (levels.join())
      // Make pageable infinite so it changes all values. Set the size to java int max value
      apiPut(url, pageable).then(() => {
        showAlert({
          title: t_('Updated correctly.'),
          severity: 'success'
        })
        setResetMultipleSelectionValue(!resetMultipleSelectionValue)
      })
    } else {
      // Values will not be updated in backend until accept button is clicked in modal.
      // Data will be saved in frontend as pendingChanges
      if (multipleSelectionValue.allSelected) {
        pendingChanges.current.rows = []
        pendingChanges.current.pageable = { ...pageable }
        pendingChanges.current.allSelected = true
        pendingChanges.current.allSelectedChecked = newCheckedState
        // Make pageable -1 so it changes all values. Set the size to java int max value
        pendingChanges.current.pageable.size = -1
        multipleSelectionValue.rows.forEach(element => {
          pendingChanges.current.rows[element.id] = !newCheckedState
          element.checked = !newCheckedState
        })
      } else {
        multipleSelectionValue.rows.forEach(element => {
          pendingChanges.current.rows[element.id] = newCheckedState
        })
      }
      setResetMultipleSelectionValue(!resetMultipleSelectionValue)
    }
  }

  const fetchData = useCallback(async (pagination) => {
    let dataList = []
    let query
    if (selectedNotifications && unChecked && !selectedAlarms) {
      query = 'getUncheckedNotifications'
    } else if (!selectedNotifications && unChecked && selectedAlarms) {
      query = 'getUncheckedAlarms'
    } else if (selectedNotifications && !unChecked && !selectedAlarms) {
      query = 'getAllNotifications'
    } else if (!selectedNotifications && !unChecked && selectedAlarms) {
      query = 'getAllAlarms'
    } else if (selectedNotifications && selectedAlarms) {
      query = 'errorLogsRevisionDates'
    } else {
      // Generate fake pageable data
      dataList = {
        content: [],
        empty: true,
        first: true,
        last: true,
        number: 0,
        numberOfElements: 0,
        pageable: { offset: 0, pageNumber: 0, pageSize: 10, paged: true, sort: { sorted: true, unsorted: false, empty: false }, unpaged: false },
        size: 10,
        sort: { sorted: true, unsorted: false, empty: false },
        totalElements: 0,
        totalPages: 0
      }
    }
    if (query !== undefined) {
      dataList = await apiGet(query, pagination)
        .catch((error) => {
          showAlert({
            title: t_('Error getting data.'),
            message: error
          })
        })
    }

    if (unChecked) {
      // Merge changes with pending so that user sees changes that are not yet in db
      dataList.content.forEach(element => {
        if (pendingChanges.current.allSelected && !(element.id in pendingChanges.current.rows)) {
          element.checked = pendingChanges.current.allSelectedChecked
        }
      })
    }
    return dataList
  }, [selectedNotifications, selectedAlarms, showAlert, unChecked, pendingChanges, t_])

  useEffect(() => {
    forceLoad.current = !forceLoad.current
  }, [selectedNotifications, selectedAlarms, forceLoad])

  return (
    <>
      {AlertElement}
      <SmartMateriaUITable
        columns={columns}
        title={title}
        dataFetch={fetchData}
        multipleSelection={!(unChecked && selectedNotifications)}
        toolbar={toolbar !== undefined ? toolbar : defaultToolbar}
        sortOptions={sortOptions}
        forceLoad={forceLoad.current}
        multipleSelectionActions={multipleSelectionActions}
        deleteMultipleSelectionDisabled
        resetMultipleSelectionValue={resetMultipleSelectionValue}
        pageable
      />
    </>
  )
}

export default AlarmsNotifications
