import React, { useState, useEffect } from 'react'
import { Grid, Typography, Stack, Badge, CircularProgress } from '@mui/material'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { FeedOutlined, AssessmentOutlined, ReportOutlined, ContentPasteSearch } from '@mui/icons-material/'
import CompassButton from '../Common/CompassButton/CompassButton'
import CompassCard from '../Common/CompassCard/CompassCard'

import { apiGet } from '../../generic/Api_Functions'
import { useNavigate } from 'react-router-dom'
import CompassGrid from '../Common/compassGrid/CompassGrid'

const Supervision = ({ fullScreen, setFullScreen }) => {
  const [devices, setDevices] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const navigate = useNavigate()
  const { t_ } = useSmartTranslation()

  useEffect(() => {
    apiGet('compass/devices').then((response) => {
      setDevices(response)
    }).catch((error) => {
      setErrorMessage(error.message)
    })
  }, [])

  const supervisionButtons = {
    DOC: 'doc',
    STATUSHISTORY: 'statusHistory',
    STATUS: 'status',
    INCIDENTHISTORY: 'incidentHistory',
    INCIDENT: 'incident'
  }

  const getDocButton = () => {
    return (
      <CompassButton
        icon={<FeedOutlined />}
        color='secondary'
        onClick={() => navigate('/compass/viewFile', { state: { urlBack: '/compass/supervision' } })}
      />
    )
  }
  const getStatusHistory = (device) => {
    return (
      <CompassButton
        icon={
          <Badge badgeContent={<AssessmentOutlined />} sx={{ padding: '0.5rem' }}>
            <ContentPasteSearch />
          </Badge>
        }
        color='secondary'
        onClick={() => navigate('/compass/supervision/statusList', { state: { device } })}
      />
    )
  }
  const getStatusButton = (device) => {
    return (
      <CompassButton
        icon={<AssessmentOutlined />}
        label={t_('Status')}
        color='primary'
        onClick={() => navigate('/compass/supervision/addStatus', { state: { device } })}
      />
    )
  }
  const getIncidentHistory = (device) => {
    return (
      <CompassButton
        icon={
          <Badge badgeContent={<ReportOutlined />} sx={{ padding: '0.5rem' }}>
            <ContentPasteSearch />
          </Badge>
        }
        color='secondary'
        onClick={() => navigate('/compass/supervision/incidentList', { state: { device } })}
      />
    )
  }
  const getIncidentButton = (device) => {
    return (
      <CompassButton
        icon={<ReportOutlined />}
        label={t_('Incidence')}
        color='primary'
        onClick={() => navigate('/compass/supervision/addIncident', { state: { device } })}
      />
    )
  }

  const getSupervisionButton = (supervisionButtonName, device) => {
    let md = 3
    let button = null
    switch (supervisionButtonName) {
      case supervisionButtons.DOC:
        button = getDocButton()
        md = 2
        break
      case supervisionButtons.STATUSHISTORY:
        button = getStatusHistory(device)
        md = 2
        break
      case supervisionButtons.STATUS:
        button = getStatusButton(device)
        break
      case supervisionButtons.INCIDENTHISTORY:
        button = getIncidentHistory(device)
        md = 2
        break
      case supervisionButtons.INCIDENT:
        button = getIncidentButton(device)
        break
      default:
        break
    }
    if (!button) return null
    return (<Grid item md={md} key={supervisionButtonName}>{button}</Grid>)
  }

  const buttons = (device) => {
    return Object.values(supervisionButtons).map(buttonName => { return getSupervisionButton(buttonName, device) })
  }

  return (
    <>
      {errorMessage && <Typography variant='h6' component='h2' style={{ marginTop: '3rem' }}> {errorMessage} </Typography>}
      {devices === null
        ? <Stack sx={{ width: '100%', height: '80vh' }} direction='row' justifyContent='center' alignItems='center'><CircularProgress size='15rem' /></Stack>
        : (
          <CompassGrid
            items={devices}
            fullScreenItem={fullScreen}
            renderItem={(device, isFullscreen) =>
              <CompassCard
                device={device}
                fabricationOrder={device.currentFabricationOrders[0]}
                buttons={buttons(device)}
                onDoubleClick={() => setFullScreen(isFullscreen ? null : device)}
                isFullScreen={isFullscreen}
              />}
          />
          )}
    </>
  )
}
export default Supervision
