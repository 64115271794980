import React from "react";
import SmartNavigator from "../generic/SmartNavigator";
import Presetting from "../dashboard/reports/Presetting";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";

const Preset = () => {
  const { t_ } = useSmartTranslation();

  const componentsInfo = [
    {
      title: t_("Preset"),
      component: <Presetting />,
    },
  ];

  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  );
};

export default Preset;
