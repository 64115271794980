import React from 'react'
import { Typography, Link } from '@mui/material'
import CopyrightIcon from '@mui/icons-material/Copyright'
import VersionNum from '../generic/VersionNumber'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

const urlPrivacy = 'https://smartpm.es/politica-privacidad'
const urlLegal = 'https://smartpm.es/aviso-legal'

const appFooterStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '1rem',
  paddingBottom: '1rem'
}

const Footer = (props) => {
  const { t_ } = useSmartTranslation()

  return (
    <div style={appFooterStyle}>

      <Typography sx={{ display: 'inline-flex', alignItems: 'center' }}>
        MIC v<VersionNum /> &nbsp; <CopyrightIcon fontSize='small' /> &nbsp;
      </Typography>
      <Typography>
        2023 SMARTPM |
        <Link href={urlPrivacy} underline='none' target='_blank' rel='noopener noreferrer'> {t_('Privacy policy')} </Link> |
        <Link href={urlLegal} underline='none' target='_blank' rel='noopener noreferrer'> {t_('Legal disclaimer')} </Link>
      </Typography>
    </div>
  )
}

export default Footer
