import React, { useMemo } from 'react'
import SmartPalette from '../../../generic/smartPalette/SmartPalette'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'

const NodeTypesBar = ({ technologies, workflows, workflowInUse }) => {
  const { t_ } = useSmartTranslation()

  const sections = useMemo(() => {
    return [
      { id: 'technology', title: t_('Technology'), items: technologies },
      { id: 'workflow', title: t_('Workflow'), items: workflows }
    ]
  }, [technologies, workflows, t_])

  const getDragProperties = (section, item) => {
    if (workflowInUse) {
      return false
    }
    if (section.id === 'workflow') {
      return { 'application/reactflow/nodeType': 'workflow', 'application/reactflow/subType': item.id }
    }
    return { 'application/reactflow/nodeType': 'technology', 'application/reactflow/subType': item.name }
  }

  return (

    <SmartPalette
      sections={sections}
      isItemDisabled={(section, item) => workflowInUse}
      getDragProperties={(section, item) => getDragProperties(section, item)}
    />
  )
}

export default NodeTypesBar
