import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import React from 'react'
import { useState } from 'react'
import SmartSortableItem from './SmartSortableItem';
import PropTypes from "prop-types";
import { useEffect } from 'react';

const SmartSortableContainer = ({
  itemList, //List of items that must be shown sortable with drag and drop
  itemId, //IMPORTANT: is the field or property in each item that will be used as id by the drag and drop control
  renderItem, //You must pass a custom component that will render each item of the list
  onSortChanged,
}) => {
  const [items, setItems] = useState(itemList)
  const sensors = [
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8
      }
    }),
    useSensor(KeyboardSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      }
    })
  ]

  // TODO COMENTAR CON BORJA
  useEffect(() => {
    setItems(itemList);
  }, [itemList])

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = items.findIndex(item => item[itemId] === active.id)
      const newIndex = items.findIndex(item => item[itemId] === over.id)

      const newItems = arrayMove(items, oldIndex, newIndex)
      setItems(newItems)
      if (onSortChanged && typeof onSortChanged === 'function') {
        onSortChanged(newItems)
      }
    }
  }
  return (<>
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={items.map(item => item[itemId])}
        strategy={verticalListSortingStrategy}
      >
        {items.map(
          (item, index) => (
            <SmartSortableItem key={item[itemId]} itemId={item[itemId]} index={index} item={item} renderItem={renderItem} />
          )
        )}
      </SortableContext>
    </DndContext>
  </>
  )
}

SmartSortableContainer.propTypes = {
  itemList: PropTypes.array.isRequired,
  itemId: PropTypes.string.isRequired,
  renderItem: PropTypes.func.isRequired,
  onSortChanged: PropTypes.func,
}

export default SmartSortableContainer