import useUser from '../useUser/useUser'

const useAuthorization = () => {
  const { user } = useUser()

  const isUserAuthorized = (permissionCode) => {
    if (user && user.profile && user.profile.permissions) {
      return user.profile.permissions.some(
        (permission) => permission.code === permissionCode
      )
    }
    return false
  }

  return isUserAuthorized
}

export default useAuthorization
