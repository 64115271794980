import { Stack } from "@mui/system";
import { Select, MenuItem, InputLabel, FormControl, TextField, Typography } from "@mui/material";
import { useState, useEffect, useReducer } from "react";
import { apiGet, apiPost } from '../../generic/Api_Functions';
import SmartDialog from '../../generic/utilities/SmartDialog/SmartDialog';
import AddElement from "../../generic/smartAddElement/AddElementPart";
import { StorageRounded, Widgets } from '@mui/icons-material';
import useToggle from '../../generic/hooks/useToggle/useToggle';
import AlertUI from "../../generic/AlertUI";
import Fixture from "../../configuration/fixture/Fixture";

import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const LoadActionDialog = (props) => {

  const { action, open, setOpen, onDone } = props;

  const [availablePositions, setAvailablePositions] = useState([]);
  const [availableStorages, setAvailableStorages] = useState([]);
  const [fabricationOrder, setFabricationOrder] = useState(null);
  const [selectedPart, setSelectedPart] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [availableParts, setAvailableParts] = useState([]);
  const [forcePartReload, toogleForcePartReload] = useToggle();

  const [avaiableShelfs, setAvaiableShelfs] = useState([]);
  const [availableRows, setAvailableRows] = useState([]);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [AlertElement, showAlert] = AlertUI();
  const { t_ } = useSmartTranslation();


  const [selectedLocation, dispatchSelectedLocation] = useReducer((state, action) => {
    if (action.type === 'storage') {
      return { storage: action.value, shelve: null, row: null, column: null, showFixtureDialog: false, selectedFixture: null, selectedFixtureReference: null };
    }
    if (action.type === 'shelve') {
      return { ...state, shelve: action.value, row: null, column: null };
    }
    if (action.type === 'row') {
      return { ...state, row: action.value, column: null };
    }
    if (action.type === 'column') {
      return { ...state, column: action.value };
    }
    if (action.type === 'showFixtureSelectDialog') {
      return { ...state, showFixtureDialog: true, selectedFixtureReference: action.value, selectedFixture: false };
    }
    if (action.type === 'close_fixture') {
      return { ...state, showFixtureDialog: false, selectedFixtureReference: null, selectedFixture: false };
    }
    if (action.type === 'set_selected_fixture') {
      return { ...state, selectedFixture: action.value };
    }
  }, { storage: null, shelve: null, row: null, column: null, showFixtureDialog: false, selectedFixture: null, selectedFixtureReference: null });



  useEffect(() => {

    const loadData = async () => {
      // Find all storage positions that are in/out and are free
      let newAvailablePositions = await apiGet('shelveStoragePositionsFreeAndTypeIn');
      setAvailablePositions(newAvailablePositions);
      // Make list of storage devices
      let storages = [];
      let storageIds = {};
      newAvailablePositions.forEach((item) => {
        if (!(item.deviceId in storageIds)) {
          storages.push({ id: item.deviceId, name: item.deviceName });
          storageIds[item.deviceId] = true;
        }
      });
      setAvailableStorages(storages);

      // Select first storage by default
      if (storages.length > 0) {
        dispatchSelectedLocation({ type: "storage", value: storages[0].id });
      }

      // Load action associated OF
      let actionFabricationOrder = await apiGet("actionFabricationOrder/" + action.id);
      setFabricationOrder(actionFabricationOrder.fabricationOrder);
    }

    if (open) {
      // Reset variables
      loadData();
    }

    setSelectedPart(null);
    setShowErrors(false);

  }, [action, open]);

  useEffect(() => {
    const loadParts = async () => {
      let parts = await apiGet("partsId/" + fabricationOrder.partReference.id + "/noFabricationOrder");
      setAvailableParts(parts);

      //  We select first available one by default
      setSelectedPart(parts.length > 0 ? parts[0].id : null)

    }
    if (fabricationOrder) {
      loadParts();
    }
  }, [action, open, forcePartReload, fabricationOrder]);

  useEffect(() => {
    let newAvaiableShelfs = availablePositions.filter((item) => item.deviceId === selectedLocation.storage);
    // Remove duplicates
    newAvaiableShelfs = newAvaiableShelfs.filter((value, index, self) =>
      index === self.findIndex((t) => (t.shelve === value.shelve))
    );
    setAvaiableShelfs(newAvaiableShelfs);
  }, [availablePositions, selectedLocation.storage]);

  useEffect(() => {
    let newAvailableRows = availablePositions.filter((item) => item.deviceId === selectedLocation.storage && item.shelve === selectedLocation.shelve);
    // Remove duplicates
    newAvailableRows = newAvailableRows.filter((value, index, self) =>
      index === self.findIndex((t) => (t.position === value.position))
    );
    setAvailableRows(newAvailableRows);
  }, [availablePositions, selectedLocation.shelve, selectedLocation.storage]);

  useEffect(() => {
    let newAvailableColumns = availablePositions.filter((item) => item.deviceId === selectedLocation.storage && item.shelve === selectedLocation.shelve && item.position === selectedLocation.row);
    // Remove duplicates
    newAvailableColumns = newAvailableColumns.filter((value, index, self) =>
      index === self.findIndex((t) => (t.depth === value.depth))
    );
    setAvailableColumns(newAvailableColumns);
  }, [availablePositions, selectedLocation.row, selectedLocation.storage, selectedLocation.shelve]);


  const titleDialogOF = {
    icon: <Widgets fontSize='large' color={"info"} />,
    render: t_("Fill a storage position with") + " " + (fabricationOrder ? fabricationOrder.partReference.name : "")
  }

  const onAccept = async () => {
    // Check all data is filled
    if (selectedPart === null || selectedLocation.storage === null || selectedLocation.shelve === null || selectedLocation.row === null || selectedLocation.column === null) {
      setShowErrors(true);
      return;
    }

    // Find the selected storage position
    let position = availableColumns.find((item) => item.depth === selectedLocation.column);

    // Find the selected part
    let newPart = availableParts.find((item) => item.id === selectedPart);

    let url = "actionFabricationOrder/" + action.id + "/" + position.id + "/" + newPart.id;

    if (selectedLocation.selectedFixture) {
      url += "/" + selectedLocation.selectedFixture.id;
    }

    await apiPost(url).then(() => {
      setOpen(false);
      dispatchSelectedLocation({ type: 'close_fixture' });
      onDone();
    }).catch(async (error) => {
      dispatchSelectedLocation({ type: 'close_fixture' });
      if (error.status !== 409) {
        showAlert({
          title: "",
          severity: "error",
          message: error.message,
        });
        return
      }
      // Show dialog to select fixture
      let resource = { ...newPart, type: { name: "Part" } };
      let reference = await apiPost("storageGetDefaultFixtureReferenceForResource", resource);
      if (!reference) {
        showAlert({
          title: "",
          severity: "error",
          message: t_("Could not add part to the Storage. No suitable fixture found"),
        });
        return;
      };
      dispatchSelectedLocation({ type: "showFixtureSelectDialog", value: reference })
    });

  }

  return (
    <>

      {selectedLocation.showFixtureDialog ?
        <SmartDialog setOpen={selectedLocation.showFixtureDialog}
          acceptCallback={() => onAccept()} cancelCallback={() => dispatchSelectedLocation({ type: "close_fixture" })} close={false}
          renderComponent={<Fixture referenceName={selectedLocation.selectedFixtureReference?.name} parameter={selectedLocation.selectedFixtureReference?.id} endpoint={"fixturesAvailableByReference"} hideColumns={true} onRowClick={(row) => dispatchSelectedLocation({ type: "set_selected_fixture", value: row })} />}
          title={
            {
              icon: <Stack
                direction="row"
                justifyContent="center"
                alignItems="center">
                <StorageRounded color="primary" />
                <Typography variant="h5" color="primary" sx={{ marginLeft: "0.3em" }}>{selectedLocation.storageName}</Typography>
              </Stack>,
              render: <h4>{t_("Select the Fixture to set")}</h4>
            }
          } /> : null}

      <SmartDialog
        message=""
        setOpen={open}
        title={titleDialogOF}
        accept={true}
        acceptCallback={onAccept}
        cancel={true}
        close={false}
        cancelCallback={() => setOpen(false)}
        renderComponent={
          <>
            {AlertElement}
            <Stack direction="row" gap={3} justifyContent="space-between" alignItems="flex-end">
              <FormControl fullWidth sx={{ minWidth: "10rem" }} error={showErrors && selectedLocation.storage === null}>
                <TextField
                  select
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={selectedLocation.storage === null ? "" : selectedLocation.storage}
                  label={t_("Storage")}
                  onChange={(event) => dispatchSelectedLocation({ type: "storage", value: event.target.value })}
                >
                  {availableStorages.map((item) => {
                    return <MenuItem key={"position-storage" + item.id} value={item.id}>{item.name}</MenuItem>
                  })}
                </TextField>
              </FormControl>

              <FormControl sx={{ minWidth: "6rem" }} fullWidth error={selectedLocation.storage !== null && showErrors && selectedLocation.shelve === null}>
                <TextField
                  select
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={selectedLocation.shelve === null ? "" : selectedLocation.shelve}
                  label={t_("Shelf")}
                  onChange={(event) => dispatchSelectedLocation({ type: "shelve", value: event.target.value })}
                  disabled={selectedLocation.storage === null}
                >
                  {avaiableShelfs.map((item) => {
                    return <MenuItem key={"position-shelve-" + item.shelve} value={item.shelve}>{item.shelve}</MenuItem>
                  })}
                </TextField>
              </FormControl>

              <FormControl sx={{ minWidth: "6rem" }} fullWidth error={selectedLocation.shelve !== null && showErrors && selectedLocation.row === null}>
                <TextField
                  select
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={selectedLocation.row === null ? "" : selectedLocation.row}
                  label={t_("Row")}
                  onChange={(event) => dispatchSelectedLocation({ type: "row", value: event.target.value })}
                  disabled={selectedLocation.shelve === null}
                >
                  {availableRows.map((item) => {
                    return <MenuItem key={"position-row-" + item.position} value={item.position}>{item.position}</MenuItem>
                  })}
                </TextField>
              </FormControl>

              <FormControl sx={{ minWidth: "6rem" }} fullWidth error={selectedLocation.row !== null && showErrors && selectedLocation.column === null}>
                <TextField
                  select
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={selectedLocation.column === null ? "" : selectedLocation.column}
                  label={t_("Column")}
                  onChange={(event) => dispatchSelectedLocation({ type: "column", value: event.target.value })}
                  disabled={selectedLocation.row === null}
                >
                  {availableColumns.map((item) => {
                    return <MenuItem key={"position-column-" + item.depth} value={item.depth}>{item.depth}</MenuItem>
                  })}
                </TextField>
              </FormControl>

              <Stack sx={{ minWidth: "10rem" }} gap={1}>
                <AddElement
                  title={t_("ADD NEW PART")}
                  endpoint={"parts"}
                  referenceName={t_("Parts")}
                  partReference={fabricationOrder ? fabricationOrder.partReference : null}
                  onAddDone={toogleForcePartReload}
                />
                <FormControl fullWidth error={showErrors && selectedPart === null}>
                  <InputLabel>{t_("Part")}</InputLabel>
                  <Select
                    value={selectedPart !== null ? selectedPart : ""}
                    label="Part"
                    onChange={(event) => setSelectedPart(event.target.value)}
                  >
                    {availableParts.map((item) => {
                      return <MenuItem key={"available-part-" + item.id} value={item.id}>{item.code}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Stack>
            </Stack >
          </>
        }
      />

    </>



  );
}

export default LoadActionDialog;