import React from "react";
import SmartNavigator from "../generic/SmartNavigator";
import Storage from "../management/Storage";
import StorageFillPosition from "../management/StorageFillPosition";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";

const MainStorage = () => {
  const { t_ } = useSmartTranslation();
  const componentsInfo = [
    {
      title: t_("Storages"),
      component: <Storage />,
    },
    {
      title: t_("Initial configuration"),
      component: <StorageFillPosition />,
    },
  ];
  return <SmartNavigator componentsInfo={componentsInfo} />;
};
export default MainStorage;
