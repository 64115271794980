import React from "react";
import SmartNavigator from "../generic/SmartNavigator";
import Inspection from "../dashboard/reports/Inspection";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";

const MainInspection = () => {
  const { t_ } = useSmartTranslation();

  const componentsInfo = [
    {
      title: t_("Inspection"),
      component: <Inspection />,
    },
  ];
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  );
};

export default MainInspection;
