import { List, ListItem, ListItemIcon, ListItemText, IconButton, Autocomplete, TextField, Paper, Tooltip } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState, useRef } from 'react';
import { apiGet } from "../../generic/Api_Functions";
import WarningIcon from '@mui/icons-material/Warning'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation';

const FixtureAssemblyList = (props) => {
  const {
    selectedItems,
    setSelectedItems,
    disabled = false,
    error = false,
  } = props;

  const [fixtureLinksById, setFixtureLinksById] = useState({});
  const loadingFixtureLinksId = useRef({});
  const { t_ } = useSmartTranslation();

  useEffect(() => {
    const loadItems = async () => {
      let changes = false;
      let loadedLinks = {};
      if (!("-1" in loadingFixtureLinksId.current)) {
        loadingFixtureLinksId['-1'] = true;
        loadedLinks["-1"] = await apiGet('fixtureReferences');
        changes = true;
      }
      for (const item of selectedItems) {
        if (!(item.id in loadingFixtureLinksId.current)) {
          loadingFixtureLinksId.current[item.id] = true;
          let links = (await apiGet('fixtureReferencesByFixtureReference/' + item.id));
          loadedLinks[item.id] = links;
          changes = true;
        }
      }
      if (changes) {
        setFixtureLinksById((prev) => ({ ...prev, ...loadedLinks }));
      }

    }
    if (!disabled) {
      loadItems();
    }
  }, [selectedItems, setFixtureLinksById, fixtureLinksById, disabled]);

  const deleteItem = (index) => {
    let newSelectedItems = [...selectedItems];
    newSelectedItems.splice(index, 1);
    setSelectedItems(newSelectedItems);
  }

  const addItem = (event, item) => {
    if (item) {
      setSelectedItems([item, ...selectedItems]);
    }
  }

  const getAvailableLinksForId = (id) => {
    if (id in fixtureLinksById) {
      return fixtureLinksById[id];
    } else {
      return [];
    }
  }

  let addItems = selectedItems.length === 0 ? getAvailableLinksForId("-1") : getAvailableLinksForId(selectedItems[0].id);

  // Check what index items can be removed
  let allowDeleteList = [];
  if (!disabled) {
    for (let i = 0; i < selectedItems.length; i++) {
      if (i === 0 || i === selectedItems.length - 1) {
        allowDeleteList.push(true);
      } else {
        allowDeleteList.push(getAvailableLinksForId(selectedItems[i + 1].id).some(item => item.id === selectedItems[i - 1].id));
      }
    }
  }

  return (
    <Paper variant="outlined" sx={{ paddingTop: '1rem', backgroundColor: "rgba(6, 23, 23, 0.09)" }}>

      {!disabled ?
        <Autocomplete sx={{ margin: "1rem", marginTop: "0" }}
          options={addItems}
          getOptionLabel={(item) => item.code}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          inputValue={""}
          onChange={addItem}
          value={null}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              error={error}
              variant="outlined"
              label={error ? <Tooltip placement="top" title={t_("A fixture assembly must contain at least two fixtures")}><WarningIcon /></Tooltip> : t_("Add fixture")}
            />
          )}
        /> : null
      }
      <List disablePadding={true}>
        {
          selectedItems.map((item, index) => {
            return (
              <ListItem key={item.id + "-" + index + "-" + disabled} divider={true}>
                <ListItemText primary={item.code} />
                {!disabled && allowDeleteList[index] ? <ListItemIcon><IconButton onClick={() => deleteItem(index)}><ClearIcon /></IconButton></ListItemIcon> : null}
              </ListItem >)
          })
        }

      </List>
    </Paper>
  );

}

export default FixtureAssemblyList;