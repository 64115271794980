import React from "react";
import SmartNavigator from "../generic/SmartNavigator";
import ToolGroups from "../configuration/tools/ToolGroup";
import ToolTypes from "../configuration/tools/ToolType";
import ToolTypeParameter from "../configuration/tools/ToolTypeParameter";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";

export default function MainConfigurationTools(props) {
  const activePath = props.activePath;
  const { t_ } = useSmartTranslation();
  const componentsInfo = [
    {
      title: t_("TOOL GROUPS"),
      component: <ToolGroups />,
      to: "/configuration/toolGroups",
    },
    {
      title: t_("TOOL TYPES"),
      component: < ToolTypes />,
      to: "/configuration/toolTypes",
    },
    {
      title: t_("TOOL PARAMETERS"),
      component: < ToolTypeParameter />,
      to: "/configuration/toolParameters",
    },

  ];

  return <SmartNavigator componentsInfo={componentsInfo} selected={activePath} />;
}