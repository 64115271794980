import React, { useCallback, useState } from 'react'
import Stack from '@mui/material/Stack'
import Calendar from './Calendar'

import { apiGet, apiPost } from '../generic/Api_Functions'
import { Alert } from '@mui/material'
import TimelineFooter from './TimelineFooter'
import { useTheme } from '@emotion/react'
import AlertUI from '../generic/AlertUI'

import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'
import useFrontEndConfig from '../generic/hooks/useFrontEndConfig/useFrontEndConfig'
export default function Timeline (props) {
  const { selectedFile, setSelectedFile, isPlannerWorking, setIsPlannerWorking, setCriteria, handleDownloadPlanification, forceReloadCalendar, publishedPlans, loadPublishedPlans } = props
  const [groupby, setGroupby] = useState('devices')
  const [editingEvent, setEditingEvent] = useState(null)
  const theme = useTheme()
  const { t_ } = useSmartTranslation()
  const [AlertElement, showAlert] = AlertUI()
  const [config] = useFrontEndConfig()
  const onTaskEditAccept = async () => {
    const priority = parseInt(editingEvent.priority)
    if (isNaN(priority) || (priority < 1 || priority > 10)) {
      setEditingEvent({ ...editingEvent, showErrorsTask: true })
      return
    }
    setEditingEvent(null)
    await apiPost('planner/part/' + editingEvent.partId, editingEvent)
  }

  const getTaskInfo = async (e) => {
    const partId = e.event.extendedProps.partId
    const data = await apiGet('planner/part/' + partId)
    setEditingEvent({ partId, ...data })
  }

  const onEventClick = useCallback((eventInfo) => {
    getTaskInfo(eventInfo)
  }, [])
  return (
    <>
      <Stack sx={{ backgroundColor: theme.palette.background.default }} padding={2}>
        {AlertElement}
        {isPlannerWorking ? <Alert severity='info' sx={{ marginBottom: '1rem' }}>{t_('Planner is working... Please wait')}</Alert> : null}
        <Calendar
          groupby={groupby}
          editable={selectedFile && (selectedFile.fileName === 'Temp' || selectedFile.fileName === 'Current')}
          onEventClick={selectedFile && config?.plannerIsActive && (selectedFile.fileName === 'Temp' || selectedFile.fileName === 'Current') ? onEventClick : null}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setGroupby={setGroupby}
          forceReloadCalendar={forceReloadCalendar}
          isPlannerWorking={isPlannerWorking}
        />
        <TimelineFooter
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setCriteria={setCriteria}
          setIsPlannerWorking={setIsPlannerWorking}
          isPlannerWorking={isPlannerWorking}
          handleDownloadPlanification={handleDownloadPlanification}
          editingEvent={editingEvent}
          setEditingEvent={setEditingEvent}
          onTaskEditAccept={onTaskEditAccept}
          publishedPlans={publishedPlans}
          loadPublishedPlans={loadPublishedPlans}
          showAlert={showAlert}
          config={config}
        />

      </Stack>
    </>
  )
}
