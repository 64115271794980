import React from "react";
import SmartNavigator from "../generic/SmartNavigator";
import Reference from "../configuration/parts/ReferenceTable";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";

const MainReference = () => {
  const { t_ } = useSmartTranslation();
  const componentsInfo = [
    {
      title: t_("Part Reference"),
      component: <Reference />,
    },
  ];
  return (
    <div>
      <SmartNavigator componentsInfo={componentsInfo} />
    </div>
  );
};

export default MainReference;
