import React, { useState } from 'react'
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
  , Typography
} from '@mui/material'

import { Link } from 'react-router-dom'
/* ICONS */
import {
  ArrowBack,
  ArrowDropDown,
  ArrowDropUp,
  ArrowForward,
  BlurLinearRounded,
  BuildRounded,
  CategoryRounded,
  DataUsageRounded,
  EventRounded,
  HomeRounded,
  PersonalVideoRounded,
  SettingsRounded,
  ListAlt
} from '@mui/icons-material'
import { useLocation } from 'react-router'
import './styles.css'
import useFrontEndConfig from '../../generic/hooks/useFrontEndConfig/useFrontEndConfig'
import NearMeIcon from '@mui/icons-material/NearMe'

import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import useAuthorization from '../../generic/hooks/useAuthorization/useAuthorization'

const ListItemDisable = (props) => (
  <ListItem
    selected={props.selected}
    button
    component={props.component}
    to={props.to}
    disableGutters
    onClick={props.onClick}
    sx={{
      width: '100%',
      maxWidth: 360,
      paddingLeft: theme => theme.spacing(3.5),
      color: '#787874',
      fontSize: '12px'
    }}
  >
    {props.children}
  </ListItem>
)

const ListItemNested = (props) => (
  <ListItem
    selected={props.selected}
    button
    component={props.component}
    to={props.to}
    disableGutters
    onClick={props.onClick}
    disabled={props.disabled}
    sx={{
      color: '#D1A22E',
      width: '100%',
      maxWidth: 360,
      paddingLeft: theme => theme.spacing(3.5),
      fontSize: '12px'
    }}
  >
    {props.children}
  </ListItem>
)

const ListItemNested2 = (props) => (
  <ListItem
    selected={props.selected}
    button
    component={props.component}
    to={props.to}
    disableGutters
    onClick={props.onClick}
    disabled={props.disabled}
    sx={{
      width: '100%',
      maxWidth: 360,
      paddingLeft: theme => theme.spacing(4.5),
      fontSize: '12px'
    }}
  >
    {props.children}
  </ListItem>
)

const ListItemMain = (props) => (
  <ListItem
    selected={props.selected}
    button
    component={props.component}
    to={props.to}
    disableGutters
    onClick={props.onClick}
    disabled={props.disabled}
    sx={{
      paddingLeft: '5px',
      marginTop: 1
    }}
  >
    {props.children}
  </ListItem>
)

const MyTypography = (props) => (
  <Typography sx={{
    paddingLeft: '5px',
    fontSize: '14px',
    fontWeight: 500
  }}
  >
    {props.children}
  </Typography>
)

const iconsShadowStyle = {
  maxWidth: 50,
  minWidth: 50,
  fontSize: '28px !important',
  filter: 'drop-shadow(0 0 4px #05fd9265)',
  color: '#80e0a7'
}

export default function LeftMenu (props) {
  const { url, handleMinimizeDrawer, minimizeDrawer, setHideMenu } = props
  const [openProcess, setOpenProcess] = useState(false)
  const [openResources, setOpenResources] = useState(false)
  const [openDataAnalytics, setOpenDataAnalytics] = useState(false)
  const [openReport, setOpenReport] = useState(false)
  const [openConfiguration, setOpenConfiguration] = useState(false)
  const [openMaintenance, setOpenMaintenance] = useState(false)
  const [currentPage, setCurrenPage] = useState('')
  const [staticConfig] = useFrontEndConfig()
  const isUserAuthorized = useAuthorization()

  const handleCloseAllMenus = () => {
    setOpenProcess(false)
    setOpenResources(false)
    setOpenDataAnalytics(false)
    setOpenReport(false)
    setOpenConfiguration(false)
    setOpenMaintenance(false)
  }

  const AutoCollapse = () => {
    const location = useLocation()
    if (location !== null) {
      const path = location.pathname
      if (currentPage !== path) {
        setCurrenPage(path)
        if ((path.includes('/process/') || path.endsWith('/process')) && !path.includes('compass')) {
          if (!openProcess) {
            setOpenProcess(true)
          }
        } else {
          if (openProcess) {
            setOpenProcess(false)
          }
        }
        if (path.includes('/resources/') || path.endsWith('/resources')) {
          if (!openResources) {
            setOpenResources(true)
          }
        } else {
          if (openResources) {
            setOpenResources(false)
          }
        }

        if (path.includes('/dataAnalytics/') || path.endsWith('/dataAnalytics')) {
          if (!openDataAnalytics) {
            setOpenDataAnalytics(true)
          }
        } else if (!path.includes('/reports')) {
          if (openDataAnalytics) {
            setOpenDataAnalytics(false)
          }
        }
        if (path.includes('/reports/') || path.endsWith('/reports')) {
          if (!openReport) {
            setOpenReport(true)
          }
        } else {
          if (openReport) {
            setOpenReport(false)
          }
        }
        if (path.includes('/configuration/') || path.endsWith('/configuration')) {
          if (!openConfiguration) {
            setOpenConfiguration(true)
          }
        } else {
          if (openConfiguration) {
            setOpenConfiguration(false)
          }
        }
      }
    }
  }

  const { t_ } = useSmartTranslation()

  return (
    <div id='leftMenu' className='left-menu-container'>
      {AutoCollapse()}
      <List component='nav'>
        <ListItemMain
          selected={url === '/home'}
          button
          component={Link}
          to='/home'
          disableGutters

        >

          <HomeRounded sx={iconsShadowStyle} />
          <MyTypography>{t_('HOME')}</MyTypography>
        </ListItemMain>
        <ListItemMain
          selected={url === '/monitoring'}
          button
          component={Link}
          to='/monitoring'
          disableGutters
          disabled={!isUserAuthorized('MONITORING')}
        >
          <PersonalVideoRounded sx={iconsShadowStyle} />
          <MyTypography>
            {t_('MONITORING')}
          </MyTypography>
        </ListItemMain>

        <ListItemMain
          selected={url === '/planning'}
          button
          component={Link}
          to='/planning'
          disableGutters
          disabled={!isUserAuthorized('PLANNING')}
        >
          <EventRounded sx={iconsShadowStyle} />
          <MyTypography>{t_('PLANNING')}</MyTypography>
        </ListItemMain>

        <ListItemMain
          selected={url === '/orders'}
          button
          component={Link}
          to='/orders'
          disableGutters
          disabled={!isUserAuthorized('ORDERS')}
        >
          <ListAlt sx={iconsShadowStyle} />
          <MyTypography>{t_('ORDERS')}</MyTypography>
        </ListItemMain>

        <ListItemMain
          selected={url === '/process'}
          button
          disableGutters
          disabled={!isUserAuthorized('PROCESS')}
          onClick={() => setOpenProcess(!openProcess)}
        >
          <BlurLinearRounded sx={iconsShadowStyle} />

          <MyTypography>{t_('PROCESS')}</MyTypography>
          {openProcess ? <ArrowDropUp /> : <ArrowDropDown />}
        </ListItemMain>

        <Collapse in={openProcess} timeout='auto' unmountOnExit>
          <ListItemNested
            selected={url === '/process/jobLinker'}
            button
            component={Link}
            to='/process/jobLinker'
            disableGutters
            disabled={!isUserAuthorized('JOB LINKER')}
          >
            <MyTypography>
              {t_('Job Linker')}
            </MyTypography>
          </ListItemNested>

          <ListItemNested
            selected={url === '/process/storage'}
            button
            component={Link}
            to='/process/storage'
            disableGutters
            disabled={!isUserAuthorized('STORAGE')}
          >
            <MyTypography>{t_('Storage')}</MyTypography>
          </ListItemNested>

          <ListItemNested
            selected={url === '/process/formula'}
            button
            component={Link}
            to='/process/formula'
            disableGutters
          >
            <MyTypography>
              {t_('Formula')}
            </MyTypography>
          </ListItemNested>

        </Collapse>

        <ListItemMain
          selected={url === '/resources'}
          button
          onClick={() => setOpenResources(!openResources)}
          disableGutters
          disabled={!isUserAuthorized('RESOURCES')}
        >
          <CategoryRounded sx={iconsShadowStyle} />
          <MyTypography>{t_('RESOURCES')}</MyTypography>
          {openResources ? <ArrowDropUp /> : <ArrowDropDown />}
        </ListItemMain>
        <Collapse in={openResources} timeout='auto' unmountOnExit>
          <ListItemNested
            selected={url === '/resources/reference'}
            button
            component={Link}
            to='/resources/reference'
            disableGutters
            disabled={!isUserAuthorized('PART')}
          >
            <MyTypography>{t_('Part')}</MyTypography>
          </ListItemNested>

          <ListItemNested
            selected={url === '/resources/rawmaterialReference'}
            button
            component={Link}
            to='/resources/rawmaterialReference'
            disableGutters
            disabled={!isUserAuthorized('RAW MATERIAL')}
          >
            <MyTypography>
              {t_('Raw Material')}
            </MyTypography>
          </ListItemNested>

          <ListItemNested
            selected={url === '/resources/fixtureReference'}
            button
            component={Link}
            to='/resources/fixtureReference'
            disableGutters
            disabled={!isUserAuthorized('FIXTURE')}
          >
            <MyTypography>{t_('Fixture')}</MyTypography>
          </ListItemNested>

          <ListItemNested
            selected={url === '/resources/toolManager'}
            button
            component={Link}
            to='/resources/toolManager'
            disableGutters
            disabled={!isUserAuthorized('TOOL MANAGER')}
          >
            <MyTypography>{t_('Tool Manager')}</MyTypography>
          </ListItemNested>
        </Collapse>

        <ListItemMain
          selected={url === '/dataAnalytics'}
          button
          onClick={() => setOpenDataAnalytics(!openDataAnalytics)}
          disableGutters
          disabled={!isUserAuthorized('ANALYTICS')}
        >
          <DataUsageRounded sx={iconsShadowStyle} />
          <MyTypography>
            {t_('ANALYTICS')}
          </MyTypography>
          {openDataAnalytics ? <ArrowDropUp /> : <ArrowDropDown />}
        </ListItemMain>

        <Collapse in={openDataAnalytics} timeout='auto' unmountOnExit>
          <ListItemNested
            selected={url === '/dataAnalytics/dashboard'}
            button
            component={Link}
            to='/dataAnalytics/dashboard'
            disableGutters
            disabled={!isUserAuthorized('DASHBOARD')}
          >
            <MyTypography>{t_('Dashboard')}</MyTypography>
          </ListItemNested>
          <ListItemDisable
            selected={url === '/dataAnalytics/prediction'}
            button
            component={Link}
            to='/dataAnalytics/prediction'
            disableGutters
            disabled={!isUserAuthorized('PREDICTION')}
          >
            <MyTypography>{t_('Prediction')}</MyTypography>
          </ListItemDisable>

          <ListItemNested
            selected={url === '/dataAnalytics/reports'}
            button
            onClick={() => setOpenReport(!openReport)}
            disableGutters
            disabled={!isUserAuthorized('REPORTS')}
          >
            <MyTypography>{t_('Reports')}</MyTypography>
            {openReport ? <ArrowDropUp /> : <ArrowDropDown />}
          </ListItemNested>

          <Collapse in={openReport} timeout='auto' unmountOnExit>
            <ListItemNested2
              selected={url === '/dataAnalytics/reports/preset'}
              button
              component={Link}
              to='/dataAnalytics/reports/preset'
              disableGutters
              disabled={!isUserAuthorized('PRESET')}
            >
              <MyTypography>{t_('Preset')}</MyTypography>
            </ListItemNested2>

            <ListItemNested2
              selected={
                url === '/dataAnalytics/reports/inspection'
              }
              button
              component={Link}
              to='/dataAnalytics/reports/inspection'
              disableGutters
              disabled={!isUserAuthorized('INSPECTION')}
            >
              <MyTypography>
                {t_('Inspection')}
              </MyTypography>
            </ListItemNested2>
          </Collapse>
        </Collapse>

        <ListItemMain
          selected={url === '/configuration'}
          button
          onClick={() => setOpenConfiguration(!openConfiguration)}
          disableGutters
          disabled={!isUserAuthorized('CONFIGURATION')}
          to='/configuration'
        >
          <SettingsRounded sx={iconsShadowStyle} />
          <MyTypography>
            {t_('CONFIGURATION')}
          </MyTypography>
          {openConfiguration ? <ArrowDropUp /> : <ArrowDropDown />}
        </ListItemMain>
        <Collapse in={openConfiguration} timeout='auto' unmountOnExit>
          <ListItemNested
            selected={url === '/configuration/assets'}
            button
            component={Link}
            to='/configuration/assets'
            disableGutters
            disabled={!isUserAuthorized('ASSETS')}
          >
            <MyTypography>{t_('Assets')}</MyTypography>
          </ListItemNested>
          <ListItemNested
            selected={url === '/configuration/fixtureTypes'}
            button
            component={Link}
            to='/configuration/fixtureTypes'
            disableGutters
            disabled={!isUserAuthorized('FIXTURE TYPES')}
          >
            <MyTypography>{t_('Fixture Types')}</MyTypography>
          </ListItemNested>
          <ListItemNested
            selected={url === '/configuration/workflows'}
            button
            component={Link}
            to='/configuration/workflows'
            disableGutters
            disabled={!isUserAuthorized('WORKFLOWS')}
          >
            <MyTypography>{t_('Workflows')}</MyTypography>
          </ListItemNested>
          <ListItemNested
            selected={url === '/configuration/sensorsActuators'}
            button
            component={Link}
            to='/configuration/sensorsActuators'
            disableGutters
            disabled={!isUserAuthorized('SENSORS & ACTUATORS')}
          >
            <MyTypography>
              {t_('Sensors & Actuators')}
            </MyTypography>
          </ListItemNested>
          <ListItemNested
            selected={url === '/configuration/technology'}
            button
            component={Link}
            to='/configuration/technology'
            disableGutters
            disabled={!isUserAuthorized('TECHNOLOGY')}
          >
            <MyTypography>{t_('Technology')}</MyTypography>
          </ListItemNested>
          <ListItemNested
            selected={url === '/configuration/toolGroups'}
            button
            component={Link}
            to='/configuration/toolGroups'
            disableGutters
            disabled={!isUserAuthorized('TOOLS')}
          >
            <MyTypography>{t_('Tools')}</MyTypography>
          </ListItemNested>
          <ListItemNested
            selected={url === '/configuration/calendars'}
            button
            component={Link}
            to='/configuration/calendars'
            disableGutters
            disabled={!isUserAuthorized('CALENDARS')}
          >
            <MyTypography>{t_('Calendars')}</MyTypography>
          </ListItemNested>
          <ListItemNested
            selected={url === '/configuration/picklist'}
            button
            component={Link}
            to='/configuration/picklist'
            disableGutters
            disabled={!isUserAuthorized('PICKLIST EDITOR')}
          >
            <MyTypography>{t_('Picklist')}</MyTypography>
          </ListItemNested>

          <ListItemNested
            selected={url === '/configuration/rule'}
            button
            component={Link}
            to='/configuration/rule'
            disableGutters
            disabled={!isUserAuthorized('RULE')}
          >
            <MyTypography>{t_('Rule')}</MyTypography>
          </ListItemNested>
          <ListItemDisable
            selected={url === '/configuration/customer'}
            button
            component={Link}
            to='/configuration/customer'
            disableGutters
            disabled={!isUserAuthorized('CUSTOMER')}
          >
            <MyTypography>{t_('Customer')}</MyTypography>
          </ListItemDisable>
          <ListItemNested
            selected={url === '/configuration/workers'}
            button
            component={Link}
            to='/configuration/workers'
            disableGutters
            disabled={!isUserAuthorized('WORKERS')}
          >
            <MyTypography>{t_('Workers')}</MyTypography>
          </ListItemNested>
          <ListItemNested
            selected={url === '/configuration/materials'}
            button
            component={Link}
            to='/configuration/materials'
            disableGutters
            disabled={!isUserAuthorized('MATERIALS')}
          >
            <MyTypography>{t_('Materials')}</MyTypography>
          </ListItemNested>
        </Collapse>
        <ListItemMain
          selected={url === '/maintenance'}
          onClick={() => setOpenMaintenance(!openMaintenance)}
          button
          disableGutters
          disabled={!isUserAuthorized('MAINTENANCE')}
          to='/maintenance'
        >
          <BuildRounded sx={iconsShadowStyle} />
          <MyTypography>{t_('MAINTENANCE')}</MyTypography>
          {openMaintenance ? <ArrowDropUp /> : <ArrowDropDown />}
        </ListItemMain>
        <Collapse in={openMaintenance} timeout='auto' unmountOnExit>
          <ListItemNested
            selected={url === '/maintenance/alarms'}
            button
            component={Link}
            to='/maintenance/alarms'
            disableGutters
            disabled={!isUserAuthorized('ALARMS')}
          >
            <MyTypography>{t_('Alarms')}</MyTypography>
          </ListItemNested>
          <ListItemNested
            selected={url === '/maintenance/notifications'}
            button
            component={Link}
            to='/maintenance/notifications'
            disableGutters
            disabled={!isUserAuthorized('NOTIFICATIONS')}
          >
            <MyTypography>{t_('Notifications')}</MyTypography>
          </ListItemNested>

          {staticConfig && staticConfig.cell === 'CELL007 - SMARTPM'
            ? (
              <ListItem
                selected={url === '/maintenance/presenceDetection'}
                button
                component={Link}
                to='/maintenance/presenceDetection'
                disableGutters
              >
                <MyTypography>{t_('Presence Detection')}</MyTypography>
              </ListItem>)
            : (null)}
        </Collapse>

        <ListItemMain
          selected={url === '/compass'}
          button
          component={Link}
          onClick={() => setHideMenu(true)}
          to='/compass'
          disableGutters
          disabled={!isUserAuthorized('COMPASS')}
        >

          <NearMeIcon sx={iconsShadowStyle} />
          <MyTypography>{t_('COMPASS')}</MyTypography>
        </ListItemMain>
      </List>
      <Divider />
      <List>
        {minimizeDrawer
          ? (
            <ListItem button onClick={handleMinimizeDrawer}>
              <ListItemIcon>
                <ArrowForward />
              </ListItemIcon>
              <ListItemText primary={t_('Close')} />
            </ListItem>
            )
          : (
            <ListItem
              onClick={() => handleMinimizeDrawer() & handleCloseAllMenus()}
              button
            >
              <ListItemIcon>
                <ArrowBack />
              </ListItemIcon>

              <ListItemText primary={t_('Close')} />
            </ListItem>
            )}
      </List>

    </div>
  )
}
