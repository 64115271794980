import Actions from './Actions'
import { Badge, Paper } from '@mui/material'
import MoveRequest from './moveRequest/MoveRequest'
import { Stack } from '@mui/system'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { useNavigate } from 'react-router-dom'
import { CompassButton } from '../../Compass/Common/CompassButton'
import { useUncheckedActions } from '../../generic/hooks/useUncheckedActions'
import actionBadgeStyle from './ActionBadgeStyle'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import React, { useState, useEffect } from 'react'
import WorkingParts from '../../configuration/parts/controlledStop/WorkingParts'
import { apiGet } from '../../generic/Api_Functions'

const ActionCenter = ({ activePath }) => {
  const { t_ } = useSmartTranslation()
  const navigate = useNavigate()
  const uncheckedActions = useUncheckedActions()
  const [showWorkingParts, setShowWorkingParts] = useState(false)
  const [cellGeneralStatus, setCellGeneralStatus] = useState('')

  const moveModeByLocation = activePath === 'command' ? null : activePath?.includes('/byLocation')

  useEffect(() => {
    const fetchCellGeneralStatus = async () => {
      const response = await apiGet('cellGeneralStatus')
      setCellGeneralStatus(response)
    }
    fetchCellGeneralStatus()
    const interval = setInterval(fetchCellGeneralStatus, 1000)
    return () => clearInterval(interval)
  }, [])

  const getToolbarButtons = (activePath) => {
    return (
      <Stack direction='row' gap={6}>
        <Badge badgeContent={uncheckedActions} sx={{ ...actionBadgeStyle, '& .MuiBadge-badge': { transform: uncheckedActions > 0 ? 'translate(0.2rem,-0.2rem)' : null } }}>
          <CompassButton
            label={t_('Pending Actions')}
            width='auto'
            height='auto'
            color={activePath === 'action' ? 'primary' : 'secondary'}
            onClick={() => { navigate('/compass/process/action') }}
            sx={{ padding: '1.5rem', paddingTop: 0, paddingBottom: 0 }}
          />
        </Badge>
        <CompassButton
          label={t_('Send Command')}
          width='auto'
          height='auto'
          color={activePath.includes('command') ? 'primary' : 'secondary'}
          onClick={() => { navigate('/compass/process/command') }}
          sx={{ padding: '1.5rem', paddingTop: '1rem', paddingBottom: '1rem' }}
        />
      </Stack>
    )
  }

  const defaultToolbar = {
    render: {
      fetchFunction: () => getToolbarButtons(activePath)
    }
  }

  if (activePath === 'action') {
    return <Actions toolbar={defaultToolbar} />
  }

  return (
    <>
      <SmartDialog
        renderComponent={<WorkingParts />}
        setOpen={showWorkingParts}
        cancel={false}
        accept={false}
        close
        closeCallback={() => { setShowWorkingParts(false) }}
      />
      <Paper
        sx={{
          background: theme => theme.palette.background.color_373636,
          paddingLeft: '24px',
          height: '85%'
        }}
      >
        <Stack sx={{ height: '100%' }}>
          <Stack direction='row' alignItems='center' sx={{ height: '100px', marginLeft: '24px' }}>
            {getToolbarButtons(activePath)}
          </Stack>
          <Stack direction='column' justifyContent='center' sx={{ flexGrow: 1, paddingBottom: '100px' }}>
            {
              moveModeByLocation == null
                ? <MoveModeSelector onModeSelected={(mode) => navigate('/compass/process/command/' + (mode ? 'byLocation' : 'byResource'))} setShowWorkingParts={setShowWorkingParts} cellGeneralStatus={cellGeneralStatus} />
                : <MoveRequest moveModeByLocation={moveModeByLocation} />
            }
          </Stack>
        </Stack>
      </Paper>
    </>
  )
}

const MoveModeSelector = (props) => {
  const { onModeSelected, setShowWorkingParts, cellGeneralStatus } = props
  const { t_ } = useSmartTranslation()

  return (
    <Stack direction='row' justifyContent='center' alignItems='center' sx={{ height: '80%' }} gap={20}>
      <CompassButton width='auto' height='auto' labelStyle={{ fontSize: '1.8rem' }} onClick={() => onModeSelected(true)} sx={{ padding: '2rem' }} label={t_('Move by location')} />
      <CompassButton width='auto' height='auto' labelStyle={{ fontSize: '1.8rem' }} onClick={() => onModeSelected(false)} sx={{ padding: '2rem' }} label={t_('Move by resource')} />
      <CompassButton
        width='auto'
        height='auto'
        labelStyle={{ fontSize: '1.8rem' }}
        onClick={() => setShowWorkingParts(true)}
        sx={{
          padding: '2rem',
          '&:disabled': {
            opacity: 0.3
          }
        }}
        label={t_('Controlled Stop')}
        disabled={cellGeneralStatus === 'OFF'}
      />
    </Stack>
  )
}

export default ActionCenter
