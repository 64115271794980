import { IconButton, Stack, TablePagination } from "@mui/material";
import { ArrowLeftRounded, ArrowRightRounded, KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";


const PaginationArrowsStack = (props) => (
  <Stack
    direction="row"
    justifyContent="center"
    alignItems="center" sx={{
      marginLeft: theme => theme.spacing(2.5)
    }}
  >
    {props.children}
  </Stack >
)
//Table pagination custom buttons
function TablePaginationActions(props) {
  // const classes = useStyles();
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };
  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <PaginationArrowsStack >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {<ArrowLeftRounded />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {<KeyboardArrowLeftRounded />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {<KeyboardArrowRightRounded />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {<ArrowRightRounded />}
      </IconButton>
    </PaginationArrowsStack>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const SmartMateriaUITablePagination = (props) => {
  const { t_ } = useSmartTranslation();
  const {
    pageable,
    searchAndPaginationVisible,
    setPage,
    setRowsPerPage,
    pageableObject,
    setPageableObject,
    editIndex,
    getTableDataFiltered,
    rowsPerPage,
    page,
    loadTable,
    setLoadTable
  } = props;

  //Table pagination
  const handleChangePage = (event, newPage) => {
    //change page
    const pageableCopy = { ...pageableObject }
    pageableCopy.number = newPage
    setPageableObject(pageableCopy)
    if (!pageable)
      setPage(newPage);
    setLoadTable(!loadTable)
  };

  const handleChangeRowsPerPage = (event) => {
    //change rows per page
    const pageableCopy = { ...pageableObject }
    pageableCopy.page = 0
    pageableCopy.size = (parseInt(event.target.value, 10))
    setPageableObject(pageableCopy)
    if (!pageable) {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }
    setLoadTable(!loadTable)
  };

  const getPaginationCount = () => {
    let standardCount = getTableDataFiltered().length;
    if (editIndex === -1) {
      standardCount++;
    }
    return standardCount;
  };

  return (
    ((pageable && Object.keys(pageableObject).length !== 0) || (!pageable && rowsPerPage !== '')) ?
      <TablePagination sx={{ borderTop: "1px solid rgba(81, 81, 81, 1)", backgroundColor: "#373636 !important", display: [searchAndPaginationVisible] }}
        rowsPerPageOptions={[3, 5, 10, 50]}
        count={pageable ? (pageableObject.totalElements === undefined ? 0 : pageableObject.totalElements) : (getPaginationCount() === undefined ? 0 : getPaginationCount())}
        page={pageable ? pageableObject.number : page}
        onPageChange={handleChangePage}
        rowsPerPage={pageable ? pageableObject.size : rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        labelRowsPerPage={t_("Rows per page:")}
      />
      : <></>
  )
}


export default SmartMateriaUITablePagination