import { useLocation, useNavigate } from 'react-router-dom'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import CompassWindow from '../Common/CompassWindow/CompassWindow'
import { useEffect, useState } from 'react'
import { apiGet, apiPost } from '../../generic/Api_Functions'
import CompassButton from './../Common/CompassButton/CompassButton'
import { DefaultCompassPopper } from '../Common/CompassPopper/CompassPopper'
import { Box, Divider, Stack, TextField, Typography } from '@mui/material'
import NumericKeyBoard from '../Common/Keyboard/NumericKeyBoard'
import KeyboardIcon from '@mui/icons-material/Keyboard'
import { FeatureIndicatorSlider, getFeatureDesviationData } from '../../generic/FeatureIndicatorSlider'
import moment from 'moment'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import RoundedIconButton from '../Common/CompassButton/RoundedIconButton'
import DocumentPreview from '../../configuration/document/DocumentPreview'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import Document from '../../configuration/document/Document'
import { useToggle } from '../../generic/hooks/useToggle'

const InspectReportFeature = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { fabricationOrder, worker, featureId } = location.state
  const { t_ } = useSmartTranslation()
  const [selectedFeature, setSelectedFeature] = useState(null)
  const [features, setFeatures] = useState([])
  const [featureSelectorOpen, setFeatureSelectorOpen] = useState(false)
  const [value, setValue] = useState('')
  const [errorData, setErrorData] = useState({ open: false, content: null, showErrors: false })
  const [comment, setComment] = useState('')
  const [selectedQualitativeFeatureValue, setSelectedQualitativeFeatureValue] = useState(null)
  const [showDocuments, setShowDocuments] = useState(false)
  const [forceReloadDocuments, reloadDocuments] = useToggle()

  const formLabelStyle = {
    fontSize: '1.5rem'
  }

  useEffect(() => {
    const updateFeatures = async () => {
      const response = await apiGet('compass/feature/fabricationOrder/' + fabricationOrder.partReferenceId + '/' + fabricationOrder.jobReferenceId)
      setFeatures(response)
    }
    if (!fabricationOrder) {
      return
    }
    updateFeatures()
  }, [fabricationOrder])

  useEffect(() => {
    if (!features) {
      return
    }
    setSelectedFeature(features.find((item) => item.featureId === featureId))
  }, [featureId, features])

  const goBack = () => {
    navigate('/compass/inspect')
  }
  const adjustedUpperTolerance = selectedFeature?.upperTolerance >= 0 ? `+${selectedFeature?.upperTolerance}` : `-${selectedFeature?.upperTolerance}`
  const getFeatureButton = (feature) => {
    return (
      <CompassButton
        label={feature.featureName}
        onClick={() => {
          setSelectedFeature(feature)
          setFeatureSelectorOpen(false)
        }}
        labelStyle={formLabelStyle}
        isSelected={selectedFeature && selectedFeature.featureId === feature.featureId}
        color='secondary'
        isSelectedColor='primary'
      />

    )
  }

  const getItemValueStack = (label, value, labelSx, valueSx) => {
    return <><Typography sx={labelSx} color='primary' fontSize='1.3rem'>{label + ':'}</Typography><Typography fontSize='1.3rem' sx={valueSx}>{value}</Typography></>
  }

  const desviationData = getFeatureDesviationData(value, selectedFeature?.nominal, selectedFeature?.upperTolerance, selectedFeature?.lowerTolerance)

  const accept = async () => {
    // Check value is valid
    if (!selectedQualitativeFeatureValue && isNaN(parseFloat(value))) {
      setErrorData({ open: true, content: t_('Measurement is not valid'), showErrors: true })
      return
    }
    const data = { ok: desviationData.toleranceRange === 0 || (selectedQualitativeFeatureValue && selectedQualitativeFeatureValue === 'OK'), workerId: worker.id, comment, features: [{ id: selectedFeature.featureId, unitId: null, value: selectedQualitativeFeatureValue || value }] }
    try {
      await apiPost('compass/inspectionReport/' + fabricationOrder.id + '/' + fabricationOrder.jobReferenceId, data)
      goBack()
    } catch (error) {
      setErrorData({ open: true, content: error.message, showErrors: false })
    }
  }

  return (
    <CompassWindow
      renderTitle={t_('Control procedure') + ' ' + t_('Reference') + ': ' + fabricationOrder.partReferenceName}
      message={errorData}
      onCloseMessage={() => setErrorData({ ...errorData, open: false })}
      onClose={goBack}
      render={
        <>
          <SmartDialog
            closeCallback={() => {
              reloadDocuments()
              setShowDocuments(false)
            }}
            accept={false}
            cancel={false}
            close
            renderComponent={<Document reference={selectedFeature ? { id: selectedFeature.featureId, name: selectedFeature.featureName } : null} resourceType='FEATURE' />}
            setOpen={showDocuments}
          />
          <Typography color='primary' style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
            {t_('Worker')}: <span style={{ fontSize: '1.3rem', color: 'white' }}>{worker.name} {worker.surname}</span>
          </Typography>

          <Typography color='primary' style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
            {t_('Fabrication Order')}: <span style={{ fontSize: '1.3rem', color: 'white' }}>{fabricationOrder.fabricationOrder}</span>
          </Typography>

          <Typography color='primary' sx={{ marginTop: '1rem', marginBottom: '1.5rem' }}>{t_('Feature')}:</Typography>

          <Stack direction='row' gap={1} alignItems='center'>
            {selectedFeature ? <CompassButton color='primary' label={selectedFeature.featureName} width='auto' isSelected labelStyle={formLabelStyle} /> : null}
            {features.length > 1
              ? (
                <DefaultCompassPopper
                  open={featureSelectorOpen}
                  setOpen={setFeatureSelectorOpen}
                  render={
                      features.map((f) => getFeatureButton(f))
                  }
                />
                )
              : null}

          </Stack>

          <Box sx={{ display: 'grid', gridTemplateColumns: '40% min-content 50%', marginTop: '3rem', gap: '1rem' }}>
            <DocumentPreview referenceId={selectedFeature?.featureId} onShowDocumentsClick={() => setShowDocuments(true)} forceReloadDocuments={forceReloadDocuments} resourceType='FEATURE' />
            <Divider orientation='vertical' />
            {selectedFeature && selectedFeature.featureType.name === 'Quantitative'
              ? (
                <Stack gap={1}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '1rem', alignItems: 'center' }}>

                    {getItemValueStack(t_('Nominal'), selectedFeature?.nominal, 'start')}
                    {getItemValueStack(t_('Unit'), selectedFeature?.unit, 'start')}
                    {getItemValueStack(t_('Upper tolerance'), adjustedUpperTolerance, 'start')}
                    {getItemValueStack(t_('Lower tolerance'), selectedFeature?.lowerTolerance, 'start')}

                    <Typography color='primary' sx={{ fontSize: '1.5rem', gridColumn: 'span 2' }}>{t_('Measurement')}</Typography>
                    <Stack direction='row' sx={{ gridColumn: 'span 2' }}>
                      <TextField
                        inputProps={{ style: { fontSize: '1.5rem' } }}
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                        error={errorData.showErrors && isNaN(parseFloat(value))}
                      />
                      <NumericKeyBoard
                        value={value} onChange={setValue}
                        decimalMode
                        buttonIcon={<KeyboardIcon />}
                      />
                    </Stack>

                    {getItemValueStack(t_('Date'), moment().format('DD/MM/YYYY, hh:mm'), { gridColumn: 'span 1' }, { gridColumn: 'span 3' })}
                    {getItemValueStack(t_('Deviation'), isNaN(desviationData.desviation) ? '' : desviationData.desviation)}
                    <FeatureIndicatorSlider sx={{ gridColumn: 'span 2' }} value={value} nominalValue={selectedFeature?.nominal} upperTolerance={selectedFeature?.upperTolerance} lowerTolerance={selectedFeature?.lowerTolerance} />
                  </div>
                </Stack>)
              : null}
            {selectedFeature && selectedFeature.featureType.name === 'Qualitative'
              ? (
                // Show stack of qualitative features
                <Stack direction='column' gap={3} sx={{ justifyContent: 'start', textAlign: 'center', alignItems: 'start' }}>
                  <Stack style={{ width: '100%', textAlign: 'center', justifyContent: 'center' }}>
                    {getItemValueStack(t_('Attribute'), selectedFeature?.qualitativeAttribute, 'center')}
                  </Stack>
                  <Stack sx={{ width: '100%' }} direction='row' gap={2} justifyContent='center'>
                    <RoundedIconButton
                      selected={selectedQualitativeFeatureValue && selectedQualitativeFeatureValue === 'OK'}
                      backgroundColor='green'
                      icon={<CheckIcon style={{ transform: ' scale(2.2)' }} />}
                      onClick={() => { setSelectedQualitativeFeatureValue('OK') }}
                    />
                    <RoundedIconButton
                      selected={selectedQualitativeFeatureValue && selectedQualitativeFeatureValue === 'NO_OK'}
                      backgroundColor='red'
                      icon={<CloseIcon style={{ transform: ' scale(2.2)' }} />}
                      onClick={() => { setSelectedQualitativeFeatureValue('NO_OK') }}
                    />
                  </Stack>
                </Stack>)
              : null}
          </Box>

          <TextField label={t_('Comment')} fullWidth variant='filled' margin='normal' rows={4} multiline value={comment} onChange={(event) => setComment(event.target.value)} />

          <Stack direction='row' sx={{ marginTop: '3rem', minHeight: '6rem' }} gap={1}>
            <CompassButton width='auto' label={t_('ACCEPT')} sx={{ flexGrow: 1 }} labelStyle={formLabelStyle} onClick={accept} color='primary' />
            <CompassButton width='auto' label={t_('CANCEL')} sx={{ flexGrow: 1 }} labelStyle={formLabelStyle} onClick={goBack} color='secondary' />
          </Stack>

        </>

  }
    />

  )
}

export default InspectReportFeature
