import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import es from "./components/local/es";
import en from "./components/local/en";
import eus from "./components/local/eu";
import fr from "./components/local/fr";
import ja from "./components/local/ja";
import ca from "./components/local/ca";
import chn from "./components/local/zh_Hans";
import pt from "./components/local/pt_PT";

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    //.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //.use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'en',
        resources: {
            es: {
                translation: es
            },
            en: {
                translation: en
            },
            eus: {
                translation: eus
            },
            pt: {
                translation: pt
            },
            fr: {
                translation: fr
            },
            ca: {
                translation: ca
            },
            ja: {
                translation: ja
            },
            chn: {
                translation: chn
            }
        },
        fallbackLng: 'en', // set fallback language
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });


export default i18n;