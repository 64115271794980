import * as React from 'react'
import { styled, Paper, Grid } from '@mui/material'
import { VideoSettings, ManageHistory, PendingActions, Troubleshoot, CoPresent } from '@mui/icons-material'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSmartTranslation } from '../generic/hooks/useSmartTranslation'

import CompassButton from './Common/CompassButton/CompassButton'
import { useAuthorization } from '../generic/hooks/useAuthorization'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: theme.spacing(1),
  textAlign: 'center',
  boxShadow: 'none',
  backgroundImage: 'none'
}))

export function MainCompassNav (props) {
  const { t_ } = useSmartTranslation()
  const { element } = props
  const location = useLocation()
  const navigate = useNavigate()
  const isUserAuthorized = useAuthorization()

  const getGridItem = (to, icon, label, permissionCode) => {
    return (
      <Grid item xs>
        <Item style={{ height: '80px' }}>

          <CompassButton
            icon={icon}
            label={label}
            color={location.pathname.includes(to) ? 'primary' : 'secondary'}
            onClick={() => navigate(to)}
            disabled={!isUserAuthorized(permissionCode)}
            labelStyle={{
              fontSize: '1rem',
              color: !isUserAuthorized(permissionCode) ? 'grey' : 'primary'
            }}
          />
        </Item>
      </Grid>
    )
  }

  return (
    <>
      <Grid container spacing={8} sx={{ marginBottom: '1rem' }}>
        {getGridItem('/compass/presence', <CoPresent />, t_('Presence'), 'COMPASS PRESENCE')}
        {getGridItem('/compass/production', <VideoSettings />, t_('Production'), 'COMPASS PRODUCTION')}
        {getGridItem('/compass/supervision', <ManageHistory />, t_('Supervision'), 'COMPASS SUPERVISION')}
        {getGridItem('/compass/process', <PendingActions />, t_('process'), 'COMPASS PROCESS')}
        {getGridItem('/compass/inspect', <Troubleshoot />, t_('Inspection'), 'COMPASS INSPECTION')}
      </Grid>
      {
        element
      }
    </>
  )
}
