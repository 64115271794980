import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { TextField, IconButton, Stack } from "@mui/material";
import { ManageSearchOutlined } from '@mui/icons-material';
import AddElement from "../../generic/smartAddElement/AddElement";
import { SmartDialog } from '../../generic/utilities/SmartDialog';
import { useState } from "react";
import React, { useRef } from "react";
import RawMaterial from './RawMaterial';
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const RawMaterialReference = () => {
  let parallelepiped = "PARALLELEPIPED";
  let cylindrical = "CYLINDRICAL";
  const createFixtureEndPoint = "rawMaterials"
  const [showRawMaterials, setShowRawMaterials] = useState(false);
  const [referenceForInstances, setReferenceForInstances] = useState(null);
  const [hideColumn, setHideColumn] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const { t_ } = useSmartTranslation();

  const [quantityValue, setQuantityValue] = useState(0)
  const [editing, setEditing] = useState(false)
  const disableRowActions = useRef(false)

  const columns = [{
    name: t_("Code"),
    field: "code",
    readOnly: true,
    charLimit: 60,
    mandatory: true,
    unique: true
  },
  {
    name: t_("Name"),
    field: "name",
    charLimit: 80,
    mandatory: true,
    unique: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_("Description"),
    field: "description",
    charLimit: 200,
  }, {
    name: t_("Material type"),
    field: "materialType.name",
    type: "select",
    edit: {
      source: async () => {
        return await apiGet("materialTypes");
      },
      id: "id",
      field: "name",
    },
    mandatory: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_("Shape"),
    field: "shape.code",
    type: "select",
    edit: {
      source: async () => {
        return await apiGet("shapes");
      },
      id: "id",
      field: "name",
    },
    mandatory: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_("X Length (mm)"),
    field: "lengthX",
    readOnly: (rowData) => {
      return readOnlyCondition(rowData, parallelepiped) || rowData.systemDefault;
    },
    mandatory: (rowData) => {
      return mandatoryCondition(rowData, parallelepiped);
    },
    reset: (rowData) => {
      return readOnlyCondition(rowData, parallelepiped);
    },
    numeric: true,
  },
  {
    name: t_("Y Length (mm)"),
    field: "lengthY",
    readOnly: (rowData) => {
      return readOnlyCondition(rowData, parallelepiped) || rowData.systemDefault;
    },
    mandatory: (rowData) => {
      return mandatoryCondition(rowData, parallelepiped);
    },
    reset: (rowData) => {
      return readOnlyCondition(rowData, parallelepiped);
    },
    numeric: true,
  },
  {
    name: t_("Z Length (mm)"),
    field: "lengthZ",
    readOnly: (rowData) => {
      return readOnlyCondition(rowData, parallelepiped) || rowData.systemDefault;
    },
    mandatory: (rowData) => {
      return mandatoryCondition(rowData, parallelepiped);
    },
    reset: (rowData) => {
      return readOnlyCondition(rowData, parallelepiped);
    },
    numeric: true,
  },

  {
    name: t_("Diam (mm)"),
    field: "diameter",
    readOnly: (rowData) => {
      return readOnlyCondition(rowData, cylindrical) || rowData.systemDefault;
    },
    mandatory: (rowData) => {
      return mandatoryCondition(rowData, cylindrical);
    },
    reset: (rowData) => {
      return readOnlyCondition(rowData, cylindrical);
    },
    numeric: true,
  },

  {
    name: t_("Length (mm)"),
    field: "length",
    readOnly: (rowData) => {
      return readOnlyCondition(rowData, cylindrical) || rowData.systemDefault;
    },
    mandatory: (rowData) => {
      return mandatoryCondition(rowData, cylindrical);
    },
    reset: (rowData) => {
      return readOnlyCondition(rowData, cylindrical);
    },
    numeric: {
      default: 0,
    },
  },
  {
    name: t_("Fixture"),
    field: "defaultFixtureReference.name",
    type: "select",
    edit: {
      source: async () => {
        if (editingRow !== null)
          return await apiGet("fixtureReferencesByRawMaterialReference/" + editingRow.id);
      },
      id: "id",
      field: "name",
    },
    hide: hideColumn,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_("Quantity (avail./total)"),
    field: "quantity",
    numeric: true,
    defaultNewValue: 0,
    mandatory: true,
    render: (row) => {
      return (
        row !== undefined && row.id === undefined && row.quantity === 0 ?
          <TextField
            value={quantityValue}
            onChange={(event) => {
              setQuantityValue(event.target.value)
            }}
            helperText={" "}
            type="number"
            inputProps={{ min: 0 }}
          /> :
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center">
            {row.availableQuantity}/{row.quantity}
            < AddElement
              endpoint={createFixtureEndPoint}
              parameter={row.id}
              referenceName={row.name}
              row={row}
              disable={editing || disableRowActions.current}
            />
          </Stack>
      )
    },
  }
  ]

  const onEditIndexChanged = (editIndex, row) => {
    setHideColumn(false)
    if (editIndex === -1) {
      // Creating new element
      setHideColumn(true);
    }

    if (editIndex >= 0) {
      // Editing element. Row will have data
      setEditingRow(row);
      setHideColumn(false)
    }
  }

  const mandatoryCondition = (row, property) => {
    if (row.shape === undefined || row.shape === null) {
      return false
    }
    if (row.shape.code === property) return true;
  };

  const readOnlyCondition = (row, property) => {
    if (
      row.shape === undefined ||
      row.shape === null ||
      row.shape.code !== property ||
      row.shape.code === "" ||
      row.shape.code === undefined
    )
      return true;
    return false;
  };

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut("rawMaterialsReference", row)
      },
    },
    create: {
      fetchFunction: (row) => {
        if (Object.keys(row.materialType).length === 0) row.materialType = null;
        row.quantity = quantityValue
        setQuantityValue(0)
        return apiPost("rawMaterialsReference", row);
      },
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete("rawMaterialsReference/" + rows[0].id)
        }
        else {
          return apiDelete("rawMaterialsReferenceMulti", rows)
        }
      },
      visibilityButton: (row) => {
        return !row.systemDefault
      },
    },
    custom: [
      {
        name: "ShowFixtures",
        render: (row) => {
          return <IconButton
            disabled={editing ? true : false}
            onClick={(event) => {
              setReferenceForInstances(row);
              setShowRawMaterials(true);
            }}
          >
            <ManageSearchOutlined />
          </IconButton>
        },
      },
    ],
  }

  return (
    <div>
      {<SmartDialog
        renderComponent={
          <RawMaterial
            reference={referenceForInstances}
            endpoint={"rawMaterials"}
          />
        }
        closeCallback={() => setShowRawMaterials(false)}
        cancel={false}
        accept={false}
        close={true}
        setOpen={showRawMaterials}
      />}
      <SmartMateriaUITable
        columns={columns}
        title={t_("Raw Materials")}
        dataFetch="rawMaterialsReference"
        actions={actions}
        multipleSelection
        editingRow={(event) => setEditing(event)}
        setDisableRowActions={(event) => disableRowActions.current = event}
        importFile={[{ bean: { beanName: 'RawMaterialReference' }, name: t_("Raw Material Reference") }, { bean: { beanName: 'RawMaterial' }, name: t_("Raw Material") }]}
        onEditIndexChanged={onEditIndexChanged}
      />
    </div>
  )
}

export default RawMaterialReference
