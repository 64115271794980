import { CircularProgress, Grid, Stack } from '@mui/material'
import { FULL_HEIGHT } from '../../../generic/utilities/size_utils'

const CompassGrid = ({ items, renderItem, actions, fullScreenItem }) => {
  return items === null
    ? <Stack sx={{ width: '100%', height: '80vh' }} direction='row' justifyContent='center' alignItems='center'><CircularProgress size='15rem' /></Stack>
    : (fullScreenItem === null || fullScreenItem === undefined)
        ? (
          <>
            <Stack style={{ display: 'flex', flexWrap: 'wrap', marginTop: '25px' }}>
              <Grid container spacing={2}>
                {items?.map((item) => (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={item.id}>
                    <Stack sx={{ width: '100%', maxWidth: '100%', height: '100%', maxHeight: '100%' }}>
                      {renderItem(item, false)}
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Stack>
            <Grid container direction='row' justifyContent='space-around' sx={{ width: '100%', height: '90px', marginTop: '10px' }}>
              {actions
                ? actions.map(action => {
                  return (
                    <Grid key={action.index} item md={4}>
                      {action}
                    </Grid>
                  )
                })
                : null}
            </Grid>
          </>)
        : (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ height: FULL_HEIGHT.WITH_NAV_BAR_AND_SMART_NAVIGATOR_AND_FOOTER, minHeight: '322px', aspectRatio: 1.88 }}>
              {renderItem(fullScreenItem, true)}
            </div>
          </div>
          )
}

export default CompassGrid
