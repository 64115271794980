import { apiGet, apiPost, apiPut, apiDelete } from '../../generic/Api_Functions'
import SmartMateriaUITable from './../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const MaterialGroups = () => {
  const { t_ } = useSmartTranslation()
  const columns = [
    {
      name: t_('Code'),
      field: 'code',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Name'),
      field: 'name',
      charLimit: 60,
      mandatory: true,
      unique: true,
      readOnly: (row) => {
        return row.systemDefault
      }
    },
    {
      name: t_('Description'),
      field: 'description',
      charLimit: 200
    }
  ]

  const dataFetch = async (pagination) => {
    const materialGroups = await apiGet('materialGroups', pagination)
    return materialGroups
  }

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('materialGroup', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        return apiPost('materialGroup', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('materialGroup/' + rows[0].id, rows)
        } else {
          return apiDelete('materialGroupMulti', rows)
        }
      },
      visibilityButton: (row) => {
        return !row.systemDefault
      }
    }
  }

  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Material groups')}
      dataFetch={dataFetch}
      actions={actions}
      pageable
      multipleSelection
    />
  )
}

export default MaterialGroups
