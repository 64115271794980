const basicColors = [
  "46,134,193",
  "255,152,0",
  "216,126,224",
  "158,158,158",
  "233,30,99",
  "0,150,136",
  "121,85,72",
  "156,39,176",
  "99,199,103",
  "255,235,59",
]

const useColorsArray = () => {
  const myColors = basicColors.map(col => {
    return {
      backgroundColor: "rgba(" + col + ",0.2)",
      borderColor: "rgba(" + col + ",1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(" + col + ",0.4)",
      hoverBorderColor: "rgba(" + col + ",1)",
    }
  })

  function getColor(index) {
    let effectiveIndex = index
    while (effectiveIndex >= myColors.length) {
      effectiveIndex -= myColors.length
    }
    while (effectiveIndex < 0) {
      effectiveIndex += myColors.length
    }
    return myColors[effectiveIndex]
  }

  const getRandomColor = () => {
    return getColor(Math.floor(Math.random() * myColors.length))
  }

  return [getColor, getRandomColor]
}

export default useColorsArray
