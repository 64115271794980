import React, { useEffect, useState } from 'react'
import { Stack, useMediaQuery, useTheme, Fab } from '@mui/material'
import TopBar from './TopBar/TopBar'
import LeftMenuContainer from './LeftMenuContainer'
import Home from '../routes/Home'
import Footer from './Footer'
import { MenuOutlined } from '@mui/icons-material'

import MainStorage from '../routes/MainStorage'
import MainStoragePosition from '../routes/MainStoragePosition'

import MainPreset from '../routes/MainPreset'
import MainInspection from '../routes/MainInspection'

import MainDefault from '../routes/MainDefault'
import { Route, Routes } from 'react-router'
import SmartMaterialUITableTester from '../generic/SmartMaterialUITableTester'
import SmartMaterialUITableTest from '../generic/SmartMaterialUITableTest'
import DeviceSettings from '../configuration/devices/DeviceSettingsLayout'
import ShelveStoragePositions from '../configuration/storage/ShelveStoragePositions'
import MainDashboard from '../routes/MainDashboard'
import MainReference from '../routes/MainReference'
import MainFixture from '../configuration/fixture/MainFixture'
import MainRawMaterialReference from '../routes/MainRawMaterialReference'
import MainConfigurationSensorsActuators from '../routes/MainConfigurationSensorsActuators'
import SensorsActuatorsComponentsEdit from '../configuration/Sensor&Actuators/SensorsActuatorsComponentsEdit'
import MainDevices from '../routes/MainDevices'
import MainFixtureTypes from '../routes/MainFixtureTypes'
import MainWorkflows from '../routes/MainWorkflows'
import MainTechnology from '../routes/MainTechnology'
import Notifications from '../maintenance/alarms&notifications/notifications/Notifications'
import Alarms from '../maintenance/alarms&notifications/alarms/Alarms'
import PresenceDetection from '../maintenance/presenceDetection/PresenceDetectionVideo'
import MainConfigurationTools from '../routes/MainConfigurationTools'
import TestNewDevice from '../generic/TestNewDevice'
import MainToolManager from '../routes/MainToolManager'
import DisplayThirdPartiesSoftware from '../dashboard/DisplayThirdPartiesSoftware'
import MainPlanner from '../routes/MainPlanner'
import theme from './../../themeConfig.js'
import MainOrders from '../routes/MainOrders'
import ToolReferenceSettings from '../configuration/tools/ToolReferenceSettings'
import ToolInstanceSettings from '../configuration/tools/ToolInstanceSettings'
import MainCalendars from './../routes/MainCalendars'
import MainCalendarsEdit from './../routes/MainCalendarEdit'
import MainPicklist from './../routes/MainPicklist'
import MainWorkers from '../routes/MainWorkers'
import WorkerSettings from '../configuration/workers/WorkerSettings'
import MainMaterials from '../routes/MainMaterials.jsx'

import LoginPage from '../routes/LoginPage'
import { activityDetector } from '../generic/utilities/ActivityDetector'
import MainProfiles from '../routes/MainProfiles'
import MainUsers from '../routes/MainUsers'
import MainRule from '../routes/MainRule'
import MainIncidence from '../routes/MainIncidence'
import MainSettings from '../routes/MainSettings'

import CompassFake from '../Compass/CompassFake.jsx'
import MainCompass from '../routes/MainCompass'

import { useUser } from '../generic/hooks/useUser'
import MainDeviceStatus from '../routes/MainDeviceStatus.jsx'

import MainReason from '../routes/MainReason.jsx'
import MainQualityTypes from '../routes/MainQualityTypes.jsx'
import MainFabricationOrderStatus from '../routes/MainFabricationOrderStatus.jsx'
import MainFormula from './../routes/MainFormula'
import { FULL_HEIGHT } from '../generic/utilities/size_utils.js'
import Compass from '../routes/Compass.jsx'
import WorkflowsProcess from '../routes/TaskLinker'

const contentStyle = {
  flexGrow: 1,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  paddingTop: '1rem'
}
const footerStyle = {
  backgroundColor: 'black',
  position: 'relative',
  bottom: 0,
  right: 0,
  left: 0
}

const activityDetectorOptions = {
  timeToIdle: 4 * 60 * 60 * 1000 // Milliseconds
}
const myActivityDetector = activityDetector(activityDetectorOptions)
const MainContainer = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const [hideMenu, setHideMenu] = useState(isSmall)
  const [minimizeDrawer, setMinimizeDrawer] = useState(false)
  const [url, setUrl] = useState('')
  const [loginPageTimeoutAlert, setLoginPageTimeoutAlert] = useState(false)
  const { user, userLogout } = useUser()
  const [reloadTopBar, setReloadTopBar] = useState(false)
  myActivityDetector.on('idle', () => {
    userLogout()
    setLoginPageTimeoutAlert(true)
    setTimeout(() => setLoginPageTimeoutAlert(false), 100)
  })

  useEffect(() => {
    if (user) {
      myActivityDetector.init()
    } else {
      myActivityDetector.stop()
    }
  }, [user])

  useEffect(() => {
    setHideMenu(isSmall)
  }, [isSmall])

  const LeftMenuButton = () => {
    return (
      <Stack
        sx={{
          direction: 'row',
          justifyContent: 'end',
          position: 'absolute',
          top: 88,
          left: 10,
          zIndex: '1300'
        }}
      >
        <Fab
          size='small'
          disabled={false}
          onClick={() => setHideMenu(!hideMenu)}
          color='primary'
          sx={{
            color: 'white',
            backgroundColor: theme => theme.palette.background.color_212121,
            opacity: !hideMenu ? 0.6 : 0.8
          }}
        >
          <MenuOutlined />
        </Fab>
      </Stack>
    )
  }

  return (

    !user
      ? <LoginPage timeoutAlert={loginPageTimeoutAlert} />
      : (
        <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <TopBar setUrl={setUrl} changeHideMenu={() => setHideMenu(!hideMenu)} reloadTopBar={reloadTopBar} />
          <div style={{ display: 'flex', flexGrow: 1 }}>
            {!isSmall
              ? <LeftMenuButton />
              : null}
            <LeftMenuContainer
              id='left-menu-container'
              hideMenu={hideMenu}
              setHideMenu={setHideMenu}
              minimizeDrawer={minimizeDrawer}
              setMinimizeDrawer={setMinimizeDrawer}
              url={url}
            />
            <div style={{ overflow: 'auto', height: FULL_HEIGHT.WITH_NAV_BAR, flexGrow: 1 }}>
              <Stack direction='column' sx={{ height: '100%', minWidth: 'min-content' }}>
                <div style={{ ...contentStyle }}>
                  <Routes>
                    <Route path='/home' element={<Home />} />
                    <Route path='/' element={<Home />} />

                    <Route path='/profiles' element={<MainProfiles />} />
                    <Route path='/users' element={<MainUsers />} />
                    <Route path='/settings' element={<MainSettings setReloadTopBar={setReloadTopBar} reloadTopBar={reloadTopBar} />} />

                    <Route path='/monitoring' element={<Home />} />

                    <Route path='/planning' element={<MainPlanner />} />

                    <Route path='/process' element={<MainDefault />} />
                    <Route path='/process/jobLinker' element={<WorkflowsProcess />} />
                    <Route path='/process/storage' element={<MainStorage />} />
                    <Route path='/process/storagePosition/:storageId' element={<MainStoragePosition />} />
                    <Route path='/process/formula' element={<MainFormula />} />

                    <Route path='/orders' element={<MainOrders activePath={0} />} />
                    <Route path='/fabricationOrders' element={<MainOrders activePath={1} />} />

                    <Route path='/resources' element={<MainDefault />} />
                    <Route path='/resources/reference' element={<MainReference />} />
                    <Route path='/resources/toolManager' element={<MainToolManager activePath={0} />} />
                    <Route path='/resources/toolTables' element={<MainToolManager activePath={1} />} />
                    <Route path='/resources/toolManager/:toolId' element={<ToolReferenceSettings />} />
                    <Route path='/resources/toolManager/:toolReferenceId/toolInstance/:toolId' element={<ToolInstanceSettings />} />

                    <Route path='/resources/rawmaterialReference' element={<MainRawMaterialReference />} />
                    <Route path='/resources/fixtures' element={<MainFixture />} />
                    <Route path='/resources/fixtureReference' element={<MainFixture activePath={0} />} />
                    <Route path='/resources/fixtureAssemblyReference' element={<MainFixture activePath={1} />} />

                    <Route path='/dataAnalytics' element={<MainDefault />} />
                    <Route path='/dataAnalytics/dashboard' element={<MainDashboard />} />
                    <Route path='/dataAnalytics/prediction' element={<MainDefault />} />

                    <Route path='/reports' element={<MainDefault />} />
                    <Route path='/dataAnalytics/reports/inspection' element={<MainInspection />} />
                    <Route path='/dataAnalytics/reports/preset' element={<MainPreset />} />

                    <Route path='/configuration' element={<MainDefault />} />
                    <Route path='/configuration/assets' element={<MainDevices />} />
                    <Route path='/configuration/fixtureTypes' element={<MainFixtureTypes />} />
                    <Route path='/configuration/workflows' element={<MainWorkflows />} />
                    <Route path='/configuration/sensorsActuators' element={<MainConfigurationSensorsActuators activePath={0} />} />
                    <Route path='/configuration/sensorsActuatorsComponents' element={<MainConfigurationSensorsActuators activePath={1} />} />
                    <Route
                      path='/configuration/sensorsActuatorsComponents/:sensorActuatorComponentId'
                      element={<SensorsActuatorsComponentsEdit />}
                    />
                    <Route path='/configuration/rule' element={<MainRule />} />
                    <Route path='/configuration/customer' element={<MainDefault />} />
                    <Route path='/configuration/technology' element={<MainTechnology activePath={0} />} />
                    <Route path='/configuration/technologyGroups' element={<MainTechnology activePath={1} />} />
                    <Route path='/configuration/toolGroups' element={<MainConfigurationTools activePath={0} />} />
                    <Route path='/configuration/toolTypes' element={<MainConfigurationTools activePath={1} />} />
                    <Route path='/configuration/toolParameters' element={<MainConfigurationTools activePath={2} />} />
                    <Route path='/configuration/assets/:deviceId' element={<DeviceSettings />} />
                    <Route path='/configuration/assets/:deviceId/shelve/:shelveId' element={<ShelveStoragePositions />} />
                    <Route path='/configuration/calendars' element={<MainCalendars />} />
                    <Route path='/configuration/calendar/:calendarId' element={<MainCalendarsEdit isReference />} />
                    <Route path='/configuration/calendarInstance/:calendarId' element={<MainCalendarsEdit isReference={false} />} />
                    <Route path='/configuration/picklist' element={<MainPicklist activePath={0} />} />
                    <Route path='/configuration/picklist/qualityTypes' element={<MainQualityTypes activePath={0} />} />
                    <Route path='/configuration/workers' element={<MainWorkers />} />
                    <Route path='/configuration/workers/:workerId' element={<WorkerSettings />} />
                    <Route path='/configuration/picklist/incidence' element={<MainIncidence activePath={0} />} />
                    <Route path='/configuration/incidenceTypes' element={<MainIncidence activePath={1} />} />
                    <Route path='/configuration/picklist/deviceStatus' element={<MainDeviceStatus activePath={0} />} />
                    <Route path='/configuration/picklist/deviceStatusTypes' element={<MainDeviceStatus activePath={1} />} />
                    <Route path='/configuration/materials' element={<MainMaterials />} />
                    <Route path='/configuration/picklist/reasons' element={<MainReason activePath={0} />} />
                    <Route path='/configuration/picklist/reasonTypes' element={<MainReason activePath={1} />} />
                    <Route path='/configuration/picklist/fabricationOrderStatus' element={<MainFabricationOrderStatus activePath={0} />} />
                    <Route path='/configuration/picklist/fabricationOrderStatusTypes' element={<MainFabricationOrderStatus activePath={1} />} />

                    <Route path='/maintenance' element={<MainDefault />} />
                    <Route path='/maintenance/alarms' element={<Alarms unChecked={false} />} />
                    <Route path='/maintenance/notifications' element={<Notifications unChecked={false} />} />
                    <Route path='/maintenance/presenceDetection' element={<PresenceDetection unChecked={false} />} />

                    <Route path='/compass' element={<MainCompass />} />

                    <Route path='/tabletester' element={<SmartMaterialUITableTester />} />
                    <Route path='/machinecenter/kpis' element={<DisplayThirdPartiesSoftware url='http://192.168.250.190/' />} />
                    <Route path='/testNewDevice' element={<TestNewDevice />} />
                    <Route path='/tabletest' element={<SmartMaterialUITableTest />} />
                    <Route path='/compass/presence' element={<Compass option='PRESENCE' />} />
                    <Route path='/compass/presence/history' element={<Compass option='PRESENCE_HISTORY' />} />
                    <Route path='/compass/presence/authentication' element={<Compass option='AUTHENTICATION' />} />
                    <Route path='/compass/presence/viewFile' element={<Compass option='VIEW_FILE' />} />
                    <Route path='/compass/production' element={<Compass option='PRODUCTION' />} />
                    <Route path='/compass/production/edit/:id' element={<Compass option='FABRICATION_ORDER_LIST' />} />
                    <Route path='/compass/production/viewFile' element={<Compass option='VIEW_FILE' />} />
                    <Route path='/compass/production/quantity/:id' element={<Compass option='QUANTITY' />} />
                    <Route path='/compass/production/edit/changeState' element={<Compass option='PRODUCTION_STATE' />} />
                    <Route path='/compass/production/quantity/report/:id' element={<Compass option='REPORT_QUANTITY' />} />
                    <Route path='/compass/supervision' element={<Compass option='SUPERVISION' />} />
                    <Route path='/compass/supervision/statusList' element={<Compass option='STATE_LIST' />} />
                    <Route path='/compass/supervision/addStatus' element={<Compass option='SUPERVISION_STATE' />} />
                    <Route path='/compass/supervision/incidentList' element={<Compass option='INCIDENT_LIST' />} />
                    <Route path='/compass/supervision/addIncident' element={<Compass option='INCIDENT' />} />
                    <Route path='/compass/process' element={<Compass option='PROCESS' />} />
                    <Route path='/compass/process/action' element={<Compass option='ACTION' />} />
                    <Route path='/compass/process/command' element={<Compass option='COMMAND' />} />
                    <Route path='/compass/process/command/byLocation' element={<Compass option='COMMAND_LOCATION' />} />
                    <Route path='/compass/process/command/byResource' element={<Compass option='COMMAND_RESOURCE' />} />
                    <Route path='/compass/inspect' element={<Compass option='INSPECT' />} />
                    <Route path='/compass/inspect/viewFile' element={<Compass option='VIEW_FILE' />} />
                    <Route path='/compass/inspect/procedure' element={<Compass option='CONTROL_PROCEDURE' />} />
                    <Route path='/compass/inspect/history' element={<Compass option='INSPECT_HISTORY' />} />
                    <Route path='/compass/inspect/report' element={<Compass option='REPORT_FEATURES' />} />
                    <Route path='/compass' element={<CompassFake />} />
                  </Routes>
                </div>
                <div style={footerStyle}>
                  <Footer />
                </div>
              </Stack>
            </div>
          </div>
        </div>
        )
  )
}

export default MainContainer
