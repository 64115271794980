import { ThemeProvider } from '@emotion/react'
import { Slider, createTheme } from '@mui/material'

const getFeatureDesviationData = (value, nominal, upperTolerance, lowerTolerance) => {
  const numValue = parseFloat(value)
  const nominalValue = parseFloat(nominal)
  const maxValue = parseFloat(nominalValue) + parseFloat(upperTolerance)
  const minValue = parseFloat(nominalValue) + parseFloat(lowerTolerance)

  if (isNaN(numValue) || isNaN(maxValue) || isNaN(minValue) || isNaN(nominalValue)) {
    return { desviation: NaN, toleranceRange: NaN }
  }

  const desviation = parseFloat((numValue - nominalValue).toFixed(3))

  if (numValue < minValue) {
    return { desviation, toleranceRange: -1 }
  }
  if (maxValue < numValue) {
    return { desviation, toleranceRange: 1 }
  }
  return { desviation, toleranceRange: 0 }
}

const FeatureIndicatorSlider = ({ value, nominalValue, upperTolerance, lowerTolerance, sx = {} }) => {
  const getSliderData = (desviationData) => {
    if (isNaN(desviationData.toleranceRange)) {
      return { position: [0, 100], color: 'error' }
    }
    return { position: desviationData.toleranceRange === 0 ? [33, 66] : desviationData.toleranceRange < 0 ? [0, 33] : [66, 100], color: desviationData.toleranceRange === 0 ? 'success' : 'error' }
  }

  const sliderData = getSliderData(getFeatureDesviationData(value, nominalValue, upperTolerance, lowerTolerance))

  const theme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          thumb: {
            display: 'none'
          }
        }
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <Slider
        value={sliderData.position}
        color={sliderData.color}
        sx={{ width: '8em', ...sx }}
      />
    </ThemeProvider>
  )
}

export { FeatureIndicatorSlider, getFeatureDesviationData }
