import React from 'react'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useLocation, useNavigate } from 'react-router-dom'
import ButtonBack from '../../generic/buttons/floatingButtons/ButtonBack'
import { Typography, Stack } from '@mui/material'
import { apiPost } from '../../generic/Api_Functions'
import { FeatureIndicatorSlider } from '../../generic/FeatureIndicatorSlider'
import { DefaultAccordion } from '../../generic/DefaultAccordion'
import Ok from '../../../images/OK.png'
import NoOk from '../../../images/NO_OK.png'
export default function InspectHistory () {
  const location = useLocation()
  const { device } = location.state
  const navigate = useNavigate()

  const dataFetch = async (pagination) => {
    const data = await apiPost('compass/inspectionReportFeaturesByDevice/' + device.id, pagination)
    return data
  }
  const qualitativeDataFetch = async (pagination) => {
    const data = await apiPost('compass/qualitative/inspectionReportFeaturesByDevice/' + device.id, pagination)
    return data
  }
  const imgStyle = {
    verticalAlign: 'middle',
    height: '1.8rem',
    width: '1.8rem'
  }

  const { t_ } = useSmartTranslation()
  const columns = [
    {
      name: t_('Worker'),
      field: 'worker',
      readOnly: true,
      render: (row) => {
        return row.worker.name + ' ' + row.worker.surname
      }
    },
    {
      name: t_('FO'),
      field: 'of',
      readOnly: true
    },
    {
      name: t_('Reference'),
      field: 'reference',
      readOnly: true
    },
    {
      name: t_('Feature'),
      field: 'feature.name',
      readOnly: true
    },
    {
      name: t_('Unit'),
      field: 'unit.code',
      readOnly: true
    },
    {
      name: t_('Nominal'),
      field: 'feature.value',
      readOnly: true
    },

    {
      name: t_('Upper tolerance'),
      field: 'feature.upperTolerance',
      readOnly: true,
      render: (row) => {
        const formattedUpperTolerance = isNaN(row.feature.upperTolerance)
          ? ''
          : row.feature.upperTolerance
        const adjustedUpperTolerance =
      row.feature.upperTolerance >= 0
        ? `+${formattedUpperTolerance}`
        : `-${formattedUpperTolerance}`
        return adjustedUpperTolerance
      }
    },
    {
      name: t_('Lower tolerance'),
      field: 'feature.lowerTolerance',
      readOnly: true

    },
    {
      name: t_('Result'),
      field: 'value'
    },
    {
      name: t_('Deviation'),
      field: 'deviation',
      readOnly: true,
      render: (row) => {
        const formattedDeviation = isNaN(row.deviation) ? '' : parseFloat(row.deviation).toFixed(3)
        return formattedDeviation
      }
    },
    {
      name: t_('Measurement date'),
      field: 'inspectionReport.reportDate',
      type: 'dateTimePicker'
    },
    {
      name: t_('Comment'),
      field: 'comment',
      readOnly: true
    },
    {
      name: '',
      field: 'inTolerance',
      search: false,
      render: (row) => {
        const adjustedUpperTolerance = row.feature.upperTolerance >= 0 ? `+${row.feature.upperTolerance}` : `-${row.feature.upperTolerance}`
        return (
          <FeatureIndicatorSlider
            value={row.value}
            nominalValue={row.feature.value}
            upperTolerance={adjustedUpperTolerance}
            lowerTolerance={row.feature.lowerTolerance}
          />
        )
      }
    }
  ]
  const qualitativeColumns = [
    {
      name: t_('Worker'),
      field: 'worker',
      readOnly: true,
      render: (row) => {
        return row.worker.name + ' ' + row.worker.surname
      }
    },
    {
      name: t_('FO'),
      field: 'fabricationOrder',
      readOnly: true
    },
    {
      name: t_('Reference'),
      field: 'reference',
      readOnly: true
    },
    {
      name: t_('Feature'),
      field: 'name',
      readOnly: true
    },
    {
      name: t_('Attribute'),
      field: 'attribute',
      readOnly: true
    },
    {
      name: t_('Measurement date'),
      field: 'inspectionReport.reportDate',
      type: 'dateTimePicker'
    },
    {
      name: t_('Comment'),
      field: 'comment',
      readOnly: true
    },
    {
      name: t_('Result'),
      field: 'inspectionReport.inspectionResult',
      render: (rowData) => (
        <img
          style={imgStyle}
          src={rowData.inspectionReport.inspectionResult === 'OK' ? Ok : NoOk}
          alt=''
        />
      )
    }

  ]
  const sortOptions = {
    default: {
      field: 'inspectionReport.reportDate',
      direction: -1
    }
  }

  const actions = { create: {} }

  return (
    <Stack>
      <DefaultAccordion
        headerRender={t_('Quantitatives')}
        defaultExpanded={false}
      >
        <SmartMateriaUITable
          title={<Typography color='secondary'>{t_('History')} - {device.name}</Typography>}
          columns={columns}
          actions={actions}
          dataFetch={dataFetch}
          sortOptions={sortOptions}
          onAddElementClick={() => { navigate('/compass/inspect/procedure', { state: { device, urlBack: '/compass/inspect' } }) }}
          pageable
        />
      </DefaultAccordion>
      <DefaultAccordion
        headerRender={t_('Qualitatives')}
        defaultExpanded={false}
      >
        <SmartMateriaUITable
          title={<Typography color='secondary'>{t_('History')} - {device.name}</Typography>}
          columns={qualitativeColumns}
          actions={actions}
          dataFetch={qualitativeDataFetch}
          sortOptions={sortOptions}
          onAddElementClick={() => { navigate('/compass/inspect/procedure', { state: { device, urlBack: '/compass/inspect' } }) }}
          pageable
        />
      </DefaultAccordion>
      <div style={{ width: '100%', float: 'right' }}>
        <ButtonBack buttonAction={() => navigate('/compass/inspect')} />
      </div>
    </Stack>
  )
}
