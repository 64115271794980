import React from 'react'
import SmartNavigator from '../generic/SmartNavigator';
import CompassFakeImg from '../../images/CompassFakeImg.png'



const CompassFake = () => {
  const componentsInfo = [
    {
      title: 'COMPASS',
      component: <div style={{ textAlign: "center" }}>
        <img style={{ width: "100%" }} src={CompassFakeImg} alt="Compass" />
      </div>,
    }
  ];

  return (
    <SmartNavigator componentsInfo={componentsInfo} />
  );
}

export default CompassFake