import { useEffect, useState } from "react"
import { apiGet } from "../../Api_Functions"

const useUncheckedActions = () => {
  const [uncheckedActions, setUncheckedActions] = useState(0)

  useEffect(() => {
    let mounted = true;
    const interval = setInterval(async () => {
      let response = await apiGet("getUncheckedActionsCount");
      if (mounted) {
        setUncheckedActions(response)
      }
    }, 1000);
    return () => {
      mounted = false;
      clearInterval(interval);
    }
  }, [setUncheckedActions]);

  return uncheckedActions
}

export default useUncheckedActions