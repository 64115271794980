import React, { useEffect, useState } from 'react'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { apiDelete, apiGet, apiPut } from '../../generic/Api_Functions'
import { CircularProgress, IconButton, Slider, Stack, TextField, ThemeProvider, createTheme, Typography, MenuItem } from '@mui/material'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import PartFeatureHistory from './PartFeatureHistory'
import AddElementFeature from '../../generic/smartAddElement/AddElementFeature'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import DefaultAccordion from './../../generic/DefaultAccordion/DefaultAccordion'
import { useSmartInterval } from '../../generic/hooks/useSmartInterval'
import Ok from '../../../images/OK.png'
import NoOk from '../../../images/NO_OK.png'
import AddElementFeatureQualitative from '../../generic/smartAddElement/AddElementFeatureQualitative'
import PartFeatureHistoryQualitative from './PartFeatureHistoryQualitative'
import { useTheme } from '@emotion/react'

const PartFeature = (props) => {
  const { part } = props
  const { t_ } = useSmartTranslation()
  const [selectedRow, setSelectedRow] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [showPartFeaturesHistory, setShowPartFeaturesHistory] = useState(false)
  const [showPartFeaturesHistoryQua, setShowPartFeaturesHistoryQua] = useState(false)
  const [quantityValue, setQuantityValue] = useState(0)
  const createInspectionReportFeatureEndpoint = 'inspectionReportFeatures'
  const [simpleFeatures, setSimpleFeatures] = useState(null)
  const [groupedFeatures, setGroupedFeatures] = useState([])
  const [indice, setIndice] = useState(null)
  const [newValue, setNewValue] = useState(null)
  const [creatingNewRow, setCreatingNewRow] = useState(false)
  const [qualityValues, setQualityValues] = useState(null)
  const theme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          thumb: {
            display: 'none'
          }
        }
      }
    }
  })

  const qualitativeDataFetch = async (pagination) => {
    const data = await apiGet('feature/part/qualitative/' + part.id, pagination)
    return data
  }

  useEffect(() => {
    const qualityValuesDataFetch = async () => {
      const data = await apiGet('qualityValues')
      setQualityValues(data)
      return data
    }
    qualityValuesDataFetch()
  }, [])

  const columns = [
    {
      name: t_('Individual'),
      field: 'individual',
      hide: true,
      mandatory: true,
      defaultNewValue: true
    },
    {
      name: t_('Code'),
      field: 'code',
      readOnly: true,
      hide: !creatingNewRow,
      mandatory: true,
      unique: true
    },
    {
      name: t_('Name'),
      field: 'name',
      readOnly: (row) => !row.individual,
      mandatory: true,
      unique: true
    },
    {
      name: t_('Description'),
      field: 'description',
      readOnly: (row) => !row.individual,
      hide: !creatingNewRow
    },
    {
      name: t_('Nominal'),
      field: 'value',
      readOnly: (row) => !row.individual,
      mandatory: true,
      numeric: true
    },
    {
      name: t_('Unit'),
      field: 'unit.code',
      mandatory: true,
      type: 'select',
      readOnly: (row) => !row.individual,
      edit: {
        source: async () => {
          return await apiGet('units')
        },
        id: 'id',
        field: 'code'
      }
    },
    {
      name: t_('Upper tolerance'),
      field: 'upperTolerance',
      readOnly: (row) => !row.individual,
      mandatory: true
    },
    {
      name: t_('Lower tolerance'),
      field: 'lowerTolerance',
      readOnly: (row) => !row.individual,
      mandatory: true
    },

    {
      name: t_('Result'),
      field: 'inspectionReportFeatureRecent.value',
      mandatory: true,
      numeric: true
    },
    {
      name: t_('Deviation'),
      field: 'inspectionReportFeatureRecent.deviation',
      readOnly: true,
      hide: creatingNewRow
    },
    {
      name: t_('Measurement date'),
      field: 'reportDate',
      type: 'dateTimePicker',
      defaultNewValue: new Date().toISOString(),
      mandatory: true

    },
    {
      name: '',
      field: 'inspectionReportFeatureRecent.inTolerance',
      search: false,
      hide: creatingNewRow,
      render: (row) => {
        if (row.inspectionReportFeatureRecent != null) {
          let color = 'success'
          let position = [33, 66]
          const min = Number(row.value) + Number(row.lowerTolerance)
          if (!row.inspectionReportFeatureRecent.inTolerance) {
            position = min > Number(row.inspectionReportFeatureRecent.value) ? [0, 33] : [66, 100]
            color = 'error'
          }
          return (
            <ThemeProvider theme={theme}>
              <Slider
                value={position}
                aria-labelledby='read-only-slider'
                color={color}
                sx={{ width: '8em' }}
              />
            </ThemeProvider>
          )
        }
        return null
      }

    },
    {
      name: t_('Measures'),
      field: 'quantity',
      numeric: true,
      mandatory: false,
      hide: creatingNewRow,
      render: (row, extraFunctions) => {
        return (
          row !== undefined && row.id === undefined && row.quantity === 0
            ? <TextField
                value={quantityValue}
                onChange={(event) => {
                  setQuantityValue(event.target.value)
                }}
                helperText={' '}
                type='number'
                inputProps={{ min: 0 }}
              />
            : (
              <div id={'quantity' + row.id} style={{ alignItems: 'center', display: 'flex' }}>
                {row.quantity}
                <AddElementFeature
                  featureUnit={row.unit}
                  partId={part.id}
                  parameter={row.id}
                  endpoint={createInspectionReportFeatureEndpoint}
                  referenceName={row.name}
                  onAlert={extraFunctions.handleShowAlert}
                />
              </div>))
      }
    }
  ]
  const imgStyle = {
    verticalAlign: 'middle',
    height: '1.8rem',
    width: '1.8rem'
  }
  const qualitativeColumns = [

    {
      name: t_('Feature'),
      field: 'code',
      readOnly: true
    },
    {
      name: t_('Attribute'),
      field: 'qualitativeAttribute',
      readOnly: true
    },
    {
      name: t_('Result'),
      field: 'value',
      render: (rowData) => {
        if (indice === rowData['smart-table-index']) {
          return (
            <TextField
              style={{ paddingBottom: '10px' }}
              select
              value={newValue !== null ? newValue : rowData.value}
              onChange={(event) => {
                setNewValue(event.target.value)
              }}
              fullWidth
              size='small'
            >
              {qualityValues.map((option) => (
                <MenuItem key={option.id} value={option.code}>
                  {option.code}
                </MenuItem>
              ))}
            </TextField>
          )
        } else {
          return rowData.value
            ? (
              <div>
                {rowData.value}
                <img
                  style={imgStyle}
                  src={rowData.value === 'OK' ? Ok : NoOk}
                  alt=''
                />
              </div>
              )
            : null
        }
      }
    },
    {
      name: t_('Measurement date'),
      field: 'reportDate',
      type: 'dateTimePicker',
      defaultNewValue: new Date().toISOString(),
      mandatory: true

    },

    {
      name: t_('Measures'),
      field: 'quantity',
      numeric: true,
      mandatory: false,
      hide: creatingNewRow,
      render: (row, extraFunctions) => {
        return (
          row !== undefined && row.id === undefined && row.quantity === 0
            ? <TextField
                value={quantityValue}
                onChange={(event) => {
                  setQuantityValue(event.target.value)
                }}
                helperText={' '}
                type='number'
                inputProps={{ min: 0 }}
              />
            : (
              <div id={'quantity' + row.id} style={{ alignItems: 'center', display: 'flex' }}>

                {row.quantity}
                <AddElementFeatureQualitative
                  partId={part.id}
                  parameter={row.featureId}
                  endpoint='inspectionReportFeaturesQualitative'
                  referenceName={row.name}
                  onAlert={extraFunctions.handleShowAlert}
                />
              </div>))
      }
    }

  ]
  useSmartInterval(
    async () => {
      return await apiGet('feature/part/noPagination/' + part.id)
    },
    1000,
    (result) => {
      handleGetFeatures(result)
    })

  const handleGetFeatures = (result) => {
    const simpleElements = []
    const groupedElements = []

    result.forEach(element => {
      element.upperTolerance = addPositiveSymbol(element.upperTolerance)
      element.lowerTolerance = addPositiveSymbol(element.lowerTolerance)
      if (element.inspectionReportFeatureRecent != null) {
        element.inspectionReportFeatureRecent.deviation = addPositiveSymbol(element.inspectionReportFeatureRecent.deviation)
      }
      if (element.featureGroup === null) {
        simpleElements.push(element)
      } else {
        // Find if its on the array allready
        let group = groupedElements.find((group) => group.id === element.featureGroup.id)
        if (!group) {
          group = { ...element.featureGroup, items: [] }
          groupedElements.push(group)
        }
        group.items.push(element)
      }
    })
    setSimpleFeatures(simpleElements)
    groupedElements.forEach(group => {
      let countDeviations = 0
      let countLeftDeviations = 0
      let countRightDeviations = 0
      group.items.forEach(feature => {
        if (feature.inspectionReportFeatureRecent != null) {
          const min = Number(feature.value) + Number(feature.lowerTolerance)
          if (!feature.inspectionReportFeatureRecent.inTolerance) {
            min > Number(feature.inspectionReportFeatureRecent.value) ? countLeftDeviations++ : countRightDeviations++
          }
          countDeviations++
        }
      })
      if (countLeftDeviations > 0 || countRightDeviations > 0) {
        group.deviationPosition = countLeftDeviations > countRightDeviations ? [0, 33] : [66, 100]
        group.color = 'error'
      }
      if (countDeviations === 0) {
        group.deviationPosition = [33, 66]
        group.color = 'primary'
      }
      if (countDeviations > 0 && (countLeftDeviations === 0 && countRightDeviations === 0)) {
        group.deviationPosition = [33, 66]
        group.color = 'success'
      }
    })
    setGroupedFeatures(groupedElements)
  }

  const addPositiveSymbol = (value) => {
    return value > 0 ? '+' + value : value
  }
  const sortOptions = {
    default: {
      field: 'name',
      direction: 1
    }
  }

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('feature/part/', row)
      },
      condition: (row) => {
        if (row.quantity === 0) {
          return false
        }
        return true
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('inspectionReportFeature/' + rows[0].id).then()
        } else {
          return apiDelete('inspectionReportFeatureMulti', rows).then()
        }
      }
    },
    custom: [
      {
        name: 'View feature history',
        render: (row) => {
          return (
            <IconButton
              onClick={(event) => {
                setSelectedRow(row)
                setShowPartFeaturesHistory(true)
              }}
            >
              <WorkHistoryIcon />
            </IconButton>
          )
        }
      }
    ]
  }
  const actionsQualitative = {
    edit: {
      fetchFunction: (row) => {
        row.value = newValue
        return apiPut('feature/qualitative/part', row)
      },
      condition: (row) => {
        if (row.quantity === 0) {
          return false
        }
        return true
      }
    },
    delete: {

      fetchFunction: (row) => {
        return apiDelete('inspectionReportFeature/' + row[0].id)
      },
      disabledButton: (row) => row.quantity === 0
    },
    custom: [
      {
        name: 'View feature history',
        render: (row) => {
          return (
            <IconButton
              onClick={(event) => {
                setSelectedRow(row)
                setShowPartFeaturesHistoryQua(true)
              }}
            >
              <WorkHistoryIcon />
            </IconButton>
          )
        }
      }
    ]
  }
  const validator = (row) => {
    const upperTolerance = parseFloat(row.upperTolerance)
    const lowerTolerance = parseFloat(row.lowerTolerance)

    if (upperTolerance <= lowerTolerance) {
      return { errorFields: ['upperTolerance'], alertMessage: 'Upper tolerance must be higher than lower tolerance' }
    } else {
      return false
    }
  }

  const headerGroupTitle = (group) => {
    return (
      <>
        <Typography>{group.name}</Typography>
        <Stack direction='row' alignItems='center' gap={2}>
          <ThemeProvider theme={theme}>
            <Slider
              value={group.deviationPosition}
              aria-labelledby='read-only-slider'
              color={group.color}
              sx={{ width: '15em' }}
            />
          </ThemeProvider>
          <IconButton
            onClick={(event) => {
              setSelectedGroup(group)
              setShowPartFeaturesHistory(true)
              // Prevent closing/opening accordion
              event.stopPropagation()
            }}
          >
            <WorkHistoryIcon />
          </IconButton>
        </Stack>
      </>
    )
  }

  // Do not show individual history button on grouped features
  const actionsGroup = { ...actions }
  delete actionsGroup.custom
  delete actionsGroup.create

  const muiTheme = useTheme()

  return (
    <>
      <SmartDialog
        renderComponent={<PartFeatureHistory feature={selectedRow} part={part} group={selectedGroup} createInspectionReportFeatureEndpoint={createInspectionReportFeatureEndpoint} />}
        setOpen={showPartFeaturesHistory}
        cancel={false}
        accept={false}
        close
        closeCallback={() => {
          setShowPartFeaturesHistory(false)
          setSelectedGroup(null)
        }}
      />
      <SmartDialog
        renderComponent={<PartFeatureHistoryQualitative feature={selectedRow} part={part} group={selectedGroup} createInspectionReportFeatureEndpoint={createInspectionReportFeatureEndpoint} />}
        setOpen={showPartFeaturesHistoryQua}
        cancel={false}
        accept={false}
        close
        closeCallback={() => {
          setShowPartFeaturesHistoryQua(false)
          setSelectedGroup(null)
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <h1>{t_('Features-') + part.code}</h1>
      </div>

      <div style={{ width: '1500px', height: '100%', minHeight: '350px', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: muiTheme.palette.background.row2 }}>
        <div style={{ marginBottom: '20px' }}>
          <title> {t_('Feature')}</title>
          <DefaultAccordion
            headerRender={t_('Quantitatives')}
            defaultExpanded
          >

            {simpleFeatures !== null
              ? <SmartMateriaUITable
                  title='Quantitative Features'
                  columns={columns}
                  dataFetch={simpleFeatures}
                  pageable={false}
                  sortOptions={sortOptions}
                  actions={actions}
                  validator={validator}
                  onEditIndexChanged={(index) => setCreatingNewRow(index === -1)}
                />
              : <CircularProgress />}

          </DefaultAccordion>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <DefaultAccordion
            headerRender={t_('Qualitatives')}
            defaultExpanded={false}
          >
            <SmartMateriaUITable
              title='Qualitative Features'
              columns={qualitativeColumns}
              dataFetch={qualitativeDataFetch}
              pageable={false}
              actions={actionsQualitative}
              onEditIndexChanged={(row) => setIndice(row)}
            />

          </DefaultAccordion>
        </div>
        {groupedFeatures.length > 0
          ? groupedFeatures.map((group) => {
            return (
              <DefaultAccordion
                headerRender={headerGroupTitle(group)}
                key={'feature-group-' + group.name}
                defaultExpanded={false}
              >
                <SmartMateriaUITable
                  columns={columns}
                  dataFetch={group.items}
                  pageable={false}
                  validator={validator}
                  actions={actionsGroup}
                />
              </DefaultAccordion>
            )
          })
          : null}

      </div>

    </>
  )
}

export default PartFeature
