import { Button } from '@mui/material'
import React, { memo } from 'react'
import { Handle, Position } from 'reactflow'
import { useSmartTranslation } from '../../../generic/hooks/useSmartTranslation'

const EndNode = ({ data }) => {
  const { t_ } = useSmartTranslation()
  const handleSize = '0.6rem'

  return (
    <div className='conditionalNode' style={{ textAlign: 'center' }}>
      <Handle
        id='end'
        type='target'
        className='left'
        position={Position.Left}
        style={{ zIndex: 10000, width: handleSize, height: handleSize }}
        isConnectable={data.isConnectable}
      />
      <Button
        color='info'
        variant='contained'
      >{t_('End')}
      </Button>
    </div>
  )
}

export default memo(EndNode)
