import React from 'react'
import { apiDelete, apiGet, apiPost, apiPut } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const ToolTypeParameter = () => {
  const { t_ } = useSmartTranslation()

  const columns = [{
    name: t_('Code'),
    field: 'code',
    charLimit: 60,
    mandatory: true,
    unique: true,
    readOnly: true
  },
  {
    name: t_('Name'),
    field: 'name',
    charLimit: 60,
    mandatory: true,
    unique: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_('Description'),
    field: 'description',
    charLimit: 80

  },
  {
    name: t_('Type'),
    field: 'toolParameterType.code',
    type: 'select',
    edit: {
      source: async () => {
        return await apiGet('toolParameterTypes')
      },
      id: 'id',
      field: 'code'
    },
    mandatory: true,
    readOnly: (row) => {
      if (row.systemDefault === undefined || row.systemDefault === null) {
        return false
      }
      return row.systemDefault
    }
  },
  {
    name: t_('Data Type'),
    field: 'dataType.name',
    type: 'select',
    edit: {
      source: async () => {
        return await apiGet('dataTypes')
      },
      id: 'id',
      field: 'name'
    },
    mandatory: true,
    readOnly: (row) => {
      return row.systemDefault
    }
  },
  {
    name: t_('Unit type'),
    field: 'unitType.code',
    type: 'select',
    edit: {
      source: async () => {
        return await apiGet('unitTypes')
      },
      id: 'id',
      field: 'code'
    },
    mandatory: false,
    readOnly: (row) => {
      return row.systemDefault
    }
  }
  ]

  const actions = {
    edit: {
      fetchFunction: async (row) => {
        const edit = await apiPut('toolParameters', row)
        return edit
      }
    },
    create: {
      fetchFunction: async (row) => {
        const newParameter = Object.assign(row)
        if (newParameter.unitType && !newParameter.unitType.id) {
          delete newParameter.unitType
        }
        const create = await apiPost('toolParameters', newParameter)
        return create
      }
    },
    delete: {
      visibilityButton: toolParameter => !toolParameter.systemDefault,
      fetchFunction: async (rowsPagination) => {
        let paginationRequest = rowsPagination
        if (rowsPagination.length === 1) {
          if (rowsPagination[0].systemDefault) {
            return new Promise((resolve, reject) => reject({
              message: 'This tool parameter can`t be deleted',
              name: 'warning'
            }))
          }
          paginationRequest = {
            columns: columns.map(col => col.field),
            genericSearch: '',
            columnFilters: [],
            columnFilterValues: [],
            page: 0,
            size: -1,
            sortKey: [],
            sortMode: [],
            rows: [rowsPagination[0].id.toString()],
            primaryKeys: ['id'],
            allSelected: false
          }
        }

        const del = await apiDelete('toolParametersPage', paginationRequest)
        return del
      }
    }
  }

  return (
    <>
      <SmartMateriaUITable
        columns={columns}
        title={t_('Tool Parameters')}
        dataFetch='toolParametersPage'
        multipleSelection
        actions={actions}
        pageable
      />
    </>
  )
}

export default ToolTypeParameter
