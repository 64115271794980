import * as React from 'react';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';

import { useSmartTranslation } from "../generic/hooks/useSmartTranslation";


export default function PlanDialogContent(props) {

  const { setPlanParameters, planParameters } = props;
  const { t_ } = useSmartTranslation();
  return (
    <Stack gap={3}>
      <TextField
        autoFocus
        fullWidth
        margin="dense"
        id="name"
        label={t_("Execution time")}
        variant="standard"
        value={planParameters.time_exec}
        error={planParameters.showErrors && (planParameters.time_exec === '' || parseInt(planParameters.time_exec) < 1)}
        helperText={planParameters.showErrors && (planParameters.showErrors && (planParameters.time_exec === '' || parseInt(planParameters.time_exec) < 1)) ? "Value must not be lower than 1 " : null}
        onChange={e => {
          setPlanParameters(prevState => ({
            ...prevState,
            time_exec: e.target.value,
          }))
        }
        }
      />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="demo-simple-select-label">{t_("Temporal criteria")}</InputLabel>
            <Select color="primary"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Criterio temporal"
              onChange={e => {
                setPlanParameters(prevState => ({
                  ...prevState,
                  criteria: e.target.value,
                }))
              }}
              value={planParameters.criteria}
            >
              <MenuItem value={"asap"}>ASAP</MenuItem>
              <MenuItem value={"jit"}>JIT</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t_("Priority")}
            fullWidth
            variant="standard"
            value={planParameters.priotemp}
            error={planParameters.showErrors && (planParameters.priotemp === '' || parseInt(planParameters.priotemp) < 1 || parseInt(planParameters.priotemp) > 10)}
            helperText={planParameters.showErrors && (planParameters.priotemp === '' || parseInt(planParameters.priotemp) < 1 || parseInt(planParameters.priotemp) > 10) ? "Value must be between 1 and 10" : null}
            onChange={e => {
              setPlanParameters(prevState => ({
                ...prevState,
                priotemp: e.target.value,
              }))
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="demo-simple-select-label">{t_("Cost criteria")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Cost criteria"
              value={planParameters.cost_criteria}
              onChange={e => {
                setPlanParameters(prevState => ({
                  ...prevState,
                  cost_criteria: e.target.value,
                }))
              }}
            >
              <MenuItem value={"cost"}>{t_("Cost")}</MenuItem>
              <MenuItem value={"energy"}>{t_("Energy consumption")}</MenuItem>
              <MenuItem value={"defective"}>{t_("Defective")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t_("Priority")}
            fullWidth
            variant="standard"
            error={planParameters.showErrors && (planParameters.priocost === '' || parseInt(planParameters.priocost) < 1 || parseInt(planParameters.priocost) > 10)}
            helperText={planParameters.showErrors && (planParameters.priocost === '' || parseInt(planParameters.priocost) < 1 || parseInt(planParameters.priocost) > 10) ? "Value must be between 1 and 10" : null}
            value={planParameters.priocost}
            onChange={e => {
              setPlanParameters(prevState => ({
                ...prevState,
                priocost: e.target.value,
              }))
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
