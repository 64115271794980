import { DoDisturbOn, ExpandMoreRounded, Save } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { apiGet, apiPut } from '../../generic/Api_Functions'
import ButtonBackSave from '../../generic/buttons/floatingButtons/ButtonBackSave'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import { SmartDialog } from '../../generic/utilities/SmartDialog'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'
import { CheckCorrectTimeFormat } from '../../generic/DateTimeFunctions'

const ToolInstanceSettings = (props) => {
  const { userPermission = false } = props
  const location = useLocation()
  const navigate = useNavigate()
  const [geometricData, setGeometricData] = useState([])
  const [processData, setProcessData] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openErrors, setOpenErrors] = useState(false)
  const [options, setOptions] = useState(null)
  const backSaveButtonRef = useRef()
  const [tool, setTool] = useState(location.state.row)

  const [editMode, setEditMode] = useState(false)
  const changeBetweenVisualizeModes = (edit) => {
    setEditMode(edit)
  }
  const { t_ } = useSmartTranslation()
  const columns = useMemo(() => [
    {
      name: t_('Code'),
      field: 'code',
      unique: true,
      edit: false
    },
    {
      name: t_('Name'),
      field: 'name',
      mandatory: true
    },
    {
      name: t_('Description'),
      field: 'description'
    },
    {
      name: t_('Current life'),
      field: 'currentLife',
      edit: true,
      condition: (currentLife) => {
        if (tool.unit?.unitType?.code === 'Duration') {
          const result = CheckCorrectTimeFormat(currentLife)
          return result === undefined || result === '' ? 'ok' : ''
        } else {
          return isNaN(currentLife) ? '' : 'ok'
        }
      }
    },
    {
      name: t_('Location'),
      field: 'location',
      edit: false
    },

    {
      name: t_('Holder'),
      field: 'fixture.code',
      type: 'select',
      edit: {
        source: async () => {
          return await apiGet('fixturesAvailableByToolReference/' + tool.id)
        },
        id: 'id',
        field: 'code'
      }
    },
    {
      name: t_('Entry date'),
      field: 'entryDate',
      type: 'dateTimePicker',
      readOnly: false,
      numeric: true,
      mandatory: true
    }
  ], [tool, t_])

  const handleColumnsErrors = useCallback(() => {
    const arrayColumns = []
    columns.forEach((column) => {
      arrayColumns[arrayColumns.length] = {
        field: column.field,
        error: false,
        errorLabel: '',
        label: ''
      }
    })
    return arrayColumns
  }, [columns])

  const [errorTextfield, setErrorTextfield] = useState(handleColumnsErrors)

  const dataFetchToolParameterValues = async (parameter) => {
    let toolParameterValues
    toolParameterValues = await apiGet('toolParameterValues/' + location.state.row.toolReference.id + '/' + location.state.row.id)
    toolParameterValues = toolParameterValues.filter((toolParameter) => toolParameter.toolParameterType.code === parameter)
    console.log('los datos son: ', toolParameterValues)

    // Translate 'name' parameter from each object of toolParametersValues array
    toolParameterValues = toolParameterValues.map((toolParameter) => {
      return {
        ...toolParameter,
        name: t_(toolParameter.name),
        readDate: toolParameter.value === null ? null : toolParameter.readDate
      }
    })

    return toolParameterValues
  }

  const toolParametersColumns = [
    {
      name: t_('Code'),
      field: 'code',
      readOnly: true,
      edit: false
    },
    {
      name: t_('Name'),
      field: 'name',
      readOnly: true,
      edit: false
    },
    {
      name: t_('Value'),
      field: 'value',
      edit: true,
      numeric: 'true'
    },
    {
      name: t_('Offset'),
      field: 'offsetValue',
      edit: true,
      numeric: 'true'
    },
    {
      name: t_('Unit'),
      field: 'unit.code',
      readOnly: true,
      edit: false
    },
    {
      name: t_('parameter Order'),
      field: 'parameterOrder',
      hide: true
    },
    {
      name: t_('Date'),
      field: 'readDate',
      edit: false,
      type: 'dateTimePicker'
    }

  ]

  useEffect(() => {
    const getIntervalColumnSelectOptions = async () => {
      const options = []
      const errors = []
      for (const column of columns) {
        if (
          column.edit &&
          column.type &&
          (column.type === 'select' || column.type === 'select-plain' || column.type === 'smartMultiSelect') &&
          typeof column.edit.source === 'function'
        ) {
          const dataOptions = await column.edit.source().catch((error) => {
            errors.push(error)
          })
          options[column.field] = {
            id: column.edit.id,
            field: column.edit.field,
            data: dataOptions
          }
        }
      }

      setOptions(options)
      return { options, errors }
    }
    getIntervalColumnSelectOptions()
    return () => {

    }
  }, [columns])

  const goBack = (state) => {
    navigate('/resources/toolManager', { state })
  }

  const displayData = (obj, field) => {
    const defaultValue = ''
    const a = field.split('.')
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i]
      if (obj !== null && obj !== undefined && obj !== 'undefined' && k in obj && obj[k] !== null) {
        obj = obj[k]
      } else {
        return defaultValue
      }
    }
    return obj
  }

  const onChange = (event, column) => {
    const copyTool = { ...tool }
    const propertySplit = column.field.split('.')
    if (propertySplit.length > 1) {
      copyTool[propertySplit[0]] = event
    } else {
      copyTool[column.field] = event
    }

    if (column.condition !== undefined && typeof column.condition === 'function') {
      manageErrorTextfield(column, column.condition(event))
    }

    if (column.mandatory === true) {
      manageErrorTextfield(column, propertySplit.length > 1 ? copyTool[propertySplit[0]] : copyTool[column.field])
    }
    setTool(copyTool)
  }

  const manageErrorTextfield = (column, result) => {
    const copyErrorTextFields = [...errorTextfield]
    if (result === undefined || result === '' || result.toString() === 'Invalid Date') {
      copyErrorTextFields.forEach((errorTextfield, index) => {
        if (column.field === errorTextfield.field) {
          errorTextfield.error = true
        }
      })
    } else {
      copyErrorTextFields.find((copyError) => {
        if (column.field === copyError.field) {
          copyError.error = false
          return true
        } else {
          return false
        }
      }
      )
    }
    setErrorTextfield(copyErrorTextFields)
  }

  const onBlur = (event, column) => {
    onChange(event, column)
  }

  const saveTool = () => {
    let toolParameterValues = []
    const errorFound = errorTextfield.find((error) => error.error === true)
    if (errorFound === undefined) {
      toolParameterValues = geometricData.concat(processData)
      toolParameterValues.forEach((toolReferenceParameterValue) => {
        toolReferenceParameterValue.toolReference = { id: tool.id }
        toolReferenceParameterValue.active = true
      })
      apiPut('tools', tool).catch((error) => {
        backSaveButtonRef.current.showErrorAlert({
          title: t_('An error occurred while editing.'),
          message: error.message,
          error: 'error'
        })
      })
      apiPut('toolParametersValues/' + tool.id, toolParameterValues).then(goBack(tool.toolReference)).catch((error) => {
        backSaveButtonRef.current.showErrorAlert({
          title: t_('An error occurred while editing.'),
          message: error.message,
          error: 'error'
        })
      })
      setOpenDialog(false)
    } else {
      setOpenDialog(false)
      setOpenErrors(true)
    }
  }

  const sortOptions = {
    default: {
      field: 'parameterOrder',
      direction: 1
    }
  }
  function RenderErrors () {
    const copyErrorTextfield = [...errorTextfield]
    const dataTodisplay = []
    copyErrorTextfield.forEach((error, index) => {
      if (error.error === true) {
        dataTodisplay.push(error)
      }
    })
    return (
      <div>
        {dataTodisplay.map((dataMissing, index) =>
          <Typography key={'missingData' + index}> Missing {dataMissing.field} </Typography>
        )}
      </div>
    )
  }
  return (
    <Paper
      sx={{
        flexGrow: 1,
        marginBottom: theme => theme.spacing(2),
        padding: theme => theme.spacing(2),
        color: theme => theme.palette.text.secondary
      }}
    >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          sx={{
            borderRadius: '5px 5px 0px 0px',
            borderBottom: '0.5px solid rgba(255, 255, 255, 0.12)',
            boxShadow: ' 0px 0px 1px 0px #7FE1A760, 0px 0px 3px 0px #7FE1A720'
          }}
        >
          <Typography
            variant='h6'
            component='div'
          >
            {tool.code} - {t_('GENERAL PROPERTIES')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{
          padding: '2em',
          borderRadius: '0px 0px 5px 5px',
          boxShadow: '0px 2px 1px -1px #7FE1A760, 0px 1px 1px 0px #7FE1A760, 0px 1px 3px 0px #7FE1A720'
        }}
        >
          <Grid container direction='row' spacing={2} columns={{ xs: 1, sm: 4, md: 8, lg: 12 }}>
            {columns.map((column, index) => (
              <Grid key={column.field + index} item xs>
                {column.type === 'dateTimePicker'
                  ? <DateTimePicker
                      key={'dateTimePicker' + index} dateAdapter={AdapterDateFns}
                      label={column.name}
                      value={new Date(displayData(tool, column.field))}
                      disabled={column.edit === false || editMode === false}
                      format='dd/MM/yyyy HH:mm'
                      onChange={(event) => onChange(event, column)}
                      slotProps={{
                        actionBar: {
                          actions: ['cancel', 'accept']
                        }
                      }}
                      localeText={{ cancelButtonLabel: t_('CANCEL'), okButtonLabel: t_('ACCEPT') }}
                      closeOnSelect={false} showToolbar={false}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          error={
                            errorTextfield[index].error
                              ? errorTextfield[index].error
                              : null
                          }
                        />}
                    />
                  : (
                    <TextField
                      sx={userPermission === false ? { color: 'white' } : null}
                      type={column.numeric ? 'number' : null}
                      select={column.type && column.type === 'select'}
                      fullWidth
                      defaultValue={displayData(tool, column.field)}
                      label={column.name}
                      onBlur={column.type && column.type === 'select' ? null : (event) => onBlur(event.target.value, column)}
                      disabled={column.edit === false || editMode === false}
                      error={errorTextfield[index].error
                        ? errorTextfield[index].error
                        : null}
                    >
                      {!column.mandatory
                        ? <MenuItem key='None' value='' onClick={() => onChange(null, column)}><em>{t_('None')}</em></MenuItem>
                        : null}
                      {column.type && column.type === 'select' && options !== null
                        ? options[column.field].data.map((option, index) => {
                          return (
                            <MenuItem
                              onClick={() => onChange(option, column)}
                              key={option.code + index}
                              value={option.code}
                            >
                              {option.code}
                            </MenuItem>
                          )
                        }
                        )
                        : []}
                    </TextField>
                    )}
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        />
        <AccordionDetails sx={{ padding: '2em', textAlign: '-webkit-center' }}>
          <Grid container direction='row' spacing={2} columns={{ xs: 1, sm: 4, md: 8, lg: 12 }}>
            <Grid item xs={6}>
              <div style={{ width: '90%', justifyContent: 'center' }}>
                <fieldset style={{ padding: '30px' }}><legend>{t_('Geometric')}</legend>
                  <SmartMateriaUITable
                    columns={toolParametersColumns}
                    dataFetch={() => dataFetchToolParameterValues('Geometric')}
                    setMultiEdit={editMode === false ? undefined : setGeometricData}
                    searchAndPaginationVisible='none'
                    sortOptions={sortOptions}
                    sticky={false}
                  />
                </fieldset>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ width: '90%' }}>
                <fieldset style={{ padding: '30px' }}><legend>{t_('Process')}</legend>
                  <SmartMateriaUITable
                    columns={toolParametersColumns}
                    dataFetch={() => dataFetchToolParameterValues('Process')}
                    setMultiEdit={editMode === false ? undefined : setProcessData}
                    searchAndPaginationVisible='none'
                    sortOptions={sortOptions}
                    sticky={false}
                  />
                </fieldset>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <ButtonBackSave
        editControl={() => changeBetweenVisualizeModes()}
        backButtonAction={() => goBack(tool.toolReference)}
        saveButtonAction={() => setOpenDialog(true)}
        ref={backSaveButtonRef}
      />

      <SmartDialog
        title={{
          visible: true,
          icon: <Save sx={{ fontSize: '30px' }} color='primary' />,
          render: <Typography color='primary' variant='h6'>{t_('Save data?')}</Typography>
        }}
        renderComponent={t_('Are you sure you want to save data?')}
        setOpen={openDialog}
        acceptCallbackArgsArray={[]}
        acceptCallback={() => saveTool()}
        cancelCallback={() => setOpenDialog(false)}
        accept
        cancel
        message=''
      />
      <SmartDialog
        title={{
          visible: true,
          icon: <DoDisturbOn sx={{ fontSize: '30px' }} color='error' />,
          render: <Typography color='error' variant='h6'>Missing Data</Typography>
        }}
        renderComponent={<RenderErrors />}
        setOpen={openErrors}
        acceptCallbackArgsArray={[]}
        cancelCallback={() => setOpenErrors(false)}
        accept={false}
        cancel
        message=''
      />
    </Paper>
  )
}

export default ToolInstanceSettings
