import React from 'react'
import { apiDelete, apiPost, apiPut, apiGet } from '../../generic/Api_Functions'
import SmartMateriaUITable from '../../generic/SmartMateriaUITable'
import Technologies from '../technologies/Technology'
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation'

const ToolGroup = (props) => {
  const { addModeByDefault = false, handleRowCreatedInOtherSmartTable } = props
  const { t_ } = useSmartTranslation()
  const columns = [{
    name: t_('Code'),
    field: 'code',
    charLimit: 60,
    mandatory: true,
    unique: true,
    readOnly: true
  },
  {
    name: t_('Name'),
    field: 'name',
    charLimit: 80,
    mandatory: true,
    unique: true,
    readOnly: (toolGroup) => {
      if (toolGroup.systemDefault === undefined || toolGroup.systemDefault === null) {
        return false
      }
      return toolGroup.systemDefault
    }
  },
  {
    name: t_('Description'),
    field: 'description',
    charLimit: 200
  },
  {
    name: t_('Technology'),
    field: 'technologies.name',
    type: 'smartMultiSelect',
    content: <Technologies handleRowCreatedInOtherSmartTable={handleRowCreatedInOtherSmartTable} addModeByDefault />,
    edit: {
      source: async () => {
        return await apiGet('technologiesBasicData')
      },
      id: 'id',
      field: 'name'
    },
    mandatory: true,
    readOnly: (toolGroup) => {
      if (toolGroup.systemDefault === undefined || toolGroup.systemDefault === null) {
        return false
      }
      return toolGroup.systemDefault
    }
  }
  ]

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPut('toolGroups', row)
      }
    },
    create: {
      fetchFunction: (row) => {
        return apiPost('toolGroups', row)
      }
    },
    delete: {
      fetchFunction: (rows) => {
        if (rows.length === 1) {
          return apiDelete('toolGroups/' + rows[0].id, rows)
        } else {
          return apiDelete('toolGroupsMulti', rows)
        }
      },
      visibilityButton: (toolGroup) => !toolGroup.systemDefault
    }
  }
  return (
    <SmartMateriaUITable
      columns={columns}
      title={t_('Tool groups')}
      dataFetch='toolGroups'
      actions={actions}
      multipleSelection
      addModeByDefault={addModeByDefault}
      handleRowCreatedInOtherSmartTable={handleRowCreatedInOtherSmartTable}
      multipleOptions
      pageable
    />
  )
}

export default ToolGroup
