import { Box, CircularProgress, Grid, Typography, useMediaQuery, useTheme, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import { Chart as ChartJS, Tooltip, Legend, LinearScale, CategoryScale, BarElement } from 'chart.js'
import { apiGet } from '../generic/Api_Functions'
import CellPartsBarChart from './charts/CellPartsBarChart'
import MachinedPartsHistory from './MachinedPartsHistory'
import { StorageGraph } from './OEEcelldonut'
import { useSmartInterval } from '../generic/hooks/useSmartInterval'

const depositContextStyle = {
  flex: 1
}

const Production = () => {
  const [actualLocations, setActualLocations] = useState([])

  const [parts, setParts] = useState([])
  const [totalPart, setTotalPart] = useState()
  const [totalSuccessPartPercentage, setTotalSuccessPartPercentage] = useState('')

  const [actualLocationsLoaded, setActualLocationsLoaded] = useState(false)
  const [partsLoaded, setPartsLoaded] = useState(false)
  const [, setOeeLoaded] = useState(false)
  const [graphTime, setGraphTime] = useState('Daily')

  const getIntervalData = async () => {
    const locs = await apiGet('locations/actualLocationsDashboard')
    const partsDashboard = await apiGet('partsDashboard/' + graphTime)
    return {
      actualLocations: locs,
      parts: partsDashboard
    }
  }

  const setIntervalData = (intervalData) => {
    setActualLocations(intervalData.actualLocations)
    setParts(intervalData.parts)
    setActualLocationsLoaded(true)
    setPartsLoaded(true)
  }

  useSmartInterval(getIntervalData, 1000, setIntervalData)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const isLaptop = useMediaQuery(theme.breakpoints.down('md'))

  const handleTotalPart = (totalPart) => {
    setTotalPart(totalPart)
  }

  const handleSuccessPartPercentage = (totalPartSuccesPercentage) => {
    setTotalSuccessPartPercentage(totalPartSuccesPercentage)
  }

  const handleOEEloadedValue = (value) => {
    setOeeLoaded(value)
  }

  const changeSelectValue = (value) => {
    setGraphTime(value)
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    BarElement
  )

  return (
    <div>
      {!partsLoaded || !actualLocationsLoaded
        ? (
          <div style={{ display: 'grid', placeItems: 'center', height: '38rem' }}>
            <CircularProgress />
          </div>
          )
        : (
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6}>
              <CellPartsBarChart locations={actualLocations} />
            </Grid>
            <Grid item xs={12} lg={6}>

              <Grid container>
                <Grid
                  container spacing={1} style={{
                    direction: 'column',
                    alignItems: 'center',
                    justify: 'center'
                  }}
                >
                  <Grid item xs={12} lg={4}>
                    <Grid item xs={12} sm={12} md={12}>
                      <>
                        <Typography style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>OEE</Typography>
                        <StorageGraph totalSuccessPartPercentage={totalSuccessPartPercentage} handleOEEloadedValue={handleOEEloadedValue} />
                      </>
                    </Grid>
                    <Grid
                      container spacing={2} style={{
                        display: 'grid',
                        gridTemplateColumns: 'auto auto',
                        paddingTop: '2em',
                        paddingBottom: '2em'
                      }}
                    >
                      <Grid item sx={{ textAlign: isMobile || isLaptop || isTablet ? 'center' : 'right', borderRight: isMobile || isLaptop || isTablet ? '0px' : '1px solid darkgrey' }}>
                        <Box style={{}}>
                          <Typography color='textSecondary' sx={depositContextStyle}>
                            Machining parts
                          </Typography>
                          {/* <Chip style={{ marginTop: "0.4em", backgroundColor: "rgb(255,152,0, 0.5)" }} label={totalPart} > </Chip> */}
                          <Typography component='p' variant='h4' style={{ color: 'rgb(255,152,0)' }}>
                            {totalPart}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item style={{ textAlign: isMobile || isLaptop || isTablet ? 'center' : 'left' }}>
                        <Typography color='textSecondary' sx={depositContextStyle}>
                          Success parts
                        </Typography>
                        <Typography component='p' variant='h4'>
                          {totalSuccessPartPercentage !== '' ? totalSuccessPartPercentage + '%' : '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={8} style={{}}>
                    <Select
                      variant='outlined' style={{ minWidth: '6em', float: 'right' }} value={graphTime} onChange={(event) => {
                        changeSelectValue(event.target.value)
                      }}
                    >
                      <MenuItem value='Daily'>Daily</MenuItem>
                      <MenuItem value='Weekly'>Weekly</MenuItem>
                      <MenuItem value='Monthly'>Monthly</MenuItem>
                    </Select>
                    <MachinedPartsHistory parts={parts} handleTotalPart={handleTotalPart} handleSuccessPartPercentage={handleSuccessPartPercentage} />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
                {<CellPartsTable
                  actualLocations={actualLocations}
                  classes={classes}
                ></CellPartsTable>}
              </Grid> */}
            </Grid>

            {/* <Grid item xs={12} lg={6}>
          <PartHistoryTable parts={parts} classes={classes}></PartHistoryTable>
        </Grid>

        <Grid item xs={12} lg={6}>
          <OrderQueueTable
            shelveStorage={shelveStorage}
            classes={classes}
          ></OrderQueueTable>
        </Grid> */}
          </Grid>
          )}
    </div>
  )
}

export default Production
