import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { Chip, Stack } from "@mui/material";
import SensorsActuatorsComponentsProperties from "./SensorsActuatorsComponentsProperties";
import SensorsActuatorsComponentsFunctions from "./SensorsActuatorsComponentsFunctions";
import AddCircleRounded from '@mui/icons-material/AddCircleRounded';
import { DefaultAccordion } from "../../generic/DefaultAccordion";
import { useSmartTranslation } from "../../generic/hooks/useSmartTranslation";

const SensorsActuatorsComponentsConfiguration = (props) => {
  const { ioElementSet, setIoElementSet, setSaveDisabled, errorAlert } = props;
  const [addNewFunction, setAddNewFunction] = useState(false)
  const { t_ } = useSmartTranslation();
  useEffect(() => {
    setSaveDisabled(addNewFunction)
  }, [addNewFunction, setSaveDisabled])

  const printIoElementSet = () => {
    return (
      <>
        <DefaultAccordion title={t_('PROPERTIES')}>
          <SensorsActuatorsComponentsProperties ioElementSet={ioElementSet} setSaveDisabled={setSaveDisabled} />
        </DefaultAccordion>
        <DefaultAccordion title={t_('FUNCTION PROTOCOLS')}>
          <Grid container >
            <Grid item xs={12}>
              <Stack spacing={2}>
                <Chip icon={<AddCircleRounded />} label={t_("Add function")} onClick={() => setAddNewFunction(true)} sx={{ margin: "1em", backgroundColor: (theme) => theme.palette.background.chip }} />
                <Grid container spacing={2} justifyContent="center"
                  alignItems="center" columns={{ xs: 1, sm: 4, md: 8, lg: 12 }}
                >
                  {addNewFunction ?
                    <SensorsActuatorsComponentsFunctions ioElementSet={ioElementSet} setIoElementSet={setIoElementSet} addNewFunction={addNewFunction} setAddNewFunction={setAddNewFunction} setSaveDisabled={setSaveDisabled} errorAlert={errorAlert} />
                    : null}
                  {ioElementSet.ioElementSetFunctions.sort((a, b) => a.id - b.id).map((ioElementSetFunction, index) => (
                    < SensorsActuatorsComponentsFunctions key={ioElementSetFunction.function.code.concat(index)} ioElementSet={ioElementSet} setIoElementSet={setIoElementSet} ioElementSetFunction={ioElementSetFunction} setSaveDisabled={setSaveDisabled} errorAlert={errorAlert} />
                  ))}
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </DefaultAccordion>
      </>
    );
  };

  if (ioElementSet && Object.keys(ioElementSet).length !== 0) {
    return printIoElementSet()
  }
  return <CircularProgress />
};

export default SensorsActuatorsComponentsConfiguration;
