import { apiPost, apiDelete } from "../../generic/Api_Functions";
import SmartMateriaUITable from "../../generic/SmartMateriaUITable";
import AddElementQuantity from "../../generic/smartAddElement/AddElementQuantity";
import { useState } from "react";
import FixtureAssemblyList from "./FixtureAssemblyList";
import { Popover, Stack, ClickAwayListener, Paper, Chip, Autocomplete, TextField, IconButton } from "@mui/material";
import { SmartDialog } from "../../generic/utilities/SmartDialog";
import LinkIcon from '@mui/icons-material/Link';
import { ManageSearchOutlined } from '@mui/icons-material';
import FixtureAssembly from "./FixtureAssembly";
import { useSmartTranslation } from '../../generic/hooks/useSmartTranslation';

const FixtureAssemblyReference = () => {
  const [editingFixtures, setEditingFixtures] = useState([]);
  const [editingRowIndex, setEditingRowIndex] = useState(-2);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogFixtureReferencesData, setDialogFixtureReferencesData] = useState(null);
  const [dialogInstancesReferenceData, setDialogInstancesReferenceData] = useState(null);
  const [quantityValue, setQuantityValue] = useState(0);
  const [fixturesError, setFixturesError] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const { t_ } = useSmartTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpen = (event, row) => {
    event.preventDefault();
    if (row.fixtureReferences.length > 10) {
      setDialogFixtureReferencesData(row.fixtureReferences);
    }
    else if (row.fixtureReferences.length > 2) {
      setAnchorEl({ index: row['smart-table-index'], target: event.currentTarget });
    }
  };

  const columns = [
    {
      name: t_("Code"),
      field: "code",
      edit: false,
      charLimit: 60,
      mandatory: true,
      unique: true,
    },
    {
      name: t_("Name"),
      field: "name",
      unique: true,
      charLimit: 80,
      mandatory: true,
    },
    {
      name: t_("Description"),
      field: "description",
      charLimit: 200,
    },
    {
      name: t_("Quantity"),
      field: "quantity",
      numeric: true,
      defaultNewValue: 0,
      render: (row, extraFunctions) => {
        if (row['smart-table-index'] === -1) {
          return (
            <TextField
              value={quantityValue}
              onChange={(event) => {
                setQuantityValue(event.target.value)
              }}
              helperText={" "}
              type="number"
              inputProps={{ min: 0 }}
            />
          );
        } else {
          return (
            <div id={"quantity" + row.id} style={{ alignItems: "center", display: "flex" }}>
              {row.quantity}
              <AddElementQuantity
                endpoint={"fixtureAssembly/create"}
                parameter={row.id}
                referenceName={row.name}
                disable={editingRowIndex !== -2}
                onAlert={extraFunctions.showAlert}
              />
            </div>);
        }
      },
    },
    {
      name: t_("Fixture"),
      field: "fixtureReferences",
      defaultNewValue: [],
      render: (row) => {
        const isEditingOrCreating = (row['smart-table-index'] === editingRowIndex);
        if (isEditingOrCreating) {
          return (<>
            < FixtureAssemblyList
              selectedItems={isEditingOrCreating ? editingFixtures : row.fixtureReferences}
              setSelectedItems={(items) => {
                if (items.length >= 2) {
                  setFixturesError(false);
                  setDisableSave(false);
                } else {
                  setDisableSave(true);
                }
                row.fixtureReferences = items;
                setEditingFixtures(items);
              }}
              error={fixturesError} />

          </>);
        } else {
          return (
            <div style={{ overflowY: "hidden", overflowX: "hidden", maxHeight: "80px" }}
              onClick={(event) => handlePopoverOpen(event, row)}
            >

              <Autocomplete
                disabled={true}
                limitTags={2}
                multiple={true}
                value={row.fixtureReferences}
                options={row.fixtureReferences}
                isOptionEqualToValue={(item1, item2) => item1.id === item2.id}
                popupIcon={null}
                renderTags={(value) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.code}
                      key={option.code + index}
                      size="small" />
                  ))
                }
                renderInput={(params) =>
                  <TextField variant="standard" {...params} InputProps={{ ...params.InputProps, readOnly: true, disableUnderline: true }} />
                }
              />
              {row.fixtureReferences.length === 0
                ? null
                : row.fixtureReferences.length <= 10 && row.fixtureReferences.length > 2 ?
                  <Popover
                    open={(anchorEl && anchorEl.index === row['smart-table-index']) ? true : false}
                    anchorEl={anchorEl ? anchorEl.target : null}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                    sx={{
                      pointerEvents: 'none',
                    }}
                    onClose={handleClose}
                    disableRestoreFocus
                  >
                    <ClickAwayListener
                      onClickAway={() => handleClose()}
                    >
                      <Stack component={Paper} direction="column" spacing={1} sx={{}}>
                        <FixtureAssemblyList
                          selectedItems={row.fixtureReferences}
                          disabled={true} />
                      </Stack>
                    </ClickAwayListener>
                  </Popover> : null
              }
            </div>
          );
        }
      },
    }
  ];

  const actions = {
    edit: {
      fetchFunction: (row) => {
        return apiPost("fixtureAssemblyReference", row);
      },
    },
    create: {
      fetchFunction: (row) => {
        row.quantity = quantityValue;
        setQuantityValue(0);
        return apiPost("fixtureAssemblyReference", row);
      },
    },
    delete: {
      fetchFunction: (pagination) => {
        if (Array.isArray(pagination)) {
          // When one selected instead of the pagination we get an array with a unique element that is the row
          let newPagination = {
            "page": 0,
            "size": -1,
            "columns": [],
            "genericSearch": "",
            "rows": [],
            "allSelected": false,
            "primaryKeys": [
              "id"
            ],
            "sortKey": [],
            "sortMode": []
          }
          pagination.map((item) => newPagination.rows.push(item.id));
          pagination = newPagination;
        }
        return apiDelete("fixtureAssemblyReference", pagination);
      },
    },
    custom: [
      {
        name: "",
        render: (row) => {
          return <IconButton
            disabled={editingRowIndex !== -2}
            onClick={(event) => {
              setDialogInstancesReferenceData(row);
            }}
          >
            <ManageSearchOutlined />
          </ IconButton >
        },
      },
    ]
  };

  const validator = (row) => {
    setFixturesError((row.fixtureReferences.length < 2));
  }

  const onEditIndexChanged = (editIndex, row) => {

    setFixturesError(false);

    if (editIndex === -1) {
      // Creating new element
      setEditingFixtures([]);
      setFixturesError(false);
      setDisableSave(true);
    } else if (editIndex >= 0) {
      // Editing element. Row will have data
      setEditingFixtures(row.fixtureReferences);
      setDisableSave((row.fixtureReferences.length < 2));
      setFixturesError(false);
    }

    setEditingRowIndex(editIndex);


  }

  return (
    <>

      <SmartDialog
        title={{ visible: true, render: "Fixtures", icon: <LinkIcon /> }}
        setOpen={(dialogFixtureReferencesData !== null)}
        close={true}
        cancel={false}
        accept={false}
        closeCallback={() => {
          setDialogFixtureReferencesData(null)
        }}
        renderComponent={
          <SmartMateriaUITable
            columns={[
              {
                name: "Code",
                field: "code",
                edit: false,
                search: false,
                sortable: false,
              }
            ]}
            dataFetch={dialogFixtureReferencesData}
            dense={true}
            toolbarDisplay={"none"}
          />
        }
      />

      <SmartDialog
        setOpen={(dialogInstancesReferenceData !== null)}
        close={true}
        cancel={false}
        accept={false}
        closeCallback={() => {
          setDialogInstancesReferenceData(null)
        }}
        renderComponent={
          <FixtureAssembly
            referenceId={dialogInstancesReferenceData ? dialogInstancesReferenceData.id : null}
            referenceName={dialogInstancesReferenceData ? dialogInstancesReferenceData.name : null} />
        }
      />

      <SmartMateriaUITable
        columns={columns}
        title={t_("Fixture Assembly")}
        dataFetch={"fixtureAssemblyReference"}
        actions={actions}
        multipleSelection
        validator={validator}
        pageable={true}
        disableSave={disableSave}
        forceDisableSaveButton={fixturesError}
        onEditIndexChanged={onEditIndexChanged}
      />
    </>
  );
};

export default FixtureAssemblyReference;
